import React, { useState } from "react";
import { MessageOutlined, MessageFilled } from "@ant-design/icons";
import { Badge, Button, Modal } from "antd";
import ChatThread from "../../../pages/buildings/chat/chatThread";

const Chat = ({ record, source }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [chatCount, setChatCount] = useState(record.chats.length);

  return (
    <div className="text-center">
      <Button onClick={() => setIsModalVisible(true)} type="text">
        <Badge count={chatCount}>
          {chatCount > 0 ? (
            <MessageFilled style={{ fontSize: 22 }} />
          ) : (
            <MessageOutlined style={{ fontSize: 22 }} />
          )}
        </Badge>
      </Button>
      <Modal
        title="Chats"
        visible={isModalVisible}
        footer={null}
        onCancel={() => setIsModalVisible(false)}
        width={1000}
      >
        <div className="card">
          <div className="card-body">
            <ChatThread
              source={source}
              id={record._id}
              chatCount={chatCount}
              setChatCount={setChatCount}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Chat;
