import React, { useState, useEffect } from 'react';
import './preferenceDetails.css'
import {
    DownSquareOutlined,
    RightSquareOutlined
} from '@ant-design/icons';
import { Input } from "antd";

import AdressTable from '../../../common/adressesTable';

const days = [90, 60, 30, 14, 7, 3, 1];

const PreferenceDetails = ({ data, handleChange, handleSelectDeselectAll, addresses, handleBuildingCheck }) => {

    const defaults = {
        90: true,
        60: true,
        30: true,
        14: true,
        7: true,
        3: true,
        1: true,
    };

    const [defaultHeaderChecked, setDefaultHeaderChecked] = useState(defaults)
    const [collapse, setCollapse] = useState({})
    const [filterValue, setFilterValue] = useState('')

    const toggleCollapse = (category) => {
        setCollapse({ ...collapse, [category]: !collapse[category] })
    }

    useEffect(() => {
        let newDefaults = defaults;
        Object.keys(data).map(group => {
            data[group].map(preference => {
                Object.keys(preference.alertDays).map(day => {
                    if (preference.alertDays[day] === false) {
                        newDefaults = {
                            ...newDefaults,
                            [day]: false,
                        }
                    }
                    return true;
                })
                return true;
            })
            return true;
        })

        setDefaultHeaderChecked(newDefaults)
        // eslint-disable-next-line
    }, [data]);

    return (
        <>
            <div className="card">
                <div className="card-header"><h5>Reminders Settings</h5></div>
                <div className="card-body ">
                    <table className="table table-bordered table-striped">
                        <thead>
                            <tr>
                                <th>
                                    Alert Type
                                </th>
                                {days.map((day, index) => (
                                    <th key={index}>
                                        {day} {day > 1 ? 'days' : 'day'}
                                        <label className="small d-flex align-items-center mt-2">
                                            <input type="checkbox" className="mr-1" checked={defaultHeaderChecked[day]} onChange={(e) => handleSelectDeselectAll(day, e.target.checked)} />
                                            <span>Un/Check All</span>
                                        </label>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {data ? Object.keys(data).map((group, groupIndex) => {
                                return data[group].map((preference, index) => {
                                    const category = preference.displayName;
                                    const alertDay = preference.alertDays;

                                    return (
                                        <React.Fragment key={index}>
                                            <tr >
                                                <td onClick={() => toggleCollapse(category)} className='pointer'>

                                                    <div className='ml-2 noselect'>{category}</div>

                                                    <small className='ml-2 d-flex align-items-center mt-1'>

                                                        {collapse[category] ? <DownSquareOutlined /> : <RightSquareOutlined />} <span className='ml-1 '>{
                                                        `exclude ${preference.excludedBins?.length ? `(${preference.excludedBins?.length} properties excluded)` : ''}`}</span>
                                                    </small>
                                                </td>
                                                {(Object.keys(alertDay)).slice().reverse().map((day, index2) => {
                                                    return (
                                                        <td key={index2}>
                                                            <input type="checkbox" checked={alertDay[day]} onChange={() => handleChange(category, group, day, alertDay[day])} />
                                                        </td>
                                                    )
                                                })}
                                            </tr>
                                            <tr>
                                                <td colSpan={8} className='p-0'>
                                                    <div className={`collapse-custom ${collapse[category] ? "" : 'collapse-custom-inactive'}`}>
                                                        <div className=" mt-2">
                                                            <div className='d-flex justify-content-between'>
                                                                <h5 className="text-uppercase mb-3 ml-2"> Exclude Buildings</h5>
                                                                <div><Input placeholder="Building Filter" value={filterValue} onChange={(e) => setFilterValue(e.target.value)} /></div>
                                                            </div>
                                                            <div className="table-responsive">
                                                                <AdressTable
                                                                    unChecked
                                                                    handleUnAndCheckAll={(value, bin) => handleBuildingCheck(bin, value, group, category)}
                                                                    buildings={
                                                                        addresses
                                                                            .filter(el => String(el.bin).includes(filterValue) || (el.address + el.display_address).toLowerCase().includes(filterValue.toLowerCase()))
                                                                            .map(el => ({ ...el, checked: preference.excludedBins?.includes(el.bin) }))}
                                                                    handleBuildingCheck={(value, bin) => handleBuildingCheck(bin, value, group, category)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td></tr>
                                            {groupIndex + 1 !== Object.keys(data).length && index + 1 === data[group].length && <tr><td colSpan={8}><hr /></td></tr>}
                                        </React.Fragment>
                                    )
                                });
                            }) : <tr><td colSpan={8}>No records</td></tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}

export default PreferenceDetails;