import React, { Fragment, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import ReactLoading from 'react-loading';

import creService from '../../services/creService';
import ViolationsTabEachBlock from './violationsTabEachBlock';
import ViolationsTabEachBlockListing from './violationsTabEachBlock-listing';
import HistoryUpdates from '../buildings/partials/historyUpdates';
import SmallCalendar from '../calendar/smallCalendar';
import LocalState from '../../common/localState';

const ViolationsTab = (props) => {
  const [board, setBoard] = useState(
    props.jestData !== undefined ? props.jestData.board : {}
  );
  const [loading, setLoading] = useState(
    props.jestData !== undefined ? props.jestData.loading : true
  );
  const [viewType, setViewType] = useState(
    props.jestData !== undefined
      ? props.jestData.viewType
      : LocalState.getViewTypeSetting('violations', 'list')
  );

  const loadingComponent = async (category) => {
    try {
      const api = await creService.getViolationsAllTab();
      setBoard(api.data);
      setLoading(false);
    } catch (e) {
      toast.error(e.message);
    }
  };

  useEffect(() => {
    loadingComponent();
  }, []);

  const switchToggle = async () => {
    const type = viewType === 'grid' ? 'list' : 'grid';
    await creService.updateUserPageView({
      page: 'violations',
      type,
    });
    setViewType(type);
  };

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row violation-sec">
          <div className="col-xl-9 violations-bg">
            <div className="card">
              <div className="card-header">
                <div className="col-md-10 pl-0">
                  <h5>Violations</h5>
                </div>
                <div className="col-md-2 text-right pr-0">
                  <div className="right-content">
                    <div id="btnContainer">
                      <button
                        className={`btn ${viewType === 'grid' ? 'active' : ''}`}
                        onClick={() => switchToggle()}
                      >
                        <i className="fa fa-th-large"></i>
                      </button>
                      <button
                        className={`btn ${viewType === 'list' ? 'active' : ''}`}
                        onClick={() => switchToggle()}
                      >
                        <i className="fa fa-bars"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                {viewType === 'grid' && (
                  <div className="row custom-col">
                    {loading ? (
                      <div className="col-12 p-t-50" align="center">
                        {' '}
                        <ReactLoading type={'bubbles'} color={'grey'} />{' '}
                      </div>
                    ) : (
                      Object.entries(board).map((value, index) => {
                        return (
                          <ViolationsTabEachBlock
                            history={props.history}
                            key={index}
                            loading={loading}
                            title={value[0]}
                            data={value[1]}
                          />
                        );
                      })
                    )}
                  </div>
                )}
                {viewType === 'list' && (
                  <div className="row vio-listing">
                    {loading ? (
                      <div className="col-12 p-t-50" align="center">
                        {' '}
                        <ReactLoading type={'bubbles'} color={'grey'} />{' '}
                      </div>
                    ) : (
                      Object.entries(board).map((value, index) => {
                        return (
                          <ViolationsTabEachBlockListing
                            history={props.history}
                            key={index}
                            loading={loading}
                            title={value[0]}
                            data={value[1]}
                          />
                        );
                      })
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-xl-3 recent-activity pr-0">
            <div className="pt-3 pb-3">
              {props.jestData !== undefined ? null : <SmallCalendar />}
            </div>
            <HistoryUpdates />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ViolationsTab;
