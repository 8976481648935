import React, { useEffect, useState } from 'react';
import Moment from "moment";
import { capSentence, formatter } from "../../../services/helperService";
import { Link } from "react-router-dom";
import creApi from "../../../services/creService";
import { toast } from "react-toastify";
import RetainedBadges from "./retainedBadges";
import CofCProgress from "./cofcProgress";
import CustomFields from "./customFields";

const DetailsDOB = ({ moveToCategory, category, sub, tableRawData, apiData, jestData = false, subUsers, vendors, customFields, setRefreshHistory }) => {

  const [ecbID, setEcbID] = useState(jestData ? jestData : []);
  useEffect(() => {

    async function fetchData() {
      try {
        let data = await creApi.getECB_ID(tableRawData.OATH_ECB_No);
        if (data.data && data.data) setEcbID(data.data)
      } catch (e) {
        toast.error(e.message)
      }
    }

    if (tableRawData.OATH_ECB_No) fetchData();
    // eslint-disable-next-line
  }, []);

  const { retained, cofc } = apiData?.violationDetails?.clientAdditionalData || {};

  const retainedTypes = Object.keys(retained).filter(key => retained[key].isRetained);

  const customFieldsData = apiData?.violationDetails?.customFieldData ? apiData?.violationDetails?.customFieldData[0]?.fields || [] : [];

  return (
    <div className="card user-card ecb-head"
      style={retainedTypes.length > 0 ? { border: '1px solid #c36c00', borderRadius: 0 } : {}}>
      <RetainedBadges retained={retained} />

      <div className="card-body flex-wrap d-flex">
        <div className="col-7 pl-0 ecb-sub-head">
          <div className="">
            <h5>Violation Details </h5>
          </div>
          <div className="ecb-row">
            <div className="col-4 pl-0">
              <h6>Issue Date</h6>
              <p>{tableRawData.Issue_Date ? Moment(tableRawData.Issue_Date).format('LL') : 'n/a'}</p>
            </div>
            <div className="col-4 pl-0">
              <h6>Device#</h6>
              <p>{tableRawData.Device_No ? tableRawData.Device_No : 'n/a'}</p>
            </div>
            <div className="col-4 pl-0">
              <h6>Type</h6>
              <p>{tableRawData.Violation_Type ? capSentence(tableRawData.Violation_Type) : 'n/a'}</p>
            </div>
          </div>
        </div>

        <div className="col-5 ecb-title">
          <h5 className="text-info mb-3 pointer" onClick={moveToCategory}>{category}/{sub}</h5>
          <div className='d-flex'>
            <h5 className="list-key">DOB#: </h5>
            <h5><span>{tableRawData.violation_id} </span></h5>
          </div>
          <div className="d-flex">
            <h5 className="list-key">Agency: </h5>
            <h5><span>{capSentence("DEPT. OF BUILDINGS")}</span></h5>

          </div>
          <div className="status mt-2">
            <p>{tableRawData.Violation_Category ? tableRawData.Violation_Category.replace(' - ', '').replace('DOB VIOLATION', '') : 'n/a'}</p>
          </div>
          <div className="d-flex text-center">
            {(tableRawData.OATH_ECB_No &&
              <div className="blue-box">
                <h6>Associated Violations</h6>
                <p>
                  ECB#:
                  {ecbID.length > 0 && ecbID.map((ecb) => {
                    return (
                      <React.Fragment key={ecb._id}>
                        <span className="px-1">
                          {ecb._id && <Link className="text-nowrap" to={`/violation-details/${ecb._id}`}>
                            {ecb.ecbNumber}
                          </Link>}
                          {!ecb._id && ecb.ecbNumber}
                        </span>
                      </React.Fragment>
                    )
                  })}
                  {ecbID.length <= 0 && tableRawData.OATH_ECB_No}
                </p>
              </div>
            )}
          </div>
        </div>
        {(tableRawData.allisn && tableRawData.ppremise60 && tableRawData.BIN) &&
          <a className="badge badge-light budge-absolute-right"
            href={`http://a810-bisweb.nyc.gov/bisweb/ActionViolationDisplayServlet?requestid=1&allbin=${tableRawData.BIN}&allinquirytype=BXS3OCV4&allboroughname=&allstrt=&allnumbhous=&allisn=${tableRawData.allisn}&ppremise60=${tableRawData.ppremise60}`}
            rel="noopener noreferrer" target="_blank">
            DOB <i className="fa fa-external-link" />
          </a>
        }
        <div className="row ecb-row mt-3 w-100">
          <div className="col-3">
            <h6>DOB Civil Penalty</h6>
            <p>{tableRawData.DOB_Civil_Penalty ? (isNaN(parseFloat(tableRawData.DOB_Civil_Penalty)) ?
              tableRawData.DOB_Civil_Penalty || 'n/a' :
              formatter.format(parseFloat(tableRawData.DOB_Civil_Penalty))) : 'n/a'}</p>
          </div>
          <div className="col-3">
            <h6>Device Type</h6>
            <p>{tableRawData.ViolationDeviceType ? tableRawData.ViolationDeviceType : 'n/a'}</p>
          </div>


          <div className="col-3">
            <h6>DOB Civil Penalty Status</h6>
            <p>{tableRawData.DOB_Civil_Penalty_Status ? tableRawData.DOB_Civil_Penalty_Status : 'n/a'}</p>
          </div>

          <div className="col-3">
            <h6>Cycle End Date</h6>
            <p>{tableRawData.CycleEndDate ? Moment(tableRawData.CycleEndDate).format('LL') : 'n/a'}</p>
          </div>

          <div className="col-3 mt-3">
            <h6>Violation Class</h6>
            <p>{tableRawData.ViolationClass ? tableRawData.ViolationClass : 'n/a'}</p>
          </div>
        </div>
        {(tableRawData.Disposition_Code || tableRawData.Disposition_Date || tableRawData.Disposition_Inspector || tableRawData.Disposition_Comments) &&
          <>
            <div className="row w-100 ecb-sub-head">
              <div className="col-12">
                <h5>Disposition</h5>
              </div>
            </div>
            <div className="row ecb-row">
              <div className="col-3">
                <h6>Code</h6>
                <p>{tableRawData.Disposition_Code ? tableRawData.Disposition_Code : 'n/a'}</p>
              </div>
              <div className="col-2">
                <h6>Date</h6>
                <p>{tableRawData.Disposition_Date ? tableRawData.Disposition_Date : 'n/a'}</p>
              </div>
              <div className="col-2">
                <h6>Inspector</h6>
                <p>{tableRawData.Disposition_Inspector ? tableRawData.Disposition_Inspector : 'n/a'}</p>
              </div>
              <div className="col-5">
                <h6>Comments</h6>
                <p>{tableRawData.Disposition_Comments ? tableRawData.Disposition_Comments : 'n/a'}</p>
              </div>
            </div>
          </>
        }
        {(tableRawData.Description) &&
          <div className="ecb-notes col-12">
            <h5>Regarding</h5>
            <div className="desc">
              <p>
                {capSentence(tableRawData.Description)}
              </p>
            </div>
          </div>
        }
        {apiData?.violationDetails?.additionalData?.bcompliantNotes &&
          <div className="ecb-notes col-12">
            <h5>
            BCompliant Violation Notes
            </h5>
            <div className="desc">
              <p>
                {apiData.violationDetails.additionalData.bcompliantNotes}
              </p>
            </div>
            {apiData.violationDetails.bcompliantNotesUpdatedBy && <small className="text-muted">
              Last updated By {apiData.violationDetails.bcompliantNotesUpdatedBy.name}
            </small>}
          </div>
        }
      </div>
      <CustomFields
        customFields={customFields}
        subUsers={subUsers}
        vendors={vendors}
        category={category}
        sub={sub}
        customFieldsData={customFieldsData}
        apiData={apiData}
        setRefreshHistory={setRefreshHistory}
      />
      <CofCProgress cofc={cofc} />
    </div>
  );
};

export default DetailsDOB;
