import React, {Fragment, useEffect, useState} from 'react';
import creApi from "../../../services/creService";
import {toast} from "react-toastify";
import moment from "moment";
import Datatable from "react-data-table-component";

const ElevatorsInspections = (props) => {


    const [loading, setLoadingState] = useState(true);
    const [data, setData] = useState([]);
    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    useEffect( () => {

        async function fetchData() {
            try {

                let data = await creApi.getElevatorsInspections(props.bin, props.deviceNumber);
                setLoadingState(false)
                setData(data.data)

            }catch (e) {
                toast.error(e.message)
            }
        }

        fetchData();
    // eslint-disable-next-line
    }, []);

    const columns = [
        {
            name: 'Inspection Date',
            selector: 'inspectDateConverted',
            sortable: true,
            format: (row, index) => {return moment(row.inspectDateConverted).format('MM/DD/YYYY'); }
        },
        {
            name: 'Device#',
            selector: 'deviceNumber',
        },
        {
            name: 'Deposition',
            selector: 'inspectDeposition',
            sortable: true,
            wrap: true,
            grow: 3
        },
        {
            name: 'Type',
            selector: 'inspectType',
            sortable: true,
            wrap: true,
            grow: 2
        },
        {
            name: 'Inspected By',
            selector: 'inspectedBy',
            sortable: true,
            wrap: true,
            grow: 3
        },
    ];

    // eslint-disable-next-line
    const filteredItems = data.filter(item => {
        let array = Object.values(item);
        if(array.find(item => item.toString().toLowerCase().includes(filterText.toLowerCase()))) return true;
    });

    return (
        <Fragment>

            <div className="card user-card">
                <div className="card-header">
                    <div className="online-user"><h5 className="font-primary f-18 mb-0">Inspections</h5></div>
                </div>

                <div className="card-body">

                    <div className="text-right">
                        <input className="" type="text" placeholder="Filter By Name" aria-label="Search Input" value={filterText} onChange={e => setFilterText(e.target.value)} />
                        <button type="button" onClick={() => {setResetPaginationToggle(!resetPaginationToggle); setFilterText('');}}>X</button>
                    </div>

                    <Datatable
                        progressPending={loading}
                        columns={columns}
                        data={filteredItems}
                        highlightOnHover={true}
                        pagination={true}
                        paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                        noHeader={true}
                    />
                </div>
            </div>

        </Fragment>
    );
};

export default ElevatorsInspections;
