const CoveredItemsTable = ({ items }) => {

    return (
        <div className="w-100">
            <div className="d-flex justify-content-center">
                <h5>Covered Items</h5>
            </div>
            <div className="row text-center align-items-center disposition-header " style={{ background: '#CECECE' }}>
                <div className="col-1 p-0">
                    Alpha
                </div>
                <div className="col-2 p-0">
                    Account #
                </div>
                <div className="col-1 p-0">
                    Item Code
                </div>

                <div className="col-2 p-0">
                    Item Desc
                </div>
                <div className="col-1 p-0">
                    Qty
                </div>
                <div className="col-1 p-0">
                    Floor
                </div>
                <div className="col-4 p-0">
                    Comments
                </div>
            </div>
            {items.map((el, i) => {
                return <div className="row text-center border-bottom align-items-center disposition-rows" key={i}>
                    <div className="col-1 p-0">
                        <p>{el["Alpha"] ? el["Alpha"] : 'n/a'}</p>
                    </div>
                    <div className="col-2 p-0">
                        <p>{el["Account #"] ? el["Account #"] : 'n/a'}</p>
                    </div>
                    <div className="col-1 p-0">
                        <p>{el["Item Code"] ? el["Item Code"] : 'n/a'}</p>
                    </div>
                    <div className="col-2 p-0">
                        <p>{el["Item Desc"] ? el["Item Desc"] : 'n/a'}</p>
                    </div>
                    <div className="col-1 p-0">
                        <p>{el["Qty"] ? el["Qty"] : 'n/a'}</p>
                    </div>
                    <div className="col-1 p-0">
                        <p>{el["Floor"] ? el["Floor"] : 'n/a'}</p>
                    </div>
                    <div className="col-4 p-0">
                        <p>{el["Comments"] ? el["Comments"] : 'n/a'}</p>
                    </div>
                </div>
            })}

        </div>
    )
}

export default CoveredItemsTable;