import React, { useState } from 'react';
import { Collapse } from 'reactstrap';

const RecentActivityListGroupCollapse = ({ data }) => {

  const [collapse, setcollapse] = useState(false);

  const toggle = () => {
    setcollapse(!collapse);
  };
  const isArray = Array.isArray(data.difference)
  let added
  let removed
  if (isArray) {
    added = data.difference.filter(el => el[0] === '+')
    removed = data.difference.filter(el => el[0] === '-')
  }


  return (
    <small>
      <li
        className="p-1 list-group-item align-items-center pointer text-muted border-0"
        onClick={toggle}
      >
        <div className="d-flex justify-content-between ">
          {`in ${data.title}`}
          <span className="badge badge-light ">{collapse ? '-' : '+'}</span>
        </div>
        <Collapse isOpen={collapse}>
          <ul className="list-group">

            {isArray ?
              removed.length > 0 &&
              <li className="list-group-item list-group-item-light ">
                {removed.map((el, i) =>
                  <div  key={el[1] + i}><span className=' text-danger'>Removed:</span> {el[1]}</div>
                )}
              </li>
              : <li className="list-group-item list-group-item-light text-danger"><s className=' text-danger'>{String(data.difference.__old)}</s></li>
            }


            {isArray ?
              added.length > 0 && <li className="list-group-item ">
                {added.map((el, i) =>
                  <div  key={el[1] + i}><span className='text-success'>Added:</span> {el[1]}</div>
                )}
              </li>
              : <li className="list-group-item "><span className='text-success'>{String(data.difference.__new)} </span></li>
            }


          </ul>
        </Collapse>
      </li>
    </small >
  )
};

export default RecentActivityListGroupCollapse;
