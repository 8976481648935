import React, { useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import { UncontrolledTooltip } from 'reactstrap';
import { Input } from "antd";

import {
  DownSquareOutlined,
  RightSquareOutlined
} from '@ant-design/icons';
import { Zoom } from 'react-toastify';

import AdressTable from '../../../common/adressesTable';
import creService from '../../../services/creService';

const NotificationPreference = ({ jestData = false, locationEnv }) => {
  const [data, setData] = useState(jestData ? jestData : {});
  const [loading, setLoading] = useState(false);
  const vpuReportInfoRef = useRef(null)
  const snapshotReportInfoRef = useRef(null)
  const defaults = {
    email: true,
    pushNotification: true,
  };
  const [selectedBins, setSelectedBins] = useState([]);
  const toastId = useRef(null);

  const [defaultHeaderChecked, setDefaultHeaderChecked] = useState(defaults);

  const [emailIndividualAlert, setEmailIndividualAlert] = useState(false);

  const [emailActivitySnapshot, setEmailActivitySnapshot] = useState(false);
  const [VPUWeeklyNotification, setVPUWeeklyNotification] = useState(false);
  const [ecbEmailDiactivated, setEcbEmailDiactivated] = useState(false);

  const [collapse, setCollapse] = useState({})
  const [filterValue, setFilterValue] = useState('')
  const [addresses, setAddresses] = useState([]);

  const toggleCollapse = (category) => {
    setCollapse({ ...collapse, [category]: !collapse[category] })
  }

  async function fetchData() {
    try {
      const res = await creService.getAllNotificationPreferences();
      let {
        emailIndividualAlert,
        VPUWeeklyNotification,
        emailActivitySnapshot,
        ecbEmailDiactivated,
        ...other
      } = res.data;

      setEmailIndividualAlert(emailIndividualAlert || false);
      setEmailActivitySnapshot(emailActivitySnapshot || false);
      setVPUWeeklyNotification(VPUWeeklyNotification || false);

      setEcbEmailDiactivated(ecbEmailDiactivated || false);

      if (res.data) setData(other);
      const buildingsList = await creService.getBuildingsListOnly('portfolio');
      if (buildingsList.data) {
        setAddresses(buildingsList.data)
      }
    } catch (error) {
      toast.error(error);
    }
  }
  useEffect(() => {
    fetchData();
    return () => {
      toast.dismiss(toastId.current);
      toastId.current = null;
    };
  }, []);

  useEffect(() => {
    let newDefaults = defaults;
    Object.keys(data).map((category) => {
      Object.keys(data[category]).map((sub) => {
        if (data[category][sub].email === false) {
          newDefaults.email = false;
        }
        if (data[category][sub].pushNotification === false) {
          newDefaults.pushNotification = false;
        }
        return true;
      });
      return true;
    });
    setDefaultHeaderChecked(newDefaults);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleSelectDeselectSingle = (category, sub, type, state) => {
    data[category][sub][type] = state;
    setData({ ...data });
  };

  const excludeSelected = () => {
    setSelectedBins((selectedBins) => {
      for (let category in data) {
        for (let sub in data[category]) {
          data[category][sub].excludedBins = selectedBins
        }
      }

      setData({ ...data });
      showSelected([])
      saveNotificationPreference()
      return []
    })

  };

  const showSelected = (selectedBins) => {

    if (selectedBins.length !== 0) {
      const content = (
        <div className='m-3 text-center'>
          <div> exclude ({selectedBins.length} properties excluded)</div>
          <button
            className="btn mt-2 btn-primary"
            onClick={excludeSelected}
          >
            Apply To All Categories
          </button>
        </div>
      );
      if (toastId.current) {
        toast.update(toastId.current, {
          render: content,
        });
      } else {
        toastId.current = toast(content, {
          position: 'bottom-left',
          autoClose: false,
          closeButton: false,
          hideProgressBar: false,
          closeOnClick: false,
          draggable: false,
          theme: 'light',
          transition: Zoom,
          // className: 'table-viol-checked',
        });
      }
    } else {
      toast.dismiss(toastId.current);
      toastId.current = null;
    }


  };

  const handleBuildingCheck = (category, sub, bin, value) => {

    let newSelected = []
    if (bin) {
      newSelected = value ? (!selectedBins.includes(bin) ? [...selectedBins, bin] : selectedBins) : selectedBins.filter(el => el !== bin)
    } else {
      if (value) {
        newSelected = addresses.map(el => el.bin)
      }
    }

    setSelectedBins(newSelected)

    showSelected(newSelected)

    if (!data[category][sub].excludedBins) data[category][sub].excludedBins = []
    if (value) {
      if (bin) {
        data[category][sub].excludedBins.push(bin)
      } else {
        data[category][sub].excludedBins = addresses.map(el => el.bin)
      }
    } else {
      if (bin) {
        data[category][sub].excludedBins = data[category][sub].excludedBins.filter(el => el !== bin)
      } else {
        data[category][sub].excludedBins = []
      }
    }
    setData({ ...data });
  };


  const handleSelectDeselectAll = (type, state) => {
    Object.keys(data).map((category) => {
      Object.keys(data[category]).map((sub) => {
        data[category][sub][type] = state;
        return true;
      });
      return true;
    });
    setData({ ...data });
    setDefaultHeaderChecked({
      ...defaultHeaderChecked,
      [type]: state,
    });
  };

  const saveNotificationPreference = async () => {
    setLoading(true);
    try {
      const res = await creService.updateNotificationsPreferences({
        ...data,
        emailIndividualAlert,
        emailActivitySnapshot,
        VPUWeeklyNotification,
        ecbEmailDiactivated
      });
      if (res.data.status && typeof res.data.message !== 'undefined') {
        toast.success(res.data.message);
      } else {
        toast.error(res.data?.message || 'Something went wrong.');
      }
    } catch (error) {
      toast.error(error);
    }
    setLoading(false);
  };

  return (
    <React.Fragment>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th></th>
            <th className="text-center">
              {/* <label className="small d-flex align-items-center justify-content-center mt-2">
                <input
                  type="checkbox"
                  checked={emailIndividualAlert}
                  onChange={(e) => setEmailIndividualAlert(e.target.checked)}
                />
                <span className="ml-1"> Send Individual Alerts</span>
              </label> */}
              Email
              <label className="small d-flex align-items-center justify-content-center mt-2">
                <input
                  type="checkbox"
                  checked={defaultHeaderChecked.email}
                  onChange={(e) =>
                    handleSelectDeselectAll('email', e.target.checked)
                  }
                />
                <span className="ml-1"> Un/Check All</span>
              </label>
            </th>
            <th className="text-center">
              Push Notification
              <label className="small d-flex align-items-center justify-content-center mt-2">
                <input
                  type="checkbox"
                  checked={defaultHeaderChecked.pushNotification}
                  onChange={(e) =>
                    handleSelectDeselectAll(
                      'pushNotification',
                      e.target.checked
                    )
                  }
                />
                <span className="ml-1"> Un/Check All</span>
              </label>
            </th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(data).filter(category => !category.includes('Philly')).map((category) => {
            if (!['emailIndividualAlert'].includes(category)) {
              const subs = data[category];
              return (
                <React.Fragment key={category}>
                  <tr className="bg-light">
                    <th colSpan={3}>{category}</th>
                    {/* <td className="text-center">
                                        <input type="checkbox" />
                                    </td>
                                    <td className="text-center">
                                        <input type="checkbox" />
                                    </td> */}
                  </tr>
                  {Object.keys(subs).map((sub) => {
                    return (
                      <React.Fragment key={sub}>
                        <tr >
                          <td onClick={() => toggleCollapse(category + sub)} className='pointer'>
                            {sub.replace(/_/g, ' ').replace(/\s\s+/g, ' ')}
                            <small className=' d-flex align-items-center mt-1'>
                              {collapse[category + sub] ? <DownSquareOutlined /> : <RightSquareOutlined />} <span className='ml-1 '>{
                                `exclude ${data[category][sub].excludedBins?.length ? `(${data[category][sub].excludedBins?.length} properties excluded)` : ''}`}</span>
                            </small>
                          </td>
                          <td className="text-center">
                            <input
                              type="checkbox"
                              checked={data[category][sub].email}
                              onChange={(e) =>
                                handleSelectDeselectSingle(
                                  category,
                                  sub,
                                  'email',
                                  e.target.checked
                                )
                              }
                            />
                          </td>
                          <td className="text-center">
                            <input
                              type="checkbox"
                              checked={data[category][sub].pushNotification}
                              onChange={(e) =>
                                handleSelectDeselectSingle(
                                  category,
                                  sub,
                                  'pushNotification',
                                  e.target.checked
                                )
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={8} className='p-0'>
                            <div className={`collapse-custom ${collapse[category + sub] ? "" : 'collapse-custom-inactive'}`}>
                              <div className=" mt-2">
                                <div className='d-flex justify-content-between'>
                                  <h5 className="text-uppercase mb-3 ml-2"> Exclude Buildings</h5>
                                  <div><Input placeholder="Building Filter" value={filterValue} onChange={(e) => setFilterValue(e.target.value)} /></div>
                                </div>
                                <div className="table-responsive">
                                  <AdressTable
                                    unChecked
                                    handleUnAndCheckAll={(value, bin) => handleBuildingCheck(category, sub, bin, value)}
                                    buildings={
                                      addresses
                                        .filter(el => String(el.bin).includes(filterValue) || (el.address + el.display_address).toLowerCase().includes(filterValue.toLowerCase()))
                                        .map(el => ({ ...el, checked: data[category][sub].excludedBins?.includes(el.bin) }))}
                                    handleBuildingCheck={(value, bin) => handleBuildingCheck(category, sub, bin, value)}
                                  />
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })}
                </React.Fragment>
              );
            }
            return null;
          })}
          <tr className="bg-light">
            <th colSpan={3}>Weekly Notifications</th>
          </tr>
          <tr>
            <td>
              <i
                ref={snapshotReportInfoRef}
                className="fa fa-info-circle"
                aria-hidden="true"
              ></i>{' '}
              Activity Snapshot Report
            </td>

            <td className="text-center">
              <input
                type="checkbox"
                checked={emailActivitySnapshot}
                onChange={(e) => setEmailActivitySnapshot((prev) => !prev)}
              />
            </td>
            <td></td>
          </tr>
          <tr>
            <td>
              <i
                id='vpu-checkbox-notification'
                ref={vpuReportInfoRef}
                className="fa fa-info-circle"
                aria-hidden="true"
              ></i>{' '}
              High threshold VPU Report
            </td>

            <td className="text-center">
              <input
                type="checkbox"
                checked={VPUWeeklyNotification}
                onChange={(e) => setVPUWeeklyNotification((prev) => !prev)}
              />
            </td>
            <td></td>
          </tr>
          <tr>
            <td>
              ECB Attention Required
            </td>

            <td className="text-center">
              <input
                type="checkbox"
                checked={!ecbEmailDiactivated}
                onChange={() => setEcbEmailDiactivated((prev) => !prev)}
              />
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>

      <button
        onClick={saveNotificationPreference}
        disabled={loading}
        className="btn my-4 btn-primary"
      >
        {!loading ? 'Save' : 'Saving...'}
      </button>
      <UncontrolledTooltip placement="right" target={vpuReportInfoRef}>
        Will be send Every Tuesday
      </UncontrolledTooltip>
      <UncontrolledTooltip placement="right" target={snapshotReportInfoRef}>
        Will be send every Friday and Monday
      </UncontrolledTooltip>

    </React.Fragment>
  );
};

export default NotificationPreference;
