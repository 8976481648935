import React, { Fragment, useState } from 'react';
import Moment from "moment";
import { Link } from 'react-router-dom'

import Helper from "../../../services/helperService";
import RetainedBadges from './retainedBadges';
import CofCProgress from "./cofcProgress";
import CustomFields from "./customFields";
import CaseViolations from './caseViolations'
import CaseInvestigations from './caseInvestigations'
import DocsViewer from '../../../common/document-viewer'
const descriptionFields = ['grounds']

const DetailsPhiladelphia = ({
  category,
  sub,
  tableRawData,
  apiData,
  convertedData,
  subUsers,
  vendors,
  customFields,
  setRefreshHistory,
  noHeader,
  moveToCategory
}) => {

  const [docsViewerVisibility, setDocsViewerVisibility] = useState(false);
  const { retained, cofc } = apiData?.violationDetails?.clientAdditionalData || {};

  const retainedTypes = retained ? Object.keys(retained).filter(key => retained[key].isRetained) : []

  const data = Object.entries(convertedData)

  return (
    // All Other Violations Show General Design
    data.map(([paragraphName, values], key) => {

      return <Fragment key={key + 100}>
        <div className="card user-card philly-display"
          style={retainedTypes.length > 0 ? { border: '1px solid #c36c00', borderRadius: 0 } : {}}>
          <RetainedBadges retained={retained} />
          {
            data.length > 1 &&
            <div className="card-header">
              <div className="online-user"><h5 className="font-primary f-18 mb-0">{paragraphName}</h5></div>
            </div>
          }


          <div className="card-body ecb-head">


            {/violation|case|investigation/i.test(sub) &&
              <div className='d-flex justify-content-between mb-3'>
                <div >
                  <h5 onClick={moveToCategory} className="text-info  pointer mb-0">
                    {category}/{sub}
                  </h5>
                  {apiData.violationDetails?.violationData?.caseprioritydesc &&
                    <h6 className="pointer badge badge-primary pointer"> {apiData.violationDetails?.violationData?.caseprioritydesc}</h6>
                    }
                </div>
                <h6 className="text-info  pointer">
                  <div>{apiData.violationDetails?.violationData?.casestatus}</div>
                  {apiData.violationDetails?.case_id &&
                    <Link className="text-nowrap text-info" to={`/violation-details/${apiData.violationDetails.case_id}`} target="_blank">
                      View Linked Case <i aria-hidden="true" class="fa fa-external-link"></i>
                    </Link>
                  }
                </h6>
              </div>
            }

            {/permit/i.test(sub) &&
              <div className='d-flex justify-content-between mb-3'>
                <div >
                  <h5 onClick={moveToCategory} className="text-info  pointer mb-0">
                    {category}/{sub}
                  </h5>
                  <h6 className=" pointer badge badge-primary"> {apiData.violationDetails?.violationData?.permitdescription}

                  </h6>
                </div>
                <h6 className="text-info  pointer">
                  <div>{apiData.violationDetails?.violationData?.permitissuedate &&
                    <> <strong>ISSUED:</strong> {Moment(apiData.violationDetails?.violationData?.permitissuedate).format('LL')}</>
                  }
                  </div>
                  <div>{apiData.violationDetails?.violationData?.zoningDocument &&
                    <div onClick={() => setDocsViewerVisibility(true)}> Zoning Document: <i aria-hidden="true" class="fa fa-external-link"></i></div>
                  }
                  </div>

                </h6>
              </div>
            }
            {/BusinessLicenses/i.test(sub) &&
              <div className='d-flex justify-content-between mb-3'>
                <div >
                  <h5 onClick={moveToCategory} className="text-info  pointer mb-0">
                    {category}/{sub}
                  </h5>
                  <h6 className="pointer badge badge-primary  pointer">
                    {apiData.violationDetails?.violationData?.revenuecode} {apiData.violationDetails?.violationData?.licensetype}

                  </h6>
                </div>
                <h6 className="text-info  pointer">
                  <div>{apiData.violationDetails?.violationData?.licensestatus}</div>

                </h6>
              </div>
            }
            {/Appeals/i.test(sub) &&
              <div className='d-flex justify-content-between mb-3'>
                <div >
                  <h5 onClick={moveToCategory} className="text-info  pointer mb-0">
                    {category}/{sub}
                  </h5>

                </div>
                <h6 className="text-info  pointer">
                  <div>{apiData.violationDetails?.violationData?.appealstatus}</div>

                </h6>
              </div>
            }
            {/Certifications/i.test(sub) &&
              <h5 onClick={moveToCategory} className="text-info  pointer mb-0">
                {category}/{sub}
              </h5>
            }


            <div className='row ecb-row'>

              {values.filter(value => tableRawData[value.apiField]?.length).map((value, key) => {

                let displayValue = tableRawData[value.apiField];

                // Converting Date
                if (displayValue && ((value.apiField.search(/date/i) !== -1) || (value.displayName.search(/date/i) !== -1)) && typeof displayValue !== 'undefined') {
                  if (Moment(displayValue).isValid()) {
                    displayValue = Moment(displayValue).format('LL');
                  } else {
                    displayValue = '--';
                  }
                }

                // Check if we need to add $ sign
                if (displayValue && value.dollarAmount) {
                  displayValue = `${Helper.formatter.format(parseFloat(displayValue))}`;
                }


                // eslint-disable-next-line
                if (typeof displayValue === 'string' && displayValue.search(/http/i) !== -1) {
                  displayValue =
                    <a className="badge badge-info" rel="noopener noreferrer" href={displayValue} target="_blank">

                      {value.apiField.includes('pdf') ? 'Open in new tab ' : 'Make a Payment '}
                      <i className="fa fa-external-link" /></a>;
                }


                if (typeof displayValue === 'object') {
                  displayValue = displayValue.join?.(', ')

                } else {
                  displayValue = displayValue || '--'
                }

                return (
                  <div className={`mt-2 ${(descriptionFields.includes(value.displayName.toLowerCase())) ? 'col-12 col-md-12' : 'col-6 col-md-4'}  mb-3`} key={key}>
                    <h6>{value.displayName} {value.link && <Link to={value.link}>
                      <i className=" fa fa-external-link" aria-hidden="true"></i>
                    </Link>
                    }</h6>
                    <p className={`rounded d-flex flex-column ${descriptionFields.includes(value.displayName.toLowerCase()) ? 'desc' : '' }`}>
                      {displayValue}
                    </p>
                  </div>)

              })

              }

              {apiData.violationDetails?.case_violations?.length ? <CaseViolations items={apiData.violationDetails.case_violations} /> : null}
              {apiData.violationDetails?.case_investigations?.length ? <CaseInvestigations items={apiData.violationDetails.case_investigations} /> : null}

              {apiData?.violationDetails?.additionalData?.bcompliantNotes &&
                <div className="ecb-notes">
                  <h5>
                    BCompliant Violation Notes
                  </h5>
                  <div className="desc">
                    <p>
                      {apiData.violationDetails.additionalData.bcompliantNotes}
                    </p>
                  </div>
                  {apiData.violationDetails.bcompliantNotesUpdatedBy && <small className="text-muted">
                    Last updated By {apiData.violationDetails.bcompliantNotesUpdatedBy.name}
                  </small>}
                </div>
              }
            </div>
          </div>
          <CustomFields
            saveOn={category === 'Compliance' ? 'customCompliance' : undefined}
            customFields={customFields}
            subUsers={subUsers}
            vendors={vendors}
            category={category}
            sub={sub}
            apiData={apiData}
            setRefreshHistory={setRefreshHistory}
          />
          <CofCProgress cofc={cofc} />
        </div>
        <DocsViewer currentKey={apiData.violationDetails?.violationData?.zoningDocument} isVisible={docsViewerVisibility} setIsVisible={setDocsViewerVisibility} useAWSURL={true} />
      </Fragment >
    })
  );
};

export default DetailsPhiladelphia;
