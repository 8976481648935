import React, { useState } from "react";
import { FileFilled, FileOutlined } from "@ant-design/icons";
import { Badge, Modal, Button } from "antd";
import NotesComponent from "../../../pages/buildings/violationDetails/notes";

//Button Component to trigger Notes Modal
const Notes = ({ record, source }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [notesCount, setNotesCount] = useState(record.notes.length);

    return (
        <div className="text-center">
            <Button onClick={() => setIsModalVisible(true)} type="text">
                <Badge count={notesCount}>
                    {notesCount > 0 && <FileFilled style={{ fontSize: 22 }} />}
                    {notesCount <= 0 && (
                        <FileOutlined style={{ fontSize: 22 }} />
                    )}
                </Badge>
            </Button>
            <Modal
                title="Notes"
                visible={isModalVisible}
                footer={null}
                onCancel={() => setIsModalVisible(false)}
                width={1000}
            >
                <NotesComponent source={source}  violationId={record._id} notesCount={notesCount} setNotesCount={setNotesCount}/>
            </Modal>
        </div>
    );
};

export default Notes;
