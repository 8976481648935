import React, { Component, Fragment } from "react";
import { TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { NavLink as RouterNavLink, Route, Switch } from 'react-router-dom';
import creApi from "../../../services/creService";
import { toast, ToastContainer } from "react-toastify";
import AddressHeaderBlock from "../partials/addressHeaderBlock";
import ReactLoading from "react-loading";
import RecentActivity from "../partials/recentActivity";
import ElevatorsInspections from "../partials/elevatorsInspections";
import { ElevatorsTrackingSingle, ElevatorsTracking } from "../partials/elevatorsTracking";
import DetailsECB from "../partials/detailsECB";
import DetailsDOB from "../partials/detailsDOB";
import DetailsHPD from "../partials/detailsHPD";
import DetailsHPDCharges from "../partials/DetailsHPDCharges";

import DetailsDOBComplaint from "../partials/detailsDOBComplaint";
import DetailsAllOther from "../partials/detailsAllOther";
import DetailsPhiladelphia from "../partials/detailsPhiladelphia";
import PermitCertificates from "../partials/permitCertificates";
import ChatThread from "../chat/chatThread";
import configDB from '../../../../data/customizer/config';
import auth from '../../../services/authService';
import Notes from "./notes";
import Uploads from "./uploads";
import WorkOrder from "./workOrder";
import RawData from "./rawData";
import ColorMark from "./colorMark";
import { settingsKey } from '../../../services/authService';
import { UncontrolledTooltip } from "reactstrap";
import ChatModal from "../chat/chatModal";
import "./index.css"

class ViolationsDetails extends Component {

    locationState = this.props.location?.state;

    constructor(props) {
        super(props);
        this.setState = this.setState.bind(this);
    }

    state = {
        doneLoading: false,
        doneLoadingActivity: true,
        tableRawData: [{}],
        addressData: {
            address: "Loading...",
            bin: '',
            lot: '',
            block: ''
        },
        filter: {},
        rawDataState: '',
        notes: '',
        violationId: '',
        recentActivity: [],
        showColorPicker: false,
        selectedColor: '',
        allColors: ['#ff4545', '#eb6c23', '#ffc533', '#65c92a', '#175dad', '#872ade', '#cc6299', '#613c22', '#000000'],
        colorToolTip: '',
        chatModalState: false,
        subUsers: [],
        vendors: [],
        customFields: [],
        refreshHistory: false,
        filePageDefault: false
    };
    setRefreshHistory = () => {
        this.setState((state) => ({ refreshHistory: !state.refreshHistory }))
    }
    getViolationData = async (id) => {
        try {
            let apiDataRaw;
            let apiData;
            let propertyInfo;
            let notes = '';
            let selectedColor = null;
            let colorToolTip = null;

            let subUsers = await this.getSubUsers();
            let vendors = await this.getVendors();



            apiDataRaw = await creApi.getViolationDetails(id);
            if (apiDataRaw.data?.status === false) {
                this.props.history.push('/my-buildings')
                toast.error(apiDataRaw.data?.message)
            } else {
                apiData = apiDataRaw.data.violationDetails;
                propertyInfo = await creApi.getPropertyInfo(apiData.bin);

                if (apiData.clientAdditionalData?.colorMark) {
                    selectedColor = apiData.clientAdditionalData?.colorMark;
                }

                const userColors = JSON.parse(localStorage.getItem(settingsKey))?.colors;

                if (userColors?.[selectedColor]?.label) {
                    colorToolTip = userColors[selectedColor].label;
                }

                let customFields = await this.getCustomFields(apiData);


                this.setState({
                    doneLoading: true,
                    category: apiData.category,
                    sub: apiData.sub.replace(/_/g, ' '),
                    subOrg: apiData.sub,
                    tableRawData: apiData.violationData,
                    convertedData: apiDataRaw.data.convertedData,
                    addressData: propertyInfo.data,
                    rawDataState: (Object.keys(apiDataRaw.data.convertedData).length) ? 'd-none' : '',
                    notes: notes.notes,
                    violationId: apiData._id,
                    apiData: {
                        notes,
                        ...apiDataRaw.data
                    },
                    filePageDefault: apiDataRaw.data?.violationDetails?.notes.length === 0 && apiDataRaw.data?.violationDetails?.files.length !== 0,
                    selectedColor,
                    colorToolTip,
                    subUsers,
                    vendors,
                    customFields,
                });
                // this.props.history?.replace(`/violation-details/${id}`);
            }
        } catch (e) {
            toast.error(e.message);
            if (this.props.match.params.bin && this.props.match.params.category && this.props.match.params.sub)
                this.props.history.push(`/violations/table/${this.props.match.params.bin}/${this.props.match.params.category}/${this.props.match.params.sub}`);

        }
    }

    componentDidMount() {
        this.getViolationData(this.props.match.params.id);
        this.scrollToHash();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // Typical usage (don't forget to compare props):
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.setState({ doneLoading: false })
            this.getViolationData(this.props.match.params.id);
        }
        const needHelp = new URLSearchParams(this.props.location.search).get("need-help")
        const hireUs = new URLSearchParams(this.props.location.search).get("hire-us")
        const reply = new URLSearchParams(this.props.location.search).get("reply")
        if (needHelp !== null || hireUs !== null || reply !== null) {
            this.setState({ chatModalState: true });
            this.props.history.replace(`/violation-details/${this.state.violationId}/chats`);
        }
    }

    handleRawDataClick = () => {
        if (this.state.rawDataState === 'd-none')
            this.setState({ rawDataState: '' })
        else
            this.setState({ rawDataState: 'd-none' })
    }

    handleBack = () => {
        this.props.history.goBack();
    };

    saveNotes = async () => {
        try {
            const { data } = await creApi.saveNotes(this.state.violationId, { notes: this.state.notes });
            if (data.status) {
                toast.success(data.message);
            } else {
                toast.error(data.message);
            }
        } catch (e) {
            toast.error(e.message);
        }
    };

    handleChangeNotes = ({ currentTarget: input }) => {
        this.setState({ notes: input.value });
    };

    Capitalize(str) {
        if (str) return str.charAt(0).toUpperCase() + str.slice(1);
    }

    getColorMarkLabel = (hexCode) => {
        const userSettings = JSON.parse(localStorage.getItem(settingsKey));
        if (userSettings?.colors?.[hexCode]) {
            return userSettings.colors[hexCode].label;
        }
        return '';
    }

    getCustomFields = async (apiData) => {
        let paramCategory = this.props.match.params.category;
        let paramSub = this.props.match.params.sub;

        if (apiData && typeof paramCategory === 'undefined') {
            paramCategory = apiData?.category;
        }
        if (apiData && typeof paramSub === 'undefined') {
            paramSub = apiData?.sub;
        }

        const categories = await creApi.getCustomFields(paramCategory, paramSub)
        const customFields = categories.data;
        return customFields;

    }

    moveToCategory = () => {
        this.props.history?.push(`/violations/table/${this.state.apiData?.violationDetails?.bin}/${this.state.apiData?.violationDetails.category}/${this.state.apiData?.violationDetails.sub}`);
    }

    scrollToHash = () => {
        let currentLocation = window.location.href;
        const hasAnchor = currentLocation.includes("/#");
        if (hasAnchor) {
            const anchorId = `${currentLocation.substring(currentLocation.indexOf("#") + 1)}`;
            const element = document.getElementById(anchorId);
            if (element) {
                setTimeout(() => {
                    window.scrollTo({
                        top: element.offsetTop + 200,
                        behavior: "smooth"
                    });
                }, 300);
            }
        }
    }

    getSubUsers = async () => {
        const subUsersRes = await creApi.getSubUsers();
        return subUsersRes.data.data;
    }

    getVendors = async () => {
        const res = await creApi.getVendors();
        return res.data.vendors;
    }

    render() {
        let paramCategory = this.props.match.params.category;
        let paramSub = this.props.match.params.sub;

        const { apiData } = this.state;
        const workOrders = apiData?.violationDetails?.allWorkOrders || [];
        if (apiData && typeof paramCategory === 'undefined') {
            paramCategory = apiData?.violationDetails.category;
        }
        if (apiData && typeof paramSub === 'undefined') {
            paramSub = apiData?.violationDetails.sub;
        }

        let renderDetails;
        if (this.state.category === "ECB" && !this.state.sub.includes('Complaints')) {
            const sub = (this.props.match.params?.sub) ? this.props.match.params.sub?.replace('__', ' ') : this.state.sub;
            renderDetails = <DetailsECB
                category={this.state.category}
                sub={sub}
                apiData={apiData}
                tableRawData={this.state.tableRawData}
                subUsers={this.state.subUsers}
                vendors={this.state.vendors}
                customFields={this.state.customFields}
                setRefreshHistory={this.setRefreshHistory}
                moveToCategory={this.moveToCategory}
            />
        } else if (this.state.category === "DOB") {
            if (this.state.sub.includes('Violations'))
                renderDetails = <DetailsDOB
                    category={this.state.category}
                    sub={this.state.sub}
                    apiData={apiData}
                    tableRawData={this.state.tableRawData}
                    subUsers={this.state.subUsers}
                    vendors={this.state.vendors}
                    customFields={this.state.customFields}
                    setRefreshHistory={this.setRefreshHistory}
                    moveToCategory={this.moveToCategory}
                />
            else
                renderDetails = <DetailsDOBComplaint
                    category={this.state.category}
                    sub={this.state.sub}
                    tableRawData={this.state.tableRawData}
                    apiData={this.state.apiData}
                    subUsers={this.state.subUsers}
                    vendors={this.state.vendors}
                    customFields={this.state.customFields}
                    setRefreshHistory={this.setRefreshHistory}
                    moveToCategory={this.moveToCategory}
                />
        } else if (this.state.category === "HPD") {
            if (this.state.sub === 'Charges') {
                renderDetails = <DetailsHPDCharges
                    category={this.state.category}
                    sub={this.state.sub}
                    apiData={apiData}
                    tableRawData={this.state.tableRawData}
                    convertedData={this.state.convertedData}
                    subUsers={this.state.subUsers}
                    vendors={this.state.vendors}
                    customFields={this.state.customFields}
                    setRefreshHistory={this.setRefreshHistory}
                    noHeader={this.props.simpleView}
                    moveToCategory={this.moveToCategory}
                />
            } else {
                renderDetails = <DetailsHPD
                    category={this.state.category}
                    sub={this.state.sub}
                    apiData={apiData}
                    tableRawData={this.state.tableRawData}
                    convertedData={this.state.convertedData}
                    subUsers={this.state.subUsers}
                    vendors={this.state.vendors}
                    customFields={this.state.customFields}
                    setRefreshHistory={this.setRefreshHistory}
                    noHeader={this.props.simpleView}
                    moveToCategory={this.moveToCategory}
                />
            }

        } else if (paramSub === "Certificate_of_Occupancy") {

            renderDetails = <PermitCertificates
                category={this.state.category}
                sub={this.state.sub}
                apiData={apiData}
                tableRawData={this.state.tableRawData}
                convertedData={this.state.convertedData}
                subUsers={this.state.subUsers}
                vendors={this.state.vendors}
                customFields={this.state.customFields}
                setRefreshHistory={this.setRefreshHistory}
                noHeader={this.props.simpleView}
                moveToCategory={this.moveToCategory}
            />
        } else if (this.state.category?.includes('Philly')) {
            renderDetails = <DetailsPhiladelphia
                category={this.state.category}
                sub={this.state.sub}
                apiData={apiData}
                tableRawData={this.state.tableRawData}
                convertedData={this.state.convertedData}
                subUsers={this.state.subUsers}
                vendors={this.state.vendors}
                customFields={this.state.customFields}
                setRefreshHistory={this.setRefreshHistory}
                noHeader={this.props.simpleView}
                moveToCategory={this.moveToCategory}
            />
        } else {
            renderDetails = <DetailsAllOther
                category={this.state.category}
                sub={this.state.sub}
                apiData={apiData}
                tableRawData={this.state.tableRawData}
                convertedData={this.state.convertedData}
                subUsers={this.state.subUsers}
                vendors={this.state.vendors}
                customFields={this.state.customFields}
                setRefreshHistory={this.setRefreshHistory}
                noHeader={this.props.simpleView}
                moveToCategory={this.moveToCategory}
            />
        }
        const colorMarkLabel = this.getColorMarkLabel(this.state.selectedColor);
        const user = auth.getCurrentUser();

        const notesPaths = [`/violation-details/${this.state.violationId}/notes`]
        const uploadPaths = [`/violation-details/${this.state.violationId}/documents`]
        if (this.state.filePageDefault) {
            uploadPaths.push(`/violation-details/${this.state.violationId}`)
        } else {
            notesPaths.push(`/violation-details/${this.state.violationId}`)
        }

        return (
            <Fragment>
                <ToastContainer />
                <div className="container-fluid p-0 violationsDetails-bg ph-2">
                    {!this.props.simpleView &&
                        <div className="row ">
                            <div className="col-md-12">
                                {this.state.addressData &&
                                    <AddressHeaderBlock data={this.state.addressData} handleGoBack={this.handleBack} violationDetailsPage />
                                }
                            </div>
                        </div>
                    }
                    <div className="row m-0 pr-3 w-100">
                        <div className={`building-detail w-100 ${this.props.simpleView ? '' : 'col-xl-9'} `}>
                            {!this.state.doneLoading ? (
                                <div align="center">
                                    <ReactLoading type={"cylon"} color={"grey"} />
                                </div>
                            ) : (
                                <div className="position-relative">
                                    <span
                                        className="color-mark-badge"
                                        style={{ backgroundColor: this.state.selectedColor?.length > 0 ? `${this.state.selectedColor}` : null }}
                                        id="colorMarkToolTip"
                                    />
                                    {colorMarkLabel && colorMarkLabel.length > 0 &&
                                        <UncontrolledTooltip placement="top" autohide={true} target="colorMarkToolTip">
                                            {colorMarkLabel}
                                        </UncontrolledTooltip>}
                                    {renderDetails}
                                </div>
                            )}


                            {this.state.doneLoading && this.state.sub === "Elevator" &&
                                <>
                                    <ElevatorsInspections bin={this.state.addressData.bin}
                                        deviceNumber={this.state.tableRawData.DeviceID} />
                                    <ElevatorsTracking
                                        bin={this.state.addressData.bin}
                                        deviceNumber={this.state.tableRawData.DeviceID}
                                        rowData={this.state.tableRawData}
                                    />
                                </>
                            }

                            {this.state.doneLoading && this.state.sub === "Elevator Tracking" &&
                                <ElevatorsTrackingSingle
                                    bin={this.state.addressData.bin}
                                    deviceNumber={this.state.tableRawData.DeviceID}
                                    rowData={this.state.tableRawData}
                                />
                            }
                            {!this.props.simpleView &&
                                <div className="card-body p-0 mt-4" id="all-tabs-area">
                                    <Nav card tabs className="d-flex m-0">
                                        <NavItem>
                                            <NavLink active={
                                                this.props.match.params.tabName === 'notes' ||
                                                (!this.props.match.params.tabName && !this.state.filePageDefault)
                                            } tag={RouterNavLink}
                                                to={`/violation-details/${this.state.violationId}/notes/#tab-content`} exact>
                                                Notes
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                active={
                                                    this.props.match.params.tabName === 'documents' ||
                                                    (!this.props.match.params.tabName && this.state.filePageDefault)
                                                }
                                                tag={RouterNavLink}
                                                to={`/violation-details/${this.state.violationId}/documents/#tab-content`} exact>
                                                Uploads/Files
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink tag={RouterNavLink} to={`/violation-details/${this.state.violationId}/chats/#tab-content`} exact>
                                                Chats
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink tag={RouterNavLink} to={`/violation-details/${this.state.violationId}/work-order/#tab-content`} exact>
                                                Work Order
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink tag={RouterNavLink} to={`/violation-details/${this.state.violationId}/color-marks/#tab-content`} exact>
                                                Color Labels
                                            </NavLink>
                                        </NavItem>
                                        {configDB.data.whiteListedEmails.indexOf(user.email) !== -1 && (
                                            <NavItem>
                                                <NavLink tag={RouterNavLink} to={`/violation-details/${this.state.violationId}/raw-data/#tab-content`} exact>
                                                    Raw Data
                                                </NavLink>
                                            </NavItem>
                                        )}

                                    </Nav>

                                    <Switch>
                                        <Route path={notesPaths} exact>
                                            <TabPane tabId={`/violation-details/${this.state.violationId}/notes`}>
                                                <Notes violationId={this.state.violationId} />
                                            </TabPane>
                                        </Route>

                                        <Route path={uploadPaths} exact>
                                            <TabPane tabId={`/violation-details/${this.state.violationId}/documents`}>
                                                <Uploads
                                                    violationId={this.state.violationId}
                                                    category={this.state.category}
                                                    sub={this.state.sub?.replace(/ /g, '_')}
                                                    tableRawData={this.state.tableRawData}
                                                    showDocumentTag
                                                />
                                            </TabPane>
                                        </Route>
                                        <Route path={`/violation-details/${this.state.violationId}/chats`}>
                                            <TabPane tabId={`/violation-details/${this.state.violationId}/chats`}>
                                                <div className="card">
                                                    <div className="card-body">
                                                        <ChatThread
                                                            id={this.state.violationId}
                                                            socket={this.props.socket}
                                                        />
                                                    </div>
                                                </div>
                                            </TabPane>
                                        </Route>
                                        <Route path={`/violation-details/${this.state.violationId}/work-order`}>
                                            <TabPane tabId={`/violation-details/${this.state.violationId}/work-order`}>
                                                <WorkOrder
                                                    violationId={this.state.violationId}
                                                    bin={this.state.addressData.bin}
                                                    workOrders={workOrders}
                                                    isVisitIntegrationEnabled={this.state.addressData?.isVisitIntegrationEnabled}
                                                    isAppWorkIntegrationEnabled={this.state.addressData?.isAppWorkIntegrationEnabled}
                                                    workOrderCreateAbility={this.state.addressData?.workOrderCreateAbility}
                                                />
                                            </TabPane>
                                        </Route>
                                        <Route path={`/violation-details/${this.state.violationId}/color-marks`}>
                                            <TabPane tabId={`/violation-details/${this.state.violationId}/color-marks`}>
                                                <ColorMark allColors={this.state.allColors} violationId={this.state.violationId} activeColor={this.state.selectedColor} updateState={this.setState} />
                                            </TabPane>
                                        </Route>
                                        {configDB.data.whiteListedEmails.indexOf(user.email) !== -1 && (
                                            <Route path={`/violation-details/${this.state.violationId}/raw-data`}>
                                                <TabPane tabId={`/violation-details/${this.state.violationId}/raw-data`}>
                                                    <RawData tableRawData={this.state.tableRawData} />
                                                </TabPane>
                                            </Route>
                                        )}

                                    </Switch>

                                </div>
                            }
                        </div>
                        {!this.props.simpleView &&
                            <div className="col-xl-3 recent-activity px-4 px-xl-0">
                                <RecentActivity
                                    type="violationActivity"
                                    match={this.props.match}
                                    history={this.props.history}
                                    bin={this.props.match.params.id}
                                    apiData={this.state.apiData}
                                    refreshHistory={this.state.refreshHistory}
                                />
                            </div>
                        }

                    </div>
                </div>
                {!this.props.simpleView &&
                    <ChatModal
                        modalState={this.state.chatModalState}
                        toggle={() => {
                            this.setState({ chatModalState: !this.state.chatModalState })
                        }}
                        violationId={this.state.violationId}
                    />
                }
            </Fragment>


        );
    }
}

export default ViolationsDetails;
