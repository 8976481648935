import AvatarEditor from "react-avatar-editor";
import React, { useState, useRef } from 'react';
import { Button } from "antd";
import creService from "../../services/creService";
import { toast } from "react-toastify";

const AvatarEdit = ({ children, setSrc }) => {

  const [image, setImage] = useState(null);
  const editorRef = useRef(null);
  const fileInputRef = useRef(null);
  const [scale, setScale] = useState(1); // Initial scale

  const handleSave = async () => {
    if (editorRef.current) {
      try {
        const canvas = editorRef.current.getImageScaledToCanvas();
        const croppedImage = canvas.toDataURL("image/png");

        const res = await creService.uploadAvatar(croppedImage)
        if (res.data.status) {
          toast.success(res.data.message)
        } else {
          toast.error(res.data.message)
        }


      } catch (err) {
        toast.error(err.message)
      }
      setSrc()
      setImage(null)
    }
  };
  const handleClose = () => {
    setImage(null)
  }
  const handleWheel = (e) => {
    e.stopPropagation(); 
    e.preventDefault();
    const newScale = scale + e.deltaY * -0.001; // Adjust scale based on scroll
    setScale(Math.min(Math.max(newScale, 0.5), 5)); // Clamp scale between 0.5 and 5
  };
  const handleUploadClick = () => {

    if (fileInputRef.current) {
      fileInputRef.current.click(); // Programmatically trigger the file input click
    }
  };

  return (
   
    <div style={{ position: 'relative' }}>
      <div onClick={handleUploadClick}>
        {children}
        <input
          type="file"
          accept="image/*"
          style={{ display: "none" }}
          onChange={(e) => setImage(e.target.files[0] ? URL.createObjectURL(e.target.files[0]) : null)}
          ref={fileInputRef}
        />
      </div>



      {image && (
        <div style={{position: 'absolute', zIndex: 100, background: 'rgba(255, 255, 255, 0.9)', right: 0 }} className="p-4 rounded" onWheel={handleWheel}>
          <AvatarEditor
            ref={editorRef}
            image={image}
            width={200}
            height={200}
            border={50}
            borderRadius={100} // Circular crop
            scale={scale}
          />
          <div className="d-flex justify-content-between">
            <Button onClick={handleClose}>close</Button>
            <Button onClick={handleSave}>Save Avatar</Button>
          </div>
        </div>
      )}

    </div>
  );
};

export default AvatarEdit