import React from 'react';
import { servicesAccessKey } from '../../services/authService';
import Expired from './expired';
import ExpireAlertMessage from './expireAlertMessage';

const WordOrderLayout = ({ serviceName, children, hideHeader }) => {
  const servicesAccess = JSON.parse(
    localStorage.getItem(servicesAccessKey) || '{}'
  );

  if (
    !servicesAccess[serviceName] &&
    children.props?.match?.path === '/work-orders/create'
  ) {
    return <Expired />;
  }

  if (
    servicesAccess[serviceName] &&
    (!servicesAccess[serviceName].enabled ||
      (servicesAccess[serviceName].dayRemaining !== undefined &&
        servicesAccess[serviceName].dayRemaining <= 0))
  ) {
    if (children.props?.match?.path === '/work-orders/create') {
      return <Expired />;
    }
  }

  return (
    <React.Fragment>
      {!hideHeader && <ExpireAlertMessage servicesAccess={servicesAccess} />}
      {children}
    </React.Fragment>
  );
};

export default WordOrderLayout;
