import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import moment from "moment";

import creService from '../../services/creService';
import './updates.css'

const Updates = () => {

    const [updates, setUpdates] = useState([])
    const history = useHistory()

    useEffect(() => {
        creService.getVersionUpdates().then(res => {
            if (res.status) {

                setUpdates(res.data.data)
                localStorage.setItem('last-checked-version', res.data.data?.[0]?.version);
            } else {
                toast.error(res.data.message)
            }

        }).catch(err => toast.error(err.message))
    }, [])

    useEffect(() => {
        if (history.location.hash) {
            setTimeout(() => {
                const anchor = document.querySelector(history.location.hash)
                anchor?.scrollIntoView({ behavior: 'smooth', block: 'center' })
            })
        }
    }, [history.location.hash, updates])

    return (
        <div className="mr-2 version-updates-block">
            <div className="row justify-content-center">

                <div className="mt-4">
                    <div className="section_heading text-center">
                        <h6>Versions updates </h6>
                        <div className="line"></div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="apland-timeline-area">
                        {updates.map(update => {
                            const key = 'vu-' + update.version
                            return (
                                <div className="single-timeline-area " key={key} id={key}>
                                    <div className="timeline-date wow fadeInLeft updates-title" data-wow-delay="0.1s" >
                                        <p>
                                            Version {update.version}
                                        </p>
                                    </div>
                                    <hr></hr>
                                    <div className="row">
                                        {update.updates?.map((item, i) => {
                                            return (<div className="col-12 " key={'update-' + i}>
                                                <div className="single-timeline-content d-flex wow fadeInLeft updates-item" data-wow-delay="0.3s" >
                                                    <div className="timeline-icon"><i className="fa fa-refresh" aria-hidden="true"></i></div>
                                                    <div className="timeline-text w-100">
                                                        <h6>Release Date {update.reactVersionUpdatedAt ? moment(new Date(update.reactVersionUpdatedAt)).format('LL') : 'unknown'}</h6>
                                                        <div dangerouslySetInnerHTML={{ __html: item.text }} />
                                                    </div>
                                                </div>
                                            </div>)
                                        })}
                                    </div>
                                </div>)
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Updates;
