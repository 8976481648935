import React, { Fragment, useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Link, useHistory } from 'react-router-dom';
import ReactLoading from "react-loading";
import RecentActivity from './recentActivity';
import creService from "../../services/creService";
import { permissionsKey } from "../../services/authService";
import Guard from "../../common/guard";

const WorkOrders = ({ jestData = false }) => {

    const [workOrders, setWorkOrders] = useState(jestData ? jestData : []);
    const [loading, setLoading] = useState(false);
    const [sortField, setSortField] = useState({});
    const [workOrderCreateAbility, setWorkOrderCreateAbility] = useState({
        bcomplaint: false,
        visitt: false
    })

    const getWorkOrders = async () => {
        setLoading(false);
        const res = await creService.getWorkOrdersByBuildings();
        if (res.data.status) {
            setWorkOrders(Object.entries(res.data.workOrders));
            setWorkOrderCreateAbility(res.data.workOrderCreateAbility);
        } else {
            toast.error(res.data.message);
        }
        if (res.data.permissions) {
            localStorage.setItem(permissionsKey, JSON.stringify(res.data.permissions));
        }
        setLoading(true);
    }

    const handleSort = async (_sortField) => {
        const direction = typeof sortField[_sortField] != 'undefined' && sortField[_sortField] === -1 ? 1 : -1;
        const sort = {
            [_sortField]: direction,
        }
        setSortField(sort);
        const unSortedArr = workOrders;
        // eslint-disable-next-line array-callback-return
        await unSortedArr.sort((a, b) => {
            if (direction === 1) {
                return a[1].workOrdersCount[_sortField] - b[1].workOrdersCount[_sortField];
            } else if (direction === -1) {
                return b[1].workOrdersCount[_sortField] - a[1].workOrdersCount[_sortField];
            }
        });
        setWorkOrders(unSortedArr);
    }

    const history = useHistory();
    const handleRowClick = (row) => {
        history.push(row);
    }
    useEffect(() => {
        getWorkOrders();
    }, []);



    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-9 my-building-wrapper">
                        <div className="card">
                            <div className="card-header d-flex justify-content-between pl-1 pr-1">
                                <h5>Work Orders</h5>
                                <Guard
                                    serviceName="workOrders"
                                    action="create"
                                    allow={() =>
                                        <div>
                                            {workOrderCreateAbility.bcomplaint && <Link to='/work-orders/create'>
                                                <button className="btn btn-primary ml-3 mt-1 text-white px-3">
                                                    <i className="fa fa-plus"></i>
                                                    <span> Create</span>
                                                </button>
                                            </Link>
                                            }
                                            {workOrderCreateAbility.visitt && <Link to='/work-orders/create-visitt'>
                                                <button className="btn btn-primary ml-3 mt-1 text-white px-3">
                                                    <i className="fa fa-plus"></i>
                                                    <span> Create (Visitt)</span>
                                                </button>
                                            </Link>
                                            }
                                        </div>
                                    } />
                            </div>
                            <div className="my-building">
                                <div className="card-body pl-1 pr-1">
                                    <div className="table-responsive">
                                        <table className="table table-borderless">
                                            <thead>
                                                <tr>
                                                    <th scope="col" style={{ whiteSpace: 'pre', width: '30%', textAlign: 'left' }}>Address </th>
                                                    <th scope="col" style={{ width: '10%', textAlign: 'center' }}>
                                                        <Link to={`/work-orders/list?status=Open`} className="text-dark">Open</Link>
                                                        <i className={`fa ml-1 fa-sort-numeric-${sortField.Open === -1 ? 'desc' : 'asc'}`} onClick={() => handleSort('Open')} style={{ cursor: "pointer" }} />
                                                    </th>
                                                    <th scope="col" style={{ width: '14%', textAlign: 'center' }}>
                                                        <Link to={`/work-orders/list?status=On Hold`} className="text-dark">On Hold</Link>
                                                        <i className={`fa ml-1 fa-sort-numeric-${sortField.OnHold === -1 ? 'desc' : 'asc'}`} onClick={() => handleSort('OnHold')} style={{ cursor: "pointer" }} />
                                                    </th>
                                                    <th scope="col" style={{ width: '14%', textAlign: 'center' }}>
                                                        <Link to={`/work-orders/list?status=In Progress`} className="text-dark">In Progress</Link>
                                                        <i className={`fa ml-1 fa-sort-numeric-${sortField.InProgress === -1 ? 'desc' : 'asc'}`} onClick={() => handleSort('InProgress')} style={{ cursor: "pointer" }} />
                                                    </th>
                                                    <th scope="col" style={{ width: '16%', textAlign: 'center' }}>
                                                        <Link to={`/work-orders/list?status=Completed`} className="text-dark">Completed</Link>
                                                        <i className={`fa ml-1 fa-sort-numeric-${sortField.Completed === -1 ? 'desc' : 'asc'}`} onClick={() => handleSort('Completed')} style={{ cursor: "pointer" }} />
                                                    </th>
                                                    <th scope="col" style={{ width: '16%', textAlign: 'center' }}>
                                                        <Link to={`/work-orders/list?status=Canceled`} className="text-dark">Canceled</Link>
                                                        <i className={`fa ml-1 fa-sort-numeric-${sortField.Canceled === -1 ? 'desc' : 'asc'}`} onClick={() => handleSort('Canceled')} style={{ cursor: "pointer" }} />
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    workOrders.map((item, index) => {
                                                        const workOrder = item[1];
                                                        if (!workOrder) return null;
                                                        return (
                                                            <tr key={index}>
                                                                <td style={{ textAlign: 'left', cursor: 'pointer' }} className="pl-0" onClick={() => handleRowClick(`/work-orders/list?bins=${workOrder.bin}`)}>
                                                                    <span className="text-dark">
                                                                        <i className="fa fa-building"></i> {workOrder.display_address ? workOrder.display_address : workOrder.address}
                                                                    </span>
                                                                </td>
                                                                {workOrder.workOrdersCount.Open > 0 && <td style={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => handleRowClick(`/work-orders/list?bins=${workOrder.bin}&status=Open`)}><span className="badge badge-wo-open">{workOrder.workOrdersCount.Open}</span></td>}
                                                                {workOrder.workOrdersCount.Open <= 0 && <td style={{ textAlign: 'center' }}><span className="text-black-50">0</span></td>}

                                                                {workOrder.workOrdersCount.OnHold > 0 && <td style={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => handleRowClick(`/work-orders/list?bins=${workOrder.bin}&status=On Hold`)}><span className="badge badge-wo-onhold">{workOrder.workOrdersCount.OnHold}</span></td>}
                                                                {workOrder.workOrdersCount.OnHold <= 0 && <td style={{ textAlign: 'center' }}><span className="text-black-50">0</span></td>}

                                                                {workOrder.workOrdersCount.InProgress > 0 && <td style={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => handleRowClick(`/work-orders/list?bins=${workOrder.bin}&status=In Progress`)}><span className="badge badge-wo-inprogress">{workOrder.workOrdersCount.InProgress}</span></td>}
                                                                {workOrder.workOrdersCount.InProgress <= 0 && <td style={{ textAlign: 'center' }}><span className="text-black-50">0</span></td>}

                                                                {workOrder.workOrdersCount.Completed > 0 && <td style={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => handleRowClick(`/work-orders/list?bins=${workOrder.bin}&status=Completed`)}><span className="badge badge-wo-completed">{workOrder.workOrdersCount.Completed}</span></td>}
                                                                {workOrder.workOrdersCount.Completed <= 0 && <td style={{ textAlign: 'center' }}><span className="text-black-50">0</span></td>}

                                                                {workOrder.workOrdersCount.Canceled > 0 && <td style={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => handleRowClick(`/work-orders/list?bins=${workOrder.bin}&status=Canceled`)}><span className="badge badge-wo-canceled">{workOrder.workOrdersCount.Canceled}</span></td>}
                                                                {workOrder.workOrdersCount.Canceled <= 0 && <td style={{ textAlign: 'center' }}><span className="text-black-50">0</span></td>}
                                                            </tr>
                                                        );
                                                    })}
                                                {workOrders.length <= 0 && !loading && (
                                                    <tr>
                                                        <td colSpan={7}>
                                                            No work order available
                                                        </td>
                                                    </tr>
                                                )}
                                                {!loading && (
                                                    <tr>
                                                        <td colSpan={7}>
                                                            <div className="loadercenter"> <ReactLoading type={"cylon"} color={"grey"} /> </div>
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 recent-activity pr-0">
                        <RecentActivity />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default WorkOrders;
