import React, { useEffect, useState } from 'react';
import { Pie, Bar } from 'react-chartjs-2';

import './summaryPie.css';
import { random_rgb } from '../../../services/helperService';

const SummaryPie = ({ totalData }) => {

  const [firstPieData, setFirstPieData] = useState({})
  const [secondPieData, setSecondPieData] = useState({})
  const [thirdPieData, setThirdPieData] = useState({})
  const [persantageOption, setPersantageOption] = useState({})
  const [pieData4, setPieData4] = useState({})
  useEffect(() => {
    if (totalData) {


      setFirstPieData({
        labels: ['RECEIVED', 'NOT RECEIVED'],
        datasets: [
          {
            data: [totalData.totalRecived, totalData.totalSent - totalData.totalRecived],
            backgroundColor: ['rgba(75, 192, 192, 0.4)', 'rgba(255, 99, 132, 0.4)'],
            borderColor: ['rgba(75, 192, 192, 1)', 'rgba(255, 99, 132, 1)'],
          },
        ],
      })

      const labelsN = [...Object.keys(totalData.totalRecivedByType), 'not received'].map(el => el?.toUpperCase())
      setSecondPieData({
        labels: labelsN,
        datasets: [
          {
            data: [
              ...Object.keys(totalData.totalRecivedByType).map(key => totalData.totalRecivedByType[key]),
              totalData.totalSent - totalData.totalRecived,
            ],
            backgroundColor: [
              'rgba(75, 192, 192, 0.4)',
              'rgba(153, 102, 255, 0.4)',
              'rgba(255, 159, 64, 0.4)',
              'rgba(255, 99, 132, 0.4)',
              'rgba(143, 188, 143, 0.4)',
            ],
            borderColor: [
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)',
              'rgba(255, 99, 132, 1)',
              'rgba(143, 188, 143, 1)',
            ],
          },
        ],
      })


      let labels = Object.keys(totalData.totalByAddresses);
      labels.sort((b, a) => totalData.totalByAddresses[a] - totalData.totalByAddresses[b]);
      labels = labels.slice(0, 5)

      const values = labels.map((el) => totalData.totalByAddresses[el]);

      const backgroundColor = labels.map((el, i) => random_rgb(0.4, i));
      const borderColor = backgroundColor.map((el) => el.replace('0.4', '1'));


      setPersantageOption({
        legend: {
          labels: {
            fontSize: 9, // Small font size for legend labels
          },
        },

        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              var dataset = data.datasets[tooltipItem.datasetIndex];
              var meta = dataset._meta[Object.keys(dataset._meta)[0]];
              var total = meta.total;
              var currentValue = dataset.data[tooltipItem.index];
              var percentage = parseFloat(
                ((currentValue / total) * 100).toFixed(1)
              );
              return currentValue + ' (' + percentage + '%)';
            },
            title: function (tooltipItem, data) {
              return data.labels[tooltipItem[0].index];
            },
          },
        },
      })

      setThirdPieData({
        data: {
          labels,
          datasets: [
            {
              data: values,
              backgroundColor,
              borderColor,
            },
          ],
        },
        labels,
      })

      let labels4 = Object.keys(totalData.totalByActions)


      const values4 = labels4.map((el) => totalData.totalByActions[el]);

      setPieData4({
        labels: labels4,
        datasets: [
          {
            data: values4,
            backgroundColor: [
              'rgba(75, 192, 192, 0.4)',
              'rgba(153, 102, 255, 0.4)',
              'rgba(255, 159, 64, 0.4)',
              'rgba(255, 99, 132, 0.4)',
              'rgba(143, 188, 143, 0.4)',
            ],
            borderColor: [
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)',
              'rgba(255, 99, 132, 1)',
              'rgba(143, 188, 143, 1)',
            ],
          },
        ],
      })

    }
  }, [totalData])

  return (
    <div className="d-flex justify-content-between flex-wrap ">

      <div >
        <Pie data={firstPieData} options={persantageOption} />
      </div>
      {!!pieData4.labels?.length && <div style={{ width: '350px' }}>
        <Bar data={pieData4} options={{
          scales: {
            // xAxes: [
            //   {
            //     display: false,
            //   },
            // ],
            yAxes: [
              {
                offset: true,
                ticks: {
                  beginAtZero: true,
                  stepSize: 1,
                  callback: function (value, index, ticks) { 
                    return ticks.length > 5 ? (index % 2 ? '' : value) : value;
                  },
                },
              },
            ],
          },
          legend: {
            display: false, //Is the legend shown?
            position: "bottom" //Position of the legend.
          }
        }} />

      </div>}
      <div >
        <Pie data={secondPieData} options={persantageOption} />
      </div>


      <div>
        <div className='text-center m-1' style={{ fontSize: '11px' }}>TOP 5 BUILDINGS</div>
        <Pie
          data={thirdPieData.data}
          options={{
            ...persantageOption,

            legend: {
              ...(persantageOption.legend || {}),
              display: false
            },

          }}
        />
      </div>

    </div>
  );
};

export default SummaryPie;
