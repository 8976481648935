import { workOrderExpireMessageKey } from "../../services/authService";
import { useState } from 'react';

const ExpireAlertMessage = ({ servicesAccess, jestData = false }) => {
    const [displayMessage, setDisplayMessage] = useState(jestData ? jestData : JSON.parse(localStorage.getItem(workOrderExpireMessageKey)));
    const daysRemaining = servicesAccess.workOrders?.dayRemaining

    const handleAlertClose = () => {
        localStorage.setItem(workOrderExpireMessageKey, false);
        setDisplayMessage(false);
    }

    if (displayMessage) {
        return (
            <div className="pt-3">
                <div className="alert alert-danger mb-0 text-center font-weight-bold" role="alert">
                    Your access to Work Orders service will expire in {daysRemaining} days. Please contact support for the renewal.
                    <span>
                        <button className="btn btn-xs" onClick={() => handleAlertClose()}>
                            <i className="fa fa-times"></i>
                        </button>
                    </span>
                </div>
            </div>
        )
    }
    return null;
}

export default ExpireAlertMessage;