import React, { useState } from 'react';
import { Input } from 'antd';
import { LinkOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import './LinkPicker.css'
import { useEffect } from 'react';

const LinkPicker = ({ onChange, data = {} }) => {

    const [value, setValue] = useState(data.value || '');
    const [displayValue, setDisplayValue] = useState(data.displayValue || '');

    useEffect(() => {
    
        setDisplayValue(data.displayValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className='d-flex justify-content-between align-items-center pl-2   link-td'>
            <div className='link-block text-center w-100'>
                <a href={value.includes('http') ? value : 'http://' + value} target='blank'>{displayValue}</a>
            </div>
            <Popover
                trigger="click"
                onVisibleChange={(val) => !val && onChange && onChange({ value, displayValue })}
                content={
                    <div className='p-1 mb-2'>
                        <div className='h5'><strong>Link</strong></div>
                        <div className='mb-1 h6'>Write or paste link</div>
                        <Input placeholder="www.example.com" onChange={(event) => setValue(event.target.value)} value={value} />

                        <div className='mt-3 mb-1 h6'>Text to display</div>
                        <Input placeholder="display text" onChange={(event) => {
                            setDisplayValue(event.target.value)
                        }} value={displayValue} />
                    </div>
                }
            >
                <div className='d-flex justify-content-end w-100 h-100 align-items-center pr-2 pl-2 icon-block'>
                    <LinkOutlined />
                </div>
            </Popover>
        </div>


    );
};

export default LinkPicker;
