import React, { Fragment, useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import auth from '../../../services/authService';
import creApi from '../../../services/creService';
import RecentActivityListGroupCollapse from './recentActivityListGroupCollapse';

const user = auth.getCurrentUser();

const HistoryUpdates = (props) => {
  const history = useHistory();

  const [doneLoading, setLoadingState] = useState(
    props.jestData === undefined ? false : true
  );
  const [data, setData] = useState(
    props.jestData === undefined ? [] : props.jestData
  );
  const [page, setPage] = useState(1);
  const [loadingHistory, setLoadingHistory] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await creApi.getHistoryUpdates(page);
        setLoadingState(true);
        let dataArray = [];
        // eslint-disable-next-line
        Object.keys(response.data).map((key) => {
          dataArray.push(response.data[key]);
        });
        setData([...data, ...dataArray]);
        setLoadingHistory(false);
      } catch (e) {
        toast.error(e.message);
      }
    }
    fetchData();
    // eslint-disable-next-line
  }, [page]);

  useEffect(
    () => () => {
      //console.log("unmount")
    },
    []
  );

  function handleClick(activityRow) {
    history.push(
      `/violation-details/${activityRow.violation_id}/notes`
    );
  }

  function handleLoadMore() {
    let nextPage = page + 1;
    setPage(nextPage);
    setLoadingHistory(true);
  }

  return (
    <Fragment>
      <div className="card user-card">
        <div className="card-header">
          <div className="online-user">
            <h5 className="f-16 mb-0">Recent Activity</h5>
          </div>
        </div>
        <div className={'card-body activity-scroll'}>
          <div className="activity">
            {!doneLoading ? (
              <div align="center">
                {' '}
                <ReactLoading type={'cylon'} color={'grey'} />{' '}
              </div>
            ) : data.length > 0 ? (
              <>
                <div>
                  {data.map((buildingData, i) =>
                    buildingData.map((violation, index) => {
                      let activityRow = violation.violationHistoryDetails[0];
                      let VPUHistory = activityRow.VPUHistory?.find((el) =>
                        el.clients.includes(user._id)
                      );
                      if (
                        activityRow &&
                        Object.keys(activityRow.changesDetected).length > 0
                      ) {
                        return (
                          <div
                            className="media badge-light mb-3 flex-column"
                            key={index}
                          >
                            <div className="w-100">
                              <div className="media-body">
                                <div className="d-flex mb-3 justify-content-between">
                                  <span
                                    onClick={() => handleClick(activityRow)}
                                    className="font-weight-bold pointer fs-14"
                                  >
                                    {violation.fullAddress} /{' '}
                                    {activityRow.category}
                                  </span>
                                </div>
                                <div className="mb-2">
                                  <span className="font-weight-normal badge">
                                    {activityRow.category}/
                                    {activityRow.sub.replace(/_/g, ' ')}
                                    <br />
                                    <small>
                                      {activityRow.uniqueData?.displayAs}
                                      {activityRow.uniqueData?.value?.length > 25
                                        ? activityRow.uniqueData.value
                                          .substr(0, 25)
                                          .concat('...')
                                        : activityRow.uniqueData.value}
                                    </small>
                                  </span>

                                  <span className="badge badge-secondary">
                                    Changes Detected
                                  </span>
                                  <div className="clearfix"></div>
                                  {activityRow.manuallyChangedBy && !activityRow.sub?.includes('Closed') && <div className='badge pt-1'>Changed by {activityRow.manuallyChangedBy.name}</div>}
                                </div>

                                <div className="d-flex justify-content-between">
                                  <span className="f-14 filling-status font-weight-bold">
                                    View Changes
                                  </span>
                                  <span className="f-14">
                                    {violation.added_at}
                                  </span>
                                </div>

                                <ul className="list-group">
                                  {Object.keys(activityRow.changesDetected).map(
                                    function (item, index) {
                                      return (
                                        <RecentActivityListGroupCollapse
                                          key={index}
                                          Data={{
                                            title: item,
                                            titleKey: item,
                                            difference:
                                              activityRow.changesDetected[item],
                                          }}
                                        />
                                      );
                                    }
                                  )}

                                  <RecentActivityListGroupCollapse
                                    VPU={VPUHistory}
                                  />
                                </ul>
                              </div>
                            </div>
                          </div>
                        );
                      } else {
                        return (
                          <div
                            className="media badge-light mb-3 ht flex-column"
                            key={index}
                          >
                            <div className="media-body w-100">
                              <div className="d-flex mb-3 justify-content-between">
                                <span
                                  onClick={() => handleClick(activityRow)}
                                  className="font-weight-bold pointer fs-14"
                                >
                                  {violation.fullAddress} /{' '}
                                  {activityRow.category}
                                </span>
                              </div>

                              <div className="mb-2">
                                <span className="font-weight-normal badge">
                                  {activityRow.category}/
                                  {activityRow.sub.replace(/_/g, ' ')}
                                  <br />
                                  <small>
                                    {activityRow.uniqueData?.displayAs}
                                    {activityRow.uniqueData?.value?.length > 25
                                      ? activityRow.uniqueData?.value
                                        .substr(0, 25)
                                        .concat('...')
                                      : activityRow.uniqueData?.value}
                                  </small>
                                </span>
                                <span className="badge text-white filling-status-btn">
                                  New Record
                                </span>
                                <div className="clearfix"></div>
                              </div>
                              <div className="d-flex mb-0 justify-content-between position-relative mb-3">
                                <span className="f-14 filling-status font-weight-bold"></span>
                                <span className="f-14">
                                  {violation.added_at}
                                </span>
                              </div>
                            </div>
                            <ul className="list-group w-100">
                              <RecentActivityListGroupCollapse
                                VPU={VPUHistory}
                              />
                            </ul>
                          </div>
                        );
                      }
                    })
                  )}
                </div>
                {!loadingHistory ? (
                  <div className="d-flex justify-content-center">
                    <button onClick={handleLoadMore} className="btn btn-link">
                      Load More
                    </button>
                  </div>
                ) : (
                  <div className="d-flex justify-content-center">
                    <ReactLoading type={'bubbles'} height={15} color={'grey'} />
                  </div>
                )}
              </>
            ) : (
              <span className="font-weight-light text-muted">
                No Activity yet
              </span>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default HistoryUpdates;
