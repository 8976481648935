import React, { Fragment } from 'react';
import ReactLoading from 'react-loading';

const ViolationsTabEachBlock = (props) => {
  function onClick(currentValue) {
    if (currentValue[1] !== 0 && currentValue[1] !== '$0.00') {
      localStorage.setItem('prev-location', '/violations-tab');
      props.history.push(
        `/violations-tab-details/${props.title}/${currentValue[0]}`
      );
    }
  }

  return (
    <Fragment>
      <div className="col-md-12">
        <div className="card fix-card flex-fill">
          <div className="card-body">
            <div className="media-body row">
              <div className="col-12 col-md-3">
                <h4>{props.title}</h4>
              </div>
              {!props.loading ? (
                Object.entries(props.data).map((value, index) => {
                  return (
                    <div key={index + value} className="col-6 col-md-3">
                      <h6
                        className={
                          value[1] !== 0 && value[1] !== '$0.00'
                            ? 'pointer'
                            : ''
                        }
                        onClick={() => onClick(value)}
                      >
                        {value[0]}: <span>{value[1]}</span>{' '}
                      </h6>
                    </div>
                  );
                })
              ) : (
                <div align="center">
                  {' '}
                  <ReactLoading type={'cylon'} color={'grey'} />{' '}
                </div>
              )}
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ViolationsTabEachBlock;
