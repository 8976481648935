import React, { Fragment, useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import { toast } from 'react-toastify';
import moment from 'moment';

import creApi from '../../../services/creService';
import UserActivityListGroupCollapse from './userActivityListGroupCollapse';



const HistoryUpdates = ({ userId }) => {


    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [loadingHistory, setLoadingHistory] = useState(false);


    async function fetchData() {
        try {
            setLoadingHistory(true);
            const response = await creApi.getClientUpdateHistory(userId, page);
            if (response.data.status) {
                setData([...data, ...response.data.data])
            } else {
                toast.error(response.data.message);
            }

        } catch (e) {
            toast.error(e.message);
        }
        setLoadingHistory(false);
    }

    useEffect(() => {

        fetchData();
        // eslint-disable-next-line
    }, [page]);


    function handleLoadMore() {
        let nextPage = page + 1;
        setPage(nextPage);
        setLoadingHistory(true);
    }

    return (
        <Fragment>
            <div className="card ">
                <div className="card-header">
                    <div className="online-user">
                        <h5 className="f-16 mb-0">Activity</h5>
                    </div>
                </div>
                <div className={'card-body activity-scroll'}>
                    <div className="activity ">
                        {loadingHistory ? (
                            <div align="center">
                                {' '}
                                <ReactLoading type={'cylon'} color={'grey'} />{' '}
                            </div>
                        ) : data.length > 0 ? (
                            <>
                                <div>
                                    {data.map((activity, i) => {

                                        return (
                                            <div
                                                className="media badge-light mb-3 flex-column"
                                                key={activity._id}
                                            >
                                                <div className="w-100">
                                                    <div className="media-body p-2">

                                                        <div className="mb-2">
                                                            <small className=' pt-1'>
                                                                Changed By: {activity.changedByClient ? activity.changedByClient?.fullName : (activity.changedByAdmin?.name || 'removed client')}
                                                            </small>
                                                        </div>

                                                        <div className="d-flex justify-content-between mb-2">

                                                            <small >
                                                                Changed At: {moment(new Date(activity.added_at)).format('MMMM Do YYYY, h:mm:ss a')}
                                                            </small>
                                                        </div>

                                                        <ul className="list-group">
                                                            {Object.keys(activity.difference).map(
                                                                (key, index) =>
                                                                    <UserActivityListGroupCollapse
                                                                        key={key + index}
                                                                        data={{
                                                                            title: key,
                                                                            difference: activity.difference[key],
                                                                        }}
                                                                    />
                                                            )}

                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                                {!loadingHistory ? (
                                    <div className="d-flex justify-content-center">
                                        <button onClick={handleLoadMore} className="btn btn-link">
                                            Load More
                                        </button>
                                    </div>
                                ) : (
                                    <div className="d-flex justify-content-center">
                                        <ReactLoading type={'bubbles'} height={15} color={'grey'} />
                                    </div>
                                )}
                            </>
                        ) : (
                            <span className="font-weight-light text-muted">
                                No Activity yet
                            </span>
                        )}
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default HistoryUpdates;
