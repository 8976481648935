import React from 'react';
import moment from 'moment';

const CofCProgress = ({ cofc = {} }) => {

    const checkedCofC = Object.keys(cofc).filter(key => cofc[key].isChecked);
    if (checkedCofC.length <= 0) return null;

    return (
        <div className='card-body mt-4'>
            <div className='ecb-head ecb-sub-head'>
                <h5>BComplaint Certificate of Correction Progress</h5>
                <div className="row">
                    {checkedCofC.map((cofcType, i) => (
                        <div className="col-4" key={`${cofcType}_${i}`}>
                            <h6 className='mb-1 text-capitalize font-weight-bold'>
                                {cofcType.replace(/_/g, ' ')}
                                <br/>
                                <small className='text-muted'>Updated at: {moment.utc(cofc[cofcType].updatedAt).format('MM/DD/YYYY')}</small>
                            </h6>
                            {cofc[cofcType].followups?.map((followUp) => (
                                <p className='mb-0'>- Follow Up {followUp.followUpNumber}: &nbsp;{moment.utc(followUp.date).format('MM/DD/YYYY')}</p>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default CofCProgress;
