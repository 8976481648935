import React, { Fragment, useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router";
import TableViolation from '../../common/table-violation/tableViolation.jsx';
import creService from "../../services/creService";


const TenantsList = () => {
  const params = useParams();
  const [selectedQuestionnaire, setSelectedQuestionnaire] = useState()
  const [questionnaires, setQuestionnaires] = useState([]);
  const history = useHistory();
  const [dataForTable, setDataForTable] = useState({});


  useEffect(() => {
    if (selectedQuestionnaire) {
      fetchQuestionnaireAnswers(selectedQuestionnaire._id)
    } else {
      setDataForTable({})
    }


  }, [selectedQuestionnaire]);

  useEffect(() => {
    fetchQuestionnaires()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  const fetchQuestionnaires = async () => {
    try {
      const { data } = await creService.getQuestionaryDataClientList();

      if (data.status) {
        setQuestionnaires(data.data);
        if (!selectedQuestionnaire) {
          setSelectedQuestionnaire(data.data.find(el => el.blockName === params.sub) || data.data[0] )
      }
      } else {
        toast.error(data.message);
      }
    } catch (_err) {
      toast.error(_err.message);
    }
  };

  const handleMenuClick = async (questionnaire) => {
    setSelectedQuestionnaire(questionnaire)
    history.push(`/questionnaire/${questionnaire.blockName}`)
}


  const fetchQuestionnaireAnswers = async (id) => {
    try {
      const { data } = await creService.getQuestionaryDataClientListAnswers(id);
      // questionnaires
      // const columns = data.columns
      // const linkedKey = columns[1] ? columns[1].key : columns[0].key


      if (data.status) {
        // setData(data.data);
        setDataForTable({
          // posibleValues: compliancesRes.data.posibleValues,
          columns: data.columns,
          dataSource: data.items,
          customFieldData: {},
          category: 'Questionnaire',
          sub: data.name,
          options: {
            links: {
              details: `/questionnaire/${data.name}/:record._id`
            }
          },
        })
      } else {
        toast.error(data.message);
      }
    } catch (_err) {
      toast.error(_err.message);
    }
  };

  return (
    <Fragment>
      <div className="row violation-sec">
        <div className="col-xl-12  compliance">
          <div className="card">
            <div className="card-header row">
              <div className="ml-3">
                <h5>Questionnaire</h5>
              </div>
            </div>

            <div className="card-body">
              <div className="custom_tab tab-sec rounded">
                {questionnaires.length > 0 &&
                  questionnaires
                    .map((questionnaire) => (
                      <li
                        key={`custom_menu_${questionnaire.blockName}`}
                        className={`${selectedQuestionnaire?.blockName === questionnaire.blockName ? "active" : ""}`}
                        onClick={() => handleMenuClick(questionnaire)}>
                        {questionnaire.blockName}
                      </li>
                    )
                    )}
              </div>
            </div>
            <div className="card-body data-table">
              {dataForTable.dataSource?.length > 0 && <TableViolation
                saveOn={'questionnaire'}
                // removeFunction={guard({ serviceName: 'custom-compliance', action: 'compliance-remove-add-individual' }) ? removeFunction : null}
                data={dataForTable}
                customFieldsFromViolation={true}
                multiChangeAction={true}
              // currentArchive={currentArchive}
              // archiveFunction={archiveHandler}
              // advancedSearchDefault={advancedSearchDefault}

              />
              }

            </div>
          </div>
        </div>
      </div>
    </Fragment >
  );
};

export default TenantsList;
