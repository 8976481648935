import React, { Fragment, useState, useEffect } from "react";
import moment from 'moment'
import creService from "../../services/creService";
import { Doughnut } from "react-chartjs-2";
import Outstanding from "./OutstandingFines";
import SnapshotDataMenu from './snapshotDataMenu'
import Block from './block'


let type = 'Complaints'
const complaintsBlocks = [
  'DOB',
  'HPD',
  'DOT',
  'DEP',
  'DSNY',
  'DOHMH',
  '311'
]

const Complaints = (props) => {
  const [data, setData] = useState(props.jestData !== undefined ? props.jestData.data : {
    "DOB": '',
    "HPD": '',
    "DOT": '',
    "DEP": '',
    "DSNY": '',
    "DOHMH": '',
    '311': ''
  });
  const [snapshotData, setSnapshotData] = useState()


  useEffect(() => {
    if (props.selectedDate) {
      const date = moment(new Date(props.selectedDate)).format('L')
      creService.getDashboardDataSnapshot(type, complaintsBlocks, date).then((res) => {
        setSnapshotData(res.data);
      })
    }
  }, [props.selectedDate])

  useEffect(() => {

    creService.getDashboardData(type).then((res) => {
      const data = complaintsBlocks.reduce((ac, a) => {
        ac[a] = {
          total: res.data[a]?.totalAllTime,
        }
        return ac
      }, {})
      setData(data);
    })
  }, [])





  const doughnutData = {
    labels: complaintsBlocks,
    datasets: [{
      data: complaintsBlocks.map(el => data[el]?.total),
      backgroundColor: [
        '#edc536',
        '#ce5370',
        '#a3437b',
        '#ff81ca',
        '#f69746',
        '#eb6f5e'
      ],
      hoverBackgroundColor: [
        '#edc536',
        '#ce5370',
        '#a3437b',
        '#ff81ca',
        '#f69746',
        '#eb6f5e'
      ]
    }]
  };

  function dashboardDetails(sub) {
    props.history.push(`/dashboard/${type}/${sub}`)
  }

  return (
    <Fragment>
      <div className="row">
        <div className="col-md-6">
          <div className="row">
            {complaintsBlocks.map(el =>
              <Fragment key={el}>
                <Block
                  sub={el}
                  data={data}
                  dashboardDetails={dashboardDetails}
                  snapshotData={snapshotData}
                  selectedDate={props.selectedDate}
                  snapshotOnClickHandler={(sub) => props.snapshotOnClickHandler(sub, type)}
                />
              </Fragment>
            )}
          </div>
          <div className='d-flex justify-content-end'>
            <SnapshotDataMenu selectedDate={props.selectedDate} setSelectedDate={(date) => { props.setSelectedDate(date); setSnapshotData() }} />
          </div>
        </div>
        <div className="col-md-6">
          <Outstanding history={props.history} />
          <div className="pie-chart">
            <Doughnut
              data={doughnutData}
              options={{
                responsive: true,
                maintainAspectRatio: true,
                legend: {
                  display: true,
                  position: "bottom"
                },
              }}
              width={1000}
              height={900}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Complaints;
