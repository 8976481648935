import React, { Fragment, useEffect, useState } from 'react';
import creApi from "../../../services/creService";
import { toast } from "react-toastify";
import ReactLoading from "react-loading";
import moment from "moment";

import RecentWorkListCollapse from './recentWorkList';
import { useHistory } from "react-router-dom";

const RecentActivity = (props) => {

    const history = useHistory();

    const [isLoading, setIsLoading] = useState(false);
    const [activityData, setActivityData] = useState(props.jestData === undefined ? [] : props.jestData);
    const [page, setPage] = useState(1);

    async function fetchData(page) {
        setIsLoading(true);
        try {
            const { data } = await creApi.getRecentWorkActivity(page);
            if (data.status) {
                const dataArray = [data.data];
                setActivityData([...activityData, ...dataArray]);
            } else {
                toast.error(data.message);
            }
            setIsLoading(false);
        } catch (e) {
            toast.error(e.message);
        }
    }

    const handleClick = (workOrderId) => {
        history.push(`/work-orders/view/${workOrderId}`)
    }

    useEffect(() => {
        fetchData(page);
        // eslint-disable-next-line
    }, [page]);

    function handleLoadMore() {
        let nextPage = page + 1;
        setPage(nextPage);
    }


    return (
        <Fragment>
            <div className="card user-card">
                <div className="card-header mt-2">
                    <div className="online-user"><h5 className="f-16 mb-0">Recent Activity</h5></div>
                </div>
                <div className={"card-body activity-scroll"}>
                    <div className="activity">
                        {activityData.length > 0 && activityData?.map((workData, i) => <Fragment key={i}>
                            {workData?.map((recentWork, index) => {
                                if (recentWork?.data?.workOrderCreated) {
                                    return (<div className="media badge-light mb-3 ht" key={index}>
                                        <div className="media-body" >
                                            <div onClick={() => handleClick(recentWork.workOrderId)} className="font-weight-bold pointer d-flex align-items-center justify-content-between">
                                                <span>#{recentWork.workOrder.workOrderId} ({recentWork.workOrder.category})</span>
                                                <span className="badge text-white filling-status-btn p-1">New Record</span>
                                            </div>
                                            <div>
                                                {recentWork.workOrder.title}
                                            </div>
                                            <div className="d-flex mb-0 justify-content-between position-relative mb-3">
                                                <span className="f-14 filling-status font-weight-bold"></span>
                                                <span className="f-14">{moment(new Date(recentWork.addedAt)).format('MMMM DD,  YYYY')}</span>
                                            </div>
                                        </div>
                                    </div>)
                                } else {
                                    return (
                                        <div className="media badge-light mb-3" key={index}>
                                            <div className="media-body" >
                                                <div onClick={() => handleClick(recentWork.workOrderId)} className="font-weight-bold pointer d-flex align-items-center justify-content-between">
                                                    <span>#{recentWork.workOrder.workOrderId} ({recentWork.workOrder.category})</span>
                                                    <span className="badge badge-secondary p-1">Changes Detected</span>
                                                </div>
                                                <div>
                                                    {recentWork.workOrder.title}
                                                </div>

                                                <div className="d-flex justify-content-between">
                                                    <span className="f-14 filling-status font-weight-bold">View Changes</span>
                                                    <span className="f-14">{moment.utc(new Date(recentWork.addedAt)).format("MM/DD/YYYY")}</span>
                                                </div>

                                                <ul className="list-group">
                                                    {Object.keys(recentWork.data).map(function (item, index) {
                                                        return <RecentWorkListCollapse key={index} data={{
                                                            title: item,
                                                            field: item,
                                                            difference: recentWork.data[item]
                                                        }} />
                                                    })}
                                                </ul>

                                            </div>
                                        </div>
                                    );
                                }
                            })}
                        </Fragment>)}
                    </div>
                    {!isLoading && <div className="d-flex justify-content-center">
                        <button onClick={handleLoadMore} className="btn btn-link">Load More</button>
                    </div>}
                    {isLoading && <div className="d-flex justify-content-center">
                        <ReactLoading type={"bubbles"} height={15} color={"grey"} />
                    </div>}
                    {!isLoading && activityData.length <= 0 && <span className="font-weight-light text-muted">No Activity yet</span>}
                </div>
            </div>
        </Fragment>
    );
};

export default RecentActivity;