import React, { useState } from 'react';
import { Pie, Bar, Line } from 'react-chartjs-2';
import moment from 'moment';
import { useEffect } from 'react';
import { Select, Modal } from 'antd';

import './Charts.css';
import { random_rgb } from '../../../services/helperService';
import creService from '../../../services/creService';
import { toast } from 'react-toastify';

const testDate = '01/01/3000';

const tooltips = {
  callbacks: {
    label: (tooltipItem, data) => {
      var dataset = data.datasets[tooltipItem.datasetIndex];
      var meta = dataset._meta[Object.keys(dataset._meta)[0]];
      var total = meta.total;
      var currentValue = dataset.data[tooltipItem.index];
      var percentage = parseFloat(((currentValue / total) * 100).toFixed(1));
      return ' ' + currentValue + ' (' + percentage + '%)';
    },
    title: (tooltipItem, data) => {
      return data.labels[tooltipItem[0].index];
    },
  },
};

const dateParser = (stringDate, generalCountFormat) => {
  const date = moment.utc(new Date(stringDate));
  let format = 'MM/DD/YYYY';
  if (generalCountFormat === 'month') {
    format = 'MM/YYYY';
  } else if (generalCountFormat === 'year') {
    format = 'YYYY';
  }
  return date.isValid() ? date.format(format) : stringDate;
};

export const dateSorter = (dates, generalCountFormat, path) =>
  dates.sort((a, b) => {
    let akeys;
    let bkeys;
    if (path) {
      akeys = a[path]?.includes('/') ? a[path].split('/') : testDate.split('/');
      bkeys = b[path]?.includes('/') ? b[path].split('/') : testDate.split('/');
    } else {
      akeys = a.split('/');
      bkeys = b.split('/');
    }

    let ayear = akeys[2];
    let amonth = akeys[0];
    let aday = akeys[1];
    let byear = bkeys[2];
    let bmonth = bkeys[0];
    let bday = bkeys[1];

    if (generalCountFormat === 'month') {
      amonth = akeys[0];
      bmonth = bkeys[0];
      ayear = akeys[1];
      byear = bkeys[1];
      bday = 0;
      aday = 0;
    } else if (generalCountFormat === 'year') {
      amonth = 0;
      bmonth = 0;
      ayear = akeys[0];
      byear = bkeys[0];
      bday = 0;
      aday = 0;
    }
    return new Date(ayear, amonth, aday) - new Date(byear, bmonth, bday);
  });

const getValueKey = (column, values) => {
  if (column.data?.bcType === 'colors') {
    const found = column.data?.data?.find?.(
      (colorLabel) => colorLabel.id === values[column.key]
    );
    return found?.text || 'no label';
  } else if (column.data?.type === 'checkBox') {
    return values[column.key] ? 'checked' : 'unchecked';
  } else if (column.data?.bcType === 'users') {
    const found = column.subUsers?.find((el) => el._id === values[column.key]);
    return found?.fullName || 'no value';
  } else if (column.data?.bcType === 'vendors') {
    const found = column.vendors?.find((el) => el._id === values[column.key]);
    return found?.fullName || 'no value';
  }
  return values[column.key] || 'no value';
};

const getData = (
  column,
  dataSource,
  uniqueKeys,
  generalCountFormat,
  additionalData,
  isDashBoard
) => {
  if (column.key === uniqueKeys?.identifierField) {
    const datasets = [];
    let biggestValue = 0;
    let keyedData = {};
    if (isDashBoard) {
      dataSource.reduce((ac, a) => {
        if (a[uniqueKeys.reportUseDate]) {
          const key = dateParser(
            a[uniqueKeys.reportUseDate],
            generalCountFormat
          );
          if (ac[key]) {
            ac[key].add2 = ac[key].add2 + 1;
          } else {
            ac[key] = { add2: 1 };
          }
          biggestValue =
            biggestValue < ac[key].add2 ? ac[key].add2 : biggestValue;
        }
        return ac;
      }, keyedData);
    }

    for (let i = 0; i < additionalData.length; i++) {
      if (additionalData[i].length) {
        if (isDashBoard && i === 2) break;
        // eslint-disable-next-line no-loop-func
        additionalData[i].reduce((ac, a) => {
          const key = dateParser(a, generalCountFormat);
          if (ac[key]) {
            if (ac[key][`add${i}`]) {
              ac[key][`add${i}`] = ac[key][`add${i}`] + 1;
            } else {
              ac[key][`add${i}`] = 1;
            }
          } else {
            ac[key] = { [`add${i}`]: 1 };
          }
          biggestValue =
            biggestValue < ac[key][`add${i}`]
              ? ac[key][`add${i}`]
              : biggestValue;
          return ac;
        }, keyedData);
      }
    }

    let labels = Object.keys(keyedData).filter((el) => el !== '-');
    labels = dateSorter(labels, generalCountFormat);

    let closed = labels.map((el) => keyedData[el].add0 || 0);
    let opened = labels.map((el) => keyedData[el].add1 || 0);
    const values = labels.map((el) => keyedData[el].add2 || 0);

    datasets.push({
      label: 'Currently Open',
      fill: false,
      data: values,
      backgroundColor: 'rgb(239,155,32)',
      borderColor: 'rgba(239,155,32, 0.5)',
    });
    datasets.push({
      label: 'All Closed',
      fill: false,
      data: closed,
      backgroundColor: '#cccccc',
      borderColor: '#cccccc',
    });
    datasets.push({
      label: 'All Issued',
      fill: false,
      data: opened,
      backgroundColor: 'rgb(234, 85, 69)',
      borderColor: 'rgba(234, 85, 69, 0.5)',
    });

    return [
      biggestValue,
      {
        labels,
        datasets,
      },
    ];
  } else {
    let isDate = null;
    const keyedData = dataSource.reduce((ac, a) => {
      let key = getValueKey(column, a);
      if (key === 'no label') return ac;
      if (isDate === null) {
        const testDate = moment.utc(key);
        if (testDate.isValid()) {
          isDate = true;
        } else {
          isDate = false;
        }
      }
      key = dateParser(key, column.chartTypeTicks); 
      if (ac[key]) {
        ac[key] = ac[key] + 1;
      } else {
        ac[key] = 1;
      }
      return ac;
    }, {});

    let labels = Object.keys(keyedData).filter((el) => el !== '-');

    if (isDate) {
      labels = dateSorter(labels);
    }

    const values = labels.map((el) => keyedData[el] || 0);

    const backgroundColor = labels.map((el, i) => random_rgb(0.4, i));
    const borderColor = backgroundColor.map((el) => el.replace('0.4', '1'));

    return [
      0,
      {
        labels,
        datasets: [
          {
            fill: false,
            data: values,
            backgroundColor,
            borderColor,
          },
        ],
      },
    ];
  }
};

const ViolationsTableCharts = ({
  columns,
  dataSource,
  uniqueKeys,
  dashboard,
  bin,
  category,
  sub,
}) => {
  const [generalCountFormat, setGeneralCountFormat] = useState('month');
  const [additionalData, setAdditionalData] = useState([]);
  const displayCharts = columns.filter((column) => {
    const otherChart =
      column.chart && column.key !== uniqueKeys?.identifierField;
    const mainChart =
      !column.chart && column.key === uniqueKeys?.identifierField;
    return (
      (otherChart || mainChart) &&
      !column.hide &&
      column.key !== uniqueKeys?.reportUseDate
    );
  });
  const [isVisible, setIsVisible] = useState(false);
  const [selectedData, setSelectedData] = useState(null);

  const selectDataHandler = (data, type) => {
    setSelectedData({ data, type });
    setIsVisible(true);
  };

  useEffect(() => {
    if (category) {
      creService
        .getAdditionalDataMainChart(category, sub, bin, dashboard)
        .then((result) => {
          setAdditionalData(result.data.data);
        })
        .catch((err) => {
          toast.warn(err.message);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  if (displayCharts.length === 0 || dataSource.length === 0) {
    return null;
  }

  return (
    <div className="col-12">
      <div className="card charts-block">
        <div className="d-flex justify-content-between flex-wrap card-body">
          {displayCharts.map((column) => {
            const [biggestValue, data] = getData(
              column,
              dataSource,
              uniqueKeys,
              generalCountFormat,
              additionalData,
              dashboard
            );
            const isUnique = column.key === uniqueKeys?.identifierField;

            return (
              <div
                className={`text-center pb-3 chart-item ${
                  isUnique ? 'chart-wrapper-unique' : 'chart-wrapper'
                }`}
                key={column.key}
              >
                {isUnique ? (
                  <div className="d-flex justify-content-center align-items-center">
                    <div className="unique-header-text">
                      <span className=" ml-5 badge badge-pill badge-info">
                        Over Time Data
                      </span>
                    </div>
                    <Select
                      value={generalCountFormat}
                      style={{ width: 88 }}
                      onChange={setGeneralCountFormat}
                      options={[
                        {
                          value: 'day',
                          label: 'Day',
                        },
                        {
                          value: 'month',
                          label: 'Month',
                        },
                        {
                          value: 'year',
                          label: 'Year',
                        },
                      ]}
                    />
                  </div>
                ) : (
                  <div className="p-1">
                    <span className="badge badge-pill badge-info">
                      {column.description} Chart
                    </span>
                  </div>
                )}
                <div
                  onClick={() =>
                    !isUnique && selectDataHandler(data, column.chartType)
                  }
                  className="pointer"
                >
                  {isUnique ? (
                    <Line
                      data={data}
                      height={200}
                      options={{
                        maintainAspectRatio: false,
                        elements: {
                          point: {
                            radius: data.labels.length > 40 ? 0 : 2,
                          },
                          line: {
                            borderWidth: data.labels.length > 40 ? 1 : 2,
                          },
                        },
                        tooltips: {
                          callbacks: {
                            label: (tooltipItem, data) =>
                              ` ${tooltipItem.value} violation${
                                tooltipItem.value > 1 ? 's' : ''
                              }`,
                          },
                        },
                        scales: {
                          yAxes: [
                            {
                              ticks: {
                                stepSize: 1,
                                max: biggestValue + 1,
                                min: 0,
                              },
                            },
                          ],
                        },
                      }}
                    />
                  ) : column.chartType === 'Pie' ? (
                    <Pie
                      data={data}
                      options={{
                        legend: {
                          display: false,
                        },
                        tooltips,
                      }}
                    />
                  ) : (
                    <Bar
                      data={data}
                      options={{
                        legend: {
                          display: false,
                        },
                        scales: {
                          xAxes: [
                            {
                              display: false,
                            },
                          ],
                          yAxes: [
                            {
                              offset: true,
                              ticks: {
                                beginAtZero: true,
                                stepSize: 1,
                              },
                            },
                          ],
                        },
                      }}
                    />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Modal
        style={{ top: 20 }}
        title={null}
        visible={isVisible}
        footer={null}
        onCancel={() => setIsVisible(false)}
        width={'90%'}
      >
        <div>
          {selectedData &&
            (selectedData.type === 'Pie' ? (
              <Pie
                data={selectedData.data}
                options={{
                  tooltips,
                }}
              />
            ) : (
              <Bar
                data={selectedData.data}
                options={{
                  legend: {
                    display: false,
                  },
                  scales: {
                    xAxes: [
                      {
                        display: false,
                      },
                    ],
                    yAxes: [
                      {
                        offset: true,
                        ticks: {
                          beginAtZero: true,
                          stepSize: 1,
                        },
                      },
                    ],
                  },
                }}
              />
            ))}
        </div>
      </Modal>
    </div>
  );
};

export default ViolationsTableCharts;
