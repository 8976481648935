import React from 'react';

const RetainedBadges = ({ retained }) => {

    return (
        <div style={{
            position: 'absolute',
            right: 0,
            display: 'flex',
        }}>
            {retained?.hearing?.isRetained && <div style={{
                padding: '5px 10px',
                background: '#c36c00',
                color: '#fff',
                borderRadius: '0 0 5px 5px',
                marginRight: '5px',
            }}>
                Hearing Retained
            </div>}
            {retained?.cofc?.isRetained && <div style={{

                padding: '5px 10px',
                background: '#c36c00',
                color: '#fff',
                borderRadius: '0 0 5px 5px',
                marginRight: '5px',
            }}>
                CofC Retained
            </div>}
            {retained?.dob?.isRetained && <div style={{
                padding: '5px 10px',
                background: '#c36c00',
                color: '#fff',
                borderRadius: '0 0 5px 5px',
                marginRight: '5px',
            }}>
                DOB Retained
            </div>}
        </div>
    )
}

export default RetainedBadges;
