import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import creApi from "../../../services/creService";
import { toast } from "react-toastify";
import moment from "moment";
import Datatable from "react-data-table-component";
import { Modal, Collapse, Tabs } from 'antd';
import './elevatorsTracking.css'

const { Panel } = Collapse;

const PanelInfo = ({ info }) =>
    <div className='panel-content' >
        {Object.keys(info).filter(key => info[key]).map(key => {
            return <Fragment key={key}>
                <div className='panel-info-title'><strong>{key}</strong></div>
                <div className='d-flex flex-wrap'>
                    {Object.keys(info[key]).filter(keyValue => !/guid/i.test(keyValue)).map(keyValue => (
                        <div key={keyValue} className="w-50 d-flex justify-content-between pl-3 pr-3 pb-2">
                            <span className="w-50"><strong>{keyValue.replace(/([a-z])([A-Z])/g, '$1 $2')}</strong></span>  <span className="w-50">{info[key][keyValue] || '--'}</span>
                        </div>
                    ))}
                </div>
            </Fragment>
        }
        )}
    </div >



const dateSorter = (propertyName, desc) => (rowA, rowB) => {
    if (desc) {
        return new Date(rowB[propertyName]) - new Date(rowA[propertyName]);
    }
    return new Date(rowA[propertyName]) - new Date(rowB[propertyName]);
};

const TrackingInfo = ({ tracking = {}, rowData, noHeader }) => {
    const columnsPay = [
        {
            name: 'Invoice Number',
            selector: 'InvoiceNumber',
            sortable: true,
        },
        {
            name: 'Filing Fee',
            selector: 'MerchantAmount',
            sortable: true,
            format: (row, index) => '$' + row.MerchantAmount || 0

        },
        {
            name: 'Late Filing Penalty',
            selector: 'LateFilingFee',
            sortable: true,
            format: (row, index) => '$' + row.LateFilingFee || 0

        },
        {
            name: 'Service Fee',
            selector: 'Conveniencefeeamount',
            sortable: true,
            format: (row, index) => '$' + row.Conveniencefeeamount || 0
        },
        {
            name: 'Total Amount Paid',
            selector: 'TotalFee',
            sortable: true,
            format: (row, index) => '$' + row.TotalFee || 0
        },
        {
            name: 'Transaction Date',
            selector: 'TransactionDate',
            sortable: true,
        },

    ];
    return <Collapse defaultActiveKey={['0', '1', '2']} className={`${noHeader ? 'noHeader' : ''}`} ghost >

        <Panel style={{ paddingTop: '10px' }} header={<div className={`${noHeader ? 'h5' : 'panel-header'}`}>General Information</div>} showArrow={false}>
            <PanelInfo info={{
                "Location Information": tracking.SafetyDetails?.LocationInformation,
                "Owner Information": tracking.SafetyDetails?.OwnerInformation,
                "Performing Agency Director/Co-Director (Applicant of Record) Information": tracking.SafetyDetails?.PerformingAgencyDirectorInformation,
                "Performing Agency Inspector Information": tracking.SafetyDetails?.PerformingAgencyInspectorInformation,
                "Performing Maintenance Director Information": tracking.SafetyDetails?.PerformingMaintenanceDirectorInformation,
                "Witnessing Agency Director/Co-Director Information": tracking.SafetyDetails?.WitnessingAgencyDirectorInformation,
                "Witnessing Agency Inspector Information": tracking.SafetyDetails?.WitnessingAgencyInspectorInformation,
                "Approved Agency Director Information": tracking.SafetyDetails?.ApprovedAgencyDirectorInformation,
                "Approved Agency Inspector Information": tracking.SafetyDetails?.ApprovedAgencyInspectorInformation
            }} />
        </Panel>
        <Panel style={{ paddingTop: '10px' }} header={<div className={`${noHeader ? 'h5' : 'panel-header'}`}>Device Information</div>} showArrow={false}>
            <PanelInfo info={{
                'Device Information': {
                    'Device ID': tracking.DeviceID,
                    'Device Type': rowData?.DeviceTypeLabel,
                    'Approved Date': rowData?.ApprovalDate,
                    'Status Date': rowData?.StatusDate,
                    'Floor To': rowData?.ToFloor,
                    'Floor From': rowData?.FromFloor,
                    'Report Year': tracking.SafetyDetails?.ReportYear,
                    'Inspection Type': tracking.InspectionType,
                    'Inspection Date': tracking.InspectionDate,
                    'Filed Date': tracking.FiledDate,
                    'Defects Exist?': tracking.DefectsExists,
                    'Premises': rowData?.Premises
                }
            }} />
        </Panel>
        {
            tracking.SafetyDetails?.PaymentInfo?.elevatorsPaymentHistory?.length &&
            <Panel style={{ paddingTop: '10px' }} header={<div className={`${noHeader ? 'h5' : 'panel-header'}`}>Payment Information</div>} showArrow={false}>
                <div className='pay-table p-2'>

                    <Datatable
                        columns={columnsPay}
                        data={tracking.SafetyDetails?.PaymentInfo?.elevatorsPaymentHistory}
                        highlightOnHover={true}
                        noHeader={true}
                        dense
                    />

                </div>
            </Panel>
        }

    </Collapse>
}

export const ElevatorsTrackingSingle = ({ rowData }) =>
    <div className="tracking-wrapper" >
        <TrackingInfo tracking={rowData} rowData={rowData} noHeader />
    </div>


export const ElevatorsTracking = (props) => {
    const [tracking, setTracking] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoadingState] = useState(true);
    const [data, setData] = useState([]);
    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    useEffect(() => {

        async function fetchData() {
            try {

                let data = await creApi.getElevatorsElevatorsTracking(props.bin, props.deviceNumber);
                setLoadingState(false)
                setData(data.data)

            } catch (e) {
                toast.error(e.message)
            }
        }

        fetchData();
        // eslint-disable-next-line
    }, []);

    const showModal = (row) => {
        setTracking(row)
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const columns = [
        /*{
            name: 'View',
            selector: '_id',
            format: (row, index) =>
                <i onClick={() => showModal(row)} class="fa fa-external-link fa-2x" aria-hidden="true" style={{ cursor: 'pointer' }}></i>
        },*/
        {
            name: 'Tracking Number',
            selector: 'TrackingNumber',
            sortable: true,
            wrap: true,
            grow: 2,
            format: (row, index) =>
                <div>
                    <i onClick={() => showModal(row)} className="fa fa-external-link mr-2" aria-hidden="true" style={{ cursor: 'pointer' }}></i>
                    <Link to={`/violation-details/${row._id}`}>{row.TrackingNumber}</Link>
                </div>
        },
        {
            name: 'Inspection Date',
            selector: 'InspectionDate',
            sortable: true,
            format: (row, index) => { return moment(row.InspectionDate).format('MM/DD/YYYY'); },
            sortFunction: dateSorter('InspectionDate')
        },
        {
            name: 'Filed Date',
            selector: 'FiledDate',
            sortable: true,
            format: (row, index) => { return moment(row.FiledDate).format('MM/DD/YYYY'); },
            sortFunction: dateSorter('FiledDate')
        },

        {
            name: 'Type',
            selector: 'InspectionType',
            sortable: true,
            wrap: true,
            grow: 2
        },
        {
            name: 'Report Status',
            selector: 'ReportStatus',
            sortable: true,
            wrap: true,
        },
        {
            name: 'Defects Exists',
            selector: 'DefectsExists',
            sortable: true,
            wrap: true,
        }
    ];

    // eslint-disable-next-line
    const filteredItems = data.filter(item => {
        let array = Object.values(item);
        if (array.find(item => item.toString().toLowerCase().includes(filterText.toLowerCase()))) return true;
    }).sort(dateSorter('FiledDate', true))

    return (
        <Fragment>

            <div className="card user-card elevator-tracking">
                <div className="card-header">
                    <div className="online-user"><h5 className="font-primary f-18 mb-0">Legacy Elevator Tracking</h5></div>
                </div>

                <div className="card-body p-2">
                    <div>
                        <Tabs tabBarExtraContent={
                            <div className="text-right pb-2">
                                <input className="" type="text" placeholder="Filter By Name" aria-label="Search Input" value={filterText} onChange={e => setFilterText(e.target.value)} />
                                <button type="button" onClick={() => { setResetPaginationToggle(!resetPaginationToggle); setFilterText(''); }}>X</button>
                            </div>
                        } type="card">
                            <Tabs.TabPane tab="Inspection/Test Report" key="item-1">
                                <Datatable
                                    progressPending={loading}
                                    columns={columns}
                                    data={filteredItems.filter(el => el.categoryType !== "Affirmations")}
                                    highlightOnHover={true}
                                    pagination={true}
                                    paginationResetDefaultPage={resetPaginationToggle}
                                    noHeader={true}
                                />
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Affirmation of Correction" key="item-2">
                                <Datatable
                                    progressPending={loading}
                                    columns={columns}
                                    data={filteredItems.filter(el => el.categoryType === "Affirmations")}
                                    highlightOnHover={true}
                                    pagination={true}
                                    paginationResetDefaultPage={resetPaginationToggle}
                                    noHeader={true}
                                />
                            </Tabs.TabPane>
                        </Tabs>

                    </div>

                </div>
                <Modal
                    title={`Tracking Number: ${tracking.TrackingNumber}`}
                    visible={isModalOpen}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    footer={<></>}
                    width={'80%'}
                    centered
                >
                    <TrackingInfo tracking={tracking} rowData={props.rowData} />
                </Modal>
            </div>

        </Fragment>
    );
};

