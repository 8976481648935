import React from 'react';
import { Pagination as PaginationComponent } from 'antd';

const Pagination = ({
  itemsCount,
  pageSize,
  currentPage,
  onPageChange,
  onPageSizeChange,
  doNotShowTotal,
  ...rest
}) => {
  let showSizeChanger = true;
  if (!onPageSizeChange) {
    onPageSizeChange = () => {};
    showSizeChanger = false;
  }
 
  return (
    <nav {...rest}>
      <PaginationComponent
        defaultPageSize={pageSize}
        showSizeChanger={showSizeChanger}
        onShowSizeChange={onPageSizeChange}
        total={itemsCount}
        showQuickJumper={true}
        size={pageSize}
        onChange={onPageChange}
        showTotal={doNotShowTotal ? null : (total) => `Total ${total} items`}
        current={currentPage}
      />
    </nav>
  );
};

export default Pagination;
