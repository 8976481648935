import './waterTanksBlock.css'

const permitsAdditionalTable = ({ violationData }) => {

    return (
        <div className="col-12 mt-4">
            <h5 className='text-center'> General Inspection Requirements</h5>

            <table class="table table-bordered border-dark">
                <tbody>
                    <tr>
                        <td></td>
                        <td class="text-center">Was examination performed?</td>
                        <td class="text-center">Results*</td>
                    </tr>
                    <tr>
                        <td>Examined general condition and integrity of internal tank structure</td>
                        <td class="text-center">{violationData.GRQS}</td>
                        <td class="text-center">{violationData.GRS}</td>
                    </tr>
                    <tr>
                        <td>Examined general condition and integrity of external tank structure</td>
                        <td class="text-center">{violationData.GRQXS}</td>
                        <td class="text-center">{violationData.GRXS}</td>
                    </tr>
                    <tr>
                        <td>Examined condition of all pipes connected to the tank</td>
                        <td class="text-center">{violationData.GRQOP}</td>
                        <td class="text-center">{violationData.GRSOP}</td>
                    </tr>
                    <tr>
                        <td>Examined condition of access ladders</td>
                        <td class="text-center">{violationData.GRQAL}</td>
                        <td class="text-center">{violationData.GRAL}</td>
                    </tr>
                    <tr>
                        <td class="text-left">Examined condition of vents, access hatches and screens</td>
                        <td class="text-center">{violationData.GRQAV}</td>
                        <td class="text-center">{violationData.GRAV}</td>
                    </tr>
                    <tr>
                        <td class="text-left">Examined condition of the roof</td>
                        <td class="text-center">{violationData.GRQRA}</td>
                        <td class="text-center">{violationData.GRSRA}</td>
                    </tr>
                </tbody>
            </table>
            <p className='mt-1'>*If an unsanitary condition is found, the building owner must immediately take steps to correct the problem.</p>
            <h5 className='text-center mt-4'> Sanitary Inspection Requirements</h5>
            <table class="table table-bordered border-dark">
                <tbody><tr>
                    <td></td>
                    <td class="text-center">Was examination performed?</td>
                    <td class="text-center">Results*</td>
                </tr>
                    <tr>
                        <td>Examined for presence of sediment</td>
                        <td class="text-center">{violationData.SRQS}</td>
                        <td class="text-center">{violationData.SRS}</td>
                    </tr>
                    <tr>
                        <td>Examined for presence of biological growth</td>
                        <td class="text-center">{violationData.SRQBG}</td>
                        <td class="text-center">{violationData.SRBG}</td>
                    </tr>
                    <tr>
                        <td>Examined for presence of floatable debris and/or insects in the tank</td>
                        <td class="text-center">{violationData.SRQDI}</td>
                        <td class="text-center">{violationData.SRSDI}</td>
                    </tr>
                    <tr>
                        <td>Examined for presence of rodent or bird activity on, in or around the tank</td>
                        <td class="text-center">{violationData.SRQRB}</td>
                        <td class="text-center">{violationData.SRSDB}</td>
                    </tr>
                </tbody>
            </table>
            <p className='mt-1'>*If an unsanitary condition is found, the building owner must immediately take steps to correct the problem, which may include draining and cleaning the water tank.</p>
            <h5 className='text-center mt-4'> Water Quality Sample*</h5>
            <table class="table table-bordered border-dark border-small" >
                <tbody><tr>
                    <td>Was a sample collected?</td>
                    <td class="text-center">{violationData.SC}</td>
                </tr>
                    <tr>
                        <td>Coliforms present?</td>
                        <td class="text-center">{violationData.EC}</td>
                    </tr>
                    <tr>
                        <td>E.coli present?</td>
                        <td class="text-center">{violationData.CF}</td>
                    </tr>

                </tbody></table>
            <p className='mt-1'>*If the sample tested contains coliform bacteria or specifically E. coli, the building owner must report the results to the Health Department within 24 hours and clean and disinfect the water tank.</p>
        </div>
    )
}

export default permitsAdditionalTable;