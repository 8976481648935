const WorkOrderExpired = () => {

    return (
        <div className="row violation-sec">
            <div className="col-xl-12 violations-bg my-3">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex justify-content-center align-items-center flex-column">
                            <h3>Create Work Order is currently disabled, please contact support to re-activate this service.</h3>
                            <div>Email: support@bcompliant.com</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default WorkOrderExpired;
