import React, { useEffect, useState, useRef } from 'react';
import { Radio, Button, Select, } from 'antd';
import { DownloadOutlined, FontSizeOutlined, FullscreenOutlined, FullscreenExitOutlined, SaveOutlined, EditOutlined } from '@ant-design/icons';

import Search from 'antd/lib/input/Search';

import './TableHeaderWithSearchBar.css'
import HideColumnDropdown from './HideColumnDropDown';
import ChartsColumnDropDown from './ChartsColumnDropDown';

const TableHeaderWithSearchBar = ({
  options,
  columns,
  handleTableSearch,
  handleTableSearchTerm,
  handleToggleVisibility,
  handleTableSizeChange,
  handleChartsDisplay,
  onCSVExport,
  uniqueKeys,
  defaultSearch,
  toggleAdvancedSearchVisibility,
  showAdvancedSearch,
  onPDFExport,
  fullSizeTable,
  setFullSizeTable,
  searchTerms,
  ...props
}) => {
  const [searchString, setSearchString] = useState('');
  const [pdfLoading, setPdfLoading] = useState(false);
  const [selected, setSelected] = useState()
  const selectRef = useRef()

  const handleSelectedSearch = (value, edit) => {
    setSelected(null)
    selectRef.current.blur()
    setTimeout(() => {
      handleTableSearchTerm(value, edit === true)
    }, 100);
  }

  useEffect(() => {
    setSearchString(defaultSearch)
  }, [defaultSearch]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleTableSearch(searchString);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchString, handleTableSearch]);

  return (
    <>
      <div className="row mb-3 d-flex custom-flex align-items-center">
        <Button
          onClick={() => setFullSizeTable(!fullSizeTable)}
          style={fullSizeTable ?
            { cursor: 'pointer', padding: '8px 14px', fontSize: '17px', color: '#FFFFFF', backgroundColor: '#4466F2', borderColor: '#4466F2' } :
            { cursor: 'pointer', padding: '0px 14px', fontSize: '17px' }}
          className="hide-btn-wrapper"
        >
          {fullSizeTable ?
            <FullscreenExitOutlined style={{ fontSize: '25px', verticalAlign: 'middle' }} /> :
            <FullscreenOutlined style={{ verticalAlign: 'middle' }} />
          }
        </Button>
        <div className="flex-elem">

          <Radio.Group
            value={options.tableSize}
            onChange={handleTableSizeChange}
          >
            <Radio.Button value="small" style={{ fontSize: '10px' }}>
              <FontSizeOutlined />
            </Radio.Button>
            <Radio.Button value="middle">
              <FontSizeOutlined
                style={{ fontSize: '14px', verticalAlign: 'middle' }}
              />
            </Radio.Button>
            <Radio.Button value="large">
              <FontSizeOutlined
                style={{ fontSize: '20px', verticalAlign: 'middle' }}
              />
            </Radio.Button>
          </Radio.Group>
        </div>
        <div className="flex-elem">

          <Button
            onClick={() => onCSVExport()}
            style={{ cursor: 'pointer' }}
            className="hide-btn-wrapper"
          >
            <DownloadOutlined className="pr-2" />
            Export to CSV
          </Button>
          <Button
            onClick={() => onPDFExport(setPdfLoading)}
            style={{ cursor: 'pointer' }}
            className="hide-btn-wrapper ml-3"
          >
            <DownloadOutlined className="pr-2" />
            {pdfLoading ? 'Loading ...' : 'Export to PDF'}
          </Button>

        </div>
        <div className="flex-elem">
          <HideColumnDropdown
            columns={columns}
            handleToggleVisibility={handleToggleVisibility}
          />
        </div>

        {!props.noCharts && <div className="flex-elem ">
          <ChartsColumnDropDown
            uniqueKeys={uniqueKeys}
            columns={columns}
            handleChartsDisplay={handleChartsDisplay}
          />
        </div>
        }
        <div className="flex-elem">
          {props.children}
        </div>

        <div className="flex-elem flex-elem-search">
          <Search

            style={{ border: '1px solid #d9d9d9' }}
            addonBefore={
              <Select style={{ width: 200, background: 'white' }} onChange={handleSelectedSearch} value={selected} placeholder="Select a search" ref={selectRef} >
                {searchTerms.map(val => {

                  return <Select.Option value={val._id} key={val._id} >
                    <div className='d-flex justify-content-between'>
                      {val.label}
                      <EditOutlined className='term-icon' onClick={(event) => { event.stopPropagation(); handleSelectedSearch(val._id, true) }} />
                    </div>

                  </Select.Option>
                })
                }

                <Select.Option value="save" ><SaveOutlined className='mr-1 ' />Save Search Terms</Select.Option>
              </Select>
            }
            value={searchString}
            placeholder="Enter search term"
            onChange={(e) => setSearchString(e.target.value)}
          />
        </div>
        <div className="flex-elem ">
          <div
            className="btn-link text-nowrap text-decoration-none pointer noselect min-width-130"
            onClick={toggleAdvancedSearchVisibility}
          >
            Advanced Search {showAdvancedSearch && <i className="fa fa-times ml-1"></i>}
          </div>
        </div>
      </div >
    </>
  );
};

export default TableHeaderWithSearchBar;
