import { settingsKey } from "../services/authService";

export const SAFETY_MAILING_CART_KEY = 'safetyMailingsCart';

/**
 * setItem - Function
 * @param {string} name
 * @param {*} value
 */
 function setItem(name, value) {
  try {
    localStorage.setItem(name, JSON.stringify(value));
  } catch (error) {
    console.error('Error setting item in Local storage');
  }
}

/**
 * getItem
 * @param {string} name
 * @return {*}
 */
function getItem(name) {
  try {
    return JSON.parse(localStorage.getItem(name));
  } catch (error) {
    return null;
  }
}


/**
 * getItem
 * @param {string} name
 * @return {*}
 */
function getViewTypeSetting(pageName, defaultViewType = 'list') {
  try {
    const data = localStorage.getItem(settingsKey);
    const settings = data !== 'undefined' ? JSON.parse(data) : null;
    let viewType = defaultViewType;
    if(settings) {
      viewType = settings.viewType[pageName];
      if(!viewType) {
        viewType = defaultViewType;
      }
    }
    return viewType;
  } catch (error) {
    return defaultViewType;
  }
}

function setViewTypeSetting(pageName, viewType) {
  let settings = {}
  try {
    const data = localStorage.getItem(settingsKey);
    settings = data !== 'undefined' ? JSON.parse(data) : null;
  } catch (error) {
    
  }
  
  if(!settings.viewType) settings.viewType = {}
   
  settings.viewType[pageName] = viewType
  localStorage.setItem(settingsKey, JSON.stringify(settings));
}

/**
 * getItem
 * @param {string} name
 * @return {*}
 */
 function getSortBySetting(pageName, category, sub) {
  let sortBy = {field: '', direction: ''};
  try {
    const data = localStorage.getItem(settingsKey);
    const settings = data !== 'undefined' ? JSON.parse(data) : null;
    if(settings.sortBy && settings.sortBy[pageName] && settings.sortBy[pageName][category] && settings.sortBy[pageName][category][sub]) {
      sortBy = settings.sortBy[pageName][category][sub];
    }
    return sortBy;
  } catch (error) {
    return sortBy;
  }
}

/**
 * removeItem
 * @param {string} name
 */
function removeItem(name) {
  try {
    localStorage.removeItem(name);
  } catch (error) {
    console.error('Error deleting item in Local storage');
  }
}

const LocalState = {
  setItem,
  getItem,
  removeItem,
  getViewTypeSetting,
  setViewTypeSetting,
  getSortBySetting,
};

export default LocalState;
