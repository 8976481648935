import React, { useState, useEffect } from 'react';
import { DragDropContext } from "react-beautiful-dnd";
import { toast } from 'react-toastify';
import VerticalColumn from './column';
import { ColumnList } from './styled';
import creService from '../../../services/creService';

const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};

const ViewByColumn = ({ isLoading, data, switchToggle, handleChange }) => {

    const [items, setItems] = useState({
        Open: {},
        'In Progress': {},
        'On Hold': {},
        Completed: {},
        Canceled: {},
    });

    const onDragEnd = async ({ draggableId, source, destination }) => {
        // dropped outside the list
        if (!destination) {
            return;
        }
        const sourceId = source.droppableId;
        const destinationId = destination.droppableId;

        if (sourceId !== destinationId) {
            const movedItems = move(
                items[sourceId]?.data || [],
                items[destinationId]?.data || [],
                source,
                destination
            );

            setItems((prev) => ({
                ...prev,
                [sourceId]: {
                    ...prev[sourceId],
                    data: movedItems[sourceId]
                },
                [destinationId]: {
                    ...prev[destinationId],
                    data: movedItems[destinationId]
                },
            }))

            const res = await creService.updateWorkOrderStatus(draggableId, destinationId);
            if (!res.data.status) {
                toast.error(res.data.message)
            }
        }
    }

    const onLoadMore = async (name, title) => {
        switchToggle('list');
        handleChange('status', [name]);
    };

    useEffect(() => {
        setItems(data);
    }, [data]);

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <ColumnList className="row">
                <VerticalColumn
                    name="Open"
                    title="Open"
                    isLoading={isLoading}
                    total={items.Open?.total}
                    items={items.Open?.data}
                />
                <VerticalColumn
                    name="In Progress"
                    title="In Progress"
                    isLoading={isLoading}
                    total={items['In Progress']?.total}
                    items={items['In Progress']?.data}
                />
                <VerticalColumn
                    name="On Hold"
                    title="On Hold"
                    isLoading={isLoading}
                    total={items['On Hold']?.total}
                    items={items['On Hold']?.data}
                />
                <VerticalColumn
                    name="Completed"
                    title="Completed"
                    isLoading={isLoading}
                    total={items.Completed?.total}
                    items={items.Completed?.data} onLoadMore={onLoadMore}
                />
                <VerticalColumn
                    name="Canceled"
                    title="Canceled"
                    isLoading={isLoading}
                    total={items.Canceled?.total}
                    items={items.Canceled?.data}
                    onLoadMore={onLoadMore}
                />
            </ColumnList>
        </DragDropContext>
    );

}

export default ViewByColumn;
