import React from 'react';
import moment from 'moment';
import DataTable from 'react-data-table-component';

const SafetyMailingsAutoRulesPreview = ({ previewData, subUsers, vendors }) => {

    const columns = [
        {
            name: 'Building',
            sortable: true,
            selector: (row) => row.address,
        },
        {
            name: 'Apt #',
            sortable: true,
            selector: (row) => row.aptNumber,
        },
        {
            name: 'Title',
            sortable: true,
            selector: (row) => row.title,
        },
        {
            name: 'Category',
            sortable: true,
            selector: (row) => row.category,
        },
        {
            name: 'Due Date',
            sortable: true,
            selector: (row) => moment(row.dueDate).format('MM/DD/YYYY'),
        },
        {
            name: 'Assigned To',
            sortable: true,
            selector: (row) => {
                const users = row.assignedUsers?.map((u) => subUsers?.find((user) => user._id === u)?.fullName);
                return users?.join(', ');
            }
        },
        {
            name: 'Vendor',
            sortable: true,
            selector: (row) => vendors.find((vendor) => vendor._id === row.vendorId)?.fullName,
        },
    ];

    return (
        <DataTable
            className="mb-4"
            columns={columns}
            data={previewData}
            pagination
            responsive
            striped
            noHeader
            noDataComponent={<div className="my-3">No safety mailings responses found yet. The system will auto create work orders as soon as it will receive responses</div>}
        />
    );
}

export default SafetyMailingsAutoRulesPreview;
