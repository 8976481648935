import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import creService from "../../services/creService";
import ReactLoading from "react-loading";
import { TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { NavLink as RouterNavLink, Route, Switch, useHistory } from 'react-router-dom';

import RecentActivity from "../buildings/partials/recentActivity";
import Notes from "../buildings/violationDetails/notes";
import Uploads from "../buildings/violationDetails/uploads";
import DetailsQuestionnaire from "../buildings/partials/detailsQuestionnaire";
import AddressHeaderBlock from "../buildings/partials/addressHeaderBlock";

const category = "questionnaire"

const Details = () => {

    let { id, sub, tabName } = useParams();
    let history = useHistory();
    const [vendors, setVendors] = useState([])
    const [subUsers, setSubUsers] = useState([])
    const [customFields, setCustomFields] = useState([])
    const [apiData, setApiData] = useState({})
    const [refreshHistory, setRefreshHistory] = useState(false)
    const [appts, setAppts] = useState([])
    const [addressData, setAddressData] = useState({
        address: "Loading...",
        bin: '',
        lot: '',
        block: ''
    })
    const [loading, setLoading] = useState(true)
    const setRefreshHistoryHandler = () => {
        setRefreshHistory((oldValue) => !oldValue)
    }

    useEffect(() => {

        getDetailsData(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])


    const getApartments = async (bin) => {
        try {
            const response = await creService.getApartments(bin);

            if (response.data.status) {
                setAppts(response.data.data);
            }


        } catch (e) {
            toast.error(e.message)
        }

    }
    const getDetailsData = async (id) => {

        try {

            const { data: details } = await creService.getQuestionaryDataClientListAnswer(id)

            if (details.status) {
                setApiData({ violationDetails: details.data })
            } else {
                toast.error(details.message);
            }

            const { data: categories } = await creService.getCustomFields('Questionnaire', sub);

            if (categories.status) {
                setCustomFields(categories)

            } else {
                toast.error(categories.message);
            }

            const { data: addressData } = await creService.getPropertyInfo(details.data.bin);
            setAddressData(addressData)
            await getApartments(details.data.bin)
            const subUsersRes = await creService.getSubUsers();
            setSubUsers(subUsersRes.data.data)
            const res = await creService.getVendors();
            setVendors(res.data.vendors);
        } catch (e) {
            toast.error(e.message);
        }
        setLoading(false)
    }

    return (
        <>

            <div className="violationsDetails-bg px-0 container-fluid">
                <div className="row ">
                    <div className="col-md-12">
                        <AddressHeaderBlock data={addressData} handleGoBack={history.goBack} violationDetailsPage />
                    </div>
                </div>
                <div className="row m-0 w-100 pr-2">
                    <div className='building-detail w-100 col-xl-9 '>

                        {loading ? (
                            <div align="center">
                                <ReactLoading type={"cylon"} color={"grey"} />
                            </div>
                        ) : (
                            <div className="position-relative">

                                <DetailsQuestionnaire
                                    category={'Questionnaire'}
                                    saveOn={'questionnaire'}
                                    appts={appts}
                                    sub={sub}
                                    apiData={apiData}


                                    subUsers={subUsers}
                                    vendors={vendors}
                                    customFields={customFields}
                                    setRefreshHistory={setRefreshHistoryHandler}
                                    moveToCategory={() => { }}
                                />

                            </div>


                        )}
                        <div className="card-body p-0 mt-4" id="all-tabs-area">

                            <Nav tabs className="d-flex m-0">
                                <NavItem>
                                    <NavLink
                                        active={tabName === 'documents' || !tabName}
                                        tag={RouterNavLink}
                                        to={`/questionnaire/${sub}/${id}/documents/#tab-content`}
                                        exact
                                    >
                                        Uploads/Files
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        active={tabName === 'notes'}
                                        tag={RouterNavLink}
                                        to={`/questionnaire/${sub}/${id}/notes/#tab-content`}
                                        exact
                                    >
                                        Notes
                                    </NavLink>
                                </NavItem>

                            </Nav>
                            <Switch>
                                <Route
                                    path={[
                                        `/questionnaire/${sub}/${id}/notes/#tab-content`,
                                        `/questionnaire/${sub}/${id}/notes`]}
                                    exact
                                >
                                    <TabPane tabId={`/questionnaire/${sub}/${id}/notes`}>
                                        <Notes violationId={id} source={category} />
                                    </TabPane>
                                </Route>
                                <Route path={[`/questionnaire/${sub}/${id}/documents`, `/questionnaire/${sub}/${id}`]}>
                                    <TabPane tabId={`/questionnaire/${sub}/${id}/documents`}>
                                        <Uploads
                                            violationId={id}
                                            category={category}
                                            sub={sub?.replace(/ /g, '_')}
                                            showDocumentTag
                                            source={category}
                                        />
                                    </TabPane>
                                </Route>
                            </Switch>
                        </div>
                    </div>
                    {apiData.violationDetails &&
                        <div className="col-xl-3 recent-activity px-4 px-xl-0">
                            <RecentActivity
                                type="violationActivity"
                                saveOn={'questionnaire'}
                                apiData={apiData}
                                refreshHistory={refreshHistory}
                            />
                        </div>
                    }
                </div>

            </div>
        </>
    );
}

export default Details
