import React, { useEffect, useState, useContext, useCallback, Fragment, useRef } from "react";
import creService from '../../services/creService';
import { getCurrentUser } from '../../services/authService'
import { toast } from 'react-toastify';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Modal, Input, Button, Popover } from 'antd';
import { SocketContext } from '../../../context/socket';
import moment from "moment";
import './chatRooms.css'
import { UncontrolledTooltip } from "reactstrap";
import {
    CloseOutlined,
} from '@ant-design/icons';

import { createPortal } from 'react-dom';

const currentUser = getCurrentUser();
const colors = [
    '#f3a53c',
    '#db4057',
    '#09ba70',
    '#c7c2c2',
    '#5a3e3e',
    '#9455c9',
    '#e5187d',
    '#e09a9a',
    '#c6b44b',
    '#f0bf0e',
    '#242323',
    '#9d233f',
    '#067041',
    '#ee47b4',
    '#7244cd',
    '#97cd1c',
    '#53b1e1',
    '#6f6868',
    '#784b3d',
    '#ec5826',
    '#e58383',
    '#fa91f0',
    '#4b91f3',
    '#71337d',
    '#93acbb',
    '#5d9db9',
    '#1e4c8d',
    '#3eb8b3',
    '#4246da',
    '#370c89',
    '#0e97d4',
    '#cdbff7',
    '#336ac7',
    '#9daed0',
    '#e464b6',
    '#bab7cf',
    '#a8896a',
    '#95d3e4',
    '#154349',
    '#aa7063',
];

const colorsObj = {}

const getColor = (id, index) => {
    if (!colorsObj[id]) {
        colorsObj[id] = colors[index]
    }
    return colorsObj[id]
}

const Message = ({ type, senderName, message, background, avatar }) => {
    if (message.source === 'System') {
        return <div style={{ color: 'gray' }} className=" text-center">{message.message}</div>

    }

    return (
        <div className={`chat-message ${type.toLowerCase()} chat-message-updated`} style={{ whiteSpace: 'pre-wrap', position: 'relative' }}>
            <div
                style={{ position: 'absolute', top: '-10px', width: '90%', 'flexDirection': type === 'sent' ? 'row' : 'row-reverse' }}
                className="d-flex justify-content-between"
            >
                <div >

                    <span
                        className="chat-viewer-small pointer"
                        id={'id' + new Date(message.date).getUTCMilliseconds()}
                        style={{
                            background: avatar ? undefined : background,
                            backgroundImage: avatar ? `url(${window.location.origin}/api/documents/get-by-key?key=${avatar})` : undefined,
                            backgroundSize: avatar ? 'cover' : undefined,
                        }}>
                        {avatar ? '' : senderName.split(' ').slice(0, 2).map(el => el[0]).join('')}
                    </span>
                    <UncontrolledTooltip
                        placement="top"
                        autohide={true}
                        target={'id' + new Date(message.date).getUTCMilliseconds()}

                    >
                        {senderName}
                    </UncontrolledTooltip>
                </div>

                <div style={{ background: 'white' }}><small>{moment(message.date).calendar()} </small></div>
            </div>
            {message.message}


        </div>
    );
};
const MessageTyping = ({ senderName = '', message, background, avatar }) => {

    return (
        <div className={`chat-message received chat-message-updated w-50`} style={{ whiteSpace: 'pre-wrap', position: 'relative' }}>
            <div
                style={{ position: 'absolute', top: '-10px', width: '90%', 'flexDirection': 'row-reverse' }}
                className="d-flex justify-content-between"
            >
                <div >

                    <span
                        className="chat-viewer-small pointer"
                        id={'id-typing'}
                        style={{
                            background: avatar ? undefined : background,
                            backgroundImage: avatar ? `url(${window.location.origin}/api/documents/get-by-key?key=${avatar})` : undefined,
                            backgroundSize: avatar ? 'cover' : undefined,
                        }}>
                        {avatar ? '' : senderName.split(' ').slice(0, 2).map(el => el[0]).join('')}
                    </span>
                    <UncontrolledTooltip
                        placement="top"
                        autohide={true}
                        target={'id-typing'}

                    >
                        {senderName}
                    </UncontrolledTooltip>
                </div>


            </div>
            <div

                style={{ color: 'gray' }}
                className=" text-center loading"
            >
                ...
            </div>


        </div>
    );
};

const ChatRoom = ({ children, chatID, getChats, currentUserId, shouldOpen, subUsers, supportUsers = [] }) => {

    const socket = useContext(SocketContext);
    const [showModal, setShowModal] = useState(false)
    const [sending, setSending] = useState(false);
    const [chatData, setChatData] = useState({ _id: chatID, new: chatID ? false : true, messages: [] })
    const [lastExecuted, setLastExecuted] = useState(0);
    const [typing, setTyping] = useState();
    const [selectedSubUsers, setSelectedSubUsers] = useState([])
    const [message, setMessage] = useState('')

    const [chatIsOpen, setChatIsOpen] = useState(false)
    const [position, setPosition] = useState({ x: 0, y: 0 });
    // const [isDragging, setIsDragging] = useState(false);
    // const [startPos, setStartPos] = useState({ x: 0, y: 0 });
    const chat = useRef(null);

    // const handleMouseDown = (e) => {
    //     setIsDragging(true);
    //     setStartPos({
    //         x: e.clientX - position.x,
    //         y: e.clientY - position.y,
    //     });
    // };

    // const handleMouseMove = (e) => {
    //     if (isDragging) {

    //         setPosition({
    //             x: e.clientX - startPos.x,
    //             y: e.clientY - startPos.y,
    //         });
    //     }
    // };

    // const handleMouseUp = () => {
    //     setIsDragging(false);
    // };

    useEffect(() => {
        if (shouldOpen) {
            handleChatOpen()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldOpen])

    useEffect(() => {
        if (showModal || chatIsOpen) {
            if (chatData._id) {
                getChatData(chatData._id)
            }
        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showModal, chatIsOpen])

    useEffect(() => {
        if (socket && chatData._id) {
            socket.on('new-room-message', chatUpdate);
            return () => socket.off('new-room-message', chatUpdate);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chatData._id])

    const chatUpdate = useCallback((payload) => {
        if (payload.client && payload.client !== currentUserId) {
            setTyping(payload);

            return setTimeout(() => {
                setTyping(null);
            }, 6000);
        }

        if (chatData._id === payload.roomId) {
            getChatData(payload.roomId)
            handleChatOpen()
            setTyping(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chatData._id]);

    const getChatData = async (_id) => {
        try {
            const res = await creService.getChatRoom(_id);
            if (res.data.status) {
                // const current = res.data.data.chatMembers.find(el => el._id === currentUserId)
                // res.data.data.chatMembers = [...res.data.data.chatMembers.filter(el => el._id !== currentUserId), current]
                setChatData(res.data.data);
            } else {
                toast.error(res.data.message);
            }
        } catch (e) {
            toast.error(e.message);
        }

    }


    const createChat = async () => {
        if (selectedSubUsers.length === 0) {
            toast.warn('Select at least one user')
            return
        }
        const res = await creService.createChat({
            selectedSubUsers: selectedSubUsers.filter(el => !el.isAdmin).map(el => el._id),
            selectedAdmins: selectedSubUsers.filter(el => el.isAdmin).map(el => el._id),
            chatName: chatData.chatName || ''
        });
        if (res.data.status) {

            setShowModal(false)
            if (getChats) {
                await getChats(res.data.data._id)
                setChatData({ new: true })
                setSelectedSubUsers([])
            }

        } else {
            toast.error(res.data.message);
        }
    }
    const sendMessage = async () => {
        if (!message.trim()) {
            return
        }
        setSending(true);
        try {
            const res = await creService.sendMessageChatRoom(chatData._id, message);
            if (res.data.status) {
                if (!socket?.connected) {
                    getChatData(chatData._id)
                }
            } else {
                toast.error(res.data.message);
            }
        } catch (_e) {
            toast.error(_e);
        }
        setMessage('')
        setSending(false);
    }




    const setMessageHandler = (e) => {
        setMessage(e.target.value)
        handleRequest();
    }

    const handleRequest = async () => {
        const now = Date.now();
        if (now - lastExecuted >= 5000) {
            setLastExecuted(now);
            await creService.sendTypingChatRoom(chatData._id);
        }
    };

    const lastMessage = chatData?.messages?.[chatData?.messages?.length - 1]

    const handleChatOpen = () => {
        setChatIsOpen((chatIsOpen) => {
            if (chatIsOpen) return chatIsOpen
            const maxChats = Math.floor(window.innerWidth / 500)
            const openedChatsLength = document.querySelectorAll('.opened-chat').length

            if (maxChats > 1) {
                setPosition({ x: (500 * openedChatsLength) % (maxChats * 500), y: window.innerHeight > 500 ? window.innerHeight - 500 : 0 })

            }

            return true
        })
    }
    const removeFromChat = async (_id, isAdmin) => {

        const res = await creService.removeFromChat(chatData._id, _id, isAdmin);
        if (res.data.status) {

            if (_id === currentUserId) {
                setChatIsOpen(false)
            } else {
                getChatData(chatData._id)
            }

            if (getChats) {
                await getChats()
            }

        } else {
            toast.error(res.data.message);
        }
    }
    const addToChat = async (_id, isAdmin) => {

        const res = await creService.addToChat(chatData._id, _id, isAdmin);
        if (res.data.status) {

            getChatData(chatData._id)
            await getChats?.()

        } else {
            toast.error(res.data.message);
        }
    }

    return (
        <div className="w-100">

            <div onClick={() => chatData.new ? setShowModal(true) : handleChatOpen()} className="d-flex w-100" >{children}</div>
            <Modal
                title={'Creating new Chat'}
                visible={showModal}
                onOk={() => setShowModal(false)}
                onCancel={() => setShowModal(false)}
                footer={<></>}
                width={'80%'}


            >

                <>
                    <div className="form-group col-12 ">
                        <label>Please select members of chat</label>
                        <Typeahead
                            id="assignedToUsers"
                            multiple
                            selected={[...subUsers, ...supportUsers]?.filter((user) => {

                                return selectedSubUsers?.some(el => user._id === el._id)
                            }

                            )}
                            placeholder="Users"
                            onChange={(selected) => {

                                setSelectedSubUsers(selected)
                            }}
                            labelKey={(option) => option.fullName + (option.isAdmin ? ' (BComplinat support)' : '')}
                            options={[
                                ...subUsers?.filter(el => el.status !== 'Disabled'),
                                ...(supportUsers.length ?
                                    [
                                        { fullName: '- BCompliant support -', disabled: true },
                                        ...supportUsers
                                    ]
                                    : []
                                )
                            ]}

                        />
                    </div>
                    <div className="form-group col-12 ">
                        <label>Chat Name</label>
                        <Input
                            onChange={(event) => setChatData({ ...chatData, chatName: event.target.value })}
                            value={chatData.chatName}
                            placeholder={selectedSubUsers.map(el => el.fullName).join(', ')}
                        />
                    </div>

                    <div className="form-group col-12 mb-0">
                        <button disabled={selectedSubUsers.length === 0} type="submit" className="btn btn-primary" onClick={createChat}>Create Chat</button>
                    </div>
                </>

            </Modal>
            {chatIsOpen ? createPortal(<div
                className="opened-chat"
                ref={chat}
                // onMouseDown={handleMouseDown}
                // onMouseMove={handleMouseMove}
                // onMouseUp={handleMouseUp}
                // onMouseLeave={handleMouseUp}
                style={{
                    position: "fixed",
                    right: position.x,
                    // top: position.y,
                    bottom: 0,
                    width: "500px",
                    maxWidth: "100vw",
                    background: 'white',
                    // cursor: "grab",
                    userSelect: "none",
                    zIndex: 1000,
                    padding: "10px",
                    border: '3px solid #CFD6F6',
                    borderRadius: '10px'
                }}>
                <div className="d-flex justify-content-between pb-2 border-bottom-header" >
                    <div className="chat-header clearfix d-flex" >



                        <div style={{ position: 'relative' }} className="members-block" >

                            {chatData.chatMembers?.map((member, i) => {

                                return <Fragment key={member._id}>
                                    <Popover
                                        trigger="hover"
                                        content={<>
                                            <div>{member.fullName}</div>
                                            <Button size="small" type="dashed" danger onClick={() => removeFromChat(member._id)} >
                                                {member._id === currentUserId ? 'Leave Chat' : 'Remove From Chat'}</Button>
                                        </>}
                                    >
                                        <span
                                            style={{
                                                left: `${i * 20}px`,
                                                background: member.avatarS3Key ? undefined : getColor(member._id, i),
                                                backgroundImage: member.avatarS3Key ? `url(${window.location.origin}/api/documents/get-by-key?key=${member.avatarS3Key})` : undefined,
                                                backgroundSize: member.avatarS3Key ? 'cover' : undefined,
                                                top: '-35px'
                                            }}
                                            className="chat-viewer mr-2 pointer"
                                            id={'ch' + chatData._id + member._id}
                                        >
                                            {member.avatarS3Key ? '' : member.fullName?.split(' ').map(el => el.slice(0, 1).toUpperCase()).slice(0, 2).join('')}
                                        </span>
                                    </Popover>

                                </Fragment>
                            })}

                            {chatData.chatAdminMembers?.map((member, i) => {

                                return <Fragment key={member._id}>
                                    <Popover
                                        trigger="hover"
                                        content={<>
                                            <div>{member.name} (BCompliant support)</div>
                                            <Button size="small" type="dashed" danger onClick={() => removeFromChat(member._id, true)} >
                                                Remove From Chat
                                            </Button>
                                        </>}
                                    >
                                        <span
                                            style={{
                                                left: `${((chatData.chatMembers.length || 0) + i + 1) * 20}px`,
                                                background: member.avatarS3Key ? undefined : 'rgb(195, 108, 0)',
                                                backgroundImage: member.avatarS3Key ? `url(${window.location.origin}/api/documents/get-by-key?key=${member.avatarS3Key})` : undefined,
                                                backgroundSize: member.avatarS3Key ? 'cover' : undefined,

                                                top: '-35px'
                                            }}
                                            className="chat-viewer mr-2 pointer"
                                            id={'ch' + chatData._id + member._id}
                                        >
                                            {member.avatarS3Key ? '' : member.name?.split(' ').map(el => el.slice(0, 1).toUpperCase()).slice(0, 2).join('')}
                                        </span>
                                    </Popover>

                                </Fragment>
                            })}

                            <Popover
                                trigger="hover"
                                content={<div style={{ width: '300px' }}>
                                    <div>Clients</div>
                                    {subUsers.filter(el => !chatData?.chatMembers?.some(member => member._id === el._id)).map((el, id) => (
                                        <Button
                                            key={el.fullName + id}
                                            size="small"
                                            type="dashed"
                                            className="d-block w-100"
                                            onClick={() => addToChat(el._id)}
                                        >
                                            {el.fullName}
                                        </Button>
                                    ))}
                                    <div>BCompliant support</div>
                                    {supportUsers.filter(el => !chatData?.chatAdminMembers?.some(member => member._id === el._id)).map((el, id) => (
                                        <Button
                                            key={el.fullName + id}
                                            size="small"
                                            type="dashed"
                                            className="d-block w-100"
                                            onClick={() => addToChat(el._id, true)}
                                        >
                                            {el.fullName}
                                        </Button>
                                    ))}

                                </div>}
                            >
                                <span
                                    style={{
                                        left: `${((
                                            (chatData.chatMembers?.length || 0) +
                                            (chatData.chatAdminMembers?.length || 0))
                                            + 1
                                        ) * 20}px`,
                                        top: '-35px',
                                        background: '#1ea6ec'
                                    }}
                                    className="chat-viewer mr-2 pointer"
                                >
                                    +
                                </span>
                            </Popover>

                        </div>
                        <div className="about">
                            <div className="name">{chatData.chatName || 'Chat'}</div>
                            {lastMessage && <div className="status digits">Activity {moment(lastMessage.date).calendar()}</div>}
                        </div>
                    </div>

                    <div className="p-1" style={{ cursor: 'pointer' }} onClick={() => setChatIsOpen(false)}>
                        <CloseOutlined />
                    </div>
                </div>
                <div className="room-wrapper p-3">
                    {typing &&
                        <MessageTyping
                            senderName={chatData.chatMembers?.find(el => el._id === typing.client)?.fullName}
                            message={typing.text}
                            background={getColor(typing.client)}
                            avatar={chatData.chatMembers?.find(el => el._id === typing.client)?.avatarS3Key}
                        />
                    }
                    {chatData.messages?.length > 0 && chatData.messages.map((chat, index) => <Message
                        key={index}
                        user={currentUser}
                        senderName={chat.senderType === 'admin' ? `${chat.sender.name} (support)` : chat.sender.fullName}
                        background={getColor(chat.sender._id)}
                        avatar={chat.sender.avatarS3Key}
                        type={chat.sender._id === currentUser?._id ? 'sent' : 'received'}
                        message={chat}
                    />)}




                    {chatData.messages.length <= 0 &&
                        <div className="bg-gray">No Messages Yet</div>
                    }
                </div>




                <Input.Group compact  >
                    <Input
                        size="large"
                        className="input-send"
                        value={message}
                        onPressEnter={(e) => { !e.shiftKey && sendMessage() }}
                        onChange={setMessageHandler}
                        style={{ width: 'calc(100% - 100px)' }}
                        placeholder="Type a message...." />
                    <Button size="large" type="primary" disabled={sending} onClick={sendMessage} style={{ height: '42px' }}>SEND</Button>
                </Input.Group>




            </div>, document.body) : null}
        </div>
    )
}
ChatRoom.getColor = getColor
export default ChatRoom;
