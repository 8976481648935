import React, { Fragment, useEffect, useState } from "react";
import { Table } from "reactstrap";
import { toast } from "react-toastify";
import creService from "../../services/creService";
import BuidlingBasedCompilance from "./partials/buidlingBasedCompilance";
import ElevatorBasedCompilance from "./partials/elevatorBasedCompilance";
import HPDRegistrationCompilance from "./partials/hpdRegistrationCompilance";
import DepBoilerCompilance from "./partials/depBoilerCompilance";
import { Link } from 'react-router-dom';
import moment from 'moment';
import { DatePicker } from 'antd';
import ReactLoading from "react-loading";
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { exportViolationEvents } from '../../services/exportExcel';

const Compliance = (props) => {

    const [dataTable, setDataTable] = useState([]);
    const [loading, setLoading] = useState(true);
    const [info, setInfo] = useState("");
    const [year, setYear] = useState()

    const loadingComponent = async (category) => {
        try {
            setLoading(true);
            const api = await creService.getComplianceRequirements(category, year);
            const data = await creService.getComplianceInfo(category);
            setInfo(data?.data?.complianceInfo?.text);
            setDataTable(api.data);
            setLoading(false);
        } catch (e) {
            toast.error(e.message)
        }
    };

    useEffect(() => {
        if (props.board) {
            loadingComponent(props.board);
        }
        // eslint-disable-next-line    
    }, [props.board, year])

    const exportToCSV = () => {

        if (props.board.toUpperCase().includes('CAT')) {
            const headers = [
                { header: 'Address', key: 'address' },
                { header: 'Device', key: 'device' },
                { header: 'Due', key: 'date' }
            ]
            const csvData = []
            for (let item of dataTable) {
                for (let elevator of item.elevators) {
                    csvData.push({
                        address: item.fullAddress,
                        device: `Device# ${elevator.deviceNumber}`,
                        date: elevator.dueDate,
                    })
                }
            }

            return exportViolationEvents(headers, csvData, props.board)
        } else if (props.board.toUpperCase() === 'HPD_REGISTRATION') {

            const headers = [
                { header: 'Address', key: 'address' },
                { header: 'Registration Id#', key: 'id' },
                { header: 'Last Registration Date', key: 'lrdate' },
                { header: 'Due', key: 'rdate' }
            ]
            const csvData = []
            for (let item of dataTable) {
                const registrations = item.violationsDetails?.filter(el => !props.addedDevices?.includes(el.violationData.registrationid))
                if (registrations.length) {

                    csvData.push({
                        address: item.fullAddress,
                        id: registrations[0].violationData.registrationid,
                        lrdate: registrations[0].violationData.lastregistrationdate,
                        rdate: registrations[0].violationData.registrationenddate,
                    })

                }
            }
            return exportViolationEvents(headers, csvData, props.board)
        } else if (props.board.toUpperCase() === 'DEP_BOILER') {
            const headers = [
                { header: 'Address', key: 'address' },
                { header: 'Application ID# ', key: 'id' },
                { header: 'Due', key: 'rdate' }
            ]
            const csvData = []
            for (let item of dataTable) {

                const aplications = item.violationsDetails?.filter(el => !props.addedDevices?.includes(el.violationData.applicationid))
                for (let application of aplications) {
                    csvData.push({
                        address: item.fullAddress,
                        id: application.violationData.applicationid,
                        rdate: application.violationData.expirationdate,
                    })
                }
            }
            return exportViolationEvents(headers, csvData, props.board)
        } else if (['Water_Tank', 'PBS'].includes(props.board)) {
            const headers = [
                { header: 'Address', key: 'address' },
                { header: 'ID', key: 'id' },
                { header: 'Due', key: 'rdate' }
            ]
            const csvData = []
            for (let item of dataTable) {

                for (let violation of item.violationsDetails) {
                    const date = violation.violationData.expirationDate ? violation.violationData.expirationDate : violation.violationData.inspectionDate
                    csvData.push({
                        address: item.fullAddress,
                        id: violation.violationData.cre_generated_id,
                        rdate: date,
                    })
                }
            }
            return exportViolationEvents(headers, csvData, props.board)
        } else if (props.board.toUpperCase() === 'BOILER') {

            const headers = [
                { header: 'Address', key: 'address' },
                { header: 'ID', key: 'id' },
                { header: 'Last Inspection Date', key: 'idate' },
                { header: 'Due', key: 'rdate' }
            ]
            const csvData = []
            for (let item of dataTable) {

                const boilers = item.boilerAsObject?.filter(el => !props.addedDevices?.includes(el.boilerId))

                for (let { boilerId, inspection_date } of boilers) {
                    csvData.push({
                        address: item.fullAddress,
                        rdate: item.dueDate,
                        idate: inspection_date,
                        id: boilerId,
                    })
                }


            }
            return exportViolationEvents(headers, csvData, props.board)
        } else if (props.board.toUpperCase() === 'LL11') {

            const headers = [
                { header: 'Address', key: 'address' },
                { header: 'Start Date', key: 'sdate' },
                { header: 'Due', key: 'rdate' }
            ]
            const csvData = []
            for (let item of dataTable) {

                csvData.push({
                    address: item.fullAddress,
                    rdate: item.dueDate,
                    idate: item.startDate,
                })


            }
            return exportViolationEvents(headers, csvData, props.board)
        } else {

            const headers = [
                { header: 'Address', key: 'address' },
                { header: 'Due', key: 'rdate' }
            ]
            const csvData = []
            for (let item of dataTable) {

                csvData.push({
                    address: item.fullAddress,
                    rdate: item.dueDate,
                })


            }
            return exportViolationEvents(headers, csvData, props.board)
        }


    }

    return (
        <Fragment>
            {info && !props.noGuide && <div className="custom_tab tab-sec" style={{ padding: '10px 30px' }}>
                <div dangerouslySetInnerHTML={{ __html: info }} />
            </div>}
            <div className="table-responsive-sm">

                <div className="d-flex justify-content-end mb-3">
                    <Button
                        style={{ cursor: 'pointer' }}
                        className="hide-btn-wrapper mr-2"
                        disabled={!dataTable.length}
                        onClick={exportToCSV}
                    >
                        <DownloadOutlined className="pr-2" />
                        Export to CSV
                    </Button>
                    {['LL152', 'LL87', 'LL11'].includes(props.board) && <>


                        <DatePicker picker='year' onChange={(value) => setYear(value?.format('YYYY'))} disabledDate={(current) => current.year() < moment().year()} />

                    </>
                    }
                </div>

                <Table className="sec-table">
                    <thead>
                        <tr>
                            <th>Address</th>
                            {['Water_Tank', 'PBS'].includes(props.board) && <th>ID</th>}
                            {(props.board === 'LL11') && <th>Start Date</th>}
                            <th>Due</th>

                        </tr>
                    </thead>
                    <tbody>
                        {
                            (loading) ? <tr align="center"><td colSpan={3}> <ReactLoading type={"bubbles"} color={"grey"} /> </td></tr> :
                                dataTable.length === 0 ? <tr><td colSpan="3">Everything looks compliant!</td></tr> :

                                    dataTable.map((value, key) => {


                                        if (props.board.toUpperCase().includes('CAT')) {
                                            return <ElevatorBasedCompilance addedDevices={props.addedDevices} value={value} key={key} history={props.history} />
                                        } else if (props.board.toUpperCase() === 'HPD_REGISTRATION') {
                                            return <HPDRegistrationCompilance addedDevices={props.addedDevices} board={props.board} value={value} key={key} />
                                        } else if (props.board.toUpperCase() === 'DEP_BOILER') {
                                            return <DepBoilerCompilance addedDevices={props.addedDevices} board={props.board} value={value} key={key} />
                                        } else if (['Water_Tank', 'PBS'].includes(props.board)) {
                                            return <>
                                                {value.violationsDetails?.map((violation) => {

                                                    const date = violation.violationData.expirationDate ? violation.violationData.expirationDate : violation.violationData.inspectionDate
                                                    return <tr key={violation._id}>
                                                        <td>
                                                            <div>
                                                                <Link to={`/violations/all/${value.bin}`}>
                                                                    <i className="fa fa-building"></i>
                                                                </Link>
                                                                {value.fullAddress}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <Link to={`/violation-details/${violation._id}`}>{violation.violationData.cre_generated_id}</Link>
                                                            </div>
                                                        </td>

                                                        <td>{(date && moment(date).isValid()) ? moment(date).format('MM/DD/YYYY') : date}</td>

                                                    </tr>
                                                })}
                                            </>

                                        } else {

                                            return <BuidlingBasedCompilance
                                                key={key}
                                                addedDevices={props.addedDevices}
                                                value={value}
                                                board={props.board}
                                                setYear={setYear}
                                            />
                                        }
                                    })
                        }
                    </tbody>
                </Table>
            </div>
        </Fragment>
    )
}

export default Compliance
