import React, { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Dropdown, Menu, Input, Modal, Spin, Tooltip } from 'antd';
import {
  SettingOutlined,
  DeleteOutlined,
  TableOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  DownCircleTwoTone,
  AppstoreAddOutlined,
  DashOutlined,
  PicRightOutlined,
  UpCircleTwoTone,
  UsergroupDeleteOutlined,
} from '@ant-design/icons';
import creService from '../../../services/creService';

const ColumnLabelInput = (props) => {
  const [isEditable, setIsEditable] = useState(false);
  const [displayLabel, setDisplayLabel] = useState(props.defaultValue);
  const [showModal, setShowModal] = useState(false);
  const [removing, setRemoving] = useState(false);
  const [isIdentifyingData, setIsIdentifyingData] = useState(false);
  const [hasData, setHasData] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [subUserRestriction, setSubUserRestriction] = useState(props.subUserRestriction);
  const [addToCalendar, setAddToCalendar] = useState(props.column.addToCalendar);
  const [addToCustomDashboard, setAddToCustomDashboard] = useState(props.column.customDashboard);
  const editableRef = useRef(null);
  const [visible, setVisible] = useState(false);
  const [sortOrder, setSortOrder] = useState(false)

  useEffect(() => {
    setSortOrder(props.column.sortOrder)
  }, [props.column.sortOrder]);

  useEffect(() => {
    if (!props.field.cannotBeRenamed) {
      document.addEventListener('click', handleClickOutside, true);
      document.addEventListener('keydown', saveByEnter, true);
      return () => {
        document.removeEventListener('click', handleClickOutside, true);
        document.addEventListener('keydown', saveByEnter, true);
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editableRef, isEditable]);


  const saveByEnter = useCallback(async (event) => {
    if (event.key === 'Enter') {
      if (isEditable) {
        event.stopPropagation();
        await handleClickOutside(event);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickOutside = async (event) => {
    if (
      editableRef.current &&
      (editableRef.current?.input !== event.target || event.key === 'Enter')
    ) {
      const field = JSON.parse(
        editableRef.current.input.getAttribute('data-field')
      );
      const value = editableRef.current.input.value;
      if (
        editableRef.current.input.value &&
        field.name !== editableRef.current.input.value
      ) {
        try {
          const result = await creService.updateCustomCategoryField(
            field._id,
            value
          );
          setDisplayLabel(value);
          if (result.data.status) {
            editableRef.current.input.setAttribute(
              'data-field',
              JSON.stringify({
                ...field,
                [field.name]: editableRef.current.input.value,
              })
            );
          } else {
            toast.error(result.data.message);
          }
        } catch (err) {
          toast.error(err.message);
        }
      } else {
        setDisplayLabel(field.name);
      }
      setIsEditable(false);
    }
  };

  const onRemove = async () => {
    setShowModal(true);
    setIsIdentifyingData(true);
    const { data } = await creService.isCustomFieldHasData(props.field._id);
    setIsIdentifyingData(false);
    setHasData(data.hasData);
    if (!data.hasData) {
      setRemoving(true);
      await creService.deleteCustomCategoryField(props.field._id);
      props.handleColumnDelete(props.field._id);
      setRemoving(false);
      setShowModal(false);
      toast.success('Column deleted successfully');
    }
  };

  const menuItemsHandler = ({ key }) => {

    let sholdClose = true

    if (key === 'remove') {
      onRemove()
    } else if (key === 'pivotTableView') {
      props.showPivotTableHandler(props.columnKey)
    } else if (key === 'asignOnlyCheckbox') {
      props.handleSubUserRestriction(props.columnKey)
      setSubUserRestriction(!subUserRestriction)
    } else if (key === 'multisort') {
      setSortOrder(props.handleMultiSort(props.columnKey, true))
      sholdClose = false
    } else if (key === 'hide') {
      props.handleToggleVisibility(props.columnKey);
    } else if (key === 'calendar') {
      props.handleColumnToggle(props.columnKey, 'addToCalendar', async (promise) => {
        setShowModal(true);
        setIsIdentifyingData(true);
        await promise;
        setShowModal(false);
        setIsIdentifyingData(false);
      })
      setAddToCalendar(!addToCalendar)
    } else if (key === 'customDashboard') {
      props.handleColumnToggle(props.columnKey, key)
      setAddToCustomDashboard(!addToCustomDashboard)
    }


    if (sholdClose) {
      setVisible(false)
    }

  }

  const menuItems = [

  ]
  if (!props.column.linkFunc && props.column.key !== 'action' && props.column.key !== 'address') {

    menuItems.push({
      icon: <DashOutlined />,
      label: 'Hide',
      key: 'hide',
    })
  }

  if (
    (props.column.data?.type === 'date' || /date/i.test(props.column.description + '_' + props.column.key))
  ) {
    menuItems.push({
      icon: addToCalendar ? <CheckCircleTwoTone /> : <AppstoreAddOutlined twoToneColor='gray' />,
      label: addToCalendar ? 'Remove from Calendar' : 'Add to Calendar',
      key: 'calendar',
    })

  } else if (!(
    props.field.bcType === 'chat' ||
    props.field.bcType === 'notes' ||
    props.field.bcType === 'files' ||
    props.field.bcType === 'uploads' ||
    props.field.bcType === 'links' ||
    props.field.bcType === 'priority' ||
    props.column.fixed
  )
  ) {
    menuItems.push({
      icon: addToCustomDashboard ? <CheckCircleTwoTone /> : <CloseCircleTwoTone twoToneColor='gray' />,
      label: addToCustomDashboard ? 'Remove from Dashboard' : 'Add to Dashboard',
      key: 'customDashboard',
    })
  }


  if (!(props.field.bcType === 'chat' ||
    props.field.bcType === 'notes' ||
    props.field.bcType === 'files' ||
    props.field.bcType === 'uploads' ||
    props.field.bcType === 'links' ||
    props.field.bcType === 'priority' ||
    props.isPivotMasterProperty)) {
    menuItems.push({
      icon: <TableOutlined />,
      label: 'Pivot Table',
      key: 'pivotTableView',
    })
  }

  if (!(props.field?.bcType === 'priority')) {
    menuItems.push({
      icon: sortOrder ? (sortOrder === 'descend' ? <DownCircleTwoTone /> : <UpCircleTwoTone />) :
        <PicRightOutlined twoToneColor='gray' />,
      label: 'Secondary Sorting',
      key: 'multisort',
    })
  }

  if (props.field.bcType === 'users') {
    menuItems.push({
      icon: subUserRestriction ? <UsergroupDeleteOutlined /> : <CloseCircleTwoTone />,
      label: 'Assign only users',
      key: 'asignOnlyCheckbox',
    },)
  }


  if (!props.field.cannotBeDeleted) {
    menuItems.push({
      icon: <DeleteOutlined />,
      label: 'Remove',
      key: 'remove',
    })
  }


  return (
    <div
      onClick={(e) => e.stopPropagation()}
      onMouseEnter={(e) => setShowOptions(true)}
      onMouseLeave={(e) => {
        setShowOptions(false);
        visible && setVisible(false)
      }}
      className="d-flex justify-content-between align-items-center"
    >
      {isEditable && (
        <Input
          {...props}
          size="small"
          defaultValue={displayLabel}
          ref={editableRef}
          data-field={JSON.stringify(props.field || {})}
        />
      )}
      {!isEditable && (
        <Tooltip placement="top" title={displayLabel}>
          <Button
            className="dragHandler pl-0 pr-0"
            style={{ width: '100%' }}
            type="text"
            onClick={() => !props.field.cannotBeRenamed && setIsEditable(true)}
          >
            {displayLabel}
          </Button>
        </Tooltip>
      )}
      {showOptions && menuItems.length > 0 && (
        <div onClick={(e) => e.stopPropagation()}>
          <Dropdown
            // trigger={'click'}

            visible={visible}
            onMouseEnter={() => { !visible && setVisible(true) }}
            className='border-0'
            overlay={
              <Menu
                className='noselect'
                onClick={menuItemsHandler}
                items={menuItems}
              ></Menu>
            }
            placement="bottom"
            arrow
          >
            <Button size="small">
              <SettingOutlined style={{ marginTop: '4px', border: 'none' }} />
            </Button>
          </Dropdown>
        </div>
      )}
      <Modal visible={showModal} footer={null} closable={false}>
        {(isIdentifyingData || removing) && (
          <div className="d-flex justify-content-center align-items-center flex-column">
            <Spin />
            {isIdentifyingData ? 'Loading...' : 'Removing...'}
          </div>
        )}
        {!isIdentifyingData && !removing && hasData && (
          <div className="d-flex justify-content-center align-items-center flex-column">
            <p>
              You can’t remove this column "{props.field.name}" as it has data
              so you can only hide.
            </p>
            <div>
              <Button
                type="default"
                onClick={() => setShowModal(false)}
                className="mr-3"
              >
                Cancel
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  props.handleToggleVisibility(props.field._id);
                  setShowModal(false);
                }}
              >
                Hide
              </Button>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default ColumnLabelInput;
