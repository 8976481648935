import React, { Fragment } from "react";
import ReactLoading from "react-loading";


const ViolationsTabEachBlock = (props) => {

  function onClick(currentValue) {
    if(currentValue[1] !== 0 && currentValue[1] !== '$0.00') {
      localStorage.setItem('prev-location', '/violations-tab');
      props.history.push(`/violations-tab-details/${props.title}/${currentValue[0]}`)
    }
  }

  return (
    <Fragment>
      <div className="col-6 col-xl-3 col-md-4 col-sm-6 d-flex">
        <div className="card fix-card flex-fill">
          <h4>{props.title}</h4>
          <div className="card-body">
            <div className="media-body">
              {!props.loading ?

                    Object.entries(props.data).map((value, index) =>{

                      return <h6 key={ index } className={(value[1] !== 0 && value[1] !== '$0.00' ? 'pointer' : '')} onClick={() => onClick(value)}>{value[0]}: <span>{value[1]}</span> </h6>
                    })

                  : <div align="center"> <ReactLoading type={"cylon"} color={"grey"} /> </div>}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ViolationsTabEachBlock;
