import LocalState from "../../../common/localState";

const TABLE_SIZE = 'TABLE_SIZE';
const TABLE_HIDE = 'TABLE_HIDE';

export const tableDisplayNames = (violationsData, value) => {
    const name = violationsData?.data?.displayName.find(v => (v.apiField.toLowerCase() === value || v.apiField === value))?.displayName;
    return (name ? name : value).replace(/ /g, '');
}

export const convertToDisplayName = (displayNames, value) => {
    let convertedDisplayName = displayNames.find(
        v => (v.apiField.toLowerCase() === value || v.apiField === value)
    );
    return (convertedDisplayName ? convertedDisplayName.displayName : value).replace(/ /g, '');
}

export const getTableHeaderFields = (data, sub) => {
    let tableHeaders = [];
    for (const violation of data?.violationsData[sub]) {
        for (const field of Object.keys(violation.violationData)) {
            if (!tableHeaders.includes(field)) {
                tableHeaders.push(field);
            }
        }
    }
    tableHeaders.splice(2, 0, "Address");
    return tableHeaders;
}

export const getTableSizeFromStorage = () => {
    const tableSize = LocalState.getItem(TABLE_SIZE)

    if (!tableSize) {
        setTableSizeInStorage('middle');
        return 'middle'
    }

    return tableSize
}

export const setTableSizeInStorage = (value, defaultValue='middle') => {
    const options = ['large', 'middle', 'small'];

    if (options.includes(value)) {
        LocalState.setItem(TABLE_SIZE, value)
    } else {
        LocalState.setItem(TABLE_SIZE, defaultValue)
    }
}

export const getHideColumns = (key) => {
    let result = LocalState.getItem(TABLE_HIDE + ':' + key);
    if (result) {
        return JSON.parse(result);
    }
    return null;
}

export const setHideColumns = (object, key) => {
    LocalState.setItem(TABLE_HIDE + ':' + key, object);
}
