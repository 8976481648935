import React, { useState, useRef } from 'react';
import { Input, Spin } from 'antd';
import { toast } from 'react-toastify';
import {
  LoadingOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import { useEffect } from 'react';

const LoadingInput = ({ onChange, data }) => {
  const [loading, setLoading] = useState(false);
  const [successInSaving, setSuccessInSaving] = useState(false);
  const [inputWasChanged, setInputWasChanged] = useState(false);
  const [showSuffix, setShowSuffix] = useState(false);
  const [startValue, setStartValue] = useState('');
  const [value, setValue] = useState(data);
  const valueRef = useRef(data);
  const timeoutRef = useRef();

  useEffect(() => {
    setValue(data)
  }, [data])


  const onSave = async (newValue) => {
    valueRef.current = newValue;
    setInputWasChanged(true);
    setLoading(true);
    setShowSuffix(true);
    try {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      await onChange(valueRef.current);
      setSuccessInSaving(true);
      setLoading(false);
      setShowSuffix(false);
      setInputWasChanged(false);

    } catch (err) {
      toast(err);
    }
  };

  const getSuffixToShow = () => {
    if (!inputWasChanged) return;

    switch (true) {
      case loading:
        return (
          <Spin
            indicator={
              <LoadingOutlined
                style={{
                  fontSize: 16,
                }}
                spin
              />
            }
          />
        );
      case !loading && !successInSaving:
        return (
          <Spin
            indicator={
              <CloseOutlined
                style={{
                  fontSize: 16,
                }}
              />
            }
          />
        );
      case !loading && successInSaving:
        return (
          <Spin
            indicator={
              <CheckOutlined
                style={{
                  fontSize: 16,
                }}
              />
            }
          />
        );
      default:
        return null;
    }
  };
  return (
    <Input
      style={{ width: '200px' }}
      onChange={(event) => { setValue(event.target.value); event.constructor.name === 'Class' && onSave(event.target.value) }}

      onKeyPress={(e) => e.key === 'Enter' && e.target.blur()}
      onFocus={() => setStartValue(value)}
      onBlur={() => startValue !== value && onSave(value)}
      autoComplete="off"
      value={value}
      // allowClear
      suffix={showSuffix && getSuffixToShow()}
    />
  );
};

export default LoadingInput;
