import React, { Fragment, createRef, useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import Papa from 'papaparse';
import moment from 'moment';
import { toast } from 'react-toastify';

import {
  getDropDownYears,
  isTenantEditingAvaliable,
} from '../../services/helperService';
import UnitNoExistsMessage from './unitNotExistsMessage';
import creService from '../../services/creService';

const columns = [
  'Bin',
  'Unit/Apt#',
  'Tenant Name',
  'Tenant Email',
  'Tenant Phone',
  'Notes',
  'Allow Multiple Tenants',
];
const columnsKeys = [
  'bin',
  'aptNumber',
  'name',
  'email',
  'phone',
  'notes',
  'allowMultipleTenants',
];


const dropdownYears = getDropDownYears();

const ImportTenants = () => {
  const history = useHistory();

  const fileRef = createRef();


  const [buildings, setBuildings] = useState([]);
  const [selectedBin, setSelectedBin] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState(null);
  const [isAlreadyUploaded, setIsAlreadyUploaded] = useState(false);
  const [dataOfSelectedFile, setDataOfSelectedFile] = useState([]);
  const params = useParams();
  const [year, setYear] = useState(() => {
    if (params.year) {
      return params.year;
    }
    return moment().format('YYYY');
  });
  const [isCreationAvaliable, setIsCreationAvaliable] = useState(
    isTenantEditingAvaliable(year)
  );
  const loadBuildings = async () => {
    const buildingRes = await creService.getBuildingsListOnly('safetyMailings');
    setBuildings(buildingRes.data);
  };

  useEffect(() => {
    history.replace(`/tenants/import/${year}`);
    setIsCreationAvaliable(isTenantEditingAvaliable(year));
  }, [year, history]);

  useEffect(() => {
    loadBuildings();
  }, []);

  const downloadTemplateFile = () => {
    if (selectedBin) {
      const rows = [columns, [selectedBin, '', '', '', '', '']];
      console.log(rows)
      const csvContent = `${rows.map((e) => e.join(',')).join('\n')}`;
      const pom = document.createElement('a');
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      pom.href = URL.createObjectURL(blob);

      pom.setAttribute(
        'download',
        `Tenant List Template for - ${buildings.find((build) => String(build.bin) === selectedBin)?.address
        }.csv`
      );
      pom.click();
    } else {
      toast.error('Please select a building from the dropdown.');
    }
  };

  const onFileSelect = (event) => {
    setIsAlreadyUploaded(false);
    const inputFile = event.target;
    const csvFile = event.target.files[0];
    const reader = new FileReader();
    setSelectedFileName(csvFile.name);
    reader.readAsText(csvFile);
    reader.onload = function (e) {
      Papa.parse(e.target.result, {
        complete: (results) => {
          inputFile.value = '';
          let rowsData = [];
          for (var i = 1; i < results.data.length; i++) {
            const cells = results.data[i];
            if (cells[0].length >= 1) {
              let record = {};
              if (cells.length > 1) {
                for (var j = 0; j < cells.length; j++) {
                  if (columnsKeys[j]) {
                    Object.assign(record, {
                      [columnsKeys[j]]: cells[j],
                    });
                  }
                }
              }
              rowsData.push(record);
            }
          }
          setDataOfSelectedFile(rowsData);
        },
      });
    };
  };

  const uploadTenantData = async () => {
    if (isAlreadyUploaded) {
      toast.error('The selected file was already uploaded.');
    } else {
      try {
        if (year !== 'general') {
          for (let i = 0; i < dataOfSelectedFile.length; i++) {
            dataOfSelectedFile[i].year = year;
          }
        }

        const { data } = await creService.uploadBulkTenants(dataOfSelectedFile);
        if (data.status) {
          toast.success(data.message);
          setDataOfSelectedFile([
            ...data.failed,
            ...data.updated,
            ...data.created,
          ]);
          setIsAlreadyUploaded(true);
          history.push('/tenants/list');
        } else {
          toast.error(<UnitNoExistsMessage {...data} />);
        }
        setIsLoading(false);
    
      } catch (_err) {
        toast.error(_err.message);
        setIsLoading(false);
      }
    }
  };

  return (
    <Fragment>
      <div className="row violation-sec">
        <div className="col-xl-12 my-3">
          <div className="card">
            <div className="card-header">
              <div className="d-flex align-items-center justify-content-lg-between width-100">
                <div className="d-flex">
                  <Link to="/tenants/list">
                    <button
                      onClick={() => { history.goBack(); }}
                      className="btn btn-light btn-lg align-self-center back-btn mx-2"
                      type="button"
                    >
                      <i className="fa fa-angle-left" />
                    </button>
                  </Link>
                  <h5>Import Tenants</h5>
                </div>
                {year !== 'general' && <select
                  className="form-control ml-3"
                  style={{ width: '250px' }}
                  required
                  name="year"
                  value={year}
                  onChange={(e) => setYear(e.target.value)}
                >
                  {dropdownYears.map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
                }
              </div>
            </div>
            {isCreationAvaliable ? (
              <div className="card-body">
                <div className="row d-flex align-items-end">
                  <div className="form-group col-8">
                    <label>Building</label>
                    <select
                      className="form-control"
                      name="bin"
                      onChange={(e) => setSelectedBin(e.target.value)}
                      defaultValue={selectedBin}
                    >
                      <option value="">-- Select Building --</option>
                      {buildings &&
                        buildings.map((building) => (
                          <option
                            key={`building_${building.bin}`}
                            value={building.bin}
                          >
                            {building.display_address
                              ? building.display_address
                              : building.address} [BIN: {building.bin}]
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="form-group col-4">
                    <button
                      className="btn btn-link"
                      onClick={() => downloadTemplateFile()}
                    >
                      Download Template File (csv)
                    </button>
                  </div>
                </div>
                <hr />
                <h5 className="mb-3">Upload Tenants List</h5>
                <div className="form-group">
                  <label>Choose CSV File</label>
                  <input
                    ref={fileRef}
                    type="file"
                    name="file"
                    className="form-control"
                    accept=".csv"
                    onChange={(event) => onFileSelect(event)}
                  />
                </div>
                {dataOfSelectedFile.length > 0 && (
                  <>
                    <div className="table-responsive">
                      <h5 className="mt-2 mb-1">
                        Data Preview - {selectedFileName}
                      </h5>
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>#</th>
                            {isAlreadyUploaded && <th>Status</th>}
                            <th>BIN</th>
                            <th>Unit/Apt#</th>
                            <th>Tenant Name</th>
                            <th>Tenant Email</th>
                            <th>Tenant Phone</th>
                            <th>Notes</th>
                            <th>Allow Multiple Tenants</th>
                          </tr>
                        </thead>
                        <tbody>
                          {dataOfSelectedFile.map((row, index) => (
                            <tr>
                              <td>{index + 1}</td>
                              {isAlreadyUploaded && (
                                <td>
                                  {typeof row.failed !== 'undefined' &&
                                    row.failed && (
                                      <small className="text-danger d-block">
                                        {row.message}
                                        {row.tenantId && (
                                          <Link
                                            to={`/tenants/edit/${row.tenantId}`}
                                            target="_blank"
                                            className="ml-2 btn btn-link btn-xs"
                                          >
                                            Edit
                                          </Link>
                                        )}
                                      </small>
                                    )}
                                  {typeof row.state !== 'undefined' &&
                                    row.state && (
                                      <small className="text-dark ml-2">
                                        {row.state === 'updated'
                                          ? 'Updated'
                                          : 'Newly Created'}
                                      </small>
                                    )}
                                </td>
                              )}
                              <td>{row.bin}</td>
                              <td>{row.aptNumber}</td>
                              <td>{row.name ? row.name : row.fullName}</td>
                              <td>{row.email}</td>
                              <td>{row.phone}</td>
                              <td>{row.notes}</td>
                              <td>{row.allowMultipleTenants ? 'Yes' : 'No'}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <button
                      className="btn btn-primary mt-3"
                      onClick={() => uploadTenantData()}
                      disabled={isLoading}
                    >
                      {isLoading ? 'Uploading...' : 'Upload'}
                    </button>
                  </>
                )}
              </div>
            ) : (
              <div className="alert alert-info">
                Unable to import tenants in {year}
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};


export default ImportTenants;
