import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { toast } from 'react-toastify';
import moment from 'moment';
import { Skeleton } from 'antd';
import styled from 'styled-components';

import creService from '../../../services/creService';





const ColumnItem = styled.div`
    border-radius: 8px;
    border: 1px solid transparent;
    background-color: #fff;
    box-shadow: 0px 0px 3px #a3a3a3;
    box-sizing: border-box;
    padding: 2px;
    margin: 5px;

    .id {
        color: #4466f2;
        font-size: 15px;
    }

    .dueDate {
        color: #7e7e7e;
        font-size: 12px;
    }

    .title {
        font-size: 15px;
        color: #747474;
        padding: 5px 0;
    }

    .address {
        font-size: 12px;
        color: #585858;
    }
`;

const ColumnList = styled.div`
    display: flex;
    justify-content: center;
    align-items: self-start;
`;
const Container = styled.div`
    display: flex;
    flex: 1;
    background: #eee;
    padding: 10px;
    flex-direction: column;
    width: 100%;
    border-right: 5px solid #fff;
    &:last-child {
        border: none;
    }
`;
const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
    text-transform: capitalize;
    margin-bottom: 10px;
`;
const findDate = (item) => {
    if (item.customFieldData?.[0]?.fields) {
        for (let field of item.customFieldData[0].fields) {
            if (typeof field.data === 'string' && moment(field.data).isValid()) {
             
                return field.data
            }
        }
    }

    return '- -'
}
const findKey = (el) => {
    const keys = Object.values(el.additionalKeys)
    return keys.length ? Object.values(el.additionalKeys)[0] : '- -'
}



const ViewByColumn = ({ data: { dataSource, category, sub } }) => {

    const [statusColumn, setStatusColumn] = useState(null);
    const [statusData, setStatusData] = useState({})
    const findAndSetStatuses = async () => {
        const categories = await creService.getCustomFields(category, sub);
        if (categories.data?.customFields?.fields) {
            const fields = categories.data?.customFields.fields
            for (let field of fields) {
                if (field.cannotBeDeleted) {
                    field.data.unshift({ text: 'No status', color: '#909090', id: 'undefined' })
                    setStatusColumn(field)
                    const resultData = dataSource.reduce((ac, item) => {
                        const id = item.customFieldData?.[0]?.fields?.find(el => el.fieldId === field._id)?.data || 'undefined'
                        if (!ac[id]) { ac[id] = [] }
                        ac[id].push(item)
                        return ac
                    }, {})
                    setStatusData(resultData)
                    break;
                }
            }
        }
    };



    useEffect(() => {
        if (dataSource) {
            findAndSetStatuses()

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataSource])

    const onDragEnd = async ({ source, destination }) => {
        // dropped outside the list
        if (!destination) {
            return;
        }

        const item = statusData[source.droppableId][source.index]
        let updatedDataDrop
        if (statusData[destination.droppableId]) {
            updatedDataDrop = [
                ...statusData[destination.droppableId].slice(0, destination.index),
                item,
                ...statusData[destination.droppableId].slice(destination.index),
            ]
        } else {
            updatedDataDrop = [item]
        }
        try {
            const result = await creService.updateCustomViolationField(
                item._id,
                {
                    fieldId: statusColumn._id,
                    violationClientDataId: item.clientDataId,
                    data: destination.droppableId === 'undefined' ? '' : destination.droppableId,
                },
                'customCompliance'
            );
            if (!result.data.status) {
                return toast.error(`${result.data.message}, 
                                server error - [${result.data.error}]`);
            }


            setStatusData({
                ...statusData,
                [source.droppableId]: [
                    ...statusData[source.droppableId].slice(0, source.index),
                    ...statusData[source.droppableId].slice(source.index + 1),
                ],
                [destination.droppableId]: updatedDataDrop
            })
        } catch (err) {
            toast.error(err.message);
        }
    }



    return statusColumn ? (
        <div className="custom_tab tab-sec ">
            <DragDropContext onDragEnd={onDragEnd}>
                <ColumnList >
                    {
                        statusColumn.data.map(status => {
                            return (<Droppable droppableId={status.id} className="dropable-column" key={status.id}>
                                {(provided, snapshot) => (
                                    <div className='flex-1 flex-grow-1 p-1 overflow-auto align-self-stretch d-flex flex-column'>
                                        <Header isDragging={snapshot.isDragging} className='text-center' >
                                            <span style={{ color: status.color }} className='pr-1'>{status.text}</span>  {statusData[status.id]?.length || 0}
                                        </Header>

                                        <Container {...provided.droppableProps} ref={provided.innerRef} >
                                            {statusData[status.id]?.map((el, i) => (
                                                <Draggable key={el._id} draggableId={el._id} index={i} >
                                                    {(provided) => (
                                                        <div

                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <ColumnItem>
                                                                <div className="d-flex justify-content-between align-items-center mb-3 flex-wrap">
                                                                    <a href={`/custom-compliance/${sub}/${el._id}`} target='_blank' rel="noreferrer">
                                                                        <div className="id" >{findKey(el)}</div>
                                                                    </a>
                                                                    <div className="dueDate">{findDate(el)}</div>
                                                                </div>

                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <div className="address">{el.address?.split(',')[0]}</div>
                                                                </div>
                                                            </ColumnItem>
                                                        </div>
                                                    )}
                                                </Draggable>))}

                                            {provided.placeholder}
                                        </Container>
                                    </div>
                                )}
                            </Droppable>)
                        })
                    }

                </ColumnList>
            </DragDropContext>
        </div>
    ) : (<Skeleton active />);

}

export default ViewByColumn;
