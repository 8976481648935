import React from 'react';
import moment from 'moment';
import { Link, useHistory } from "react-router-dom";

const BuidlingBasedCompilance = ({ value, addedDevices = [], board }) => {
    const generateBoilerHref = (violationId) => {
        return `/violation-details/${violationId}`
    }

    let history = useHistory();

    let boilers = value.boilerAsObject
    if (boilers) {
        boilers = value.boilerAsObject?.filter(el => !addedDevices.includes(el.boilerId))
        if (!boilers?.length) return null
    } else {
        if (addedDevices.includes(value.bin)) {
            return null
        }
    }


    return (
        <tr>
            <td>
                <Link to={`/violations/all/${value.bin}`}>
                    <i className="fa fa-building"></i></Link> {value.fullAddress}
                
                
                {(typeof value.notes !== "undefined")
                    ? <div><small>{value.notes} </small></div>
                    : ''
                }
                {(typeof value.violationid !== "undefined")
                    ? <div><span className="badge badge-light pointer" onClick={() => history.push(`/violation-details/${value._id}`)}>ViolationID: {value.violationid} </span></div>
                    : ''
                }
                {boilers
                    ? <div className='d-flex flex-wrap'>
                        {boilers.map(({ boilerId, id, bin, inspection_date }, index) =>
                            <span key={boilerId} className='mr-2 mb-1'>
                                <small> <Link to={generateBoilerHref(id, bin)}>{boilerId}</Link>{index < value.boilerIDList.length - 1 ? ',' : ''} </small>
                                <br />
                                <small>Last Inspection Date: {moment(new Date(inspection_date)).format('MM/DD/YYYY')}</small>
                            </span>)}
                    </div>
                    : ''
                }

            </td>
            {
                board === 'LL11' && <td>
                    {value.startDate}
                </td>
            }

            <td>
                {value.dueDate}
            </td>

        </tr>
    );
};

export default BuidlingBasedCompilance;
