import React, { Component, Fragment } from "react";
import creApi from "../../services/creService";
import { toast, ToastContainer } from "react-toastify";
import ReactLoading from "react-loading";
import HistoryUpdates from "../buildings/partials/historyUpdates";
import SmallCalendar from "../calendar/smallCalendar";

export default class PermitsDetails extends Component {
    state = this.props.jestData !== undefined ? this.props.jestData.state : {
        doneLoading: false,
        tableData: [{}],
        filter: {}
    };

    getData = async (category, sub) => {
        try {
            let apiData;

            apiData = await creApi.getPermitsAllTabDetails(category, sub);
            this.setState({
                doneLoading: true,
                tableData: apiData.data,
            });

        } catch (e) {
            toast.error(e.message)
        }
    }

    componentDidMount() {
        this.getData(this.props.match.params.category, this.props.match.params.sub);
    }

    onClick = (bin, category, sub) => {
        localStorage.setItem('prev-location', this.props.history.location.pathname);
        this.props.history.push(`/violations/table/${bin}/${category}/${sub}`)
    }

    handleBack = () => {
        //this.props.history.push(`/violations/all/${this.state.addressData.bin}`)
        this.props.history.push(`/permits`)
    };


    render() {
        return (
            <Fragment>
                <ToastContainer />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-9 violations-detail">
                            <div className="row violations-head">
                                <div className="d-flex">
                                    <button className="btn btn-light btn-xs align-self-center back-btn" onClick={this.handleBack} type="button"><i className="fa fa-angle-left"></i></button>
                                </div>
                                <div className="col-11 col-md-5 d-flex">
                                    <h5 className="align-self-center">{this.props.match.params.category + ' / ' + this.props.match.params.sub.replaceAll('_', ' ')} </h5>
                                </div>
                            </div>
                            <div className="card ribbon-wrapper">
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Address</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {!this.state.doneLoading ? (<tr><td colSpan="5"><div align="center"> <ReactLoading type={"cylon"} color={"grey"} /> </div></td></tr>) : (
                                                    this.state.tableData.map((value, key) => {
                                                        return <tr key={key} className="pointer"
                                                            onClick={() => this.onClick(value.violationsDetails[0].bin, value.match.category, value.match.sub)}>
                                                            <td><i className="fa fa-building"></i> {value.fullAddress}</td>
                                                            <td>{value.violationsDetails.length}</td>
                                                        </tr>
                                                    }
                                                    )
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="col-xl-3 recent-activity pr-0">
                            <div className="pt-3 pb-3">
                                {this.props.jestData !== undefined ? null : <SmallCalendar />}
                            </div>
                            <HistoryUpdates />
                        </div>
                    </div>
                </div>
            </Fragment>

        );
    }
}
