import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { Typeahead } from 'react-bootstrap-typeahead';
import { UncontrolledTooltip } from 'reactstrap';
import ReactLoading from "react-loading";
import creService from '../../../services/creService';
import SafetyMailingsAutoRulesPreview from './preview';



const SafetyMailingsAutoRulesForm = ({ formData, setFormData, isProcessing, isPreviewLoading, edit, isPreviewLoaded, previewData, onPreview, onSubmit, jestData = false }) => {

    const form = useRef(null);

    const [actionList, setActionList] = useState([])
    const [subUsers, setSubUsers] = useState(jestData ? jestData.subUsers : []);
    const [vendors, setVendors] = useState(jestData ? jestData.vendors : []);
    const [years, setYears] = useState(jestData ? jestData.years : []);
    const [categories, setCategories] = useState(jestData ? jestData.categories : []);
    const [buildings, setBuildings] = useState(jestData ? jestData.buildings : []);
    const [allBuildingsBins, setAllBuildingsBins] = useState([]);
    const [isVendorLoading, setVendorLoading] = useState(true);
    const [isUsersLoading, setUsersLoading] = useState(true);
    const [isCategoriesLoading, setCategoriesLoading] = useState(true);
    const [isBuildingLoading, setIsBuildingLoading] = useState(true);
    const [isYearsLoading, setIsYearsLoading] = useState(true);

    const onChange = (key, value) => {
        setFormData(state => ({
            ...state,
            [key]: value,
        }));
    }

    const fetchYears = async () => {
        setIsYearsLoading(true)
        try {
            const { data } = await creService.getSafetyMailingYears();
            setYears(data.data);
            setIsYearsLoading(false)
        } catch (_err) {
            toast.error(_err.message);
            setIsYearsLoading(false);
        }
    }

    const fetchActions = async () => {
    
        try {
            const { data } = await creService.getSafetyMailingActions();
            setActionList(data.data);
         
        } catch (_err) {
            toast.error(_err.message);
           
        }
    }
    

    const fetchVendors = async () => {
        setVendorLoading(true)
        try {
            const { data } = await creService.getWorkOrderVendors();
            if (data.status) {

                setVendors(data.vendors);
            }

            setVendorLoading(false)
        } catch (_err) {
            toast.error(_err.message);
            setVendorLoading(false);
        }
    }

    const fetchSubUsers = async () => {
        setUsersLoading(true)
        try {
            const { data } = await creService.getAssignees();
            if (data.status) {
                setSubUsers(data.data);
                setUsersLoading(false)
            } else {
                toast.error(data.message);
            }
        } catch (_err) {
            toast.error(_err.message);
            setUsersLoading(false);
        }
    }

    const fetchCategories = async () => {
        setCategoriesLoading(true);
        try {
            const { data } = await creService.getWorkOrderCategories();
            if (data.status) {
                setCategories(data.data);
            } else {
                toast.error(data.message);
            }
            setCategoriesLoading(false);
        } catch (_err) {
            toast.error(_err.message);
            setCategoriesLoading(false);
        }
    }

    const fetchBuildings = async () => {
        setIsBuildingLoading(true);

        try {
            const buildingRes = await creService.getBuildingsListOnly('safetyMailings');
            setBuildings(buildingRes.data);
            const binsArray = buildingRes.data.map(function (obj) {
                return obj.bin;
            });
            setAllBuildingsBins(binsArray)
            setIsBuildingLoading(false)
        } catch (_err) {
            toast.error(_err.message);
            setIsBuildingLoading(false)
        }
    }

    const handleBuildingCheck = (isChecked, bin) => {
        if (formData?.bins) {
            setFormData((state) => {
                let bins = [...formData.bins];
                if (isChecked) {
                    return ({
                        ...state,
                        bins: [...bins, bin]
                    })
                } else {
                    return ({
                        ...state,
                        bins: bins.filter(b => b !== bin)
                    })
                }

            });
        } else {
            setFormData({ ...formData, bins: [bin] })
        }
    }

    const handleUnAndCheckAll = (state) => {
        if (state) {
            setFormData({ ...formData, bins: allBuildingsBins })
        } else {
            setFormData({ ...formData, bins: [] });
        }
    }


    useEffect(() => {
        fetchActions()
        fetchYears();
        fetchVendors();
        fetchSubUsers();
        fetchCategories();
        fetchBuildings();
    }, [])

    const currentVendor = vendors.find((item) => formData.vendor === item._id);


    return (
        <form ref={form} className="form-horizontal">
            <div className="row">
                <div className="form-group col-12">
                    <h4>Autorule For</h4>
                </div>
                <div className="form-group col-12 col-md-6">
                    <label className="control-label">Year</label>
                    <select className="form-control" disabled={isYearsLoading} required name="year" value={formData.year} onChange={(e) => onChange('year', e.target.value)}>
                        <option value="">-- Select Year --</option>
                        {years.map((year, index) => (<option key={index} value={year}>{year}</option>))}
                    </select>
                </div>

                <div className="form-group col-12 col-md-6">
                    <label className="control-label">Action</label>
                    <select className="form-control" required name="action" value={formData.action} onChange={(e) => onChange('action', e.target.value)}>
                        <option value="">-- Select Action --</option>
                        {actionList.map((action, index) => (<option key={index} value={action}>
                            {action}
                        </option>))}
                    </select>
                </div>

                <div className="form-group col-12 mt-3">
                    <h4>Create Work Order</h4>
                </div>

                <div className="form-group col-12 col-md-6">
                    <label className="control-label">Add to Title</label>
                    <input type="text" className="form-control" name="addToTitle" value={formData.addToTitle} placeholder="Add to Title" onChange={(e) => onChange('addToTitle', e.target.value)} />
                </div>

                <div className="form-group col-12 col-md-6">
                    <label className="control-label">Category</label>
                    <select className="form-control" required disabled={isCategoriesLoading} value={formData.category} name="category" onChange={(e) => onChange('category', e.target.value)}>
                        <option value="">-- Select --</option>
                        {categories && categories.map((category, index) =>
                            <option key={index} value={category} selected={formData.category === category}>
                                {category}
                            </option>)}
                    </select>
                </div>


                <div className="col-12 d-flex mb-3">
                    <div className="custom-control custom-radio mr-3">
                        <input
                            required
                            type="radio"
                            className="custom-control-input"
                            name="isAllBuilding"
                            id="ForAllBuildings"
                            onClick={(e) => onChange('isAllBuilding', true)}
                            checked={formData.isAllBuilding}
                        />
                        <label className="custom-control-label" for="ForAllBuildings">Apply to All Buildings</label>
                    </div>
                    <div className="custom-control custom-radio">
                        <input
                            required
                            type="radio"
                            className="custom-control-input"
                            name="isAllBuilding"
                            id="selectBuildings"
                            checked={!formData.isAllBuilding}
                            onClick={(e) => onChange('isAllBuilding', false)}
                        />
                        <label className="custom-control-label" for="selectBuildings">Select Buildings</label>
                    </div>
                </div>

                {!formData.isAllBuilding &&
                    <div className="form-group col-12">
                        <label>Buildings</label>
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            <input type='checkbox' defaultChecked={false} onChange={(e) => handleUnAndCheckAll(e.target.checked)} />
                                        </th>
                                        <th scope="col">Address</th>
                                        <th scope="col">BIN</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        isBuildingLoading ?
                                            (<tr>
                                                <td colSpan="5">
                                                    <div align="center">
                                                        <ReactLoading type={"cylon"} color={"grey"} />
                                                    </div>
                                                </td>
                                            </tr>)
                                            :
                                            (buildings.map((building, key) => {
                                                return (
                                                    <tr key={key}>
                                                        <td>
                                                            <input type='checkbox' id={`bin${building.bin}`} className='building' checked={formData.bins?.includes(building.bin) ? true : false} onChange={(e) => handleBuildingCheck(e.target.checked, building.bin)} />
                                                        </td>
                                                        <td>{building.display_address ? building.display_address : building.address}</td>
                                                        <td>{building.bin}</td>
                                                    </tr>)
                                            }
                                            ))}
                                </tbody>
                            </table>
                        </div>

                    </div>
                }

                <div className="form-group col-12 col-md-6">
                    <label className="control-label">Due Date - (add number of days to current date, example: 2)</label>
                    <input type="number" required className="form-control" min={0} name="dueDate" value={formData.dueDate} placeholder="2" onChange={(e) => onChange('dueDate', e.target.value)} />
                </div>

                <div className="form-group col-12 col-md-6">
                    <label>Assign To</label>
                    <Typeahead
                        id="assignedToUsers"
                        multiple
                        disabled={isUsersLoading}
                        placeholder="Users"
                        onChange={(selected) => {
                            // handleAssignToChange(selected.map((b) => b._id));
                            onChange('assignedTo', selected.map((b) => b._id))
                        }}
                        selected={subUsers?.filter((b) => formData.assignedTo?.includes(b._id))}
                        labelKey={(option) => {
                            if (option.fullName) {
                                return option.fullName;
                            }
                            return option._id;
                        }}
                        options={subUsers}
                    />
                </div>


                <div className="form-group col-12">
                    <label>Vendor</label>
                    <select className="form-control" name="vendorId" disabled={isVendorLoading} value={formData.vendor} onChange={(e) => onChange('vendor', e.target.value)}>
                        <option value="">{isVendorLoading ? 'Loading...' : '-- Select --'}</option>
                        {vendors && vendors.map((vendor) =>
                            <option key={`vendor_${vendor._id}`} value={vendor._id} selected={formData.vendorId === vendor._id}>
                                {vendor.fullName}
                            </option>)}
                    </select>

                    {formData.vendor &&
                        <div className="row">
                            <div className="col-12 col-md-12 mt-2">
                                <label>
                                    Vendor Notification
                                    {currentVendor && <span className={"font-weight-bold"}> (All email sent to {currentVendor.email})</span>}
                                </label>
                                <div className="row">
                                    <div className="form-group col-12 col-md-4">
                                        <div className="form-check">
                                            <label className="fom-check-label">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name={"vendorNotification"}
                                                    checked={formData.vendorNotification === 'NoEmail'}
                                                    value={"NoEmail"}
                                                    required={currentVendor !== null || typeof currentVendor !== 'undefined'}
                                                    onChange={(e) => onChange(`vendorNotification`, e.target.value)}
                                                />
                                                No Emails
                                                <i id="NoEmail" className="fa fa-info-circle text-black-50 p-2 fa-lg" />
                                            </label>
                                            <UncontrolledTooltip
                                                placement="right"
                                                target="NoEmail"
                                            >
                                                Vendors will not receive any emails for this safety mailing auto rule.
                                            </UncontrolledTooltip>
                                        </div>
                                    </div>

                                    <div className="form-group col-12 col-md-4">
                                        <div className="form-check">
                                            <label className="fom-check-label">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name={"vendorNotification"}
                                                    checked={formData.vendorNotification === 'EmailNewOnly'}
                                                    value={"EmailNewOnly"}
                                                    required={currentVendor !== null || typeof currentVendor !== 'undefined'}
                                                    onChange={(e) => onChange(`vendorNotification`, e.target.value)}
                                                />
                                                Email New Only
                                                <i id="emailNewOnly" className="fa fa-info-circle text-black-50 p-2 fa-lg" />
                                            </label>
                                            <UncontrolledTooltip
                                                placement="right"
                                                target="emailNewOnly"
                                            >
                                                Vendor will receive an email when this work order is
                                                created.
                                            </UncontrolledTooltip>
                                        </div>
                                    </div>

                                    <div className="form-group col-12 col-md-4">
                                        <div className="form-check">
                                            <label className="form-check-label">
                                                <input
                                                    type="radio"
                                                    className="form-check-input"
                                                    name={"vendorNotification"}
                                                    checked={formData.vendorNotification === 'EmailAllUpdates'}
                                                    value={"EmailAllUpdates"}
                                                    required={currentVendor !== null || typeof currentVendor !== 'undefined'}
                                                    onChange={(e) => onChange(`vendorNotification`, e.target.value)}
                                                />
                                                Email All Updates
                                                <i id="emailAllUpdates" className="fa fa-info-circle text-black-50 p-2 fa-lg" />
                                            </label>
                                            <UncontrolledTooltip
                                                placement="right"
                                                target="emailAllUpdates"
                                            >
                                                Vendor will receive an email when this safety mailing auto rule is
                                                created and will receive an email about every
                                                modification of this safety mailing auto rule.
                                            </UncontrolledTooltip>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>

            </div>
            <div className="form-group">
                <button type="button" onClick={() => onPreview()} className="btn btn-info" disabled={isPreviewLoading}>
                    {isPreviewLoading ? 'Processing...' : 'Preview'}
                </button>
            </div>
            {isPreviewLoaded && <>
                <SafetyMailingsAutoRulesPreview previewData={previewData} subUsers={subUsers} vendors={vendors} />
                <div className="form-group">
                    <button type="button" onClick={() => onSubmit(form.current)} className="btn btn-primary" disabled={isProcessing}>
                        {isProcessing ? 'Processing...' : edit ? 'Update Rule' : ' Create Rule'}
                    </button>
                </div>
            </>}
        </form>
    );
}

export default SafetyMailingsAutoRulesForm;
