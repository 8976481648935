import React, { Fragment, useState, useEffect } from "react";
import { Doughnut } from 'react-chartjs-2';
import ActivityWeekCard from './weekCard';
import Outstanding from '../OutstandingFines';
import creService from "../../../services/creService";

const ActivitySnapshot = (props) => {

  const [nextWeekData, setNextWeekData] = useState([]);
  const [prevWeekData, setPrevWeekData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchActivitySnapshot = async () => {
    setIsLoading(true);
    try {
      const { data } = await creService.getActivitySnapshot();
      if (data.status) {
        setNextWeekData(data.nextWeekViolation);
        setPrevWeekData(data.prevWeekViolation);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchActivitySnapshot()
  }, []);

  return (
    <Fragment>
      <div className="row">
        <div className="col-md-7">
          <ActivityWeekCard title="Last Week Activity" data={prevWeekData} isLoading={isLoading} />
          <ActivityWeekCard title="Next Week Activity" data={nextWeekData} isLoading={isLoading} />
        </div>
        <div className="col-md-5">
          <Outstanding history={props.history} />
          <Doughnut
            data={{
              labels: [
                'Last Week',
                'Next Week',
              ],
              datasets: [{
                data: [
                  prevWeekData.length,
                  nextWeekData.length
                ],
                backgroundColor: [
                  '#edc536',
                  '#ce5370',
                ],
                hoverBackgroundColor: [
                  '#edc536',
                  '#ce5370',
                ]
              }]
            }}
            options={{
              responsive: true,
              maintainAspectRatio: true,
              legend: {
                display: true,
                position: 'bottom'
              },
            }}
            width={1000}
            height={900}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default ActivitySnapshot;
