import React, { Fragment, useState, useEffect, createRef } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { RedoOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import creService from '../../services/creService';
import { permissionsKey, settingsKey } from '../../services/authService';
import Guard from '../../common/guard';
import LocalState from '../../common/localState';
import Pagination from '../../common/forms/pagination';
import AdvanceFilter from './filter';
import WorkOrdersCalendar from './calendar';
import ViewByColumn from './columns';
import ViewByTable from './table';


const WorkOrders = ({ jestData = false }) => {
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const calendarRef = createRef();
  const [visittSync, setVisittSync] = useState(false);

  const queryParams = new URLSearchParams(location.search);
  const currentPage = queryParams.get('page')
    ? parseInt(queryParams.get('page'))
    : 1;

  const [workOrders, setWorkOrders] = useState([]);
  const [doneLoading, setDoneLoading] = useState(jestData ? true : false);
  const [search, setSearch] = useState('');
  const [dndState, setDNDState] = useState({});
  const [viewType, setViewType] = useState(
    LocalState.getViewTypeSetting('workOrders', 'list')
  );
  const [sortField, setSortField] = useState({ workOrderId: -1 });
  const [showAdanceSearch, setShowAdvanceSearch] = useState(false);

  const [buildings, setBuildings] = useState(
    jestData ? jestData.buildings : []
  );
  const [selectedFilterData, setSelectedFilterData] = useState({});
  const [vendors, setVendors] = useState(jestData ? jestData.vendors : []);
  const [workOrderCategories, setWorkOrderCategories] = useState(
    jestData ? jestData.workOrderCategories : []
  );
  const [allWorkOrderStatus, setAllWorkOrderStatus] = useState(
    jestData ? jestData.allWorkOrderStatus : []
  );
  const [subUsers, setSubUsers] = useState(jestData ? jestData.subUsers : []);
  const [showDateRangePicker, setShowDateRangePicker] = useState(false);
  const [calendarDateRange, setCalendarDateRange] = useState({
    startDate: moment().startOf('month').toISOString(),
    endDate: moment().endOf('month').toISOString(),
  });
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
    key: 'selection',
  });
  const [itemCount, setItemCount] = useState(0);
  const [hasVissitOrders, setHasVissitOrders] = useState(false)
  const [workOrderCreateAbility, setWorkOrderCreateAbility] = useState({
    bcomplaint: false,
    visitt: false
  })

  const priority = ['Low', 'Medium', 'High'];


  const syncVisittOrders = async () => {

    try {
      setVisittSync(true);
      const { data } = await creService.syncAllVisittOrders()

      if (data.status) {
        await getWorkOrders()
        toast.success('Work Orders Synced');
      } else {
        toast.error(data.message);
      }

    } catch (_err) {
      toast.error(_err.message);
    }
    setVisittSync(false);
  }


  const getWorkOrders = async (_filters = null, sort = {}, page = 1) => {

    setDoneLoading(false);
    let filter = _filters ? _filters : buildFilterFromQuery();
    if (
      Object.keys(sort).length <= 0 &&
      queryParams.get('sortField') &&
      queryParams.get('sortDirection')
    ) {
      sort = {
        [queryParams.get('sortField')]: parseInt(
          queryParams.get('sortDirection')
        ),
      };
      setSortField(sort);
    }
    let otherProps = {
      pageSize: +queryParams.get('pageSize') ? +queryParams.get('pageSize') : 10
    };

    if (viewType === 'calendar') {
      otherProps = {
        ...otherProps,
        ...calendarDateRange,
      };
    }

    const groupByStatus = viewType === 'column';
    if (groupByStatus) {
      otherProps.pageSize = 5;
    }
    const res = await creService.getWorkOrders(
      sort,
      search,
      filter,
      page,
      otherProps,
      groupByStatus
    );
    if (res.data.status) {
      setWorkOrders(res.data.workOrders || []);
      prepareDNDState(res.data.workOrders || []);
      setItemCount(res.data.total);
      setHasVissitOrders(res.data.hasVisittOrders)
      setWorkOrderCreateAbility(res.data.workOrderCreateAbility)
    } else {
      toast.error(res.data.message);
    }
    if (res.data.permissions) {
      localStorage.setItem(
        permissionsKey,
        JSON.stringify(res.data.permissions)
      );
    }
    setDoneLoading(true);
  };

  const buildFilterFromQuery = () => {
    let filter = {
      bins: [],
      categories: [],
      priority: [],
      status: [],
      vendors: [],
      subUsers: [],
      dueDateFrom: '',
      dueDateTo: '',
      uploads: '',
      signature: '',
    };
    const bins = query.get('bins');
    if (bins && bins.length > 0) {
      filter = {
        ...filter,
        bins: bins
          .split(',')
          .map(Number)
          .filter((bin) => !Number.isNaN(bin)),
      };
    }

    const status = query.get('status');
    if (status && status.length > 0) {
      filter = {
        ...filter,
        status: status.split(',').map(String),
      };
    }

    const categories = query.get('categories');
    if (categories && categories.length > 0) {
      filter = {
        ...filter,
        categories: categories.split(',').map(String),
      };
    }

    const priority = query.get('priority');
    if (priority && priority.length > 0) {
      filter = {
        ...filter,
        priority: priority.split(',').map(String),
      };
    }

    const vendors = query.get('vendors');
    if (vendors && vendors.length > 0) {
      filter = {
        ...filter,
        vendors: vendors.split(',').map(String),
      };
    }

    const subUsers = query.get('subUsers');
    if (subUsers && subUsers.length > 0) {
      filter = {
        ...filter,
        subUsers: subUsers.split(',').map(String),
      };
    }

    const uploads = query.get('uploads');
    if (uploads && uploads.length > 0) {
      filter = {
        ...filter,
        uploads,
      };
    }

    const signature = query.get('signature');
    if (signature && signature.length > 0) {
      filter = {
        ...filter,
        signature,
      };
    }

    const dueDateFrom = query.get('dueDateFrom');
    const dueDateTo = query.get('dueDateTo');

    if (dueDateFrom) {
      filter = {
        ...filter,
        dueDateFrom,
      };
      if (moment(dueDateFrom).isValid()) {
        setDateRange((prev) => ({
          ...prev,
          startDate: moment(dueDateFrom).toDate(),
        }));
      } else {
        setDateRange((prev) => ({ ...prev, startDate: moment().toDate() }));
      }
    }

    if (dueDateTo) {
      filter = {
        ...filter,
        dueDateTo,
      };
      if (moment(dueDateFrom).isValid()) {
        setDateRange((prev) => ({
          ...prev,
          endDate: moment(dueDateTo).toDate(),
        }));
      } else {
        setDateRange((prev) => ({ ...prev, endDate: moment().toDate() }));
      }
    }

    return filter;
  };

  const handleSearchInput = (e) => {
    setSearch(e.target.value);
  };


  const prepareDNDState = (workOrders) => {

    const dnd = workOrders.reduce((ac, a) => {
      // removed visitt work order from this view 
      const result = a.data?.data.filter(el => el.source !== 'visitt') || []
      ac[a._id] = {
        data: result,
        total: result.length
      }
      return ac
    }, {})
    setDNDState(dnd);
  };

  const handleSearchSubmit = async (event) => {
    event.preventDefault();
    setDoneLoading(false);
    if (search) {
      const res = await creService.getWorkOrders({}, search, {}, currentPage);
      if (res.data.status) {
        setWorkOrders(res.data.workOrders);
        prepareDNDState(res.data.workOrders);
        setItemCount(res.data.total);
      } else {
        toast.error(res.data.message);
      }
      if (res.data.permissions) {
        localStorage.setItem(
          permissionsKey,
          JSON.stringify(res.data.permissions)
        );
      }
    } else {
      getWorkOrders();
    }
    setDoneLoading(true);
  };

  const switchToggle = (type) => {
    let settings = JSON.parse(localStorage.getItem(settingsKey))?.viewType;
    settings = {
      ...settings,
      workOrders: type,
    };
    localStorage.setItem(settingsKey, JSON.stringify({ viewType: settings }));
    setViewType(type);
  };

  const handleSort = async (_sortField) => {
    const direction =
      typeof sortField[_sortField] != 'undefined' &&
        sortField[_sortField] === -1
        ? 1
        : -1;
    const sort = {
      [_sortField]: direction,
    };
    setSortField(sort);
    queryParams.set('sortField', _sortField);
    queryParams.set('sortDirection', direction);

    const filter = buildFilterFromQuery();
    const res =
      search.length <= 0
        ? await creService.getWorkOrders(sort, '', filter, currentPage)
        : await creService.getWorkOrders(sort, search, filter, currentPage);
    if (res.data.status) {
      setWorkOrders(res.data.workOrders);
      setItemCount(res.data.total);
    } else {
      toast.error(res.data.message);
    }
    history.push(`/work-orders/list?${queryParams.toString()}`);
  };

  const loadVendors = async () => {
    const vendorsRes = await creService.getVendors();
    setVendors(vendorsRes.data.vendors);
    if (vendorsRes.data.permissions) {
      localStorage.setItem(
        permissionsKey,
        JSON.stringify(vendorsRes.data.permissions)
      );
    }
  };

  const loadBuildings = async () => {
    const buildingRes = await creService.getBuildingsListOnly(true);
    setBuildings(buildingRes.data);
    if (buildingRes.data.permissions) {
      localStorage.setItem(
        permissionsKey,
        JSON.stringify(buildingRes.data.permissions)
      );
    }
  };

  const loadWorkOrderCategories = async () => {
    const res = await creService.getWorkOrderCategories();
    if (res.data.status) {
      setWorkOrderCategories(res.data.data);
    } else {
      toast.error(res.data.message);
    }
  };

  const loadAllWorkOrderStatus = async () => {
    const res = await creService.getWorkOrderStatus();
    if (res.data.status) {
      setAllWorkOrderStatus(res.data.data);
    } else {
      toast.error(res.data.message);
    }
  };

  const loadSubUsers = async () => {
    const subUsersRes = await creService.getAssignees();
    if (subUsersRes.data.status) {
      setSubUsers(subUsersRes.data.data);
    }
    if (subUsersRes.data.permissions) {
      localStorage.setItem(
        permissionsKey,
        JSON.stringify(subUsersRes.data.permissions)
      );
    }
  };

  const handleInputChange = (e) => {
    if (e) {
      const { name, value, type, checked } = e.target;
      if (type === 'date') {
        if (moment(value).isValid()) {
          setSelectedFilterData((state) => ({
            ...state,
            [name]: moment(value).format('MM-DD-YYYY'),
          }));
        }
      } else if (type === 'checkbox') {
        setSelectedFilterData((state) => ({
          ...state,
          [name]: checked ? 'yes' : 'no',
        }));
      } else {
        setSelectedFilterData((state) => ({
          ...state,
          [name]: value,
        }));
      }
    }
  };

  const handleChange = (name, value) => {
    setSelectedFilterData((state) => ({
      ...state,
      [name]: value,
    }));
  };

  useEffect(() => {
    loadWorkOrderCategories();
    loadAllWorkOrderStatus();
    loadSubUsers();
    loadVendors();
    loadBuildings();
    setSelectedFilterData(buildFilterFromQuery());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let newQueryString = '';
    let _selectedFilterData = selectedFilterData;
    // _selectedFilterData = buildFilterFromQuery();
    Object.keys(_selectedFilterData).map((field) => {
      const data = _selectedFilterData[field];
      if (data.length > 0 || (field === 'dueDate' && data)) {
        newQueryString += `${newQueryString && '&'}${field}=${data.toString()}`;
      }
      return field;
    });
    if (newQueryString.length > 0) {
      setShowAdvanceSearch(true);
    }
    if (queryParams.get('sortField') && queryParams.get('sortDirection')) {
      history.push(
        `/work-orders/list?sortField=${queryParams.get(
          'sortField'
        )}&sortField=${queryParams.get('sortDirection')}${newQueryString.length > 0 ? `&${newQueryString}` : ''
        }`
      );
    } else {
      history.push(`/work-orders/list?${newQueryString}`);
    }
    if (Object.keys(_selectedFilterData).length > 0) {
      getWorkOrders(_selectedFilterData, {}, 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilterData, viewType, calendarDateRange]);

  const onDateSelect = (range) => {
    setDateRange(range.selection);

    if (moment(range.selection.startDate).isValid()) {
      handleChange(
        'dueDateFrom',
        moment(range.selection.startDate).format('YYYY-MM-DD')
      );
    } else {
      handleChange('dueDateFrom', moment().format('YYYY-MM-DD'));
    }
    if (moment(range.selection.endDate).isValid()) {
      handleChange(
        'dueDateTo',
        moment(range.selection.endDate).format('YYYY-MM-DD')
      );
    } else {
      handleChange('dueDateTo', moment().format('YYYY-MM-DD'));
    }
  };

  const onPageChange = (page) => {

    queryParams.set('page', page);
    history.push(`/work-orders/list?${queryParams.toString()}`);
    getWorkOrders(null, {}, page);
  };

  const onPageSizeChange = (current, size) => {
    queryParams.set('pageSize', size);
    history.push(`/work-orders/list?${queryParams.toString()}`);
  }

  const onCalendarDatesRender = (dateRange) => {
    setCalendarDateRange(dateRange);
  };

  return (
    <Fragment>
      <div className="row violation-sec">
        <div className="col-xl-12 my-3">
          <div className="card">
            <div className="card-header">
              <div className="col-10">
                <h5>Work Orders</h5>
              </div>
              <div className="col-2 text-right">
                <div id="btnContainer">
                  <button
                    className={`btn mr-2 ${viewType === 'list' ? 'active' : ''
                      }`}
                    onClick={() => switchToggle('list')}
                  >
                    <i className="fa fa-bars"></i>
                  </button>
                  <button
                    className={`btn mr-2 ${viewType === 'column' ? 'active' : ''
                      }`}
                    onClick={() => switchToggle('column')}
                  >
                    <i className="fa fa-columns"></i>
                  </button>
                  <button
                    className={`btn ${viewType === 'calendar' ? 'active' : ''}`}
                    onClick={() => switchToggle('calendar')}
                  >
                    <i className="fa fa-calendar"></i>
                  </button>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <Guard
                  serviceName="workOrders"
                  action="create"
                  allow={() => (
                    <div className="col-6 d-flex justify-content-between">
                      <div>
                        {workOrderCreateAbility.bcomplaint && <Link
                          to={{
                            pathname: '/work-orders/create',
                            state: { from: location },
                          }}
                          className='mr-2'
                        >
                          <button className="btn btn-primary">
                            <i className="fa fa-plus"></i>
                            <span> Create</span>
                          </button>

                        </Link>
                        }
                        {workOrderCreateAbility.visitt && <Link
                          to={{
                            pathname: '/work-orders/create-visitt',
                            state: { from: location },
                          }}
                        >
                          <button className="btn btn-primary">
                            <i className="fa fa-plus"></i>
                            <span> Create (Visitt)</span>
                          </button>

                        </Link>
                        }
                      </div>
                      {workOrderCreateAbility.visitt && hasVissitOrders && <Button className='ml-3' type="dashed" icon={<RedoOutlined />} loading={visittSync} onClick={syncVisittOrders} >
                        Sync Visitt Work Orders
                      </Button>
                      }
                    </div>
                  )}
                />
                <div className="col-6">
                  <form onSubmit={handleSearchSubmit} className="d-flex">
                    <div className="input-group">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Search"
                        onChange={(e) => handleSearchInput(e)}
                      />
                      <div className="input-group-append">
                        <button className="btn btn-primary" type="submit">
                          <i className="fa fa-search"></i>
                        </button>
                      </div>
                    </div>
                    <div
                      className="btn btn-link text-nowrap text-decoration-none"
                      onClick={() => setShowAdvanceSearch((prev) => !prev)}
                    >
                      Advanced Search
                      {showAdanceSearch && <i className="fa fa-times ml-1"></i>}
                    </div>
                    {showAdanceSearch && (
                      <a
                        href="/work-orders/list"
                        className="btn btn-link text-danger px-0"
                      >
                        [Clear]
                      </a>
                    )}
                  </form>
                </div>
                {showAdanceSearch && (
                  <AdvanceFilter
                    handleChange={handleChange}
                    buildings={buildings}
                    selectedFilterData={selectedFilterData}
                    workOrderCategories={workOrderCategories}
                    priority={priority}
                    allWorkOrderStatus={allWorkOrderStatus}
                    vendors={vendors}
                    subUsers={subUsers}
                    onDateSelect={onDateSelect}
                    dateRange={dateRange}
                    handleInputChange={handleInputChange}
                    viewType={viewType}
                    showDateRangePicker={showDateRangePicker}
                    setShowDateRangePicker={setShowDateRangePicker}
                  />
                )}
              </div>
              <div className="my-3 bg-white text-dark work-order-list">
                {viewType === 'column' && (
                  <ViewByColumn
                    isLoading={!doneLoading}
                    data={dndState}
                    handleChange={handleChange}
                    switchToggle={switchToggle}
                  />
                )}
                {viewType === 'list' && (
                  <ViewByTable
                    workOrders={workOrders}
                    handleSort={handleSort}
                    sortField={sortField}
                    doneLoading={doneLoading}
                  />
                )}
                {viewType === 'calendar' && (
                  <WorkOrdersCalendar
                    data={workOrders}
                    calendarRef={calendarRef}
                    onDatesRender={onCalendarDatesRender}
                  />
                )}
                {['list'].includes(viewType) && (
                  <Pagination
                    className="my-4"
                    itemsCount={itemCount}
                    pageSize={+queryParams.get('pageSize') ? +queryParams.get('pageSize') : 10}
                    onPageSizeChange={onPageSizeChange}
                    currentPage={currentPage}
                    onPageChange={onPageChange}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default WorkOrders;
