import React, { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';

import './summaryPie.css';
import { random_rgb } from '../../../services/helperService';

const SummaryPie = ({ totalSent, totalResponseData, displayData }) => {

  const [firstPieData, setFirstPieData] = useState({})
  const [secondPieData, setSecondPieData] = useState({})
  const [thirdPieData, setThirdPieData] = useState({})
  const [persantageOption, setPersantageOption] = useState({})

  useEffect(() => {

    if (totalSent) {

     

      setFirstPieData({
        labels: ['RECEIVED', 'NOT RECEIVED'],
        datasets: [
          {
            data: [totalResponseData.length, totalSent - totalResponseData.length],
            backgroundColor: ['rgba(75, 192, 192, 0.4)', 'rgba(255, 99, 132, 0.4)'],
            borderColor: ['rgba(75, 192, 192, 1)', 'rgba(255, 99, 132, 1)'],
          },
        ],
      })
      // if (totalResponseData[0]?.receivedBy) {
      const setdata = new Set()

      for (let item of totalResponseData) {
        setdata.add(item.responseData.receivedBy)
      }
      const labelsN = [...setdata, 'not received'].map(el => el?.toUpperCase())
      setSecondPieData({
        labels: labelsN,
        datasets: [
          {
            data: [
              ...totalResponseData.reduce(
                (ac, a) => {
                  for (let i = 0; i < labelsN.length; i++) {
                    if (a.responseData.receivedBy?.toUpperCase() === labelsN[i]) {
                      ac[i] = ac[i] ? ac[i] + 1 : 1
                      break;
                    }
                  }

                  return ac;
                },
                []
              ),
              totalSent - totalResponseData.length,
            ],
            backgroundColor: [
              'rgba(75, 192, 192, 0.4)',
              'rgba(153, 102, 255, 0.4)',
              'rgba(255, 159, 64, 0.4)',
              'rgba(255, 99, 132, 0.4)',
              'rgba(143, 188, 143, 0.4)',
            ],
            borderColor: [
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)',
              'rgba(255, 99, 132, 1)',
              'rgba(143, 188, 143, 1)',
            ],
          },
        ],
      })
      // }
      const buildingData = displayData.reduce((ac, a) => {
        const address = a.building?.address?.split(',')[0];
        if (ac[address]) {
          ac[address]++;
        } else {
          ac[address] = 1;
        }
        return ac;
      }, {});

      const labels = Object.keys(buildingData);
      const values = labels.map((el) => buildingData[el]);
      const backgroundColor = labels.map((el, i) => random_rgb(0.4, i));
      const borderColor = backgroundColor.map((el) => el.replace('0.4', '1'));


      setPersantageOption({
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              var dataset = data.datasets[tooltipItem.datasetIndex];
              var meta = dataset._meta[Object.keys(dataset._meta)[0]];
              var total = meta.total;
              var currentValue = dataset.data[tooltipItem.index];
              var percentage = parseFloat(
                ((currentValue / total) * 100).toFixed(1)
              );
              return currentValue + ' (' + percentage + '%)';
            },
            title: function (tooltipItem, data) {
              return data.labels[tooltipItem[0].index];
            },
          },
        },
      })

      setThirdPieData({
        data: {
          labels,
          datasets: [
            {
              data: values,
              backgroundColor,
              borderColor,
            },
          ],
        },
        labels,
      })
    }
  }, [totalResponseData, displayData, totalSent])







  return (
    <div className="d-flex justify-content-between">
      <div className="pie-wrapper">
        <Pie data={firstPieData} options={persantageOption} />
      </div>
      <div className="pie-wrapper">
        <Pie data={secondPieData} options={persantageOption} />
      </div>
      <div className={`pie-wrapper ${thirdPieData.labels?.length >= 5 ? 'mt-4' : ''}`}>
        <Pie
          data={thirdPieData.data}
          options={{
            legend: {
              display: thirdPieData.labels?.length < 5,
            },
            ...persantageOption,
          }}
        />
      </div>
    </div>
  );
};

export default SummaryPie;
