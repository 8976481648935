import React, { Fragment, useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';

// legacy
import SafetyMailingViewStoveReceivedRows from './stoveReceived';
import SafetyMailingViewWindowSummaryRows from './windowSummary';

//new version 

import SafetyMailingViewRows from './newSummary';


import { useParams } from "react-router-dom";

const SafetyMailingViewTabs = ({ order }) => {

    const { tabActive } = useParams();
    const [activeTab, setActiveTab] = useState(() => {
        if (tabActive) {
            return tabActive;
        }
        return 'window-guard';
    });

    const toggleTabs = (tabName) => {
        setActiveTab(tabName);
    }

    return (
        <Fragment>
            {/* new Date(order.addedAt) >= new Date('2024-09-20')  */}
            {order.newVersion ? <SafetyMailingViewRows order={order} /> :
                <><Nav tabs>
                    <NavItem style={{ minWidth: '20%' }}>
                        <NavLink
                            className={activeTab === 'window-guard' ? 'active' : ''}
                            onClick={() => { toggleTabs('window-guard'); }}
                        >
                            <div className="d-flex flex-column text-center mb-2 pointer">
                                <i className="fa fa-window-maximize fa-2x mb-1" />
                                <span>Window Summary and Action</span>
                            </div>
                        </NavLink>
                    </NavItem>
                    <NavItem style={{ minWidth: '20%' }}>
                        <NavLink
                            className={activeTab === 'stove-safety' ? 'active' : ''}
                            onClick={() => { toggleTabs('stove-safety'); }}
                        >
                            <div className="d-flex flex-column text-center mb-2 pointer">
                                <i className="fa fa-fire fa-2x mb-1" />
                                <span>Stove Knob Notices</span>
                            </div>
                        </NavLink>
                    </NavItem>
                </Nav>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="window-guard" className="py-3">
                            <SafetyMailingViewWindowSummaryRows order={order} />
                        </TabPane>
                        <TabPane tabId="stove-safety" className="py-3">
                            <SafetyMailingViewStoveReceivedRows order={order} />
                        </TabPane>
                    </TabContent>
                </>
            }
        </Fragment>
    );
}

export default SafetyMailingViewTabs;
