import React from "react";
import ReactLoading from "react-loading";

import Helper from '../../services/helperService';


const DashboardDetailsGrid = ({ tableData, onClick, category, sub, doneLoading }) => {
    return (
        <div className="card custom-violation-grid">
          <div className="card-body">
            <div className="row">
              {!doneLoading ? (
                <table>
                  <tbody>
                    <tr>
                      <td colSpan="5">
                        <div align="center">
                          {' '}
                          <ReactLoading
                            type={'cylon'}
                            color={'grey'}
                          />{' '}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              ) : (
                tableData?.map((value, key) => {
                  if (
                    category ===
                    'Outstanding Fines'
                  ) {
                    let balance_due = 0;
                    let overPaidAmount = 0;
                    for (let violation of value.violationsDetails) {
                      overPaidAmount +=
                        (parseFloat(
                          violation.violationData.paid_amount
                        ) || 0) -
                        (parseFloat(
                          violation.violationData.penalty_imposed
                        ) || 0);
                      balance_due +=
                        parseFloat(
                          violation.violationData.balance_due
                        ) || 0;
                    }
                    return (
                      <div className="col-6 col-xl-3 col-md-4 col-sm-4 mb-5">
                        <div
                          key={key}
                          className="pointer"
                          onClick={() => onClick(value)}
                        >
                          <div className="icon-bg">
                            <i className="fa fa-building"></i>
                          </div>
                          <div className="thead">
                            {value.fullAddress}
                          </div>
                          <table>
                            <tbody>
                              <tr className="d-flex">
                                <td>
                                  {value.violationsDetails.length}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  {Helper.formatter.format(balance_due)}
                                  {sub ===
                                    'Overpaid' && (
                                      <span className="badge badge-danger m-l-5">
                                        {' '}
                                        Over Paid:{' '}
                                        {Helper.formatter.format(
                                          overPaidAmount
                                        )}
                                      </span>
                                    )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        className="col-6 col-xl-3 col-md-4 col-sm-4 mb-5"
                        key={key}
                      >
                        <div
                          className="pointer"
                          onClick={() => onClick(value)}
                        >
                          <div className="icon-bg">
                            <i className="fa fa-building"></i>
                          </div>
                          <div className="thead d-flex">
                            {value.fullAddress}
                          </div>
                          <div className="row">
                            <div className="col-8 col-md-8 found">
                              Open
                            </div>
                            <div className="col-4 col-md-4 text-right">
                              <div className="number-bg">
                                {value.violationsDetails.length}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                })
              )}
            </div>
          </div>
        </div>
      )
};

export default DashboardDetailsGrid;