import React, {useState, useEffect} from "react";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';
import creService from "../../services/creService";
import { useHistory } from "react-router-dom";

const SmallCalendar = () => {
  let history = useHistory()
  const [markedDates, setMarkedDates] = useState([]);

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) {
      var startDate = moment().startOf('month').toISOString();
      var endDate = moment().endOf('month').toISOString();

      setCalendar(startDate, endDate);
    }

    return () => { unmounted = true };
  }, []);

  const setCalendar = async (startDate, endDate) => {
    const result = await creService.getCalendar(startDate, endDate);
    
    if (result.status === 200) {
      let allMonthDate = []
      result.data.map((val, i) => {
        allMonthDate.push(moment(val.start).utc(0).format('DD-MM-YYYY'))
        return allMonthDate;
      })

      setMarkedDates(allMonthDate)
    }
  };

  const onChange = (date) => {
    history.push({ pathname: "/calendar", state: { passDate: date }})
  }

  const onActiveStartDateChange = date => {
    var startDate = moment(date.activeStartDate).startOf('month').toISOString();
    var endDate = moment(date.activeStartDate).endOf('month').toISOString();
    setCalendar(startDate, endDate);
  }

  return (
    <Calendar
      onChange={onChange}
      onActiveStartDateChange={onActiveStartDateChange}
      tileClassName={({ date, view }) => {
        if(markedDates.length) {
          if(markedDates.find(x=>x===moment(date).format("DD-MM-YYYY"))){
            return 'calendar-highlight'
          }
        }
      }}
    />
  );
};

export default SmallCalendar;
