import React from 'react';
import PropTypes from 'prop-types';
import './style.css';

const Marker = ({ onClick, color, name }) => (
  <div>
    <div
      className="pin bounce"
      style={{ backgroundColor: color, cursor: 'pointer' }}
      title={name}
      alt={name}
      onClick={onClick}
    />
    <div className="pulse" />
  </div>
);

Marker.defaultProps = {
  onClick: null,
};

Marker.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
};

export default Marker;