import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify';

import DocsViewer from '../../../common/document-viewer'
import RetainedBadges from './retainedBadges';
import CofCProgress from "./cofcProgress";
import CustomFields from "./customFields";
import creApi from './../../../services/creService';

const PermitCertificates = ({
  category,
  sub,
  tableRawData,
  apiData,
  convertedData,
  subUsers,
  vendors,
  customFields,
  setRefreshHistory,
  noHeader,
  moveToCategory
}) => {

  const { retained, cofc } = apiData?.violationDetails?.clientAdditionalData || {};
  const [currentfile, setCurrentfile] = useState(null);
  const [docsViewerVisibility, setDocsViewerVisibility] = useState(false);
  const retainedTypes = retained ? Object.keys(retained).filter(key => retained[key].isRetained) : []

  const data = Object.entries(convertedData)

  const viewDocument = async (key) => {
    try {
      const { data } = await creApi.getDocumentByKey(key, {
        violation: apiData.violationDetails._id
      })
      if (data.status) {
        setCurrentfile(data.document)
        setDocsViewerVisibility(true)
      } else {
        toast.error(data.message)
      }
    } catch (err) {
      toast.error(err.message)
    }

  }

  return (
    // All Other Violations Show General Design
    data.map(([paragraphName, values], key) => {

      return <Fragment key={key + 100}>
        <div className="card user-card"
          style={retainedTypes.length > 0 ? { border: '1px solid #c36c00', borderRadius: 0 } : {}}>
          <RetainedBadges retained={retained} />
          {
            data.length > 1 &&
            <div className="card-header">
              <div className="online-user"><h5 className="font-primary f-18 mb-0">{paragraphName}</h5></div>
            </div>
          }


          <div className="card-body ecb-head">
            {!noHeader && <h5 className="text-info mb-3 pointer" onClick={moveToCategory} >{category}/{sub}</h5>}
            <div className='row ecb-row'>

              {values.filter(value => tableRawData[value.apiField]?.length).map((value, key) => {

                let displayValue = tableRawData[value.apiField];


                if (typeof displayValue === 'object') {
                  if (value.apiField === 'fileNames') {
                    displayValue = displayValue.map(key => {
                      return <span
                        key={key}
                        className="ml-0 mb-1 btn-link pointer"
                        onClick={() => viewDocument(`${apiData.violationDetails.sub}/${key}`)}
                      >{key}
                      </span>
                    })
                  }
                } else {
                  displayValue = displayValue || '--'
                }

                return (
                  <div className={`mt-2 col-6 col-md-6 mb-3`} key={key}>
                    <h6>{value.displayName} {value.link && <Link to={value.link}>
                      <i className=" fa fa-external-link" aria-hidden="true"></i>
                    </Link>
                    }</h6>
                    <p className="rounded d-flex flex-column " style={{ whiteSpace: 'pre-line' }}>
                      {displayValue}
                    </p>
                  </div>)

              })

              }

              {apiData?.violationDetails?.additionalData?.bcompliantNotes &&
                <div className="ecb-notes">
                  <h5>
                  BCompliant Violation Notes
                  </h5>
                  <div className="desc">
                    <p>
                      {apiData.violationDetails.additionalData.bcompliantNotes}
                    </p>
                  </div>
                  {apiData.violationDetails.bcompliantNotesUpdatedBy && <small className="text-muted">
                    Last updated By {apiData.violationDetails.bcompliantNotesUpdatedBy.name}
                  </small>}
                </div>
              }
            </div>
          </div>
          <CustomFields
            customFields={customFields}
            subUsers={subUsers}
            vendors={vendors}
            category={category}
            sub={sub}
            apiData={apiData}
            setRefreshHistory={setRefreshHistory}
          />
          <CofCProgress cofc={cofc} />
        </div>
        <DocsViewer document={currentfile} isVisible={docsViewerVisibility} setIsVisible={setDocsViewerVisibility} />
      </Fragment>
    })
  );
};

export default PermitCertificates;
