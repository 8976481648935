import React, { Fragment } from "react";
import {ToastContainer} from "react-toastify";

const Violations = () => {
    return (
    	<Fragment >
    	<ToastContainer />
    	 <div className="container-fluid">
    	  	<div className="row violation-sec">
    	  		<div className="col-xl-9">
    	  			<div className="card">
    	  				<div className="card-header">
    	  					<h5>permits</h5>
    	  					<div className="filter-sec">
    	  						<ul>
    	  							<li className="active">
    	  								<a className="icon-grid grid-layout-view" href="#javascript" data-original-title="" title="">
	    	  								<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect x="3" y="3" width="7" height="7"></rect><rect x="14" y="3" width="7" height="7"></rect><rect x="14" y="14" width="7" height="7"></rect><rect x="3" y="14" width="7" height="7"></rect></svg>
    	  								</a>
    	  							</li>
    	  							<li>
    	  								<a className="icon-grid grid-layout-view" href="#javascript" data-original-title="" title="">
	    	  								<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="8" y1="6" x2="21" y2="6"></line><line x1="8" y1="12" x2="21" y2="12"></line><line x1="8" y1="18" x2="21" y2="18"></line><line x1="3" y1="6" x2="3.01" y2="6"></line><line x1="3" y1="12" x2="3.01" y2="12"></line><line x1="3" y1="18" x2="3.01" y2="18"></line></svg>
    	  								</a>
    	  							</li>
    	  						</ul>
    	  					</div>
    	  				</div>
    	  				<div className="card-body permit-box">
    	  					<div className="row custom-col">
    	  						<div className="col-md-4 col-sm-6">
    	  							<div className="card fix-card">
    	  								<div className="card-body">
    	  									<div className="media-body">
												<ul>
													<li>
														<h6 className="title">address</h6>
														<h6 className="content">114 W 17th St 2nd Floor New York, NY 10011, US</h6>
													</li>
													<li>
														<h6 className="title">agency</h6>
														<h6 className="content">Construcation</h6>
													</li>
													<li>
														<h6 className="title">permit#</h6>
														<h6 className="content">3124</h6>
													</li>
													<li>
														<h6 className="title">type</h6>
														<h6 className="content">DOB</h6>
													</li>
													<li>
														<h6 className="title">status</h6>
														<h6 className="content default-btn">active/open</h6>
													</li>
													<li>
														<h6 className="title">issued</h6>
														<h6 className="content">12/01/2020</h6>
													</li>
													<li>
														<h6 className="title">expires</h6>
														<h6 className="content">18/02/2020</h6>
													</li>
												</ul>
    	  									</div>
    	  								</div>
    	  							</div>
    	  						</div>
    	  						<div className="col-md-4 col-sm-6">
    	  							<div className="card fix-card">
    	  								<div className="card-body">
    	  									<div className="media-body">
												<ul>
													<li>
														<h6 className="title">address</h6>
														<h6 className="content">114 W 17th St 2nd Floor New York, NY 10011, US</h6>
													</li>
													<li>
														<h6 className="title">agency</h6>
														<h6 className="content">Construcation</h6>
													</li>
													<li>
														<h6 className="title">permit#</h6>
														<h6 className="content">3124</h6>
													</li>
													<li>
														<h6 className="title">type</h6>
														<h6 className="content">DOB</h6>
													</li>
													<li>
														<h6 className="title">status</h6>
														<h6 className="content default-btn warning">rescinded</h6>
													</li>
													<li>
														<h6 className="title">issued</h6>
														<h6 className="content">12/01/2020</h6>
													</li>
													<li>
														<h6 className="title">expires</h6>
														<h6 className="content">18/02/2020</h6>
													</li>
												</ul>
    	  									</div>
    	  								</div>
    	  							</div>
    	  						</div>
    	  						<div className="col-md-4 col-sm-6">
    	  							<div className="card fix-card">
    	  								<div className="card-body">
    	  									<div className="media-body">
												<ul>
													<li>
														<h6 className="title">address</h6>
														<h6 className="content">114 W 17th St 2nd Floor New York, NY 10011, US</h6>
													</li>
													<li>
														<h6 className="title">agency</h6>
														<h6 className="content">Construcation</h6>
													</li>
													<li>
														<h6 className="title">permit#</h6>
														<h6 className="content">3124</h6>
													</li>
													<li>
														<h6 className="title">type</h6>
														<h6 className="content">DOB</h6>
													</li>
													<li>
														<h6 className="title">status</h6>
														<h6 className="content default-btn">active/open</h6>
													</li>
													<li>
														<h6 className="title">issued</h6>
														<h6 className="content">12/01/2020</h6>
													</li>
													<li>
														<h6 className="title">expires</h6>
														<h6 className="content">18/02/2020</h6>
													</li>
												</ul>
    	  									</div>
    	  								</div>
    	  							</div>
    	  						</div>
    	  					</div>
	  					</div>
					</div>
				</div>
			</div>
    	</div>
        </Fragment>
    );
};

export default Violations;
