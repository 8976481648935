import React, { useState, useRef } from 'react';
import { Modal, ModalBody, } from 'reactstrap';
import { toast } from 'react-toastify';
import { CloseOutlined } from '@ant-design/icons'
import creService from '../../../../services/creService';

const ModalVendors = ({
    modalTenant,
    handleAddCreatedTenant = () => { },
    setModalTenant = () => { },
    bin
}) => {
    const formRef = useRef();
    const [isCreating, setIsCreating] = useState(false);
    const [formData, setFormData] = useState({bin});
    const handleInput = (e) => {
        const { name, value } = e.target;
        setFormData(state => ({
            ...state,
            [name]:  value
        }));
    };

    const phoneValidation = () => {
        if (formData.phone === undefined || formData.phone === '') { return true }

        // eslint-disable-next-line no-useless-escape
        const regex = /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i;
        return regex.test(formData.phone);
    }

    const handleOnSubmit = async (event) => {
        event.preventDefault();

        const isPhoneValidate = phoneValidation();

        if (!isPhoneValidate) {
            toast.error("Phone Number not valid!");
            return false;
        }

        setIsCreating(true);
        try {
            const { data } = await creService.createTenant(formData);
            if (data.status) {
                formRef.current.reset();
                toast.success(data.message);
                handleAddCreatedTenant()
                setModalTenant(null)
            } else {
                toast.error(data.message);
            }
           
        } catch (_err) {
            toast.error(_err.message);
            
        }
  

        setIsCreating(false);
    };

    return (
        <div>
            <Modal isOpen={!!modalTenant} backdrop="static">
                <ModalBody>
                    <div className='d-flex justify-content-end '>
                        <CloseOutlined
                            className='pointer'
                            onClick={() => { setModalTenant(null) }}
                        />
                    </div>
                    <form ref={formRef} onSubmit={handleOnSubmit}>
                        <div className="row">
                            <div className="form-group col-12">
                                <label>Unit/Apt# <sup className="text-danger">*</sup></label>
                                <input type="text" name="aptNumber" className="form-control" onChange={handleInput} required ></input>
                            </div>
                            <div className="form-group col-12">
                                <label>Tenant Name </label>
                                <input type="text" name="name" className="form-control" onChange={handleInput}  ></input>
                            </div>
                            <div className="form-group col-12">
                                <label>Tenant Phone</label>
                                <input type="text" name="phone" className="form-control" maxLength={20} onChange={handleInput}  ></input>
                            </div>
                            <div className="form-group col-12">
                                <label>Tenant Email </label>
                                <input type="email" name="email" className="form-control" onChange={handleInput} ></input>
                            </div>

                            <div className="form-group col-12 mb-0 d-flex justify-content-end">
                                <button type="submit" className="btn btn-primary" disabled={isCreating} >{isCreating ? 'Creating ...' : 'Add'}</button>
                            </div>
                        </div>
                    </form>
                </ModalBody>

            </Modal>
        </div>
    );
};

export default ModalVendors;
