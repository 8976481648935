import React, { useEffect, useState, useContext, useCallback, Fragment, useRef, useMemo } from "react";
import creService from '../../services/creService';
import { toast } from 'react-toastify';
import { SocketContext } from '../../../context/socket';
import './chatRooms.css'
import ChatRoom from './index';
import moment from "moment";
import auth from '../../services/authService';


const ChatRooms = ({ setDotVisible = () => { }, filter = '' }) => {

    const socket = useContext(SocketContext);
    const [chatRooms, setChatRooms] = useState([])
    const chatsElement = useRef(null);
    const currentUserId = useMemo(() => auth.getCurrentUser()?._id, []);
    const [subUsers, setSubUsers] = useState([])
    const [supportUsers, setSupportUsers] = useState([])

    useEffect(() => {
        getChats();
        getSubUsers()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    useEffect(() => {
        if (socket) {
            socket.on('new-room-message', chatsUpdate);
            return () => socket.off('new-room-message', chatsUpdate);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const chatsUpdate = useCallback((chatID) => {

        getChats(undefined, chatID)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getChats = async (openIdOnUpdate, chatID) => {
        try {
            const res = await creService.getChatRooms();
            if (res.data.status) {
                setChatRooms(res.data.data.map(el => {
                    if (el._id === openIdOnUpdate) {
                        el.shouldOpen = true
                    }
                    return el
                }));
                if (!chatID) {
                    const newCount = res.data.data.reduce((ac, el) => ac + (el.newMessagesCount || 0), 0)
                    if (newCount) {
                        setDotVisible((count) => count + newCount)
                    }
                }

            } else {
                toast.error(res.data.message);
            }
        } catch (e) {
            toast.error(e.message);
        }

    }

    const getSubUsers = async () => {
        const res = await creService.getSubUsers();
        if (res.data.status) {
            setSupportUsers(res.data.support.map(el => ({ _id: el._id, isAdmin: true, fullName: el.name })) || [])
            setSubUsers([...res.data.data, res.data.masterClient].filter(cl => cl._id !== currentUserId));
        } else {
            toast.error(res.data.message);
        }
    }

    return (
        <div ref={chatsElement}>


            <div className="chat-box custom-scrollbar">
                <div className="people-list friend-list">
                    <ul className="list pt-0">
                        {chatRooms.length ?
                            chatRooms.filter(el =>
                                el.chatName?.toLowerCase().includes(filter.toLowerCase()) ||
                                el.chatMembers?.map(el => el.fullName).join('').toLowerCase().includes(filter.toLowerCase())
                            )
                                .map(chat => {
                                    const widthBlocks = (chat.chatMembers.length + chat.chatAdminMembers.length) === 2 ?
                                        1
                                        :
                                        ((chat.chatMembers.length + chat.chatAdminMembers.length) === 3 ? 2 : 3)
                                    return <div key={chat._id} className="mt-3 pointer  hover-effect pl-2 pr-2">

                                        <ChatRoom supportUsers={supportUsers} chatID={chat._id} shouldOpen={chat.shouldOpen} currentUserId={currentUserId} getChats={getChats} subUsers={subUsers}>

                                            <div className="d-flex align-items-center ">


                                                <div className="d-flex justify-content-center members-block mr-2" >
                                                    <Fragment>
                                                        <span id={'chat' + chat._id} style={{
                                                            position: 'relative',
                                                            width: widthBlocks * 22 + 'px'
                                                        }}>

                                                            {[...chat.chatMembers, ...chat.chatAdminMembers]?.filter(member => currentUserId !== member._id).slice(0, 3).map((member, i) => {
                                                                return <Fragment key={member._id}>
                                                                    <span
                                                                        style={{
                                                                            left: `${i * 20}px`,
                                                                            background: member.avatarS3Key ? undefined : member.name ? '#1ea6ec' : ChatRoom.getColor(member._id, i),
                                                                            backgroundImage: member.avatarS3Key ? `url(${window.location.origin}/api/documents/get-by-key?key=${member.avatarS3Key})` : undefined,
                                                                            backgroundSize: member.avatarS3Key ? 'cover' : undefined,
                                                                        }}
                                                                        className="chat-viewer mr-2 pointer"

                                                                    >
                                                                        {i === 2 && (chat.chatMembers.length + chat.chatAdminMembers.length) !== 3 ?
                                                                            `+${(chat.chatMembers.length + chat.chatAdminMembers.length - 1) - 2}` :
                                                                            member.avatarS3Key ? '' :
                                                                                (member.fullName || member.name)?.split(' ').map(el => el.slice(0, 1).toUpperCase()).slice(0, 2).join('')
                                                                        }
                                                                    </span>
                                                                </Fragment>
                                                            })}
                                                        </span>

                                                        {/* <UncontrolledTooltip
                                                            placement="top"
                                                            autohide={true}
                                                            target={'chat' + chat._id}
                                                            container={chatsElement}
                                                        >
                                                            {chat.chatMembers.filter(member => currentUserId !== member._id).map(el => <div key={el.fullName}>{el.fullName}</div>)}
                                                            {chat.chatAdminMembers.map(el => <div key={el.name}>{el.name} (support)</div>)}
                                                        </UncontrolledTooltip> */}
                                                    </Fragment>
                                                </div>
                                                <div className="mb-2 chat-box">
                                                    <span className="  about">
                                                        <span className="name">{chat.chatName || chat.chatMembers
                                                            .filter(member => currentUserId !== member._id)
                                                            .map(m => m.fullName)
                                                            .join(', ') || 'Chat with Support'} </span><br />
                                                        <span className='status'> {chat.updatedAt ? moment(chat.updatedAt).calendar() : null} </span>
                                                    </span>

                                                    {chat.newMessagesCount ?
                                                        <span className="badge badge-info">
                                                            {chat.newMessagesCount} New
                                                        </span>
                                                        : ''}
                                                </div>

                                            </div>
                                        </ChatRoom >

                                    </div >
                                }
                                ) :
                            <div className="alert alert-info mt-3">No Company Chats Yet</div>
                        }
                        <div className='mt-3'>
                            <ChatRoom supportUsers={supportUsers} getChats={getChats} currentUserId={currentUserId} subUsers={subUsers}>
                                <div className="d-flex justify-content-center w-100">
                                    <button className="btn btn-light ">+ create new chat</button>
                                </div>
                            </ChatRoom>
                        </div>
                    </ul>
                </div>
            </div>
        </div>
    )
}



export default ChatRooms;
