import React, { Fragment, useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import DocsViewer from './document-viewer'

import auth from '../services/authService';
import { servicesAccessKey } from '../services/authService';
import creApi from '../services/creService';
import http from '../services/httpService';
import config from '../../config.json';

const apiUrl = config.apiUrl;

const UploadDocument = ({
  id,
  violationId,
  category,
  sub,
  documentLink,
  setUploadedDocuments,
  fieldId,
  showDocumentTag,
  source,
  setDataSource
}) => {
  const user = auth.getCurrentUser();

  const [loaded, setLoaded] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadedList, setUploadedList] = useState([]);

  const [currentfile, setCurrentfile] = useState(null);
  const [docsViewerVisibility, setDocsViewerVisibility] = useState(false);

  useEffect(() => {
    if (violationId) loadUploadsList();
    // eslint-disable-next-line
  }, [violationId]);

  useEffect(() => {
    window?.addEventListener("paste", pastFunction);
    return () => {
      window?.removeEventListener("paste", pastFunction);
    };
    //eslint-disable-next-line
  }, []);

  const pastFunction = useCallback((event) => {
    if (event?.clipboardData?.files?.length) {
      const target = { files: event.clipboardData.files }
      if (setDataSource) {
        setDataSource(dataSource => {
          onUpload(target, dataSource.filter(el => el.multiChange).map(el => el._id))
          return dataSource
        })
      } else {
        onUpload(target)
      }

    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const servicesAccess = JSON.parse(
    localStorage.getItem(servicesAccessKey) || '{}'
  );

  const documentsService = servicesAccess?.documents || {};

  const loadUploadsList = async () => {
    try {
      const { data } = await creApi.getDocumentsByViolationId(
        'FILE',
        violationId,
        fieldId
      );
      setUploadedList(data.data);
      setUploadedDocuments && setUploadedDocuments(data.data);

    } catch (e) {
      toast.error(e.message);
    }
  };
  const OnUploadWrapped = (event => {
    if (checkFileSize(event)) {
      const target = event.target

      if (setDataSource) {
        setDataSource(dataSource => {
          onUpload(target, dataSource.filter(el => el.multiChange).map(el => el._id))
          return dataSource
        })
      } else {
        onUpload(target)
      }

    }

  })



  const onUpload = async (target, violationIds = []) => {

    setLoaded(0);
    setIsUploading(true);
    for (let file of target.files) {
      const data = new FormData();

      data.append('file', file);
      data.append('violationId', id);
      data.append('source', source || 'Violation');

      if (fieldId) {
        data.append('fieldId', fieldId);
      }
      if (violationIds.length) {
        for (let id of violationIds) {
          data.append('additionalViolations[]', id)
        }

      }



      try {
        const response = await http.post(
          `${apiUrl}/api/documents/upload`,
          data,
          {
            onUploadProgress: (ProgressEvent) => {
              setLoaded((ProgressEvent.loaded / ProgressEvent.total) * 100);
            },
          }
        );
        if (!response.data.status) {
          toast.error(response.data.message);
        }

        setLoaded(0);
      } catch (e) {
        toast.error(e.message);
      }
    }

    setIsUploading(false);
    loadUploadsList();
    target.value = '';

  };

  const checkFileSize = (event) => {
    let files = event.target.files;
    let size = 36214400; // in bytes - 25mg
    let err = '';
    for (let x = 0; x < files.length; x++) {
      if (files[x].size > size) {
        err += files[x].type + 'is too large, please pick a smaller file\n';
      }
    }
    if (err !== '') {
      event.target.value = null;
      toast.error(err);
      return false;
    }

    return true;
  };

  const onDelete = async (documentId) => {
    try {
      const { data } = await creApi.deleteDocument(documentId);
      if (data.status) {
        toast.success(data.message);
        loadUploadsList();
      } else {
        toast.error(data.message);
      }
    } catch (e) {
      toast.error(e.message);
    }
  };

  const handleVisibilityChange = async (documentId, isPrivate) => {
    try {
      const { data } = await creApi.changeDocumentVisibility(
        documentId,
        isPrivate
      );
      if (data.status) {
        toast.success(data.message);
        loadUploadsList();
      } else {
        toast.error(data.message);
        setUploadedList((oldList) =>
          oldList.map((doc) => {
            if (doc._id === documentId) {
              doc.isPrivate = !isPrivate;
            }
            return doc;
          })
        );
      }
    } catch (_error) {
      toast.error(
        'There was some problems in updating the document visibility.'
      );
    }
  };

  const handleUrlClick = async (document) => {
    setCurrentfile(document)
    setDocsViewerVisibility(true)
  };


  const getLabel = (fields, fieldId) => {
    const found = fields.find((field) => String(field._id) === String(fieldId));
    if (found) {
      return found?.name;
    }
  };

  return (
    <Fragment>
      <input type="file" name="file" onChange={OnUploadWrapped} multiple />
      {isUploading && (
        <div className="progress mt-2">
          <div
            className="progress-bar progress-bar-striped progress-bar-animated bg-success"
            role="progressbar"
            style={{ width: `${loaded}%` }}
          >
            Uploading...
          </div>
        </div>
      )}
      {uploadedList ? (
        <>
          <table className="table table-sm mt-3">
            <tbody>
              {category === 'HPD' && sub === 'Violations__Open' && (
                // only render when HPD/Violation__Open
                <td>
                  <a
                    className="btn btn-xs btn-link text-left px-0"
                    href={documentLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Certification of Correction
                  </a>
                  <div className="d-flex small text-capitalize">
                    Uploaded By Bcompliant
                  </div>
                </td>
              )}
              {uploadedList.map((value, key) => (
                <tr key={key}>
                  <td>
                    <button
                      className="btn btn-xs btn-link text-left px-0"
                      onClick={() => handleUrlClick(value)}
                      rel="noopener noreferrer"
                    >
                      {value?.file?.originalname}
                    </button>
                    <div className="d-flex small text-capitalize">
                      {value.uploadedBy && (
                        <>
                          Uploaded By &nbsp;
                          <strong>
                            {value.uploadedBy?.fullName || value.uploadedBy?.name}
                          </strong>{' '}
                          &nbsp;
                          {value.uploadedByUserType && (
                            <span>
                              ({value.uploadedByUserType?.toLowerCase()})
                            </span>
                          )}{' '}
                          &nbsp; at {moment(value.createdAt).format('MM/DD/YYYY')}{' '}
                          at {moment(value.createdAt).format('hh:mm A')}
                        </>
                      )}
                    </div>

                    <span className="badge badge-dark">
                      {value.uploadedByUserType === 'system' ? 'System Upload' : `Uploaded from ${showDocumentTag &&
                        value?.categoriesCustomFields?.fields.length &&
                        value?.fieldId
                        ? getLabel(
                          value.categoriesCustomFields.fields,
                          value.fieldId
                        ) || 'Files'
                        : 'Details'
                        }`}
                    </span>
                  </td>
                  <td className="text-right fit-to-content">
                    {value.uploadedByClientId === user._id &&
                      !documentsService.disablePrivateUploadsForSubUser && (
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id={`file_private_switch_${value._id}`}
                            checked={value.isPrivate}
                            onChange={(e) =>
                              handleVisibilityChange(value._id, e.target.checked)
                            }
                          />
                          <label
                            className="custom-control-label"
                            htmlFor={`file_private_switch_${value._id}`}
                          >
                            {' '}
                            Private
                          </label>
                        </div>
                      )}
                  </td>

                  <td className="text-right fit-to-content">
                    {value.uploadedByClientId === user._id && (
                      <button
                        className="btn btn-danger btn-xs btn-delete"
                        onClick={() => onDelete(value._id)}
                      >
                        <i className="fa fa-trash" /> Delete
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <DocsViewer document={currentfile} isVisible={docsViewerVisibility} setIsVisible={setDocsViewerVisibility} />
        </>
      ) : (
        ''
      )}
    </Fragment>
  );
};

export default UploadDocument;
