const moment = require('moment');

const PartialEditTextFields = (props) => {
    return (
        <>
            <div className="row">
                <div className="form-group col-12">
                    <label><strong>Title</strong></label>
                    <p>{props.formData.title}</p>
                </div>
                <div className="form-group col-12">
                    <label><strong>Building Address</strong></label>
                    {props.buildings.map((building) => {
                        return (
                            <p>{props.formData.bin === building.bin && (building.display_address ? building.display_address : building.address)}</p>
                        )
                    })}
                </div>
                <div className="form-group col-4">
                    <label><strong>Category</strong></label>
                    <p>{props.formData.category}</p>
                </div>
                <div className="form-group col-4">
                    <label><strong>Recurring Every</strong></label>
                    <div className="d-flex">
                        <p>{props.formData.recurring}</p>
                        <p>{props.formData.recurringFrequency}</p>
                    </div>
                </div>
                <div className="form-group col-4">
                    <label><strong>Estimated Time (in hours)</strong></label>
                    <p>{props.formData.estimatedTime}</p>
                </div>
            </div>
            <div className="row">
                <div className="form-group col-4">
                    <label><strong>Due Date</strong></label>
                    <p>{props.formData.dueDate && moment.utc(props.formData.dueDate).format("YYYY-MM-DD")}</p>
                </div>
                <div className="form-group col-4">
                    <label><strong>Priority</strong></label>
                    <p>{props.priority}</p>
                </div>
                {props.formData.vendorDetails &&
                    <div className="form-group col-4">
                        <label><strong>Vendor</strong></label>
                        <p>{props.formData.vendorDetails.fullName}</p>
                    </div>
                }
            </div>
        </>
    )
}
export default PartialEditTextFields;
