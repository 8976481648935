import React from 'react';
import { Droppable, Draggable } from "react-beautiful-dnd";
import ReactLoading from "react-loading";
import { Container, Header, ItemsWrapper, LoadMoreButton } from './styled';
import Item from './item';
import { useHistory } from 'react-router-dom';

const VerticalColumn = ({ isLoading, title, name, items, total, onLoadMore }) => {

    const history = useHistory();

    const count = (items?.length || 0) + (total > 5 ? total - 5 : 0) || 0;

    const onItemClick = (item) => {
        history.push(`/work-orders/view/${item._id}`);
    }

    return (
        <Droppable droppableId={name} className="dropable-column">
            {(provided, snapshot) => (
                <Container {...provided.droppableProps} ref={provided.innerRef}>
                    <Header isDragging={snapshot.isDragging}>
                        {title} - {count}
                    </Header>
                    {isLoading && <div className="d-flex justify-content-center"><ReactLoading type={"cylon"} color={"grey"} /></div>}
                    <ItemsWrapper>
                        {!isLoading && items?.map((item, index) => (
                            <Draggable key={item._id} draggableId={item._id} index={index}>
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                    >
                                        <Item item={item} onClick={() => onItemClick(item)} />
                                    </div>
                                )}
                            </Draggable>
                        ))}
                    </ItemsWrapper>
                    {!isLoading && items?.length <= 0 && <div className="text-center">No Work Orders for this section</div>}

                    {!isLoading && onLoadMore && total > items?.length &&
                        <LoadMoreButton className="btn btn-link" onClick={() => onLoadMore(name, title)}>See all {title} Work Orders</LoadMoreButton>
                    }
                    {provided.placeholder}
                </Container>
            )}
        </Droppable>
    );

}

export default VerticalColumn;
