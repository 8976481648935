import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import moment from 'moment';


const isImage = (value) => ['jpg', 'jpeg', 'png', 'gif', 'tiff'].includes(value.originalname.split('.').pop()) || value.file?.mimetype.includes('image')
const Doc = ({ doc }) => {
    return isImage(doc) ? <img
        src={doc.file?.mimetype.includes('image') ?
            window.location.origin + `/api/documents/aws-s3-file-stream/thumbnail/${localStorage.getItem('cre-token')}:${doc._id}`
            :
            doc.s3Url
        }
        alt=' unavalible'
        width="50px"
        height="50px"
        className="rounded"
    /> : <i className={`fa fa-file-o fa-3x`} style={{ width: '50px', textAlign: 'center' }} />
}
const ListCollapse = (props) => {

    const [collapse, setcollapse] = useState(false);
    const toggle = () => {
        setcollapse(!collapse)
    }


    return (
        <li className="p-1 list-group-item align-items-center pointer border-0" onClick={toggle}>
            <div className="d-flex justify-content-between text-capitalize hoverEffect">
                {['workOrderCreated', 'workOrderMovedToVisitt'].includes(props.field) ?
                    `Work Order ${props.field === 'workOrderMovedToVisitt' ? 'Moved to Visitt' : 'Created' } #${props.data}` :
                    <span className="d-flex justify-content-between text-capitalize w-100">
                        <span>
                            {['notificationsSent', 'emailSent'].includes(props.field) ? '' : 'in '}
                            {props.field === 'userId' ? 'Assignee' : props.field.replace('Id', ' ').replace(/([A-Z])/g, " $1").toLowerCase()}
                            {props.field === 'emailSent' && props?.data?.fullName !== undefined && ` To ${props.data.fullName}`}
                        </span>
                        <span className="badge badge-light">{(collapse) ? '-' : '+'}</span>
                    </span>
                }
            </div>
            {props.field !== 'workOrderCreated' &&
                <Collapse isOpen={collapse} className="mt-2">
                    {['assignedUsers', 'userId'].includes(props.field) &&
                        <ul className="list-group">
                            <li className="list-group-item list-group-item-light">
                                {Array.isArray(props.data?.__old) && <s>{props.data?.__old?.map((item) => item?.fullName).join(', ')}</s>}
                                {!Array.isArray(props.data?.__old) && <s>{props.data?.__old?.fullName || props.data?.oldName}</s>}
                            </li>
                            <li className="list-group-item">
                                {Array.isArray(props.data?.__new) && props.data?.__new?.map((item) => item?.fullName).join(', ')}
                                {!Array.isArray(props.data?.__new) && (props.data?.__new?.fullName || props.data?.newName)}
                            </li>
                        </ul>
                    }
                    {!['assignedUsers', 'userId'].includes(props.field) && props.field !== 'uploads' && props.field !== 'notificationsSent' &&
                        <ul className="list-group">
                            <li className="list-group-item list-group-item-light" style={{ whiteSpace: 'pre-wrap' }} >
                                <s>{props.data.oldName ? props.data.oldName : props.data.__old}</s></li>
                            <li className="list-group-item" style={{ whiteSpace: 'pre-wrap' }}>{props.data.newName ? props.data.newName : props.data.__new}</li>
                        </ul>
                    }
                    {/* {props.field === 'uploads' && (props.data.__old || props.data.__new) &&
                        <ul className="list-group">
                            {props.data.__old.map((uploadObj) => {
                                return (
                                    <li className="list-group-item list-group-item-light">
                                        <s>{uploadObj.originalname}</s>
                                    </li>
                                )
                            })}
                            {props.data.__new.map((uploadObj) => {
                                return (
                                    <li className="list-group-item">
                                        {uploadObj.originalname}
                                    </li>
                                )
                            })}
                        </ul>
                    } */}
                    {props.field === 'uploads' && (!props.data.__old && !props.data.__new) &&
                        <ul className="list-group ">
                            {/* {props.data.currentDocuments && props.data.currentDocuments.map((uploadObj) => {
                                return (
                                    <li className="list-group-item">
                                        {uploadObj.originalname}
                                    </li>
                                )
                            })} */}
                            {props.data.newDocuments && props.data.newDocuments.map((uploadObj) => {

                                return (
                                    <li className="list-group-item list-group-item-success d-flex align-items-center flex-column">
                                        <Doc doc={uploadObj} />
                                        <br /> {uploadObj.originalname}
                                    </li>
                                )
                            })}
                            {props.data.deletedDocuments && props.data.deletedDocuments.map((uploadObj) => {
                                return (
                                    <li className="list-group-item list-group-item-danger d-flex align-items-center flex-column">
                                        <Doc doc={uploadObj} /> <br /> {uploadObj.originalname}
                                    </li>
                                )
                            })}
                        </ul>
                    }
                    {props.field === 'notificationsSent' &&
                        <ul className="list-group">
                            {props.data.map((notify) => ['email', 'pushNotification'].includes(notify.type) ? (
                                <li className="list-group-item">
                                    <span className="text-capitalize">{notify.type.replace(/([A-Z])/g, " $1")} </span>
                                    was sent to {notify.email}
                                    {notify.vendorId && <span className="badge badge-dark ml-2">Vendor</span>}
                                    <br />
                                    at {moment(notify.date).format('MM/DD/YYYY hh:mm A')}
                                    <br /> with subject {notify.emailSubject}
                                </li>
                            ) : (
                                <li className="list-group-item">
                                    <span className="text-capitalize">{notify.type.replace(/([A-Z])/g, " $1")} </span>
                                    to {notify.email}
                                    {notify.vendorId && <span className="badge badge-dark ml-2">Vendor</span>}
                                    <br />
                                    at {moment(notify.date).format('MM/DD/YYYY hh:mm A')}
                                </li>
                            ))}
                        </ul>
                    }
                </Collapse>
            }
        </li>
    )
}

export default ListCollapse;
