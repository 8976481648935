import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import creService from '../../../services/creService';

const ChatsPreference = ({ jestData = false }) => {
    const [data, setData] = useState(jestData ? jestData : {});
    const [loading, setLoading] = useState(false);

    const defaults = {
        email: true,
        pushNotification: true
    };

    // const [defaultHeaderChecked, setDefaultHeaderChecked] = useState(defaults)

    async function fetchData() {
        try {
            const res = await creService.getAllChatsPreferences();
            if (res.data) setData(res.data);
        } catch (error) {
            toast.error(error);
        }
    }
    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        let newDefaults = defaults;
        Object.keys(data).map((eventType) => {
            if (data[eventType].email === false) {
                newDefaults.email = false;
            }
            if (data[eventType].pushNotification === false) {
                newDefaults.pushNotification = false;
            }
            return true;
        })
        // setDefaultHeaderChecked(newDefaults)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const handleSelectDeselectSingle = (eventType, type, state) => {
        data[eventType][type] = state;
        setData({ ...data });
    }

    // const handleSelectDeselectAll = (type, state) => {
    //     Object.keys(data).map((eventType) => {
    //         data[eventType][type] = state;
    //         return true;
    //     })

    //     setData({ ...data });
    //     setDefaultHeaderChecked({
    //         ...defaultHeaderChecked,
    //         [type]: state,
    //     })
    // }

    const saveNotificationPreference = async () => {
        setLoading(true);
        try {

            console.log('data', data);
            const res = await creService.updateChatsPreferences(data);
            if (res.data.status && typeof res.data.message !== 'undefined') {
                toast.success(res.data.message);
            } else {
                toast.error(res.data?.message || 'Something went wrong.');
            }
        } catch (error) {
            toast.error(error);
        }
        setLoading(false);
    }

    return (
        <React.Fragment>
            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th>Alert Type</th>
                        <th className="text-center">
                            Email
                            {/* <label className="small d-flex align-items-center justify-content-center mt-2">
                                <input type="checkbox" checked={defaultHeaderChecked.email} onChange={(e) => handleSelectDeselectAll('email', e.target.checked)} />
                                <span className="ml-1"> Un/Check All</span>
                            </label> */}
                        </th>
                        <th className="text-center">
                            Push Notification
                            {/* <label className="small d-flex align-items-center justify-content-center mt-2">
                                <input type="checkbox" checked={defaultHeaderChecked.pushNotification} onChange={(e) => handleSelectDeselectAll('pushNotification', e.target.checked)} />
                                <span className="ml-1"> Un/Check All</span>
                            </label> */}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(data).map((eventType) => {
                        return (
                            <tr key={eventType}>
                                <td>{data[eventType].displayName}</td>
                                <td className="text-center">
                                    <input type="checkbox" checked={data[eventType].email} onChange={(e) => handleSelectDeselectSingle(eventType, 'email', e.target.checked)} />
                                </td>

                                <td className="text-center">
                                    {
                                        eventType !== 'SafetyMailingDailyResponseReport' && <input type="checkbox" checked={data[eventType].pushNotification} onChange={(e) => handleSelectDeselectSingle(eventType, 'pushNotification', e.target.checked)} />
                                    }
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>

            <button onClick={saveNotificationPreference} disabled={loading} className="btn my-4 btn-primary">
                {!loading ? 'Save' : 'Saving...'}
            </button>
        </React.Fragment>
    );
}

export default ChatsPreference;
