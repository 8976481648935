import React, { Fragment } from "react";
import moment from "moment";
import { useHistory } from 'react-router-dom';

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";


const WorkOrdersCalendar = (props) => {
    const history = useHistory();

    const onEventClick = (event) => {
        const workOrder = event.event._def.extendedProps;
        history.push(`/work-orders/view/${workOrder._id}/documents`);
    };

    const statusClasses = {
        'Open': 'badge-info',
        'In Progress': 'badge-primary',
        'On Hold': 'badge-warning',
        'Completed': 'badge-success',
        'Canceled': 'badge-danger',
    }

    const statusIconClass = {
        'Open': 'O',
        'In Progress': 'I',
        'On Hold': 'OH',
        'Completed': 'CO',
        'Canceled': 'CA',
    }

    return (
        <Fragment>

            <div className="calender-bg">
                <FullCalendar
                    ref={props.calendarRef}
                    timeZone="UTC"
                    defaultView="dayGridMonth"
                    header={{
                        left: "prev title next",
                        center: "",
                        right: "timeGridDay,timeGridWeek,dayGridMonth,listWeek"
                    }}
                    allDayDefault={true}
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    events={props.data}
                    eventClick={onEventClick}
                    eventColor={'#f0f7fd'}
                    gotoDate={moment(new Date() + 1).utc(0).format('YYYY-MM-DD')}
                    defaultDate={moment(new Date()).format('YYYY-MM-DD')}
                    eventRender={(info) => {
                        const workOrder = info.event._def.extendedProps;
                        info.el.innerHTML = `<div class="p-1">
                            <span class="badge ${statusClasses[workOrder.status]}" title="${workOrder.status}">${statusIconClass[workOrder.status]}</span>
                            #${workOrder.workOrderId} - ${info.event.title} 
                        </div>`;
                    }}
                    datesRender={(info) => {
                        props.onDatesRender({
                            startDate: moment(info.view.activeStart).toISOString(),
                            endDate: moment(info.view.activeEnd).toISOString(),
                        })
                    }}
                />
            </div>
        </Fragment>
    )
}

export default WorkOrdersCalendar;
