
import { ToastContainer } from 'react-toastify';
import React, { useEffect, useState, Fragment } from 'react';
import { Button, message, Input, Select } from 'antd';
import './index.css';
import creService from "../../services/creService";
import { toast } from "react-toastify";
import { PlusSquareOutlined } from '@ant-design/icons';


const getCurrentStep = (path, questions) => {
    let finishStep
    for (let key of path) {
        if (finishStep) {
            finishStep = finishStep[key]
        } else {
            finishStep = questions[key]
        }
    }
    return finishStep
}

const getNextStep = (currentPath, questions, answerSubkey, moveBack) => {
    if (!currentPath.length) {
        return currentPath
    }
    const currentQuestion = getCurrentStep(currentPath, questions)
    if (moveBack) {
        const backpath = [...currentPath.slice(0, -1), currentPath[currentPath.length - 1] - 1]
        const priviousQuestion = getCurrentStep(backpath, questions)
        if (priviousQuestion) {
            return backpath
        } else {
            return backpath.slice(0, -2)
        }

    } else {
        if (answerSubkey) {
            if (currentQuestion[answerSubkey]) {
                return [...currentPath, answerSubkey, 0]
            }
            if (currentQuestion.sub) {
                return [...currentPath, 'sub', 0]
            }
        }


        const nextpath = [...currentPath.slice(0, -1), currentPath[currentPath.length - 1] + 1]

        const nextQuestion = getCurrentStep(nextpath, questions)
        if (nextQuestion) {
            return nextpath
        } else {
            return getNextStep(currentPath.slice(0, -2), questions)
        }

    }
}




const QuestionDisplayInterface = ({
    questionBlocks,
    questionSaveHandler,
    savedQuestionAnswers = [],
    bin,
    vendors = ['n/a'],
    appts = ['n/a'],
    users = ['n/a'],
    buildings = [{ address: 'n/a', _id: 'n/a' }],
    address,
    setModalVendorColumn = () => { },
    setModalTenant = () => { }
}) => {

    const [questions, setQuestions] = useState();
    const [currentPath, setCurrentPath] = useState([]);
    const [currentStep, setCurrentStep] = useState({});
    const [loaded, setLoaded] = useState(0);
    const [showLoadedFor, setShowLoadedFor] = useState('');
    const [block, setBlock] = useState(false);
    const [selectedFromList, setSelectedFromList] = useState('')


    useEffect(() => {

        if (currentPath.length) {
            setCurrentStep(getCurrentStep(currentPath, questions))
        }


    }, [currentPath, questions])

    useEffect(() => {

        setQuestions(questionBlocks)


    }, [questionBlocks])

    const uploadFile = async (answer, id) => {
        let result = []
        setShowLoadedFor(id)
        if (answer.files[0] && bin) {
            for (let file of answer.files) {
                const data = new FormData();
                data.append('file', file);
                // data.append('binFile', bin);
                data.append('source', 'Violation');


                try {
                    const response = await creService.uploadDocument(data, {
                        onUploadProgress: (ProgressEvent) => {
                            setLoaded(() => (ProgressEvent.loaded / ProgressEvent.total) * 100)
                        },
                    })
                    setLoaded(0)
                    if (response.data.status) {
                        result.push(response.data.key)
                    } else {
                        toast.error(response.data.message);
                    }
                } catch (e) {
                    toast.error(e.message);
                }
            }

        }
        setShowLoadedFor('')
        return result
    }

    const next = async (answer) => {

        setSelectedFromList('')
        setBlock(true)

        // upload case
        if (typeof answer === 'object') {
            answer = uploadFile(answer)
        }
        const currentQuestion = getCurrentStep(currentPath, questions)

        if (answer) {
            currentQuestion.resultAnswer = answer
            setQuestions([...questions]) // to trigger update
        }
        const answerSubkey = ((answer || currentQuestion.resultAnswer) + 'Sub')
        const nextPath = getNextStep(currentPath, questions, answerSubkey)
        if (nextPath.length > 1) {

            setCurrentPath(nextPath)
        } else {


            message.success('Processing complete!')
            if (questionSaveHandler) {
                await questionSaveHandler(questions[currentPath[0]])
                setCurrentPath([])
                setCurrentStep({})
            }
        }
        setBlock(false)

    };

    const prev = () => {
        const backPath = getNextStep(currentPath, questions, undefined, true)

        if (backPath.length > 1) {
            setCurrentPath(backPath)
        } else {
            message.error('No privious questions!')
        }

    };
    const QuestionInput = ({ onChange = () => { }, questionBlock, index }) => {
        const id = questionBlock.question.replaceAll(' ', '_') + index

        if (questionBlock.answers[0] === 'client input') {
            return <Input defaultValue={questionBlock.resultAnswer} onChange={(event) => onChange(event.target.value)} allowClear />
        } else if (questionBlock.answers[0] === 'client number') {
            return <Input defaultValue={questionBlock.resultAnswer} onChange={(event) => onChange(event.target.value)} type="number" allowClear />
        } else if (questionBlock.answers[0] === 'client date') {
            return <Input defaultValue={questionBlock.resultAnswer} onChange={(event) => onChange(event.target.value)} type="date" allowClear />
        } else if (questionBlock.answers[0] === 'client upload') {
            return <>
                <Input type="file" allowClear onChange={async (event) => { onChange(await uploadFile(event.target, id)) }} multiple={true} />
                {loaded !== 0 && showLoadedFor === id && <div className="progress mt-2">
                    <div
                        className={`progress-bar progress-bar-striped progress-bar-animated bg-info`}
                        role="progressbar"
                        style={{ width: `${loaded}%` }}
                    >
                        {`Uploading...`}
                    </div>
                </div>
                }</>
        } else if (questionBlock.answers[0] === 'client users') {
            return <Select
                allowClear
                placeholder='user no selected'
                onChange={(value) => { onChange(value) }}
                defaultValue={questionBlock.resultAnswer}
                style={{ minWidth: '100%' }}
                options={users.map((value, i) => ({
                    value,
                    label: value,
                }))}
            />
        } else if (questionBlock.answers[0] === 'client vendors') {
            return <div className='d-flex align-items-center'>

                <Select
                    allowClear
                    style={{ flex: 1 }}
                    placeholder='vendor no selected'
                    onChange={(value) => { onChange(value) }}
                    defaultValue={questionBlock.resultAnswer}

                    options={vendors.map((value, i) => ({
                        value,
                        label: value,
                    }))}
                />

                <PlusSquareOutlined
                    style={{ fontSize: '25px', marginLeft: '15px', color: 'rgb(213 212 212)', cursor: 'pointer' }}
                    onClick={() => { setModalVendorColumn(true) }}

                />
            </div>

        } else if (questionBlock.answers[0] === 'client appt') {
            return <div className='d-flex align-items-center'>

                <Select
                    allowClear
                    style={{ flex: 1 }}
                    placeholder='apartment no selected'
                    onChange={(value) => { onChange(value) }}
                    defaultValue={questionBlock.resultAnswer}

                    options={appts.map((value, i) => ({
                        value,
                        label: value,
                    }))}
                />

                <PlusSquareOutlined
                    style={{ fontSize: '25px', marginLeft: '15px', color: 'rgb(213 212 212)', cursor: 'pointer' }}
                    onClick={() => { setModalTenant(true) }}

                />
            </div>

        }

        if (questionBlock.answers.length <= 3) {
            return <div className='d-flex '>
                {questionBlock.answers.map(answer =>
                    <button
                        key={answer}
                        onClick={() => onChange(answer === questionBlock.resultAnswer ? '' : answer)}
                        className={`btn btn-outline-light flex-grow-1 rounded-0 q-btn ${answer === questionBlock.resultAnswer ? 'active' : ''}`}
                    >
                        {answer}
                    </button>
                )}
            </div>
        }

        return <Select
            allowClear
            placeholder='answer no selected'
            onChange={(value) => { onChange(value) }}
            defaultValue={questionBlock.resultAnswer}
            style={{ minWidth: '100%' }}
            options={questionBlock.answers.map((value, i) => ({
                value,
                label: value,
            }))}
        />

    }
    const submitListQuestionary = async () => {
        message.success('Processing complete!')
        if (questionSaveHandler) {
            await questionSaveHandler(questions[currentPath[0]])
            setCurrentPath([])
            setCurrentStep({})
        }
    }
    const listHandleChange = async (value, indexForm, indexQuestion) => {
        questions[indexForm].questions[indexQuestion].resultAnswer = value
        setQuestions([...questions])

    }



    return (
        questions ?
            <>
                <div className='d-flex justify-content-center w-100' >

                    <div className='w-100' >
                        <div className='d-flex justify-content-between'>
                            <Select
                                value={currentPath[0]}
                                placeholder='select question block'
                                style={{ fontSize: '21px', minWidth: '300px' }}
                                onChange={(value) => { setCurrentPath([value, 'questions', 0]) }}
                                options={questions.map((value, i) => {

                                    const allreadyDone = savedQuestionAnswers.some(el => el.questionBlockID === value._id)
                                    return {
                                        value: i,
                                        label: value.blockName + (allreadyDone ? ' (Done)' : ''),

                                    }
                                }
                                )}
                            />

                        </div>
                        {questions[currentPath[0]]?.listView ? <>

                            <div className='steps-content'>
                                {
                                    currentPath[0] !== undefined && (
                                        <>
                                            <div className='text-left  '>
                                                <div className={`d-flex m-2 pt-2 pr-5`}>
                                                    <div className='w-50 d-flex align-items-center' >1. Property</div>
                                                    <div className='w-50'><Input disabled={true} value={address?.address} /></div>
                                                </div>
                                                {
                                                    questions[currentPath[0]].questions.map((questionBlock, i) => (
                                                        <div className={`d-flex m-2 pt-2  pr-5 border-top`} key={questionBlock.question.replaceAll(' ', '_')}>
                                                            <div className='w-50 d-flex align-items-center' >{i + 2}. {questionBlock.question}</div>
                                                            <div className='w-50' key={i + questionBlock.question}>
                                                                {QuestionInput({
                                                                    index: i,
                                                                    questionBlock,
                                                                    onChange: (value) => listHandleChange(value, currentPath[0], i)
                                                                })}

                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                            <button
                                                className={`btn btn-primary d-block w-100 mt-3`}

                                                onClick={submitListQuestionary}
                                            >
                                                Submit

                                            </button>
                                        </>
                                    )
                                }
                            </div>



                        </> : (
                            <>
                                <div className="steps-content d-flex align-items-center justify-content-center flex-column">

                                    <h3>{currentStep.question}</h3>
                                    <div className='mt-3 w-100 p-2'>
                                        {currentStep.answers?.map((answer, i) => {
                                            const id = currentStep.question + i
                                            if (answer === 'client input') {
                                                return <Input.Group compact key={id}>
                                                    <Input style={{ width: 'calc(100% - 200px)' }} defaultValue={currentStep.resultAnswer} id={id} />
                                                    <Button disabled={block} type="primary" onClick={(event) => next(document.getElementById(id).value)} >Submit</Button>
                                                </Input.Group>
                                            } else if (answer === 'client number') {
                                                return <Input.Group compact key={id}>
                                                    <Input style={{ width: 'calc(100% - 200px)' }} defaultValue={currentStep.resultAnswer} type="number" id={id} />
                                                    <Button
                                                        disabled={block}
                                                        type="primary"
                                                        onClick={(event) => next(document.getElementById(id).value)} >
                                                        Submit
                                                    </Button>
                                                </Input.Group>
                                            } else if (answer === 'client date') {
                                                return <Input.Group compact key={id}>
                                                    <Input style={{ width: 'calc(100% - 200px)' }} defaultValue={currentStep.resultAnswer} type="date" id={id} />
                                                    <Button
                                                        disabled={block}
                                                        type="primary"
                                                        onClick={(event) => next(document.getElementById(id).value)} >
                                                        Submit
                                                    </Button>
                                                </Input.Group>
                                            } else if (answer === 'client upload') {
                                                return <Fragment key={id}>
                                                    <Input type="file" id={id} />
                                                    {loaded !== 0 && <div className="progress mt-2">
                                                        <div
                                                            className={`progress-bar progress-bar-striped progress-bar-animated bg-info`}
                                                            role="progressbar"
                                                            style={{ width: `${loaded}%` }}
                                                        >
                                                            {`Uploading...`}
                                                        </div>
                                                    </div>
                                                    }
                                                    <Button
                                                        disabled={loaded || block}
                                                        className='mt-2' type="primary"
                                                        onClick={(event) => next(document.getElementById(id))}  >
                                                        Submit
                                                    </Button>
                                                </Fragment>
                                            } else if (answer === 'client users') {
                                                return <div key={id} className='d-flex justify-content-center'>
                                                    <Select
                                                        id={id}
                                                        placeholder='user no selected'
                                                        onChange={(value) => { setSelectedFromList(value) }}
                                                        defaultValue={currentStep.resultAnswer}
                                                        style={{ fontSize: '21px', minWidth: '300px' }}
                                                        options={users.map((value, i) => ({
                                                            value,
                                                            label: value,
                                                        }))}
                                                    />

                                                    <Button
                                                        disabled={block}
                                                        type="primary"
                                                        onClick={(event) => next(selectedFromList)} >
                                                        Submit
                                                    </Button>
                                                </div>
                                            } else if (answer === 'client vendors') {
                                                return <div key={id} className='d-flex justify-content-center'>
                                                    <Select
                                                        id={id}
                                                        placeholder='vendor no selected'
                                                        onChange={(value) => { setSelectedFromList(value) }}
                                                        defaultValue={currentStep.resultAnswer}
                                                        style={{ fontSize: '21px', minWidth: '300px' }}
                                                        options={vendors.map((value, i) => ({
                                                            value,
                                                            label: value,
                                                        }))}
                                                    />
                                                    <PlusSquareOutlined
                                                        style={{ fontSize: '33px', color: 'd9d9d9', cursor: 'pointer' }}
                                                        onClick={() => { setModalVendorColumn(true) }}
                                                    />

                                                    <Button
                                                        disabled={block}
                                                        type="primary"
                                                        onClick={(event) => next(selectedFromList)} >
                                                        Submit
                                                    </Button>
                                                </div>
                                            } else if (answer === 'client appt') {
                                                return <div key={id} className='d-flex justify-content-center'>
                                                    <Select
                                                        id={id}
                                                        placeholder='apartment no selected'
                                                        onChange={(value) => { setSelectedFromList(value) }}
                                                        defaultValue={currentStep.resultAnswer}
                                                        style={{ fontSize: '21px', minWidth: '300px' }}
                                                        options={appts.map((value, i) => ({
                                                            value,
                                                            label: value,
                                                        }))}
                                                    />
                                                    <PlusSquareOutlined
                                                        style={{ fontSize: '33px', color: 'd9d9d9', cursor: 'pointer' }}
                                                        onClick={() => { setModalTenant(true) }}
                                                    />

                                                    <Button
                                                        disabled={block}
                                                        type="primary"
                                                        onClick={(event) => next(selectedFromList)} >
                                                        Submit
                                                    </Button>
                                                </div>
                                            }
                                            // else if (answer === 'client buildings') {
                                            //     return <div key={id} className='d-flex justify-content-center'>
                                            //         <Select
                                            //             id={id}
                                            //             placeholder='building no selected'
                                            //             onChange={(value) => { setSelectedFromList(value) }}
                                            //             defaultValue={currentStep.resultAnswer}
                                            //             style={{ fontSize: '21px', minWidth: '300px' }}
                                            //             options={buildings.map((value, i) => ({
                                            //                 value: value._id,
                                            //                 label: value.address,
                                            //             }))}
                                            //         />

                                            //         <Button
                                            //             disabled={block}
                                            //             type="primary"
                                            //             onClick={(event) => next(selectedFromList)} >
                                            //             Submit
                                            //         </Button>
                                            //     </div>
                                            // } 
                                            else {
                                                // if (currentStep.listDisplay) {
                                                //     if (i !== 0) { return <Fragment key={id}></Fragment> }
                                                //     return <div key={id} className='d-flex justify-content-center'>
                                                //         <Select
                                                //             id={id}
                                                //             placeholder='answer no selected'
                                                //             onChange={(value) => { setSelectedFromList(value) }}
                                                //             defaultValue={currentStep.resultAnswer}
                                                //             style={{ fontSize: '21px', minWidth: '300px' }}
                                                //             options={currentStep.answers.map((value, i) => ({
                                                //                 value,
                                                //                 label: value,
                                                //             }))}
                                                //         />

                                                //         <Button
                                                //             disabled={block}
                                                //             type="primary"
                                                //             onClick={(event) => next(selectedFromList)} >
                                                //             Submit
                                                //         </Button>
                                                //     </div>
                                                // }
                                                return <Button
                                                    key={answer + i}
                                                    className='ml-2'
                                                    type={currentStep.resultAnswer === answer && `primary`}
                                                    onClick={() => next(answer)}
                                                    disabled={block}
                                                >{answer}</Button>
                                            }
                                        })
                                        }
                                    </div>
                                </div>


                                {
                                    currentPath.length !== 0 && !questionSaveHandler &&
                                    <div className="steps-action">

                                        <Button type="primary" onClick={() => next()}>
                                            Next
                                        </Button>
                                        <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
                                            Previous
                                        </Button>

                                    </div>
                                }
                            </>
                        )
                        }
                    </div>
                </div>

                <ToastContainer />
            </> : null
    );
};

export default QuestionDisplayInterface;