import React from 'react';

const CounterWidget = ({ iconClass = 'fa-arrow-up', label = 'Total Sent', value = 0, isActive = false, onClick = null }) => {
    return (
        <div className={`d-flex justify-content-center text-center border p-3 rounded ${isActive ? 'bg-light text-dark' : ''} ${onClick ? 'pointer' : ''}`} onClick={onClick}>
            <div className="bg-primary rounded-circle mr-2" style={{ padding: '15px 18px 10px 18px' }}><i className={`fa ${iconClass}`} /></div>
            <div className="d-flex flex-column">
                <label className="text-uppercase font-weight-bold">{label}</label>
                <span>{value}</span>
            </div>
        </div>
    )
}

export default CounterWidget;