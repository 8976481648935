import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Bar } from "react-chartjs-2";

import creApi from "../../../../services/creService";


const HPDViolationBarChart = ({ tableData, violationData }) => {

    const [chartData, setChartData] = useState({
        labels: [],
        datasets: []
    });

    // eslint-disable-next-line no-unused-vars
    const [violationByApartments, setViolationByApartments] = useState([]);
    const [violationByCodes, setViolationByCodes] = useState([]);
    const [codes, setCodes] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [isCodesLoading, setIsCodesLoading] = useState(false);

    const getHPDViolationCodes = async (codes) => {
        setIsCodesLoading(true);
        try {
            const { data } = await creApi.getHPDViolationCodesDesc(codes);
            if (data.status) {
                setCodes(data.data);
            } else {
                toast.error(data.message);
            }
            setIsCodesLoading(false);
        } catch (err) {
            toast.error(err.message);
            setIsCodesLoading(false);
        }
    }

    const getViolationsByApartments = async (tableData) => {
        const recordWithApartmentsOnly = tableData.filter((record) => record.apartment);

        const violationByApartments = {};
        
        for (const record of recordWithApartmentsOnly) {
            let apartment = record.apartment.replaceAll(/[^\dA-Za-z]/ig,'');
            if (typeof violationByApartments[apartment] === 'undefined') {
                violationByApartments[apartment] = [];
            }
            violationByApartments[apartment].push(record);
        }

        setViolationByApartments(violationByApartments);
        setChartData((prev) => ({ ...prev, labels: Object.keys(violationByApartments) }));
    }

    const getViolationsByCodes = async (violationData) => {
        const recordWithCodesOnly = violationData.filter((record) => record.ordernumber);
        const violationByCodes = {};

        for (const record of recordWithCodesOnly) {
            if (typeof violationByCodes[record.ordernumber] === 'undefined') {
                violationByCodes[record.ordernumber] = [];
            }
            violationByCodes[record.ordernumber].push(record);
        }

        setViolationByCodes(violationByCodes);
    }

    useEffect(() => {
        if (Array.isArray(violationData) && violationData.length > 0) {
            getViolationsByCodes(violationData)
        }

    }, [violationData]);

    useEffect(() => {
        if (Array.isArray(codes) && codes.length > 0) {

            const datasets = [];

            for (const code of codes) {
                let data = [];
                for (const aptNumber of chartData.labels) {
                    const violationOfApartment = violationData.filter((record) => record.apartment === aptNumber && code.code.includes(record.ordernumber));
                    data.push(violationOfApartment.length);
                };

                datasets.push({
                    data,
                    label: code.name,
                    borderColor: code.colorCode,
                    backgroundColor: code.colorCode,
                    fill: true,
                    stack: 1,
                });

            }

            setChartData((prev) => ({
                ...prev,
                datasets,
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [codes]);

    useEffect(() => {
        if (Array.isArray(tableData) && tableData.length > 0) {
            getViolationsByApartments(tableData);
        }
    }, [tableData]);

    useEffect(() => {
        if (violationByCodes && Object.keys(violationByCodes).length > 0) {
            getHPDViolationCodes(Object.keys(violationByCodes));
        }
    }, [violationByCodes]);

    if (chartData.labels.length <= 0) {
        return null;
    }
    
    return <Bar
        type="bar"
        width={130}
        height={50}
        options={{
            title: {
                display: true,
                text: "HPD Violation Count by Unit",
                fontSize: 15
            },
            legend: {
                display: true, //Is the legend shown?
                position: "bottom" //Position of the legend.
            }
        }}
        data={chartData}
    />

};

export default HPDViolationBarChart;