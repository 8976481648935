import React, { Fragment, useState } from 'react';
import creService from "../../services/creService";
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import localState, { SAFETY_MAILING_CART_KEY } from '../../common/localState';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { UncontrolledTooltip } from 'reactstrap';

const SafetyMailingCart = ({ cartData, setCartData, onEdit }) => {

    const history = useHistory();

    const [isLoading, setIsLoading] = useState(false);

    const deleteItemFromCart = (itemIndex) => {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        try {
                            let cloneCartData = Array.from(cartData);
                            if (typeof cloneCartData[itemIndex] !== 'undefined') {
                                cloneCartData.splice(itemIndex, 1);
                                setCartData(cloneCartData);
                                localState.setItem(SAFETY_MAILING_CART_KEY, cloneCartData);
                                toast.success('This order has been removed from your Safety Mailing Orders Cart.');
                            } else {
                                toast.error('Invalid item or it was already removed from the cart. Please refresh.');
                            }
                        } catch (_err) {
                            toast.error(_err.message);
                        }
                    }
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    }

    const submitOrder = async () => {
        setIsLoading(true);
        try {
            const payload = localState.getItem(SAFETY_MAILING_CART_KEY);
            const { data } = await creService.createSafetyMailingOrder(payload);
            if (data.status) {
                toast.success(data.message);
                history.push(`/safety-mailings?newOrder=${data.data.orderNumber}`);
                localState.removeItem(SAFETY_MAILING_CART_KEY);
            } else {
                toast.error(data.message);
            }
            setIsLoading(false);
        } catch (_err) {
            toast.error(_err.message);
            setIsLoading(false);
        }
    }

    return (
        <Fragment>
            <h5>Safety Mailing Order Cart</h5>
            <hr />

            {cartData.length <= 0 && <span>No items added in the cart yet.</span>}
            {cartData.length > 0 && <>
                {cartData.map((_item, index) => <>
                    <h6 className="d-flex justify-content-between">
                        <div>
                            <div>{index + 1}. {_item.building.address}</div>
                            <small>{_item.addressLine1} {_item.city} {_item.state} {_item.zipcode} - ({_item.aptNumbers.length})</small>
                        </div>
                        <div className="d-flex flex-column">
                            <button className="btn btn-info btn-xs mb-1" onClick={() => onEdit(_item)} id='editHelp'>
                                <i className="fa fa-edit"></i>
                            </button>
                            <UncontrolledTooltip
                                placement="right"
                                target="editHelp"
                            >
                                Edit Order
                            </UncontrolledTooltip>
                            <button className="btn btn-danger btn-xs" onClick={() => deleteItemFromCart(index)} id='deleteHelp' >
                                <i className="fa fa-times"></i>
                            </button>
                            <UncontrolledTooltip
                                placement="right"
                                target="deleteHelp"
                            >
                                Delete Order
                            </UncontrolledTooltip>
                        </div>
                    </h6>
                    <div>
                        {_item.aptNumbers.map((_apt) => <small className="badge" style={{ backgroundColor: '#e9e9e9' }}>{_apt}</small>)}
                    </div>
                    <hr />
                </>)}
                <button className="btn btn-primary btn-lg" onClick={() => submitOrder()} disabled={isLoading}>{isLoading ? 'Processing...' : 'Submit Order'}</button>
            </>}

        </Fragment>
    );
}

export default SafetyMailingCart;
