import React, { Fragment, useState, useEffect } from 'react';
import { useHistory } from "react-router";

import Block from './block'
import creService from '../../services/creService';

const OpenViolationsBlocks = [
  'DOB',
  'DEP',
  'SWO',
  'VO',
  'HPD',
  'FDNY',
  'ECB-DOB',
  'DOT',
  'DSNY',
  'Hearings',
  'DOH',
  'V.Orders',
  'Corrections',
  'HPD-Cert',
  // 'BcompliantRetained',
]

let type = 'Open Violations';
const OpenViolationList = (props) => {

  const history = useHistory();


  const [data, setData] = useState(
    {
      SWO: { total: '' },
      VO: { total: '' },
      HPD: { total: '' },
      DOB: { total: '' },
      FDNY: { total: '' },
      'ECB-DOB': { total: '' },
      DOT: { total: '' },
      DSNY: { total: '' },
      DEP: { total: '' },
      DOH: { total: '' },
      'V.Orders': { total: '' },
      'Criminal S': { total: '' },
      Corrections: { total: '' },
      BcompliantRetained: { total: '' },
      DOS: { total: '' },
      Hearings: { total: '' },
      'HPD-Cert': { total: '' },
    }
  );

  useEffect(() => {
    creService.getDashboardData(type).then((res) => {
      const data = OpenViolationsBlocks.reduce((ac, a) => {
        ac[a] = {
          total: res.data[a]?.totalAllTime,
          label: a === 'VO' ? a : res.data[a].label
        }
        return ac
      }, {})
      setData(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function dashboardDetails(sub) {
    history.push(`/dashboard/${type}/${sub}`);
  }

  return (
    <Fragment>
      <div className='d-flex flex-wrap justify-content-center'>
        {OpenViolationsBlocks.map(el =>
          <Fragment key={el}>
            <Block
              sub={el}
              data={data}
              dashboardDetails={dashboardDetails}
              type='Open Violations'
            />

          </Fragment>
        )}
      </div>
    </Fragment>
  );
};



export default OpenViolationList;
