import React, { Fragment, useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { Badge, Card, Checkbox, Divider } from 'antd';
import styles from './todolist.module.css'
import creService from "../../services/creService";
import moment from 'moment';

const countStep = 2
const ShowMoreBtn = ({ loadMore }) => {
  return (
    <div onClick={loadMore} style={{ fontSize: 10, cursor: 'pointer' }}
      className="text-underline text-muted text-decoration-underline mt-2">
      Show more...
    </div>
  )

}
const TodoItem = ({ item, editProperty, setProperty, setTodoChecked, colorStyle, itemType }) => {
  const history = useHistory();

  function addressClickHandler() {
    history.push(`/violations/all/${item.bin}`);
  }

  function categoryClickHandler() {
    if (item.sub === 'customCompliance') {
      history.push(`/custom-compliance/${item.sub}/${item.recordId}`);
    } else {
      history.push(`/violation-details/${item.recordId}`);
    }
  }
 const date = item.additionalInfo?.dueDate

  return (
    <div className="media d-flex w-100 flex-column">
      <div className="w-100 d-flex justify-content-between">
        <div className='d-flex align-items-center'>
          <div className={`${styles.verticalDivider} badge-${colorStyle} mr-3`}>&nbsp;</div>
          <div className={`d-flex align-items-center mr-4 ${styles.dateInfo}`}>
            <Checkbox
              onChange={() => setTodoChecked(item._id)}
              className={`${styles[`checkbox-${colorStyle}`]} mr-3`} />
            <div>
              <div className="d-flex flex-column align-items-end">
                {date ? (
                  <>
                    <span className={`text-${colorStyle}`}>
                      {moment.utc(new Date(date)).format('MMM')}
                    </span>
                    <span className={`text-${colorStyle}`}>
                      {moment.utc(new Date(date)).format('D')}
                    </span>
                  </>

                ) : <span className="text-muted">N/A{" "}
                  <i
                    onClick={() => editProperty(item._id, 'dueDate', true)}
                    style={{ fontSize: 11 }}
                    className="fa fa-pencil pointer text-muted"></i>
                </span>
                }
                <div className="position-relative" style={{ height: 10 }}>
                  {item['dueDate']?.editable ? (
                    <input
                      className={styles.dateInput}
                      type='date'
                      onChange={(e) => {
                        setProperty(item._id, 'dueDate', e.target.value)
                      }}
                      value={item.additionalInfo?.dueDate}
                      autoFocus
                      placeholder={"dd.mm.yy"}
                      max="2055-12-31"
                      onBlur={() => editProperty(item._id, 'dueDate', false)}
                      onKeyPress={(e) => e.key === 'Enter' && e.target.blur()}
                    />
                  ) : (
                    <>
                      {item.additionalInfo?.dueDate &&
                        <i onClick={() => editProperty(item._id, 'dueDate', true)}
                          style={{ fontSize: 11 }}
                          className="fa fa-pencil pointer text-muted">
                        </i>
                      }
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className={`${styles.mainInfo}`}>
            <div onClick={categoryClickHandler} className="pointer pt-2 d-flex">
              <div className="d-flex flex-column">
                <span className={`text-${colorStyle}`}>{item.category}</span>
                <p className="text-capitalize text-muted mb-0">{item.sub}</p>
              </div>
              <div className={`${styles.itemType} badge-${colorStyle}`}>{itemType}</div>
            </div>

            <div onClick={addressClickHandler} className="pointer">
              {item.building?.display_address ? item.building?.display_address : item.building?.address}
            </div>
            <div className="pb-1">
              <strong>Notes:</strong> {item['notes']?.editable ? (
                <input
                  onChange={(e) => {
                    setProperty(item._id, 'notes', e.target.value)
                  }}
                  value={item.additionalInfo?.notes}
                  onBlur={() => editProperty(item._id, 'notes', false)}
                  autoFocus
                  onKeyPress={(e) => e.key === 'Enter' && e.target.blur()}
                />
              ) : (
                <span className="text-muted">
                  {item.additionalInfo?.notes || 'N/A'}{' '}<i onClick={() => editProperty(item._id, 'notes', true)}
                    className="fa fa-pencil pointer"></i>
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>)
}
const tabs = {
  TODAY: "TODAY",
  POST: "POST",
  UPCOMING: "UPCOMING"
}
const TodoList = () => {

  const [loading, setLoading] = useState(true);
  const [dataItems, setData] = useState([]);
  const [postIdx, setPostIdx] = useState(3);
  const [upcomingIdx, setUpcomingIdx] = useState(3);
  const [todayIdx, setTodayIdx] = useState(3);
  const [activeTab, setActiveTab] = useState(null);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  async function fetchData() {
    try {
      setLoading(true);
      const response = await creService.getAllPriority();
      if (response.data.status) {
        setData(response.data.data.map(el => ({
          ...el, additionalInfo: { dueDate: el.additionalInfo?.dueDate?.slice(0,10) || '', notes: el.additionalInfo?.notes || '' }
        })));
      } else {
        toast.error(response.data.message);
      }
    } catch (e) {
      toast.error(e.message);
    }
    setLoading(false);
  }


  const setProperty = (id, propertyName, value) => {

    setData(dataItems.map((el) => {
      if (el._id === id) {
        if (!el.additionalInfo) {
          el.additionalInfo = {}
        }
        el.additionalInfo[propertyName] = value
        if (propertyName === 'dueDate') {
          editProperty(id, propertyName, false)
        }
      }
      return el
    }))
  }

  const editProperty = async (id, propertyName, value) => {

    if (value === false) {
      try {
        const { additionalInfo } = dataItems.find(el => el._id === id)
        const { data } = await creService.updatePriority(id, { additionalInfo });
        if (!data.status) {
          toast.error(data.message);
        }
      } catch (e) {
        toast.error(e.message);
      }
    }

    setData(dataItems.map((el) => {
      if (el._id === id) {
        el[propertyName] = { editable: value }
      }
      return el
    }))

  }
  const setTodoChecked = async (id) => {
    try {
      const { data } = await creService.updatePriority(id, { ready: true });
      if (data.status) {
        setData(dataItems.filter((el) => el._id !== id))
        toast.success('TODO checked successfully')
      } else {
        toast.error(data.message);
      }
    } catch (e) {
      toast.error(e.message);
    }

  }

  const { postDue, upcomingDue, todayDue } = dataItems.reduce((ac, a) => {
    
    const { dueDate } = a.additionalInfo
   
    if (!dueDate || (dueDate && dueDate === moment(new Date()).format('YYYY-MM-DD'))) {
      ac.todayDue.push(a)
    } else if (dueDate && (new Date(a.additionalInfo.dueDate)).getTime() < Date.now()) {
      ac.postDue.push(a)
    } else {
      ac.upcomingDue.push(a)
    }
    return ac
  }, {
    postDue: [],
    upcomingDue: [],
    todayDue: []
  })

  const onTabChange = (tabName) => {
  
    if (activeTab === tabName) {
      setActiveTab(null)
    } else {
      setActiveTab(tabName)
    }
  }
  const isTabShown = (tab) => {
    return (activeTab === null || activeTab === tab)
  }
  return (
    <Fragment>
      {dataItems.length > 0 &&
        <div className="card user-card mt-3">
          <div className="card-header">
            <div className="online-user">
              <h5 className="f-16 mb-0">TO DO List</h5>
            </div>

            <div className="d-flex mt-3 mb-2 align-items-center">
              <Badge onClick={() => onTabChange(tabs.TODAY)} offset={[-10, 5]} className={styles.customBadge}
                color="#FFFFFD" count={todayDue.length}>
                <div
                  className={`${styles.badgeSuccess} ${!isTabShown(tabs.TODAY) ? styles.tabsActive : ''} ${styles.customTab}`}>
                  TO DO
                </div>
              </Badge>
              <Badge onClick={() => onTabChange(tabs.POST)} offset={[-10, 5]} color="#FF5370" count={postDue.length}>
                <div
                  className={`${styles.badgeDanged} ${!isTabShown(tabs.POST) ? styles.tabsActive : ''} ${styles.customTab}`}>
                  Past Due
                </div>
              </Badge>
              <Badge onClick={() => onTabChange(tabs.UPCOMING)} offset={[-10, 5]} color="#007bff"
                count={upcomingDue.length}>
                <div
                  className={`${styles.badgeInfo} ${!isTabShown(tabs.UPCOMING) ? styles.tabsActive : ''} ${styles.customTab}`}>
                  Upcoming Due
                </div>
              </Badge>
            </div>
          </div>


          {loading ? (
            <div align="center">
              {' '}
              <ReactLoading type={'cylon'} color={'grey'} />{' '}
            </div>
          ) :
            <>
              {postDue.length > 0 && isTabShown(tabs.POST) &&
                <div className="mt-3">
                  {postDue.slice(0, postIdx).map((item, index) => {
                    return (
                      <Fragment key={item._id}>
                        <Card
                          bodyStyle={{ fontSize: 16, padding: 0 }}
                          className={`${styles.todoWrapper} rounded mb-3`}
                          size="small"
                        >
                          <div className="d-flex flex-column align-items-start">
                            <TodoItem
                              colorStyle="danger"
                              setTodoChecked={setTodoChecked}
                              item={item}
                              editProperty={editProperty}
                              setProperty={setProperty}
                              itemType={"Past Due"}
                            />
                          </div>
                        </Card>
                      </Fragment>
                    )
                  })}
                  {(postIdx < postDue.length) && <ShowMoreBtn
                    loadMore={() => setPostIdx(count => count + countStep > postDue.length ? postDue.length : count + countStep)} />}
                </div>
              }
              {todayDue.length > 0 && isTabShown(tabs.TODAY) &&
                <div className="mt-3">
                  {todayDue.slice(0, todayIdx).map((item, index) => {
                    return (
                      <Fragment key={item._id}>
                        <Card
                          bodyStyle={{ fontSize: 16, padding: 0 }}
                          className={`${styles.todoWrapper} rounded mb-3`}
                          size="small"
                        >
                          <div key={item._id} className="d-flex flex-column align-items-start">
                            <TodoItem
                              colorStyle={"success"}
                              setTodoChecked={setTodoChecked}
                              item={item}
                              editProperty={editProperty}
                              setProperty={setProperty}
                              itemType={"TO DO"}
                            />
                          </div>
                        </Card>
                      </Fragment>
                    )
                  })}
                  {(todayIdx < todayDue.length) && <ShowMoreBtn
                    loadMore={() => setTodayIdx(count => count + countStep > todayDue.length ? todayDue.length : count + countStep)} />}
                </div>
              }
              {upcomingDue.length > 0 && isTabShown(tabs.UPCOMING) &&
                <div className="mt-3">
                  {upcomingDue.slice(0, upcomingIdx).map((item, index) => {
                    return (
                      <Fragment key={item._id}>
                        <Card
                          bodyStyle={{ fontSize: 16, padding: 0 }}
                          className={`${styles.todoWrapper} rounded mb-3`}
                          size="small"
                        >
                          <div key={item._id} className="d-flex flex-column align-items-start">
                            <TodoItem
                              colorStyle={"info"}
                              setTodoChecked={setTodoChecked}
                              item={item}
                              editProperty={editProperty}
                              setProperty={setProperty}
                              itemType={"Upcoming Due"}
                            />
                          </div>
                        </Card>
                      </Fragment>
                    )
                  })}
                  {(upcomingIdx < upcomingDue.length) &&
                    <ShowMoreBtn
                      loadMore={() => setUpcomingIdx(count => count + countStep > upcomingDue.length ? upcomingDue.length : count + countStep)} />
                  }
                </div>
              }
            </>
          }
        </div>
      }
      <Divider />
    </Fragment>
  );
};
export default TodoList;
