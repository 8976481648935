import moment from 'moment';
import './caseInvestigations.css'
import { useHistory } from "react-router";

const CaseInvestigations = ({ items }) => {

    const history = useHistory();

    const investigationClickHandler = (_id) => {
      history.push(`/violation-details/${_id}`);
    }

    return (
        <div className="w-100 mt-5 col-12">
            <h5 className='text-info'>Investigations</h5>
            <div className="d-flex text-center align-items-center" >
                <div className="col-4 p-0 font-weight-bold">
                    Date
                </div>
                <div className="col-4 p-0 font-weight-bold">
                    Case #
                </div>
                <div className="col-4 p-0 font-weight-bold">
                    Status
                </div>
            </div>
            {items.map((el, i) => {
                return <div className="d-flex text-center border-top m-1 pt-3 pb-2 align-items-center pointer caseInvestigation" key={i} onClick={() => investigationClickHandler(el._id)}>
                    <div className="col-4 p-0">
                        <p>{el.violationData.investigationcompleted ? moment(new Date(el.violationData.investigationcompleted)).format('MM/DD/YYYY') : 'n/a'}</p>
                    </div>
                    <div className="col-4 p-0">
                        <p>{el.violationData.casenumber}</p>
                    </div>

                    <div className="col-4 p-0">
                        <p><span className='p-1' style={{ background: el.violationData.investigationstatus === 'FAILED' ? '#ffb6c1' : '#ccffd3' }}>{el.violationData.investigationstatus}</span></p>
                    </div>
                </div>
            })}

        </div>
    )
}

export default CaseInvestigations;