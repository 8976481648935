import React, { Fragment, useState, useRef, useEffect } from 'react';

import { toast } from 'react-toastify';
import { Link, useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import ReactLoading from 'react-loading';
import { Switch } from 'antd';
import guard from '../../../common/guard';
import HistoryUpdates from './activity'

import creService from '../../../services/creService';

const StaffCreate = ({ jestData = false }) => {
  const history = useHistory();
  const formRef = useRef();
  const params = useParams();
  const subUserId = params.id;
  const editView = subUserId ? true : false;
  const [formData, setFormData] = useState(jestData ? jestData.formData : {});
  const [buildings, setBuildings] = useState(
    jestData ? jestData.buildings : []
  );
  const [availableServicePermissions, setAvailableServicePermissions] =
    useState([]);
  const [permissions, setPermissions] = useState({});
  const [selectedPermissions, setSelectedPermissions] = useState({
    workOrders: [],
    violations: [],
    colors: [],
    safetyMailings: [],
    integrations: []
  });
  const [notifications, setNotifications] = useState({
    workOrders: true,
    violations: true,
  });
  const [showWorkOrderList, setShowWorkOrderList] = useState(false);
  const [selectedWOListRadio, setSelectedWOListRadio] = useState('');
  const [showWorkOrderEdit, setShowWorkOrderEdit] = useState(false);
  const [selectedWOEditRadio, setSelectedWOEditRadio] = useState('');
  const [isCreating, setIsCreating] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingInvite, setIsLoadingInvite] = useState(false);

  const [portfolioBuildings, setPortfolioBuildings] = useState(false);
  const [portfolios, setPortfolios] = useState([]);
  const [selectedPortfolios, setSelectedPortfolios] = useState([]);

  const phoneValidation = () => {
    // eslint-disable-next-line no-useless-escape
    const regex =
      /^(\+\d{1,2}\s?)?1?-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i;
    return !(!formData.phone || regex.test(formData.phone) === false);
  };

  const handleInput = (e) => {
    const { name, value, type } = e.target;
    setFormData((state) => ({
      ...state,
      [name]: type === 'date' ? new Date(value) : value,
    }));
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    setIsCreating(true);

    const isPhoneValidate = phoneValidation();

    if (!isPhoneValidate) {
      toast.error('Phone Number not valid!');
      setIsCreating(false);
      return false;
    }

    let selectedWOPermissions = permissions.workOrders
      ? [...permissions.workOrders]
      : [];
    if (selectedWOListRadio.length > 0) {
      const availableOptions = ['list', 'listSub', 'listBin'];
      availableOptions.map((option) => {
        const optionIndex = selectedWOPermissions.indexOf(option);
        if (optionIndex > -1) {
          selectedWOPermissions.splice(optionIndex, 1);
        }
        return true;
      });
      if (!selectedWOPermissions.includes(selectedWOListRadio)) {
        selectedWOPermissions.push(selectedWOListRadio);
      }
    }
    if (selectedWOEditRadio.length > 0) {
      const availableOptions = ['edit', 'partial_edit'];
      availableOptions.map((option) => {
        const optionIndex = selectedWOPermissions.indexOf(option);
        if (optionIndex > -1) {
          selectedWOPermissions.splice(optionIndex, 1);
        }
        return true;
      });
      if (!selectedWOPermissions.includes(selectedWOEditRadio)) {
        selectedWOPermissions.push(selectedWOEditRadio);
      }
    }

    let payload = {
      ...formData,
      permissions: {
        ...permissions,
        workOrders: selectedWOPermissions,
      },
      notifications,

      portfolioBuildings,
      selectedPortfolios: portfolioBuildings ? selectedPortfolios : []
    };
    try {
      const res = editView
        ? await creService.updateSubUser(payload)
        : await creService.createStaff(payload);
      if (res.data.status) {
        formRef.current.reset();
        toast.success(res.data.message);
        history.push('/settings/sub-users');
      } else {
        if (res.data.errors) {
          res.data.errors.map((_validationError) =>
            toast.error(_validationError)
          );
        } else {
          toast.error(res.data.message);
        }
      }
      setIsCreating(false);
    } catch {
      toast.error('There is some error while saving');
      setIsCreating(false);
    }
  };

  const getUserBuildings = async () => {
  
    const res = await creService.getUserBuildingList();
    if (res.data.status) {
      setBuildings(res.data.data);
      let bins = [];
      for (const property of res.data.data) {
        bins.push(property.bin);
      }
      setFormData({
        ...formData,
        bins,
      });
    } else {
      toast.error(res.data.message);
    }
   
  };

  const getServicePermissions = async () => {
  
    const res = await creService.getServicePermissions();
    if (res.data.status) {
      setAvailableServicePermissions(res.data.data);
    } else {
      toast.error(res.data.message);
    }
   
  };

  const handleChangeAllBuildings = (checked) => {
    if (checked) {
      let bins = [];
      for (const property of buildings) {
        bins.push(property.bin);
      }
      setFormData({
        ...formData,
        bins,
      });
    } else {
      setFormData({
        ...formData,
        bins: [],
      });
    }
  };

  const fetchPortfolioData = async () => {
    try {
      const { data } = await creService.getPortfolioData();
      setPortfolios(data.data);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const handleBuildingCheck = (checked, bin) => {
    let selectedBins = [...formData.bins];
    if (checked) {
      if (!selectedBins.includes(bin)) {
        selectedBins.push(bin);
      }
    } else {
      const index = selectedBins.indexOf(bin);
      if (index > -1) {
        selectedBins.splice(index, 1);
      }
    }
    setFormData({
      ...formData,
      bins: selectedBins,
    });
  };

  const handlePermissionsCheck = (checked, permissionKey, action) => {
    console.log(selectedPermissions[action], action, selectedPermissions)
    let selected =
      editView && permissions[action]
        ? [...permissions[action]]
        : [...(selectedPermissions[action] || [])];
    if (checked) {
      if (!selected.includes(permissionKey)) {
        selected.push(permissionKey);
      }
    } else {
      const index = selected.indexOf(permissionKey);
      if (index > -1) {
        selected.splice(index, 1);
      }
    }
    setPermissions({
      ...permissions,
      [action]: selected,
    });
    setSelectedPermissions({
      ...selectedPermissions,
      [action]: selected,
    });
  };

  const handleNoticationsCheck = (checked, type) => {
    // let selectedNotifications = editView ? formData.notifications :
    setNotifications({
      ...notifications,
      [type]: checked,
    });
  };

  const handleWorkOrderListShow = (checked) => {
    setShowWorkOrderList(checked);
    if (checked && selectedWOListRadio === '') {
      setSelectedWOListRadio('list');
    }
    if (!checked) {
      setSelectedWOListRadio('');
      if (editView) {
        const prevPermissions = [...(permissions['workOrders'] || [])];
        const availableOptions = ['list', 'listSub', 'listBin'];
        availableOptions.map((option) => {
          const optionIndex = prevPermissions.indexOf(option);
          if (optionIndex > -1) {
            prevPermissions.splice(optionIndex, 1);
          }
          return true;
        });
        setPermissions({
          ...permissions,
          workOrders: prevPermissions,
        });
      }
    }
  };

  const handleWorkOrderEditShow = (checked) => {
    setShowWorkOrderEdit(checked);
    if (checked && selectedWOEditRadio === '') {
      setSelectedWOEditRadio('edit');
    }
    if (!checked) {
      setSelectedWOEditRadio('');
      if (editView) {
        const prevPermissions = [...(permissions['workOrders'] || [])];
        const availableOptions = ['edit', 'partial_edit'];
        availableOptions.map((option) => {
          const optionIndex = prevPermissions.indexOf(option);
          if (optionIndex > -1) {
            prevPermissions.splice(optionIndex, 1);
          }
          return true;
        });
        setPermissions({
          ...permissions,
          workOrders: prevPermissions,
        });
      }
    }
  };
  const handlePortfolioToggle = (checked, _id) => {
    if (checked) {
      setSelectedPortfolios([...selectedPortfolios, _id])
    } else {
      setSelectedPortfolios(selectedPortfolios.filter(id => _id !== id))
    }

  }

  const getUserData = async (id) => {
   
    const res = await creService.editSubUser(id);
    if (res.data.status) {

      setFormData(res.data.data);
      setNotifications(res.data.data.notifications);
      setPortfolioBuildings(res.data.data.portfolioBuildings)
      setSelectedPortfolios(res.data.data.selectedPortfolios || [])


      let resPermissions = res.data.data.permissions || {};
      setPermissions(resPermissions);

      if (resPermissions.workOrders) {
        if (resPermissions.workOrders.includes('list')) {
          setSelectedWOListRadio('list');
          setShowWorkOrderList(true);
        } else if (resPermissions.workOrders.includes('listSub')) {
          setSelectedWOListRadio('listSub');
          setShowWorkOrderList(true);
        } else if (resPermissions.workOrders.includes('listBin')) {
          setSelectedWOListRadio('listBin');
          setShowWorkOrderList(true);
        } else {
          setSelectedWOEditRadio('');
          setShowWorkOrderList(false);
        }
      }
      if (resPermissions.workOrders) {
        if (resPermissions.workOrders.includes('edit')) {
          setSelectedWOEditRadio('edit');
          setShowWorkOrderEdit(true);
        } else if (resPermissions.workOrders.includes('partial_edit')) {
          setSelectedWOEditRadio('partial_edit');
          setShowWorkOrderEdit(true);
        } else {
          setSelectedWOEditRadio('');
          setShowWorkOrderEdit(false);
        }
      }
    } else {
      toast.error(res.data.message);
    }
    
  };

  const resendInvitation = async () => {
    setIsLoadingInvite(true);
    try {
      const res = await creService.sendPasswordReset(subUserId);
      if (res.data.status) {
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
    } catch (err) {
      toast.error(err.message);
    }
    setIsLoadingInvite(false);
  };


 
  const dataIntitliaze = async() => {
    setIsLoading(true);
    await getUserBuildings();
    await getServicePermissions();
    await fetchPortfolioData()
    if (editView) {
      await getUserData(subUserId);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    dataIntitliaze()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <div className="row violation-sec">
        <div className="col-xl-9 my-3">
          <div className="card">
            <div className="card-header d-flex justify-content-between">
              <div className='d-flex align-items-center'>
                <Link to="/settings/sub-users">
                  <button
                    className="btn btn-light btn-lg align-self-center back-btn mx-2"
                    type="button"
                  >
                    <i className="fa fa-angle-left"></i>
                  </button>
                </Link>
                <h5>{editView ? 'Edit' : 'Add'} Staff</h5>
              </div>
              {editView && <button
                onClick={resendInvitation}
                className="btn btn-primary text-white"
                disabled={isLoadingInvite}
              >
                Invite Resend
              </button>
              }
            </div>
            {isLoading ? (
              <div className="loadercenter">
                {' '}
                <ReactLoading type={'cylon'} color={'grey'} />{' '}
              </div>
            ) : (
              <div className="card-body">
                <form ref={formRef} onSubmit={(e) => handleOnSubmit(e)}>
                  <div className="row">
                    <div className="form-group col-4">
                      <label>
                        Full Name <sup className="text-danger">*</sup>
                      </label>
                      <input
                        type="text"
                        name="fullName"
                        className="form-control"
                        onChange={(e) => handleInput(e)}
                        required
                        defaultValue={formData.fullName}
                      ></input>
                    </div>
                    <div className="form-group col-4">
                      <label>
                        Email <sup className="text-danger">*</sup>
                      </label>
                      <input
                        type="text"
                        name="email"
                        className="form-control"
                        onChange={(e) => handleInput(e)}
                        required
                        defaultValue={formData.email}
                      ></input>
                    </div>
                    <div className="form-group col-4">
                      <label>
                        Phone <sup className="text-danger">*</sup>
                      </label>
                      <input
                        type="text"
                        name="phone"
                        className="form-control"
                        onChange={(e) => handleInput(e)}
                        required
                        defaultValue={formData.phone}
                      ></input>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-6">
                      <label>
                        Title <sup className="text-danger">*</sup>
                      </label>
                      <select
                        className="form-control"
                        name="title"
                        required
                        value={formData.title}
                        onChange={(e) => handleInput(e)}
                      >
                        <option value="">-- Select --</option>
                        <option value="Super">Super</option>
                        <option value="Manager">Manager</option>
                        <option value="AssistantManager">
                          Assistant Manager
                        </option>
                      </select>
                    </div>
                    <div className="form-group col-6">
                      <label>
                        Status <sup className="text-danger">*</sup>
                      </label>
                      <select
                        className="form-control"
                        name="status"
                        required
                        onChange={(e) => handleInput(e)}
                        value={formData.status}
                      >
                        <option value="">-- Select --</option>
                        <option value="Active">Active</option>
                        <option value="Disabled">Disabled</option>
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-12">
                      <label>Notes </label>
                      <textarea
                        type="text"
                        name="notes"
                        className="form-control"
                        onChange={(e) => handleInput(e)}
                        defaultValue={formData.notes}
                      ></textarea>
                    </div>
                  </div>
                  <div className="row">
                    {availableServicePermissions.filter(permissionGroup => guard({ serviceName: permissionGroup.service })).map((permissionGroup) => {
                      return permissionGroup.service !== 'violations' ? (
                        <div className="form-group col-6" key={permissionGroup.label}>
                          <div className="card mb-1">
                            <div className="card-header p-2">
                              <strong>{permissionGroup.label}</strong>
                            </div>
                            <div className="card-body p-2">
                              {permissionGroup.permissions.map((permission) => {
                                return (
                                  <div className="form-check" key={permission.key}>
                                    <input
                                      className="form-check-input mt-1"
                                      type="checkbox"
                                      value={permission.key}
                                      id={`checkbox_${permission.key}`}
                                      onChange={(e) =>
                                        handlePermissionsCheck(
                                          e.target.checked,
                                          permission.key,
                                          permissionGroup.service
                                        )
                                      }
                                      checked={permissions[
                                        permissionGroup.service
                                      ]?.includes(permission.key)}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor={`checkbox_${permission.key}`}
                                    >
                                      {permission.description}
                                    </label>
                                  </div>
                                );
                              })}
                              {
                                permissionGroup.service === 'workOrders' &&
                                permissionGroup.subService === 'workOrders' && (
                                  <>
                                    <div className="form-check">
                                      <label
                                        className="form-check-label"
                                        htmlFor="showWorkOrderListMenu"
                                      >
                                        <input
                                          type="checkbox"
                                          className="form-check-input mt-1"
                                          id="showWorkOrderListMenu"
                                          onChange={(e) =>
                                            handleWorkOrderListShow(
                                              e.target.checked
                                            )
                                          }
                                          checked={showWorkOrderList}
                                        />
                                        List Work Orders
                                      </label>
                                    </div>
                                    {showWorkOrderList && (
                                      <div className="card m-1">
                                        <div className="card-body p-1">
                                          <div className="form-check">
                                            <label
                                              className="form-check-label"
                                              htmlFor="list"
                                            >
                                              <input
                                                className="form-check-input workOrderListMenu mt-1"
                                                type="radio"
                                                id="list"
                                                checked={
                                                  selectedWOListRadio === 'list'
                                                }
                                                name="workOrder_list"
                                                onChange={() =>
                                                  setSelectedWOListRadio('list')
                                                }
                                              />
                                              List All Work Orders
                                            </label>
                                          </div>
                                          <div className="form-check">
                                            <label
                                              className="form-check-label"
                                              htmlFor="listSub"
                                            >
                                              <input
                                                className="form-check-input mt-1"
                                                type="radio"
                                                id="listSub"
                                                checked={
                                                  selectedWOListRadio ===
                                                  'listSub'
                                                }
                                                name="workOrder_list"
                                                onChange={() =>
                                                  setSelectedWOListRadio(
                                                    'listSub'
                                                  )
                                                }
                                              />
                                              List only assigned to sub-user
                                            </label>
                                          </div>
                                          <div className="form-check">
                                            <label
                                              className="form-check-label"
                                              htmlFor="listBin"
                                            >
                                              <input
                                                className="form-check-input mt-1"
                                                type="radio"
                                                id="listBin"
                                                checked={
                                                  selectedWOListRadio ===
                                                  'listBin'
                                                }
                                                name="workOrder_list"
                                                onChange={() =>
                                                  setSelectedWOListRadio(
                                                    'listBin'
                                                  )
                                                }
                                              />
                                              List only assigned to sub-user
                                              building
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                    <div className="form-check">
                                      <label
                                        className="form-check-label"
                                        htmlFor="showWorkOrderEditMenu"
                                      >
                                        <input
                                          type="checkbox"
                                          className="form-check-input mt-1"
                                          id="showWorkOrderEditMenu"
                                          onChange={(e) =>
                                            handleWorkOrderEditShow(
                                              e.target.checked
                                            )
                                          }
                                          checked={showWorkOrderEdit}
                                        />
                                        Edit Work Order
                                      </label>
                                    </div>
                                    {showWorkOrderEdit && (
                                      <div className="card m-1">
                                        <div className="card-body p-1">
                                          <div className="form-check">
                                            <label
                                              className="form-check-label"
                                              htmlFor="edit"
                                            >
                                              <input
                                                className="form-check-input mt-1"
                                                type="radio"
                                                id="edit"
                                                name="workOrder_edit"
                                                checked={
                                                  selectedWOEditRadio === 'edit'
                                                }
                                                onChange={() =>
                                                  setSelectedWOEditRadio('edit')
                                                }
                                              />
                                              Edit All
                                            </label>
                                          </div>
                                          <div className="form-check">
                                            <label
                                              className="form-check-label"
                                              htmlFor="partial_edit"
                                            >
                                              <input
                                                className="form-check-input mt-1"
                                                type="radio"
                                                id="partial_edit"
                                                name="workOrder_edit"
                                                checked={
                                                  selectedWOEditRadio ===
                                                  'partial_edit'
                                                }
                                                onChange={() =>
                                                  setSelectedWOEditRadio(
                                                    'partial_edit'
                                                  )
                                                }
                                              />
                                              Partial Edit
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </>
                                )}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="form-group col-6" key={permissionGroup.label}>
                          <div className="card mb-1">
                            <div className="card-header p-2">
                              <strong>{permissionGroup.label}</strong>
                            </div>
                            <div className="card-body p-2">
                              {permissionGroup.permissions.map((permission) => {
                                return (
                                  <div className="form-check" key={permission.key}>
                                    <input
                                      className="form-check-input mt-1"
                                      type="checkbox"
                                      value={permission.key}
                                      id={`checkbox_${permission.key}`}
                                      onChange={(e) =>
                                        handlePermissionsCheck(
                                          e.target.checked,
                                          permission.key,
                                          permissionGroup.service
                                        )
                                      }
                                      checked={permissions[
                                        permissionGroup.service
                                      ]?.includes(permission.key)}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor={`checkbox_${permission.key}`}
                                    >
                                      {permission.description}
                                    </label>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                          <div className="card">
                            <div className="card-header p-2">
                              <strong>Notifications</strong>
                            </div>
                            <div className="card-body p-2">
                              <div className="form-check">
                                <input
                                  type="checkbox"
                                  className="form-check-input mt-1"
                                  checked={
                                    notifications && notifications.violations
                                      ? 'checked'
                                      : ''
                                  }
                                  onChange={(e) =>
                                    handleNoticationsCheck(
                                      e.target.checked,
                                      'violations'
                                    )
                                  }
                                />
                                <label className="form-check-label">
                                  Violations
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    <></>
                    <div className="form-group col-12 ">
                      <Switch
                        checked={portfolioBuildings}
                        checkedChildren="Portfolio Select"
                        unCheckedChildren="Buildings Select"
                        onChange={() => setPortfolioBuildings(!portfolioBuildings)}
                      />
                    </div>
                    {
                      portfolioBuildings ?
                        <div className="form-group col-6" >
                          <div className="card mb-1">
                            <div className="card-header p-2">
                              <strong>Building Portfolios</strong>
                            </div>
                            <div className="card-body p-2">
                              {portfolios.map((portfolio) => {
                                return (
                                  <div className="form-check" key={portfolio._id}>
                                    <input
                                      className="form-check-input mt-1"
                                      type="checkbox"
                                      value={portfolio._id}
                                      id={`checkbox_${portfolio.portfolioName}`}
                                      onChange={(e) =>
                                        handlePortfolioToggle(
                                          e.target.checked,
                                          portfolio._id
                                        )
                                      }
                                      checked={selectedPortfolios.includes(portfolio._id)}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor={`checkbox_${portfolio.portfolioName}`}
                                    >
                                      {portfolio.portfolioName}
                                    </label>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                        :
                        <div className="form-group col-12">
                          <table className="table">
                            <thead>
                              <tr>
                                <th scope="col">
                                  <input
                                    type="checkbox"
                                    checked={
                                      formData.bins?.length === buildings.length
                                        ? 'checked'
                                        : ''
                                    }
                                    onChange={(e) =>
                                      handleChangeAllBuildings(e.target.checked)
                                    }
                                  />
                                </th>
                                <th scope="col">Address</th>
                                <th scope="col">BIN</th>
                              </tr>
                            </thead>
                            <tbody>
                              {buildings.map((building, key) => {
                                return (
                                  <tr key={key} className="pointer">
                                    <td>
                                      <input
                                        type="checkbox"
                                        id={`bin${building.bin}`}
                                        className="building"
                                        checked={
                                          formData.bins?.includes(building.bin)
                                            ? 'checked'
                                            : ''
                                        }
                                        onChange={(e) =>
                                          handleBuildingCheck(
                                            e.target.checked,
                                            building.bin
                                          )
                                        }
                                      />
                                    </td>
                                    <td>
                                      {building.display_address
                                        ? building.display_address
                                        : building.address}
                                    </td>
                                    <td>{building.bin}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                    }


                    <div className="form-group col-12 mb-0">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={isCreating}
                      >
                        {editView ? 'Update' : 'Add'}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>

        <div className="col-xl-3 my-3">
          {subUserId && <HistoryUpdates userId={subUserId} />}
        </div>
      </div>
    </Fragment>
  );
};

export default StaffCreate;
