import React, { useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import { Zoom } from 'react-toastify';

import PreferenceDetails from './preferenceDetails';
import creService from '../../../services/creService';

const AlertPreference = ({ jestData = false }) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(jestData ? jestData : '');
    const [addresses, setAddresses] = useState([]);
    const toastId = useRef(null);
    const handleChange = (category, group, day, checked) => {
        const selectedGroup = data[group];
        const selectedCategory = selectedGroup.find(key => key.displayName === category);
        const index = selectedGroup.indexOf(selectedCategory);
        selectedCategory.alertDays[day] = !checked;
        data[group][index] = selectedCategory;
        setData({ ...data });
    }
    const [selectedBins, setSelectedBins] = useState([]);


    const excludeSelected = () => {
        setSelectedBins((selectedBins) => {
            for (let category in data) {
                for (let sub in data[category]) {
                    data[category][sub].excludedBins = selectedBins
                }
            }

            setData({ ...data });
            showSelected([])
            savePreference()
            return []
        })

    };

    const showSelected = (selectedBins) => {

        if (selectedBins.length !== 0) {
            const content = (
                <div className='m-3 text-center'>
                    <div> exclude ({selectedBins.length} properties excluded)</div>
                    <button
                        className="btn mt-2 btn-primary"
                        onClick={excludeSelected}
                    >
                        Apply To All Categories
                    </button>
                </div>
            );
            if (toastId.current) {
                toast.update(toastId.current, {
                    render: content,
                });
            } else {
                toastId.current = toast(content, {
                    position: 'bottom-left',
                    autoClose: false,
                    closeButton: false,
                    hideProgressBar: false,
                    closeOnClick: false,
                    draggable: false,
                    theme: 'light',
                    transition: Zoom,
                    // className: 'table-viol-checked',
                });
            }
        } else {
            toast.dismiss(toastId.current);
            toastId.current = null;
        }


    };

    const handleBuildingCheck = (bin, value, group, category) => {

        let newSelected = []
        if (bin) {
            newSelected = value ? (!selectedBins.includes(bin) ? [...selectedBins, bin] : selectedBins) : selectedBins.filter(el => el !== bin)
        } else {
            if (value) {
                newSelected = addresses.map(el => el.bin)
            }
        }

        setSelectedBins(newSelected)

        showSelected(newSelected)

        const selectedGroup = data[group];

        for (let i = 0; i < selectedGroup.length; i++) {
            if (selectedGroup[i].displayName === category) {
                const updatedCategory = selectedGroup[i]
                if (!updatedCategory.excludedBins) updatedCategory.excludedBins = []
                if (value) {
                    if (bin) {
                        updatedCategory.excludedBins.push(bin)
                    } else {
                        updatedCategory.excludedBins = addresses.map(el => el.bin)
                    }
                } else {
                    if (bin) {
                        updatedCategory.excludedBins = updatedCategory.excludedBins.filter(el => el !== bin)
                    } else {
                        updatedCategory.excludedBins = []
                    }
                }
                data[group][i] = updatedCategory;
                setData({ ...data });
                break;
            }
        }
    }


    const handleSelectDeselectAll = (day, state) => {
        Object.keys(data).map(group => {
            data[group].map(preference => {
                preference.alertDays[day] = state;
                return true;
            })
            return true;
        })
        setData({ ...data });
    }




    async function fetchData() {
        try {
            const res = await creService.getAllAlertPreferences();
            if (res.data) setData(res.data);
            const buildingsList = await creService.getBuildingsListOnly('portfolio');
            if (buildingsList.data) {
                setAddresses(buildingsList.data)
            }
        } catch (error) {
            toast.error(error);
        }
    }
    useEffect(() => {
        fetchData();
        return () => {
            toast.dismiss(toastId.current);
            toastId.current = null;
        };
    }, []);

    async function savePreference() {
        setLoading(true);
        try {
            let userPreferences = [];
            Object.keys(data).map((group) => userPreferences.push(...data[group]));
            const res = await creService.updateAlertPreferences(userPreferences);
            if (res.data.status && typeof res.data.message !== 'undefined') {
                toast.success(res.data.message);
            } else {
                toast.error(res.data?.message || 'Something went wrong.');
            }
        } catch (error) {
            toast.error(error);
        }
        setLoading(false);
    }

    return (
        <React.Fragment>
            <PreferenceDetails savePreference={savePreference} data={data} handleChange={handleChange} handleSelectDeselectAll={handleSelectDeselectAll} addresses={addresses} handleBuildingCheck={handleBuildingCheck} />
            <button onClick={savePreference} disabled={loading} className="btn mb-4 btn-primary">
                {!loading ? 'Save' : 'Saving...'}
            </button>
        </React.Fragment>
    );
}

export default AlertPreference;
