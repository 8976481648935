import React, {Fragment} from "react";
import Joi from "joi-browser";
import Form from "../common/forms/form";
import auth from "../services/authService";
import {ToastContainer, toast} from "react-toastify";
import logo from "../../assets/images/bcompliant_logo.png";
import loginimg from '../../assets/images/dashboard/login.png';

class Login extends Form {
    state = {
        data: { email: "", password: ""},
        errors: {},
        loading: false
    };

    schema = {
        email: Joi.string().max(50).required().email().label("Email"),
        password: Joi.string().max(50).required().label("Password"),
    };

    doSubmit = async () => {
        try {
            this.setState({loading: 1});
            
            const result = await auth.login(this.state.data.email, this.state.data.password);
            if (result) {
                toast.success("Logged in Successfully!")
                // auth.loginWithJwt(response.headers["x-auth-token"]);

                // check if user got redirected from somewhere
                const { state } = this.props.location;

                const referrer = sessionStorage.getItem('lastPath');

                window.location = referrer ? referrer : state?.from?.pathname ? state.from.pathname : '/';
            } else {
                this.setState({loading: 0});
            }
        } catch (ex) {
            if (ex.response && ex.response.status === 400) {
                const errors = { ...this.state.errors };
                errors.formError = ex.response.data;
                this.setState({ errors });
            }
            this.setState({loading: 0});
        }
    };

    render() {
        let submitButtonLabel = !this.state.loading ? "Login" : "Logging";
        let submitButtonIcon =  !this.state.loading ? "" : "fa fa-spinner fa-spin";
        return (
            <Fragment>
                <ToastContainer />
                <div className="page-wrapper">
                    <div className="container-fluid">
                      <div className="row login-header">
                        <div className="col-md-6">
                          <div className="pt-2 mobile-center">
                            <img src={logo}  className="image-logo" alt="" />
                          </div>
                        </div>
                        <div className="col-md-6 text-right mobile-center">
                          <p>Don't have an account yet ? <a href="/enroll">Enroll</a></p>
                        </div>
                      </div>
                    </div>
                    <div className="auth-bg">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 mt-5 mobile-hide">
                                  <img className="w-100 mt-5" src={loginimg} alt="" />
                                </div>

                                <div className="col-md-6 mt-5 pb-3">
                                  <div className="card">
                                      <div className="card-body">
                                          <div className="text-left">
                                              <h4>Sign In</h4>
                                              <h6 className="mb-5">This signup can only be used by internal.</h6>
                                          </div>
                                          <form className="theme-form" onSubmit={this.handleSubmit}>
                                              {this.renderFormError(this.state.errors.formError)}
                                              <div className="form-group">
                                                  {this.renderInput("email", "Email")}
                                              </div>
                                              <div className="form-group">
                                                  {this.renderInput("password", "Password", "password")}
                                              </div>

                                              <div className="form-group form-row mt-3 mb-3">
                                                  {this.renderButton(submitButtonLabel, ()=>{}, '', submitButtonIcon)}
                                              </div>

                                              <div className="checkbox p-0 text-center mb-5">
                                                  <a className="btn-link" href="/forgot-password">Forgot password? Recover now</a>
                                              </div>

                                              <div className="bottom-reg text-center">
                                                  <p>Don't have an account yet?</p>
                                                  <a className="btn-link" href="/enroll">Register Now</a>
                                              </div>
                                          </form>
                                      </div>
                                  </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </Fragment>

        );
    }
}

export default Login;
