import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import ReactLoading from "react-loading";
import { Doughnut } from "react-chartjs-2";

import creService from "../../services/creService";
import Helper from "../../services/helperService";

const colors = [
  "#ff4545",
  "#eb6c23",
  "#ffc533",
  "#65c92a",
  "#175dad",
  "#872ade",
  "#cc6299",
  "#613c22",
  "#000000"
]
const OutstandingFines = (props) => {

  const type = 'Outstanding Fines';
  // eslint-disable-next-line
  const [doneLoading, setLoadingState] = useState(false);
  const [data, setData] = useState(props.jestData !== undefined ? props.jestData.data : {});

  async function fetchData(type) {
    try {
      setLoadingState(false)
      let resData = await creService.getDashboardData(type);
      setLoadingState(true)
      setData(resData.data);
    } catch (e) {
      toast.error(e.message)
    }
  }

  useEffect(() => {
    fetchData('Outstanding Fines');
  }, []);

  function dashboardDetails(sub) {
    props.history.push(`/dashboard/${type}/${sub}`)
  }

  function block(sub) {
    return data[sub] === '' ? <div align="center"><ReactLoading type={"cylon"} color={"grey"} /></div>
      :
      (data[sub] > 0) ? <div className="pointer" onClick={() => dashboardDetails(sub)}> {Helper.formatter.format(data[sub])}</div>
        : data[sub]
  }

  return (
    <div className="d-flex justify-content-around flex-wrap">
      <div className="outstanding mb-5 d-flex w-100  justify-content-between">
        <div >
          <h3>Outstanding Fines</h3>
          <h4>{block('totalAll')}</h4>
          <h5>Total</h5>
          <ul className="d-flex flex-wrap">
            <li className="mr-2">
              <h6>Imposed</h6>
              <h5>{block('Imposed')}</h5>
            </li>
            <li className="m-0">
              <h6>Default</h6>
              <h5>{block('Defaulted')}</h5>
            </li>
            {/* <li>
            <h6>Overpaid</h6>
            <h5>{block('Overpaid')}</h5>
          </li> */}

          </ul>
        </div>
        {data?.savedAmountInfo?.all ?
          (<div className="saved-amount w-50 pointer" onClick={() => props.history.push(`/dashboard/Saved Amount/ECB`)}>
            <h3 className="text-center">Saved Amount</h3>
            <Doughnut
              data={{
                labels: Object.keys(data.savedAmountInfo.buildings),
                datasets: [{
                  data: Object.values(data.savedAmountInfo.buildings),
                  backgroundColor: colors,
                  hoverBackgroundColor: colors
                }]
              }}
              options={{
                responsive: true,
                maintainAspectRatio: true,
                legend: {
                  display: false,

                },
                tooltips: {
                  callbacks: {
                    title: (tooltipItem, data) => data.labels[tooltipItem[0].index],
                    label: (tooltipItem, data) => ` ${`${Helper.formatter.format(parseFloat(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]))}`}`
                  },

                }
              }}
            />
          </div>) : null
        }
      </div>

    </div>
  );
};

export default OutstandingFines;
