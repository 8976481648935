import React from 'react';
import { Select } from 'antd';


const ChartsColumnDropDown = ({ columns, handleSetColumnPivotMasterProperty, pivotSecondKey }) => {


    const displayCharts = columns.filter(
        (column) =>
            column.key !== 'action' &&
            !column.hide &&
            column.data?.bcType !== 'files' &&
            column.data?.bcType !== 'uploads' &&
            column.data?.bcType !== 'chat' &&
            column.data?.bcType !== 'notes' &&
            column.data?.bcType !== 'links' &&
            column.key !== pivotSecondKey

    );

    return (
        <Select
            defaultValue={columns.find(el => el.isPivotMasterProperty).description}
            style={{ width: 200 }}
            onChange={(value) => handleSetColumnPivotMasterProperty(value)}
            options={displayCharts.map(el => ({ value: el.key, label: el.description }))}
        />
    );
};

export default ChartsColumnDropDown;
