import { Link } from 'react-router-dom';
import logoVisitt from '../../../../assets/images/visitt-logo-main.svg';

const moment = require('moment');
const WorkOrder = (props) => {
    return (
        <>
            {props.workOrders && props.workOrders.map((workOrder) =>

                <div className="card user-card ecb-head">
                    <div className="card-body">
                        {workOrder.source === 'visitt' && <div className='mb-3'><img src={logoVisitt} height='25px' alt='visitt' /></div>}

                        <div className="row ecb-row">
                            {workOrder.violation && (
                                <>
                                    <div className="col-4 mb-3">
                                        <h6>{workOrder.violation.uniqueData.label}</h6>
                                        <p>
                                            <Link to={`/violation-details/${workOrder.violationId}`}>
                                                {workOrder.violation.uniqueData.value}
                                            </Link>
                                        </p>
                                    </div>
                                </>
                            )}

                            <div className="col-4 mb-3">
                                <h6>Work Order Id</h6>

                                <p>{workOrder.workOrderId}</p>
                            </div>
                            <div className="col-4 mb-3">
                                <h6>Title</h6>
                                <p>{workOrder.title}</p>
                            </div>
                            <div className="col-4 mb-3">
                                <h6>Due Date</h6>
                                <p>{workOrder.dueDate && moment.utc(workOrder.dueDate).format("MM/DD/YYYY")}</p>
                            </div>
                            <div className="col-4 mb-3">
                                <h6>Category</h6>
                                <p>{workOrder.category}</p>
                            </div>
                            <div className="col-4 mb-3">
                                <h6>Building Address</h6>
                                <p>{workOrder.building && (workOrder.building.display_address ? workOrder.building.display_address : workOrder.building.address)}</p>
                            </div>
                            <div className="col-4 mb-3">
                                <h6>Status</h6>
                                <p>{workOrder.status}</p>
                            </div>
                            <div className="col-4 mb-3">
                                <h6>Priority</h6>
                                <p>{workOrder.priority}</p>
                            </div>
                        </div>
                        <div className="row ecb-row">
                            {workOrder.recurring && workOrder.recurringFrequency &&
                                <div className="col-4">
                                    <h6>Frequency</h6>
                                    <p>{workOrder.recurring} {workOrder.recurring === '1' ? 'time' : 'times'} in a {workOrder.recurringFrequency}</p>
                                </div>
                            }
                            {workOrder.vendor &&
                                <div className="col-4">
                                    <h6>Vendor</h6>
                                    <p>{workOrder.vendor?.fullName}</p>
                                </div>
                            }
                            {workOrder.assignee &&
                                <div className="col-4">
                                    <h6>Assignee</h6>
                                    <p>{workOrder.assignee?.fullName}</p>
                                </div>
                            }
                        </div>
                        <div className="row ecb-row">
                            {workOrder.aptNumber &&
                                <div className="col-4">
                                    <h6>Apt. #</h6>
                                    <p>{workOrder.aptNumber}</p>
                                </div>
                            }
                            <div className="col-8">
                                <h6>Description</h6>
                                <p style={{ whiteSpace: 'pre-line' }}>{workOrder.description}</p>
                            </div>
                        </div>
                        <Link className="btn btn-sm btn-primary" to={`/work-orders/view/${workOrder._id}`}>
                            View
                        </Link>
                    </div>
                </div>
            )}



            <div className='d-flex'>
                {props.workOrderCreateAbility?.bcompliant &&
                    <div className="m-3">
                        <Link className="btn btn-sm btn-primary" to={`/work-order/create-by-violation/${props.violationId}/${props.bin}`}>
                            <i className="fa fa-plus" /> Create BCompliant Order
                        </Link>
                    </div>
                }
                {props.isVisitIntegrationEnabled && props.workOrderCreateAbility?.visitt &&
                    <div className="m-3">
                        <Link className="btn btn-sm btn-info" to={`/work-order/create-by-violation-visitt/${props.violationId}/${props.bin}`}>
                            <i className="fa fa-plus" /> Create Visitt Order
                        </Link>
                    </div>
                }
                {props.isAppWorkIntegrationEnabled && props.workOrderCreateAbility?.appWork &&
                    <div className="m-3">
                        <Link className="btn btn-sm btn-info" to={`/work-order/create-by-violation-appWork/${props.violationId}/${props.bin}`}>
                            <i className="fa fa-plus" /> Create AppWork Order
                        </Link>
                    </div>
                }
            </div>

        </>
    )
}

export default WorkOrder;
