

import DocsViewer from './document-viewer';
import { useState } from "react";

const DocsViewerWrap = (props) => {

    const [visible, setVisible] = useState(null)

    return <>
        <div onClick={() => setVisible(true)}>{props.children}</div>
        <DocsViewer {...props} isVisible={visible} setIsVisible={setVisible} />
    </>

}

export default DocsViewerWrap;