import React, { Fragment, useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2/dist/sweetalert2.js';

import creService from '../../services/creService';

const FireSafetyCreate = () => {
  const search = useLocation().search;

  const [defaultBuilding] = useState(new URLSearchParams(search).get('bin'));

  const history = useHistory();
  const [fireSafetyId, setFireSafetyId] = useState(null);
  const [buildings, setBuildings] = useState([]);
  const [formData, setFormData] = useState({});
  const [cloneFormData, setCloneFormData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isLocationSpeakerOther, setLocationSpeakerOther] = useState(false);
  const [ownerPhones, setOwnerPhones] = useState([
    {
      id: 1,
      number: '',
    },
  ]);
  const [meansEgress, setMeansEgress] = useState([
    { id: 1, typeEgress: '', identification: '', location: '', leadsTo: '' },
  ]);

  const loadBuildings = async () => {
    const buildingRes = await creService.getBuildingsListOnly('safetyMailings');
    setBuildings(buildingRes.data);
    try {
      const binRes = await creService.getFireSafetyBinListOnly();
      setBuildings((prev) => {
        return [
          ...prev.map((item) => {
            binRes.data.data.forEach((binObj) => {
              if (binObj.bin === item.bin) {
                item.exist = true;
                item.fireSafetyId = binObj._id;
              }
            });
            return item;
          }),
        ];
      });
    } catch (e) { }
  };

  const onChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const onChangeAndAction = (field, value, id = null) => {
    if (field === 'locationSpeakersOtherCheck') {
      setLocationSpeakerOther(value);
    }
    if (field === 'bin' && id) {
      goToEditFireSafety(id);
    }
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const onChangeMeansEgress = (field, value, id, action = 'change') => {
    if (action === 'add') {
      setMeansEgress((prev) => [
        ...prev,
        {
          id: Math.max(...meansEgress.map((item) => item.id)) + 1,
          typeEgress: '',
          identification: '',
          location: '',
          leadsTo: '',
        },
      ]);
    } else if (action === 'remove') {
      if (id !== 1) {
        setMeansEgress(meansEgress.filter((item) => item.id !== id));
      }
    } else if (action === 'change') {
      setMeansEgress((prev) => {
        return prev.map((item) => {
          if (item.id === id) {
            return { ...item, [field]: value };
          }
          return item;
        });
      });
    }
  };

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      meansEgress,
    }));
  }, [meansEgress]);

  useEffect(() => {
    if (buildings.length > 0 && defaultBuilding) {
      setFormData((prev) => ({
        ...prev,
        bin: Number(defaultBuilding),
      }));
    }
  }, [buildings, defaultBuilding]);

  const onChangeOwnerPhones = (field, value, id, action = 'change') => {
    if (action === 'add') {
      setOwnerPhones((prev) => [
        ...prev,
        {
          id: Math.max(...ownerPhones.map((item) => item.id)) + 1,
          number: '',
        },
      ]);
    } else if (action === 'remove') {
      if (id !== 1) {
        setOwnerPhones(ownerPhones.filter((item) => item.id !== id));
      }
    } else if (action === 'change') {
      setOwnerPhones((prev) => {
        return prev.map((item) => {
          if (item.id === id) {
            return { ...item, [field]: value };
          }
          return item;
        });
      });
    }
  };

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      ownerPhones,
    }));
  }, [ownerPhones]);

  const phoneValidation = () => {
    // eslint-disable-next-line no-useless-escape
    const regex =
      /^(\+\d{1,2}\s?)?1?-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i;
    for (let { number } of ownerPhones) {
      if (!number) {
        return 'Owner Phone is required'
      }
      if (!regex.test(number)) {
        return 'Owner phone not valid'
      }
    }

  };


  const onSubmit = async (event) => {
    event.preventDefault();

    if (
      fireSafetyId &&
      JSON.stringify(cloneFormData) === JSON.stringify(formData)
    ) {
      toast.success('Fire Safety has been created successfully.');
      return history.push('/fire-safety/list');
    } else if (
      fireSafetyId &&
      JSON.stringify(cloneFormData) !== JSON.stringify(formData)
    ) {
      await creService.updateFireSafety(formData);
      return history.push('/fire-safety/list');
    } else {
      await submitProcess()
    }
  };
  const submitProcess = async (emulate) => {
    const phoneValidationErr = phoneValidation();
    if (phoneValidationErr) {
      toast.error(phoneValidationErr);
      return false;
    }

    setIsLoading(true);
    try {

      const payload = {
        ...formData,
        ownerPhones: formData.ownerPhones.map((phone) => ({
          id: phone.id,
          number: phone.number.replaceAll(/\D*/gi, ''),
        })),
        locationManualPullStations: formData.locationManualPullStations,
        sprinklerCoverage: formData.sprinklerCoverage
      }
      if (payload.sprinklerSystem === 'no') {
        delete payload['dwellingCheck'];
        delete payload['dwelling'];
        delete payload['compactor'];
        delete payload['compactorCheck'];
        delete payload['hallways'];
        delete payload['hallwaysCheck'];
        delete payload['stairwell'];
        delete payload['stairwellCheck'];
        delete payload['other'];
        delete payload['otherCheck'];
      }
      if (payload.paSystem === 'no') {
        delete payload['locationSpeakersDwellingCheck'];
        delete payload['locationSpeakersHallwayCheck'];
        delete payload['locationSpeakersOtherCheck'];
        delete payload['locationSpeakersOtherText'];
        delete payload['locationSpeakersStairwellCheck'];
      }


      const { data } = await creService.createFireSafety(payload);
      if (data.status) {
        if (emulate) {
          return data.id
        } else {
          toast.success(data.message);
          if (defaultBuilding) {
            history.push('/safety-mailings/create');
          } else {
            history.push('/fire-safety/list');
          }
        }

      } else {
        toast.error(data.message);
      }
    } catch (_err) {
      toast.error(_err.message);
    } finally {
      setIsLoading(false);
    }
  }

  const emulateSubmit = async () => {
    return await submitProcess(true)
  };

  useEffect(() => {
    loadBuildings();
  }, []);

  const eventGeneratePreview = async () => {
    if (
      fireSafetyId &&
      JSON.stringify(cloneFormData) === JSON.stringify(formData)
    ) {
      const formPreview = await creService.previewFireSafety(fireSafetyId);
      previewModal(formPreview.data);
    } else if (
      fireSafetyId &&
      JSON.stringify(cloneFormData) !== JSON.stringify(formData)
    ) {
      try {
        await creService.updateFireSafety(formData);
        const formPreview = await creService.previewFireSafety(fireSafetyId);
        setCloneFormData(formData);
        previewModal(formPreview.data);
      } catch (error) {
        toast.error(error.message);
      }
    } else {
      const id = await emulateSubmit();
      setFireSafetyId(id);
      if (id) {
        try {
          const formPreview = await creService.previewFireSafety(id);
          setCloneFormData(formData);
          previewModal(formPreview.data);
        } catch (error) {
          toast.error(error.message);
        }
      } else {
        toast.error('Failed to generate id');
      }
    }
  };
  const previewModal = (html) => {
    Swal.fire({
      showCloseButton: true,
      showCancelButton: false,
      showConfirmButton: false,
      grow: 'fullscreen',
      html: html,
    });
  };

  const goToEditFireSafety = (id) => {
    history.push(`/fire-safety/edit/${id}`);
  };

  return (
    <Fragment>
      <div className="row violation-sec">
        <div className="col-xl-12 my-3">
          <div className="card">
            <div className="card-header">
              <Link to="/fire-safety/list">
                <button
                  className="btn btn-light btn-lg align-self-center back-btn mx-2"
                  type="button"
                >
                  <i className="fa fa-angle-left" />
                </button>
              </Link>
              <h5>Create New Fire Safety</h5>
            </div>
            <div className="card-body">
              <form method="post" onSubmit={onSubmit}>
                <div className="row">
                  <div className="title__block">Building Information</div>
                  <div className="form__group paddings flex align-items-center">
                    <div className="flex uniq-flex-basis align-items-center paddings">
                      <span>Building<span style={{ color: 'red' }}>*</span> </span>
                    </div>
                    <div className="flex width-100 paddings">
                      <select
                        value={formData.bin}
                        className="form-control"
                        name="bin"
                        onChange={(e) =>
                          onChangeAndAction(
                            e.target.name,
                            e.target.value,
                            e.target[e.target.selectedIndex].getAttribute(
                              'data-id'
                            )
                          )
                        }
                      >
                        <option value="">-- Select Building --</option>
                        {buildings &&
                          buildings.map((building) => (
                            <Fragment key={building.bin}>
                              {building.exist ? (
                                <option
                                  data-id={building.fireSafetyId}
                                  style={{
                                    backgroundColor: 'rgb(218 218 218)',
                                  }}
                                  key={`building_${building.bin}`}
                                  value={building.bin}
                                >
                                  {building.display_address
                                    ? building.display_address
                                    : building.address}
                                </option>
                              ) : (
                                <option
                                  key={`building_${building.bin}`}
                                  value={building.bin}
                                >
                                  {building.display_address
                                    ? building.display_address
                                    : building.address}
                                </option>
                              )}
                            </Fragment>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="form__block width-100">
                    <div className="two__columns">
                      <div className="form__column paddings">
                        <div className="flex form__group align-items-center">
                          <div className="form__label">
                            <span>Entity Name<span style={{ color: 'red' }}>*</span> </span>
                          </div>
                          <div className="flex width-100">
                            <input
                              type="text"
                              className="form-control"
                              name="entityName"
                              value={formData.entityName}
                              onChange={(e) =>
                                onChange(e.target.name, e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="flex form__group align-items-center">
                          <div className="form__label">
                            <span> Building Owner
                              <span style={{ color: 'red' }}>*</span> </span>
                          </div>
                          <div className="flex width-100">
                            <input
                              type="text"
                              className="form-control"
                              name="buildingOwner"
                              onChange={(e) =>
                                onChange(e.target.name, e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="flex form__group align-items-center">
                          <div className="form__label">
                            <span> Owner Address 1
                              <span style={{ color: 'red' }}>*</span> </span>
                          </div>
                          <div className="flex width-100">
                            <input
                              type="text"
                              className="form-control"
                              name="ownerAddress_1"
                              onChange={(e) =>
                                onChange(e.target.name, e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="flex form__group align-items-center">
                          <div className="form__label">
                            <span> Owner Address 2
                              <span style={{ color: 'red' }}>*</span> </span>
                          </div>
                          <div className="flex width-100">
                            <input
                              type="text"
                              className="form-control"
                              name="ownerAddress_2"
                              onChange={(e) =>
                                onChange(e.target.name, e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="flex form__group align-items-center">
                          <div className="form__label">Owner Address 3 </div>
                          <div className="flex width-100">
                            <input
                              type="text"
                              className="form-control"
                              name="ownerAddress_3"
                              onChange={(e) =>
                                onChange(e.target.name, e.target.value)
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form__column paddings">
                        {ownerPhones.map(({ id, number }) => {
                          return (
                            <div
                              key={id}
                              className="flex form__group align-items-center"
                            >
                              <div className="form__label">
                                <span> Owner Phone {id}
                                  <span style={{ color: 'red' }}>*</span> </span>
                              </div>
                              <div className="flex paddings width-100">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="number"
                                  value={number}
                                  onChange={(e) =>
                                    onChangeOwnerPhones(
                                      e.target.name,
                                      e.target.value,
                                      id
                                    )
                                  }
                                />
                                <div className="form__actions">
                                  <div
                                    className="form__btn btn-primary"
                                    onClick={() =>
                                      onChangeOwnerPhones(
                                        null,
                                        null,
                                        null,
                                        'add'
                                      )
                                    }
                                  >
                                    +
                                  </div>
                                  {id !== 1 ? (
                                    <div
                                      className="form__btn btn-danger"
                                      onClick={() =>
                                        onChangeOwnerPhones(
                                          null,
                                          null,
                                          id,
                                          'remove'
                                        )
                                      }
                                    >
                                      -
                                    </div>
                                  ) : (
                                    <div className="form__btn form__btn_empty">
                                      -
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                        <div className="flex form__group align-items-center">
                          <div className="form__label">
                            Additional Owner / Rep Info
                          </div>
                          <div className="flex paddings width-100">
                            <input
                              type="text"
                              className="form-control"
                              name="additionalOwner"
                              onChange={(e) =>
                                onChange(e.target.name, e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="flex form__group align-items-center">
                          <div className="form__label">
                            <span>Year of Construction<span style={{ color: 'red' }}>*</span></span>

                          </div>
                          <div className="flex paddings width-100">
                            <input
                              type="text"
                              className="form-control"
                              name="yearConstruction"
                              onChange={(e) =>
                                onChange(e.target.name, e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="flex form__group align-items-center">
                          <div className="form__label">
                            <span>Type of Construction
                              <span style={{ color: 'red' }}>*</span> </span>
                          </div>
                          <div className="flex paddings width-100">
                            <select
                              className="form-control"
                              name="typeConstruction"
                              value={formData.typeConstruction}
                              onChange={(e) =>
                                onChange(e.target.name, e.target.value)
                              }
                            >
                              <option value="">
                                -- Select Type of Construction --
                              </option>
                              <option key="Combustible" value="Combustible">
                                Combustible
                              </option>
                              <option
                                key="Non-Combustible"
                                value="Non-Combustible"
                              >
                                Non-Combustible
                              </option>
                            </select>
                          </div>
                        </div>
                        <div className="two__columns">
                          <div className="form__column">
                            <div className="flex form__group align-items-center">
                              <div className="form__label flex-basis_floors">
                                <span>   Floors Above Ground
                                  <span style={{ color: 'red' }}>*</span>
                                </span>
                              </div>
                              <div className="flex paddings width-100">
                                <input
                                  type="number"
                                  className="form-control"
                                  name="floorsAboveGround"
                                  onChange={(e) =>
                                    onChange(e.target.name, e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="form__column">
                            <div className="flex form__group align-items-center">
                              <div className="form__label flex-basis_floors">
                                <span>  Floors Below Ground
                                  <span style={{ color: 'red' }}>*</span>
                                </span>
                              </div>
                              <div className="flex paddings width-100">
                                <input
                                  type="number"
                                  className="form-control"
                                  name="floorsBelowGround"
                                  onChange={(e) =>
                                    onChange(e.target.name, e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form__block form__block_2 width-100">
                    <div className="two__columns">
                      <div className="form__column paddings">
                        <div className="flex form__group align-items-center">
                          <div className="form__label">
                            <span>  Sprinkler System
                              <span style={{ color: 'red' }}>*</span>
                            </span>
                          </div>
                          <div className="flex width-100">
                            <select
                              className="form-control"
                              name="sprinklerSystem"
                              value={formData.sprinklerSystem}
                              onChange={(e) =>
                                onChange(e.target.name, e.target.value)
                              }
                            >
                              <option value="">
                                -- Select Sprinkler System --
                              </option>
                              <option key="no" value="no">
                                no
                              </option>
                              <option key="yes" value="yes">
                                yes
                              </option>
                            </select>
                          </div>
                        </div>
                        {formData.sprinklerSystem !== 'no' && (
                          <div className="flex form__group align-items-center">
                            <div className="form__label">
                              <span> Sprinkler Coverage

                              </span>
                            </div>
                            <div className="flex width-100">
                              <select
                                className="form-control"
                                name="sprinklerCoverage"
                                value={formData.sprinklerCoverage}
                                onChange={(e) =>
                                  onChange(e.target.name, e.target.value)
                                }
                              >
                                <option value="">
                                  -- Select Sprinkler Coverage --
                                </option>
                                <option key="Entire" value="Entire">
                                  Entire
                                </option>
                                <option key="Partial" value="Partial">
                                  Partial
                                </option>
                              </select>
                            </div>
                          </div>
                        )}
                        <div className="flex form__group align-items-center empty__form">
                          <div className="form__label">Empty Object</div>
                          <div className="flex width-100">
                            <select className="form-control">
                              <option value="">Empty Object</option>
                            </select>
                          </div>
                        </div>
                        <div className="flex form__group align-items-center">
                          <div className="form__label">
                            <span>Fire Alarm<span style={{ color: 'red' }}>*</span>
                            </span>
                          </div>
                          <div className="flex width-100">
                            <select
                              className="form-control"
                              name="fireAlarm"
                              value={formData.fireAlarm}
                              onChange={(e) =>
                                onChange(e.target.name, e.target.value)
                              }
                            >
                              <option value="">-- Select Fire Alarm --</option>
                              <option key="no" value="no">
                                no
                              </option>
                              <option key="yes" value="yes">
                                yes
                              </option>
                              <option
                                key="Transmits Alarm"
                                value="Transmits Alarm"
                              >
                                Transmits Alarm
                              </option>
                            </select>
                          </div>
                        </div>
                        {formData.fireAlarm !== 'no' && (
                          <div className="flex form__group align-items-center">
                            <div className="form__label">
                              Location of Manual Pull Stations
                            </div>
                            <div className="flex width-100">
                              <input
                                type="text"
                                className="form-control"
                                name="locationManualPullStations"
                                onChange={(e) =>
                                  onChange(e.target.name, e.target.value)
                                }
                              />
                            </div>
                          </div>
                        )}
                      </div>
                      {formData.sprinklerSystem !== 'no' && (
                        <div>
                          <div className="form__column paddings">
                            <div className="flex form__group align-items-center">
                              <div className="form__label flex-basis_20">
                                <input
                                  type="checkbox"
                                  className="form-check-inline"
                                  id="dwellingCheck"
                                  name="dwellingCheck"
                                  checked={formData.dwellingCheck}
                                  onChange={(e) =>
                                    onChangeAndAction(
                                      e.target.name,
                                      e.target.checked
                                    )
                                  }
                                />
                                <label
                                  htmlFor="dwellingCheck"
                                  className="_label"
                                >
                                  <span>Dwelling
                                  </span>
                                </label>
                              </div>
                              <div className="flex width-100">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="dwelling"
                                  value={formData.dwelling}
                                  onChange={(e) =>
                                    onChange(e.target.name, e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <div className="flex form__group align-items-center">
                              <div className="form__label flex-basis_20">
                                <input
                                  type="checkbox"
                                  className="form-check-inline"
                                  id="stairwellCheck"
                                  name="stairwellCheck"
                                  checked={formData.stairwellCheck}
                                  onChange={(e) =>
                                    onChangeAndAction(
                                      e.target.name,
                                      e.target.checked
                                    )
                                  }
                                />
                                <label
                                  htmlFor="stairwellCheck"
                                  className="_label"
                                >
                                  <span>   Stairwell

                                  </span>
                                </label>
                              </div>
                              <div className="flex width-100">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="stairwell"
                                  value={formData.stairwell}
                                  onChange={(e) =>
                                    onChange(e.target.name, e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <div className="flex form__group align-items-center">
                              <div className="form__label flex-basis_20">
                                <input
                                  type="checkbox"
                                  className="form-check-inline"
                                  id="hallwaysCheck"
                                  name="hallwaysCheck"
                                  checked={formData.hallwaysCheck}
                                  onChange={(e) =>
                                    onChangeAndAction(
                                      e.target.name,
                                      e.target.checked
                                    )
                                  }
                                />
                                <label
                                  htmlFor="hallwaysCheck"
                                  className="_label"
                                >
                                  <span>  Hallways

                                  </span>
                                </label>
                              </div>
                              <div className="flex width-100">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="hallways"
                                  value={formData.hallways}
                                  onChange={(e) =>
                                    onChange(e.target.name, e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <div className="flex form__group align-items-center">
                              <div className="form__label flex-basis_20">
                                <input
                                  type="checkbox"
                                  className="form-check-inline"
                                  id="compactorCheck"
                                  name="compactorCheck"
                                  checked={formData.compactorCheck}
                                  onChange={(e) =>
                                    onChangeAndAction(
                                      e.target.name,
                                      e.target.checked
                                    )
                                  }
                                />
                                <label
                                  htmlFor="compactorCheck"
                                  className="_label"
                                >
                                  <span> Compactor

                                  </span>
                                </label>
                              </div>
                              <div className="flex width-100">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="compactor"
                                  value={formData.compactor}
                                  onChange={(e) =>
                                    onChange(e.target.name, e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <div className="flex form__group align-items-center">
                              <div className="form__label flex-basis_20">
                                <input
                                  type="checkbox"
                                  className="form-check-inline"
                                  id="otherCheck"
                                  name="otherCheck"
                                  checked={formData.otherCheck}
                                  onChange={(e) =>
                                    onChangeAndAction(
                                      e.target.name,
                                      e.target.checked
                                    )
                                  }
                                />
                                <label htmlFor="otherCheck" className="_label">
                                  <span>  Other </span>
                                </label>
                              </div>
                              <div className="flex width-100">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="other"
                                  value={formData.other}
                                  onChange={(e) =>
                                    onChange(e.target.name, e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form__block form__block_3 width-100">
                    <div className="two__columns">
                      <div className="form__column paddings">
                        <div className="flex form__group align-items-center">
                          <div className="form__label">
                            <span> Emergency Voice Communication System
                              <span style={{ color: 'red' }}>*</span> </span>
                          </div>
                          <div className="flex width-100">
                            <select
                              className="form-control"
                              name="emergencyVoice"
                              value={formData.emergencyVoice}
                              onChange={(e) =>
                                onChange(e.target.name, e.target.value)
                              }
                            >
                              <option value="">
                                -- Select Emergency Voice --
                              </option>
                              <option key="no" value="no">
                                no
                              </option>
                              <option key="yes" value="yes">
                                yes
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="form__column paddings">
                        <div className="flex form__group align-items-center">
                          <div className="form__label flex-basis_20">
                            <span>PA System<span style={{ color: 'red' }}>*</span></span>
                          </div>
                          <div className="flex width-100">
                            <select
                              className="form-control"
                              name="paSystem"
                              value={formData.paSystem}
                              onChange={(e) =>
                                onChange(e.target.name, e.target.value)
                              }
                            >
                              <option value="">-- Select PA System --</option>
                              <option key="no" value="no">
                                no
                              </option>
                              <option key="yes" value="yes">
                                yes
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    {formData.paSystem !== 'no' && (
                      <div className="one__column paddings">
                        <div className="flex form__group">
                          <div
                            className="form__label"
                            style={{ flexBasis: '15.8%' }}
                          >
                            Location of Speakers
                          </div>
                          <div
                            className="checkers"
                            style={{ flexBasis: '100%' }}
                          >
                            <div className="checkers__item">
                              <input
                                type="checkbox"
                                id="locationSpeakersStairwellCheck"
                                name="locationSpeakersStairwellCheck"
                                checked={
                                  formData.locationSpeakersStairwellCheck
                                }
                                onChange={(e) =>
                                  onChange(e.target.name, e.target.checked)
                                }
                              />
                              <label
                                htmlFor="locationSpeakersStairwellCheck"
                                className="label"
                              >
                                Stairwell
                              </label>
                            </div>
                            <div className="checkers__item">
                              <input
                                type="checkbox"
                                id="locationSpeakersHallwayCheck"
                                name="locationSpeakersHallwayCheck"
                                checked={formData.locationSpeakersHallwayCheck}
                                onChange={(e) =>
                                  onChange(e.target.name, e.target.checked)
                                }
                              />
                              <label
                                htmlFor="locationSpeakersHallwayCheck"
                                className="label"
                              >
                                Hallway
                              </label>
                            </div>
                            <div className="checkers__item">
                              <input
                                type="checkbox"
                                id="locationSpeakersDwellingCheck"
                                name="locationSpeakersDwellingCheck"
                                checked={formData.locationSpeakersDwellingCheck}
                                onChange={(e) =>
                                  onChange(e.target.name, e.target.checked)
                                }
                              />
                              <label
                                htmlFor="locationSpeakersDwellingCheck"
                                className="label"
                              >
                                Dwelling
                              </label>
                            </div>
                            <div className="checkers__item">
                              <input
                                type="checkbox"
                                id="locationSpeakersOtherCheck"
                                name="locationSpeakersOtherCheck"
                                checked={formData.locationSpeakersOtherCheck}
                                onChange={(e) =>
                                  onChangeAndAction(
                                    e.target.name,
                                    e.target.checked
                                  )
                                }
                              />
                              <label
                                htmlFor="locationSpeakersOtherCheck"
                                className="label"
                              >
                                Other
                              </label>
                            </div>
                          </div>
                          <textarea
                            disabled={!isLocationSpeakerOther}
                            style={{ marginLeft: '94' }}
                            className="flex width-100 form__textarea"
                            name="locationSpeakersOtherText"
                            value={formData.locationSpeakersOtherText}
                            onChange={(e) =>
                              onChange(e.target.name, e.target.value)
                            }
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="title__block">
                    <span> Means Of Egress<span style={{ color: 'red' }}>*</span> </span>
                  </div>
                  <div className="form__block width-100">
                    {meansEgress.map(
                      ({
                        id,
                        typeEgress,
                        identification,
                        location,
                        leadsTo,
                      }) => {
                        return (
                          <div key={id} className="two__columns">
                            <div className="form__column paddings">
                              <div className="flex form__group align-items-center">
                                <div className="form__label">Type Egress<span style={{ color: 'red' }}>*</span></div>
                                <div className="flex width-100">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="typeEgress"
                                    value={typeEgress}
                                    onChange={(e) =>
                                      onChangeMeansEgress(
                                        e.target.name,
                                        e.target.value,
                                        id
                                      )
                                    }
                                  />
                                </div>
                              </div>
                              <div className="flex form__group align-items-center">
                                <div className="form__label">Location<span style={{ color: 'red' }}>*</span></div>
                                <div className="flex width-100">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="location"
                                    value={location}
                                    onChange={(e) =>
                                      onChangeMeansEgress(
                                        e.target.name,
                                        e.target.value,
                                        id
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="form__column paddings">
                              <div className="flex form__group align-items-center">
                                <div className="form__label">
                                  Identification<span style={{ color: 'red' }}>*</span>
                                </div>
                                <div className="flex width-100">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="identification"
                                    value={identification}
                                    onChange={(e) =>
                                      onChangeMeansEgress(
                                        e.target.name,
                                        e.target.value,
                                        id
                                      )
                                    }
                                  />
                                  <div className="form__actions">
                                    <div
                                      className="form__btn btn-primary"
                                      onClick={() =>
                                        onChangeMeansEgress(
                                          null,
                                          null,
                                          null,
                                          'add'
                                        )
                                      }
                                    >
                                      +
                                    </div>
                                    {id !== 1 ? (
                                      <div
                                        className="form__btn btn-danger"
                                        onClick={() =>
                                          onChangeMeansEgress(
                                            null,
                                            null,
                                            id,
                                            'remove'
                                          )
                                        }
                                      >
                                        -
                                      </div>
                                    ) : (
                                      <div className="form__btn form__btn_empty">
                                        -
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="flex form__group align-items-center">
                                <div className="form__label">Leads To<span style={{ color: 'red' }}>*</span></div>
                                <div className="flex width-100">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="leadsTo"
                                    value={leadsTo}
                                    onChange={(e) =>
                                      onChangeMeansEgress(
                                        e.target.name,
                                        e.target.value,
                                        id
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>

                <button className="btn btn-danger mr-2" disabled={isLoading}>
                  {isLoading ? 'Creating...' : 'Submit'}
                </button>
                <div onClick={eventGeneratePreview} className="btn btn-primary">
                  Preview Form
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default FireSafetyCreate;
