import React from 'react';
import { io } from "socket.io-client";
import config from "../config.json";
import { getJwt } from '../components/services/authService';

export const socket = () => {
    if (!window.location.href.includes(config.disallowSocketConnection)) {
        return io({
            path: '/api/socket/',
            auth: {
                token: getJwt(),
            }
        });
    }
    return null;
}

export const SocketContext = React.createContext();
