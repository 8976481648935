import React, { Component, Fragment } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Switch } from 'antd';

import creApi from "../../services/creService";
import HistoryUpdates from "./partials/historyUpdates";
import SmallCalendar from "../calendar/smallCalendar";
import LocalState from "../../common/localState";
import { getCurrentUser } from "../../services/authService";
import BuildingsComponentNYC from './buildingsComponentNYC'
import BuildingsComponentPhiladelphia from './buildingsComponentPhiladelphia'

class Buildings extends Component {
    state = {
        buildingsData: this.props.jestData !== undefined ? this.props.jestData.buildingsData.data : [],
        doneLoading: this.props.jestData !== undefined ? this.props.jestData.doneLoading : false,
        viewType: LocalState.getViewTypeSetting('buildings', 'list'),
        fullBildingPage: LocalState.getViewTypeSetting('buildingsTable', ''),
        sortField: {},
        locationEnv: getCurrentUser()?.locationEnv,
    };

    getBuildingsList = async (detailResponse) => {
        try {
            let buildingsList = await creApi.getBuildingsList(detailResponse);
            this.setState({
                doneLoading: true,
                buildingsData: buildingsList.data
            });
        } catch (e) {
            toast.error(e.message)
        }
    }

    componentDidMount() {
        const promise = this.getBuildingsList();
        if (this.state.fullBildingPage === 'full') {
            promise.then(() => this.getBuildingsList(true))
        }
    }
    onAddressClick = (bin) => {
        this.props.history.push("/violations/all/" + bin);
    }

    switchToggle = async () => {
        const type = this.state.viewType === 'grid' ? 'list' : 'grid';
        await creApi.updateUserPageView({
            page: 'buildings',
            type,
        });
        this.setState({ viewType: type })
    }

    switchInfoToggle = async () => {
        const type = this.state.fullBildingPage === 'full' ? '' : 'full';
        if (type === 'full') {
            this.getBuildingsList(true);
        }
        await creApi.updateUserPageView({
            page: 'buildingsTable',
            type,
        });
        this.setState({ fullBildingPage: type })
    }

    sortByAddress = (addressOne, addressTwo, order) => {
        const addressOneHouseNumber = parseInt((addressOne.replace(/_/, '').replace(/-/, '').split(' '))[0], 10);
        const addressTwoHouseNumber = parseInt((addressTwo.replace(/_/, '').replace(/-/, '').split(' '))[0], 10);
        return order === 1 ? addressOneHouseNumber - addressTwoHouseNumber : addressTwoHouseNumber - addressOneHouseNumber;
    };

    handleSort = async (_sortField) => {
        const { buildingsData, sortField } = this.state;
        const direction = typeof sortField[_sortField] != 'undefined' && sortField[_sortField] === -1 ? 1 : -1;
        const sort = {
            [_sortField]: direction,
        }
        this.setState({ sortField: sort });
        const unSortedArr = buildingsData;
        // eslint-disable-next-line array-callback-return
        await unSortedArr.sort((a, b) => {
            if (_sortField === 'Address') {
                return this.sortByAddress(a[_sortField], b[_sortField], direction);
            } else if (typeof a[_sortField] === 'string' && typeof b[_sortField] === 'string') {
                if (direction === 1) {
                    return a[_sortField].localeCompare(b[_sortField]);
                } else if (direction === -1) {
                    return b[_sortField].localeCompare(a[_sortField]);
                }
            } else {
                if (direction === 1) {
                    return a[_sortField] - b[_sortField];
                } else if (direction === -1) {
                    return b[_sortField] - a[_sortField];
                }
            }
        });
        this.setState({ buildingsData: unSortedArr });
    }

    render() {
        const combined = this.state.buildingsData.reduce((ac, a, i) => {
            if ((i + 1) % 2) {
                ac.push([a])
            } else {
                ac[ac.length - 1].push(a)
            }
            return ac
        }, [])
        const { viewType, sortField } = this.state;
        return (
            <Fragment>
                <ToastContainer />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-9 my-building-wrapper">
                            <div className="card">
                                <div className="card-header d-flex justify-content-between">
                                    <h5>My Buildings  <Switch checkedChildren="show count" unCheckedChildren="hide count" onChange={this.switchInfoToggle} defaultChecked={this.state.fullBildingPage === 'full'} /></h5>
                                    <div id="btnContainer">
                                        <button className={`btn ${viewType === 'grid' ? 'active' : ''}`} onClick={() => this.switchToggle()}><i className="fa fa-th-large"></i></button>
                                        <button className={`btn ${viewType === 'list' ? 'active' : ''}`} onClick={() => this.switchToggle()}><i className="fa fa-bars"></i></button>
                                    </div>
                                </div>
                                {this.state.locationEnv === 'NYC' &&
                                    <BuildingsComponentNYC
                                        viewType={viewType}
                                        doneLoading={this.state.doneLoading}
                                        buildingsData={this.state.buildingsData}
                                        fullBildingPage={this.state.fullBildingPage}
                                        onAddressClick={this.onAddressClick}
                                        sortField={sortField}
                                        handleSort={this.handleSort}
                                        combined={combined}
                                    />}
                                {this.state.locationEnv === 'Philadelphia' &&
                                    <BuildingsComponentPhiladelphia
                                        viewType={viewType}
                                        doneLoading={this.state.doneLoading}
                                        buildingsData={this.state.buildingsData}
                                        fullBildingPage={this.state.fullBildingPage}
                                        onAddressClick={this.onAddressClick}
                                        sortField={sortField}
                                        handleSort={this.handleSort}
                                        combined={combined}
                                    />}


                            </div>
                        </div>
                        <div className="col-xl-3 recent-activity pr-0">
                            <div className="pt-3 pb-3">
                                {this.props.jestData !== undefined ? null : <SmallCalendar />}
                            </div>
                            <HistoryUpdates />
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default Buildings;
