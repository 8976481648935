import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import Moment from "moment";
import { capSentence, formatter } from "../../../services/helperService";
import RetainedBadges from './retainedBadges';
import CofCProgress from "./cofcProgress";
import CustomFields from "./customFields";
import DocsViewer from '../../../common/document-viewer'

const ChargesBlock = ({ tableRawData, i }) => {
  const amount = tableRawData[`charge_${i}_infraction_amount`]
  return (
    <Fragment>
      <div className="row ecb-row">
        <div className="col-4 com-md-6 mb-3">
          <h6>Infraction Codes</h6>
          <p>{tableRawData[`charge_${i}_code`] ? tableRawData[`charge_${i}_code`] : 'n/a'}</p>
        </div>
        <div className="col-4 com-md-6 mb-3">
          <h6>Section of Law</h6>
          <p>{tableRawData[`charge_${i}_code_section`] ? tableRawData[`charge_${i}_code_section`] : 'n/a'}</p>
        </div>
        <div className="col-4 com-md-6 mb-3">
          <h6>Amount</h6>
          <p>{
            isNaN(parseFloat(amount)) ?
              amount || 'n/a' :
              formatter.format(parseFloat(amount))
          }</p>
        </div>
        <div className="col-6">
          <h6>Description</h6>
          <p>{tableRawData[`charge_${i}_code_description`] ? capSentence(tableRawData[`charge_${i}_code_description`]) : 'n/a'}</p>
        </div>
      </div>
    </Fragment>
  )
}



const DetailsECB = ({ moveToCategory, category, sub, tableRawData, apiData, jestData = false, subUsers, vendors, customFields, setRefreshHistory }) => {


  const [docsViewerVisibility, setDocsViewerVisibility] = useState(false);

  const chargesBlock = []

  for (let i = 1; i <= 5; i++) {
    if (tableRawData[`charge_${i}_code`]) {
      chargesBlock.push(<ChargesBlock key={i} tableRawData={tableRawData} i={i} />)
    }
  }

  const { retained, cofc } = apiData?.violationDetails?.clientAdditionalData || {};


  const retainedTypes = Object.keys(retained).filter(key => retained[key].isRetained);

  const customFieldsData = apiData?.violationDetails?.customFieldData ? apiData?.violationDetails?.customFieldData[0]?.fields || [] : [];

  return (
    <div className="card user-card ecb-head" style={retainedTypes.length > 0 ? { border: '1px solid #c36c00', borderRadius: 0 } : {}}>
      <RetainedBadges retained={retained} />
      <div className="card-body">
        <div className="row ecb-title align-items-center">
          <div className="col-12">

            <h5 className="text-info mb-3 pointer" onClick={moveToCategory}>{apiData?.violationDetails?.title ? apiData?.violationDetails?.title : `${category}/${sub}`}</h5>

          </div>
          <div className="col-4">
            <h5>Agency: <span>{capSentence(tableRawData.issuing_agency)}</span></h5>
            {tableRawData['Violation Type'] ? <h5>Violation Type: <span>{capSentence(tableRawData['Violation Type'])}</span></h5> : ''}
          </div>
          <div className="col-4">
            {(tableRawData.DOBEcb_severity &&
              <div className={(tableRawData.DOBEcb_severity) === "CLASS - 1" ? "red-box" : "blue-box"}>
                <h6>Severity</h6>
                <p><span>{tableRawData.DOBEcb_severity}</span></p>
              </div>
            )}
          </div>
          <div className="col-4 text-center">
            <h5>ECB#: <span>{tableRawData.ticket_number}</span> </h5>
            {apiData.violationDetails.ecbLinkedViolations && apiData.violationDetails.ecbLinkedViolations.length > 0 &&
              <div className="blue-box">
                <h6>Associated Violations</h6>
                <p>
                  DOB#: &nbsp;
                  {apiData.violationDetails.ecbLinkedViolations?.map((violation, i) => (
                    <Link className="text-nowrap" to={`/violation-details/${violation._id}`} target="_blank">
                      {violation.uniqueData.value}
                    </Link>
                  ))}
                </p>
              </div>
            }
            {apiData.violationDetails.savedSummonPdf ?
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a className="badge badge-light pointer" onClick={() => { setDocsViewerVisibility(true) }}>
                Saved Summons PDF  <i className="fa fa-external-link" />
              </a> : <a className="badge badge-light" href={'http://a820-ecbticketfinder.nyc.gov/GetViolationImage?violationNumber=' + tableRawData.ticket_number} rel="noopener noreferrer" target="_blank">
                Summons/Notice <i className="fa fa-external-link" />
              </a>
            }

            {(tableRawData.issuing_agency === "DEPT. OF BUILDINGS") &&
              <a className="badge badge-light" href={`http://a810-bisweb.nyc.gov/bisweb/ECBQueryByNumberServlet?requestid=1&ecbin=${tableRawData.ticket_number}`} rel="noopener noreferrer" target="_blank">
                DOB <i className="fa fa-external-link" />
              </a>
            }

            <a className="badge badge-light m-b-10" href={`https://account.bcompliant.com/redirect/ecb.html?violation_id=${tableRawData.ticket_number}`} rel="noopener noreferrer" target="_blank">
              ECB <i className="fa fa-external-link" />
            </a>
          </div>
        </div>
        <div className="row ecb-sub-head">
          <div className="col-12">
            <h5>Violation Details</h5>
          </div>
        </div>

        <div className="row ecb-row">
          <div className="col-4 com-md-6 mb-3">
            <h6>Respondent</h6>
            <p>{capSentence(tableRawData.respondent_last_name)}</p>
            <small><Link className="text-nowrap" to={`/settings/excluded-respondents?action=add&respondentName=${tableRawData.respondent_last_name}`} target="_blank">
              (exclude respondent options)
            </Link>
            </small>
          </div>
          <div className="col-4 com-md-6 mb-3">
            <h6>Balance Due</h6>
            <p>
              {tableRawData.balance_due ? formatter.format(parseFloat(tableRawData.balance_due)) : 'n/a'}
              {tableRawData.paid_amount && tableRawData.paid_amount > 0 ? ' [Paid: ' + formatter.format(parseFloat(tableRawData.paid_amount)) + ']' : ''}
              {tableRawData.balance_due && tableRawData.balance_due > 0 && (
                <a className="badge badge-light ml-2 m-b-10" href="https://a836-citypay.nyc.gov/citypay/ecb" rel="noopener noreferrer" target="_blank">
                  Pay <i className="fa fa-external-link" />
                </a>
              )}
            </p>
          </div>
          <div className="col-4 com-md-6 mb-3">
            <h6>Issued</h6>
            <p>{(tableRawData.violation_date && Moment(tableRawData.violation_date).isValid()) ? Moment(tableRawData.violation_date).format('LL') : tableRawData.violation_date}</p>
          </div>
          <div className="col-4 com-md-6">
            <h6>Hearing Date</h6>
            <p>{(tableRawData.hearing_date && Moment(tableRawData.hearing_date).isValid()) ? Moment(tableRawData.hearing_date).format('LL') : tableRawData.hearing_date}</p>
          </div>
          <div className="col-4 com-md-6">
            <h6>Hearing Status {tableRawData.hearing_result ? "/ Hearing Results" : ''}</h6>
            <p>{capSentence(tableRawData.hearing_status)} {tableRawData.hearing_result ? `/ ${capSentence(tableRawData.hearing_result)}` : ''}</p>
          </div>
          {capSentence(tableRawData.issuing_agency) !== 'Fire Department Of Nyc' &&
            <div className="col-4 com-md-6 mb-3">
              <h6>ECB Violation Status</h6>
              <p>{tableRawData.DOBEcb_ecb_violation_status ? capSentence(tableRawData.DOBEcb_ecb_violation_status) : 'n/a'}</p>
            </div>
          }
          {(['DEPT. OF BUILDINGS', 'DEPT OF BUILDINGS', 'DEPARTMENT OF BUILDINGS', 'DEPT OF BLDGS', 'FIRE DEPARTMENT OF NYC'].includes(tableRawData.issuing_agency?.trim().toUpperCase())) && <div className="col-4 com-md-6 mb-3">
            <h6>Corrections</h6>
            <p>{tableRawData.DOBEcb_certification_status ? capSentence(tableRawData.DOBEcb_certification_status) : capSentence(tableRawData.compliance_status)}</p>
          </div>
          }
          {tableRawData.compliance_date &&
            <div className="col-4 com-md-6">
              <h6>Compliance Date</h6>
              <p>{(tableRawData.compliance_date && Moment(tableRawData.compliance_date).isValid()) ? Moment(tableRawData.compliance_date).format('LL') : tableRawData.compliance_date}</p>
            </div>
          }
          {/*<div className="col-4 com-md-6 mb-3">
            <h6>Compliance</h6>
            <p>{
              (tableRawData.issuing_agency === "DEPT. OF BUILDINGS" || tableRawData.issuing_agency === "DEPT OF BUILDINGS") ? capSentence(tableRawData.DOBEcb_certification_status) : capSentence(tableRawData.compliance_status)
            }</p>
          </div>*/}
        </div>

        <div className="row ecb-sub-head">
          <div className="col-12">
            <h5>Charges</h5>
          </div>
        </div>
        {chargesBlock}

        {apiData?.violationDetails?.additionalData?.bcompliantNotes &&
          <div className="ecb-notes">
            <h5>
              BCompliant Violation Notes
            </h5>
            <div className="desc">
              <p>
                {apiData.violationDetails.additionalData.bcompliantNotes}
              </p>
            </div>
            {apiData.violationDetails.bcompliantNotesUpdatedBy && <small className="text-muted">
              Last updated By {apiData.violationDetails.bcompliantNotesUpdatedBy.name}
            </small>}
          </div>
        }
      </div>
      <CustomFields
        customFields={customFields}
        subUsers={subUsers}
        vendors={vendors}
        customFieldsData={customFieldsData}
        category={category}
        sub={sub}
        apiData={apiData}
        setRefreshHistory={setRefreshHistory}
      />
      <CofCProgress cofc={cofc} />
      <DocsViewer currentKey={apiData.violationDetails.savedSummonPdf} isVisible={docsViewerVisibility} setIsVisible={setDocsViewerVisibility} useAWSURL={true} />
    </div>
  );
};

export default DetailsECB;
