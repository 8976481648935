import React, { Fragment, useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import creService from '../../../services/creService';
import SafetyMailingsAutoRulesForm from "./form";
import RuleExistsMessage from './ruleNotExistsMessage'

const SafetyMailingsAutoRulesCreate = ({ jestData = false }) => {

    const history = useHistory();

    const [isProcessing, setIsProcessing] = useState(false);

    const [isPreviewLoading, setIsPreviewLoading] = useState(false);

    const [isPreviewLoaded, setIsPreviewLoaded] = useState(false);

    const [isPreview, setIsPreview] = useState(false);

    const [previewData, setPreviewData] = useState(jestData ? jestData.previewData:[]);

    const [formData, setFormData] = useState(jestData ? jestData.formData : { isAllBuilding: true });

    const onSubmit = async () => {
        setIsProcessing(true)
        try {
            const { data } = await creService.createSafetyMailingAutoRules(formData);
            if (data.success) {
                toast.success(data.message);
                setFormData({});
                history.push('/safety-mailings/auto-rules');
            } else {
                if (data.code === 'EXISTS') {
                    toast.warn(<RuleExistsMessage {...data} />);
                } else {
                    toast.error(data.message);
                }
            }

            setIsProcessing(false)
        } catch (_err) {
            toast.error(_err.message);
            setIsProcessing(false);
        }
    }

    const onPreview = async () => {
        setIsPreviewLoading(true)
        try {
            const { data } = await creService.previewSafetyMailingAutoRules(formData);
            if (data.success) {
                setPreviewData(data.data);
                setIsPreviewLoaded(true);
            } else {
                toast.error(data.message);
            }

            setIsPreviewLoading(false)
        } catch (_err) {
            toast.error(_err.message);
            setIsPreviewLoading(false);
            setIsPreviewLoaded(true);
        }
    }

    useEffect(() => {
        setIsPreview(false);
    }, [formData]);

    return (
        <Fragment>
            <div className="row violation-sec">
                <div className="col-xl-12 my-3">
                    <div className="card">
                        <div className="card-header row">
                            <div className="col-6">
                                <h5>[ Create ] Safety Mailings Auto Rule</h5>
                            </div>
                            <div className="col-6 text-right">
                                <Link to='/safety-mailings/auto-rules'>
                                    <button className="btn btn-primary text-white" type="button">
                                        <i className="fa fa-arrow-left mr-1"></i>
                                        Back
                                    </button>
                                </Link>
                            </div>
                        </div>
                        <div className="card-body">
                            <SafetyMailingsAutoRulesForm
                                formData={formData}
                                setFormData={setFormData}
                                onSubmit={onSubmit}
                                onPreview={onPreview}
                                isPreviewLoading={isPreviewLoading}
                                isProcessing={isProcessing}
                                isPreview={isPreview}
                                setIsPreview={setIsPreview}
                                isPreviewLoaded={isPreviewLoaded}
                                previewData={previewData}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default SafetyMailingsAutoRulesCreate;
