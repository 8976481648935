import React, { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import ReactLoading from 'react-loading';
import { toast } from 'react-toastify';
import { useParams, useLocation } from 'react-router-dom';

import creService from '../../../services/creService';
import CounterWidget from './_counterWidget';
import SafetyMailingViewFilters from './filters';
import SummaryRow from './newSummaryRow';
import SummaryPie from './summaryPie';

const SafetyMailingViewWindowSummaryRows = ({ order }) => {
  const { subMenuActive, bin } = useParams();
  const location = useLocation();
  const [printed] = useState(new URLSearchParams(location.search).get("printed"))

  const [selectedFilterData, setSelectedFilterData] = useState(() => {
    if (!bin) {
      return {
        bins: [],
        status: [],
      };
    }
    return {
      bins: [parseInt(bin)],
      status: [],
    };
  });

  const [displayData, setDisplayData] = useState([]);
  const [printedRows, setPrintedRows] = useState([]);
  const [printedRowsResponse, setPrintedRowsResponse] = useState([]);
  const [printedRowsNoResponse, setPrintedRowsNoResponse] = useState([]);
  const [totalSent, setTotalSent] = useState(0);
  const [totalResponseReceived, setTotalResponseReceived] = useState(0);
  const [responseRate, setResponseRate] = useState(0);
  const [showOnly, setShowOnly] = useState(() => {
    if (subMenuActive) {
      return subMenuActive.toUpperCase();
    }
    return 'TOTAL_SENT';
  });
  const [isLoading, setIsLoading] = useState(false);

  const columns = [
    {
      name: 'Address',
      sortable: true,
      minWidth: '180px',
      selector: (row) => row.mailingAddress.addressLine1,
    },
    {
      name: 'Tenant Name',
      sortable: true,
      minWidth: '180px',
      selector: (row) => row.name,
    },
    {
      name: 'Unit',
      sortable: true,
      selector: (row) => row.aptNumber,
    },
    {
      name: 'Action',
      sortable: true,
      minWidth: '200px',
      selector: (row) => {
        if (row.responseData?.actions) {
          return (
            <>
              {Object.keys(row.responseData?.actions).map(
                (action) => {
                  return (
                    row.responseData?.actions[action].completed ? '' :
                      <p key={action} className="text-danger mb-0">
                        {action}
                      </p>
                  );
                }
              )}
            </>
          );
        }
        return '-';
      },
    },
    {
      name: 'Received',
      sortable: true,
      selector: (row) =>
        row.responseData?.receivedBy
          ? row.responseData.receivedBy
          : '-',
    },
  ];

  const fetchPrintedRows = async () => {
    setIsLoading(true);
    try {
      const { data } = await creService.getSafetyMailingsOrderResponses(
        order._id,
        selectedFilterData
      );
      if (data.status) {
        const totalRecieved = data.data.filter(el => el.responseData)
        const totalNotRecieved = data.data.filter(el => !el.responseData)
        setPrintedRows(data.data);
        setPrintedRowsResponse(totalRecieved);
        setPrintedRowsNoResponse(totalNotRecieved);

        setTotalSent(data.data.length);
        setTotalResponseReceived(totalRecieved.length);
        setResponseRate(
          ((totalRecieved.length / data.data.length) * 100).toFixed(2)
        );
      } else {
        toast.error(data.message);
      }
      setIsLoading(false);
    } catch (_err) {
      toast.error(_err.message);
      setIsLoading(false);
    }
  };

  const exportToCSV = async () => {
    try {
      const { data } = await creService.exportSafetyMailingsOrderResponses(
        order._id,
        showOnly === 'TOTAL_RECEIVED',
        displayData.map(el => el._id)
      );
      if (data.status) {
        const pom = document.createElement('a');
        const blob = new Blob([data.csv], { type: 'text/csv;charset=utf-8;' });
        pom.href = URL.createObjectURL(blob);
        pom.setAttribute(
          'download',
          `Window Summary And Action for - ${selectedFilterData.bins
            .map(
              (bin) =>
                order.buildings.find((building) => building.bin === bin)
                  ?.building.address
            )
            .join(', ')}.csv`
        );
        pom.click();
      } else {
        toast.error(data.message);
      }
    } catch (_err) {
      toast.error(_err.message);
    }
  };

  useEffect(() => {
    fetchPrintedRows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilterData]);

  useEffect(() => {

    if (showOnly === 'TOTAL_SENT') {
      setDisplayData(printed ? printedRows.filter(el => el._id === printed) : printedRows);
    }
    if (showOnly === 'TOTAL_RECEIVED') {
      setDisplayData(printedRowsResponse);
    }
    if (showOnly === 'TOTAL_NOT_RECEIVED') {
      setDisplayData(printedRowsNoResponse);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [printedRows, printedRowsResponse, printedRowsNoResponse, showOnly]);

  return (
    <Fragment>
      <div className="row">
        <div className="col-12 col-md-3">
          <CounterWidget
            iconClass="fa-arrow-up"
            label="Total Sent"
            value={totalSent}
            isActive={showOnly === 'TOTAL_SENT'}
            onClick={() => setShowOnly('TOTAL_SENT')}
          />
        </div>
        <div className="col-12 col-md-3">
          <CounterWidget
            iconClass="fa-arrow-down"
            label="Total Received"
            value={totalResponseReceived}
            isActive={showOnly === 'TOTAL_RECEIVED'}
            onClick={() => setShowOnly('TOTAL_RECEIVED')}
          />
        </div>
        <div className="col-12 col-md-3">
          <CounterWidget
            iconClass="fa-times"
            label="Total Not Received"
            value={totalSent - totalResponseReceived}
            isActive={showOnly === 'TOTAL_NOT_RECEIVED'}
            onClick={() => setShowOnly('TOTAL_NOT_RECEIVED')}
          />
        </div>
        <div className="col-12 col-md-3">
          <CounterWidget
            iconClass="fa-percent"
            label="Response Rate"
            value={responseRate}
          />
        </div>
      </div>
      <SafetyMailingViewFilters
        order={order}
        onExport={() => exportToCSV()}
        selectedFilterData={selectedFilterData}
        setSelectedFilterData={setSelectedFilterData}
      />
      {!!totalSent && <SummaryPie totalSent={totalSent} totalResponseData={printedRowsResponse} displayData={displayData} />}
      <DataTable
        columns={columns}
        data={displayData}
        defaultSortField="responseData"
        expandableRowsComponent={<SummaryRow year={order.year} />}
        progressPending={isLoading}
        progressComponent={<ReactLoading type={'cylon'} color={'grey'} />}
        expandableRows
        pagination
        responsive
        // striped
        noHeader
      />
    </Fragment>
  );
};

export default SafetyMailingViewWindowSummaryRows;
