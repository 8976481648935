import React, { Fragment, useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";

import creService from "../../services/creService";
import {
  getDropDownYears,
  isTenantEditingAvaliable,
} from "../../services/helperService";

const dropdownYears = getDropDownYears();

const TenantCreate = () => {
  const history = useHistory();
  const search = useLocation().search;


  const [buildings, setBuildings] = useState([]);
  const [formData, setFormData] = useState({bin: +(new URLSearchParams(search).get('bin'))});
  const [isLoading, setIsLoading] = useState(false);

  const params = useParams();
  const [year, setYear] = useState(() => {
    if (params.year) {
      return params.year
    }
    return moment().format("YYYY");
  });
  const [isCreationAvaliable, setIsCreationAvaliable] = useState(
    isTenantEditingAvaliable(year)
  );

  const loadBuildings = async () => {
    const buildingRes = await creService.getBuildingsListOnly('safetyMailings');
    setBuildings(buildingRes.data);
  };

  const onChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      year: year === 'general' ? undefined : year,
    }));
    setIsCreationAvaliable(isTenantEditingAvaliable(year))
  }, [year]);

  const phoneValidation = () => {
    if (formData.phone === undefined || formData.phone === '') { return true }

    // eslint-disable-next-line no-useless-escape
    const regex = /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i;
    return regex.test(formData.phone);
  }

  const onSubmit = async (event) => {
    event.preventDefault();

    const isPhoneValidate = phoneValidation();

    if (!isPhoneValidate) {
      toast.error("Phone Number not valid!");
      return false;
    }

    setIsLoading(true);
    try {
      const { data } = await creService.createTenant(formData);
      if (data.status) {
        toast.success(data.message);
        history.goBack();
      } else {
        toast.error(data.message);
      }
      setIsLoading(false);
    } catch (_err) {
      toast.error(_err.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadBuildings();
  }, []);

  return (
    <Fragment>
      <div className="row violation-sec">
        <div className="col-xl-12 my-3">
          <div className="card">
            <div className="card-header">
              <div className="d-flex align-items-center justify-content-lg-between width-100">
                <div className="d-flex">

                  <button
                    onClick={() => { history.goBack(); }}
                    className="btn btn-light btn-lg align-self-center back-btn mx-2"
                    type="button"
                  >
                    <i className="fa fa-angle-left" />
                  </button>

                  <h5>Create New Tenant</h5>
                </div>
                {year !== 'general' && <select
                  className="form-control ml-3"
                  style={{ width: "250px" }}
                  required
                  name="year"
                  value={year}
                  onChange={(e) => setYear(e.target.value)}
                >
                  {dropdownYears.map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
                }
              </div>
            </div>
            {isCreationAvaliable ? (
              <div className="card-body">
                <form method="post" onSubmit={onSubmit}>
                  <div className="row">
                    <div className="form-group col-12">
                      <label>
                        Building <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        className="form-control"
                        name="bin"
                
                        onChange={(e) =>
                          onChange(e.target.name, e.target.value)
                        }
                      >
                        <option value="">-- Select Building --</option>
                        {buildings &&
                          buildings.map((building) => (
                            <option
                              key={`building_${building.bin}`}
                              value={building.bin}
                              selected={formData.bin === building.bin}
                            >
                              {building.display_address
                                ? building.display_address
                                : building.address}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="form-group col-6">
                      <label>
                        Unit/Apt# <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="aptNumber"
                        onChange={(e) =>
                          onChange(e.target.name, e.target.value)
                        }
                      />
                    </div>
                    {/* <div className="col-6 d-flex align-items-center">
                      <div className="form-check" style={{ marginTop: "18px" }}>
                        <input
                          type="checkbox"
                          className="form-check-inline"
                          id="allowMultipleTenants"
                          name="allowMultipleTenants"
                          onChange={(e) =>
                            onChange(e.target.name, e.target.checked)
                          }
                        />
                        <label
                          className="form-check-label"
                          for="allowMultipleTenants"
                          style={{ position: "relative", top: "-2px" }}
                        >
                          Allow Multiple Tenants
                        </label>
                      </div>
                    </div> */}
                  </div>
                  <div className="row">
                    <div className="form-group col-12 col-md-4">
                      <label>
                        Tenant Name <span style={{ color: "red" }}></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        onChange={(e) =>
                          onChange(e.target.name, e.target.value)
                        }
                      />
                    </div>
                    <div className="form-group col-12 col-md-4">
                      <label>
                        Tenant Email <span style={{ color: "red" }}></span>
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        onChange={(e) =>
                          onChange(e.target.name, e.target.value)
                        }
                      />
                    </div>
                    <div className="form-group col-12 col-md-4">
                      <label>Tenant Phone</label>
                      <input
                        type="text"
                        className="form-control"
                        name="phone"
                        onChange={(e) =>
                          onChange(e.target.name, e.target.value)
                        }
                      />
                    </div>
                    <div className="form-group col-12">
                      <label>Notes</label>
                      <textarea
                        className="form-control"
                        name="notes"
                        onChange={(e) =>
                          onChange(e.target.name, e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <button className="btn btn-primary" disabled={isLoading}>
                    {isLoading ? "Creating..." : "Submit"}
                  </button>
                </form>
              </div>
            ) : (
              <div className="alert alert-info">
                Unable to create tenant in {year}
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default TenantCreate;
