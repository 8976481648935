import React, { Fragment, useRef  } from 'react';
import CustomFields from "./customFields";
import { Input, Select } from 'antd';
import { UncontrolledTooltip } from 'reactstrap';
import creService from '../../../services/creService';
import { toast } from 'react-toastify';

const QuestionInput = ({ onChange = () => { }, questionBlock, users, vendors, appts }) => {
  const timeoutRef = useRef();

  const onChangeWithTrottle = (value) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    
    timeoutRef.current = setTimeout(() => {
      onChange(value)
    }, 2000);
    
  }


  if (questionBlock.answers[0] === 'client input') {
    return <Input disabled={!questionBlock.editable} defaultValue={questionBlock.answer} onChange={(event) => onChangeWithTrottle(event.target.value)} allowClear />
  } else if (questionBlock.answers[0] === 'client number') {
    return <Input disabled={!questionBlock.editable} defaultValue={questionBlock.answer} onChange={(event) => onChangeWithTrottle(event.target.value)} type="number" allowClear />
  } else if (questionBlock.answers[0] === 'client date') {
    return <Input disabled={!questionBlock.editable} defaultValue={questionBlock.answer} onChange={(event) => onChange(event.target.value)} type="date" allowClear />
  } else if (questionBlock.answers[0] === 'client users') {
    return <Select
      disabled={!questionBlock.editable}
      allowClear
      placeholder='user no selected'
      onChange={(value) => { onChange(value) }}
      defaultValue={questionBlock.answer}
      style={{ minWidth: '100%' }}
      options={users.map((value, i) => ({
        value,
        label: value,
      }))}
    />
  } else if (questionBlock.answers[0] === 'client vendors') {
    return <div className='d-flex align-items-center'>

      <Select

        disabled={!questionBlock.editable}
        allowClear
        style={{ flex: 1 }}
        placeholder='vendor no selected'
        onChange={(value) => { onChange(value) }}
        defaultValue={questionBlock.answer}

        options={vendors.map((value, i) => ({
          value,
          label: value,
        }))}
      />

    </div>

  } else if (questionBlock.answers[0] === 'client appt') {
    return <div className='d-flex align-items-center'>

      <Select
        disabled={!questionBlock.editable}
        allowClear
        style={{ flex: 1 }}
        placeholder='apartment no selected'
        onChange={(value) => { onChange(value) }}
        defaultValue={questionBlock.answer}

        options={appts.map((value, i) => ({
          value,
          label: value,
        }))}
      />

    </div>

  }


  return <Select
    disabled={!questionBlock.editable}
    allowClear
    placeholder='answer no selected'
    onChange={(value) => { onChange(value) }}
    defaultValue={questionBlock.answer}
    style={{ minWidth: '100%' }}
    options={questionBlock.answers.map((value, i) => ({
      value,
      label: value,
    }))}
  />

}

const DetailsQuestionnaire = ({
  category,
  sub,
  apiData,
  subUsers,
  vendors,
  customFields,
  setRefreshHistory,
  moveToCategory,
  saveOn,
  appts = []
}) => {


  const changeHandler = async (questionBlock, value) => {
    if (questionBlock.editable) {
      try {

        const { data: details } = await creService.updateQuestionaryDataClientListAnswer(apiData.violationDetails?._id, { question: questionBlock.question, value })
        if (!details.status) {
          toast.error(details.message);
        }


      } catch (e) {
        toast.error(e.message);
      }

    }

  }
  return (
    <Fragment >
      <div className="card user-card" >
        <div className="card-body ecb-head">

          <h5 onClick={moveToCategory} className="text-info mb-3 pointer">{category}/{sub}</h5>

          <div className='row ecb-row justify-content-between'>

            {apiData.violationDetails?.questions?.filter(questionBlock => questionBlock.answers[0] !== 'client upload').map((questionBlock, key) => {


              return (
                <div className={`m-2`} style={{ width: '250px' }} key={key}>
                  <h6 id={'answer' + key} style={{
                    overflow: 'hidden',
                    'whiteSpace': 'nowrap',
                    textOverflow: 'ellipsis',
                    cursor: 'pointer'
                  }}>{questionBlock.question}</h6>
                  <UncontrolledTooltip
                    placement="top"
                    target={'answer' + key}
                  >{questionBlock.question}
                  </UncontrolledTooltip>
                  <div className="rounded d-flex flex-column" >
                    <QuestionInput onChange={(value) => changeHandler(questionBlock, value)} questionBlock={questionBlock} users={subUsers.map(el => el.fullName)} vendors={vendors.map(el => el.fullName)} appts={appts} />
                  </div>
                </div>)

            })

            }

          </div>
        </div>
        <CustomFields
          saveOn={saveOn}
          customFields={customFields}
          subUsers={subUsers}
          vendors={vendors}
          category={category}
          sub={sub}
          apiData={apiData}
          setRefreshHistory={setRefreshHistory}
        />
      </div>
    </Fragment >

  );
};

export default DetailsQuestionnaire;
