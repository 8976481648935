import React, { Fragment, useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import moment from "moment";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import { Checkbox } from 'antd';
import creService from "../../services/creService";
import {
  getDropDownYears,
  isTenantEditingAvaliable,
} from "../../services/helperService";
import { confirmAlert } from "react-confirm-alert"; // Import
import FilterReactDataTable from "../../common/filterReactDataTable";
import { Typeahead } from "react-bootstrap-typeahead";
import { Zoom } from 'react-toastify';
import { Skeleton } from 'antd';
import { UncontrolledTooltip } from 'reactstrap';

function arrayToCsv(data) {
  return data.map(row =>
    row
      .map(String)  // convert every value to String
      .map(v => v.replaceAll('"', '""'))  // escape double quotes
      .map(v => `"${v}"`)  // quote it
      .join(',')  // comma-separated
  ).join('\r\n');  // rows starting on new lines
}

const dropdownYears = getDropDownYears();
const getStyleEditing = (tenantEditingAvaliable) => ({
  opacity: tenantEditingAvaliable ? 1 : 0.65,
});
const TenantsList = () => {
  const [data, setData] = useState([]);
  const toastId = useRef(null);

  const [buildings, setBuildings] = useState([]);
  const location = useLocation();
  const [tenantGeneralPage] = useState(location.pathname)
  const [useSM, setUseSM] = useState(false);
  const [selectedFilterData, setSelectedFilterData] = useState({
    bins: [],
  });
  const [checkedTenants, setCheckedTenants] = useState([])

  const params = useParams();

  const [year, setYear] = useState(() => {
    if (params.year) {
      return params.year;
    }
    const currentYear = moment().format("YYYY");

    return isTenantEditingAvaliable(currentYear) ? currentYear : +currentYear + 1
  });


  const [tenantEditingAvaliable, setTenantEditingAvaliable] = useState(
    isTenantEditingAvaliable(params.year)
  );

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const history = useHistory();
  const firstTenantsUpdate = useRef(true);
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    showSelected()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedTenants])

  const downloadTemplateFile = () => {

    const rows = [[
      'Bin',
      'Unit/Apt#',
      'Tenant Name',
      'Tenant Email',
      'Tenant Phone',
      'Notes',
      'Allow Multiple Tenants',
    ], ...data.map(el => [el.bin, el.aptNumber, el.fullName, el.email || '', el.phone || '', el.notes || '', el.allowMultipleTenants || ''])];

    const csvContent = arrayToCsv(rows)
    const pom = document.createElement('a');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    pom.href = URL.createObjectURL(blob);

    pom.setAttribute(
      'download',
      'Tenant List.csv'
    );
    pom.click();

  };

  const toggleCheckedTenants = (state, tenant) => {
    if (state) {

      if (tenant) {
        setCheckedTenants([...checkedTenants, tenant])
      } else {
        setCheckedTenants(filteredItems.filter(tenant => !tenant.orderNumber))
      }

    } else {
      if (tenant) {
        setCheckedTenants(checkedTenants.filter(tenantO => tenantO._id !== tenant._id))
      } else {
        setCheckedTenants([])
      }

    }
  }
  const toggleUseSM = async () => {

    try {
      const { data } = await creService.updateOptionUseSMTenats(!useSM);

      if (!data.status) {
        toast.error(data.message);
      } else {
        setUseSM(!useSM)
        fetchTenants()
      }
    } catch (_err) {
      toast.error(_err.message);
    }
  
  }
  const getUseSMTenants = async () => {

    try {
      const { data } = await creService.getOptionUseSMTenats(!useSM);

      if (!data.status) {
        toast.error(data.message);
      } else {
        setUseSM(data.data)
        
      }
    } catch (_err) {
      toast.error(_err.message);
    }
  
  }

  const filteredItems = data.filter((item) => {
    if (filterText.length > 0) {
      if (
        (item.fullName &&
          item.fullName.toLowerCase().includes(filterText.toLowerCase())) ||
        (item.building &&
          item.building.address
            .toLowerCase()
            .includes(filterText.toLowerCase())) ||
        (item.email &&
          item.email.toLowerCase().includes(filterText.toLowerCase())) ||
        (item.phone &&
          item.phone.toLowerCase().includes(filterText.toLowerCase())) ||
        (item.aptNumber &&
          item.aptNumber.toLowerCase().includes(filterText.toLowerCase()))
      ) {
        return true;
      }
      return false;
    }
    return true;
  });

  const showSelected = () => {

    if (checkedTenants.length !== 0) {
      const content = (
        <ul>
          <div> {checkedTenants.length} selected Tenants</div>
          {checkedTenants.slice(0, 8).map((el) => (
            <li key={el._id}>
              - {el.fullName}
            </li>
          ))}
          {checkedTenants.length > 8 && (
            <li> ... {checkedTenants.length - 8} more </li>
          )}
          <button
            className="btn btn-danger btn-xs text-nowrap pl-1 pr-1 pt-0 pb-0"
            onClick={() => deleteTenant()}
          >
            <i className="fa fa-times"></i> Remove Selected
          </button>
        </ul>
      );
      if (toastId.current) {
        toast.update(toastId.current, {
          render: content,
        });
      } else {
        toastId.current = toast(content, {
          position: 'bottom-left',
          autoClose: false,
          closeButton: false,
          hideProgressBar: false,
          closeOnClick: false,
          draggable: false,
          theme: 'light',
          transition: Zoom,
          className: 'table-viol-checked',
        });
      }
    } else {
      toast.dismiss(toastId.current);
      toastId.current = null;
    }
  };

  const columns = [
    {
      name: <Checkbox onChange={(event) => toggleCheckedTenants(event.target.checked)} checked={checkedTenants.length !== 0 && (checkedTenants.length === filteredItems.filter(t => !t.orderNumber).length)} ></Checkbox>,
      sortable: false,
      cell: (row) => row.orderNumber ? '-' : < Checkbox onChange={(event) => toggleCheckedTenants(event.target.checked, row)} checked={checkedTenants.some(el => el._id === row._id)} ></Checkbox >,
      width: '40px'
    },
    {
      name: "Name",
      sortable: true,
      selector: (row) => row.fullName || "--",
    },
    {
      name: "Building",
      sortable: true,
      selector: (row, i) =>

        <>
          <div id={'build-help' + i}>
            {row.building?.display_address
              ? row.building?.display_address
              : row.building?.address || "--"}
          </div>
          <UncontrolledTooltip
            placement="right"
            target={'build-help' + i}
          >
            {row.building?.display_address
              ? row.building?.display_address
              : row.building?.address || "--"}
          </UncontrolledTooltip>
        </>

    },
    {
      name: "Unit/Apt#",
      sortable: true,
      selector: (row) => row.aptNumber,
    },
    {
      name: "Email",
      sortable: true,
      selector: (row, i) =>
        <>
          <div id={'email-help' + i}>
            {row.email || "--"}
          </div>
          <UncontrolledTooltip
            placement="right"
            target={'email-help' + i}
          >
            {row.email || "--"}
          </UncontrolledTooltip>
        </>
    },
    {
      name: "Phone",
      sortable: true,
      selector: (row) => row.phone || "--",
    },
    {
      name: "Added At",
      sortable: true,
      selector: (row) => moment(row.addedAt).format("MM/DD/YYYY"),
    },
    {
      name: "Action",
      cell: (row) =>
        row.orderNumber || (tenantGeneralPage === '/tenants' && row.year) ? (
          <span className="btn btn-outline-info btn-xs text-nowrap" onClick={() => history.replace(`/safety-mailings/view/${row.orderNumber}`)}>
            {tenantGeneralPage !== '/tenants' ? 'in Order' : 'From Safety Mailing'}
          </span>
        ) : (
          <>
            <Link
              to={`/tenants/edit/${row._id}`}
              className="btn btn-info btn-xs mr-2 text-nowrap"
            >
              <i className="fa fa-edit"></i> Edit
            </Link>
            <button
              className="btn btn-danger btn-xs text-nowrap"
              onClick={() => deleteTenant(row._id)}
            >
              <i className="fa fa-times"></i> Remove
            </button>
          </>
        ),
    },
  ];

  const fetchTenants = async () => {
    setLoading(true)
    try {
      const { data } = await creService.getAllTenants(selectedFilterData, tenantGeneralPage === '/tenants' ? undefined : year);
      firstTenantsUpdate.current = false;
      if (data.status) {
        setData(data.data);
      } else {
        toast.error(data.message);
      }
    } catch (_err) {
      toast.error(_err.message);
    }
    setLoading(false)
  };

  const deleteTenant = async (id) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              const { data } = await creService.deleteTenant(id ? [id] : checkedTenants.map(el => el._id));
              setCheckedTenants([])
              if (data.status) {
                toast.success(data.message);
                fetchTenants();
              } else {
                toast.error(data.message);
              }
            } catch (_err) {
              toast.error(_err.message);
            }
          },
        },
        {
          label: "No",
          onClick: () => { },
        },
      ],
    });
  };

  const handleChange = (name, value) => {
    setSelectedFilterData((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const fetchBuildings = async (year) => {
    try {
      const { data } = await creService.getSafetyMailingsBuildings(null, tenantGeneralPage === '/tenants' ? undefined : year);
      if (data.status) {
        setBuildings(data.data);
      } else {
        toast.error(data.message);
      }
    } catch (_err) {
      toast.error(_err.message);
    }
  };

  const createOrImport = (to) => {
    if (tenantEditingAvaliable) {
      history.push(to);
    } else {
      toast.warn(
        "Cannot Add New Tenants for the past years. Please select future year"
      );
    }
  };
  const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
    }
  };


  useEffect(() => {
    getUseSMTenants()
    fetchTenants();
    fetchBuildings(year);
    setTenantEditingAvaliable(isTenantEditingAvaliable(year));
    const bin = new URLSearchParams(history.location.search).get('bin')
    if (bin) {
      setSelectedFilterData({
        bins: [+bin]
      })
    }


    // history.replace(`/tenants/list/${year}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year]);

  useEffect(() => {
    if (!firstTenantsUpdate.current) {
      fetchTenants();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilterData]);

  return (
    <Fragment>
      <div className="row violation-sec">
        <div className="col-xl-12 my-3">
          <div className="card">
            <div className="card-header row">
              <div className="col-3">
                <h5>Tenants List</h5>
              </div>
              <div className="col-9 text-right d-flex align-items-center justify-content-xl-end">

                <div>
                  <button
                    onClick={() => createOrImport(`/tenants/create/${tenantGeneralPage === '/tenants' ? 'general' : year}`)}
                    style={getStyleEditing(tenantEditingAvaliable)}
                    className="btn btn-primary text-white mb-1 mt-1 text-nowrap"
                    type="button"
                  >
                    Create New Tenant
                  </button>
                  <button
                    onClick={() => createOrImport(`/tenants/import/${tenantGeneralPage === '/tenants' ? 'general' : year}`)}
                    style={getStyleEditing(tenantEditingAvaliable)}
                    className="btn btn-secondary text-white ml-2"
                    type="button"
                  >
                    Import Tenants
                  </button>
                  {tenantGeneralPage !== '/tenants' && < button
                    onClick={() => createOrImport(`/tenants/copy-from/${year}`)}
                    style={getStyleEditing(tenantEditingAvaliable)}
                    className="btn btn-info text-white ml-2"
                    type="button"
                  >
                    Copy from previous year
                  </button>
                  }

                </div>
                {tenantGeneralPage !== '/tenants' && <select
                  className="form-control ml-3"
                  style={{ width: "250px" }}
                  required
                  name="year"
                  value={year}
                  onChange={(e) => setYear(e.target.value)}
                >
                  {dropdownYears.map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
                }
              </div>
            </div>
            <div className="card-body data-table">
              <div className="d-flex justify-content-end mr-3">
                {
                  !!selectedFilterData.bins?.length && <button
                    onClick={downloadTemplateFile}
                    className="btn btn-outline-secondary ml-2"
                    type="button"
                  > Export Tenants</button>
                }
              </div>
              <div className="row my-3">
                <div className="col-12 col-md-4">
                  <Typeahead
                    id="filter_buildings"
                    multiple
                    placeholder="Buildings"
                    onChange={(selected) => {
                      handleChange(
                        "bins",
                        selected.map((b) => b.bin)
                      );
                    }}
                    selected={buildings.filter((b) =>
                      selectedFilterData.bins.includes(b.bin)
                    )}
                    labelKey={(option) => {
                      if (option.display_address) {
                        return option.display_address;
                      }
                      return option.address || "";
                    }}
                    options={buildings}
                  />
                </div>
                <div className="col-12 col-md-4">
                  {<Checkbox onChange={toggleUseSM} checked={useSM}>
                    Include Safety Mailing Tenants
                  </Checkbox>
                  }
                </div>
                <div className="col-12 col-md-4">
                  <FilterReactDataTable
                    onFilter={(e) => setFilterText(e.target.value)}
                    onClear={handleClear}
                    filterText={filterText}
                  />
                </div>
              </div>
              <DataTable
                columns={columns}
                data={filteredItems}
                paginationResetDefaultPage={resetPaginationToggle}
                paginationRowsPerPageOptions={[
                  10, 20, 30, 40, 50, 60, 70, 80, 90, 100,
                ]}
                // subHeader
                pagination
                responsive
                striped
                noHeader={true}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment >
  );
};

export default TenantsList;
