import React, { Fragment, useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { useHistory, useLocation } from 'react-router-dom';
import { useParams } from 'react-router';
import moment from 'moment';


import creService from '../../services/creService';

const WorkOrderCreate = (props) => {

    const history = useHistory();
    const params = useParams();
    const location = useLocation();

    const cloneId = new URLSearchParams(location.search).get('clone');
    const workOrderId = params.id ? params.id : cloneId;
    const violationId = params.violationId;
    const editView = workOrderId && !cloneId ? true : false;

    const [bin, setBin] = useState(params.bin);


    const formRef = useRef();

    const [isCreating, setIsCreating] = useState(false);

    const [isBuildingLoading, setBuildingLoading] = useState(true);
    const [formData, setFormData] = useState({ vendorNotification: 'NoEmail', title: 'AppWork Work Order' });
    const [buildings, setBuildings] = useState([]);
    const [violationData, setViolationData] = useState({});
    // const [appWorkApts, setAppWorkApts] = useState([]);


    const loadBuildings = async () => {
        setBuildingLoading(true);
        const buildingRes = await creService.getBuildingsListOnly();
        setBuildings(buildingRes.data);
        setBuildingLoading(false);
    };

    const loadViolation = async () => {
        const res = await creService.getWorkOrderViolation(violationId, 'appWork');
        if (res.data.status) {
            setViolationData(res.data.data);
            setFormData((state) => ({
                ...state,
                description: res.data.data.description,
                aptNumber: res.data.data.apartment
            }));
        } else {
            toast.error(res.data.message);
        }
    };

    // const getApts = async () => {
    //     try {
    //         const res = await creService.getAppWorkApts(bin);

    //         // if (res.data.status) {
    //         //     setAppWorkApts(res.data.sites)

    //         // } else {
    //         //     toast.error(res.data.message);
    //         // }
    //     } catch (_err) {
    //         toast.error(_err.message);

    //     }
    // }
    // const aptChange = (value) => {

    //     setFormData((state) => ({
    //         ...state,
    //         aptNumber: value,
    //     }));
    // };




    const handleInput = (e) => {
        let { name, value, type, checked } = e.target;
        if (type === 'date') {
            value = new Date(value)
        } else if (type === 'checkbox') {
            value = checked
        } else {
            value = value.length ? value : null
        }


        setFormData((state) => ({
            ...state,
            [name]: value
        }));
    };

    const handleOnSubmit = async (e) => {
        e.preventDefault();

        setIsCreating(true);
        let payload = {
            ...formData,
            priority: 'Low',
            violationId,
            bin,
            dueDate: moment().add(3, 'days').format('MM/DD/YYYY'),
            category: 'N/A'
        };

        try {
            const res = await creService.createWorkOrder(payload, 'appWork');

            if (res.data.status) {
                toast.success(res.data.message);
                if (res.data.workOrderId) {
                    history.push(`/work-orders/view/${res.data.workOrderId}/documents`);
                } else {
                    history.push('/work-orders/list');
                }
            } else {
                if (res.data.errors) {
                    res.data.errors.map((err) => toast.error(err));
                } else {
                    toast.error(res.data.message);
                }
            }
        } catch (_err) {
            toast.error(_err.message || 'Something went wrong');

        }
        setIsCreating(false);
    };



    useEffect(() => {
        loadBuildings();
        if (violationId) {
            loadViolation();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // useEffect(() => {
    //     if (bin) {
    //         getApts()
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [bin])



    return (
        <Fragment>
            <div className="row violation-sec">
                <div className="col-xl-12 my-3">
                    <div className="card">
                        <div className="card-header">

                            <h5>
                                {editView ? 'Edit' : 'Create'} Work Order{' '}

                            </h5>
                        </div>

                        <div className="card-body">
                            {Object.keys(violationData).length > 0 && (
                                <h6>
                                    <b>{violationData.label}:</b> {violationData.data}
                                </h6>
                            )}

                            <form ref={formRef} onSubmit={(e) => handleOnSubmit(e)}>
                                {!editView && (
                                    <>
                                        <div className="row">

                                            <div className="form-group col-12 col-md-6">
                                                <label>
                                                    Building Address{' '}
                                                    <sup className="text-danger">*</sup>
                                                </label>
                                                <select
                                                    className="form-control"
                                                    defaultValue={formData.bin}
                                                    value={bin}
                                                    disabled={
                                                        isBuildingLoading ||
                                                        Object.keys(violationData).length > 0
                                                    }
                                                    name="bin"
                                                    required
                                                    onChange={(e) => setBin(e.target.value)}
                                                >
                                                    <option value="">
                                                        {isBuildingLoading
                                                            ? 'Loading...'
                                                            : '-- Select --'}
                                                    </option>
                                                    {buildings &&
                                                        buildings.filter(building => building.appWorkAddress).map((building) => (
                                                            <option
                                                                key={`building_${building.bin}`}
                                                                value={building.bin}
                                                            >
                                                                {building.appWorkAddress}
                                                            </option>
                                                        ))}
                                                </select>
                                            </div>


                                            {/* <div className="form-group col-12 col-md-6">
                                                <label>
                                                    Apt Number 
                                                </label>
                                                <select
                                                    defaultValue={formData.aptNumber}
                                                    className="form-control"
                                                    name="space"
                                                  
                                                    onChange={(e) => aptChange(e.target.value)}
                                                >
                                                    <option value="">-- Select --</option>
                                                    {appWorkApts.map(space => (
                                                        <option key={space._id} value={space._id}>
                                                            {space.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div> */}
                                        </div>

                                    </>
                                )}

                                <div className="row">
                                    <div className="form-group col-12">
                                        <label>
                                            Description <sup className="text-danger">*</sup>
                                        </label>
                                        <textarea
                                            style={{ height: '200px' }}
                                            type="text"
                                            name="description"
                                            className="form-control"
                                            onChange={(e) => handleInput(e)}
                                            required
                                            value={formData.description}
                                        ></textarea>
                                    </div>
                                    <div className="form-group col-12 mb-0">
                                        <button
                                            type="submit"
                                            className="btn btn-primary"
                                            disabled={isCreating}
                                        >
                                            {isCreating ? 'Sending WorkOrder to AppWork ... ' : editView ? 'Update' : 'Add'}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default WorkOrderCreate;
