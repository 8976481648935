import React from 'react';
import AddressHeaderBlockNYC from './addressHeaderBlockNYC'
import AddressHeaderBlockPhiladelphia from './addressHeaderBlockPhiladelphia'

const AddressHeaderBlock = (props) => props.data.locationEnv === "Philadelphia" ?
  <AddressHeaderBlockPhiladelphia {...props} />
  : <AddressHeaderBlockNYC {...props} />;




export default AddressHeaderBlock;
