import React from 'react';

const VPUSign = ({ VPU, VPUHistory, bigFormat, onlyVPU, oldVPU, justifyStart }) => {
  const threshold = 2.5;
  let stylesText = { width: 'auto' };
  let stylesMark = {};
  if (oldVPU) {
    stylesText.opacity = 0.7;
    stylesText.textDecoration = 'line-through';
  }
  if (bigFormat) {
    stylesText.fontSize = '0.7rem';
    stylesMark.fontSize = '1.2rem';
  }
  return VPU ? (
    <span
      className={`d-flex ${justifyStart ? '' : `justify-content-center`} align-items-center  ${bigFormat ? 'pt-2 pb-2' : ''}`}
      title={`VPU ${VPU >= threshold ? 'above' : 'below'} 2.5% threshold`}
    >
      <span
        className={`mr-1 ${
          VPU >= threshold
            ? `${onlyVPU ? 'text-danger pr-0' : 'badge badge-danger'}`
            : `${onlyVPU ? 'text-success pr-0' : 'badge badge-success'}`
        }`}
        style={{ ...stylesText, padding: '0.44em 0.7em' }}
      >
        {(bigFormat ? 'VPU: ' : '') + VPU.toFixed(2)}
      </span>

      {VPUHistory && (
        <i
          style={stylesMark}
          className={`fa fa-caret-${
            VPUHistory === 'up' ? 'up text-danger' : 'down text-success'
          }`}
          aria-hidden="true"
        ></i>
      )}
    </span>
  ) : null;
};

export default VPUSign;
