import creApi from "../../../services/creService";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { settingsKey } from "../../../services/authService";
import auth from "../../../services/authService";
import { UncontrolledTooltip } from 'reactstrap';

const ColorMark = (props) => {
    const [activeColor, setActiveColor] = useState(props.activeColor);
    const [userSettings, setUserSettings] = useState(JSON.parse(localStorage.getItem(settingsKey)));
    const [colorLabel, setColorLabel] = useState('');
    const [savedColorLabel, setSavedColorLabel] = useState('');
    const [loading, setLoading] = useState(false);
    const handleColorChange = async (color) => {
        setLoading(true);
        const res = await creApi.updateViolationColor(props.violationId, color);
        if (res.data.status) {
            toast.success(res.data.message);
            setActiveColor(color);
            props.updateState({ selectedColor: color });
            setColorLabel('');
            getColorLabel(color);
        } else {
            toast.error(res.data.message);
        }
        setLoading(false);
    }
    const getColorLabel = (initalColor) => {
        const label = userSettings?.colors?.[initalColor]?.label ? userSettings.colors[initalColor].label : '';
        setColorLabel(label);
        setSavedColorLabel(label);
        props.updateState({ colorToolTip: label });
    }
    const handleColorLabelSubmit = async () => {
        setLoading(true);
        const res = await creApi.updateColorLabel(activeColor, colorLabel);
        if (res.data.status) {
            toast.success(res.data.message);
            let userColors = userSettings?.colors ? userSettings.colors : {};
            userColors = {
                ...userColors,
                [activeColor]: {
                    label: colorLabel,
                },
            };
            localStorage.setItem(settingsKey, JSON.stringify({ ...userSettings, colors: userColors }));
            setUserSettings({ ...userSettings, colors: userColors });
            props.updateState({ colorToolTip: colorLabel });
            setSavedColorLabel(colorLabel);
        } else {
            toast.error(res.data.message);
        }
        setLoading(false);
    }
    useEffect(() => {
        getColorLabel(props.activeColor);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div className="card">
            <div className="card-body">
                {activeColor &&
                    <div className="mb-3">
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <button className="btn btn-sm" style={{ 'background-color': activeColor, 'cursor': 'initial' }}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</button>
                            </div>
                            <input className="form-control" id="colorLabel" placeholder="Add Label" onChange={(e) => setColorLabel(e.target.value)} value={colorLabel} disabled={auth.getCurrentUser().isSubUser && savedColorLabel.length > 0} />
                            {((auth.getCurrentUser()?.isSubUser && savedColorLabel.length <= 0) || (!auth.getCurrentUser()?.isSubUser)) &&
                                <div className="input-group-append">
                                    <button className="btn btn-darkblue" onClick={() => handleColorLabelSubmit()} disabled={loading}>Save</button>
                                </div>
                            }
                            {auth.getCurrentUser()?.isSubUser && savedColorLabel.length > 0 &&
                                <div className="input-group-append">
                                    <button className="btn btn-darkblue" id="save-button-color-mark" disabled={loading}>
                                        <i className="fa fa-info-circle" />
                                    </button>
                                    <UncontrolledTooltip placement="top" target="save-button-color-mark">
                                        Color names can be updated by master client only
                                    </UncontrolledTooltip>
                                </div>
                            }

                        </div>
                    </div>
                }
                <div className="row">
                    {props.allColors.map((color, key) => {
                        return (
                            <div className="col-1 m-1" key={key}>
                                <button style={{ 'backgroundColor': color, 'padding': '15px', 'border': 'none', 'cursor': 'pointer' }} onClick={() => handleColorChange(color)} disabled={loading}></button>
                            </div>
                        )
                    })}
                    {!auth.getCurrentUser()?.isSubUser &&
                        <div className="col-12 mt-3">
                            <button className="btn btn-darkblue btn-sm" onClick={() => handleColorChange('')} disabled={loading}>
                                Remove Color &nbsp;
                                <i className="fa fa-times "></i>
                            </button>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default ColorMark;
