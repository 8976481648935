import React, { Fragment, useState, useEffect } from 'react';
import moment from 'moment';
import { UncontrolledTooltip } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert'; // Import
import DataTable from 'react-data-table-component';
import { Skeleton } from 'antd';
import creService from '../../../services/creService';

const SafetyMailingsAutoRules = ({ jestData = false }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(true)
  const [autoRules, setAutoRules] = useState(
    jestData ? jestData.autoRules : []
  );


  const columns = [
    {
      name: 'Year',
      sortable: true,
      selector: (row) => row.year,
    },
    {
      name: 'Added Date',
      sortable: true,
      selector: (row) => moment(row.addedAt).format('MM/DD/YYYY'),
    },
    {
      name: 'BINs',
      sortable: true,
      width: '150px',
      selector: (row) => {
        if (row.isAllBuilding) {
          return 'All BINs';
        }
        return (
          <>
            <span id={`bins_${row._id}`}>{row.bins.join(', ')}</span>
            <UncontrolledTooltip target={`bins_${row._id}`} placement="left">
              {row.bins.join(', ')}
            </UncontrolledTooltip>
          </>
        );
      },
    },
    {
      name: 'Action',
      sortable: true,
      selector: (row) => row.action,
    },
    {
      name: 'Category',
      sortable: true,
      selector: (row) => row.category,
    },
    {
      name: 'Work Orders',
      sortable: true,
      selector: (row) => row.workOrders,
    },
    {
      name: 'Add to Title',
      sortable: true,
      selector: (row) => row.addToTitle,
    },
    {
      name: 'Action',
      cell: (row) => (
        <>
          <button
            onClick={() =>
              history.push(`/safety-mailings/auto-rules/edit/${row._id}`)
            }
            className="btn btn-info btn-xs mr-1"
          >
            <i className="fa fa-edit"></i> Edit
          </button>
          <button
            onClick={() => deleteRule(row._id)}
            className="btn btn-danger btn-xs"
          >
            <i className="fa fa-trash"></i> Delete
          </button>
        </>
      ),
    },
  ];

  const deleteRule = async (ruleId) => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            try {
              const { data } = await creService.deleteSafetyMailingAutoRules(
                ruleId
              );
              if (data.success) {
                toast.success(data.message);
                fetchAutoRules();
              } else {
                toast.error(data.message);
              }
            } catch (_err) {
              toast.error(_err.message);
            }
          },
        },
        {
          label: 'No',
          onClick: () => { },
        },
      ],
    });
  };

  const fetchAutoRules = async () => {
    setLoading(true);
    try {
      const { data } = await creService.getSafetyMailingAutoRules();
      if (data.success) {
        setAutoRules(data.data);
      } else {
        toast.error(data.message);
      }
      setLoading(false);
    } catch (_err) {
      toast.error(_err.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAutoRules();
  }, []);

  return (
    <Fragment>
      <div className="row violation-sec">
        <div className="col-xl-12 my-3">
          <div className="card">
            <div className="card-header row">
              <div className="col-6">
                <h5>List of Safety Mailings Auto Rules</h5>
              </div>
              <div className="col-6 text-right">
                <Link to="/safety-mailings/auto-rules/create">
                  <button className="btn btn-primary text-white" type="button">
                    Create New Rule
                  </button>
                </Link>
              </div>
            </div>
            <div className="card-body data-table">
              {loading ?
                <Skeleton active /> :
                <DataTable
                  columns={columns}
                  data={autoRules}
                  pagination
                  responsive
                  striped
                  noHeader
                  paginationRowsPerPageOptions={[
                    10, 20, 30, 40, 50, 60, 70, 80, 90, 100,
                  ]}
                />
              }
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SafetyMailingsAutoRules;
