import React, { Fragment } from 'react';
import logo from "../../assets/images/endless-logo.png";

const ThankYou = () => {
  return (
    <Fragment>
      <div className="page-wrapper enroll-bg">
        {/* <!-- error-400 start--> */}
        <div className="container-fluid">
          <div className="row login-header">
            <div className="col-md-4">
              <div className="pt-2">
                <img src={logo}  className="image-logo" alt="" />
              </div>
            </div>
            <div className="col-md-4 offset-md-4 text-right">
              <ul>
                <li><a href="/login">Log In</a></li>
                <li><a href="/enroll">Sign Up</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="error-wrapper">
          <div className="container">
            <div className="thankyou">
              <div className="inner-thankyou">
                <h1>Thank You</h1>
                <p>Thank you for your interest in BCompliant! One of our associates is currently
                reviewing your information and will reach out to you shortly</p>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- error-400 end--> */}
      </div>
    </Fragment>
  );
};

export default ThankYou;
