import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { Typography, Timeline } from 'antd';
import moment from 'moment';
import creService from '../../../services/creService';
import DocsViewer from '../../../common/document-viewer'
import './windowSummaryRow.css'

const { Paragraph } = Typography;
const checkDate = (year) => {
  const newDate = new Date(year)
  newDate.setMonth(1)
  newDate.setDate(15)
  return newDate < new Date()
}
const ExpandedRow = (props) => {

  const history = useHistory();
  const [data, setData] = useState(props.data);
  const [email, setEmail] = useState(props.data.email)
  const [sending, setSending] = useState(false)
  const [currentfile, setCurrentfile] = useState(null);
  const [docsViewerVisibility, setDocsViewerVisibility] = useState(false);


  const getS3Url = async (key) => {

    // key = 'safety-mailings/2023/response-data/signatures/617-4474181-1-012624.pdf'

    const { data } = await creService.getDocumentByKey(key);

    setCurrentfile(data.document)
    setDocsViewerVisibility(true)
    // window.open(window.location.origin + `/api/documents/aws-s3-file-stream/${localStorage.getItem('cre-token')}:${data.document._id}`, '_blank');

  };

  useEffect(() => {
    if (props.data._id !== data._id) {
      setData(props.data);
      setEmail(props.data.email)

    }
  }, [props.data, data._id]);

  const markAsCompleted = async (state, action, responseData) => {
    try {
      const response =
        await creService.safetyMailingsMarkCompletedResponseAction({
          id: responseData._id,
          completed: state,
          action
        });
      if (response.data.status) {
        setData({
          ...data,
          responseData: response.data.responseData,
        });
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (_err) {
      toast.error(_err.message);
    }
  };

  const createWorkOrder = (data, action) => {
    history.push(
      `/work-order/create-by-safety-mailing-printed/${data.responseData._id}/${data.bin}?action=${action}`
    );
  };

  const sendEmail = async () => {
    try {
      setSending(true)
      const { data: reponseData } = await creService.sendSafetyMailingEmail({
        printedRow: data._id
      })

      if (reponseData.status) {
        setData({
          ...data,
          emailSendInfo: [...(data.emailSendInfo || []), reponseData.emailSendInfo],
        });
        toast.success('Email send to your email')
      } else {
        toast.error(reponseData.message)
      }

    } catch (err) {
      toast.error(err.message)
    }
    setSending(false)
  }

  const onChangeEmail = async (value) => {
    try {
      const result = await creService.setSafetyMailingEmail(value, data._id)
      if (result.data.status) {
        toast.success('New email set')
        setEmail(value)
      } else {
        toast.error(result.data.message);
      }

    } catch (err) {
      toast.error(err.message);
    }


  }
  const viewWorkOrder = (workOrder) => {
    history.push(`/work-orders/view/${workOrder._id}`);
  };
  const keyProperty = props.isStove ? 'stoveReceived' : 'windowsSummaryReport'
  const actionKey = props.isStove ? 'stoveActionRequired' : 'windowsActionRequired'


  return (
    <>
      <div className="row expand-row">
        <div className="card col-4 border-right rounded-0">
          <div className="card-header">Address</div>
          <div className="card-body">
            <p className="mb-1">
              {data.building.display_address
                ? data.building.display_address
                : data.building.address}
            </p>
            <p className="mb-0">Apt# {data.aptNumber}</p>
            {data.responseData?.windowsSummaryReport &&
              data.responseData?.windowsSummaryReport['Signature'] &&
              data.responseData?.windowsSummaryReport['SignatureUploaded'] && (
                <button
                  onClick={() =>
                    getS3Url(data.responseData?.windowsSummaryReport['Signature'])
                  }
                  className="btn btn-link p-0 text-decoration-none"
                >
                  View Digital Signature
                </button>
              )}
            {data.phoneReceiveInfo?.length ? <div className='card-body ' ><h5 className=' mt-3 mb-3 '>Phone History</h5> <Timeline mode='left' className=' w-100'>{
              data.phoneReceiveInfo.reverse().map((phoneReceiveInfo, i) => {
                const date = new Date()
                date.setMinutes(date.getMinutes() - 20)
                const status = (date > new Date(phoneReceiveInfo.date) && phoneReceiveInfo.status === 'in progress') ? 'hang up' : phoneReceiveInfo.status

                return (
                  <Timeline.Item
                    label={moment(new Date(phoneReceiveInfo.date)).format('LLL')}
                    key={'phoneReceiveInfo' + i} className='ml-2' color={status === 'hang up' ? 'red' : (status === 'complited' ? 'green' : 'orange')}
                  >
                    <p>[{status === 'complited' ? 'Completed' : status}] [{phoneReceiveInfo.phoneNumber}]</p>

                  </Timeline.Item>)
              }

              )
            }</Timeline></div> : ''
            }
          </div>


        </div>
        {data.responseData && (
          <>
            <div className="card col-4 border-right rounded-0">
              <div className="card-header">Survey Response</div>
              <div className="card-body">

                {data.responseData[keyProperty] &&
                  Object.keys(data.responseData[keyProperty]).filter((key) => ['Yes', 'No'].includes(data.responseData[keyProperty][key])).map(key =>
                    <div className="row d-flex align-items-center" key={key}>
                      <strong className="col-10">{key}</strong>
                      <input
                        type="checkbox"
                        disabled={true}
                        className="col-2"
                        checked={
                          data.responseData[keyProperty][key] === 'Yes'
                        }
                      />
                    </div>
                  )}
                {data.responseData.windowsSummaryReport?.audioSign && <div className='mt-3'>
                  <div>Audio Sign</div>
                  <audio controls src={`http://localhost:8082/api/documents/get-by-key?key=${data.responseData.windowsSummaryReport?.audioSign}`}></audio>
                </div>}


              </div>
            </div>
            <div className="card col-4 rounded-0">
              <div className="card-header">
                <div className="row w-100 m-0">
                  <div className="col-6">Action Item </div>
                  <div className="col-2 p-0 small text-center">Received</div>
                  <div className="col-2 p-0 small text-center">Completed</div>
                  <div className="col-2 p-0 small text-center">Work Order</div>
                </div>
              </div>
              <div className="card-body">
                {data.responseData[actionKey] && (

                  actionKey === 'windowsActionRequired' ? <>
                    <div className="row mx-0 mb-2">
                      <strong className="col-6">Install Window Guards</strong>
                      <div className="col-2 p-0 text-center">
                        <input
                          type="checkbox"
                          readOnly
                          checked={data.responseData.windowsActionRequired.actionArray
                            .map(({ action }) => action)
                            .includes('Install Window Guards')}
                        />
                      </div>
                      <div className="col-2 p-0 text-center">
                        {data.responseData.workOrders &&
                          data.responseData.workOrders['Install Window Guards'] ? (
                          <span className="badge badge-info">
                            {
                              data.responseData.workOrders[
                                'Install Window Guards'
                              ].status
                            }
                          </span>
                        ) : (
                          <input
                            type="checkbox"
                            checked={
                              data.responseData.customWindowAction &&
                              data.responseData.customWindowAction[
                              'Install Window Guards'
                              ] &&
                              data.responseData.customWindowAction[
                                'Install Window Guards'
                              ].completed
                            }
                            onChange={(event) =>
                              markAsCompleted(
                                event.target.checked,
                                'Install Window Guards',
                                data.responseData
                              )
                            }
                          />
                        )}
                      </div>
                      <div className="col-2 p-0 text-center">
                        {data.responseData.workOrders &&
                          data.responseData.workOrders['Install Window Guards'] ? (
                          <button
                            onClick={() =>
                              viewWorkOrder(
                                data.responseData.workOrders[
                                'Install Window Guards'
                                ]
                              )
                            }
                            className="align-items-center btn btn-primary btn-xs d-inline-flex px-1 py-0 ml-1"
                          >
                            #
                            {
                              data.responseData.workOrders[
                                'Install Window Guards'
                              ].workOrderId
                            }
                          </button>
                        ) : (
                          <button
                            onClick={() =>
                              createWorkOrder(data, 'Install Window Guards')
                            }
                            className="align-items-center btn btn-primary btn-xs d-inline-flex px-1 py-0 ml-1"
                          >
                            <i className="fa fa-plus mr-1" /> Create
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="row mx-0 mb-2">
                      <strong className="col-6">Repair Window Guards</strong>
                      <div className="col-2 p-0 text-center">
                        <input
                          type="checkbox"
                          readOnly
                          checked={data.responseData.windowsActionRequired.actionArray
                            .map(({ action }) => action)
                            .includes('Repair Window Guards')}
                        />
                      </div>
                      <div className="col-2 p-0 text-center">
                        {data.responseData.workOrders &&
                          data.responseData.workOrders['Repair Window Guards'] ? (
                          <span className="badge badge-info">
                            {
                              data.responseData.workOrders['Repair Window Guards']
                                .status
                            }
                          </span>
                        ) : (
                          <input
                            type="checkbox"
                            checked={
                              data.responseData.customWindowAction &&
                              data.responseData.customWindowAction[
                              'Repair Window Guards'
                              ] &&
                              data.responseData.customWindowAction[
                                'Repair Window Guards'
                              ].completed
                            }
                            onChange={(event) =>
                              markAsCompleted(
                                event.target.checked,
                                'Repair Window Guards',
                                data.responseData
                              )
                            }
                          />
                        )}
                      </div>
                      <div className="col-2 p-0 text-center">
                        {data.responseData.workOrders &&
                          data.responseData.workOrders['Repair Window Guards'] ? (
                          <button
                            onClick={() =>
                              viewWorkOrder(
                                data.responseData.workOrders[
                                'Repair Window Guards'
                                ]
                              )
                            }
                            className="align-items-center btn btn-primary btn-xs d-inline-flex px-1 py-0 ml-1"
                          >
                            #
                            {
                              data.responseData.workOrders['Repair Window Guards']
                                .workOrderId
                            }
                          </button>
                        ) : (
                          <button
                            onClick={() =>
                              createWorkOrder(data, 'Repair Window Guards')
                            }
                            className="align-items-center btn btn-primary btn-xs d-inline-flex px-1 py-0 ml-1"
                          >
                            <i className="fa fa-plus mr-1" /> Create
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="row mx-0 mb-2">
                      <strong className="col-6">Lead Inspection Required?</strong>
                      <div className="col-2 p-0 text-center">
                        <input
                          type="checkbox"
                          readOnly
                          checked={data.responseData.windowsActionRequired.actionArray
                            .map(({ action }) => action)
                            .includes('Lead Inspection Required')}
                        />
                      </div>
                      <div className="col-2 p-0 text-center">
                        {data.responseData.workOrders &&
                          data.responseData.workOrders[
                          'Lead Inspection Required'
                          ] ? (
                          <span className="badge badge-info">
                            {
                              data.responseData.workOrders[
                                'Lead Inspection Required'
                              ].status
                            }
                          </span>
                        ) : (
                          <input
                            type="checkbox"
                            checked={
                              data.responseData.customWindowAction &&
                              data.responseData.customWindowAction[
                              'Lead Inspection Required'
                              ] &&
                              data.responseData.customWindowAction[
                                'Lead Inspection Required'
                              ].completed
                            }
                            onChange={(event) =>
                              markAsCompleted(
                                event.target.checked,
                                'Lead Inspection Required',
                                data.responseData
                              )
                            }
                          />
                        )}
                      </div>
                      <div className="col-2 p-0 text-center">
                        {data.responseData.workOrders &&
                          data.responseData.workOrders[
                          'Lead Inspection Required'
                          ] ? (
                          <button
                            onClick={() =>
                              viewWorkOrder(
                                data.responseData.workOrders[
                                'Lead Inspection Required'
                                ]
                              )
                            }
                            className="align-items-center btn btn-primary btn-xs d-inline-flex px-1 py-0 ml-1"
                          >
                            #
                            {
                              data.responseData.workOrders[
                                'Lead Inspection Required'
                              ].workOrderId
                            }
                          </button>
                        ) : (
                          <button
                            onClick={() =>
                              createWorkOrder(data, 'Lead Inspection Required')
                            }
                            className="align-items-center btn btn-primary btn-xs d-inline-flex px-1 py-0 ml-1"
                          >
                            <i className="fa fa-plus mr-1" /> Create
                          </button>
                        )}
                      </div>
                    </div>
                  </> : <>
                    <div className="row mx-0 mb-2">
                      <strong className="col-6">Install/Repair Knob Covers</strong>
                      <div className="col-2 p-0 text-center">
                        <input
                          type="checkbox"
                          readOnly
                          checked={data.responseData[actionKey].actionArray
                            .map(({ action }) => action)
                            .includes('Install/Repair Knob Covers')}
                        />
                      </div>
                      <div className="col-2 p-0 text-center">
                        {data.responseData.workOrders?.['Install/Repair Knob Covers'] ? (
                          <span className="badge badge-info">
                            {
                              data.responseData.workOrders[
                                'Install/Repair Knob Covers'
                              ].status
                            }
                          </span>
                        ) : (
                          <input
                            type="checkbox"
                            checked={
                              data.responseData.customWindowAction &&
                              data.responseData.customWindowAction[
                              'Install/Repair Knob Covers'
                              ] &&
                              data.responseData.customWindowAction[
                                'Install/Repair Knob Covers'
                              ].completed
                            }
                            onChange={(event) =>
                              markAsCompleted(
                                event.target.checked,
                                'Install/Repair Knob Covers',
                                data.responseData,
                              )
                            }
                          />
                        )}
                      </div>
                      <div className="col-2 p-0 text-center">
                        {data.responseData.workOrders?.['Install/Repair Knob Covers'] ? (
                          <button
                            onClick={() =>
                              viewWorkOrder(
                                data.responseData.workOrders['Install/Repair Knob Covers']
                              )
                            }
                            className="align-items-center btn btn-primary btn-xs d-inline-flex px-1 py-0 ml-1"
                          >
                            #
                            {
                              data.responseData.workOrders?.['Install/Repair Knob Covers'].workOrderId
                            }
                          </button>
                        ) : (
                          <button
                            onClick={() =>
                              createWorkOrder(data, 'Install/Repair Knob Covers')
                            }
                            className="align-items-center btn btn-primary btn-xs d-inline-flex px-1 py-0 ml-1"
                          >
                            <i className="fa fa-plus mr-1" /> Create
                          </button>
                        )}
                      </div>

                    </div>
                  </>
                )}
                {!data.responseData[actionKey] && (
                  <div className="d-flex justify-content-center align-items-center">
                    <span>No actions available</span>
                  </div>
                )}


              </div>
            </div>
          </>
        )}



        {
          !data.responseData && (
            <div className="card col-10 d-flex justify-content-center align-items-center ">
              <div className='d-flex flex-column w-100 text-center' >
                <div className='d-flex justify-content-center text-left'>
                  {data.emailSendInfo?.map(emailSendInfo =>

                    <div key={emailSendInfo.emailTo} className='ml-2'>
                      <strong>Emailed At</strong> - {moment(new Date(emailSendInfo.emailAt)).format('MM/DD/YYYY')}<br />
                      <strong>Emailed To</strong> - {emailSendInfo.emailTo}<br />
                      <strong>Emailed By</strong> - {emailSendInfo.emailedBy?.fullName}
                    </div>
                  )
                  }

                </div>
                <div>
                  <Paragraph className='mt-2' editable={{ onChange: onChangeEmail }}>{email}</Paragraph>
                </div>
              </div>
              <strong> Response not received yet.</strong>
              <div className='mt-1'>
                {(!data.emailSendInfo || (data.emailSendInfo.filter(em => em.emailTo === email).length < 2)) && checkDate(props.year) &&
                  <>
                    <button type="button" className="btn btn-sm btn-outline-primary mr-2" disabled={!data.email || sending} onClick={sendEmail}>
                      {data.email ? (sending ? 'Sendng ...' : `Send Email ${(data.emailSendInfo.filter(em => em.emailTo === email)).length ? 'Second Time' : ''}`) : 'No Email Information'}
                    </button>

                    <button
                      type="button"
                      className="btn btn-sm btn-outline-primary mr-2"
                      disabled={!data.email} onClick={() => window.open("/safety-mailings/email-temlate", "_blank")}
                    >
                      View Email Text
                    </button>
                  </>
                }
                <button
                  type="button"
                  className="btn btn-sm btn-outline-primary "
                  onClick={() => window.open(`/smf/${data._id}`, "_blank")}
                >
                  Offline Answer
                </button>

              </div>
            </div>
          )
        }
        <DocsViewer document={currentfile} isVisible={docsViewerVisibility} setIsVisible={setDocsViewerVisibility} useAWSURL={true} />
      </div >

    </>
  );
};

export default ExpandedRow;
