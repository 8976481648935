import React from 'react';
import { Link } from 'react-router-dom';

const RuleExistsMessage = (props) => {
    return (
        <div className="text-center">
            <div>
                Safety Mailing Rule &nbsp;
                <Link to={`/safety-mailings/auto-rules/edit/${props.ruleId}`} className="text-white text-underline">
                    Already Exists
                </Link>
            </div>
        </div>
    )
};

export default RuleExistsMessage;
