import React from 'react';

const ExpandedRow = ({ data }) => {

    return (
        <>
            <table className="table table-striped">
                <tr>
                    <th>BIN</th>
                    <th>Address</th>
                    <th>Mailing Address</th>
                    <th>Unit/Apt#</th>
                </tr>
                <tbody>
                    {data.buildings && data.buildings.map((building) => (<tr>
                        <td>{building.bin}</td>
                        <td>{building.building ? building.building.display_address ? building.building.display_address : building.building.address : 'building removed'}</td>
                        <td>
                            {building.mailingAddress.addressLine1} {' '}
                            {building.mailingAddress.addressLine2} {' '}
                            {building.mailingAddress.city} {' '}
                            {building.mailingAddress.state} {' '}
                            {building.mailingAddress.zipcode}
                        </td>
                        <td>{building.aptNumbers.map((_apt) => <small className="badge" style={{ backgroundColor: '#e9e9e9' }}>{_apt}</small>)}</td>
                    </tr>))}
                </tbody>
            </table>
        </>
    )
}

export default ExpandedRow;
