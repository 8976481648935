import React, { Fragment, useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import moment from 'moment';
import { toast } from 'react-toastify';

import AdressTable from '../../common/adressesTable';
import {
  getDropDownYears,
  isTenantEditingAvaliable,
} from '../../services/helperService';
import creService from '../../services/creService';
import { Switch, Input } from 'antd';

const dropdownYears = getDropDownYears();

const CopyTenants = () => {
  const history = useHistory();
  const [buildings, setBuildings] = useState([]);
  const [selectedBins, setSelectedBins] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [multipleSelect, setMultipleSelect] = useState(false);
  const [filterValue, setFilterValue] = useState('')

  const [tenants, setTenants] = useState([]);
  const params = useParams();
  const [year, setYear] = useState(() => {
    if (params.year) {
      return params.year;
    }
    return moment().format('YYYY');
  });

  const [filterBuildings, setFilterBuildings] = useState([]);

  const toggleSelectOption = () => {
    const newValue = !multipleSelect
    setMultipleSelect(!multipleSelect)
    if (newValue) {
      setFilterValue('')
      setSelectedBins(buildings.map(el => el.bin))
    } else {
      setSelectedBins(selectedBins.slice(0, 1))
    }

  }


  const [isCreationAvaliable, setIsCreationAvaliable] = useState(
    isTenantEditingAvaliable(year)
  );
  const loadBuildings = async () => {
    const buildingRes = await creService.getBuildingsListOnly('safetyMailings', year - 1);
    setBuildings(buildingRes.data);
  };

  useEffect(() => {
    setFilterBuildings(
      buildings
        .filter(el => String(el.bin).includes(filterValue) || (el.address + el.display_address).toLowerCase().includes(filterValue.toLowerCase()))
        .map(el => ({ ...el, checked: selectedBins.includes(el.bin) })))
  }, [filterValue, buildings, selectedBins])

  useEffect(() => {
    history.replace(`/tenants/copy-from/${year}`);
    setIsCreationAvaliable(isTenantEditingAvaliable(year));
  }, [year, history]);




  const handleBuildingCheck = (state, bin) => {
    if (state) {
      setSelectedBins([...selectedBins, bin])
    } else {
      setSelectedBins(selectedBins.filter(binValue => binValue !== bin))
    }
  };

  const handleUnAndCheckAll = (state) => {
    if (state) {
      setSelectedBins(buildings.map(el => el.bin))
    } else {
      setSelectedBins([])
    }

  };

  useEffect(() => {
    loadBuildings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedBins.length) {
      fetchTenants()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBins])

  const fetchTenants = async () => {
    try {
      const { data } = await creService.getAllTenants({
        bins: selectedBins
      }, year - 1);

      if (data.status) {
        setTenants(data.data);
      } else {
        toast.error(data.message);
      }
    } catch (_err) {
      toast.error(_err.message);
    }
  };

  const copyToCurrentYear = async () => {
    try {
      setIsLoading(true)
      const { data } = await creService.copyFromYearTenants({
        bins: selectedBins
      }, year);

      if (data.status) {
        await fetchTenants()
        toast.success('Tenants Copied');
        history.replace(`/tenants/list/${year}?bin=${selectedBins.length === 1 ? selectedBins[0] : '' }`);

      } else {
        toast.error(data.message);
      }
    } catch (_err) {
      toast.error(_err.message);
    }
    setIsLoading(false)
  };

  return (
    <Fragment>
      <div className="row violation-sec">
        <div className="col-xl-12 my-3">
          <div className="card">
            <div className="card-header">
              <div className="d-flex align-items-center justify-content-lg-between width-100">
                <div className="d-flex">
                  <Link to="/tenants/list">
                    <button
                      className="btn btn-light btn-lg align-self-center back-btn mx-2"
                      type="button"
                    >
                      <i className="fa fa-angle-left" />
                    </button>
                  </Link>
                  <h5>Import Tenants</h5>
                </div>
                <select
                  className="form-control ml-3"
                  style={{ width: '250px' }}
                  required
                  name="year"
                  value={year}
                  onChange={(e) => setYear(e.target.value)}
                >
                  {dropdownYears.map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </select>

              </div>
            </div>
            {isCreationAvaliable ? (
              <div className="card-body">
                <div >
                  <div className="form-group col-8">
                    <label>Building
                      <Switch
                        className='ml-3'
                        checkedChildren="multiple select"
                        unCheckedChildren="single select"
                        onChange={toggleSelectOption}
                        checked={multipleSelect}
                      />
                    </label>

                    {!multipleSelect && <select
                      className="form-control"
                      name="bin"
                      onChange={(e) => setSelectedBins([+e.target.value])}
                      defaultValue={selectedBins[0]}
                    >
                      <option value="">-- Select Building --</option>
                      {buildings &&
                        buildings.map((building) => (
                          <option
                            key={`building_${building.bin}`}
                            value={building.bin}
                          >
                            {building.display_address
                              ? building.display_address
                              : building.address} [BIN: {building.bin}]
                          </option>
                        ))}
                    </select>
                    }

                  </div>
                  {multipleSelect && <div>
                    <h5 className="text-uppercase mb-3 d-flex justify-content-end">
                      <div>
                        <Input placeholder="Building Filter" value={filterValue} onChange={(e) => setFilterValue(e.target.value)} />
                      </div>
                    </h5>
                    <div className="table-responsive">
                      <AdressTable
                        handleUnAndCheckAll={handleUnAndCheckAll}
                        buildings={filterBuildings}
                        doneLoading={isLoading}
                        handleBuildingCheck={handleBuildingCheck}
                      />
                    </div>
                  </div>
                  }

                </div>
                <hr />


                {tenants.length > 0 ? (
                  <>
                    <div className="table-responsive">
                      <div className='d-flex align-items-center justify-content-between p-2'>
                        <h5 className="mt-2 ">
                          Privious Year Tenants
                        </h5>
                        <button
                          className="btn btn-primary "
                          onClick={() => copyToCurrentYear()}
                          disabled={isLoading}
                        >
                          {isLoading ? 'Coping...' : 'Copy To Current Year'}
                        </button>
                      </div>
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Action</th>
                            <th>BIN</th>
                            <th>Unit/Apt#</th>
                            <th>Tenant Name</th>
                            <th>Tenant Email</th>
                            <th>Tenant Phone</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tenants.map((row, index) => (
                            <tr>
                              <td>{index + 1}</td>

                              <td>
                                {row.copiedID && <>
                                  <span className="text-success">copied</span> <br />
                                </>}
                                <small className="text-danger ">
                                  <Link
                                    to={`/tenants/edit/${row.copiedID ? row.copiedID : row._id}`}
                                    target="_blank"
                                    className="ml-2  btn-link "
                                  >
                                    Edit
                                  </Link>
                                </small>
                              </td>

                              <td>{row.bin}</td>
                              <td>{row.aptNumber}</td>
                              <td>{row.fullName}</td>
                              <td>{row.email}</td>
                              <td>{row.phone}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                  </>
                ) : <div>No tenants in this building for previous year</div>}
              </div>
            ) : (
              <div className="alert alert-info">
                Unable to import tenants in {year}
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment >
  );
};

export default CopyTenants;
