import React, { Fragment, useEffect, useState, useRef } from "react";
import creService from "../../services/creService";
import { settingsKey } from "../../services/authService";

import { Input } from 'antd';

import { toast } from 'react-toastify';


const { TextArea } = Input;


const EmailTemlate = () => {
    const template = useRef(null)
    const [value, setValue] = useState('')
    const [subjectValue, setSubjectValue] = useState('')
    const [personalizationTokens, setPersonalizationTokens] = useState([])
    const [sending, setSending] = useState(false)
    const [email, setEmail] = useState('myself')

    const addToken = (token) => {
        setValue(`${value} [${token}]`)
        template.current.focus()
    }

    const initilize = async () => {
        try {

            const { data } = await creService.getSafetyMailingEmailTemplate()
            if (data.status) {
                setValue(data.template.templateText)
                setSubjectValue(data.template.emailSubject)
                setPersonalizationTokens(data.personalizationTokens)
                const obj = JSON.parse(localStorage.getItem(settingsKey))
                if(obj.email) {
                    setEmail(obj.email)
                }
                
            } else {
                toast.error(data.message)
            }

        } catch (err) {
            toast.error(err.message)
        }


    }
    const saveTemplate = async () => {
        try {
            const { data } = await creService.saveSafetyMailingEmailTemplate({
                templateText: value,
                emailSubject: subjectValue
            })

            if (data.status) {
                toast.success('Template Saved')
            } else {
                toast.error(data.message)
            }

        } catch (err) {
            toast.error(err.message)
        }


    }

    const sendEmail = async () => {
        try {
            setSending(true)
            const { data } = await creService.sendSafetyMailingEmail({
                test: true
            })

            if (data.status) {
                toast.success('Email send to your email')
            } else {
                toast.error(data.message)
            }

        } catch (err) {
            toast.error(err.message)
        }
        setSending(false)
    }

    useEffect(() => {
        initilize()
    }, [])

    return (
        <Fragment>
            <div className="row violation-sec">
                <div className="col-xl-12 my-3">
                    <div className="card">
                        <div className="card-header">
                            <h5>Safety Mailings Email Template</h5>
                        </div>
                        <div className="card-body mt-3">
                            <Input addonBefore="Subject of the email" onChange={(e) => setSubjectValue(e.target.value)} value={subjectValue} />
                            <TextArea rows={10} value={value} onChange={(e) => setValue(e.target.value)} ref={template} />
                            <div className="mt-3">
                                <strong>Personalization Tokens:</strong>
                                {personalizationTokens.map(el =>
                                    <button key={el} type="button" className="btn btn-outline-primary ml-2  btn-sm" onClick={() => addToken(el)}> {el}</button>
                                )}

                            </div>
                            <div className="mt-3">
                                <button type="button" className="btn btn-primary " disabled={!value.trim() || !subjectValue.trim()} onClick={saveTemplate}> Save</button>
                                <button type="button" className="btn btn-primary ml-2" onClick={sendEmail} disabled={sending}> {sending ? 'Sendng ... ' : `Send test email to ${email}`}</button>
                            </div>
                        </div>

                    </div>

                </div>

            </div>
        </Fragment>
    )
}

export default EmailTemlate;