const RawData = (props) => {
    return (
        <div className="card">
            <div className="card-body">
                {Object.keys(props.tableRawData).map((value, key) => {
                    return <div className="form-group row" key={key}>
                        <label className="col-sm-3 col-form-label pt-0"><b>{value}:</b></label>
                        <div className="col-sm-9">
                            <div className="form-control-static ">{(typeof props.tableRawData[value] !== 'object')
                                ? props.tableRawData[value] : 'Object'}
                            </div>
                        </div>
                    </div>
                })}
            </div>
        </div>
    )
}

export default RawData;
