import React, { Fragment } from 'react';
import Moment from "moment";
import { Link } from 'react-router-dom'
import { Collapse } from 'antd';

import Helper from "../../../services/helperService";
import RetainedBadges from './retainedBadges';
import CofCProgress from "./cofcProgress";
import CustomFields from "./customFields";
import './DetailsHPDCharges.css'

const { Panel } = Collapse;

const headerKeys = ['omonumber', 'omoType', 'omostatusreason', 'approvedAmount', 'omoawarddate', 'totalCharges']

const DetailsHPDCharges = ({
    category,
    sub,
    tableRawData,
    apiData,
    convertedData,
    subUsers,
    vendors,
    customFields,
    setRefreshHistory,
    noHeader,
    moveToCategory
}) => {

    const { retained, cofc } = apiData?.violationDetails?.clientAdditionalData || {};

    const retainedTypes = retained ? Object.keys(retained).filter(key => retained[key].isRetained) : []

    const data = Object.entries(convertedData)

    return (
        // All Other Violations Show General Design
        data.map(([paragraphName, values], key) => {


            const header = <div className='d-flex justify-content-between mb-2' >
                {
                    headerKeys.map((headerValue, key) => {

                        let value = values.find(el => el.apiField === headerValue) || {}
                        let displayValue = tableRawData[value.apiField];
                        // Converting Date
                        if (displayValue && ((value.apiField.search(/date/i) !== -1) || (value.displayName.search(/date/i) !== -1)) && typeof displayValue !== 'undefined') {
                            if (Moment(displayValue).isValid()) {
                                displayValue = Moment(displayValue).format('LL');
                            } else {
                                displayValue = '--';
                            }
                        }

                        // Check if we need to add $ sign
                        if (displayValue && value.dollarAmount) {
                            displayValue = `${Helper.formatter.format(parseFloat(displayValue))}`;
                        }


                        displayValue = displayValue || '--'

                        return (

                            <div key={key}>
                                <div className='title-block'>{value.displayName} </div>
                                <p className="rounded d-flex flex-column">
                                    {displayValue}
                                </p>
                            </div>

                        )
                    })
                }
            </div>

            return <Fragment key={key + 100}>
                <div className="card user-card violation-block "
                    style={retainedTypes.length > 0 ? { border: '1px solid #c36c00', borderRadius: 0 } : {}}>
                    <RetainedBadges retained={retained} />
                    {
                        data.length > 1 &&
                        <div className="card-header">
                            <div className="online-user"><h5 className="font-primary f-18 mb-0">{paragraphName}</h5></div>
                        </div>
                    }


                    <div className="card-body ecb-head ">
                        {!noHeader &&

                            <h5 onClick={moveToCategory} className="text-info mb-3 pointer" id='headerBlock'>{category}/{sub}</h5>

                        }

                        {header}
                        <div className='row ecb-row' >
                            {values.filter(value => !headerKeys.includes(value.apiField)).map((value, key) => {

                                let displayValue = tableRawData[value.apiField];

                                // Converting Date
                                if (displayValue && ((value.apiField.search(/date/i) !== -1) || (value.displayName.search(/date/i) !== -1)) && typeof displayValue !== 'undefined') {
                                    if (Moment(displayValue).isValid()) {
                                        displayValue = Moment(displayValue).format('LL');
                                    } else {
                                        displayValue = '--';
                                    }
                                }

                                // Check if we need to add $ sign
                                if (displayValue && value.dollarAmount) {
                                    displayValue = `${Helper.formatter.format(parseFloat(displayValue))}`;
                                }


                                displayValue = displayValue || '--'

                                return (
                                    <div className='mt-2 col-6 col-md-4 mb-3' key={key}>
                                        <h6>{value.displayName} {value.link && <Link to={value.link}>
                                            <i className=" fa fa-external-link" aria-hidden="true"></i>
                                        </Link>
                                        }</h6>
                                        <p className="rounded d-flex flex-column">
                                            {displayValue}
                                        </p>
                                    </div>
                                )

                            })
                            }
                        </div>


                        {apiData?.violationDetails?.additionalData?.bcompliantNotes &&
                            <div className="ecb-notes" id='bcompliantNotes'>
                                <h5>
                                    BCompliant Violation Notes
                                </h5>
                                <div className="desc">
                                    <p>
                                        {apiData.violationDetails.additionalData.bcompliantNotes}
                                    </p>
                                </div>
                                {apiData.violationDetails.bcompliantNotesUpdatedBy && <small className="text-muted">
                                    Last updated By {apiData.violationDetails.bcompliantNotesUpdatedBy.name}
                                </small>}
                            </div>
                        }
                    </div>

                    <CustomFields
                        saveOn={category === 'Compliance' ? 'customCompliance' : undefined}
                        customFields={customFields}
                        subUsers={subUsers}
                        vendors={vendors}
                        category={category}
                        sub={sub}
                        apiData={apiData}
                        setRefreshHistory={setRefreshHistory}
                    />
                    <CofCProgress cofc={cofc} />
                </div>
            </Fragment>
        })
    );
};

export default DetailsHPDCharges;
