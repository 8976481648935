import React, { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import { Checkbox } from 'antd';

import creService from '../../services/creService';

const ReportFilters = (props) => {
  const [filterConfig, setFilterConfig] = useState(
    props.jestData !== undefined ? props.jestData.filterConfig : {}
  );
  
  const [filterConfigInitial, setFilterConfigInitial] = useState({});
  const [filterConfigWorkOrders, setfilterConfigWorkOrders] = useState({});

  const [loading, setLoading] = useState(false);

  const initialize = async () => {
    setLoading(true);
    const res = await creService.getReportFiltersConfig();
    setFilterConfigInitial(res.data.default);
    setfilterConfigWorkOrders(res.data.workOrders);
    setLoading(false);
  };


  useEffect(() => {
    if (props.reportType === 'Progress') {
      const updatedConfig = Object.keys(filterConfig).reduce((ac, a) => {
        if (!filterConfig[a].noSnapshot) {
          const fields = Object.keys(filterConfig[a].fields).reduce((acNext, aNext) => {
            if (!filterConfig[a].fields[aNext].noSnapshot) { acNext[aNext] = filterConfig[a].fields[aNext] }
            return acNext
          }, {})

          ac[a] = { ...filterConfig[a], fields }
        }

        return ac
      }, {})
      setFilterConfig(updatedConfig)

    } else if (props.reportType === 'WorkOrders') {
      setFilterConfig(filterConfigWorkOrders)
    } else {
      setFilterConfig(filterConfigInitial)
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.reportType, filterConfigInitial, filterConfigWorkOrders]);

  const handleCategoryChange = async (checked, category, hasRespondents) => {
    let subCategories = [];
    if (checked) {
      Object.keys(filterConfig[category].fields).map((subCategory) => {
        subCategories.push(subCategory);
        return true;
      });
    }
    let respondents = [];
    if (hasRespondents && props.respondentChecked) {
      respondents = await props.getRespondent(true, {
        ...props.filterData,
        [category]: subCategories,
      });
    }
    let filterObj = {
      ...props.filterData,
      [category]: subCategories,
      Respondents: respondents,
    };
    let respondentFilter = false;
    Object.keys(filterObj).map((category) => {
      filterObj[category].map((subCategory) => {
        if (filterConfig[category]?.fields[subCategory]?.hasRespondents) {
          respondentFilter = true;
        }
        return true;
      });
      return true;
    });
    if (!respondentFilter) {
      filterObj = {
        ...filterObj,
        Respondents: [],
      };
      props.setRespondents([]);
      props.setRespondentChecked(false);
    }
    props.setFilterData(filterObj);
    props.setFilterRespondents(respondentFilter);
  };

  const handleSubCategoryChange = async (
    checked,
    category,
    subCategory,
    hasRespondents
  ) => {
    let subCategories = [];
    if (props.filterData[category]) {
      props.filterData[category].map((subCat) => {
        subCategories.push(subCat);
        return true;
      });
    }
    if (checked) {
      subCategories.push(subCategory);
      if (hasRespondents) {
        props.setFilterRespondents(true);
      }
    } else {
      const subCategoryIndex = subCategories.indexOf(subCategory);
      if (subCategoryIndex > -1) {
        subCategories.splice(subCategoryIndex, 1);
      }
    }
    let respondents = [];
    if (hasRespondents && props.respondentChecked) {
      respondents = await props.getRespondent(true, {
        ...props.filterData,
        [category]: subCategories,
      });
    }
    let filterObj = {
      ...props.filterData,
      [category]: subCategories,
      Respondents: respondents,
    };
    let respondentFilter = false;
    Object.keys(filterObj).map((category) => {
      filterObj[category].map((subCategory) => {
        if (filterConfig[category]?.fields[subCategory]?.hasRespondents) {
          respondentFilter = true;
        }
        return true;
      });
      return true;
    });
    if (!respondentFilter) {
      filterObj = {
        ...filterObj,
        Respondents: [],
      };
      props.setRespondents([]);
      props.setRespondentChecked(false);
    }
    props.setFilterData(filterObj);
    props.setFilterRespondents(respondentFilter);
  };

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loading ? (
    <div align="center">
      {' '}
      <ReactLoading type={'cylon'} color={'grey'} />{' '}
    </div>
  ) : (
    <>
      <h5 className="text-uppercase mb-3" id='filter-by-section'>Filter by Section</h5>
      <div className="row">
        {Object.keys(filterConfig).map((category, index) => {
       
          return (
            <div key={`${category}_${index}`}>
              <div className="card">
                <div className="card-header p-2">
                  <div className="form-check">
                    <Checkbox
                      disabled={props.disabled}
                      id={category}
                      checked={
                        props.filterData[category]?.length ===
                          Object.keys(filterConfig[category].fields).length
                          ? 'checked'
                          : ''
                      }
                      onChange={(e) =>
                        handleCategoryChange(
                          e.target.checked,
                          category,
                          filterConfig[category].hasRespondents
                        )
                      }
                    >
                      {filterConfig[category].displayName}
                    </Checkbox>
                  </div>
                </div>
                <div className="card-body p-2">
                  {Object.keys(filterConfig[category].fields).map(
                    (subCategory, index) => {
                      return (
                        <div
                          key={`${category}_${subCategory}_${index}`}
                          className="form-check"
                        >
                          <Checkbox
                            disabled={props.disabled}
                            checked={
                              props.filterData[category]?.includes(subCategory)
                                ? 'checked'
                                : ''
                            }
                            onChange={(e) =>
                              handleSubCategoryChange(
                                e.target.checked,
                                category,
                                subCategory,
                                filterConfig[category].fields[subCategory]
                                  .hasRespondents
                              )
                            }
                          >
                            <span
                              style={
                                category === 'Colors'
                                  ? { color: subCategory }
                                  : {}
                              }
                            >
                              {
                                filterConfig[category].fields[subCategory]
                                  .displayName
                              }
                            </span>
                          </Checkbox>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default ReportFilters;
