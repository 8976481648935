import React, { Fragment, useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';

import creService from '../../services/creService';
import UnitNoExistsMessage from './unitNotExistsMessage';



const FireSafetyEdit = () => {
  const history = useHistory();

  const params = useParams();

  const [buildings, setBuildings] = useState([]);
  const [displayAddress, setDisplayAddress] = useState('');
  const [formData, setFormData] = useState({});
  const [cloneFormData, setCloneFormData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isLocationSpeakerOther, setLocationSpeakerOther] = useState(false);
  const [ownerPhones, setOwnerPhones] = useState([
    {
      id: 1,
      number: '',
    },
  ]);
  const [meansEgress, setMeansEgress] = useState([
    { id: 1, typeEgress: '', identification: '', location: '', leadsTo: '' },
  ]);

  useEffect(() => {
    setDisplayAddress(
      [
        ...buildings.map((item) => {
          if (item.bin === formData.bin) {
            return item.display_address ? item.display_address : item.address;
          }
          return false;
        }),
      ]
        .filter((item) => item)
        .pop()
    );
  }, [buildings, formData.bin]);

  const loadFireSafety = async () => {
    const { data } = await creService.getOneFireSafety(params.id);
    const _fireSafety = data.data || {};
    if (_fireSafety) {
      setFormData({
        _id: _fireSafety._id,
        bin: _fireSafety.bin,
        entityName: _fireSafety.entityName,
        buildingOwner: _fireSafety.buildingOwner,
        ownerAddress_1: _fireSafety.ownerAddress_1,
        ownerAddress_2: _fireSafety.ownerAddress_2,
        ownerAddress_3: _fireSafety.ownerAddress_3,
        additionalOwner: _fireSafety.additionalOwner,
        yearConstruction: _fireSafety.yearConstruction,
        typeConstruction: _fireSafety.typeConstruction,
        floorsAboveGround: _fireSafety.floorsAboveGround,
        floorsBelowGround: _fireSafety.floorsBelowGround,
        sprinklerSystem: _fireSafety.sprinklerSystem,
        sprinklerCoverage: _fireSafety.sprinklerCoverage,
        dwellingCheck: _fireSafety.dwellingCheck,
        stairwellCheck: _fireSafety.stairwellCheck,
        hallwaysCheck: _fireSafety.hallwaysCheck,
        compactorCheck: _fireSafety.compactorCheck,
        otherCheck: _fireSafety.otherCheck,
        dwelling: _fireSafety.dwelling,
        hallways: _fireSafety.hallways,
        stairwell: _fireSafety.stairwell,
        compactor: _fireSafety.compactor,
        other: _fireSafety.other,
        fireAlarm: _fireSafety.fireAlarm,
        locationManualPullStations: _fireSafety.locationManualPullStations,
        emergencyVoice: _fireSafety.emergencyVoice,
        paSystem: _fireSafety.paSystem,
        locationSpeakersStairwellCheck:
          _fireSafety.locationSpeakersStairwellCheck,
        locationSpeakersHallwayCheck: _fireSafety.locationSpeakersHallwayCheck,
        locationSpeakersDwellingCheck:
          _fireSafety.locationSpeakersDwellingCheck,
        locationSpeakersOtherCheck: _fireSafety.locationSpeakersOtherCheck,
        locationSpeakersOtherText: _fireSafety.locationSpeakersOtherText,
      });
      setLocationSpeakerOther(_fireSafety.locationSpeakersOtherCheck);
      setOwnerPhones(
        _fireSafety.ownerPhones.map((item) => {
          delete item._id;
          return item;
        })
      );
      setMeansEgress(
        _fireSafety.meansEgress.map((item) => {
          delete item._id;
          return item;
        })
      );
      setCloneFormData(formData);
    }
  };

  const loadBuildings = async () => {
    const buildingRes = await creService.getBuildingsListOnly('safetyMailings');
    setBuildings(buildingRes.data);
  };

  const onChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const onChangeAndAction = (field, value) => {
    if (field === 'locationSpeakersOtherCheck') {
      setLocationSpeakerOther(value);
    }
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const onChangeMeansEgress = (field, value, id, action = 'change') => {
    if (action === 'add') {
      setMeansEgress((prev) => [
        ...prev,
        {
          id: Math.max(...meansEgress.map((item) => item.id)) + 1,
          typeEgress: '',
          identification: '',
          location: '',
          leadsTo: '',
        },
      ]);
    } else if (action === 'remove') {
      if (id !== 1) {
        setMeansEgress(meansEgress.filter((item) => item.id !== id));
      }
    } else if (action === 'change') {
      setMeansEgress((prev) => {
        return prev.map((item) => {
          if (item.id === id) {
            return { ...item, [field]: value };
          }
          return item;
        });
      });
    }
  };

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      meansEgress,
    }));
  }, [meansEgress]);

  const onChangeOwnerPhones = (field, value, id, action = 'change') => {
    if (action === 'add') {
      setOwnerPhones((prev) => [
        ...prev,
        {
          id: Math.max(...ownerPhones.map((item) => item.id)) + 1,
          number: '',
        },
      ]);
    } else if (action === 'remove') {
      if (id !== 1) {
        setOwnerPhones(ownerPhones.filter((item) => item.id !== id));
      }
    } else if (action === 'change') {
      setOwnerPhones((prev) => {
        return prev.map((item) => {
          if (item.id === id) {
            return { ...item, [field]: value };
          }
          return item;
        });
      });
    }
  };

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      ownerPhones,
    }));
  }, [ownerPhones]);

  const phoneValidation = () => {
    // eslint-disable-next-line no-useless-escape
    const regex =
      /^(\+\d{1,2}\s?)?1?-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i;
    for (let { number } of ownerPhones) {
      if (!number) {
        return 'Owner Phone is required'
      }
      if (!regex.test(number)) {
        return 'Owner phone not valid'
      }
    }

  };

  const onSubmit = async (event) => {
    event.preventDefault();

    const phoneValidationErr = phoneValidation();

    if (phoneValidationErr) {

      toast.error(phoneValidationErr);
      return false;
    }
    const payload = {
      ...formData,
      ownerPhones: formData.ownerPhones.map((phone) => ({
        id: phone.id,
        number: phone.number.replaceAll(/\D*/gi, ''),
      })),
      locationManualPullStations: formData.locationManualPullStations,
      sprinklerCoverage: formData.sprinklerCoverage,
    }
    if (payload.sprinklerSystem === 'no') {
      delete payload['dwellingCheck'];
      delete payload['dwelling'];
      delete payload['compactor'];
      delete payload['compactorCheck'];
      delete payload['hallways'];
      delete payload['hallwaysCheck'];
      delete payload['stairwell'];
      delete payload['stairwellCheck'];
      delete payload['other'];
      delete payload['otherCheck'];
    }
    if (payload.paSystem === 'no') {
      delete payload['locationSpeakersDwellingCheck'];
      delete payload['locationSpeakersHallwayCheck'];
      delete payload['locationSpeakersOtherCheck'];
      delete payload['locationSpeakersOtherText'];
      delete payload['locationSpeakersStairwellCheck'];
    }


    setIsLoading(true);
    try {
      const { data } = await creService.updateFireSafety(payload);

      if (data.status) {
        toast.success(data.message);
        history.goBack()
      } else {
        toast.error(<UnitNoExistsMessage {...data} />);
      }
      setIsLoading(false);
    } catch (_err) {
      toast.error(_err.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadFireSafety();
    loadBuildings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  const eventGeneratePreview = async () => {
    if (JSON.stringify(cloneFormData) === JSON.stringify(formData)) {
      const formPreview = await creService.previewFireSafety(formData._id);
      previewModal(formPreview.data);
    } else if (JSON.stringify(cloneFormData) !== JSON.stringify(formData)) {
      try {
        await creService.updateFireSafety(formData);
        const formPreview = await creService.previewFireSafety(formData._id);
        setCloneFormData(formData);
        previewModal(formPreview.data);
      } catch (error) {
        toast.error(error.message);
      }
    }
  };

  const deleteFireSafety = async () => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            try {
              const { data } = await creService.deleteFireSafety(formData._id);
              if (data.status) {
                toast.success(data.message);
                history.push('/fire-safety/list');
              } else {
                toast.error(data.message);
              }
            } catch (_err) {
              toast.error(_err.message);
            }
          },
        },
        {
          label: 'No',
          onClick: () => { },
        },
      ],
    });
  };

  const previewModal = (html) => {
    Swal.fire({
      showCloseButton: true,
      showCancelButton: false,
      showConfirmButton: false,
      grow: 'fullscreen',
      html: html,
    });
  };

  return (
    <Fragment>

      <div className="row violation-sec">
        <div className="col-xl-12 my-3">
          <div className="card">
            <div className="card-header">
              <button
                onClick={history.goBack}
                className="btn btn-light btn-lg align-self-center back-btn mx-2"
                type="button"
              >
                <i className="fa fa-angle-left" />
              </button>

              <h5>Edit Fire Safety</h5>
            </div>
            <div className="card-body">
              <form method="post" onSubmit={onSubmit}>
                <div className="row">
                  <div className="title__block">Building Information</div>
                  <div className="form__group paddings flex align-items-center">
                    <div className="flex uniq-flex-basis align-items-center paddings">
                      <span>Building<span style={{ color: 'red' }}>*</span> </span>
                    </div>
                    <div className="flex width-100 paddings">
                      <input
                        type="text"
                        className="form-control"
                        value={displayAddress}
                        disabled
                      />
                      <input type="hidden" value={formData.bin} />
                    </div>
                  </div>
                  <div className="form__block width-100">
                    <div className="two__columns">
                      <div className="form__column paddings">
                        <div className="flex form__group align-items-center">
                          <div className="form__label">
                            <span>Entity Name<span style={{ color: 'red' }}>*</span> </span>
                          </div>
                          <div className="flex width-100">
                            <input
                              type="text"
                              className="form-control"
                              name="entityName"
                              value={formData.entityName}
                              onChange={(e) =>
                                onChange(e.target.name, e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="flex form__group align-items-center">
                          <div className="form__label">
                            <span>    Building Owner
                              <span style={{ color: 'red' }}>*</span> </span>
                          </div>
                          <div className="flex width-100">
                            <input
                              type="text"
                              className="form-control"
                              name="buildingOwner"
                              value={formData.buildingOwner}
                              onChange={(e) =>
                                onChange(e.target.name, e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="flex form__group align-items-center">
                          <div className="form__label">
                            <span>  Owner Address 1
                              <span style={{ color: 'red' }}>*</span> </span>
                          </div>
                          <div className="flex width-100">
                            <input
                              type="text"
                              className="form-control"
                              name="ownerAddress_1"
                              value={formData.ownerAddress_1}
                              onChange={(e) =>
                                onChange(e.target.name, e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="flex form__group align-items-center">
                          <div className="form__label">
                            <span> Owner Address 2
                              <span style={{ color: 'red' }}>*</span> </span>
                          </div>
                          <div className="flex width-100">
                            <input
                              type="text"
                              className="form-control"
                              name="ownerAddress_2"
                              value={formData.ownerAddress_2}
                              onChange={(e) =>
                                onChange(e.target.name, e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="flex form__group align-items-center">
                          <div className="form__label">Owner Address 3</div>
                          <div className="flex width-100">
                            <input
                              type="text"
                              className="form-control"
                              name="ownerAddress_3"
                              value={formData.ownerAddress_3}
                              onChange={(e) =>
                                onChange(e.target.name, e.target.value)
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form__column paddings">
                        {ownerPhones.map(({ id, number }) => {
                          return (
                            <div className="flex form__group align-items-center">
                              <div className="form__label">
                                <span>  Owner Phone {id}
                                  <span style={{ color: 'red' }}>*</span> </span>
                              </div>
                              <div className="flex paddings width-100">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="number"
                                  value={number}
                                  onChange={(e) =>
                                    onChangeOwnerPhones(
                                      e.target.name,
                                      e.target.value,
                                      id
                                    )
                                  }
                                />
                                <div className="form__actions">
                                  <div
                                    className="form__btn btn-primary"
                                    onClick={() =>
                                      onChangeOwnerPhones(
                                        null,
                                        null,
                                        null,
                                        'add'
                                      )
                                    }
                                  >
                                    +
                                  </div>
                                  {id !== 1 ? (
                                    <div
                                      className="form__btn btn-danger"
                                      onClick={() =>
                                        onChangeOwnerPhones(
                                          null,
                                          null,
                                          id,
                                          'remove'
                                        )
                                      }
                                    >
                                      -
                                    </div>
                                  ) : (
                                    <div className="form__btn form__btn_empty">
                                      -
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                        <div className="flex form__group align-items-center">
                          <div className="form__label">
                            Additional Owner / Rep Info
                          </div>
                          <div className="flex paddings width-100">
                            <input
                              type="text"
                              className="form-control"
                              name="additionalOwner"
                              value={formData.additionalOwner}
                              onChange={(e) =>
                                onChange(e.target.name, e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="flex form__group align-items-center">
                          <div className="form__label">

                            <span>Year of Construction<span style={{ color: 'red' }}>*</span></span>

                          </div>
                          <div className="flex paddings width-100">
                            <input
                              type="text"
                              className="form-control"
                              name="yearConstruction"
                              value={formData.yearConstruction}
                              onChange={(e) =>
                                onChange(e.target.name, e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="flex form__group align-items-center">
                          <div className="form__label">
                            <span> Type of Construction
                              <span style={{ color: 'red' }}>*</span> </span>
                          </div>
                          <div className="flex paddings width-100">
                            <select
                              className="form-control"
                              name="typeConstruction"
                              value={formData.typeConstruction}
                              onChange={(e) =>
                                onChange(e.target.name, e.target.value)
                              }
                            >
                              <option value="">
                                -- Select Type of Construction --
                              </option>
                              <option key="Combustible" value="Combustible">
                                Combustible
                              </option>
                              <option
                                key="Non-Combustible"
                                value="Non-Combustible"
                              >
                                Non-Combustible
                              </option>
                            </select>
                          </div>
                        </div>
                        <div className="two__columns">
                          <div className="form__column">
                            <div className="flex form__group align-items-center">
                              <div className="form__label flex-basis_floors">
                                <span>   Floors Above Ground
                                  <span style={{ color: 'red' }}>*</span> </span>
                              </div>
                              <div className="flex paddings width-100">
                                <input
                                  type="number"
                                  className="form-control"
                                  name="floorsAboveGround"
                                  value={formData.floorsAboveGround}
                                  onChange={(e) =>
                                    onChange(e.target.name, e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="form__column">
                            <div className="flex form__group align-items-center">
                              <div className="form__label flex-basis_floors">
                                <span>   Floors Below Ground
                                  <span style={{ color: 'red' }}>*</span> </span>
                              </div>
                              <div className="flex paddings width-100">
                                <input
                                  type="number"
                                  className="form-control"
                                  name="floorsBelowGround"
                                  value={formData.floorsBelowGround}
                                  onChange={(e) =>
                                    onChange(e.target.name, e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form__block form__block_2 width-100">
                    <div className="two__columns">
                      <div className="form__column paddings">
                        <div className="flex form__group align-items-center">
                          <div className="form__label">
                            <span>  Sprinkler System
                              <span style={{ color: 'red' }}>*</span> </span>
                          </div>
                          <div className="flex width-100">
                            <select
                              className="form-control"
                              name="sprinklerSystem"
                              value={formData.sprinklerSystem}
                              onChange={(e) =>
                                onChange(e.target.name, e.target.value)
                              }
                            >
                              <option value="">
                                -- Select Sprinkler System --
                              </option>
                              <option key="no" value="no">
                                no
                              </option>
                              <option key="yes" value="yes">
                                yes
                              </option>
                            </select>
                          </div>
                        </div>
                        {formData.sprinklerSystem !== 'no' && (
                          <div className="flex form__group align-items-center">
                            <div className="form__label">
                              <span>    Sprinkler Coverage
                                <span style={{ color: 'red' }}>*</span> </span>
                            </div>
                            <div className="flex width-100">
                              <select
                                className="form-control"
                                name="sprinklerCoverage"
                                value={formData.sprinklerCoverage}
                                onChange={(e) =>
                                  onChange(e.target.name, e.target.value)
                                }
                              >
                                <option value="">
                                  -- Select Sprinkler Coverage --
                                </option>
                                <option key="Entire" value="Entire">
                                  Entire
                                </option>
                                <option key="Partial" value="Partial">
                                  Partial
                                </option>
                              </select>
                            </div>
                          </div>
                        )}
                        <div className="flex form__group align-items-center empty__form">
                          <div className="form__label">Empty Object</div>
                          <div className="flex width-100">
                            <select className="form-control">
                              <option value="">Empty Object</option>
                            </select>
                          </div>
                        </div>
                        <div className="flex form__group align-items-center">
                          <div className="form__label">
                            <span>Fire Alarm<span style={{ color: 'red' }}>*</span></span>
                          </div>
                          <div className="flex width-100">
                            <select
                              className="form-control"
                              name="fireAlarm"
                              value={formData.fireAlarm}
                              onChange={(e) =>
                                onChange(e.target.name, e.target.value)
                              }
                            >
                              <option value="">-- Select Fire Alarm --</option>
                              <option key="no" value="no">
                                no
                              </option>
                              <option key="yes" value="yes">
                                yes
                              </option>
                              <option
                                key="Transmits Alarm"
                                value="Transmits Alarm"
                              >
                                Transmits Alarm
                              </option>
                            </select>
                          </div>
                        </div>
                        {formData.fireAlarm !== 'no' && (
                          <div className="flex form__group align-items-center">
                            <div className="form__label">
                              Location of Manual Pull Stations
                            </div>
                            <div className="flex width-100">
                              <input
                                type="text"
                                className="form-control"
                                name="locationManualPullStations"
                                value={formData.locationManualPullStations}
                                onChange={(e) =>
                                  onChange(e.target.name, e.target.value)
                                }
                              />
                            </div>
                          </div>
                        )}
                      </div>
                      {formData.sprinklerSystem !== 'no' && (
                        <div className="form__column paddings">
                          <div className="flex form__group align-items-center">
                            <div className="form__label flex-basis_20">
                              <input
                                type="checkbox"
                                className="form-check-inline"
                                id="dwellingCheck"
                                name="dwellingCheck"
                                checked={formData.dwellingCheck}
                                onChange={(e) =>
                                  onChangeAndAction(
                                    e.target.name,
                                    e.target.checked
                                  )
                                }
                              />
                              <label htmlFor="dwellingCheck" className="_label">
                                Dwelling
                              </label>
                            </div>
                            <div className="flex width-100">
                              <input
                                type="text"
                                className="form-control"
                                name="dwelling"
                                value={formData.dwelling}
                                onChange={(e) =>
                                  onChange(e.target.name, e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className="flex form__group align-items-center">
                            <div className="form__label flex-basis_20">
                              <input
                                type="checkbox"
                                className="form-check-inline"
                                id="stairwellCheck"
                                name="stairwellCheck"
                                checked={formData.stairwellCheck}
                                onChange={(e) =>
                                  onChangeAndAction(
                                    e.target.name,
                                    e.target.checked
                                  )
                                }
                              />
                              <label
                                htmlFor="stairwellCheck"
                                className="_label"
                              >
                                Stairwell
                              </label>
                            </div>
                            <div className="flex width-100">
                              <input
                                type="text"
                                className="form-control"
                                name="stairwell"
                                value={formData.stairwell}
                                onChange={(e) =>
                                  onChange(e.target.name, e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className="flex form__group align-items-center">
                            <div className="form__label flex-basis_20">
                              <input
                                type="checkbox"
                                className="form-check-inline"
                                id="hallwaysCheck"
                                name="hallwaysCheck"
                                checked={formData.hallwaysCheck}
                                onChange={(e) =>
                                  onChangeAndAction(
                                    e.target.name,
                                    e.target.checked
                                  )
                                }
                              />
                              <label htmlFor="hallwaysCheck" className="_label">
                                Hallways
                              </label>
                            </div>
                            <div className="flex width-100">
                              <input
                                type="text"
                                className="form-control"
                                name="hallways"
                                value={formData.hallways}
                                onChange={(e) =>
                                  onChange(e.target.name, e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className="flex form__group align-items-center">
                            <div className="form__label flex-basis_20">
                              <input
                                type="checkbox"
                                className="form-check-inline"
                                id="compactorCheck"
                                name="compactorCheck"
                                checked={formData.compactorCheck}
                                onChange={(e) =>
                                  onChangeAndAction(
                                    e.target.name,
                                    e.target.checked
                                  )
                                }
                              />
                              <label
                                htmlFor="compactorCheck"
                                className="_label"
                              >
                                Compactor
                              </label>
                            </div>
                            <div className="flex width-100">
                              <input
                                type="text"
                                className="form-control"
                                name="compactor"
                                value={formData.compactor}
                                onChange={(e) =>
                                  onChange(e.target.name, e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className="flex form__group align-items-center">
                            <div className="form__label flex-basis_20">
                              <input
                                type="checkbox"
                                className="form-check-inline"
                                id="otherCheck"
                                name="otherCheck"
                                checked={formData.otherCheck}
                                onChange={(e) =>
                                  onChangeAndAction(
                                    e.target.name,
                                    e.target.checked
                                  )
                                }
                              />
                              <label htmlFor="otherCheck" className="_label">
                                Other
                              </label>
                            </div>
                            <div className="flex width-100">
                              <input
                                type="text"
                                className="form-control"
                                name="other"
                                value={formData.other}
                                onChange={(e) =>
                                  onChange(e.target.name, e.target.value)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form__block form__block_3 width-100">
                    <div className="two__columns">
                      <div className="form__column paddings">
                        <div className="flex form__group align-items-center">
                          <div className="form__label">
                            <span> Emergency Voice Communication System
                              <span style={{ color: 'red' }}>*</span> </span>
                          </div>
                          <div className="flex width-100">
                            <select
                              className="form-control"
                              name="emergencyVoice"
                              value={formData.emergencyVoice}
                              onChange={(e) =>
                                onChange(e.target.name, e.target.value)
                              }
                            >
                              <option value="">
                                -- Select Emergency Voice --
                              </option>
                              <option key="no" value="no">
                                no
                              </option>
                              <option key="yes" value="yes">
                                yes
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="form__column paddings">
                        <div className="flex form__group align-items-center">
                          <div className="form__label flex-basis_20">
                            <span>PA System<span style={{ color: 'red' }}>*</span> </span>
                          </div>
                          <div className="flex width-100">
                            <select
                              className="form-control"
                              name="paSystem"
                              value={formData.paSystem}
                              onChange={(e) =>
                                onChange(e.target.name, e.target.value)
                              }
                            >
                              <option value="">-- Select PA System --</option>
                              <option key="no" value="no">
                                no
                              </option>
                              <option key="yes" value="yes">
                                yes
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    {formData.paSystem !== 'no' && (
                      <div className="one__column paddings">
                        <div className="flex form__group">
                          <div
                            className="form__label"
                            style={{ flexBasis: '15.8%' }}
                          >
                            Location of Speakers
                          </div>
                          <div
                            className="checkers"
                            style={{ flexBasis: '100%' }}
                          >
                            <div className="checkers__item">
                              <input
                                type="checkbox"
                                id="locationSpeakersStairwellCheck"
                                name="locationSpeakersStairwellCheck"
                                checked={
                                  formData.locationSpeakersStairwellCheck
                                }
                                onChange={(e) =>
                                  onChange(e.target.name, e.target.checked)
                                }
                              />
                              <label
                                htmlFor="locationSpeakersStairwellCheck"
                                className="label"
                              >
                                Stairwell
                              </label>
                            </div>
                            <div className="checkers__item">
                              <input
                                type="checkbox"
                                id="locationSpeakersHallwayCheck"
                                name="locationSpeakersHallwayCheck"
                                checked={formData.locationSpeakersHallwayCheck}
                                onChange={(e) =>
                                  onChange(e.target.name, e.target.checked)
                                }
                              />
                              <label
                                htmlFor="locationSpeakersHallwayCheck"
                                className="label"
                              >
                                Hallway
                              </label>
                            </div>
                            <div className="checkers__item">
                              <input
                                type="checkbox"
                                id="locationSpeakersDwellingCheck"
                                name="locationSpeakersDwellingCheck"
                                checked={formData.locationSpeakersDwellingCheck}
                                onChange={(e) =>
                                  onChange(e.target.name, e.target.checked)
                                }
                              />
                              <label
                                htmlFor="locationSpeakersDwellingCheck"
                                className="label"
                              >
                                Dwelling
                              </label>
                            </div>
                            <div className="checkers__item">
                              <input
                                type="checkbox"
                                id="locationSpeakersOtherCheck"
                                name="locationSpeakersOtherCheck"
                                checked={formData.locationSpeakersOtherCheck}
                                onChange={(e) =>
                                  onChangeAndAction(
                                    e.target.name,
                                    e.target.checked
                                  )
                                }
                              />
                              <label
                                htmlFor="locationSpeakersOtherCheck"
                                className="label"
                              >
                                Other
                              </label>
                            </div>
                          </div>
                          <textarea
                            disabled={!isLocationSpeakerOther}
                            style={{ marginLeft: '94' }}
                            className="flex width-100 form__textarea"
                            name="locationSpeakersOtherText"
                            value={formData.locationSpeakersOtherText}
                            onChange={(e) =>
                              onChange(e.target.name, e.target.value)
                            }
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="title__block">
                    <span>Means Of Egress<span style={{ color: 'red' }}>*</span> </span>
                  </div>
                  <div className="form__block width-100">
                    {meansEgress.map(
                      ({
                        id,
                        typeEgress,
                        identification,
                        location,
                        leadsTo,
                      }) => {
                        return (
                          <div className="two__columns">
                            <div className="form__column paddings">
                              <div className="flex form__group align-items-center">
                                <div className="form__label">Type Egress<span style={{ color: 'red' }}>*</span></div>
                                <div className="flex width-100">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="typeEgress"
                                    value={typeEgress}
                                    onChange={(e) =>
                                      onChangeMeansEgress(
                                        e.target.name,
                                        e.target.value,
                                        id
                                      )
                                    }
                                  />
                                </div>
                              </div>
                              <div className="flex form__group align-items-center">
                                <div className="form__label">Location<span style={{ color: 'red' }}>*</span></div>
                                <div className="flex width-100">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="location"
                                    value={location}
                                    onChange={(e) =>
                                      onChangeMeansEgress(
                                        e.target.name,
                                        e.target.value,
                                        id
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="form__column paddings">
                              <div className="flex form__group align-items-center">
                                <div className="form__label">
                                  Identification<span style={{ color: 'red' }}>*</span>
                                </div>
                                <div className="flex width-100">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="identification"
                                    value={identification}
                                    onChange={(e) =>
                                      onChangeMeansEgress(
                                        e.target.name,
                                        e.target.value,
                                        id
                                      )
                                    }
                                  />
                                  <div className="form__actions">
                                    <div
                                      className="form__btn btn-primary"
                                      onClick={() =>
                                        onChangeMeansEgress(
                                          null,
                                          null,
                                          null,
                                          'add'
                                        )
                                      }
                                    >
                                      +
                                    </div>
                                    {id !== 1 ? (
                                      <div
                                        className="form__btn btn-danger"
                                        onClick={() =>
                                          onChangeMeansEgress(
                                            null,
                                            null,
                                            id,
                                            'remove'
                                          )
                                        }
                                      >
                                        -
                                      </div>
                                    ) : (
                                      <div className="form__btn form__btn_empty">
                                        -
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="flex form__group align-items-center">
                                <div className="form__label">Leads To<span style={{ color: 'red' }}>*</span></div>
                                <div className="flex width-100">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="leadsTo"
                                    value={leadsTo}
                                    onChange={(e) =>
                                      onChangeMeansEgress(
                                        e.target.name,
                                        e.target.value,
                                        id
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
                <div
                  className="form__row"
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div className="form__column">
                    <button
                      className="btn btn-primary mr-2"
                      disabled={isLoading}
                    >
                      {isLoading ? 'Creating...' : 'Submit'}
                    </button>
                    <div
                      onClick={eventGeneratePreview}
                      className="btn btn-secondary"
                    >
                      Preview Form
                    </div>
                  </div>
                  <div className="form__column">
                    <div onClick={deleteFireSafety} className="btn btn-danger">
                      Remove
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default FireSafetyEdit;
