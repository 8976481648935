import React, { Fragment, useState, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import Top10Card from './top10Card';
import Outstanding from '../OutstandingFines';
import creService from '../../../services/creService';

const getlength = (category)=> category.reduce((ac, el) => ac + el.violationsDetails.length, 0)

const ActivitySnapshot = (props) => {
  const [DOB, setDOB] = useState([]);
  const [HPD, setHPD] = useState([]);
  const [ECB, setECB] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const fetchActivitySnapshot = async () => {
    setIsLoading(true);
    try {
      const { data } = await creService.getDashboardData(
        'Top 10 Open Violations',
        undefined,
        ['DOB', 'HPD', 'ECB']
      );

      setDOB(data.DOB);
      setHPD(data.HPD);
      setECB(data.ECB);

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchActivitySnapshot();
  }, []);

  return (
    <Fragment>
      <div className="row">
        <div className="col-md-7">
          <Top10Card title="DOB" data={DOB} isLoading={isLoading} />
          <Top10Card title="HPD" data={HPD} isLoading={isLoading} />
          <Top10Card title="ECB" data={ECB} isLoading={isLoading} />
        </div>
        <div className="col-md-5">
          <Outstanding history={props.history} />
          <Doughnut
            data={{
              labels: ['DOB', 'HPD', 'ECB'],
              datasets: [
                {
                  data: [
                    getlength(DOB),
                    getlength(HPD),
                    getlength(ECB),
                  ],
                  backgroundColor: ['#edc536', '#ce5370', '#65c92a'],
                  hoverBackgroundColor: ['#edc536', '#ce5370', '#65c92a'],
                },
              ],
            }}
            options={{
              responsive: true,
              maintainAspectRatio: true,
              legend: {
                display: true,
                position: 'bottom',
              },
            }}
            width={1000}
            height={900}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default ActivitySnapshot;
