import React from 'react';
import { Popover } from 'antd';
import { toast } from 'react-toastify';

import creService from '../../../services/creService';
import './ColorPicker.css';
import ColorPickerEdit from './ColorPickerEdit';
import { permissionsKey, isMasterClient } from "../../../services/authService";

const permissions = JSON.parse(localStorage.getItem(permissionsKey))

class ColorPicker extends React.Component {
  state = {
    displayColorPicker: false,
    visible: false,
    editMode: false,
    colorLabels: [],
  };

  hasAnyAccess = (actions) => {
    if (isMasterClient) {
      return true;
    } else {
      for (let action of actions) {
        if (permissions?.colors?.includes(action)) {
          return true;
        }
      }
    }
    return false;
  };

  handleEditModeToggle = () => {
    this.setState({ editMode: !this.state.editMode });
  };

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  handleLableChange = async (id) => {
    const data = {
      fieldId: this.props.field._id,
      violationFieldId: this.props.record[`${this.props.field._id}_id`],
      violationClientDataId: this.props?.clientDataId,
      data: id,
    };

    try {
      await this.props.handleColorLabelSelect(this.props.record._id, data);
      this.hide();
    } catch (e) {
      toast.error(e.message);
    }
  };

  hide = () => {
    this.setState({ visible: false });
  };

  handleVisibleChange = (newVisible) => {
    this.setState({ visible: newVisible });
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      this.setColorAndLabel();
    }
    if (
      prevState.visible === false &&
      prevState.visible !== this.state.visible
    ) {
      this.setState({ editMode: false });
    }
  }

  componentDidMount() {
    this.setColorAndLabel();
  }

  setColorAndLabel = () => {
    const selectedColorField = this.props.field.data?.find?.(
      (el) => el.id === this.props.data
    );

    this.setState({
      colorLabel: selectedColorField?.text || '',
      color:
        selectedColorField?.color || (this.props.data && 'gray') || 'white',
      colorLabels: this.props.field.data?.length ? this.props.field.data : [],
    });
  };

  onLabelChange = async (newData) => {
    try {
      const {
        data: { labels: newlabels },
      } = await creService.updateCustomCategoryFieldColorLabel(
        this.props.field._id,
        newData
      );
      this.props.handleColorLabelsUpdate(this.props.field._id, newlabels);
      this.hide();
    } catch (e) {
      toast.error(e.message);
    }
  };

  render() {
    let blockInRow = 1;
    if (this.state.colorLabels.length > 21) {
      blockInRow = 4;
    } else if (this.state.colorLabels.length > 18) {
      blockInRow = 3;
    } else if (this.state.colorLabels.length > 5) {
      blockInRow = 2;
    }
    let blockWidth = blockInRow * 150 + (blockInRow > 1 ? 10 : 0) + 'px';
    const otherRows = this.state.colorLabels.length % blockInRow

    return (
      <div className="violations-picker-wrapper">
        <Popover
          trigger="click"
          content={
            this.state.editMode ? (
              <ColorPickerEdit
                hasAccess={this.hasAnyAccess}
                colorLabelsProp={this.state.colorLabels}
                onLabelChange={this.onLabelChange}
              />
            ) : (
              <div className="d-flex align-items-center justify-content-center flex-column">
                <div
                  className={`d-flex align-items-center flex-wrap ${blockWidth !== '150px' ? 'justify-content-around' : 'flex-column justify-content-center'}`}
                  style={{ width: blockWidth }}
                >
                  {this.state.colorLabels.map?.((colorObj, i) => (
                    <button
                      key={colorObj.color}
                      className={`btn d-flex align-items-center justify-content-center color-menu-item`
                      }
                      style={{
                        background: colorObj.color,
                        width: otherRows && i >= (this.state.colorLabels.length - otherRows) ? blockInRow*150 / otherRows + 'px' : undefined
                      }}
                      onClick={() => {
                        this.handleLableChange(colorObj.id);
                      }}
                    >
                      {colorObj.text}
                    </button>
                  ))}
                </div>
                <div className="d-flex align-items-center justify-content-center flex-column">
                  <button
                    className={`btn d-flex align-items-center color-menu-item color-menu-item-empty`}
                    style={{ width: blockWidth }}
                    onClick={() => {
                      this.handleLableChange('');
                    }}
                  >
                    <span>clear</span>
                    <i className="fa fa-trash"></i>
                  </button>
                  {this.hasAnyAccess([
                    'create_colors',
                    'edit_colors',
                    'delete_colors',
                  ]) && (
                      <div className="d-flex align-items-center justify-content-center edit-block">
                        <button
                          className={`btn btn-light color-menu-edit-btn`}
                          style={{ width: blockWidth }}
                          onClick={this.handleEditModeToggle}
                        >
                          <i className="fa fa-edit"></i>Edit
                        </button>
                      </div>
                    )}
                </div>
              </div>
            )
          }
          visible={this.state.visible}
          onVisibleChange={this.handleVisibleChange}
        >
          <div
            id={`colorLabelTip_${this.props.record.uniqueViolationId}${this.props.field._id}`}
            style={{
              background: '#fff',
              borderRadius: '5px',
              boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
              cursor: 'pointer',
              minWidth: '120px',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              position: 'relative',
            }}
            onClick={this.handleClick}
          >
            <div
              className="violations-picker"
              style={{
                width: '100%',
                height: '32px',
                background: `${this.state.color}`,
              }}
            />
            {this.state.colorLabel && (
              <div
                className="mx-2"
                style={{
                  position: 'absolute',
                  top: 0,
                  bottom: 0,
                  right: 0,
                  left: 0,
                  transform: 'translate(0, 15%)',
                  color: '#ffffff',
                }}
              >
                {this.state.colorLabel}
              </div>
            )}
          </div>
        </Popover>
      </div>
    );
  }
}

export default ColorPicker;
