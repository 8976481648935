import React, { Fragment } from 'react';
import ReactLoading from 'react-loading';
import { titleWrapper } from '../../services/helperService';

const ViolationsTabEachBlock = (props) => {
  function onClick(currentValue) {
    if (currentValue[1] > 0) {
      localStorage.setItem('prev-location', '/permits');
      props.history.push(`/permits-details/${props.title}/${currentValue[0]}`);
    }
  }

  return (
    <Fragment>
      <div className="col-md-12">
        <div className="card fix-card">
          <div className="card-body">
            <div className="media-body row">
              <div className="col-12 col-md-2">
                <h4>{props.title}</h4>
              </div>
              {!props.loading ? (
                Object.entries(props.data).map((value, index) => {
                  return (
                    <div key={index + value} className="col-6 col-md-2">
                      <h6
                        className={value[1] > 0 ? 'pointer' : ''}
                        onClick={() => onClick(value)}
                      >
                        {typeof value[0] === 'string'
                          ? titleWrapper(value[0].replace('_', ' ').replace('__', ' '))
                          : value[0]}
                        :{' '}
                        <span>
                          {value[0] === 'Fines' ? '$' : ''}
                          {value[1]}{' '}
                        </span>{' '}
                      </h6>
                    </div>
                  );
                })
              ) : (
                <div align="center">
                  {' '}
                  <ReactLoading type={'cylon'} color={'grey'} />{' '}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ViolationsTabEachBlock;
