import React, { createRef, useEffect, useState } from "react";
import { DeleteOutlined, DownloadOutlined, FileOutlined, FilePdfOutlined, FileImageOutlined, FileExcelOutlined } from '@ant-design/icons';
import { toast } from "react-toastify";


import creApi from "../../services/creService";
import DocsViewer from '../../common/document-viewer'
import guard from '../../common/guard';


const FileAttachments = ({ bin }) => {
    const fileRef = createRef();
    const [isUploading, setIsUploading] = useState(false)
    const [files, setFiles] = useState([])
    const [loaded, setLoaded] = useState({});
    const [currentfile, setCurrentfile] = useState(null);
    const [docsViewerVisibility, setDocsViewerVisibility] = useState(false);

    const onUpload = async (event) => {
        setLoaded({})
        setIsUploading(true);
        const target = event.target
        if (target.files.length) {

            for (let file of target.files) {
                const name = file.name

                const data = new FormData();
                data.append('file', file);
                data.append('binFile', bin);
                data.append('source', 'Violation');


                try {
                    const response = await creApi.uploadDocument(data, {
                        onUploadProgress: (ProgressEvent) => {
                            setLoaded((loaded)=>({ ...loaded, [name]: (ProgressEvent.loaded / ProgressEvent.total) * 100 }));
                        },
                    })
                    if (response.data.status) {
                        toast.success('File Uploaded');
                    } else {
                        toast.error(response.data.message);
                    }
                } catch (e) {
                    toast.error(e.message);
                }
            }
            // setLoaded({})
            target.value = '';
        }
        getFiles()
        setIsUploading(false);
    }
    const getFiles = async () => {


        try {
            const response = await creApi.getBuidingDocuments(bin)
            if (response.data.status) {
                setFiles(response.data.items)
            } else {
                toast.error(response.data.message);
            }
        } catch (e) {
            toast.error(e.message);
        }
    }

    const onDelete = async (documentId) => {
        try {
            const { data } = await creApi.deleteDocument(documentId);
            if (data.status) {
                toast.success(data.message);
                getFiles()
            } else {
                toast.error(data.message);
            }
        } catch (e) {
            toast.error(e.message);
        }
    };

    useEffect(() => {
        if (bin) {
            getFiles()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bin])

    const handleFileClick = async (document) => {
        setCurrentfile(document)
        setDocsViewerVisibility(true)
    };
    const getIcon = (type) => {

        if (type.includes('pdf')) {
            return <FilePdfOutlined />
        }
        if (type.includes('csv')) {
            return <FileExcelOutlined />
        }
        if (type.includes('image')) {
            return <FileImageOutlined />
        }

        return <FileOutlined />
    }

    return <div className="card user-card ">
        <div className="card-header p-3">
            <div className="online-user">
                <h5 className="f-18 mb-0">Files Attachment</h5>
            </div>
            <div className='  mt-2 '>
                <div style={{ maxHeight: '300px', overflow: 'auto' }}>
                    {files.map(el =>{
                     
                        const size = (el.file.size / 1000000) > 0.001 ? `${(el.file.size / 1000000).toFixed(2)} MB` : `${(el.file.size / 1000).toFixed(2)} KB`
                        return <div
                        key={el._id}
                        onClick={() => handleFileClick(el)}
                        className="d-flex p-2 align-items-center rounded justify-content-between mb-1"
                        style={{ border: '1px dashed #d1cccc', maxWidth: '500px' }}
                    >
                        <div
                            style={{ width: '50px', height: '50px', background: '#d1cccc', fontSize: '24px' }}
                            className="d-flex align-items-center justify-content-center rounded pointer"
                        >
                            {getIcon(el.file.mimetype)}
                        </div>
                        <div className="pointer ml-1 " style={{ flex: 1 }}>
                            <div style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis', textWrap: 'nowrap' }}>{el.file.originalname}</div>
                            <div>{size}</div>
                        </div>
                        <div style={{ fontSize: '18px', textAlign: 'end' }} >
                            {guard({ serviceName: 'violations', action: 'remove_documents' }) &&
                                < DeleteOutlined className="mr-2 pointer" onClick={(event) => { event.stopPropagation(); onDelete(el._id) }} />
                            }
                            <DownloadOutlined className="mr-2 pointer" onClick={(event) => { event.stopPropagation(); window.open(el.awsUrl, '_blank') }} />
                        </div>
                    </div>
                    }
                        

                    )}
                </div>
                <input className='mt-2' ref={fileRef} type="file" onChange={onUpload} disabled={isUploading} multiple />
                {isUploading && Object.keys(loaded).map(fileName => (
                    <div className="progress mt-2">
                        <div
                            className={`progress-bar progress-bar-striped progress-bar-animated ${loaded[fileName] === 100 ? 'bg-success' : 'bg-info'}`}
                            role="progressbar"
                            style={{ width: `${loaded[fileName]}%` }}
                        >
                            {loaded[fileName] === 100 ?` ${fileName} Uploaded` : `Uploading ${fileName} ...` }
                        </div>
                    </div>
                ))}
            </div>
            <DocsViewer document={currentfile} isVisible={docsViewerVisibility} setIsVisible={setDocsViewerVisibility} />
        </div>
    </div>



}

export default FileAttachments;
