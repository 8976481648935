import React, { Fragment } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';

// const SafetyMailingOrderStatus = [
//     'Forms Requiring Review',
//     'Empty Forms',
//     'Install Required',
//     'Repair Required',
//     'Lead Insp. Required',
//     'No Action Required',
//     'Received',
//     'Not Received',
// ];

const SafetyMailingViewFilters = ({ order, selectedFilterData, setSelectedFilterData, onExport }) => {

    const handleChange = (name, value) => {
        setSelectedFilterData(state => ({
            ...state,
            [name]: value
        }));
    };

    const pdfExportHandler = async (letter) => {
        const jwt = localStorage.getItem('cre-token');
        let baseEndpoint = `/api/safety-mailings/responses/pdf/${jwt}:${order._id}${selectedFilterData?.bins?.length ? '?' + selectedFilterData.bins.map(el => 'bins[]=' + el).join('&') : ''}`
        if (letter) {
            baseEndpoint += '&letter=true'
        }
        window.open(window.location.origin + baseEndpoint, '_blank');
    }


    return (
        <Fragment>
            <div className="row my-3">
                <div className="col-8">
                    <Typeahead
                        id="filter_bins"
                        multiple
                        placeholder="Buildings"
                        onChange={(selected) => {
                            handleChange('bins', selected.map((b) => b.bin));
                        }}
                        selected={order.buildings.filter((b) => selectedFilterData.bins.includes(b.bin))}
                        labelKey={(option) => {
                            if (option.building?.display_address) {
                                return option.building.display_address;
                            }
                            return option.building?.address ;
                        }}
                        options={order.buildings.filter(el => el.building)}
                    />
                </div>
                <div className="col-4 pl-0 d-flex align-items-center justify-content-end">

                    {!!selectedFilterData?.bins?.length && <button type="button" className="btn btn-sm btn-outline-primary mr-1" onClick={() => pdfExportHandler(true)}>Generate DOH Letter</button>}
                    <button type="button" className="btn btn-sm btn-primary mr-1" onClick={onExport}>Export to CSV</button>
                    <button type="button" className="btn btn-sm btn-primary " onClick={() => pdfExportHandler()}>Export to PDF</button>
                </div>
            </div>
        </Fragment>
    );
}

export default SafetyMailingViewFilters;
