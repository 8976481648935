import React, { Fragment, useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';

import creService from '../../services/creService';
import SafetyMailingViewTabs from './view/tabs';

const SafetyMailingView = ({ jestData = false }) => {
  const params = useParams();

  const [order, setOrder] = useState(jestData ? jestData.order : null);

  const fetchOrder = async () => {
    try {
      const { data } = await creService.getSafetyMailingsOrder(params.id);
      if (data.status) {
        setOrder(data.data);
      } else {
        toast.error(data.message);
      }
    } catch (_err) {
      toast.error(_err.message);
    }
  };

  useEffect(() => {
    fetchOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  return (
    <Fragment>
      <div className="row violation-sec">
        <div className="col-xl-12 my-3">
          <div className="card">
            <div className="card-header row">
              <div className="col-6">
                <h5>Safety Mailing Order #{order?.orderNumber || '--'}</h5>
              </div>
              <div className="col-6 text-right">
                <Link
                  to="/safety-mailings"
                  className="btn btn-primary text-white"
                >
                  <i className="fa fa-arrow-left" /> Back
                </Link>
              </div>
            </div>
            <div className="card-body p-0">
              {order && (
                <>
                  <div className="table-responsive border-0">
                    <table className="table">
                      <tbody>
                        <tr>
                          <th>Order Number</th>
                          <td>{order.orderNumber}</td>
                          <th>Status</th>
                          <td>{order.status}</td>
                        </tr>
                        <tr>
                          <th>Master Client</th>
                          <td>{order?.masterClient?.fullName || '--'}</td>
                          <th>Created By</th>
                          <td>{order?.createdByUser?.fullName || '--'}</td>
                        </tr>
                        <tr>
                          <th>Updated At</th>
                          <td>
                            {moment(order.updatedAt).format(
                              'MM/DD/YYYY hh:mm A'
                            )}
                          </td>
                          <th>Added At</th>
                          <td>
                            {moment(order.addedAt).format('MM/DD/YYYY hh:mm A')}
                          </td>
                        </tr>
                        {order.notes && order.notes.length > 0 && (
                          <tr>
                            <th>Notes</th>
                            <td>{order.notes || '-'}</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="mt-4">
                    <SafetyMailingViewTabs order={order} /> 
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SafetyMailingView;
