import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import Moment from 'moment';

import VPUSign from '../../../common/VPUSign';

const RecentActivityListGroupCollapse = (props) => {

  const [collapse, setcollapse] = useState(!!props.VPU);
  if (!props.VPU && !props.Data) {
    return null;
  }
  const toggle = () => {
    setcollapse(!collapse);
  };

  const Data = props.Data;

  // Converting Dates
  if (
    !props.VPU &&
    Data.title &&
    (Data.title.search(/date/i) !== -1 ||
      props.Data.titleKey.search(/date/i) !== -1)
  ) {

    if (Data.difference.__old) {
      const date = Moment(Data.difference.__old)
      Data.difference.__old = date.isValid() ? date.format('LL') : Data.difference.__old
    }
    const newdate = Moment(Data.difference.__new)

    Data.difference.__new = newdate.isValid() ? newdate.format('LL') : (Data.difference.__new || ' ');

  }

  const makeTitle = (props) => {
    if (props.titleKey === 'sub') {
      return 'Sub';
    }

    let title =
      (props.title?.length > 0 ? props?.title : props?.titleKey) || '';
    title = title.replace(/_/g, ' ').replace(/__/g, ' ');
    return title;
  };

  return (
    <small>
      <li
        className="p-1 list-group-item align-items-center pointer text-muted border-0"
        onClick={toggle}
      >
        <div className="d-flex justify-content-between ">
          {props.VPU ? 'VPU' : `in ${makeTitle(props.Data)}`}
          <span className="badge badge-light ">{collapse ? '-' : '+'}</span>
        </div>
        <Collapse isOpen={collapse}>
          <ul className="list-group">
            <li className="list-group-item list-group-item-light">
              {props.VPU ? (
                <VPUSign VPU={props.VPU?.oldVPU} oldVPU justifyStart></VPUSign>
              ) : (
                <s>{String(Data.difference.__old || 'n/a').replace('__', ' ')}</s>
              )}
            </li>
            <li className="list-group-item">
              {props.VPU ? (
                <VPUSign
                  VPU={props.VPU?.VPU}
                  VPUHistory={props.VPU?.VPUHistory}
                  justifyStart
                ></VPUSign>
              ) : (
                String(Data.difference.__new || 'n/a').replace('__', ' ')
              )}
            </li>
          </ul>
        </Collapse>
      </li>
    </small>
  )
};

export default RecentActivityListGroupCollapse;
