import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { toast } from "react-toastify";
import ReactLoading from "react-loading";
import ListCollapse from './listCollapse';
import creService from "../../../../services/creService";
import { permissionsKey } from "../../../../services/authService";

const HistoryUpdates = (props) => {
    let jestData = props.jestData !== undefined ? props.jestData : false;
    const [history, setHistory] = useState(jestData ? jestData : {});
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        getWorkOrderHistory()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const getWorkOrderHistory = async () => {
        setLoading(true);
        const res = await creService.getWorkOrderHistory(props.workOrderId);
        if (res.data.status) {
            setHistory(res.data.data);
        } else {
            toast.error(res.data.message);
        }
        if (res.data.permissions) {
            localStorage.setItem(permissionsKey, JSON.stringify(res.data.permissions));
        }
        setLoading(false);
    }

    return (
        <React.Fragment>
            {isLoading && <div className="loadercenter"> <ReactLoading type={"cylon"} color={"grey"} /> </div>}
            {history && !isLoading &&
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Updated At</th>
                            <th scope="col">Changed by</th>
                            <th scope="col">Changes</th>
                        </tr>
                    </thead>
                    <tbody>
                        {history.length > 0 && history.map((history, index) => {
                            return (
                                <tr>
                                    <th scope="row">{index + 1}</th>
                                    <td>{moment(history.addedAt).format("MM/DD/YYYY, h:mm a")}</td>
                                    <td>{history.userDetails?.fullName}</td>
                                    <td>
                                        {Object.keys(history.data).map((dataField) => {
                                            return (
                                                <ListCollapse data={history.data[dataField]} field={dataField} />
                                            )
                                        })}
                                    </td>
                                </tr>
                            )
                        })}
                        {history.length <= 0 && (
                            <tr>
                                <td colSpan={4}>
                                    No Activity Yet
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            }
        </React.Fragment>
    );
}

export default HistoryUpdates;
