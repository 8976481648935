import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import moment from "moment";

const RecentWorkListCollapse = (props) => {
    const [collapse, setCollapse] = useState(false);

    const toggle = () => {
        setCollapse(!collapse)
    }

    const data = props.data;

    // Converting Dates
    if (data.title && (data.title.search(/date/i) !== -1 || data.field.search(/date/i) !== -1)) {
        data.difference.__old = data.difference.__old ? moment(new Date(data.difference.__old)).format('LL') : '';
        data.difference.__new = moment(new Date(data.difference.__new)).format('LL');
    }

    if (data.field === 'uploads') {
        return (<small>
            <li className="p-1 list-group-item align-items-center pointer text-muted border-0" onClick={toggle}>
                <div className="d-flex justify-content-between ">
                    In Uploads
                    <span className="badge badge-light ">{(collapse) ? '-' : '+'}</span>
                </div>
                <Collapse isOpen={collapse}>
                    <ul className="list-group">
                        {data?.difference?.deletedDocuments?.map((item) => {
                            return (
                                <li className="list-group-item list-group-item-light">
                                    <s>{item.originalname?.replace('__', ' ')}</s>
                                </li>
                            )
                        })}
                        {data?.difference?.newDocuments?.map((item) => {
                            return (
                                <li className="list-group-item">{item?.originalname?.replace('__', ' ')}</li>
                            )
                        })}

                    </ul>
                </Collapse>
            </li>
        </small>)
    } else if (['assignedUsers', 'userId'].includes(data.field)) {
        return (
            <small>
                <li className="p-1 list-group-item align-items-center pointer text-muted border-0" onClick={toggle}>
                    <div className="d-flex justify-content-between ">
                        In Assigned Users
                        <span className="badge badge-light ">{(collapse) ? '-' : '+'}</span>
                    </div>
                    <Collapse isOpen={collapse}>
                        <ul className="list-group">
                            <li className="list-group-item list-group-item-light">
                                {Array.isArray(data?.difference?.__old) && <s>{data?.difference?.__old?.map((item) => item?.fullName).join(', ')}</s>}
                                {!Array.isArray(data?.difference?.__old) && <s>{data?.difference?.__old?.fullName || props.data?.oldName}</s>}
                            </li>
                            <li className="list-group-item">
                                {Array.isArray(data?.difference?.__new) && data?.difference?.__new?.map((item) => item?.fullName).join(', ')}
                                {!Array.isArray(data?.difference?.__new) && (data?.difference?.__new?.fullName || props.data?.newName)}
                            </li>
                        </ul>
                    </Collapse>

                </li>
            </small>
        );
    }  else if (['vendorId'].includes(data.field)) {
        return (
            <small>
                <li className="p-1 list-group-item align-items-center pointer text-muted border-0" onClick={toggle}>
                    <div className="d-flex justify-content-between ">
                        In Vendor
                        <span className="badge badge-light ">{(collapse) ? '-' : '+'}</span>
                    </div>
                    <Collapse isOpen={collapse}>
                        <ul className="list-group">
                            <li className="list-group-item list-group-item-light">
                                {Array.isArray(data?.difference?.__old) && <s>{data?.difference?.__old?.map((item) => item?.fullName).join(', ')}</s>}
                                {!Array.isArray(data?.difference?.__old) && <s>{data?.difference?.__old?.fullName || props.data?.oldName}</s>}
                            </li>
                            <li className="list-group-item">
                                {Array.isArray(data?.difference?.__new) && data?.difference?.__new?.map((item) => item?.fullName).join(', ')}
                                {!Array.isArray(data?.difference?.__new) && (data?.difference?.__new?.fullName || props.data?.newName)}
                            </li>
                        </ul>
                    </Collapse>

                </li>
            </small>
        );
    } else if (['notificationsSent'].includes(data.field)) {
        return (
            <small>
                <li className="p-1 list-group-item align-items-center pointer text-muted border-0" onClick={toggle}>
                    <div className="d-flex justify-content-between ">
                        Notifications Sent
                        <span className="badge badge-light ">{(collapse) ? '-' : '+'}</span>
                    </div>
                    <Collapse isOpen={collapse}>
                        <ul className="list-group">
                            {data.difference.map((item) => {
                                return (
                                    <li className="list-group-item">Sent to {item.email} with subject {item.emailSubject} on {moment(new Date(item.date)).format('MM/DD/YYYY')}</li>
                                )
                            })}
                        </ul>
                    </Collapse>

                </li>
            </small>
        );
    } else {
        return (
            <small>
                <li className="p-1 list-group-item align-items-center pointer text-muted border-0" onClick={toggle}>
                    <div className="d-flex justify-content-between ">
                        In {data.title.charAt(0).toUpperCase() + data.title.slice(1)}
                        <span className="badge badge-light ">{(collapse) ? '-' : '+'}</span>
                    </div>
                    <Collapse isOpen={collapse}>
                        <ul className="list-group">
                            <li className="list-group-item list-group-item-light" style={{whiteSpace: 'pre-wrap'}}>
                                <s>{data.difference.__old}</s>
                            </li>
                            <li className="list-group-item" style={{whiteSpace: 'pre-wrap'}}>
                                {data.difference.__new}
                            </li>
                        </ul>
                    </Collapse>
                </li>
            </small>
        );
    }
}

export default RecentWorkListCollapse