import React, { Fragment, useState, useEffect } from "react";
import { Doughnut } from "react-chartjs-2";
import moment from 'moment'

import creService from "../../services/creService";
import Outstanding from "./OutstandingFines";
import { getColorData } from '../../services/colorService';
import SnapshotDataMenu from './snapshotDataMenu'
import Block from './block'

let type = 'Colors'
const colors = [
  "#ff4545",
  "#eb6c23",
  "#ffc533",
  "#65c92a",
  "#175dad",
  "#872ade",
  "#cc6299",
  "#613c22",
  "#000000"
]

const Colors = (props) => {

  const [allColorData, setAllColorData] = useState([]);

  const [data, setData] = useState(props.jestData !== undefined ? props.jestData.data : colors.reduce((ac, key) => ({ ...ac, [key]: { total: "" } }), {}));

  const [snapshotData, setSnapshotData] = useState()


  useEffect(() => {
    if (props.selectedDate) {
      const date = moment(new Date(props.selectedDate)).format('L')
      creService.getDashboardDataSnapshot(type, colors, date).then((res) => {
        setSnapshotData(res.data);
      })
    }
  }, [props.selectedDate])

  useEffect(() => {
    const colorData = getColorData();

    creService.getDashboardData(type).then((res) => {
      setData(
        colors.reduce((ac, key) => ({
          ...ac, [key]: {
            total: res.data[key]?.totalAllTime,
            label: colorData.find(el => el.colorCode === key)?.colorLabel || 'No Label'
          }
        }), {})
      )
    })

    setAllColorData(colorData)
  }, [])

  const doughnutData = {
    labels: colors.map(key => data[key].label),
    datasets: [{
      data: colors.map(key => data[key].total),
      backgroundColor: colors,
      hoverBackgroundColor: colors
    }]
  };

  function dashboardDetails(color) {
    props.history.push(`/dashboard/${type}/${color.slice(1)}`)
  }

  const chartDataExist = doughnutData.datasets[0].data.some(el => el)
  return (
    <Fragment>
      <div className="row">
        <div className="col-md-6">
          <div className="row">
            {allColorData.map((el) =>
            (
              <Fragment key={el.colorCode}>
                <Block
                  sub={el.colorCode}
                  data={data}
                  dashboardDetails={dashboardDetails}
                  selectedDate={props.selectedDate}
                  style={{ backgroundColor: el.colorCode, color: "#fff" }}
                  snapshotData={snapshotData}
                  snapshotOnClickHandler={(sub) => props.snapshotOnClickHandler(sub, type)}
                />
              </Fragment>
            )
            )}

          </div>
          <div className='d-flex justify-content-end'>
            <SnapshotDataMenu selectedDate={props.selectedDate} setSelectedDate={(date) => { props.setSelectedDate(date); setSnapshotData() }} />
          </div>
        </div>
        <div className="col-md-6">
          {!props.noOutstanding && <Outstanding history={props.history} />}
          {chartDataExist && <div className="pie-chart">
            <Doughnut
              data={doughnutData}
              options={{
                responsive: true,
                maintainAspectRatio: true,
                legend: {
                  display: true,
                  position: "bottom"
                },
              }}
              width={1000}
              height={900}
            />
          </div>
          }
        </div>
      </div>
    </Fragment>
  );
};

export default Colors;