import React, { Fragment, useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Link } from 'react-router-dom';
import ReactLoading from "react-loading";
import Guard from '../../common/guard';
import { confirmAlert } from 'react-confirm-alert'; // Import
import Search from 'antd/lib/input/Search';

import creService from "../../services/creService";
import { permissionsKey } from "../../services/authService";

const Vendors = ({ jestData = false }) => {
    const [searchString, setSearchString] = useState('');
    const [vendors, setVendors] = useState(jestData ? jestData : []);
    const [initialVendors, setInitialVendors] = useState([]);
    const [doneLoading, setDoneLoading] = useState(false);

    const getVendors = async () => {
        setDoneLoading(false);
        const res = await creService.getVendors();
        if (res.data.status) {
            setInitialVendors(res.data.vendors);
        } else {
            toast.error(res.data.message);
        }
        if (res.data.permissions) {
            localStorage.setItem(permissionsKey, JSON.stringify(res.data.permissions));
        }
        setDoneLoading(true);
    }

    const handleDelete = async (id, fullName) => {
        confirmAlert({
            title: 'Confirm to delete',
            message: `Do you want to remove ${fullName}?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        const res = await creService.deleteVendor(id);
                        if (res.data.status) {
                            toast.success(res.data.message);
                            getVendors();
                        } else {
                            toast.error(res.data.message);
                        }
                        if (res.data.permissions) {
                            localStorage.setItem(permissionsKey, JSON.stringify(res.data.permissions));
                        }
                    }
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    }

    useEffect(() => {
        getVendors();
    }, []);

    useEffect(() => {
        let result = initialVendors
        if(searchString) {
            result = initialVendors.filter(el=> Object.values(el).some(value=> value.toLocaleLowerCase().includes(searchString.toLocaleLowerCase())))
        }
        setVendors(result)
       
    }, [initialVendors, searchString])

    return (
        <Fragment>
            <div className="row violation-sec">
                <div className="col-xl-12 my-3">
                    <div className="card">
                        <div className="card-header d-flex align-items-center">
                            <h5>Vendors</h5>
                            <div className="card-header-right">
                                <Guard
                                    serviceName="violations"
                                    action="create_vendor"
                                    allow={() =>
                                        <Link to='/vendors/create'>
                                            <button className="btn btn-primary text-white">
                                                <i className="fa fa-plus text-white"></i>
                                                <span> Create</span>
                                            </button>
                                        </Link>
                                    }
                                />
                            </div>
                        </div>

                        <div className="card-body">
                            <div className="row">

                            </div>
                            <div className="my-3 bg-white text-dark">
                                <Guard
                                    serviceName="violations"
                                    action="list_vendors"
                                    allow={() => (
                                        <>
                                            <div className="d-flex justify-content-end">
                                                <div>
                                                    <Search
                                                        placeholder="Search vendor"
                                                        onChange={(e) => setSearchString(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <table className="table table-borderless">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">#</th>
                                                        <th scope="col">Full Name</th>
                                                        <th scope="col">Address</th>
                                                        <th scope="col">Phone</th>
                                                        <th scope="col">Email</th>
                                                        <th scope="col">Status</th>
                                                        <Guard
                                                            serviceName="violations"
                                                            action={['edit_vendor', 'delete_vendor']}
                                                            allow={() => <th scope="col">Actions</th>}
                                                        />
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {vendors.length > 0 && vendors.map((vendor, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <th scope="row">{index + 1}</th>
                                                                <td>{vendor.fullName}</td>
                                                                <td>{vendor.address}</td>
                                                                <td>{vendor.phone}</td>
                                                                <td>{vendor.email}</td>
                                                                <td>{vendor.status}</td>
                                                                <Guard
                                                                    serviceName="violations"
                                                                    action={['edit_vendor', 'delete_vendor']}
                                                                    allow={() => <td>
                                                                        <Guard
                                                                            serviceName="violations"
                                                                            action="edit_vendor"
                                                                            allow={() => <Link className="btn btn-info btn-xs mr-2" to={`/vendors/edit/${vendor._id}`}><i className="fa fa-pencil"></i></Link>}
                                                                        />
                                                                        <Guard
                                                                            serviceName="violations"
                                                                            action="delete_vendor"
                                                                            allow={() => <button className="btn btn-danger btn-xs" onClick={() => handleDelete(vendor._id, vendor.fullName)}><i className="fa fa-trash"></i></button>}
                                                                        />
                                                                    </td>}
                                                                />
                                                            </tr>
                                                        );
                                                    })}
                                                    {vendors.length <= 0 && doneLoading && (
                                                        <tr>
                                                            <td colSpan={6}>
                                                                No vendor available
                                                            </td>
                                                        </tr>
                                                    )}

                                                    {!doneLoading && (
                                                        <tr>
                                                            <td colSpan={6}>
                                                                <div className="loadercenter"> <ReactLoading type={"cylon"} color={"grey"} /> </div>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </>
                                    )}
                                    deny={() => <div className="alert alert-warning">Your don't have access to see the list of vendors.</div>}
                                />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Vendors;
