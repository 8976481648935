import React, { Component, Fragment } from "react";
import { ToastContainer, toast } from "react-toastify";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { Button, Modal, Input, DatePicker, Radio, Checkbox } from 'antd';

import './sweetalert2.css'

import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";

import creService from "../../services/creService";
import authService from "../../services/authService";

import moment from "moment";
import HistoryUpdates from '../buildings/partials/historyUpdates';
const { TextArea } = Input;

class Calendar extends Component {

    state = this.props.jestData !== undefined ?
        this.props.jestData.state : {
            calendarEvents: [],
            customEvent: {
                date: null,
                privateEvent: null,
                allDay: true,
                type: '',
                data: {
                    Description: ''
                }
            },
            isVisible: false,
            currentEvents: [],
            currentEventsTitle: ''
        };


    saveEvent = async (shouldRemove) => {
        try {
            if (!this.state.customEvent.type) {
                toast.warn('Title should not be empty')
                return
            }
            if (!this.state.customEvent.date) {
                toast.warn('Date should not be empty')
                return
            }
            const response = await creService.createEventCalendar({
                date: this.state.customEvent.date,
                privateEvent: this.state.customEvent.privateEvent ? true : false,
                type: this.state.customEvent.type,
                data: this.state.customEvent.data,
                id: this.state.customEvent.id,
                shouldRemove,
                allDay: this.state.customEvent.allDay,
            });

            toast.success(response.data.message)
            await this.datesRender()
        } catch (e) {
            toast.error(e.message)
        }
        this.setState({
            customEvent: {
                date: null,
                privateEvent: null,
                allDay: true,
                type: '',
                data: {
                    Description: ''
                },
            },
            isVisible: false
        })

    }


    updateCustomEvent = (other) => {
        const oldEvent = this.state.customEvent

        this.setState({
            customEvent: {
                ...oldEvent,
                ...other
            }
        })

    }

    datesRender = async (datesRender = this.state.datesRender) => {
        try {
            let response = await creService.getCalendar(moment(new Date(datesRender.view.activeStart)).toISOString(), moment(new Date(datesRender.view.activeEnd)).toISOString());
            this.setState({ calendarEvents: response.data, datesRender })
        } catch (e) {
            toast.error(e.message)
        }
    }

    getRetainedBadges = (retained) => {
        const allLabels = [];

        if (retained?.hearing?.isRetained) allLabels.push('Hearing Retained');
        if (retained?.cofc?.isRetained) allLabels.push('CofC Retained');
        if (retained?.dob?.isRetained) allLabels.push('DOB Retained');

        let labelHTML = '';
        allLabels.forEach((value) => {
            labelHTML += `<div class="table-retained-label">
                            ${value}
                        </div>`
        })

        if (!labelHTML) return '';

        return `<div class="d-flex justify-content-end">
                    ${labelHTML}
                </div>`
    }
    /**
     * when we click on event we are displaying event details
     */

    eventClick = (eventClick) => {
        const calendarEvent = eventClick.event._def.extendedProps;
        const title = eventClick.event.title
        if (calendarEvent.data[0].customEvent) {

            return this.setState({
                currentEvents: calendarEvent.data,
                currentEventsTitle: title
            })
        }


        let finalOutput = '';

        for (const event of calendarEvent.data) {
            let evenDetailsHTML = '';
            let retainedBadges = '';
            if (event.hasOwnProperty('data')) {
                evenDetailsHTML = Object.entries(event.data).map(v => {
                    let cellText = v[1];
                    if (cellText.indexOf('http://') >= 0 || cellText.indexOf('https://') >= 0) {
                        cellText = `<a target="_blank" className="badge badge-secondary" href="${cellText}">${v[0].replace(/Link/g, ' ')} <i className="fa fa-external-link"></i></a>`
                    }
                    return `<tr>
                           <td class="label">${v[0]}</td>
                           <td>${cellText}</td>
                        </tr>`;
                }).join('');
            }
            let addLink = '';
            if (event.hasOwnProperty('violation_id')) {
                let href = `/violation-details/${event.violation_id}`
                if (event.customDateKey?.includes('customCompliance')) {
                    href = `/custom-compliance/${event.customDateKey.split('/')[1]}/${event.violation_id}`
                }

                addLink = `<tr>
                                  <td class="label">Details</td>
                                  <td><a href='${href}' target='_blank'>Open in a New Window</a></td>
                              </tr>`
            }
            if (event?.violation?.clientAdditionalData?.retained) {
                const retained = event.violation.clientAdditionalData.retained;
                retainedBadges = this.getRetainedBadges(retained)
            }

            finalOutput += `
                    <div class="table ${event.isRetained ? 'table-bordered-retained' : 'table-bordered'} mb-2 p-2">
                        ${retainedBadges}
                        <table>
                            <tbody>
                                <tr>
                                    <td class="label">Address</td>
                                    <td>${event.fullAddress}</td>
                                </tr>
                                ${evenDetailsHTML}
                                ${addLink}
                            </tbody>
                        </table>
                     </div>
                `;

        }

        Swal.fire({
            title,
            html:
                `<div class="calendar-popup">
                    ${finalOutput}
                </div>`,

            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Close",
        });
    };

    render() {
        const { passDate } = (this.props.location && this.props.location.state) || {};

        return (
            <Fragment>
                <ToastContainer />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-9 calender-bg">
                            <div className="row calender-head">
                                <div className="col-md-12 d-flex justify-content-between ">
                                    <h5>Calendar</h5>
                                    <Button type="primary" onClick={() => this.setState({ isVisible: true })}>
                                        Add Event
                                    </Button>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <div className="animated fadeIn demo-app">
                                        <div className="demo-app-calendar" id="mycalendartest">
                                            {this.props.jestData !== undefined ? null :
                                                <FullCalendar
                                                    timeZone="UTC"
                                                    defaultView={passDate ? "timeGridDay" : "dayGridMonth"}
                                                    header={{
                                                        left: "prev title next",
                                                        center: "",
                                                        right: "timeGridDay,timeGridWeek,dayGridMonth,listWeek"
                                                    }}
                                                    eventDurationEditable={false}
                                                    editable={true}
                                                    droppable={false}
                                                    allDayDefault={true}
                                                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                                    ref={this.calendarComponentRef}
                                                    weekends={this.state.calendarWeekends}
                                                    events={this.state.calendarEvents}
                                                    eventDrop={this.drop}
                                                    eventReceive={this.eventReceive}
                                                    eventClick={this.eventClick}
                                                    datesRender={this.datesRender}
                                                    eventColor={'#f0f7fd'}
                                                    eventRender={(renderedEvent) => {
                                                        const eventData = renderedEvent.event._def.extendedProps.data;
                                                        const eventColor = renderedEvent.event._def.extendedProps.eventColor;

                                                        let retainedCount = 0;
                                                        const totalCount = eventData.length;
                                                        for (let extraData of eventData) {
                                                            if (extraData.isRetained) retainedCount++;
                                                        }

                                                        const retainedPercentage = retainedCount / totalCount * 100;
                                                        renderedEvent.el.style.background = `linear-gradient(360deg, ${eventColor} ${100 - retainedPercentage}%, #C36C00 ${retainedPercentage}%)`;
                                                        renderedEvent.el.style.color = `#fff`;
                                                    }}
                                                    gotoDate={moment(new Date(new Date() + 1)).utc(0).format('YYYY-MM-DD')}
                                                    defaultDate={passDate ? moment(new Date(passDate)).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD')}
                                                />}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="col-xl-3 recent-activity pr-0 pt-3 pb-3">
                            <HistoryUpdates />
                        </div>
                    </div>
                </div>
                <Modal

                    title={'Add Calendar Event'}
                    visible={this.state.isVisible}
                    footer={null}
                    onCancel={() => this.setState({ isVisible: false })}

                >
                    <div>
                        <Input
                            value={this.state.customEvent.type}
                            className='mt-2'
                            placeholder="Event Title"
                            addonBefore={'Title'}
                            onChange={(event) => this.updateCustomEvent({ type: event.target.value })}
                        />
                        <div className="mt-3 ant-input-wrapper ant-input-group">
                            <span className="ant-input-group-addon">Date</span>
                            <DatePicker
                                style={{ width: '77%' }}
                                value={this.state.customEvent.date}
                                showTime={!this.state.customEvent.allDay}
                                onChange={(date) => this.updateCustomEvent({ date })}
                            />
                            <Checkbox
                                style={{ width: '21%' }}
                                className="ml-2"
                                onChange={(event) => this.updateCustomEvent({ allDay: event.target.checked })}
                                checked={this.state.customEvent.allDay}
                            >
                                Full Day
                            </Checkbox>
                        </div>
                        <div className='mt-2' >

                            <div>Description:</div>
                            <TextArea
                                className="w-100 mt-1"
                                placeholder="Description"
                                value={this.state.customEvent.data.Description}
                                onChange={(event) => this.updateCustomEvent({ data: { Description: event.target.value } })}
                                autoSize={{ minRows: 3, maxRows: 5 }}
                            />
                        </div>
                        <div className='mt-2 d-flex' >

                            <div className="mr-3">Visibility: </div>

                            <Radio.Group onChange={(event) => this.updateCustomEvent({ privateEvent: event.target.value })} value={this.state.customEvent.privateEvent}>
                                <Radio value={0}>Visible to all</Radio>
                                <Radio value={1}>Private</Radio>
                            </Radio.Group>
                        </div>
                        <div className="mt-4 d-flex justify-content-between">
                            <div>
                                <Button type="primary" className="mr-2" onClick={() => this.setState({ isVisible: false })}>
                                    Cancel
                                </Button>
                                {this.state.customEvent.id &&
                                    <Button type="danger" ghost onClick={() => this.saveEvent(true)}>
                                        Remove Event
                                    </Button>
                                }
                            </div>
                            <Button type="primary" onClick={() => this.saveEvent(false)}>
                                Save
                            </Button>
                        </div>

                    </div>
                </Modal>
                <Modal
                    title={this.state.currentEventsTitle}
                    visible={this.state.currentEventsTitle}
                    footer={null}
                    onCancel={() => this.setState({ currentEventsTitle: '' })}
                >
                    {this.state.currentEvents.map(event =>
                        <div className=" mb-2 p-2 border " key={event._id}>

                            <table className="w-100">
                                <tbody>
                                    <tr >
                                        <td className='pr-4'><strong>Visibility</strong></td>
                                        <td>
                                            <div className="d-flex justify-content-between align-items-center">
                                                {event.private ? 'Private' : 'All Company'}
                                                <Button type="primary" ghost onClick={() => this.setState({
                                                    currentEventsTitle: '',
                                                    isVisible: true,
                                                    customEvent: {
                                                        date: moment(new Date(event.date)),
                                                        privateEvent: event.private ? 1 : 0,
                                                        type: event.type,
                                                        data: event.data,
                                                        id: event._id
                                                    },
                                                })}>
                                                    Edit
                                                </Button>
                                            </div>

                                        </td>

                                    </tr>
                                    <tr>
                                        <td className='pr-4'><strong>Date & Time</strong></td>
                                        <td >{moment(new Date(event.date)).format(`MMMM Do YYYY${event.allDay ? '' : ', h:mm:ss a'}`)}</td>
                                    </tr>
                                    {Object.keys(event.data).map(key =>
                                        <tr key={key}><td colSpan='2'><strong className='pr-2'>{key}:</strong> {event.data[key]}</td></tr>
                                    )}

                                </tbody>
                            </table>

                        </div>
                    )}
                    <div className="d-flex justify-content-center">
                        <Button type="primary" ghost onClick={() => this.setState({ currentEventsTitle: '' })}>
                            Cancel
                        </Button>
                    </div>

                </Modal>
                <div className="col-sm-12 listing">
                    <div className="card">

                        <div className="card-body">
                            <div className="row">
                                <div className="col-sm-12 col-xl-6"><h6 className="sub-title"><i className="fa fa-angle-double-right txt-primary m-r-10"></i> Add To Google Calendar</h6>
                                    <ol className="d-block">
                                        <li> On your computer, open <a href="https://calendar.google.com/" rel="noopener noreferrer" target="_blank">Google Calendar. <i className="fa fa-external-link txt-primary m-r-10" /></a></li>
                                        <li> On the left, next to "Other calendars," click Add (+) then <b>From URL</b>.</li>
                                        <li>Enter the calendar's address below in the field provided.
                                            <span className="badge badge-light">https://account.bcompliant.com/api/calendar/public/{this.props.jestData !== undefined ? this.props.jestData.state.id : authService.getCurrentUser()._id}</span></li>
                                        <li>Click Add calendar. The calendar will appear on the left side under "Other calendars."</li>
                                        <li>It might take up to 12 hours for changes to show in your Google Calendar.</li>

                                    </ol>
                                </div>
                                <div className="col-sm-12 col-xl-6"><h6 className="sub-title"><i className="fa fa-angle-double-right txt-primary m-r-10"></i> Add to Outlook</h6>
                                    <ol className="d-block">
                                        <li>Sign in to <a href="https://outlook.live.com/calendar" rel="noopener noreferrer" target="_blank"> Outlook on the web. <i className="fa fa-external-link txt-primary m-r-10" /></a></li>
                                        <li>At the bottom of the navigation pane, click the Calendar icon.</li>
                                        <li>On the toolbar, click Add calendar.</li>
                                        <li>Click "Subscribe from web", and in the Link to the calendar box, type or copy and paste the web address below.
                                            <span className="badge badge-light">https://account.bcompliant.com/api/calendar/public/{this.props.jestData !== undefined ? this.props.jestData.state.id : authService.getCurrentUser()._id}</span></li>
                                        <li>In the Calendar name box, enter a name for the linked calendar.</li>
                                        <li>Click Save.</li>
                                    </ol>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </Fragment>

        );
    }
}

export default Calendar;
