import UploadDocument from "../../../common/upload_document";
import { useEffect, useState } from "react"
import creApi from "../../../services/creService";

const Uploads = (props) => {
    const [hpdCategoryName, setHpdCategoryName] = useState("")

    useEffect(() => {
        if (props.category === "HPD" && props.sub === "Violations__Open") {
            const fetchData = async () => {
                const { data } = await creApi.getHPDViolationCodesDesc([props.tableRawData.ordernumber]);
                if (data.status && data.data.length > 0) {
                    setHpdCategoryName(data.data[0]?.name)
                }
            }
            fetchData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getLink = () => {
        if (hpdCategoryName === "Mold") {
            return 'https://bcompliant-public.s3.amazonaws.com/certificate-of-correction-mold.pdf';
        } else if (hpdCategoryName === "Lead") {
            return 'https://bcompliant-public.s3.amazonaws.com/certification-of-correction-lead.pdf';
        } else {
            return 'https://bcompliant-public.s3.amazonaws.com/certification-of-correction-of-violations.pdf';
        }
    }

    return (
        <div className="card">
            <div className="card-body">
                <div className="form-group row mb-0">
                    <label className="col-sm-3 col-form-label"><b>Upload Document</b></label>
                    <div className="col-sm-9">
                        <UploadDocument
                            setDataSource={props.setDataSource}
                            id={props.violationId}
                            violationId={props.violationId}
                            category={props.category}
                            sub={props.sub}
                            documentLink={getLink()}
                            setUploadedDocuments={props.setUploadedDocuments}
                            showDocumentTag={props.showDocumentTag}
                            source={props.source}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Uploads;