import React, {Fragment} from "react";
import Joi from "joi-browser";
import Form from "../common/forms/form";
import {ToastContainer, toast} from "react-toastify";
import logo from "../../assets/images/endless-logo.png";
import creService from "../services/creService";


class ForgotPassword extends Form {
    state = {
        data: { email: "" },
        errors: {},
        loading: false,
        expired: false,
        buttonClass: 'mt-3 mb-0 text-center'
    };

    schema = {
        email: Joi.string().max(50).required().email().label("Email")
    };


    doSubmit = async () => {
        try {
            this.setState({loading: 1});
            await creService.forgotPassword(this.state.data.email);
            toast.success("Reset Password Email Successfully Sent!")
            this.setState({buttonClass: 'd-none'});
            setTimeout(() => { window.location = "/"; }, 2000)

        } catch (ex) {
            if (ex.response && ex.response.status === 400) {
                const errors = { ...this.state.errors };
                errors.formError = ex.response.data;
                this.setState({ errors });
            }
        }
        this.setState({loading: 0});
    };


    render() {
        let submitButtonLabel = !this.state.loading ? "Send Reset Password Link" : "Sending...";

        return (
            <Fragment>
                <ToastContainer />
                <div className="page-wrapper">
                    <div className="auth-bg">
                        <div className="authentication-box">
                            <div className="text-center"><img src={logo} alt="" /></div>
                            <div className="card mt-4">
                                <div className="card-body">

                                    <div className="text-center">
                                        <h4>Forgot Password</h4>
                                        <h6>Enter your Email Address </h6>
                                    </div>

                                    <form className="theme-form" onSubmit={this.handleSubmit}>
                                        {this.renderFormError(this.state.errors.formError)}
                                        <div className="form-group">
                                            {this.renderInput("email", "Email")}
                                        </div>

                                        <div className={this.state.buttonClass}>
                                            {this.renderButton(submitButtonLabel)}
                                        </div>

                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>

        );
    }
}

export default ForgotPassword;
