import React, { Component, Fragment } from "react";
import creApi from "../../services/creService";
import { toast, ToastContainer } from "react-toastify";
import RecentActivity from "./partials/recentActivity";
import AddressHeaderBlock from "./partials/addressHeaderBlock";
import SmallCalendar from '../calendar/smallCalendar';
import LocalState from "../../common/localState";
import RetainedListView from './violationTable/retained/listView';
import RetainedGridView from './violationTable/retained/gridView';

class ViolationsTableRetained extends Component {

    state = this.props.jestData !== undefined ?
        this.props.jestData.state : {
            data: [],
            isLoading: true,
            viewType: LocalState.getViewTypeSetting('violationsTableComponent', 'list'),
        };

    getViolationData = async (bin, category, sub, sortBy, sortType) => {
        try {
            let filter = [];
            if (this.props.location.state !== undefined) filter = this.props.location.state?.ids || [];

            let propertyInfo = await creApi.getPropertyInfo(bin);
            if (propertyInfo.data?.status === false) {
                this.props.history.push('/my-buildings')
                toast.error(propertyInfo.data?.message)
            } else {
                this.setState({ addressData: propertyInfo.data });

                const violationsData = await creApi.getViolationData(bin, category, sub, filter, sortBy, sortType);

                this.setState({
                    isLoading: false,
                    data: violationsData.data,
                });
            }
        } catch (e) {
            toast.error(e.message)
        }
    }

    componentDidMount() {
        // id is bin
        this.getViolationData(this.props.match.params.id, this.props.match.params.category, this.props.match.params.sub, this.props.match.params.sortBy);
    }

    handleBack = () => {
        const prev = localStorage.getItem("prev-location");
        if (prev) {
            this.props.history.push(prev);
        } else {
            this.props.history.push(`/dashboard/Open%20Violations/BcompliantRetained`);
        }
    };

    onViolationClick = (violation) => {
        this.props.history.push(`/violation-details/${violation._id}/documents`)
    };

    switchToggle = async () => {
        const type = this.state.viewType === 'grid' ? 'list' : 'grid';
        await creApi.updateUserPageView({
            page: 'violationsTableComponent',
            type,
        });
        this.setState({ viewType: type })
    }

    render() {
        const { data, viewType } = this.state;
        return (
            <Fragment>
                <ToastContainer />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-9 violation-detail">
                            <div className="row position-relative">
                                <div className="col-md-12 violation-head">
                                    {this.state.addressData &&
                                        <AddressHeaderBlock data={this.state.addressData} sub={this.props.match.params.sub} handleGoBack={this.handleBack} handleCategoryUrl={this.handleCategoryUrl} />
                                    }
                                </div>
                                <div className="filter-sec d-flex pt-4 ">
                                    <div className="onhover-dropdown sort-by-side-btn">
                                        <button className={`btn button ${viewType === 'grid' ? 'active' : ''}`} onClick={() => this.switchToggle()}><i className="fa fa-th-large"></i></button>
                                        <button className={`btn button ${viewType === 'list' ? 'active' : ''}`} onClick={() => this.switchToggle()}><i className="fa fa-bars"></i></button>
                                    </div>
                                </div>
                            </div>

                            {viewType === 'list' && <RetainedListView data={data} onViolationClick={this.onViolationClick} />}
                            {viewType === 'grid' && <RetainedGridView data={data} onViolationClick={this.onViolationClick} />}

                        </div>
                        <div className="col-xl-3 recent-activity pr-0">
                            <div className="pt-3 pb-3">
                                {this.props.jestData !== undefined ? null : <SmallCalendar />}
                            </div>
                            <RecentActivity
                                type="buildingCategroySubActivity"
                                history={this.props.history}
                                match={this.props.match}
                                bin={this.props.match.params.id}
                                category={this.props.match.params.category}
                                sub={this.props.match.params.sub}
                            />
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}
export default ViolationsTableRetained;
