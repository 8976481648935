
import moment from 'moment';

import { exportViolationEvents } from '../../../services/exportExcel';
import creService from '../../../services/creService';

const getData = (columns, dataSource, doNotShowHide, source) => {
  let columnsWithForCSV = columns.filter(el => el.key !== 'action' && (doNotShowHide ? !el.hide : true)).map((element) => {

    return {
      header: element.description,
      key: element.description,
      isCustom: element.isCustom,
      keyReal: element.key,
    };
  });
  columnsWithForCSV = [
    ...columnsWithForCSV,
  ];
  if (!source) {
    columnsWithForCSV.push({ header: 'Retained', key: 'retained' })
  }

  const csvData = dataSource.map((row) => {
    const objForCsv = {};
    for (let column of columns) {
      if (
        column.isCustom &&
        column.data?.type !== 'text' &&
        column.data?.type !== 'number' &&
        column.data?.type !== 'date' &&
        column.data?.type !== 'checkBox' &&
        column.data?.bcType !== 'priority'
      ) {
        if (column.data.bcType === 'colors') {
          const found = column.data?.data?.find?.(
            (colorLabel) => colorLabel.id === row[column.key]
          );
          objForCsv[column.description] = found?.text || '';
        } else if (column.data.bcType === 'users') {
          const found = column.subUsers?.find(
            (el) => el._id === row[column.key]
          );
          objForCsv[column.description] = found?.fullName || '';
        } else if (column.data.bcType === 'vendors') {
          const found = column.vendors?.find(
            (el) => el._id === row[column.key]
          );
          objForCsv[column.description] = found?.fullName || '';
        } else if (column.data?.bcType === 'chat') {
          objForCsv[column.description] = row.chats.length;
        } else if (column.data?.bcType === 'notes') {
          objForCsv[column.description] = row.notes.length;
        } else if (column.data?.bcType === 'uploads') {
          objForCsv[column.description] = row.documents.length;
        } else if (column.data?.bcType === 'files') {
          objForCsv[column.description] = row.documents.filter(
            (doc) => doc.fieldId === column.key
          ).length;
        } else {
          objForCsv[column.description] = '';
        }
      } else {
        objForCsv[column.description] = String(row[column.key] || '');
        if (/date/i.test(column.key) && row[column.key] && moment(row[column.key]).isValid()) {
          let dateJS = new Date(row[column.key])
          if (dateJS.getTimezoneOffset() > 0) {
            dateJS.setHours(dateJS.getHours() + new Date().getTimezoneOffset() / 60);
          }
          objForCsv[column.description] = moment(dateJS).format('MM/DD/YYYY');
        }
      }
    }
    if (!source) {
      objForCsv.retained = row.retained ? 'yes' : 'no';
    }

    return objForCsv;
  });
  return { columnsWithForCSV, csvData }
}

const exportCsv = async (columns, dataSource, category, sub, source) => {
  const { columnsWithForCSV, csvData } = getData(columns, dataSource, false, source)
  if (category && sub) {
    await exportViolationEvents(
      columnsWithForCSV,
      csvData,
      `${category}-${sub}`
    );
  } else {
    await exportViolationEvents(columnsWithForCSV, csvData, `csv`);
  }
};


const exportPDF = async (columns, dataSource, category, sub, source) => {
  const { columnsWithForCSV, csvData } = getData(columns, dataSource, true, source)


  const { data } = await creService.generatePDF(columnsWithForCSV, csvData, `${category}/${sub}`);
  if (data.status) {
    window.open(data.pdfKey, '_blank');
  } else {
    throw new Error(data.message)
  }
};



export {
  exportCsv,
  exportPDF
};
