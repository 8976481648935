import React, { useState } from 'react';
import { FolderOpenOutlined } from '@ant-design/icons';
import { Badge, Button, Modal } from 'antd';

import ViolationFiles from './ViolationFiles';

//Button Component to trigger Uploads Modal
const ViolationFilesButton = ({ record, fieldId, category, source, setDataSource }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
    
  const [uploadedDocuments, setUploadedDocuments] = useState(record.documents);
  const badgeCount =
    uploadedDocuments?.filter((el) => el?.fieldId === fieldId).length || 0;

  return (
    <div className="text-center">
      <Button onClick={() => setIsModalVisible(true)} type="text">
        <Badge count={badgeCount}>
          {badgeCount > 0 && <FolderOpenOutlined style={{ fontSize: 22 }} />}
          {badgeCount <= 0 && <FolderOpenOutlined style={{ fontSize: 22 }} />}
        </Badge>
      </Button>
      <Modal
        title="Files"
        visible={isModalVisible}
        footer={null}
        onCancel={() => setIsModalVisible(false)}
        width={1000}
      >
        <ViolationFiles
          violationId={record._id}
          category={category}
          fieldId={fieldId}
          setUploadedDocuments={setUploadedDocuments}
          source={source}
          setDataSource={setDataSource}
        />
      </Modal>
    </div>
  );
};

export default ViolationFilesButton;
