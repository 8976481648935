import React, { Fragment, useState, useEffect } from "react";
import { toast } from "react-toastify";
import ReactLoading from "react-loading";
import { useHistory } from 'react-router-dom';

import LocalState from "../../common/localState";
import creService from "../../services/creService";
import './dashboard.css'
import creApi from "../../services/creService";
import DashboardItem from './dashboardItem'
import { settingsKey } from "../../services/authService";

const Dashboard = () => {
  const [options] = useState(JSON.parse(localStorage.getItem(settingsKey)))
  const [loading, setLoading] = useState(false);
  const [sortField, setSortField] = useState({});
  const [customCompliances, setCustomCompliances] = useState([]);
  const [posibleStatuses, setPosibleStatuses] = useState([]);
  const [viewType, setViewType] = useState(LocalState.getViewTypeSetting('dashboardCompliance', 'bubble'))
  const [currentCustomCompliance, setCurrentCustomCompliance] = useState(null)
  const history = useHistory();
  const loadConfig = async () => {
    try {
      setLoading(true)
      const response = await creService.getCustomComplianceDashboard();
      if (response.data.status) {

        setCustomCompliances(response.data.customCompliances);
        if (!currentCustomCompliance) {
          setCurrentCustomCompliance(response.data.customCompliances[0])
        }
        setPosibleStatuses(response.data.allStatuses)
      } else {
        toast.error(response.data.message)
      }


    } catch (err) {

    }
    setLoading(false)
  }

  const switchToggle = async () => {
    const type = viewType === 'bubble' ? 'list' : 'bubble'
    await creApi.updateUserPageView({
      page: 'dashboardCompliance',
      type,
    });
    setViewType(type)
  }
  const handleSort = async (field) => {
    const direction = typeof sortField[field] != 'undefined' && sortField[field] === -1 ? 1 : -1;
    const sort = {
      [field]: direction,
    }
    setSortField(sort);

    setCustomCompliances(customCompliances.sort((a, b) => {
      if (direction === 1) {
        return (a.customCompliances[field] || 0) - (b.customCompliances[field] || 0);
      }
      return (b.customCompliances[field] || 0) - (a.customCompliances[field] || 0);

    }));
  }

  const handleRowClick = (row) => {
    history.push(row);
  }

  const handleMenuClick = async (compliance) => {
    setCurrentCustomCompliance(compliance)

  }

  useEffect(() => {
    loadConfig();
    // eslint-disable-next-line    
  }, [])


  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 my-building-wrapper">
            <div className="card">
              <div className="card-header d-flex justify-content-between pl-1 pr-1">
                <h5>Dashboard</h5>
                <div id="btnContainer">
                  <button className={`btn ${viewType === 'bubble' ? 'active' : ''}`} onClick={switchToggle}><i className="fa fa-th-large"></i></button>
                  <button className={`btn ${viewType === 'list' ? 'active' : ''}`} onClick={switchToggle}><i className="fa fa-bars"></i></button>
                </div>
              </div>
              {viewType !== 'bubble' ? (
                <div className="my-building">
                  <div className="card-body pl-1 pr-1">
                    <div className="table-responsive Container rotateX">
                      <table className="table table-borderless overflow-auto Content">
                        <thead>
                          <tr>
                            <th scope="col" className='pt-1 pb-1 pr-1 compliance-header' >Compliance </th>
                            {
                              Object.keys(posibleStatuses).map(status =>
                                <th scope="col" className="text-center p-1 text-nowrap" key={status}>
                                  {status}
                                  <i
                                    className={`fa ml-1 fa-sort-numeric-${sortField[status] === -1 ? 'desc' : 'asc'}`}
                                    onClick={() => handleSort(status)}
                                    style={{ cursor: "pointer" }}
                                  />
                                </th>
                              )
                            }
                          </tr>
                        </thead>
                        <tbody>
                          {
                            customCompliances.filter(el => !options.customCompliance?.[el.name.trim()]?.hide).map((item, index) => {
                              let name = item.displayName ? item.displayName : item.name.replaceAll('_', ' ')

                              return (
                                <tr key={index}>
                                  <td style={{ textAlign: 'left', cursor: 'pointer' }} className="pl-0" onClick={() => handleRowClick(`/custom-compliance/${item.name}`)}>
                                    <span className="text-dark">
                                      {name}
                                    </span>
                                  </td>
                                  {
                                    Object.keys(posibleStatuses).map(status =>
                                      <td
                                        key={status}
                                        className="text-center"
                                        onClick={() => item.customCompliances[status] && handleRowClick(`/custom-compliance/${item.name}?status=${status}`)}>
                                        <span
                                          className='badge'
                                          style={{
                                            color: 'white',
                                            background: posibleStatuses[status]
                                          }}
                                        >
                                          {item.customCompliances[status] || 0}
                                        </span>
                                      </td>
                                    )
                                  }

                                </tr>
                              );
                            })}
                          {customCompliances.length <= 0 && !loading && (
                            <tr>
                              <td colSpan={'100%'} className="pl-0">
                                No compliance available
                              </td>
                            </tr>
                          )}
                          {loading && (
                            <tr>
                              <td colSpan={'100%'} className="pl-0">
                                <div className="loadercenter"> <ReactLoading type={"cylon"} color={"grey"} /> </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>) :

                <div className="card-body">

                  <ul className="d-flex w-100 flex-wrap custom_wrapper ">
                    {customCompliances.length > 0 &&
                      customCompliances
                        .filter(el => {
                          return !options.customCompliance?.[el.name.trim()]?.hide}
                          )
                        .map((item) => {
                          let name = item.displayName ? item.displayName : item.name.replaceAll('_', ' ')
                          return (
                            <li
                              key={`custom_menu_${item.name}`}
                              className={`${currentCustomCompliance?.name === item.name ? "active" : ""}`}
                              onClick={() => handleMenuClick(item)}
                            >
                              {name}
                            </li>
                          )
                        }
                        )}
                  </ul>
                  {currentCustomCompliance && <DashboardItem
                    item={currentCustomCompliance}
                    posibleStatuses={posibleStatuses}
                    handleRowClick={handleRowClick}

                  />}
                </div>


              }

            </div>
          </div>
        </div>
      </div>
    </Fragment >
  )
};

export default Dashboard
