import React from 'react';
import { Button, Menu, Dropdown, Checkbox } from 'antd';
import { EyeOutlined } from '@ant-design/icons';

import './HideColumnDropDown.css'

const HideColumnDropdown = ({ columns, handleToggleVisibility, placement, size }) => {

  const menu = (
    <Menu
      className='hide-dropdown'
      items={
        columns &&
        columns.filter(column => column.key !== 'action' && column.key !== 'address' && !column.linkFunc).map((col) => ({
          key: col.key || col.name,
          label: (col.description || col.name ||
            (col.title && col.title.props.defaultValue)) && (
              <div onClick={() => handleToggleVisibility(col.name ? col.name : col.key)} className={`${!col.isCustom ? 'no-custom ' : ''} menu-item-all`} >
                <div className="d-flex justify-content-between">
                  {col.displayName || col.description || (col.name ? col.name.replaceAll('_', ' ') : col.name) || (col.title && col.title.props.defaultValue)}
                  <Checkbox
                    className="ml-2"
                    checked={!col.hide}

                  />
                </div>
              </div>
            ),
        }))
      }
    />
  );
  return (
    <Dropdown
      overlay={menu}
      placement={placement}
      className="hide-btn-wrapper"
      arrow
      trigger="click"
    >
      <Button size={size} className={`${columns.some(el => el.hide) ? 'active-hide' : ''}`}>
        <EyeOutlined />
        Hide
      </Button>
    </Dropdown>
  );
};

export default HideColumnDropdown;
