import SignatureCanvas from 'react-signature-canvas'
import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from "react-router";
import creService from "../../services/creService";
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import ReactLoading from 'react-loading';
import './form.css'
import background from "./final.jpg";
import { Switch, Input } from 'antd';

const questions1 = [
    {
        question: 'A child 5 years or younger lives in or routinely spends 10 or more hours each week in my home or apartment.',
        questionSP: 'Un niño de 5 años o menos vive o pasa habitualmente 10 horas o más a la semana en mi casa o apartamento.',
        answers: [],
        id: 'Child Under 6',
        clearID: ['No Child Under 11'],
    },
    {
        question: 'A child 10 years or younger lives in my home and:',
        questionSP: 'Un niño de 10 años o menos vive en mi casa y:',
        id: 'Child Under 11',
        clearID: ['No Child Under 11'],
        // addID: ['Child Under 6'],
        answerRequired: true,
        answers: [
            {
                text: 'Window guards are installed in all windows as required.',
                textSP: 'Se instalan protecciones en todas las ventanas según sea necesario.',
                id: 'Under 11 - Guards Installed'
            },
            {
                text: 'Window guards need repair.',
                textSP: 'Las protecciones de las ventanas necesitan reparación.',
                id: 'Under 11 - Need Repair'
            },
            {
                text: 'Window guards are NOT installed in all windows as required.',
                textSP: 'NO se instalan protecciones en todas las ventanas, como es preceptivo.',
                id: 'Under 11 - No Guards'
            },
        ]
    },
    {
        question: 'No child 10 years or younger lives in my home',
        questionSP: 'En mi casa no vive ningún niño de 10 años o menos',
        id: 'No Child Under 11',
        clearID: ['Child Under 6', 'Child Under 11'],
        answers: [
            {
                text: 'I want window guards installed anyway.',
                textSP: 'De todas formas, quiero que instalen protecciones en las ventanas.',
                id: 'Over 10 - Want Guards'
            },
            {
                text: 'I have window guards, but they need repair.',
                textSP: 'Tengo protectores de ventanas, pero necesitan reparación.',
                id: 'Over 10 - Need Repair'
            }
        ]
    },
    {
        question: 'Please provide your signature',
        questionSP: 'Indique su firma',
        id: 'signature',
        signature: true,
        required: true,
    }
]

const questions2 = [
    {
        question: 'Yes, I want stove covers or replacement stove knob covers for my stove.',
        questionSP: 'Sí, quiero fundas de estufa o fundas de pomo de estufa de repuesto para mi estufa.',
        answers: [],
        id: 'Need Knob Covers',
    },
    {
        question: 'Yes, I want permanent stove safety knobs with integrated locking mechanisms for my stove.',
        questionSP: 'Sí, quiero pomos de seguridad permanentes con mecanismos de bloqueo integrados para mi estufa.',
        answers: [],
        id: 'Need Lockable Knobs',
    },
    {
        question: 'There is a child under age six residing in my apartment',
        questionSP: 'Hay un niño menor de seis años residiendo en mi apartamento',
        answers: [],
        id: 'Child Under 6',
    },
]

const requireCheck = (answers) => {
    const wasChecked = Object.keys(answers).some(key => answers[key].checked)

    return !wasChecked || Object.keys(answers).some(key => {
        if (!answers[key].text) {
            const question = questions1.find(el => el.question === key)
            if (question?.required) {
                return true
            }
            if (question?.answerRequired && answers[key].checked) {
                return true
            }
        }

        return false

    })
}

const Form = (props) => {


    const [spanish, setSpanish] = useState(false)
    const [answers, setAnswers] = useState([...questions1, ...questions2].reduce((ac, a) => {
        ac[a.question] = {
            text: '',
            id: a.id,
            clearID: a.clearID,
            checked: false
        };
        return ac
    }, {}))
    const sigRef = useRef();
    const [loading, setLoading] = useState(true)
    const [record, setRecord] = useState(null)
    const [formNumber, setFormNumber] = useState(0)
    const [code, setCode] = useState()
    const history = useHistory();
    useEffect(() => {
        if (formNumber !== 0) {
            updateAnswers()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formNumber])

    const updateAnswers = async () => {
        const payload = Object.keys(answers).reduce((ac, key) => {
            const question = [...questions1, ...questions2].find(el => el.question === key)
            if (question.signature) {
                if (answers[key].text) {
                    ac.signature = answers[key].text
                }
            } else if (answers[key].checked) {
                ac.answers[question.id] = 'Yes'
                if (answers[key].text) {

                    for (let el of question.answers) {
                        let answer = ''
                        if (el.text === answers[key].text) {
                            answer = 'Yes'
                        } else {
                            answer = 'No'
                        }
                        ac.answers[el.id] = answer
                    }

                }
            } else {
                ac.answers[question.id] = 'No'
            }


            return ac
        }, {
            answers: {}
        })

        return await creService.submitSafetyMailingPrintedResponse(props.match.params.id, payload)

    }
    const submit = async () => {

        if (requireCheck(answers)) {
            toast.warn('Please complite the form');
        } else {

            if (props.match.params.id === 'test') {
                setRecord({ ...record, responseData: true })
                setLoading(false)
                return
            }


            const result = await updateAnswers()
            if (result.data.status) {
                toast.success('Response sent');
                intilize()
            } else {
                toast.error('Response did not sent, Something go wrong');
            }
        }
    }
    const clearSignature = () => {

        sigRef.current.clear();

        const key = questions1.find(el => el.signature).question
        setAnswers({ ...answers, [key]: { ...answers[key], text: '' } })
    }

    const handleSignatureEnd = () => {
        const key = questions1.find(el => el.signature).question
        setAnswers({ ...answers, [key]: { ...answers[key], text: sigRef.current.toDataURL() } })
    }
    const intilize = async () => {
        setLoading(true)
        if (!props.match.params.id && !code) {
         
            setLoading(false)
            return
        }

        if (props.match.params.id === 'test') {
            setRecord({ accountNumber: 'Test', aptNumber: 'Test' })
            setLoading(false)
            return
        }
        const sendCode = code || props.match.params.id
        const result = await creService.getSafetyMailingPrintedRow(sendCode)
        if (result.data.status && result.data.printedRow) {
            setRecord(result.data.printedRow)
            history.push(`/smf/${sendCode}`);
        } else {
            toast.error(spanish ? 'Código de arrendatario no válido' : 'Invalid Tenant Code');
           
            setCode()
        }
        setLoading(false)

    }
    const setAnswersResult = (answer, { question }, checked = true) => {
        const id = answers[question].id

        setAnswers(Object.keys(answers).reduce((ac, quest) => {
            if (quest === question) {
                ac[quest] = { ...answers[quest], text: answer, checked }
            } else if (answers[quest].clearID?.includes(id)) {
                ac[quest] = { ...answers[quest], text: '', checked: false }
            } else if (answers[quest].addID?.includes(id)) {
                ac[quest] = { ...answers[quest], text: checked ? answers[quest].text : '', checked }
            } else {
                ac[quest] = answers[quest]
            }
            return ac
        }, {}))
    }

    useEffect(() => {
        intilize()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className='d-flex align-items-center justify-content-center flex-column' style={{
            height: '100vh',
            backgroundImage: `url(${background})`,
            // backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center'
        }}>

            <div className={`wrapper`}>

                <div className={`blue_top`}>

                    <h1>{spanish ? 'Aviso de Seguridad Respuesta Rápida' : 'Safety Notice Fast Response'}  </h1>

                    {record ? (formNumber ?
                        <h2>{spanish ? 'Pintura con Plomo Descascarada' : 'Peeling Lead Paint'} &amp;
                            {spanish ? 'Protectores de Ventanas' : 'Window Guards'}</h2>
                        :
                        <h2>{spanish ? 'Cubiertas para Perillas de la Estufa' : 'Stove Knob Covers'}</h2>
                    ) : <h2>{spanish ? 'Bienvenido' : 'Welcome'}</h2>
                    }
                </div>

                {
                    loading ?
                        <div className='d-flex justify-content-center'>
                            <ReactLoading type={'cylon'} color={'grey'} />
                        </div>
                        :
                        <>
                            {record ? (
                                <div className='questions'>
                                    <div className='d-flex mb-2'>
                                        <strong className='mr-1'>Language: </strong> <Switch checkedChildren="spanish" unCheckedChildren="english" onChange={() => setSpanish(!spanish)} />
                                    </div>
                                    <div>

                                        <strong>{(spanish ? 'Dirección del edificio' : 'Building Address')}: </strong>

                                        {record.building?.display_address ? record.building?.display_address : record.building?.address}<br />
                                        <strong>{(spanish ? 'Número de apartamento' : 'Apartment Number')}: </strong> {record.aptNumber}<br />
                                        <strong>{(spanish ? 'Número de cuenta' : 'Account Number')}: </strong> {record.accountNumber}</div>
                                    <hr />
                                    {record.responseData?.isCompleted ? <div> {formNumber ?
                                        (spanish ? '¡Gracias! Respuesta guardada con éxito.' : 'Thank you! Response successfully saved.') :
                                        (spanish ? 'Response already sent' : 'Respuesta ya enviada')}
                                    </div> :
                                        <>
                                            {(formNumber ? questions1 : questions2).map((el, i) => {

                                                return <div className="card m-1" key={el.question}>
                                                    <div className='d-flex align-items-center'>
                                                        <div>
                                                            {!el.signature && < input type="checkbox" className='checkbox-safe' checked={answers[el.question].checked} onChange={(event) =>
                                                                setAnswersResult(
                                                                    '',
                                                                    el,
                                                                    event.target.checked
                                                                )
                                                            } />}
                                                        </div>
                                                        <strong className='ml-1'>{spanish ? el.questionSP : el.question}</strong >
                                                    </div>

                                                    <div className="card-body p-1">

                                                        {el.signature ?
                                                            <>
                                                                <SignatureCanvas
                                                                    ref={sigRef}
                                                                    canvasProps={{ width: 300, height: 100, className: 'sigCanvas' }}
                                                                    onEnd={handleSignatureEnd}
                                                                />
                                                                <br />
                                                                <button onClick={clearSignature}> {(spanish ? 'Claro' : 'Clear')}</button>
                                                            </> :
                                                            el.answers.map(answer =>
                                                                <div className="form-check" key={el.question + answer.text}>
                                                                    <label
                                                                        className="form-check-label"
                                                                        htmlFor="list"

                                                                    >
                                                                        <input
                                                                            className="form-check-input  mt-1"
                                                                            type="radio"
                                                                            id={el.question + answer}
                                                                            checked={answers[el.question].text === answer.text}
                                                                            name={el.question}
                                                                            onChange={() => setAnswersResult(answer.text, el)}
                                                                        />
                                                                        {spanish ? answer.textSP : answer.text}

                                                                    </label>
                                                                </div>)}
                                                    </div>

                                                </div>
                                            })}
                                            {formNumber !== 0 && <button
                                                type="submit"
                                                className="btn btn-safe mt-3 mr-2"
                                                onClick={() => setFormNumber(formNumber - 1)}
                                            >
                                                {spanish ? 'Volver' : 'Back'}
                                            </button>
                                            }
                                            <button
                                                type="submit"
                                                className={`btn btn-safe mt-3`}
                                                onClick={() => formNumber === 0 ? setFormNumber(formNumber + 1) : submit()}
                                                disabled={formNumber === 0 ? false : requireCheck(answers)}
                                            >
                                                {formNumber === 0 ? (spanish ? 'Siguiente' : 'Next') : (spanish ? 'Enviar' : 'Submit')}
                                            </button>

                                        </>
                                    }
                                </div>) : (

                                <div className='questions nocode'>

                                    <div className='d-flex mb-2'>
                                        <strong className='mr-1'>Language: </strong> <Switch checkedChildren="spanish" unCheckedChildren="english" onChange={() => setSpanish(!spanish)} />
                                    </div>
                                    <hr />
                                    <Input value={code} onChange={(e) => setCode(e.target.value)} addonBefore={`${spanish ? 'Código' : 'Code'} (123)`} placeholder={spanish ? 'Código de respuesta del inquilino' : 'Tenant Response Code'} />
                                    <button
                                        type="submit"
                                        className={`btn btn-safe mt-3`}
                                        onClick={() => intilize()}

                                    >
                                        {(spanish ? 'Enviar' : 'Submit')}
                                    </button>
                                </div>)

                            }

                        </>
                }

            </div >
            <ToastContainer />
        </div >
    )
}

export default Form;
