import React, { Fragment, useState, useEffect } from 'react';
import { toast } from "react-toastify";
import creService from '../../services/creService';
import ReactLoading from 'react-loading';
import { Doughnut } from 'react-chartjs-2';

const colors = [
  '#f3a53c',
  '#db4057',
  '#09ba70',
  '#c7c2c2',
  '#5a3e3e',
  '#9455c9',
  '#e5187d',
  '#e09a9a',
  '#c6b44b',
  '#f0bf0e',
  '#242323',
  '#9d233f',
  '#067041',
  '#ee47b4',
  '#7244cd',
  '#97cd1c',
  '#53b1e1',
  '#6f6868',
  '#784b3d',
  '#ec5826',
  '#e58383',
  '#fa91f0',
  '#4b91f3',
  '#71337d',
  '#93acbb',
  '#5d9db9',
  '#1e4c8d',
  '#3eb8b3',
  '#4246da',
  '#370c89',
  '#0e97d4',
  '#cdbff7',
  '#336ac7',
  '#9daed0',
  '#e464b6',
  '#bab7cf',
  '#a8896a',
  '#95d3e4',
  '#154349',
  '#aa7063',
];

const type = 'Compliance';
const complianceDue = ['DOB Boiler','DEP Boiler','HPD Registration', 'CAT1', 'CAT5', 'LL11', 'LL84', 'LL152', 'LEAD', 'MOLD', 'LL87','Water_Tank', 'PBS']
const buildingCompliance = [
  'DOB_Boiler', 'DEP_Boiler', 'HPD_Registration',
  'Elevator', 'Facade', 'Gas', 'Benchmarking', 'Energy_Audit', 
  
]
const Compliance = (props) => {
  const [data, setData] = useState(props.jestData !== undefined ? props.jestData.data : 
    [...complianceDue, ...buildingCompliance].reduce((ac, a) => {
      ac[a] = ''
      return ac
    }, {})
  );

  const [customData, setCustomData] = useState([]);

  useEffect(() => {
    creService.getDashboardData(type).then((res) => {
      const buildingCompl = buildingCompliance.reduce((ac, a) => {
        ac[a] = res.data[a]?.totalAllTime
        return ac
      }, {})
      const complianceD = complianceDue.reduce((ac, a) => {
        ac[a] = res.data[a]?.totalAllTime
        return ac
      }, {})
      setData({
        ...buildingCompl,
        ...complianceD
      })
    })
    loadCustomCompliance()

  }, [])

  const loadCustomCompliance = async () => {
    try {

      const response = await creService.getCustomComplianceDashboard();
      if (response.data.status) {
        setCustomData(response.data.customCompliances)
      } else {
        toast.error(response.data.message)
      }

    } catch (err) {
      toast.error(err.message)
    }
  }


  const doughnutDataComplianceDue = {
    labels: complianceDue,
    datasets: [{
      data: complianceDue.map(el => data[el]),
      backgroundColor: colors,
      hoverBackgroundColor: colors
    }]
  };
 

  const doughnutDataOther = {
    labels: buildingCompliance,
    datasets: [{
      data: buildingCompliance.map(el => data[el]),
      backgroundColor: colors,
      hoverBackgroundColor: colors
    }]
  };

  const doughnutCustom = {
    labels: customData.filter(el => el.totalAllTime).map(item => item.displayName ? item.displayName : item.name.replaceAll('_', ' ')),
    datasets: [{
      data: customData.filter(el => el.totalAllTime).map(item => Object.keys(item.customCompliances).reduce((ac, a) => ac + item.customCompliances[a], 0)),
      backgroundColor: colors,
      hoverBackgroundColor: colors
    }]
  };




  function dashboardDetails(sub) {
    if (!complianceDue.includes(sub)) {
      props.history.push(`/dashboard/${type}/${sub}`)
    } else {
      customComplianceDetails(sub);
    }
  }
  function customComplianceDetails(sub) {
    if (sub === 'LEAD') {
      sub = 'Lead-HPD';
    }
    if (sub === 'MOLD') {
      sub = 'Mold-HPD';
    }
    props.history.push(`/compliance-requirements/${sub}`)
  }

  function block(sub) {
    return data[sub] === '' ? <div align='center'><ReactLoading type={'cylon'} color={'grey'} /></div>
      :
      (data[sub] > 0) ? <div className='pointer' > {data[sub]}</div>
        : data[sub]
  }

  return (
    <Fragment>
      <h3 className='row ml-2'>Building Compliance</h3>
      <div className='row mb-4'>
        <div className='col-md-6'>

          <div className='row'>
            {data && Object.keys(data).filter(key => !complianceDue.includes(key)).map((key) =>
              <div key={key} className='col-6 col-md-4' onClick={() => data[key] && dashboardDetails(key)}>
                <div className='card'>
                  <div className='card-body'>
                    <div className='media-body text-center'>
                      <h3>{block(key)}</h3>
                      <p>{key.replace(/_/ig, ' ')}</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='col-md-5'>
          <div className='pie-chart'>
            <Doughnut
              data={doughnutDataOther}
              options={{
                responsive: true,
                maintainAspectRatio: true,
                legend: {
                  display: true,
                  position: 'bottom'
                },
              }}
              width={1000}
              height={900}
            />
          </div>
        </div>
      </div>
      <h3 className='ml-2 mt-4 row '> Compliance Due</h3>
      <div className='row'>
        <div className='col-md-6'>


          <div className='row'>
            {data && Object.keys(data).filter(key => complianceDue.includes(key)).map((key) =>
              <div key={key} className='col-6 col-md-4' onClick={() => data[key] && dashboardDetails(key)}>
                <div className='card'>
                  <div className='card-body'>
                    <div className='media-body text-center'>
                      <h3>{block(key)}</h3>
                      <p>{key.replace(/_/ig, ' ')}</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

        </div>
        <div className='col-md-5 '>

          <div className='pie-chart'>
            <Doughnut
              data={doughnutDataComplianceDue}
              options={{
                responsive: true,
                maintainAspectRatio: true,
                legend: {
                  display: true,
                  position: 'bottom'
                },
              }}
              width={1000}
              height={900}
            />
          </div>
        </div>
      </div>

      {customData.length !== 0 && <>
        <h3 className='row ml-2 mt-4 '> Compliance Portal</h3>
        <div className='row'>
          <div className='col-md-6'>

            <div className='row'>

              {customData.map((item => {

                if (item.totalAllTime === 0) return null
                return (<div className='col-6 col-md-4' key={item._id} onClick={() => props.history.push(`/custom-compliance/${item.name}`)} >
                  <div className='card card-hover'>
                    <div className='card-body'>
                      <div className='media-body text-center'>
                        <h3>
                          <div className='pointer' >
                            {item.totalAllTime}
                          </div>
                        </h3>
                        <p>{item.displayName ? item.displayName : item.name.replaceAll('_', ' ')}</p>
                      </div>
                    </div>
                  </div>
                </div>)
              }))}
            </div>
          </div>
          <div className='col-md-5 '>
            <div className='pie-chart'>
              <Doughnut
                data={doughnutCustom}
                options={{
                  responsive: true,
                  maintainAspectRatio: true,
                  legend: {
                    display: true,
                    position: 'bottom'
                  },
                }}
                width={1000}
                height={900}
              />
            </div>
          </div>
        </div>
      </>
      }
    </Fragment>
  );
};

export default Compliance;
