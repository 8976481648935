import React, { Fragment, useState, useEffect, useRef } from "react";
import creService from "../../services/creService";
import { toast } from "react-toastify";
import { Link, useHistory } from 'react-router-dom';
import { useParams } from "react-router";
import { permissionsKey } from "../../services/authService";
import ReactLoading from "react-loading";
import { Typeahead } from 'react-bootstrap-typeahead';

const PortfolioCreate = ({ jestData = false }) => {

    const history = useHistory();
    const params = useParams();
    const portfolioId = params?.id;
    const formRef = useRef();
    const editView = portfolioId ? true : false;

    const [isCreating, setIsCreating] = useState(false);
    const [usersLoading, setUsersLoading] = useState(false);
    const [isBuildingLoading, setBuildingLoading] = useState(true);
    const [allBuildingsBins, setAllBuildingsBins] = useState([]);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState(jestData.formData ? jestData.formData : {});
    // Used for Edit
    const [originalData, setOriginalData] = useState(jestData.formData ? jestData.formData : {});
    const [buildings, setBuildings] = useState(jestData.buildings ? jestData.buildings : []);
    const [subUsers, setSubUsers] = useState(jestData.subUsers ? jestData.subUsers : []);

    const fetchBuildings = async () => {
        setBuildingLoading(true);
        try {
            const buildingRes = await creService.getUserBuildingList();
            setBuildings(buildingRes.data.data);
            const binsArray = buildingRes.data.data.map(function (obj) {
                return obj.bin;
            });
            setAllBuildingsBins(binsArray)
            setBuildingLoading(false)
        } catch (_err) {
            toast.error(_err.message);
            setBuildingLoading(false)
        }
    }

    const loadSubUsers = async () => {
        setUsersLoading(true)
        const subUsersRes = await creService.getSubUsers();
        setSubUsers(subUsersRes.data.data);
        if (subUsersRes.data.permissions) {
            localStorage.setItem(permissionsKey, JSON.stringify(subUsersRes.data.permissions));
        }
        setUsersLoading(false)
    }

    const handleInput = (event) => {
        const { name, value } = event.target;
        setFormData(state => ({
            ...state,
            [name]: value.length > 0 ? value : null
        }));
    };

    const handleUnAndCheckAll = (state) => {
        if (state) {
            setFormData({ ...formData, bins: allBuildingsBins })
        } else {
            setFormData({ ...formData, bins: [] });
        }
    }

    const handleBuildingCheck = (isChecked, bin) => {
        if (formData?.bins) {
            setFormData((state) => {
                let bins = [...formData.bins];
                if (isChecked) {
                    return ({
                        ...state,
                        bins: [...bins, bin]
                    })
                } else {
                    return ({
                        ...state,
                        bins: bins.filter(b => b !== bin)
                    })
                }

            });
        } else {
            setFormData({ ...formData, bins: [bin] })
        }
    }

    const handleDefaultCheck = (isChecked) => {
        setFormData((state) => ({
            ...state,
            isDefault: isChecked,
        }));
    }

    const handleSubUserChange = (subUserIdsEnabled) => {

        setSubUsers(subUsers.map(user => {
            user.selectedPortfolios = subUserIdsEnabled.includes(user._id) ? [portfolioId] : []
            return user
        }))
        setFormData(state => ({
            ...state,
            subUserIdsEnabled,
        }));
    }

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        setIsCreating(true);
        let payload = {
            ...formData,
        };
        try {
            delete payload.__v;
            delete payload.updatedAt;
            delete payload.addedAt;
            delete payload.masterClientId;
            delete payload.subUserIdsEnabledInfo;
            const { data } = editView ? await creService.updatePortfolio(payload) : await creService.createPortfolio({ payload });
            if (data.status) {
                toast.success(data.message);
                history.push('/portfolio');
            } else {
                if (data.errors) {
                    data.errors.map((err) => toast.error(err));
                } else {
                    toast.error(data.message);
                }
            }
            setIsCreating(false);
        } catch (_err) {
            toast.error(_err.message || 'Something went wrong');
            setIsCreating(false);
        }
    }

    const getPortfolioData = async (id) => {
        setLoading(true);
        const res = await creService.editPortfolio(id);
        if (res.data.status) {
            setFormData(res.data.data);
            setOriginalData(res.data.data);
        } else {
            toast.error(res.data.message);
        }
        setLoading(false);
    }

    useEffect(() => {
        loadSubUsers();
        fetchBuildings();
        if (editView) {
            getPortfolioData(portfolioId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [portfolioId]);


    return (
        <Fragment>
            <div className="row violation-sec">
                <div className="col-xl-12 my-3">
                    <div className="card">
                        <div className="card-header">
                            <Link to='/portfolio'>
                                <button className="btn btn-light btn-lg align-self-center back-btn mx-2" type="button">
                                    <i className="fa fa-angle-left" />
                                </button>
                            </Link>
                            <h5>{editView ? 'Edit' : 'Create'} Portfolio {editView ? `${originalData.portfolioName}` : ''}</h5>
                        </div>
                        {loading ? (<ReactLoading type={"cylon"} color={"grey"} />) : (
                            <div className="card-body">
                                <form ref={formRef} onSubmit={(e) => handleOnSubmit(e)} >
                                    <div className="row">
                                        <div className="form-group col-12">
                                            <label>Portfolio Name <sup className="text-danger">*</sup></label>
                                            <input type="text" name="portfolioName" className="form-control" onChange={(event) => handleInput(event)} value={formData.portfolioName} />

                                        </div>

                                        <div className="form-group col-12">
                                            <label>Buildings</label>
                                            <div className="table-responsive">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">
                                                                <input type='checkbox' defaultChecked={false} onChange={(e) => handleUnAndCheckAll(e.target.checked)} />
                                                            </th>
                                                            <th scope="col">Address</th>
                                                            <th scope="col">BIN</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {isBuildingLoading && <tr>
                                                            <td colSpan="5">
                                                                <div align="center">
                                                                    <ReactLoading type={"cylon"} color={"grey"} />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        }
                                                        {!isBuildingLoading && buildings.map((building, key) => (
                                                            <tr key={key}>
                                                                <td>
                                                                    <input type='checkbox' id={`bin${building.bin}`} className='building' checked={formData.bins?.includes(building.bin) ? true : false} onChange={(e) => handleBuildingCheck(e.target.checked, building.bin)} />
                                                                </td>
                                                                <td>{building.display_address ? building.display_address : building.address}</td>
                                                                <td>{building.bin}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                        <div className="form-group col-12 col-6">
                                            <label>Add Sub Users</label>
                                            <Typeahead
                                                id="assignedToUsers"
                                                multiple
                                                disabled={usersLoading}
                                                placeholder="Users"
                                                onChange={(selected) => {
                                                    handleSubUserChange(selected.map((b) => b._id));
                                                }}
                                                selected={subUsers?.filter((user) => user.selectedPortfolios?.includes(portfolioId))}
                                                labelKey={(option) => {
                                                    if (option.fullName) {
                                                        return option.fullName;
                                                    }
                                                    return option._id;
                                                }}
                                                options={subUsers}
                                            />
                                        </div>

                                        <div className="form-group col-12 col-6">
                                            <div className="form-check mt-3">
                                                <label className="form-check-label" htmlFor="isDefaultPortfolio">
                                                    <input className="form-check-input checkbox-large" type="checkbox" name="isDefault" id="isDefaultPortfolio" onChange={(e) => handleDefaultCheck(e.target.checked)} checked={formData.isDefault} />
                                                    Make Default Portfolio
                                                </label>
                                                <p className="text-hint">
                                                    <small>
                                                        <i className="fa fa-info-circle"></i>
                                                        This will make this portfolio the default portfolio for the client. If you have already set a default portfolio for the client, this will unset the previous default portfolio.
                                                    </small>
                                                </p>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="form-group col-12 col-6">
                                            <button type="submit" className="btn btn-primary" disabled={isCreating}>{editView ? 'Update' : 'Add'}</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        )
                        }
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default PortfolioCreate;
