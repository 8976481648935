import React from 'react';
import moment from 'moment';
import { ColumnItem } from './styled';

const Item = ({ item, onClick }) => {

    const makeAddress = (building) => {
        const address = building?.display_address ? building?.display_address : building?.address;
        return address?.split(',')[0];
    }

    return (
        <ColumnItem>
            <div className="d-flex justify-content-between align-items-center">
                <div className="workOrderId" onClick={onClick}>#{item.workOrderId}</div>
                <div className="dueDate">{item.dueDate && moment(item.dueDate).isValid() && moment(item.dueDate).format('MM/DD/YYYY')}</div>
            </div>
            <div className="title">
                {item.title}
            </div>
            <div className="d-flex justify-content-between align-items-center">
                <div className="address">{makeAddress(item.building)}</div>
                {item.priority && item.priority === 'Low' && <span className="badge badge-info">L</span>}
                {item.priority && item.priority === 'Medium' && <span className="badge badge-primary">M</span>}
                {item.priority && item.priority === 'High' && <span className="badge badge-danger">H</span>}
            </div>
        </ColumnItem>
    );

}

export default Item;
