import React from 'react';
import { permissionsKey } from "../../services/authService";
import { Redirect } from "react-router-dom";

const RedirectWrapper = (path) => {
    const permissions = JSON.parse(localStorage.getItem(permissionsKey) || '{}');
    if (permissions?.violations?.includes('all')) {
        return (
            <Redirect to={path} />
        )
    }
    if (permissions?.workOrders?.includes('list')) {
        return (
            <Redirect to="/work-orders" />
        )
    }
    if (permissions?.workOrders?.includes('create')) {
        return (
            <Redirect to="/work-orders/create" />
        )
    }
    return (
        <Redirect to="/chats" />
    )
}

export default RedirectWrapper;