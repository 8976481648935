import React from 'react';
import { Link } from "react-router-dom";


const ElevatorBasedCompilance = ({ value,  addedDevices= []  }) => {

    const elevators = value.elevators?.filter(el => !addedDevices.includes(el.deviceNumber))
    if (!elevators?.length) {
        return null
    }

    return (
        <>
            <tr>
                <td colSpan="3">
                    <i className="fa fa-building"></i> {value.fullAddress}
                </td>
            </tr>

            {value.elevators?.map((elevatorValue, key) => {

                return <tr key={key}>
                    <td>
                        <div>Device# <Link to={`/violation-details/${elevatorValue.violationID}`}>{elevatorValue.deviceNumber}</Link></div>

                    </td>
                    <td>{elevatorValue.dueDate}</td>

                </tr>
            })
            }


        </>
    );
};

export default ElevatorBasedCompilance;
