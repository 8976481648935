import React, { useState } from 'react';
import { CirclePicker } from 'react-color';
import { Popover } from 'antd';
import { toast } from 'react-toastify';

import './ColorPickerEdit.css';

const colors = [
  '#f3a53c',
  '#db4057',
  '#09ba70',
  '#c7c2c2',
  '#5a3e3e',
  '#9455c9',
  '#e5187d',
  '#e09a9a',
  '#c6b44b',
  '#f0bf0e',
  '#242323',
  '#9d233f',
  '#067041',
  '#ee47b4',
  '#7244cd',
  '#97cd1c',
  '#53b1e1',
  '#6f6868',
  '#784b3d',
  '#ec5826',
  '#e58383',
  '#fa91f0',
  '#4b91f3',
  '#71337d',
  '#93acbb',
  '#5d9db9',
  '#1e4c8d',
  '#3eb8b3',
  '#4246da',
  '#370c89',
  '#0e97d4',
  '#cdbff7',
  '#336ac7',
  '#9daed0',
  '#e464b6',
  '#bab7cf',
  '#a8896a',
  '#95d3e4',
  '#154349',
  '#aa7063',
];

const ColorPickerEdit = ({ colorLabelsProp, onLabelChange, hasAccess }) => {
  const [colorLabels, setColorLabels] = useState(colorLabelsProp);
  const [newLabelNumber, setNewLabelNumber] = useState(1);
  const handleSubmit = () => {
    onLabelChange(colorLabels);
  };

  const removeLabel = (index) => {
    setColorLabels(colorLabels.filter((el, i) => i !== index));
  };

  const setColorLabelText = (i, newText) => {
    setColorLabels(
      colorLabels.map((label, index) =>
        index === i ? { ...label, text: newText } : label
      )
    );
  };

  const setColorLabelColor = (i, newColor) => {
    setColorLabels(
      colorLabels.map((label, index) =>
        index === i ? { ...label, color: newColor } : label
      )
    );
  };

  const createNewLabel = () => {
    const usedColors = colorLabels.map((obj) => obj.color);
    const color = colors.find((color) => !usedColors.includes(color));

    if (color) {
      setColorLabels([
        ...colorLabels,
        { color, text: `Label${newLabelNumber}` },
      ]);
      setNewLabelNumber(newLabelNumber + 1);
    } else {
      toast.info('unable to create label: [all colors in use]');
    }
  };

  return (
    <div className="d-flex align-items-center justify-content-center flex-column">
      {colorLabels.map((colorObj, index) => (
        <div
          key={colorObj.color + index}
          className="d-flex align-items-center justify-content-start color-menu-item-edit"
        >
          {(hasAccess(['edit_colors']) && colorObj.id) || (hasAccess(['create_colors']) && !colorObj.id) ? (
            <>
              <Popover
                trigger="click"
                content={
                  <div>
                    <CirclePicker
                      color={colorObj.color}
                      colors={colors.filter((colorStr) =>
                        colorObj.color === colorStr
                          ? true
                          : !colorLabels.some((clr) => clr.color === colorStr)
                      )}
                      onChange={(newColor) => {
                        setColorLabelColor(index, newColor.hex);
                      }}
                    />
                  </div>
                }
              >
                <div
                  className="color-menu-item-edit-icon"
                  style={{
                    background: colorObj.color,
                  }}
                ></div>
              </Popover>

              <input
                placeholder="Color label"
                value={colorObj.text}
                onChange={(event) =>
                  setColorLabelText(index, event.target.value)
                }
              />
            </>
          ) : (
            <>
              <div
                className="color-menu-item-edit-icon"
                style={{
                  background: colorObj.color,
                }}
              ></div>
              <div className="color-menu-item-edit-text">{colorObj.text}</div>
            </>
          )}

          {hasAccess(['delete_colors']) && (
            <i
              className="fa fa-times btn-remove"
              onClick={(newColor) => {
                removeLabel(index);
              }}
            ></i>
          )}
        </div>
      ))}
      {hasAccess(['create_colors']) && (
        <div
          onClick={createNewLabel}
          className="d-flex align-items-center justify-content-center color-menu-item-edit color-menu-item-edit-new"
        >
          + New label
        </div>
      )}
      <div className="d-flex align-items-center justify-content-center edit-block">
        <button
          className="btn btn-light color-menu-edit-btn"
          onClick={handleSubmit}
        >
          Apply
        </button>
      </div>
    </div>
  );
};

export default ColorPickerEdit;
