import React, { useState } from 'react';
import moment from 'moment';
import { Popover, PopoverBody, ListGroup, ListGroupItem, Badge } from 'reactstrap';

const CalendarEventPopover = (props) => {

    const [isOpen, setIsOpen] = useState(false);

    const date = moment(props.day);

    const toggle = () => setIsOpen((prev) => !prev);

    
console.log("props ",JSON.stringify(props))
    return (
        <span className="custom-number">
            <button id={`day_popover_${date.unix()}`} onClick={toggle} className={`btn btn-xs`}>
                <i className="fa fa-info-circle" />
            </button>
            <Popover placement="bottom" trigger="hover" isOpen={isOpen} target={`day_popover_${date.unix()}`} toggle={toggle}>
                {props.events && props.events.length <= 0 && <PopoverBody>
                    <span>This date is free.</span>
                </PopoverBody>}
                {props.events && props.events.length > 0 && <ListGroup>
                    {props.events && props.events.map((record) => <ListGroupItem>
                        {record.fullName ? record.fullName : 'No Staff Assigned'}
                        <Badge className="ml-4">{record.WOCount}</Badge>
                    </ListGroupItem>)}
                </ListGroup>}
            </Popover>
        </span>
    )
}

export default CalendarEventPopover;

