import React from 'react';
import moment from 'moment';
import { Typeahead } from 'react-bootstrap-typeahead';
import { DateRangePicker } from 'react-date-range';

const AdvanceFilter = ({
    handleChange,
    buildings,
    selectedFilterData,
    workOrderCategories,
    priority,
    allWorkOrderStatus,
    vendors,
    subUsers,
    onDateSelect,
    dateRange,
    handleInputChange,
    viewType,
    showDateRangePicker,
    setShowDateRangePicker,
}) => {

    return (
        <div className="col-12">
            <div className="row mt-3">
                <div className="col-4">
                    <Typeahead
                        id="filter_bins"
                        multiple
                        placeholder="Buildings"
                        onChange={(selected) => {
                            handleChange('bins', selected.map((b) => b.bin));
                        }}
                        selected={buildings.filter((b) => selectedFilterData.bins.includes(b.bin))}
                        labelKey={(option) => {
                            if (option.display_address) {
                                return option.display_address;
                            }
                            return option.address;
                        }}
                        options={buildings}
                    />
                </div>
                <div className="col-2">
                    <input
                        type="text"
                        name="aptNumber"
                        placeholder="Apt #"
                        value={selectedFilterData.aptNumber}
                        className="form-control"
                        onChange={(e) => {
                            handleChange('aptNumber', e.target.value);
                        }}
                    />
                </div>
                <div className="col-2">
                    <Typeahead
                        id="filter_category"
                        multiple
                        placeholder="Category"
                        onChange={(selected) => {
                            handleChange('categories', selected.map((b) => b));
                        }}
                        selected={workOrderCategories.filter((b) => selectedFilterData.categories.includes(b))}
                        labelKey={(option) => {
                            return option;
                        }}
                        options={workOrderCategories}
                    />
                </div>
                <div className="col-2">
                    <Typeahead
                        id="filter_priority"
                        multiple
                        placeholder="Priority"
                        onChange={(selected) => {
                            handleChange('priority', selected.map((b) => b));
                        }}
                        selected={priority.filter((b) => selectedFilterData.priority.includes(b))}
                        labelKey={(option) => {
                            return option;
                        }}
                        options={priority}
                    />
                </div>
                <div className="col-2">
                    <Typeahead
                        id="filter_status"
                        multiple
                        placeholder="Status"
                        onChange={(selected) => {
                            handleChange('status', selected.map((b) => b));
                        }}
                        selected={allWorkOrderStatus.filter((b) => selectedFilterData.status.includes(b))}
                        labelKey={(option) => {
                            return option;
                        }}
                        options={allWorkOrderStatus}
                    />
                </div>
                <div className="col-4 mt-3">
                    <Typeahead
                        id="filter_vendors"
                        multiple
                        placeholder="Vendors"
                        onChange={(selected) => {
                            handleChange('vendors', selected.map((b) => b._id));
                        }}
                        selected={vendors?.filter((b) => selectedFilterData.vendors.includes(b._id))}
                        labelKey={(option) => {
                            if (option.fullName) {
                                return option.fullName;
                            }
                            return option._id;
                        }}
                        options={vendors}
                    />
                </div>
                <div className="col-3 mt-3">
                    <Typeahead
                        id="filter_users"
                        multiple
                        placeholder="Users"
                        onChange={(selected) => {
                            handleChange('subUsers', selected.map((b) => b._id));
                        }}
                        selected={subUsers?.filter((b) => selectedFilterData.subUsers.includes(b._id))}
                        labelKey={(option) => {
                            if (option.fullName) {
                                return option.fullName;
                            }
                            return option._id;
                        }}
                        options={subUsers}
                    />
                </div>
                {viewType !== 'calendar' &&
                    <div className="col-2 mt-3">
                        <button style={{ textAlign: 'left' }} className="form-control form-control-btn" onClick={() => setShowDateRangePicker((prev) => !prev)}>
                            {dateRange.startDate && dateRange.endDate && <span className="small">{moment(dateRange.startDate).format('MM/DD/YYYY')} - {moment(dateRange.endDate).format('MM/DD/YYYY')}</span>}
                            {!dateRange.startDate && !dateRange.endDate && <span>Due Date</span>}
                        </button>
                        {showDateRangePicker &&
                            <div className="date-range-picker-popout">
                                <DateRangePicker
                                    onChange={onDateSelect}
                                    // maxDate={new Date()}
                                    ranges={[dateRange]}
                                    showSelectionPreview={true}
                                    moveRangeOnFirstSelection={false}
                                    months={2}
                                    direction="horizontal"
                                />
                            </div>
                        }
                    </div>
                }
                <div className="col-3 mt-3 pt-2">
                    <label>
                        <input type="checkbox" name="uploads" value="yes" checked={selectedFilterData.uploads === 'yes'} onChange={(e) => handleInputChange(e)} /> Uploads
                    </label>
                    <label className="ml-2">
                        <input type="checkbox" name="signature" value="yes" checked={selectedFilterData.signature === 'yes'} onChange={(e) => handleInputChange(e)} /> Signature
                    </label>
                    <label className="ml-2">
                        <input type="checkbox" name="autoRule" value="yes" checked={selectedFilterData.autoRule === 'yes'} onChange={(e) => handleInputChange(e)} /> Auto Rule
                    </label>
                </div>
            </div>
        </div>
    );
}

export default AdvanceFilter;
