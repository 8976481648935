import React, { Component, Fragment } from 'react';
import creApi from '../../services/creService';
import { toast, ToastContainer } from 'react-toastify';
import ReactLoading from 'react-loading';
// import HistoryUpdates from "../buildings/partials/historyUpdates";
// import SmallCalendar from '../calendar/smallCalendar';
import LocalState from '../../common/localState';
import TableViolation from '../../common/table-violation/tableViolation';
import { generateViolationTableData, uniquekeys } from '../../services/helperService';

export default class ViolationsTabDetails extends Component {
  state =
    this.props.jestData !== undefined
      ? this.props.jestData.state
      : {
          doneLoading: false,
          dataForTable: [],
          tableData: [{}],
          isAllViolationsLoading: true,
          allViolationsData: {},
          filter: {},
          sub: '',
          category: '',
          viewType: LocalState.getViewTypeSetting(
            'violationsTabDetails',
            'list'
          ),
          paginationData: {
            pageSize: 10,
            itemsCount: 0,
          },
          currentPage: new URLSearchParams(this.props.location.search).get(
            'page'
          )
            ? parseInt(
                new URLSearchParams(this.props.location.search).get('page')
              )
            : 1,
          exportViolationData: {},
        };

  onPageChange = (page) => {
    this.setState({ currentPage: page });
    this.props.history.push({
      search: `?page=${page}`,
    });
    this.fetchAllViolations(page);
  };

  getData = async (category, sub) => {
    try {
      let apiData;

      apiData = await creApi.getViolationsAllTabDetails(category, sub);
      this.setState({
        doneLoading: true,
        tableData: apiData.data,
      });
    } catch (e) {
      toast.error(e.message);
    }
  };

  componentDidMount() {
    this.getData(this.props.match.params.category, this.props.match.params.sub);
    this.fetchAllViolations();
  }

  onClick = (bin, category, sub) => {
    localStorage.setItem('prev-location', this.props.history.location.pathname);
    this.props.history.push(`/violations/table/${bin}/${category}/${sub}`);
  };

  handleBack = () => {
    //this.props.history.push(`/violations/all/${this.state.addressData.bin}`)
    // this.props.history.goBack();
    this.props.history.push(`/violations-tab`);
  };

  switchToggle = async () => {
    const type = this.state.viewType === 'grid' ? 'list' : 'grid';
    await creApi.updateUserPageView({
      page: 'violationsTabDetails',
      type,
    });
    this.setState({ viewType: type });
  };

  fetchAllViolations = async (page = this.state.currentPage) => {
    this.setState({
      isAllViolationsLoading: true,
    });
    try {
      let sub = this.props.match.params.sub;
      let category = this.props.match.params.category;
      let sortBy = this.props.match.params.sortBy || '';
      let sortType = parseInt(this.props.match.params.sortType) || '';

      if (category === 'Complaints') {
        category = sub;
        sub = 'Complaints__Open';
      } else if (category === 'Violations') {
        category = sub;
        sub = 'Hearings__Open';
      } else {
        if (sub === 'SWO') {
          sub = 'Stop_Work_Order__Open';
        } else if (sub === 'Violation Orders') {
          sub = 'Violation_Orders';
        } else if (sub === 'Criminal Summons') {
          sub = 'Criminal_Summons';
        } else if (sub === 'VO') {
          sub = 'Vacate_Order__Open';
        } else {
          sub = `${sub}__Open`;
        }
      }

      try {
        const { data } = await creApi.getViolationsForAllBuilding(
          category,
          sub,
          [], // filter
          sortBy, // sortBy
          sortType // sortType
        );
        let dataForTable = {};

        let violationData =
          data.violationsData[sub]?.map((r) => r.violationData) || [];

        let orgTableData = data.violationsData[sub];

        let codes = violationData.map((item) => {
          if (item?.ordernumber) {
            return item.ordernumber.replace(' *', '');
          }
          return null;
        });

        if (codes.length) {
          const { data } = await creApi.getHPDViolationCodesDesc(codes);

          if (data.data) {
            orgTableData.map((item) => {
              data.data.forEach((data) => {
                if (data.code.includes(item.violationData?.ordernumber)) {
                  item.violationData.codeColor = data.colorCode;
                  item.violationData.codeName = data.name;
                }
              });
              return item;
            });
          }
        }

        if (
          'displayName' in data &&
          'violationsData' in data &&
          data.displayName.length
        ) {

          dataForTable = generateViolationTableData(
            data.displayName,
            data.violationsData[sub],
            false,
            sub,
            data.dollarAmountFields
          );
        }
        this.setState({
          isAllViolationsLoading: false,
          allViolationsData: data,
          sub: sub,
          category: category,
          exportViolationData: data,
          dataForTable,
          dataForTableLoading: true,
        });
      } catch (e) {
        toast.error(e.message);
      }
    } catch (e) {
      toast.error(e.message);
      this.setState({
        isAllViolationsLoading: false,
      });
    }
  };

  render() {
    const { viewType } = this.state;
    return (
      <Fragment>
        <ToastContainer />
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12 violations-detail">
              <div className="row violations-head">
                <div className="d-flex">
                  <button
                    className="btn btn-light btn-xs align-self-center back-btn"
                    onClick={this.handleBack}
                    type="button"
                  >
                    <i className="fa fa-angle-left"></i>
                  </button>
                </div>
                <div className="col-md-9 d-flex">
                  <h5 className="align-self-center">
                    {this.props.match.params.category +
                      ' / ' +
                      this.props.match.params.sub}{' '}
                  </h5>
                </div>
                <div className="col-3 col-md-2 align-self-center text-right">
                  <div id="btnContainer">
                    <button
                      className={`btn ${viewType === 'grid' ? 'active' : ''}`}
                      onClick={() => this.switchToggle()}
                    >
                      <i className="fa fa-th-large"></i>
                    </button>
                    <button
                      className={`btn ${viewType === 'list' ? 'active' : ''}`}
                      onClick={() => this.switchToggle()}
                    >
                      <i className="fa fa-bars"></i>
                    </button>
                  </div>
                </div>
              </div>
              {viewType === 'grid' && (
                <div className="card custom-violation-grid mb-0 pb-0">
                  <div className="card-body">
                    <div className="row">
                      {!this.state.doneLoading ? (
                        <tr>
                          <td colSpan="5">
                            <div align="center">
                              {' '}
                              <ReactLoading
                                type={'cylon'}
                                color={'grey'}
                              />{' '}
                            </div>
                          </td>
                        </tr>
                      ) : (
                        this.state.tableData?.map((value, key) => {
                          // Adding Overwrite for ECB/Corrections__Open
                          if (
                            !value.match.sub &&
                            value.match.category === 'ECB'
                          ) {
                            value.match.sub = 'Corrections__Open';
                          }
                          return (
                            <div className="col-6 col-xl-3 col-md-4 col-sm-4 mb-5">
                              <div
                                key={key}
                                className="pointer"
                                onClick={() =>
                                  this.onClick(
                                    value.violationsDetails[0].bin,
                                    value.match.category,
                                    value.match.sub
                                  )
                                }
                              >
                                <div className="icon-bg">
                                  <i className="fa fa-building"></i>
                                </div>
                                <div className="thead d-flex">
                                  {value.fullAddress}
                                </div>
                                <div className="row">
                                  <div className="col-8 col-md-8 found">
                                    Open
                                  </div>
                                  <div className="col-4 col-md-4 text-right">
                                    <div className="number-bg">
                                      {value.violationsDetails.length}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      )}
                    </div>
                  </div>
                </div>
              )}
              {viewType === 'list' && (
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">Address</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!this.state.doneLoading ? (
                            <tr>
                              <td colSpan="5">
                                <div align="center">
                                  {' '}
                                  <ReactLoading
                                    type={'cylon'}
                                    color={'grey'}
                                  />{' '}
                                </div>
                              </td>
                            </tr>
                          ) : (
                            this.state.tableData.map((value, key) => {
                              // Adding Overwrite for ECB/Corrections__Open
                              if (
                                !value.match.sub &&
                                value.match.category === 'ECB'
                              ) {
                                value.match.sub = 'Corrections__Open';
                              }
                              return (
                                <tr
                                  key={key}
                                  className="pointer"
                                  onClick={() =>
                                    this.onClick(
                                      value.violationsDetails[0].bin,
                                      value.match.category,
                                      value.match.sub
                                    )
                                  }
                                >
                                  <td>
                                    <i className="fa fa-building"></i>{' '}
                                    {value.fullAddress}
                                  </td>
                                  <td>{value.violationsDetails.length}</td>
                                </tr>
                              );
                            })
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
            <TableViolation
                  data={{
                    columns: this.state.dataForTable.columns,
                    dataSource: this.state.dataForTable.dataSource,
                    customFieldData: this.state.dataForTable.customFieldData,
                    category: this.state.category,
                    sub: this.state.sub,
                    options: {
                      fixedColumn: uniquekeys.reduce((res, key) => { res[key] = true; return res }, {}),
                      links: uniquekeys.reduce((res, key) => { res[key] = '/violation-details/:record._id'; return res }, {})
                    },
                  }}
                />
            </div>
            {/* <div className="col-xl-3 recent-activity pr-0">
                            <div className="pt-3 pb-3">
                                {this.props.jestData !== undefined ? null : <SmallCalendar />}
                            </div>
                            <HistoryUpdates />
                        </div> */}
          </div>
        </div>
      </Fragment>
    );
  }
}
