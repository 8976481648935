import React, { Fragment } from 'react';
import axios from 'axios';
import Joi from "joi-browser";
import Form from "../../common/forms/form";
import config from "../../../config";
import {ToastContainer, toast} from "react-toastify";
import Breadcrumb from "../../common/breadcrumb";

const apiUrl = config.apiUrl;

class BuildingsEdit extends Form {

    state = {
        addButton: "Add Address",
        data: { boro: "", houseNumber: "", streetName: "" },
        addresses: [],
        errors: {}
    };

    schema = {
        boro: Joi.string()
            .required()
            .label("Boro"),
        houseNumber: Joi.string()
            .required()
            .label("House Number"),
        streetName: Joi.string()
            .required()
            .label("Street name")
    };

    handleDelete = (dataObject) => {
        console.log('Delete', dataObject, dataObject._id);
        console.log(this.state.addresses)

        const tableData = this.state.addresses.filter(word => dataObject._id !== word._id);
        this.setState({ addresses: tableData});
        /*this.props.history.push("/dashboard/ecommerce");*/
    };

    doSubmit = async () => {
        // Call the server
        this.setState({addButton: "Checking..."});

        try {
           let r = await axios.get(`${apiUrl}/registration/signup?streetNum=${this.state.data.houseNumber}&boro=${this.state.data.boro}&streetName=${this.state.data.streetName}`);
            if(r.data.length){
                const copy = [...this.state.addresses];
                r.data.forEach(address => {
                    const tableData = this.state.addresses.filter(saved => address._id === saved._id);
                    if(tableData.length > 0) {
                        toast.error("The Address is Already in the list.");
                    }else {
                        copy.push(address);
                        this.setState({addresses: copy});
                        toast.success("Successfully Added!");
                    }
                });
            }else{
                console.log('not found')
                toast.error('Address was not found.');
            }

        }catch (e) {
            toast.error("Error: "+ e.message);
        }

        this.setState({addButton: "Add Address"});
        console.log(this.state.addresses)
    };


    render() {
        return <>
            <Fragment>
                <Breadcrumb title="Add/Edit Buildings" parent="Dashboard" />
                <ToastContainer />
                <div className="container-fluid">

                            <div className="card mt-4 p-4">

                                <form className="needs-validation" onSubmit={this.handleSubmit}>
                                    <div className="form-group mt-4 ">
                                        <label className="col-form-label">Enter Address</label>
                                        <div className="form-row">

                                            <div className="col-md-4">
                                                {this.renderSelect("boro", "Boro", [
                                                    {'_id':'1', name: 'Manhattan'},
                                                    {'_id':'2', name: 'Bronx'},
                                                    {'_id':'3', name: 'Brooklyn'},
                                                    {'_id':'4', name: 'Queens'},
                                                    {'_id':'5', name: 'Staten Island'},
                                                ])}
                                            </div>

                                            <div className="col-md-2">
                                                {this.renderInput("houseNumber", "House#", "text")}

                                            </div>

                                            <div className="col-md-6">
                                                {this.renderInput("streetName", "Street Name", "text")}
                                            </div>

                                        </div>
                                        <div className="form-group form-row mb-8  mt-2 text-right ">
                                            <div className="col-md-2">
                                                {this.renderButton(this.state.addButton,this.handleSubmit, 'submit')}
                                            </div>
                                        </div>
                                    </div>
                                    <br/>

                                    <h6 className="f-14 mt-8 mb-3">My Buildings</h6>
                                    <table className="table table-striped">
                                        <thead>
                                        <tr>
                                            <th>Bin</th>
                                            <th>Block</th>
                                            <th>Lot</th>
                                            <th>Address</th>
                                            <th></th>

                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            this.state.addresses.map( (option, id) => {
                                                return <tr key={option._id}>
                                                    <td>{option.bin} </td>
                                                    <td>{option.block} </td>
                                                    <td>{option.lot} </td>
                                                    <td>{option.lhnd_int}-{option.hhnd_int} {option.stname.trim()}, {option.zipcode} </td>
                                                    <td>{this.renderButton('', () => this.handleDelete(option), 'button', 'fa fa-trash','btn btn-danger')} </td>
                                                </tr>
                                            })
                                        }

                                        </tbody>
                                    </table>




                                </form>
                            </div>
                        </div>
            </Fragment>

        </>
    }
}

export default BuildingsEdit;
