import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import creService from "../../services/creService";
import ReactLoading from "react-loading";
import { TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { NavLink as RouterNavLink, Route, Switch, useHistory } from 'react-router-dom';
import { Select } from "antd";

import ViolationDetails from '../buildings/violationDetails/index'
import RecentActivity from "../buildings/partials/recentActivity";
import Notes from "../buildings/violationDetails/notes";
import Uploads from "../buildings/violationDetails/uploads";
import ChatThread from "../buildings/chat/chatThread";
import DetailsAllOther from "../buildings/partials/detailsAllOther";
import AddressHeaderBlock from "../buildings/partials/addressHeaderBlock";

const category = "customCompliance"

const Details = () => {

    let { id, sub, tabName } = useParams();
    let history = useHistory();
    const [vendors, setVendors] = useState([])
    const [subUsers, setSubUsers] = useState([])
    const [customFields, setCustomFields] = useState([])
    const [apiData, setApiData] = useState({})
    const [refreshHistory, setRefreshHistory] = useState(false)
    const [addressData, setAddressData] = useState({
        address: "Loading...",
        bin: '',
        lot: '',
        block: ''
    })
    const [loading, setLoading] = useState(true)
    const setRefreshHistoryHandler = () => {
        setRefreshHistory((oldValue) => !oldValue)
    }

    useEffect(() => {

        getDetailsData(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    const archiveHandler = (_id) => {
        console.log(apiData.violationDetails)
        history.push(`/custom-compliance/${apiData.violationDetails?.customComplianceId?.name}/${_id}`);

    }
    const getDetailsData = async (id) => {

        try {

            const { data: details } = await creService.getCustomComplianceNew(id)
            if (details.status) {
                setApiData({ violationDetails: details.data })
            } else {
                toast.error(details.message);
            }

            const { data: categories } = await creService.getCustomFields(category, sub);
            if (categories.status) {
                setCustomFields(categories)

            } else {
                toast.error(categories.message);
            }

            const { data: addressData } = await creService.getPropertyInfo(details.data.bin);
            setAddressData(addressData)

            const subUsersRes = await creService.getSubUsers();
            setSubUsers(subUsersRes.data.data)
            const res = await creService.getVendors();
            setVendors(res.data.vendors);
        } catch (e) {
            toast.error(e.message);
        }
        setLoading(false)
    }

    return (
        <>

            <div className="violationsDetails-bg px-0 container-fluid">
                <div className="row ">
                    <div className="col-md-12">
                        <AddressHeaderBlock data={addressData} handleGoBack={history.goBack} violationDetailsPage />
                    </div>
                </div>
                <div className="row m-0 w-100 pr-2">
                    <div className='building-detail w-100 col-xl-9 '>

                        {loading ? (
                            <div align="center">
                                <ReactLoading type={"cylon"} color={"grey"} />
                            </div>
                        ) : (
                            <div className="position-relative">
                                {
                                    apiData.violationDetails?.archives && <div className='d-flex justify-content-end'>
                                        <Select
                                            style={{ width: 200 }}
                                            placeholder="Select Archive"
                                            className="ml-2 mb-1"
                                            value={apiData.violationDetails._id}
                                            onChange={archiveHandler}
                                            options={apiData.violationDetails?.archives}
                                        />
                                    </div>
                                }
                                <DetailsAllOther
                                    category={'Compliance'}
                                    sub={
                                        apiData.violationDetails?.customComplianceId?.displayName ?
                                            apiData.violationDetails.customComplianceId.displayName :
                                            sub
                                    }
                                    apiData={apiData}
                                    convertedData={{
                                        'Compliance Details': apiData.violationDetails?.additionalKeys ?
                                            Object.keys(apiData.violationDetails.additionalKeys).map((key, i) => ({
                                                apiField: key,
                                                displayName: key.replace(/([A-Z])/g, ' $1'),
                                                link: (apiData.violationDetails.violation && i === 0) ?
                                                    `/violation-details/${apiData.violationDetails.violation._id}` :
                                                    null
                                            }))
                                            :
                                            []
                                    }}
                                    tableRawData={apiData.violationDetails?.additionalKeys || {}}
                                    subUsers={subUsers}
                                    vendors={vendors}
                                    customFields={customFields}
                                    setRefreshHistory={setRefreshHistoryHandler}
                                    moveToCategory={() => { }}
                                />
                                {apiData.violationDetails.violation && (
                                    <div style={{ marginLeft: "-25px", marginRight: "-40px" }}>
                                        <ViolationDetails
                                            simpleView
                                            match={{ params: { id: apiData.violationDetails.violation._id } }}
                                            location={{}}
                                        />
                                    </div>
                                )}
                            </div>


                        )}
                        <div className="card-body p-0 mt-4" id="all-tabs-area">

                            <Nav tabs className="d-flex m-0">
                                <NavItem>
                                    <NavLink
                                        active={tabName === 'notes' || !tabName}
                                        tag={RouterNavLink}
                                        to={`/custom-compliance/${sub}/${id}/notes/#tab-content`}
                                        exact
                                    >
                                        Notes
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        tag={RouterNavLink}
                                        to={`/custom-compliance/${sub}/${id}/documents/#tab-content`}
                                        exact
                                    >
                                        Uploads/Files
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        tag={RouterNavLink}
                                        to={`/custom-compliance/${sub}/${id}/chats/#tab-content`}
                                        exact
                                    >
                                        Chats
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <Switch>
                                <Route
                                    path={[
                                        `/custom-compliance/${sub}/${id}/notes/#tab-content`,
                                        `/custom-compliance/${sub}/${id}`,
                                        `/custom-compliance/${sub}/${id}/notes`]}
                                    exact
                                >
                                    <TabPane tabId={`/custom-compliance/${sub}/${id}/notes`}>
                                        <Notes violationId={id} source={category} />
                                    </TabPane>
                                </Route>
                                <Route path={`/custom-compliance/${sub}/${id}/documents`}>
                                    <TabPane tabId={`/custom-compliance/${sub}/${id}/documents`}>
                                        <Uploads
                                            violationId={id}
                                            category={category}
                                            sub={sub?.replace(/ /g, '_')}
                                            showDocumentTag
                                            source={category}
                                        />
                                    </TabPane>
                                </Route>
                                <Route path={`/custom-compliance/${sub}/${id}/chats`}>
                                    <TabPane tabId={`/custom-compliance/${sub}/${id}/chats`}>
                                        <div className="card">
                                            <div className="card-body">
                                                <ChatThread
                                                    id={id}
                                                    source={category}
                                                />
                                            </div>
                                        </div>
                                    </TabPane>
                                </Route>
                            </Switch>
                        </div>
                    </div>
                    {apiData.violationDetails &&
                        <div className="col-xl-3 recent-activity px-4 px-xl-0">
                            <RecentActivity
                                type="violationActivity"
                                saveOn={'customCompliance'}
                                apiData={apiData}
                                refreshHistory={refreshHistory}
                            />
                        </div>
                    }
                </div>

            </div>
        </>
    );
}

export default Details
