import React, { Fragment, useState } from 'react';
import moment from 'moment';
import creApi from "../../services/creService";
import http from "../../services/httpService";
import config from "../../../config.json";
import { permissionsKey } from "../../services/authService";
import { toast } from "react-toastify";


const apiUrl = config.apiUrl;

const WorkOrderUploadDocument = (props) => {

    const [loaded, setLoaded] = useState(0);
    const [isUploading, setIsUploading] = useState(false);

    const onUpload = async event => {
        if (checkFileSize(event)) {

            setLoaded(0);
            if (event.target.files[0]) {
                const data = new FormData()
                for (const file of event.target.files) {
                    data.append('file', file)
                }
                event.target.value = '';
                try {

                    setIsUploading(true);
                    props.isUploading(true);
                    const response = await http.post(`${apiUrl}/api/work-order/upload`, data, {
                        onUploadProgress: ProgressEvent => {
                            setLoaded((ProgressEvent.loaded / ProgressEvent.total * 100));
                        },
                    });
                    if (response.data.status) {
                        let uploads = [...props.uploads]

                        for (let upload of response.data.data) {
                            uploads.push(upload);
                            if (props.id && !props.createView) {
                                updateWorkOrder(props.id, upload)
                            }
                        }
                        if ((props.id && (props.editView || props.createView)) || !props.id) {
                            props.setUploads(uploads);
                        }
                    } else {
                        toast.error(response.data.message);
                    }
                    if (response.data.permissions) {
                        localStorage.setItem(permissionsKey, JSON.stringify(response.data.permissions));
                    }
                    setIsUploading(false);
                    props.isUploading(false);
                    setLoaded(0);
                } catch (e) {
                    toast.error(e.message);
                    setIsUploading(false);
                    props.isUploading(false);
                }
            }
        }
    }

    const checkFileSize = (event) => {
        let files = event.target.files
        let size = 36214400 // in bytes - 25mg
        let err = "";
        for (let x = 0; x < files.length; x++) {
            if (files[x].size > size) {
                err += files[x].type + 'is too large, please pick a smaller file\n';
            }
        }
        if (err !== '') {
            event.target.value = null
            toast.error(err);
            return false
        }

        return true;
    }

    const onDelete = async (document) => {
        if (props.id) {
            if (!props.createView) {
                const { data } = await creApi.deleteDocumentWorkOrder(props.id, document._id);
                if (data.status) {
                    toast.success(data.message);
                } else {
                    toast.error(data.message);
                }
            }
            if (props.editView || props.createView) {
                props.setUploads(props.uploads.filter((doc) => {
                    if (doc._id) {
                        return doc._id !== document._id;
                    }
                    return doc.key !== document.key;
                }));
            } else {
                props.getWorkOrder(props.id);
            }
        } else {
            props.setUploads(props.uploads.filter((doc) => {
                if (doc._id) {
                    return doc._id !== document._id;
                }
                return doc.key !== document.key;
            }));
        }
    }



    const updateWorkOrder = async (workOrderId, document) => {
        const res = await creApi.addDocumentWorkOrder(workOrderId, {
            originalname: document.originalname,
            key: document.key,
            _id: document._id
        });
        if (res.data.status) {
            if (!props.editView) {
                props.getWorkOrder(props.id);
            }
            toast.success(res.data.message);
        } else {
            toast.error(res.data.message);
        }
        if (res.data.permissions) {
            localStorage.setItem(permissionsKey, JSON.stringify(res.data.permissions));
        }
    }

    const documents = props.uploads?.filter((v) => (typeof v.signature === 'undefined' || (v.signature && v.signature === false)));
    const signatures = props.uploads?.filter((v) => (typeof v.signature !== 'undefined' && v.signature));

    return (
        <Fragment>
            {props.createView || (props.uploads && props.uploads?.length > 0) ?
                <Fragment>

                    {signatures.length > 0 && documents.length > 0 && <h5 className="mt-4">Documents</h5>}
                    {documents.length > 0 &&
                        <table className="table table-sm mt-3">
                            <tbody>
                                {documents.sort((a, b) => new Date(b.uploadedAt).getDate() - new Date(a.uploadedAt).getDate() ).map((value, key) => {

                                    const isImage = ['jpg', 'jpeg', 'png', 'gif', 'tiff'].includes(value.originalname.split('.').pop()) || value.file?.mimetype.includes('image')

                                    return <tr key={key}>
                                        <td className='d-flex justify-content-between align-items-center'>
                                            <div className='d-flex flex-wrap pointer' onClick={() => props.handleUrlClick(value)}>
                                                {
                                                    isImage ?
                                                        (<img
                                                            src={value.file?.mimetype.includes('image') ?
                                                                window.location.origin + `/api/documents/aws-s3-file-stream/thumbnail/${localStorage.getItem('cre-token')}:${value._id}`
                                                                :
                                                                value.awsUrl
                                                            }
                                                            alt='here should be img'
                                                            width="50px"
                                                            height="50px"
                                                            className="rounded"
                                                        />) :
                                                        (<i className={`fa fa-file-o fa-3x`} style={{ width: '50px', textAlign: 'center' }} />)
                                                }


                                                <button type="button" className="btn btn-xs btn-link text-decoration-none text-left text-dark" rel="noopener noreferrer" >
                                                    {value.originalname}
                                                    {value.signature && <span className="badge badge-dark ml-2">Signature</span>}
                                                    <div className="d-flex small">
                                                        {value.uploadedBy && `Added By ${value.uploadedBy.fullName}`}
                                                        {value.uploadedAt && ` on ${moment(value.uploadedAt).format('MM/DD/YYYY')}`}
                                                    </div>
                                                </button>
                                            </div>
                                            {props.allowed && (value._id || props.createView) &&
                                                <div>
                                                    <button type="button" className="btn btn-danger btn-xs btn-delete" onClick={() => onDelete(value)}>
                                                        <i className="fa fa-trash" /> Delete
                                                    </button>
                                                </div>
                                            }
                                        </td>

                                    </tr>

                                })}
                                {documents.length <= 0 && <tr><td>No documents uploaded.</td></tr>}
                            </tbody>
                        </table>
                    }
                    {signatures.length > 0 &&
                        <Fragment>
                            <h5 className="mt-4">Signatures</h5>
                            <table className="table table-sm mt-3">
                                <tbody>
                                    {signatures.map((value, key) => (
                                        <tr key={key}>
                                            <td className='d-flex justify-content-between align-items-center'>
                                                <div className='d-flex flex-wrap pointer' onClick={() => props.handleUrlClick(value)}>
                                                    {
                                                        <img
                                                            src={value.file?.mimetype.includes('image') ?
                                                                window.location.origin + `/api/documents/aws-s3-file-stream/thumbnail/${localStorage.getItem('cre-token')}:${value._id}`
                                                                :
                                                                value.awsUrl
                                                            }
                                                            alt='here should be img'
                                                            width="50px"
                                                            height="50px"
                                                            className="rounded"
                                                        />

                                                    }
                                                    <button type="button" className="btn btn-xs btn-link text-decoration-none text-left text-dark" rel="noopener noreferrer" >
                                                        {value.originalname}
                                                        <div className="d-flex small">
                                                            {value.uploadedBy && `Added By ${value.uploadedBy.fullName}`}
                                                            {value.uploadedAt && ` on ${moment(value.uploadedAt).format('MM/DD/YYYY')}`}
                                                        </div>
                                                    </button>
                                                </div>
                                                {value.signature &&
                                                    <div>
                                                        {value.signatureName && <span className="d-block">{value.signatureName}</span>}
                                                        <span className="badge badge-dark ml-2">Signature</span>
                                                    </div>
                                                }
                                            </td>
                                        </tr>

                                    ))}
                                    {signatures.length <= 0 && <tr><td>No signatures uploaded.</td></tr>}
                                </tbody>
                            </table>
                        </Fragment>
                    }
                </Fragment>
                : (
                    <div className="mt-3" role="alert">
                        No documents uploaded.
                    </div>
                )
            }
            {isUploading &&
                <div className="progress mt-2">
                    <div className="progress-bar progress-bar-striped progress-bar-animated bg-success" role="progressbar" style={{ width: `${loaded}%` }}>Uploading...</div>
                </div>
            }
            {props.allowed &&
                <div className="p-2"><input multiple type="file" name="file" onChange={onUpload} /></div>
            }
        </Fragment>
    );
};

export default WorkOrderUploadDocument;
