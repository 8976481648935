import React, { useEffect, useState } from 'react';
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { Link } from 'react-router-dom';
import ReactLoading from "react-loading";
import moment from 'moment';
import creService from "../../../services/creService";
import auth from '../../../services/authService';
import logo from '../../../../assets/images/bcompliant_logo.png';
import './style.css';



const jwt = localStorage.getItem('cre-token');
const WorkOrderPrint = () => {

    const [workOrder, setWorkOrder] = useState({});
    const [loading, setLoading] = useState(false);
    const params = useParams();
    const user = auth.getCurrentUser();


    const getWorkOrder = async (id) => {
        setLoading(true);
        const res = await creService.getWorkOrder(id);
        if (res.data.status) {
            setWorkOrder(res.data.data);
            setTimeout(() => {
                window.print();
            }, 5000)
        } else {
            toast.error(res.data.message);
        }
        setLoading(false);
    }
    let logoImage = logo
    if (user?.imageId) {
        logoImage = window.location.origin + `/api/documents/aws-s3-file-stream/${jwt}:${user.imageId}`
    }
    useEffect(() => {
        getWorkOrder(params.id)
    }, [params.id])

    return (
        <div className="page workOrderPrintPage">
            <div className="wrapper">
                <header>
                    <div className="header">
                        <div className="logo">
                            <img  src={logoImage} alt="" />
                        </div>
                        <div className="header-bg"></div>
                        <div className="clearfix"></div>
                    </div>
                </header>
                {loading && <ReactLoading type={"cylon"} color={"grey"} />}
                {!loading && Object.keys(workOrder).length > 0 && <>
                    <div className="order-heading">
                        <p>Work Order #{workOrder.workOrderId}</p>
                    </div>
                    <div className="billing row">
                        <div className="billing-left col-8">
                            <ul className='d-flex justify-content-between'>
                                <li>
                                    <h2>Building Address</h2>
                                    <p>{workOrder.building && (workOrder.building.display_address ? workOrder.building.display_address : workOrder.building.address)}</p>
                                </li>
                                {workOrder.violation && (
                                    <li>
                                        <h2>{workOrder.violation.uniqueData.label}</h2>
                                        <p>
                                            <Link to={`/violation-details/${workOrder.violationId}`}>
                                                {workOrder.violation.uniqueData.value}
                                            </Link>
                                        </p>
                                    </li>
                                )}
                                <li>
                                    <h2>APT. #</h2>
                                    <p>{workOrder.aptNumber || '-'}</p>
                                </li>
                            </ul>
                        </div>
                        <div className="billing-right col-4">
                            <ul>
                                {workOrder.vendor && <li>
                                    <h2>Vendor:</h2>
                                    <p>{workOrder.vendor?.fullName || '-'}</p>
                                </li>}
                                {workOrder.assignee &&
                                    <li>
                                        <h2>Assign to:</h2>
                                        <p>{workOrder.assignee?.fullName || '-'}</p>
                                    </li>
                                }
                                {workOrder.recurring &&
                                    <li>
                                        <h2>Recurring:</h2>
                                        <p>
                                            {workOrder.recurring} {workOrder.recurring === '1' ? 'time' : 'times'}
                                            {workOrder.recurringFrequency && `in a ${workOrder.recurringFrequency}`}
                                        </p>
                                    </li>
                                }
                            </ul>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                    <div className="billing-status">
                        <ul>
                            <li><strong>Status:</strong> {workOrder.status}</li>
                            {workOrder.category && <li><strong>Category:</strong> {workOrder.category}</li>}
                            <li><strong>Priority:</strong> {workOrder.priority}</li>
                            {workOrder.dueDate && <li><strong>Due date:</strong> {workOrder.dueDate && moment.utc(workOrder.dueDate).format("MM/DD/YYYY")}</li>}
                        </ul>
                    </div>
                    <div className="billing-content">
                        <h2>Title</h2>
                        <p>{workOrder.title}</p>
                        <h2>Description</h2>
                        <p>{workOrder.description}</p>
                    </div>
                    {workOrder.additionalData && workOrder.additionalData?.uploads && workOrder.additionalData?.uploads.filter((f) => ['jpg', 'jpeg', 'png', 'gif', 'tiff'].includes(f.originalname.split('.').pop())).length > 0 &&
                        <div className="billing-uploads row ">
                            <div className='col-6 mt-3'>
                                <h2>Uploads</h2>
                                <ul class="d-flex flex-wrap">
                                    {workOrder.additionalData.uploads.map((value, key) => {
                                        if (['jpg', 'jpeg', 'png', 'gif', 'tiff'].includes(value.originalname.split('.').pop()) && !value.signature && value.awsUrl) {
                                            return (
                                                <li key={key} >
                                                    <img src={value.awsUrl} alt={value.originalname} className="img-fluid" max-height={200} />
                                                </li>
                                            )
                                        }
                                        return null;
                                    })}
                                </ul>
                            </div>

                            <div className='col-6 mt-3'>
                                <h2>Signatures</h2>
                                <ul class="d-flex flex-wrap">
                                    {workOrder.additionalData.uploads.map((value, key) => {
                                        if (['jpg', 'jpeg', 'png', 'gif', 'tiff'].includes(value.originalname.split('.').pop()) && value.signature && value.awsUrl) {
                                            return (
                                                <li key={key} class="border text-center">
                                                    <div><strong>{value.signatureName}</strong></div>
                                                    <img src={value.awsUrl} alt={value.originalname} height='200' />
                                                </li>
                                            )
                                        }
                                        return null;
                                    })}
                                </ul>
                            </div>
                        </div>
                    }
                </>}
                <div className="footer">
                    <a href="https://www.bcompliant.com" target="_blank" rel="noreferrer">www.bcompliant.com</a>
                </div>
            </div>
        </div>
    )
}

export default WorkOrderPrint;