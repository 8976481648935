import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

const HPDRegistrationCompilance = ({ value, addedDevices = [] }) => {

    const registrations = value.violationsDetails?.filter(el => !addedDevices.includes(el.violationData.registrationid))
    if (!registrations?.length) {
        return null
    }

    return (
        <>
            <tr>
                <td colSpan={3}>
                    <i className="fa fa-building"></i> {value.fullAddress}
                </td>
            </tr>
          
                <tr >
                    <td>
                        <div>
                            Registration Id# <Link to={`/violation-details/${registrations[0]._id}`}>{registrations[0].violationData.registrationid}</Link>
                        </div>

                        {registrations[0].violationData.lastregistrationdate && <small>Last Registration Date: {moment(registrations[0].violationData.lastregistrationdate).format('MM/DD/YYYY')}</small>}
                    </td>
                    <td>{(registrations[0].violationData.registrationenddate && moment(registrations[0].violationData.registrationenddate).isValid()) ? moment(registrations[0].violationData.registrationenddate).format('MM/DD/YYYY') : registrations[0].violationData.registrationenddate}</td>
                    <td></td>
                </tr>
            
        </>
    );
};

export default HPDRegistrationCompilance;
