import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import ReactLoading from 'react-loading';

import VPUSign from '../../../common/VPUSign';

const Top10Card = ({ title, isLoading, data }) => {
  return (
    <Fragment>
      <div className="card">
        <div className="card-header p-3">
          <h5>{title}</h5>
        </div>
        <div className="card-body p-0">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Address</th>
                <th className="text-center">Complaints</th>
                <th className="text-center">Violations</th>
                <th className="text-center">Total</th>
              </tr>
            </thead>
            <tbody>
              {isLoading && (
                <tr>
                  <td colSpan="2">
                    <ReactLoading type={'cylon'} color={'grey'} />
                  </td>
                </tr>
              )}
              {!isLoading && data.length <= 0 && (
                <tr>
                  <td colSpan="2">No data available.</td>
                </tr>
              )}
              {data &&
                data.map((row, i) => {
                  const address = (
                    row.display_address ? row.display_address : row.address
                  )?.split(',')[0];
                  return (
                    <tr key={address + i}>
                      <td>
                        <p className="mb-0 font-weight-bold">
                          <Link
                            to={`/violations/all/${row.bin}`}
                            className="text-dark-blue"
                          >
                            <i className="fa fa-building mr-1" />
                            {address}
                          </Link>
                        </p>
                      </td>
                      <td className="text-center">
                        <Link
                          to={`/violations/category/${row.bin}/${title}/Open`}
                          className="text-dark-blue"
                        >
                          {row.violationsDetails.reduce(
                            (ac, a) =>
                              !a.sub.includes('Complaints') ? ac : ac + 1,
                            0
                          )}
                        </Link>
                      </td>
                      <td className="text-center">
                        <Link
                          to={`/violations/category/${row.bin}/${title}/Open`}
                          className="text-dark-blue"
                        >
                          {row.violationsDetails.reduce(
                            (ac, a) =>
                              a.sub.includes('Complaints') ? ac : ac + 1,
                            0
                          )}
                        </Link>
                      </td>

                      <td>
                        <Link
                          to={`/violations/category/${row.bin}/${title}/Open`}
                          className="text-dark-blue"
                        >
                          <p className="mb-0 font-weight-bold text-center">
                            {row.violationsDetails.length}
                            {title === 'HPD' && (
                              <VPUSign
                                VPU={row.VPU}
                                VPUHistory={row.VPUHistory}
                                onlyVPU
                              />
                            )}
                          </p>
                        </Link>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </Fragment>
  );
};

export default Top10Card;
