import UploadDocument from "../../../common/upload_document";

const ViolationFiles = (props) => {

    return (
        <div className="card">
            <div className="card-body">
                <div className="form-group row mb-0">
                    <label className="col-sm-3 col-form-label"><b>Upload Document</b></label>
                    <div className="col-sm-9">
                        <UploadDocument
                            id={props.violationId}
                            violationId={props.violationId}
                            category={props.category}
                            sub={props.sub}
                            fieldId={props.fieldId}
                            setUploadedDocuments={props.setUploadedDocuments}
                            source={props.source}
                            setDataSource={props.setDataSource}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViolationFiles;
