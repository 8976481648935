import React from 'react';
import { Doughnut } from 'react-chartjs-2';

const DashboardItem = ({ item, posibleStatuses, handleRowClick }) => {
  const statuses = Object.keys(posibleStatuses)
  const doughnutData = {
    labels: statuses,
    datasets: [
      {
        data: statuses.map(status => item.customCompliances[status] || 0),
        backgroundColor: statuses.map(key => posibleStatuses[key]),
        hoverBackgroundColor: statuses.map(key => posibleStatuses[key]),
      },
    ],
  };
  return <div className="d-flex justify-content-between">
    <div className=' mt-5 w-50 d-flex flex-column justify-content-between'>
      <div className="d-flex flex-wrap align-items-start align-content-start">

        {
          statuses.map(status =>
            <div
              onClick={() => item.customCompliances[status] && handleRowClick(`/custom-compliance/${item.name}?status=${status}`)}
              key={status}
              style={{
                height: 100,
                width: 120,
                boxSizing: 'border-box'
              }}
              className="d-flex card card-hover  m-2 bg-white flex-1"
            >
              <div className="card-body d-flex align-items-center p-0" >
                <div className="media-body text-center">
                  <h3>
                    {item.customCompliances[status] || 0}
                  </h3>
                  <h6>
                    <span
                      style={{
                        color: 'white',
                        background: posibleStatuses[status]
                      }}
                      className='pointer badge'
                    >
                      {status}
                    </span>
                  </h6>
                </div>
                <div >

                </div>
              </div>
            </div>
          )

        }

      </div>
      <div className='text-right font-weight-bold'>Total: {Object.keys(item.customCompliances).reduce((ac,a) => ac += (item.customCompliances[a] || 0), 0)}</div>
    </div>

    <div className="pie-chart mt-4">
      <Doughnut
        data={doughnutData}
        options={{
          responsive: true,
          maintainAspectRatio: true,
          legend: {
            display: true,
            position: 'bottom',
          },
        }}
        width={400}
        height={280}
      />
    </div>
  </div>


}

export default DashboardItem