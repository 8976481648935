import React, { useState } from 'react';
import { Button, Menu, Dropdown, Checkbox } from 'antd';
import { PieChartOutlined } from '@ant-design/icons';
import { FormGroup, CustomInput } from 'reactstrap';

const ChartsColumnDropDown = ({ columns, handleChartsDisplay, uniqueKeys }) => {
  const [value, setValue] = useState(false);

  const displayCharts = columns.filter(
    (column) =>
      column.key !== 'action' &&
      !column.hide &&
      column.data?.bcType !== 'files' &&
      column.data?.bcType !== 'chat' &&
      column.data?.bcType !== 'notes' &&
      column.data?.bcType !== 'files' &&
      column.key !== uniqueKeys?.reportUseDate
  );
  const menu = (
    <Menu
      style={{ float: 'right' }}
      items={
        displayCharts &&
        displayCharts.map((col) => ({
          key: col.key,
          label: (
            <div>
              <div className="d-flex justify-content-between border-bottom">

                {col.key !== uniqueKeys?.identifierField ? (
                  <>
                    <Checkbox
                      onChange={(e) =>
                        handleChartsDisplay(
                          col.key,
                          e.target.checked,
                          col.chartType
                        )
                      }
                      className="ml-2"
                      checked={col.chart}
                    >
                      {col.description}
                    </Checkbox>
                    <FormGroup>
                      <div className='d-flex justify-content-around'>
                        <CustomInput
                          type="radio"
                          id={'bar' + col.description}
                          name={'chart' + col.description}
                          checked={col.chartType === 'Bar'}
                          label="Bar"
                          onChange={() =>
                            handleChartsDisplay(col.key, col.chart, 'Bar')
                          }
                          inline
                        />
                        <CustomInput
                          type="radio"
                          id={'pie' + col.description}
                          name={'chart' + col.description}
                          checked={col.chartType === 'Pie'}
                          label="Pie"
                          onChange={() =>
                            handleChartsDisplay(col.key, col.chart, 'Pie')
                          }
                          inline
                        />
                      </div>
                    {
                      (col.data?.type === 'date' || /date/i.test(col.description + '_' + col.key)) &&
                    
                      <div>
                        <CustomInput
                          type="radio"
                          id={'day' + col.description}
                          name={'chart-ticks' + col.description}
                          checked={!col.chartTypeTicks || col.chartTypeTicks === 'day'}
                          label="Day"
                          onChange={() =>
                            handleChartsDisplay(col.key, col.chart, col.chartType)
                          }
                          inline
                        />
                        <CustomInput
                          type="radio"
                          id={'month' + col.description}
                          name={'chart-ticks' + col.description}
                          checked={col.chartTypeTicks === 'month'}
                          label="Month"
                          onChange={() =>
                            handleChartsDisplay(col.key, col.chart, col.chartType, 'month')
                          }
                          inline
                        />
                        <CustomInput
                          type="radio"
                          id={'year' + col.description}
                          name={'chart-ticks' + col.description}
                          checked={col.chartTypeTicks === 'year'}
                          label="Year"
                          onChange={() =>
                            handleChartsDisplay(col.key, col.chart, col.chartType, 'year')
                          }
                          inline
                        />
                      </div>
                    }
                    </FormGroup>
                  </>) : <Checkbox
                    onChange={(e) =>
                      handleChartsDisplay(
                        col.key,
                        !e.target.checked,
                        col.chartType
                      )
                    }
                    className="ml-2"
                    checked={!col.chart}
                  >
                  General chart
                </Checkbox>}
              </div>
            </div>
          ),
        }))
      }
    />
  );
  return (
    <Dropdown
      overlay={menu}
      placement="right"
      onVisibleChange={setValue}
      visible={value}
      className="hide-btn-wrapper"
      arrow
      trigger="click"
    >
      <Button>
        <PieChartOutlined />
        Charts
      </Button>
    </Dropdown>
  );
};

export default ChartsColumnDropDown;
