
import React from 'react';
import { useHistory } from 'react-router-dom';

const UnitNoExistsMessage = (props) => {
    const history = useHistory();

    return (
        <div className="text-center">
            <div>{props.message}</div>
            {props.tenantId && <button className="btn btn-light btn-sm my-2" onClick={() => history.push(`/fire-safety/edit/${props.fireSafetyId}`)}>Edit</button>}
        </div>
    )
};

export default UnitNoExistsMessage;
