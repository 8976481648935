import React, { useState, Fragment, useLayoutEffect, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { AlignLeft, MoreHorizontal } from 'react-feather';
import { Popover, Divider, Badge } from 'antd';
import { InfoCircleTwoTone } from '@ant-design/icons';
import moment from "moment";

import SearchHeader from './searchHeader';
import '../../../assets/css/custom.css';
import logo from '../../../assets/images/bcompliant_logo.png';
import UserMenu from './userMenu';
import creService from "../../services/creService";
import { lSvaliableSnapshotsKey } from '../../pages/dashboard/snapshotDataMenu'
import auth from '../../services/authService';
import guard from '../guard';

import './header.css'
const jwt = localStorage.getItem('cre-token');
const Header = (props) => {
  const [sidebar, setSidebar] = useState(false);
  const [headerbar, setHeaderbar] = useState(true);
  const [updates, setUpdates] = useState([])
  const [open, setOpen] = useState(false);


  const locationEnv = useMemo(() => auth.getCurrentUser()?.locationEnv, []);
  const imageId = useMemo(() => auth.getCurrentUser()?.imageId, []);

  const openCloseSidebar = () => {
    if (sidebar) {
      setSidebar(!sidebar)
      document.querySelector(".page-main-header").classList.remove('open');
      document.querySelector(".page-sidebar").classList.remove('open');
    } else {
      setSidebar(!sidebar)
      document.querySelector(".page-main-header").classList.add('open');
      document.querySelector(".page-sidebar").classList.add('open');
    }
  }

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };
  const width = useWindowSize()

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize(window.innerWidth);
      }
      window?.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }
  function showRightSidebar() {
    const sideBar = document.querySelector(".right-sidebar")
    if (!sideBar?.className.includes('show')) {
      sideBar?.classList.add('show');
    } else {
      sideBar?.classList.remove('show');
    }
  }



  useEffect(() => {

    creService.getVersionUpdates(3).then(res => {
      if (res.status) {
        setUpdates(res.data.data)
        if (res.data.snapShotVersions) {
          localStorage.setItem(lSvaliableSnapshotsKey, JSON.stringify(res.data.snapShotVersions))
        } else {
          localStorage.removeItem(lSvaliableSnapshotsKey)
        }
      } else {
        toast.error(res.data.message)
      }

    }).catch(err => toast.error(err.message))
  }, [])

  useEffect(() => {
    if (width <= 991) {
      document.querySelector(".page-main-header").className = 'page-main-header open'
      document.querySelector(".page-sidebar").className = 'page-sidebar open'
    }
    else {
      document.querySelector(".page-main-header").className = 'page-main-header '
      document.querySelector(".page-sidebar").className = 'page-sidebar '
    }
  }, [width])

  let src

  if (imageId) {
    src = window.location.origin + `/api/documents/aws-s3-file-stream/${jwt}:${imageId}`
  }


  return (
    <Fragment>
      <div className="page-main-header" >
        <div className="main-header-right row">
          <div className="main-header-left d-lg-none">
            <div className="logo-wrapper">
              <Link to="/dashboard/">
                <div className="image-logo position-relative">
                  <span className=" position-absolute" style={{
                    fontSize: '14px', left: '36px', top: '24px', color: 'rgb(0,91,169)', fontWeight: '700'
                  }}> {(locationEnv !== 'NYC') && locationEnv} {window.location.href.includes('beta.bcompliant.com') ? 'Beta' : ''}
                  </span>
                  <img className="img-fluid image-logo" src={logo} alt="" />
                </div>

              </Link>
            </div>

          </div>
          <div className="mobile-sidebar d-block">
            <div className="media-body text-right switch-sm">
              <label className="switch">
                <a href="#javascript" onClick={() => openCloseSidebar()}>
                  <AlignLeft />
                </a>
              </label>
            </div>
          </div>
          <div className="nav-right col p-0">
            <ul className={`nav-menus ${headerbar ? '' : 'open'}`}>
              <li className='d-flex align-items-center justify-content-between'>
                <SearchHeader />
                {src && <img className="media align-items-center " src={src} style={{ height: '41px' }} alt="" />}
              </li>
              <UserMenu history={props.history} />
              {/* <li className="header-folder">
                <Link to="/uploads-and-notes">
                  <i className="fa fa-folder" aria-hidden="true"></i>
                </Link>
              </li> */}
              <Popover
                trigger="click"
                visible={open}
                onVisibleChange={handleOpenChange}
                placement='bottomRight'
                content={
                  <div style={{ width: '300px' }} className='pointer updates-menu'>
                    <div className='mb-2 d-flex justify-content-between'>
                      Latest updates
                      <span className="badge badge-pill badge-primary">{updates.reduce((ac, update) => {
                        if (update.version > +localStorage.getItem('last-checked-version')) {
                          ac++
                        }
                        return ac
                      }, 0)}</span>
                    </div>
                    <Divider className='m-0' />

                    {
                      updates.map(el => (
                        <Fragment key={'ver-' + el.version}>
                          <Link to={"/updates#vu-" + el.version}>
                            <div className='mt-2 mb-2' onClick={hide}>
                              <span className={`badge badge-pill badge-light  ${+localStorage.getItem('last-checked-version') < el.version ? 'text-info font-weight-bold' : 'text-secondary'}`}>
                                <InfoCircleTwoTone className='mr-1' /> v. {el.version} - {el.reactVersionUpdatedAt ? moment(new Date(el.reactVersionUpdatedAt)).format('LL') : 'no date'}
                              </span> <br />
                              {el.updates.map(el => el.title).join(', ')}
                            </div>
                          </Link>
                        </Fragment>

                      ))}

                    <Divider className='m-0' />
                    <Link to="/updates">
                      <div className='mt-2' onClick={hide}>
                        Check all updates
                      </div>
                    </Link>
                  </div>

                }
              >
                <li className='header-folder pl-3 pr-1 m-0 pointer'>
                  <svg className=' mt-2 ' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path><path d="M13.73 21a2 2 0 0 1-3.46 0"></path></svg>
                  {+localStorage.getItem('last-checked-version') !== updates?.[0]?.version && <span className='dot'></span>}
                </li>
              </Popover>


              {guard({ serviceName: 'chat' }) && <li className='header-folder pl-3 pr-1 m-0 pointer border-0' onClick={() => showRightSidebar()}>
                <Badge count={props.dotVisible} size={'small'} >

                  <svg className='mt-2' xmlns="http://www.w3.org/2000/svg"
                    width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" >
                    <path d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z">
                    </path>
                  </svg>
                </Badge>

                {props.dotVisible ? <span className='dot'></span> : null}
              </li>
              }


            </ul>
            <div className="d-lg-none mobile-toggle pull-right" onClick={() => setHeaderbar(!headerbar)}><MoreHorizontal /></div>
          </div>
          <script id="result-template" type="text/x-handlebars-template">
            <div className="ProfileCard u-cf">
              <div className="ProfileCard-avatar">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-airplay m-0"><path d="M5 17H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1">
                </path>
                  <polygon points="12 15 17 21 7 21 12 15"></polygon>
                </svg>
              </div>
              <div className="ProfileCard-details">
                <div className="ProfileCard-realName"></div>
              </div>
            </div>
          </script>
          <script id="empty-template" type="text/x-handlebars-template">
            <div className="EmptyMessage">Your search turned up 0 results. This most likely means the backend is down, yikes!</div>
          </script>
        </div>
      </div >
    </Fragment >
  )
};
export default Header;
