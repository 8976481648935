import React, { useEffect, useState, useMemo } from 'react';
import { toast } from 'react-toastify';
import { subDays } from 'date-fns';
import { DateRangePicker } from 'react-date-range';
import _ from 'lodash';
import { FormGroup, CustomInput } from 'reactstrap';
import { Input } from "antd";
import moment from 'moment'
import { Switch, Checkbox, Select } from 'antd';

import auth from '../../services/authService';
import creService from '../../services/creService';
import { settingsKey } from "../../services/authService";
import Breadcrumb from '../../common/breadcrumb';
import ReportFilters from './filters';
import RespondentFilter from './respondentFilter';
import AdressTable from '../../common/adressesTable';
import SnapshotDataMenu from '../dashboard/snapshotDataMenu'
import guard from '../../common/guard'
import './createReport.css'
import ConfigDB from '../../../data/customizer/config'

const daysOfweek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

const HistoricalReport = (props) => {
  const locationEnv = useMemo(() => auth.getCurrentUser()?.locationEnv, []);
  const [selectedDate, setSelectedDate] = useState()
  const [buildings, setBuildings] = useState(
    props.jestData !== undefined ? props.jestData.buildings : []
  );
  const [showDateRange, setShowDateRange] = useState(false)
  const [doneLoading, setDoneLoading] = useState(false);
  const [dateRange, setDateRange] = useState({
    startDate: subDays(new Date(), 30),
    endDate: new Date(),
    key: 'selection',
  });
  const [scheduledDays, setScheduledDays] = useState(daysOfweek.map((el, i) => i));
  const [intialType] = useState((new URLSearchParams(props.history.location.search)).get('type'))

  const [reportType, setReportType] = useState((new URLSearchParams(props.history.location.search)).get('type') ? 'WorkOrders' : 'Snapshot');
  const [reportLabel, setReportLabel] = useState();
  const [filterData, setFilterData] = useState({});
  const [respondents, setRespondents] = useState([]);
  const [filterRespondents, setFilterRespondents] = useState(false);
  const [includeUpcomingHearing, setIncludeUpcomingHearing] = useState(false);
  const [respondentChecked, setRespondentChecked] = useState(false);
  const [respondentsLoading, setRespondentsLoading] = useState(false);
  const [doNotShowNotes, setDoNotShowNotes] = useState(false);
  const [includeCustomFields, setIncludeCustomFields] = useState(false);
  const [filterValue, setFilterValue] = useState('')

  const [filterBuildings, setFilterBuildings] = useState([]);
  const [schedule, setSchedule] = useState(false);
  const [scheduleTime, setScheduleTime] = useState('8am');
  const [scheduleReportType, setScheduleReportType] = useState('PDF');
  const [email] = useState(JSON.parse(localStorage.getItem(settingsKey))?.email)


  useEffect(() => {
    setFilterBuildings(buildings.filter(el => String(el.bin).includes(filterValue) || (el.address + el.display_address).toLowerCase().includes(filterValue.toLowerCase())))
  }, [filterValue, buildings])

  async function fetchData() {
    setDoneLoading(true);
    try {
      const buildingsList = await creService.getBuildingsListOnly('portfolio');
      if (buildingsList.data) {
        setBuildings(
          buildingsList.data.map((building) => {
            building.checked = true;
            return building;
          })
        );
      }
    } catch (e) {
      toast.error(e.message);
    }
    setDoneLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);


  const setSelectedDateHadler = (value) => {

    setSelectedDate(value)
    if (value) {
      setReportLabel(`Progress Compare to ${moment(value).format('ll')}`)
    } else {
      setReportLabel('')
    }

  }
  const handleBuildingCheck = (state, bin) => {
    const updateBuildings = _.map(buildings, (b) =>
      b.bin === bin ? _.assign(b, { checked: state }) : b
    );
    setBuildings(updateBuildings);
    if (respondentChecked) {
      getRespondent(true);
    }
  };

  const handleUnAndCheckAll = (state) => {
    if (state) {
      const updateBuildings = _.map(buildings, (b) =>
        _.assign(b, { checked: true })
      );
      setBuildings(updateBuildings);
    } else {
      const updateBuildings = _.map(buildings, (b) =>
        _.assign(b, { checked: false })
      );
      setBuildings(updateBuildings);
    }
    if (respondentChecked) {
      getRespondent(true);
    }
  };

  const generateReport = async () => {
    if (Object.keys(filterData).length === 0) {
      toast.warn('Please select at least one in `FILTER BY SECTION` ');
      const section = document.querySelector('#filter-by-section');
      section.scrollIntoView({ behavior: 'smooth', block: 'start' });
      return
    }

    const selectedBuildings = filterBuildings.filter(
      (b) => typeof b.checked !== 'undefined' && b.checked
    );

    const selectedBins = selectedBuildings.map((b) => b.bin);
    if (selectedBins.length <= 0) {
      toast.error('Please select atleast Address to generate the report.');
    } else {
      let payload = {
        reportLabel,
        reportType,
        building: selectedBins,
        filters: filterData,
        hasECBSections: includeUpcomingHearing,
        doNotShowNotes,
        includeCustomFields

      };
      if (schedule) {
        payload.scheduleAt = scheduleTime
        payload.scheduleReportType = scheduleReportType
        payload.scheduledDays = scheduledDays
      }
      if (reportType === 'Historical' || (reportType === 'WorkOrders' && showDateRange)) {
        payload = {
          ...payload,
          startDate: dateRange.startDate.toISOString(),
          endDate: dateRange.endDate.toISOString(),
        };
      }
      if (reportType === 'Progress' && !selectedDate) {
        toast.error('Please select comparation date');
        return
      } else {
        if (selectedDate) {
          payload.comparationDate = moment(new Date(selectedDate)).format('L')
        }

      }
      try {
        const res = await creService.generateReport(payload);
        // document.body.innerHTML = res.data
        // return
        if (res.data.success) {
          toast.success(res.data.message);
          props.history.push(`/reports${schedule ? '?type=schedule' : ''}`);
        } else {
          toast.error(res.data.message);
        }
      } catch (error) {
        if (typeof error.response?.data?.errors !== 'undefined') {
          error.response.data.errors.map((e) => toast.error(e));
        } else {
          toast.error(
            'We are facing some problem is generating your report right now.'
          );
        }
      }
    }
  };

  const onDateSelect = (range) => {
    setDateRange(range.selection);
  };

  const getRespondent = async (checked, passedFilter = {}) => {
    setRespondentChecked(checked);
    setRespondentsLoading(true);
    let subRespondents = [];
    if (checked) {
      const selectedBuildings = buildings.filter(
        (b) => typeof b.checked !== 'undefined' && b.checked
      );
      const selectedBins = selectedBuildings.map((b) => b.bin);
      let filters = [];
      if (Object.keys(passedFilter).length > 0) {
        for (const category of Object.keys(passedFilter)) {
          if (category !== 'Respondents') {
            for (const subCategory of passedFilter[category]) {
              filters.push({
                category,
                subCategory,
              });
            }
          }
        }
      } else {
        for (const category of Object.keys(filterData)) {
          if (category !== 'Respondents') {
            for (const subCategory of filterData[category]) {
              filters.push({
                category,
                subCategory,
              });
            }
          }
        }
      }
      const res = await creService.getRespondent(filters, selectedBins);
      if (res.data.status) {
        res.data.data.map((subRespondent) => {
          subRespondents.push(subRespondent);
          return true;
        });
      }
    }

    setFilterData({
      ...filterData,
      Respondents: subRespondents,
    });
    setRespondents(subRespondents);
    setRespondentsLoading(false);
    if (Object.keys(passedFilter).length > 0) {
      return subRespondents;
    }
  };

  return (
    <>
      <Breadcrumb title={'Create a Report'} parent="Reports" />
      <div className="container-fluid report-wrapper">
        <div className="row">
          <div className="form-group col-12">
            <h5 className="text-uppercase mb-3">Report Label</h5>
            <input
              type="text"
              className="form-control"
              placeholder="Enter this report label (optional)"
              onChange={(event) => setReportLabel(event.target.value)}
              value={reportLabel}
            />
          </div>
          <div className="col-12">
            <h5 className="text-uppercase mb-3">Report Type</h5>
            <div className='d-flex align-items-center' style={{ minHeight: '57px' }}>

              {intialType !== 'WorkOrders' && <>
                <CustomInput

                  type="radio"
                  name="reportType"
                  checked={reportType === 'Snapshot'}
                  id="report_type_snapshot"
                  label="Snapshot"
                  onChange={() => setReportType('Snapshot')}
                  inline
                />
                <CustomInput
                  type="radio"
                  name="reportType"
                  checked={reportType === 'Historical'}
                  id="report_type_historical"
                  label="Historical"
                  onChange={() => setReportType('Historical')}
                  inline
                />
                {guard({ serviceName: 'snapshot' }) &&
                  <CustomInput
                    type="radio"
                    name="reportType"
                    checked={reportType === 'Progress'}
                    id="report_type_progress"
                    label="Progress"
                    onChange={() => setReportType('Progress')}
                    inline
                  />
                }
              </>
              }
              {guard({ serviceName: 'workOrders' }) &&
                <CustomInput
                  type="radio"
                  name="reportType"
                  checked={reportType === 'WorkOrders'}
                  id="report_type_workorder"
                  label="Work Orders"
                  onChange={() => setReportType('WorkOrders')}
                  inline
                />
              }



            </div>
            {reportType === 'Progress' && <>

              <SnapshotDataMenu selectedDate={selectedDate} setSelectedDate={setSelectedDateHadler} noStorage noPadding />
            </>}

          </div>
          {!(reportType === 'Progress' || reportType === 'WorkOrders') &&
            <>
              {locationEnv === 'NYC' &&
                < div className="col-3">
                  <h5 className="text-uppercase mb-3">Include Upcoming Hearings</h5>
                  <FormGroup>
                    <CustomInput
                      type="radio"
                      id="includeUpcomingHearingYes"
                      name="includeUpcomingHearing"
                      checked={includeUpcomingHearing}
                      label="Yes"
                      onChange={() => setIncludeUpcomingHearing(true)}
                      inline
                    />
                    <CustomInput
                      type="radio"
                      id="includeUpcomingHearingNo"
                      name="includeUpcomingHearing"
                      checked={!includeUpcomingHearing}
                      label="No"
                      onChange={() => setIncludeUpcomingHearing(false)
                      }
                      inline
                    />
                  </FormGroup>
                </div>
              }
              <div className="col-3">
                <h5 className="text-uppercase mb-3">Include notes</h5>
                <FormGroup>
                  <CustomInput
                    type="radio"
                    id="IncludeNotesYes"
                    name="IncludeNotes"
                    checked={!doNotShowNotes}
                    label="Yes"
                    onChange={() => setDoNotShowNotes(false)}
                    inline
                  />
                  <CustomInput
                    type="radio"
                    id="IncludeNotesNo"
                    name="IncludeNotes"
                    checked={doNotShowNotes}
                    label="No"
                    onChange={() => setDoNotShowNotes(true)}
                    inline
                  />
                </FormGroup>
              </div>
              <div className="col-3">
                <h5 className="text-uppercase mb-3">Include custom fields</h5>
                <FormGroup>
                  <CustomInput
                    type="radio"
                    id="IncludeCustomFieldsYes"
                    name="IncludeCustomFields"
                    checked={includeCustomFields}
                    label="Yes"
                    onChange={() => setIncludeCustomFields(true)}
                    inline
                  />
                  <CustomInput
                    type="radio"
                    id="IncludeCustomFieldsNo"
                    name="IncludeCustomFields"
                    checked={!includeCustomFields}
                    label="No"
                    onChange={() => setIncludeCustomFields(false)}
                    inline
                  />
                </FormGroup>
              </div>
            </>

          }

          {(reportType === 'Historical' || reportType === 'WorkOrders') && (
            <div className="col-12 mt-3 mb-3">
              <h5 className="text-uppercase mb-3 d-flex align-items-center">{reportType === 'WorkOrders' ? <>Select Due Date Range <Switch className='ml-2' value={!showDateRange} onChange={() => setShowDateRange(!showDateRange)} /> </> : 'Select Date Range'} </h5>
              {props.jestData !== undefined ? null : (reportType === 'WorkOrders' && !showDateRange ? null : (
                <DateRangePicker
                  onChange={onDateSelect}
                  maxDate={new Date()}
                  ranges={[dateRange]}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  months={2}
                  direction="horizontal"
                />
              ))}
            </div>
          )}
          <div className="col-12 my-3">
            <ReportFilters
              reportType={reportType}
              filterData={filterData}
              setFilterData={setFilterData}
              setRespondents={setRespondents}
              setFilterRespondents={setFilterRespondents}
              setRespondentChecked={setRespondentChecked}
              getRespondent={getRespondent}
              respondentChecked={respondentChecked}
            />
          </div>
          <div className="col-12 mt-2">
            <h5 className="text-uppercase mb-3 d-flex justify-content-between">Select Properties <div><Input placeholder="Building Filter" value={filterValue} onChange={(e) => setFilterValue(e.target.value)} /></div></h5>
            <div className="table-responsive">
              <AdressTable handleUnAndCheckAll={handleUnAndCheckAll} buildings={filterBuildings} doneLoading={doneLoading} handleBuildingCheck={handleBuildingCheck} />
            </div>
            {respondentChecked && <div className="col-12 my-4 px-1">
              <RespondentFilter
                filterData={filterData}
                setFilterData={setFilterData}
                buildings={buildings}
                respondents={respondents}
                setRespondents={setRespondents}
                filterRespondents={filterRespondents}
                respondentChecked={respondentChecked}
                setRespondentChecked={setRespondentChecked}
                respondentsLoading={respondentsLoading}
                getRespondent={getRespondent}
              />
            </div>}
            <div className='mt-2 mb-4'>
              {
                ConfigDB.data.whiteListedEmails.includes(email) &&
                <Checkbox className='pl-2' onChange={(event) => setSchedule(event.target.checked)} value={schedule} >Scheduled Report</Checkbox>
              }
            </div>
            {!schedule && <div className='d-flex align-items-center mt-2 pl-2'>
              <button
                type="button"
                className='btn btn-success'
                onClick={() => generateReport()}
              >
                Generate Report
              </button>

            </div>}
            {schedule && <><div>
              <div className='w-50 ml-3'>Will be send to - <strong >{email}</strong></div>
              <div className='w-50'>
                <FormGroup className='m-3'>

                  <Select
                    value={scheduleTime}
                    style={{ width: 200 }}
                    onChange={setScheduleTime}
                    options={[
                      {
                        value: '8am',
                        label: '8am',
                      },
                      {
                        value: '6pm',
                        label: '6pm',
                      }
                    ]}
                  />

                </FormGroup>
                <FormGroup className='m-3'>

                  <Select
                    mode="multiple"
                    placeholder="select days"
                    value={scheduledDays}
                    onChange={setScheduledDays}
                    labelInValue={true}
                    style={{ width: 400 }}
                    options={daysOfweek.map((label, value) => ({ value, label }))}
                  />

                </FormGroup>
                <FormGroup className='m-3'>
                  <Select
                    value={scheduleReportType}
                    style={{ width: 200 }}
                    onChange={setScheduleReportType}
                    options={[
                      {
                        value: 'PDF',
                        label: 'PDF',
                      },
                      {
                        value: 'Excel',
                        label: 'Excel',
                      },
                      {
                        value: 'Both',
                        label: 'Both',
                      }
                    ]}
                  />

                </FormGroup>

              </div>
            </div>
              <button
                type="button"
                className='btn btn-success'
                onClick={() => generateReport()}
              >
                Create Scheduled Report
              </button>
            </>}
          </div>
        </div>
      </div >
    </>
  );
};

export default HistoricalReport;
