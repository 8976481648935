import React, { useState, useEffect } from "react";
import WorkOrderUploadDocument from "../uploadDocument";
import { permissionsKey } from "../../../services/authService";

const Documents = (props) => {
    const [allowed, setAllowed] = useState(true);
    const permissions = JSON.parse(localStorage.getItem(permissionsKey))?.workOrders;

    const checkAllowed = () => {
        if (permissions && !permissions.includes('edit') && !permissions.includes('partial_edit')) {
            setAllowed(false);
        }
    }

    useEffect(() => {
        checkAllowed()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="form-group col-12 mt-3">
            <WorkOrderUploadDocument
                handleUrlClick={props.handleUrlClick}
                isUploading={() => { }}
                uploads={props.workOrder.additionalData?.uploads}
                id={props.id}
                getWorkOrder={props.getWorkOrder}
                allowed={allowed}
            />
        </div>
    );
}

export default Documents;
