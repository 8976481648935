import React from 'react';
import moment from 'moment';
import { Link, useLocation } from 'react-router-dom';
import ReactLoading from 'react-loading';
import logoVisitt from '../../../../assets/images/visitt-logo-main.svg';
import appWork from '../../../../assets/images/appWork.svg';
import Guard from '../../../common/guard';

const ViewByTable = ({ workOrders, handleSort, sortField, doneLoading }) => {
  const location = useLocation();

  const getStatusBadge = (status) => {
    if (status === 'Open') {
      return 'badge-wo-open';
    } else if (status === 'In Progress') {
      return 'badge-wo-inprogress';
    } else if (status === 'On Hold') {
      return 'badge-wo-onhold';
    } else if (status === 'Completed') {
      return 'badge-wo-completed';
    } else if (status === 'Canceled') {
      return 'badge-wo-canceled';
    }
  };

  const getDisplayAddress = (workOrder) => {
    let address = workOrder.building?.address;
    if (workOrder.building?.display_address) {
      address = workOrder.building?.display_address;
    }
    if (workOrder.source === 'visitt') {
      address = workOrder.building.visittAddress
    }
    return address?.split(',')[0];
  };

  return (
    <table className="table table-borderless">
      <thead>
        <tr>
          <th
            scope="col"
            onClick={() => handleSort('workOrderId')}
            style={{ whiteSpace: 'pre' }}
          >
            WO #
            <i
              className={`fa ml-1 fa-sort-numeric-${sortField.workOrderId === -1 ? 'desc' : 'asc'
                }`}
            />
          </th>
          <th
            scope="col"
            onClick={() => handleSort('title')}
            style={{ whiteSpace: 'pre' }}
          >
            Title
            <i
              className={`fa ml-1 fa-sort-alpha-${sortField.title === -1 ? 'desc' : 'asc'
                }`}
            />
          </th>
          <th
            scope="col"
            onClick={() => handleSort('buildingNum')}
            style={{ whiteSpace: 'pre' }}
          >
            Address
            <i
              className={`fa ml-1 fa-sort-alpha-${sortField.buildingNum === -1 ? 'desc' : 'asc'
                }`}
            />
          </th>
          <th
            scope="col"
            onClick={() => handleSort('dueDate')}
            style={{ whiteSpace: 'pre' }}
          >
            Due Date
            <i
              className={`fa ml-1 fa-sort-numeric-${sortField.dueDate === -1 ? 'desc' : 'asc'
                }`}
            />
          </th>
          <th
            scope="col"
            onClick={() => handleSort('category')}
            style={{ whiteSpace: 'pre' }}
          >
            Category
            <i
              className={`fa ml-1 fa-sort-alpha-${sortField.category === -1 ? 'desc' : 'asc'
                }`}
            />
          </th>
          <th
            scope="col"
            onClick={() => handleSort('priorityNum')}
            style={{ whiteSpace: 'pre' }}
          >
            Priority
            <i
              className={`fa ml-1 fa-sort-alpha-${sortField.priorityNum === -1 ? 'desc' : 'asc'
                }`}
            />
          </th>
          <th
            scope="col"
            onClick={() => handleSort('statusNum')}
            style={{ whiteSpace: 'pre' }}
          >
            Status
            <i
              className={`fa ml-1 fa-sort-alpha-${sortField.statusNum === -1 ? 'desc' : 'asc'
                }`}
            />
          </th>
          <th
            scope="col"
            onClick={() => handleSort('vendorName')}
            style={{ whiteSpace: 'pre' }}
          >
            Vendor
            <i
              className={`fa ml-1 fa-sort-alpha-${sortField.vendorName === -1 ? 'desc' : 'asc'
                }`}
            />
          </th>
          <th
            scope="col"
            onClick={() => handleSort('userName')}
            style={{ whiteSpace: 'pre' }}
          >
            Assigned to
            <i
              className={`fa ml-1 fa-sort-alpha-${sortField.vendorName === -1 ? 'desc' : 'asc'
                }`}
            />
          </th>
          <Guard
            serviceName="workOrders"
            action={['edit', 'partial_edit']}
            allow={() => (
              <th scope="col" style={{ whiteSpace: 'pre' }}>
                Actions
              </th>
            )}
          />
        </tr>
      </thead>
      <tbody>
        {workOrders[0] &&
          workOrders[0]._id !== 'Open' &&
          workOrders.map((workOrder, index) => {
            return (
              <tr key={index} className='border-top'>
                <th scope="row" className="hoverEffect">
                  <div className='d-flex align-items-center'>
                    <div>
                      <div>
                        {workOrder.source === 'visitt' && <img src={logoVisitt} height='15px' alt='visitt' />}
                        {workOrder.source === 'appWork' && <img src={appWork} height='25px' alt='appWork' style={{ background: 'rgb(4, 51, 59)', padding:'2px', borderRadius:'7px' }} />}


                      </div>
                      <Guard
                        serviceName="workOrders"
                        action="view"
                        allow={() => (
                          <Link
                            to={{
                              pathname: `/work-orders/view/${workOrder._id}`,
                              state: { from: location },
                            }}
                          >
                            {workOrder.workOrderId}
                          </Link>
                        )}
                        deny={() => workOrder.workOrderId}
                      />

                      {workOrder.cloneOf && (
                        <Link
                          to={{
                            pathname: `/work-orders/view/${workOrder.cloneOf}`,
                            state: { from: location },
                          }}
                          className="btn btn-link p-0 text-dark"
                        >
                          <i
                            className="fa fa-copy ml-1"
                            title="Clonned Work Order"
                          />
                        </Link>
                      )}
                    </div>
                    <div>
                      {workOrder.violationId && (
                        <Link
                          to={{
                            pathname: `/violation-details/${workOrder.violationId}`,
                            state: { from: location },
                          }}
                          className="btn btn-link p-0 text-dark"
                        >
                          <i
                            className="fa fa-ticket ml-1"
                            title="Violation Work Order"
                          />
                        </Link>
                      )}
                    </div>
                  </div>
                </th>
                <td>{workOrder.title}</td>
                <td>
                  {getDisplayAddress(workOrder)}
                  <br />
                  {workOrder.aptNumber && <>Apt #: {workOrder.aptNumber}</>}
                </td>
                <td>{moment.utc(workOrder.dueDate).format('MM/DD/YYYY')}</td>
                <td>{workOrder.category}</td>
                <td>
                  <span
                    className={`badge ${workOrder.priority === 'Low' && 'badge-info'
                      } ${workOrder.priority === 'Medium' && 'badge-warning'} ${workOrder.priority === 'High' && 'badge-danger'
                      }`}
                  >
                    {' '}
                    {workOrder.priority}
                  </span>
                </td>
                <td>
                  <span className={`badge ${getStatusBadge(workOrder.status)}`}>
                    {workOrder.status}
                  </span>
                </td>
                <td>
                  {workOrder.vendorDetails && workOrder.vendorDetails.fullName
                    ? workOrder.vendorDetails.fullName
                    : '--'}
                </td>
                <td>
                  <ul>
                    {workOrder.assignedUsers &&
                      workOrder.assignedUsers.map((user, i) => (
                        <li key={i + user?.fullName}>{user?.fullName}</li>
                      ))}
                  </ul>
                </td>
                <Guard
                  serviceName="workOrders"
                  action={['edit', 'partial_edit', 'create']}
                  allow={() => (
                    <td>
                      <Guard
                        serviceName="workOrders"
                        action={['edit', 'partial_edit']}
                        allow={() => (

                          workOrder.source !== 'visitt' ?
                            <Link
                              className="btn btn-info btn-xs mx-1"
                              to={{
                                pathname: `/work-orders/edit/${workOrder._id}`,
                                state: { from: location },
                              }}
                            >
                              <i className="fa fa-pencil"></i>
                            </Link> : null

                        )}
                      />
                    </td>
                  )}
                />
              </tr>
            );
          })}
        {workOrders.length <= 0 && doneLoading && (
          <tr>
            <td colSpan={7}>No work order available</td>
          </tr>
        )}
        {!doneLoading && (
          <tr>
            <td colSpan={7}>
              <div className="loadercenter">
                {' '}
                <ReactLoading type={'cylon'} color={'grey'} />{' '}
              </div>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default ViewByTable;
