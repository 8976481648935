import React, { useEffect, useState, useContext } from 'react';
import ReactLoading from "react-loading";
import Moment from "moment";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { toast } from 'react-toastify';
import 'react-confirm-alert/src/react-confirm-alert.css';
import creService from '../../services/creService';
import HistoryUpdates from "../buildings/partials/historyUpdates";
import Breadcrumb from "../../common/breadcrumb";
import Pagination from "../../common/forms/pagination";
import SmallCalendar from '../calendar/smallCalendar';
import { confirmAlert } from 'react-confirm-alert'; // Import
import ReportFilters from './filters';
import './index.css'
import { SocketContext } from '../../../context/socket';

// import { Typography } from 'antd';

// const { Paragraph } = Typography;

const ReportItem = ({
    key,
    report,
    showUpdateReportLabelModal,
    openOptionsModal,
    downloadReportFile,
    regenerateReport,
    handleReportDelete
}) => {

    // const [editableStr, setEditableStr] = useState(report.displayName ? report.displayName : report.reportType);

    // const onChange = async (value) => {
    //     try {
    //         const result = await creService.setReportDisplayName(report._id, value)
    //         if (result.data.status) {
    //             setEditableStr(value)
    //         } else {
    //             toast.error(result.data.message);
    //         }

    //     } catch (err) {
    //         toast.error(err.message);
    //     }


    // }

    const pdfStatus = report.pdfStatus ? report.pdfStatus : (report.status || 'Not Started')
    const excelStatus = report.excelStatus ? report.excelStatus : (report.status || 'Not Started')
    return (
        <tr key={key}>
            <td className="text-left" style={{ verticalAlign: 'middle' }}>
                {report.reportType === "Snapshot" && (
                    /*<span className='d-flex flex-wrap'>
                        <Paragraph className='mr-2' editable={{ onChange }}>{editableStr}</Paragraph> [{Moment(report.requestedAt).format('MM/DD/YYYY')}]
                    </span>*/
                    <span>
                        Snapshot [{Moment(report.requestedAt).format('MM/DD/YYYY')}]
                    </span>
                )}
                {report.reportType === "Historical" && (
                    /*<span className='d-flex flex-wrap'>
                        <Paragraph className='mr-2' editable={{ onChange }}>{editableStr}</Paragraph> [{Moment(report.startDate).format('MM/DD/YYYY')}-{Moment(report.endDate).format('MM/DD/YYYY')}]
                    </span>*/
                    <span>
                        Historical [{Moment(report.startDate).format('MM/DD/YYYY')}-{Moment(report.endDate).format('MM/DD/YYYY')}]
                    </span>
                )}
                {report.reportType === "Progress" && (
                    /*<span className='d-flex flex-wrap'>
                        <Paragraph className='mr-2' editable={{ onChange }}>{editableStr}</Paragraph> [{Moment(report.comparationDate).format('MM/DD/YYYY')}]
                    </span>*/
                    <span>
                        Progress [{Moment(report.comparationDate).format('MM/DD/YYYY')}]
                    </span>
                )}
                {report.reportType === "WorkOrders" && (
                    <span>
                        WorkOrders Report
                    </span>
                )}
                <div className="hover-effect">
                    {report.reportLabel && <b className="mr-1">{report.reportLabel}</b>}
                    <button className="btn btn-xs p-0" onClick={() => showUpdateReportLabelModal(report)}>{report.reportLabel ? '[Edit]' : '[Add Label]'}</button>
                </div>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
                {report.user.fullName}
                {report.portfolio && <div className="small">{report.portfolio.portfolioName}</div>}
            </td>
            <td onClick={() => report.reportType !== "WorkOrders" && openOptionsModal(report)} className='pointer'>
                {report.buildings.length <= 0 && "All Properties"}
                {report.buildings.length > 0 && `${report.buildings.length} Properties`}
                <br />
                {report.reportType}
            </td>
            <td style={{ verticalAlign: 'middle' }}>
                <div>{Moment(report.requestedAt).format('MM/DD/YY HH:mm:ss')}</div>
                {
                    report.scheduleAt && (excelStatus === "FAILED" || pdfStatus === "FAILED" || report.failedErrors) &&
                    <div className='badge badge-danger'>
                        {report.failedErrors?.includes('Too Large') ? 'The file is too large for email. Canceled!' : 'Report was generating Error multiple times. Canceled!'}
                    </div>
                }
            </td>
            <td style={{ verticalAlign: 'middle' }}>
                {report.scheduleAt ? <div className='d-flex flex-column justify-content-center align-items-center'>{`Every day at ${report.scheduleAt}`} <span className='badge badge-success'> {report.scheduleReportType} </span></div> : <>

                    <div className="d-inline-flex">
                        <button
                            disabled={pdfStatus !== "COMPLETED" && pdfStatus !== "FAILED"}
                            onClick={() => pdfStatus === "FAILED" ? regenerateReport(report, 'PDF') : downloadReportFile(report, 'pdf')}
                            className={`btn btn-xs btn-${pdfStatus === "COMPLETED" ? 'success' : (pdfStatus === "FAILED" ? 'danger' : 'secondary')} `}
                        >
                            {pdfStatus === "COMPLETED" ? 'DOWNLOAD PDF' : <><span className='d-block'>PDF Generation Status</span>{pdfStatus}</>}
                            {pdfStatus === "FAILED" && <span className='d-block'>click to regenerate</span>}
                        </button>

                        <button
                            disabled={excelStatus !== "COMPLETED" && excelStatus !== "FAILED"}
                            onClick={() => excelStatus === "FAILED" ? regenerateReport(report, 'Excel') : downloadReportFile(report, 'excel')}
                            className={`ml-1 btn btn-xs btn-${excelStatus === "COMPLETED" ? 'success' : (excelStatus === "FAILED" ? 'danger' : 'secondary')} `}
                        >
                            {excelStatus === "COMPLETED" ? 'DOWNLOAD EXCEL' : <>  <span className='d-block'>EXCEL Generation Status</span>{excelStatus}</>}
                            {excelStatus === "FAILED" && <span className='d-block'>click to regenerate</span>}
                        </button>
                    </div>

                    {/* {report.status === "FAILED" && (
                        <div className="d-inline-flex ">
                            <button disabled className="btn btn-xs btn-danger text-uppercase">
                                Time Out
                            </button>
                            {report.filters &&
                                <button onClick={() => regenerateReport(report)} className="btn btn-xs btn-success text-uppercase ml-1">
                                    Regenerate
                                </button>
                            }
                        </div>
                    )}
                    {report.status === "INITIATED" && (
                        <span className='badge badge-info text-uppercase'>In Progress</span>
                    )} */}

                </>
                }

            </td>
            <td style={{ verticalAlign: 'middle' }}>
                <div className='d-flex justify-content-center'>
                    <button className="btn btn-xs btn-danger" onClick={() => handleReportDelete(report._id)}><i className="fa fa-trash"></i></button>
                </div>
            </td>
        </tr >)
}

const SheduledHeaders = <tr>
    <th scope='col'>Scheduled Report Name</th>
    <th scope='col' width="15%">Created By</th>
    <th scope='col'>Properties</th>
    <th scope='col' >Last Generated at</th>
    <th scope='col' className="text-center">Scheduled At</th>
    <th scope='col' className="text-center">Actions</th>
</tr>


const Reports = (props) => {
    // const timerRef = useRef(null);
    const socket = useContext(SocketContext);
    const queryParams = props.jestData !== undefined ? props.jestData.queryParams : new URLSearchParams(props.history.location.search);
    const currentPage = props.jestData !== undefined ? props.jestData.currentPage : queryParams.get('page') ? parseInt(queryParams.get('page')) : 1;

    const [data, setData] = useState(props.jestData !== undefined ? props.jestData.data : []);
    const [scheduled, setScheduled] = useState([]);
    const [filter, setFilter] = useState(queryParams.get?.('type') || "All");
    const [reportLoading, setReportLoading] = useState(false);
    const [paginationData, setPaginationData] = useState(props.jestData !== undefined ? props.jestData.paginationData : {
        pageSize: 10,
        itemsCount: 0,
    });
    const [reportModalState, setReportModalState] = useState(false);
    const [currentReport, setCurrentReport] = useState(null);

    const [optionsModalVisible, setOptionsModalVisible] = useState(false);

    const toggleReportModal = () => setReportModalState(!reportModalState);

    async function fetchData(page = currentPage, getOnly = filter) {
        if (!reportLoading) {
            setReportLoading(true);
            try {
                const res = await creService.getAllReports(page, getOnly);
                if (res.data.reports) {
                    setData(res.data.reports);
                    setScheduled(res.data.scheduled)
                    setPaginationData({
                        ...paginationData,
                        pageSize: res.data.pageSize,
                        itemsCount: res.data.total,
                    })
                    props.history.push(`/reports?${queryParams.toString()}`)
                }
                setReportLoading(false);
            } catch (error) {
                toast.error(error);
                setReportLoading(false);
            }
        }
    }

    useEffect(() => {
        fetchData(1, filter);
        if (socket) {
            const modifyStatus = ({ report }) => {
                setData((data => {
                    const updated = data.map(el => {
                        if (el._id === report._id) {
                            return { ...el, ...report }
                        }
                        return el
                    })
                    return updated
                }))
            };
            socket.on('report-update', modifyStatus);
            return () => socket.off('report-update', modifyStatus);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    // useEffect(() => {
    //     const processingReportsCount = data.filter(r => (r.status === "INITIATED" || r.status === "IN LAMBDA") && !r.scheduleAt).length;
    //      console.log(processingReportsCount)
    //     if (processingReportsCount >= 1) {
    //         timerRef.current = setTimeout(() => {
    //             console.log(123)
    //             fetchData(1);
    //             clearTimeout(timerRef.current)
    //         }, 5000)
    //     }


    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [data]);

    const onPageChange = (page) => {
        queryParams.set('page', page);
        fetchData(page, filter);
    }

    const downloadReportFile = async (report, type) => {
        try {
            const res = await creService.getReportDownloadUrl(report._id, type);
            if (res.data.success) {
                const anchor = document.createElement('a');
                anchor.href = res.data.downloadUrl;
                console.log(res.data.downloadUrl)
                anchor.target = '_blank';

                anchor.click();
            } else {
                toast.error(res.data.message);
            }
        } catch (error) {
            toast.error(error);
        }
    }

    const handleReportDelete = async (reportId) => {
        confirmAlert({
            title: 'Confirm to delete',
            message: `Do you want to delete this report?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        const page = queryParams.get('page');
                        const res = await creService.deleteReport(reportId);
                        if (res.data.status) {
                            toast.success(res.data.message);
                            fetchData(page, filter);
                        } else {
                            toast.error(res.data.message);
                        }
                    }
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    }
    const openOptionsModal = (report) => {

        setOptionsModalVisible(true)
        setCurrentReport(report);
    }
    const showUpdateReportLabelModal = (report) => {
        setReportModalState(true);
        setCurrentReport(report);
    }
    const regenerateReport = async (report, type) => {
        try {
            const res = await creService.reGenerateReport(report._id, type);
            if (res.data.status) {
                toast.success(res.data.message);
                fetchData(1, filter);
            } else {
                toast.error(res.data.message);
            }
        } catch (error) {
            toast.error(error.message);
        }

    }

    const onSaveReportLabel = async (event) => {
        event.preventDefault();
        const res = await creService.updateReportLabel(currentReport._id, currentReport.reportLabel);
        if (res.data.success) {
            toast.success(res.data.message);
            setReportModalState(false);
            fetchData(queryParams.get('page'), filter);
        } else {
            if (res.data.errors) {
                res.data.errors.map((err) => toast.error(err));
            } else {
                toast.error(res.data.message);
            }
        }
    }
    const setFilerData = async (value) => {
        setFilter(value);
        fetchData(1, value);
    }
    const AllScheduled = scheduled?.map((report, key) => {

        return <ReportItem
            key={key}
            report={report}
            showUpdateReportLabelModal={showUpdateReportLabelModal}
            openOptionsModal={openOptionsModal}
            downloadReportFile={downloadReportFile}
            regenerateReport={regenerateReport}
            handleReportDelete={handleReportDelete}
        />
    }
    )

    const AllBlock = data?.map((report, key) => {

        return <ReportItem
            key={key}
            report={report}
            showUpdateReportLabelModal={showUpdateReportLabelModal}
            openOptionsModal={openOptionsModal}
            downloadReportFile={downloadReportFile}
            regenerateReport={regenerateReport}
            handleReportDelete={handleReportDelete}
        />
    }
    )


    return (
        <>
            <div className='container-fluid'>
                <div className="row">
                    <div className="col-9">
                        <Breadcrumb title={'All Reports'} parent="Reports" />
                        <div className='row mb-3'>
                            <div className='col-4'>
                                <button onClick={() => props.history.push('/create-report')} className='btn btn-primary'>Create Report</button>
                            </div>
                            <div className='col-8 text-right d-flex'>
                                <button type="button" className="btn btn-primary btn-xs px-3 mr-2" onClick={() => {
                                    const page = queryParams.get('page');;
                                    fetchData(page, filter);
                                }}>
                                    <i className="fa fa-refresh"></i>
                                </button>
                                <select onChange={(e) => {
                                    setFilerData(e.target.value)
                                }} name="reportStatus" value={filter} className="form-control text-uppercase">
                                    <option value="All">All Reports</option>
                                    <option value="Snapshot">Snapshot Reports</option>
                                    <option value="Historical">Historical Reports</option>
                                    <option value="Intitiated">Under Progress Reports</option>
                                    <option value="schedule">Scheduled</option>
                                </select>
                            </div>
                        </div>
                        {!!AllScheduled.length && <><h4> Scheduled </h4> <div className='row mb-5'>
                            <div className='col-xl-12'>
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            {SheduledHeaders}
                                        </thead>
                                        <tbody>
                                            {reportLoading
                                                ?
                                                (<tr>
                                                    <td colSpan="5">
                                                        <div align="center">
                                                            <ReactLoading type={"cylon"} color={"grey"} />
                                                        </div>
                                                    </td>
                                                </tr>)
                                                :
                                                AllScheduled
                                            }

                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div></>}

                        <h4> Reports </h4>
                        <div className='row'>
                            <div className='col-xl-12'>
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            {filter === 'schedule' ? SheduledHeaders :
                                                <tr>
                                                    <th scope='col'>Report Name</th>
                                                    <th scope='col' width="15%">Generated By</th>
                                                    <th scope='col'>Properties</th>
                                                    <th scope='col'>Date</th>
                                                    <th scope='col' className="text-center">Download Actions</th>
                                                    <th scope='col' className="text-right">Actions</th>
                                                </tr>}

                                        </thead>
                                        <tbody>
                                            {reportLoading
                                                ?
                                                (<tr>
                                                    <td colSpan="5">
                                                        <div align="center">
                                                            <ReactLoading type={"cylon"} color={"grey"} />
                                                        </div>
                                                    </td>
                                                </tr>)
                                                : AllBlock

                                            }
                                            {data && data.length <= 0 && (
                                                <tr>
                                                    <td colSpan="6">No Reports Found.</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <Pagination
                                    className="my-4"
                                    itemsCount={paginationData.itemsCount}
                                    pageSize={paginationData.pageSize}
                                    currentPage={currentPage}
                                    onPageChange={onPageChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 recent-activity pr-0">
                        <div className="pt-3 pb-3">
                            {props.jestData !== undefined ? null : <SmallCalendar />}
                        </div>
                        <HistoryUpdates />
                    </div>
                </div>
            </div >
            <Modal size="lg" isOpen={reportModalState} toggle={toggleReportModal}>
                <ModalHeader toggle={toggleReportModal}>Update Label of <b>{currentReport?.reportName}</b></ModalHeader>
                <ModalBody>
                    <form onSubmit={onSaveReportLabel}>
                        <div className="form-group">
                            <label>Enter Label</label>
                            <input
                                className="form-control"
                                name="reportLabel"
                                type="text"
                                placeholder="Enter Report Label"
                                defaultValue={currentReport?.reportLabel}
                                onChange={(event) => setCurrentReport({ ...currentReport, reportLabel: event?.target?.value })}
                            />
                        </div>
                        <div className="form-group">
                            <button className="btn btn-primary" type="submit">Save</button>
                        </div>
                    </form>
                </ModalBody>
            </Modal>

            <Modal size="xl" isOpen={optionsModalVisible} toggle={() => setOptionsModalVisible(!optionsModalVisible)}>
                <ModalHeader toggle={() => setOptionsModalVisible(!optionsModalVisible)}>Chosen Properties For <b>{currentReport?.reportName}</b></ModalHeader>
                <ModalBody>
                    {currentReport?.filters &&
                        <>
                            <h6>
                                <div className='mt-1'><strong>REPORT TYPE </strong>- {currentReport.reportType}</div>
                                {currentReport.comparationDate && <div className='mt-1'><strong>COMPARE TO </strong>- {Moment(new Date(currentReport.comparationDate)).format('ll')}</div>}
                                {currentReport.reportType ==='Historical' && <div className='mt-1'>
                                    <strong>HISTORICAL BETWEEN: </strong> {Moment(new Date(currentReport.startDate)).format('ll')} - {Moment(new Date(currentReport.endDate)).format('ll')}
                                    </div>}
                                <div className='mt-1'><strong>INCLUDE UPCOMING HEARINGS </strong>- {currentReport.hasECBSections ? "YES" : "NO"}</div>
                                <div className='mt-1'><strong>INCLUDE NOTES </strong>- {!currentReport.doNotShowNotes ? "YES" : "NO"}</div>
                                <div className='mt-1'><strong>INCLUDE CUSTOM FIELDS </strong>- {currentReport.includeCustomFields ? "YES" : "NO"}</div>
                                <div className='mt-1'><strong>BINS </strong>- [{currentReport.buildings.join(', ')}]</div>
                            </h6>
                            <ReportFilters
                                reportType={currentReport.reportType}
                                filterData={currentReport.filters}
                                disabled
                            />

                        </>
                    }
                </ModalBody>
            </Modal>
        </>
    )
}

export default Reports;
