import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import Helper from "../../../../services/helperService";
import { useState } from 'react';

const RetainedListView = ({ data, onViolationClick }) => {
    const [sort, setSort] = useState('')
    const [sortType, setSortType] = useState(1)
    const [icon, setIcon] = useState('')

    const convertToDisplayName = (displayNames, value) => {
        let convertedDisplayName = displayNames.find(
            v => (v.apiField.toLowerCase() === value || v.apiField === value)
        );
        return (convertedDisplayName ? convertedDisplayName.displayName : value);
    }

    const handleSortClick = async (value, category, sub) => {
        setSort(value);
        if (sortType === 1) {
            setSortType(-1)
            setIcon('down')

        }
        else {
            setSortType(1)
            setIcon('up')
        }
        const direction = sortType;
        await data[category][sub].violations.sort((a, b) => {
            if (typeof a.violationData[value] === 'string' && typeof b.violationData[value] === 'string') {

                if (direction === 1) {
                    return a.violationData[value].localeCompare(b.violationData[value]);
                } else if (direction === -1) {
                    return b.violationData[value].localeCompare(a.violationData[value]);
                }
            } else {

                if (direction === 1) {
                    return a.violationData[value] - b.violationData[value];
                } else if (direction === -1) {
                    return b.violationData[value] - a.violationData[value];
                }
            }
            return data;
        });

    }

    return <div className="row violation-sec">
        <div className="col-md-12">
            {data && Object.keys(data).map((category) => <>
                {data[category] && Object.keys(data[category]).map((sub) => <>
                    <div className="d-flex justify-content-between border-bottom mb-3">
                        <h4 className="pb-2 mb-4 border-bottom">{category}/{sub.replace(/__/g, ' ').replace(/_/g, ' ')}</h4>
                        <div className="onhover-dropdown sort-by-side-btn">
                            <button className="btn btn-primary btn-pill mr-3" type="button">
                                Sort By
                                <span className="ml-1 pr-0"><i className="fa fa-angle-down" /></span>
                            </button>
                            <div className="onhover-show-div right-header-dropdown list-group p-0">
                                {(data[category][sub].availableSortByFields) && Object.keys(data[category][sub].availableSortByFields).map((value, key) => {
                                    let displayName = convertToDisplayName(data[category][sub].displayName, value);
                                    let bg = 'text-info'
                                    // eslint-disable-next-line
                                    if (value === sort) {
                                        bg = "text-muted";
                                    }
                                    return (
                                        // eslint-disable-next-line
                                        <a href={null} className={`p-2 list-group-item d-flex justify-content-between align-items-center pointer ${bg}`} key={key} onClick={() => handleSortClick(value, category, sub)}>
                                            {displayName} {(value === sort) ? <span className="badge badge-light counter digits"><i className={`fa fa-long-arrow-${icon}`} /> </span> : ''}
                                        </a>
                                    )
                                })
                                }
                            </div>
                        </div>
                    </div>
                    <div className="row custom-col permit-box">
                        {
                            data[category][sub] && data[category][sub]?.violations?.map((violation) => {
                                const displayNames = data[category][sub].displayName;

                                return (<div className="col-4 mb-2 d-flex" key={`${category}_${sub}`}>
                                    <div className="card fix-card flex-fill pointer" onClick={() => onViolationClick(violation)}>
                                        <div className="card-body">
                                            <div className="media-body">
                                                <ul>
                                                    {displayNames && displayNames.map((field) => {
                                                        let displayValue = _.get(violation, field.apiField) ? _.get(violation, field.apiField) : violation.violationData[field.apiField];
                                                        // Converting Date
                                                        if (displayValue && ((field.apiField.search(/date/i) !== -1) || (field.displayName.search(/date/i) !== -1)) && moment(displayValue).isValid()) {
                                                            displayValue = moment(displayValue).format('MM/DD/YYYY');
                                                        }
                                                        // Check if we need to add $ sign
                                                        if (displayValue && data[category][sub].dollarAmountFields.includes(field.apiField) && displayValue) {
                                                            displayValue = `${Helper.formatter.format(parseFloat(displayValue))}`;
                                                        }
                                                        return <li>
                                                            <h6 className="title">{field.displayName}</h6>
                                                            <h6 className="content">
                                                                {displayValue}
                                                            </h6>
                                                        </li>
                                                    })}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                )
                            })
                        }
                    </div>
                </>
                )}
            </>)}
        </div>
    </div>
}

export default RetainedListView;
