import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { getRenderFunctionForCustomColumns } from '../../../common/table-violation/utils/buildColumns'
import creService from '../../../services/creService';
import ModalVendors from '../../violations/violationsTable/components/ModalVendors';

const noFields = ['files', 'uploads', 'chat', 'notes', 'priority']

const CustomFields = ({
  customFields,
  subUsers,
  vendors,
  saveOn,
  category,
  sub,
  apiData,
  setRefreshHistory
}) => {

  const [customRecord, setCustomRecord] = useState({})
  const [readyColumns, setReadyColumns] = useState([])
  const [modalVendorColumn, setModalVendorColumn] = useState(null);
  
  useEffect(() => {

    const categoriesCustomOptions = customFields.customFields?.categoriesCustomOptions

    const customFieldsData = apiData?.violationDetails?.customFieldData ? apiData?.violationDetails?.customFieldData[0] || {} : {};
    

    const readyColumns = customFields.customFields?.fields.filter(customColumn => !noFields.includes(customColumn.bcType)).map((customColumn) => {

      return {
        sourceUpload: saveOn,
        subUsers: customColumn.bcType === 'users' && subUsers,
        vendors: customColumn.bcType === 'vendors' && vendors,
        category,
        sub,
        title: customColumn.name,
        key: customColumn._id,
        isCustom: true,
        data: customColumn,
        ...(categoriesCustomOptions?.[customColumn._id] || {}),
      };
    }) || []

    setReadyColumns(readyColumns)

    const customRecord = customFieldsData.fields?.reduce((updatedViolation, field) => {
      updatedViolation[field.fieldId] = field.data;
      updatedViolation[`${field.fieldId}_id`] = field._id;

      return updatedViolation
    }, {}) || {}
    customRecord._id = apiData.violationDetails?._id;
    customRecord.key = apiData.violationDetails?._id;
    customRecord.clientDataId = apiData?.violationDetails?.customFieldData?.[0]?._id
    setCustomRecord(customRecord)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customFields, subUsers, vendors])


  const handleColorLabelsUpdate = (key, newlabels) => {
    setReadyColumns((pre) =>
      pre.map((column) => {
        if (column.key === key) {
          const newcolumn = {
            ...column,
            data: { ...column.data, data: newlabels },
          };


          return newcolumn;
        }
        return column;
      })
    );
  };
  const handleAddCreatedVendor = (key, newVendor) => {
    setReadyColumns((pre) =>
      pre.map((column) => {
        if (column.key === key) {
          const newcolumn = {
            ...column,
            vendors: [...column.vendors, newVendor],
          };

          return newcolumn;
        }
        return column;
      })
    );
  };

  const customDataServerSave = async (id, newData) => {
    try {
      const result = await creService.updateCustomViolationField(
        id,
        newData,
        saveOn
      );
      if (!result.data.status) {
        return toast.error(`${result.data.message}`);
      }
      setRefreshHistory?.()
    } catch (err) {
      toast.error(err.message);
    }
  };

  const handleCustomDataChange = async (id, newData) => {
    await customDataServerSave(id, newData);
    setCustomRecord({ ...customRecord, [newData.fieldId]: newData.data })
  };

  if (readyColumns.length === 0) {
    return null;
  }

  return (
    <>
      <div className="card-body mt-4">
        <div className="row ecb-sub-head ">
          <div className="col-12">
            <h5>Custom Fields</h5>
          </div>
        </div>
        <div className="row ecb-row">
          {readyColumns?.map((column) => {


            return (
              <div className="col-4 mt-2 mb-2 ecb-head " key={column.key}>
                <div className="custom-field-title"><b>{column.title}</b></div>

                {getRenderFunctionForCustomColumns(
                  column, {
                  handleCustomDataChange,
                  handleColorLabelsUpdate,
                  setModalVendorColumn
                })(customRecord[column.key], customRecord)}
              </div>
            );
          })}
        </div>
        <ModalVendors
          modalVendorColumn={modalVendorColumn}
          setModalVendorColumn={setModalVendorColumn}
          handleAddCreatedVendor={handleAddCreatedVendor}
        />
      </div>
    </>
  );
};

export default CustomFields;
