import React, { Fragment, useState, useRef, useEffect } from "react";
import creService from "../../services/creService";
import { toast } from "react-toastify";
import { Link, useHistory } from 'react-router-dom';
import { useParams } from "react-router";
import { permissionsKey } from "../../services/authService";
import ReactLoading from "react-loading";

const VendorsCreate = ({jestData = false}) => {

    const history = useHistory();
    const formRef = useRef();
    const params = useParams();
    const [formData, setFormData] = useState(jestData? jestData : {});
    const vendorId = params.id;
    const editView = vendorId ? true : false;
    const [loading, setLoading] = useState(false);
    const [isCreating, setIsCreating] = useState(false);

    const handleInput = (e) => {
        const { name, value, type } = e.target;
        setFormData(state => ({
            ...state,
            [name]: type === 'date' ? new Date(value) : value
        }));
    };

    const handleOnSubmit = async (e) => {
        setIsCreating(true);
        e.preventDefault();
        let res = {}
        if (editView) {
            res = await creService.updateVendor(formData);
        } else {
            res = await creService.createVendor(formData);
        }
        if (res.data.status) {
            formRef.current.reset();
            toast.success(res.data.message);
            history.push('/vendors');
        } else {
            toast.error(res.data.message);
        }
        if (res.data.permissions) {
            localStorage.setItem(permissionsKey, JSON.stringify(res.data.permissions));
        }
        setIsCreating(false);
    }

    const getVendorData = async (id) => {
        setLoading(true);
        const res = await creService.editVendor(id);
        if (res.data.status) {
            setFormData(res.data.data);
        } else {
            toast.error(res.data.message);
        }
        if (res.data.permissions) {
            localStorage.setItem(permissionsKey, JSON.stringify(res.data.permissions));
        }
        setLoading(false);
    }

    useEffect(() => {
        if (editView) {
            getVendorData(vendorId);
        }
    }, [editView, vendorId])

    return (
        <Fragment>
            <div className="row violation-sec">
                <div className="col-xl-12 my-3">
                    <div className="card">
                        <div className="card-header">
                            <Link to='/vendors'>
                                <button className="btn btn-light btn-lg align-self-center back-btn mx-2" type="button">
                                    <i className="fa fa-angle-left"></i>
                                </button>
                            </Link>
                            <h5>{editView ? 'Edit Vendor' : 'Create Vendor'}</h5>
                        </div>
                        {loading ? (<ReactLoading type={"cylon"} color={"grey"} />) : (
                            <div className="card-body">
                                <form ref={formRef} onSubmit={(e) => handleOnSubmit(e)}>
                                    <div className="row">
                                        <div className="form-group col-12">
                                            <label>Full Name <sup className="text-danger">*</sup></label>
                                            <input type="text" name="fullName" className="form-control" onChange={(e) => handleInput(e)} required defaultValue={formData.fullName}></input>
                                        </div>
                                        <div className="form-group col-12">
                                            <label>Address <sup className="text-danger">*</sup></label>
                                            <input type="text" name="address" className="form-control" onChange={(e) => handleInput(e)} required defaultValue={formData.address}></input>
                                        </div>
                                        <div className="form-group col-12">
                                            <label>Phone <sup className="text-danger">*</sup></label>
                                            <input type="text" name="phone" className="form-control" maxLength={20} onChange={(e) => handleInput(e)} required defaultValue={formData.phone}></input>
                                        </div>
                                        <div className="form-group col-12">
                                            <label>Email <sup className="text-danger">*</sup></label>
                                            <input type="email" name="email" className="form-control" onChange={(e) => handleInput(e)} required defaultValue={formData.email}></input>
                                        </div>
                                        <div className="form-group col-12">
                                            <label>Status <sup className="text-danger">*</sup></label>
                                            <select className="form-control" name="status" required onChange={(e) => handleInput(e)}>
                                                <option value="">-- Select --</option>
                                                <option value="Active" selected={formData.status === "Active" ? true : false}>Active</option>
                                                <option value="NotActive" selected={formData.status === "NotActive" ? true : false}>Not Active</option>
                                            </select>
                                        </div>
                                        <div className="form-group col-12 mb-0">
                                            <button type="submit" disabled={isCreating} className="btn btn-primary">{editView ? 'Update' : 'Add'}</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default VendorsCreate;