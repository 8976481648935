import React, { useEffect, useState } from 'react';
import ReactLoading from "react-loading";
import creService from '../../services/creService';
import ReportFilters from './filters';
import { useParams, useHistory } from 'react-router';
import { toast } from 'react-toastify';
import './index.css'




const Reports = () => {

    const params = useParams();
    const history = useHistory()
    const [currentReport, setCurrentReport] = useState(null);

    useEffect(() => {
        getReportById(params.id);

    }, [params.id]);

    const getReportById = async (id) => {
        try {
            const res = await creService.getReportById(id);
            if (res.data.status) {
                setCurrentReport(res.data.data)
            } else {
                toast.error(res.data.message);
                history.push('/reports');
            }
        } catch (error) {
            toast.error(error.message);
            history.push('/reports');
        }
    }

    const remove = async () => {

        try {
        
            const res = await creService.deleteReport(params.id);
            if (res.data.status) {
                toast.success('Report Cancelled');
            } else {
                toast.error(res.data.message);
            }
        } catch (error) {
            toast.error(error.message);
           
        }
        history.push('/reports');
    }

    return (
        <div className='p-4'>
            {currentReport ?
                <>
                    <h6>
                        <div><strong>REPORT TYPE </strong>- {currentReport.reportType}</div>
                        <div><strong>INCLUDE UPCOMING HEARINGS </strong>- {currentReport.hasECBSections ? "YES" : "NO"}</div>
                        <div><strong>INCLUDE NOTES </strong>- {!currentReport.doNotShowNotes ? "YES" : "NO"}</div>
                        <div><strong>INCLUDE CUSTOM FIELDS </strong>- {currentReport.includeCustomFields ? "YES" : "NO"}</div>
                        <div><strong>BINS </strong>- [{currentReport.buildings.join(', ')}]</div>
                    </h6>
                    {currentReport?.filters && <ReportFilters
                        reportType={currentReport.reportType}
                        filterData={currentReport.filters}
                        disabled
                    />}
                    <div>
                        <button className="btn btn-danger" type="submit" onClick={remove} >[ Unsubscribed/Stop this Report ]</button>
                    </div>


                </> : <ReactLoading />
            }
        </div>
    )
}

export default Reports;
