import React, { useMemo } from 'react';
import {
    NavLink as RouterNavLink,
    Route,
    Switch,
    Redirect,
    useLocation,
} from "react-router-dom";
import Breadcrumb from '../../common/breadcrumb';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import Chats from './chats';
import Reminders from './reminders';
import NotificationNYC from './notification/NYC';
import NotificationPhiladelphia from './notification/Philadelphia';

import WorkOrder from './workOrder';
import Guard from '../../common/guard';
import auth from '../../services/authService';

const Preferences = () => {
    const { pathname } = useLocation();
    const locationEnv = useMemo(() => auth.getCurrentUser()?.locationEnv, []);

    return (
        <div className="container-fluid">
            <Breadcrumb title="Notifications" parent="Preferences" />
            <Nav tabs className="d-flex">
                <NavItem>
                    <NavLink tag={RouterNavLink} to="/preferences/chats" exact>
                        Other
                    </NavLink>
                </NavItem>
                <Guard
                    serviceName="violations"
                    action="all"
                    allow={() => (
                        <>
                            {locationEnv === 'NYC' && <NavItem>
                                <NavLink tag={RouterNavLink} to="/preferences/reminders" exact>
                                    Reminders
                                </NavLink>
                            </NavItem>
                            }
                            <NavItem>
                                <NavLink tag={RouterNavLink} to="/preferences/violations" exact>
                                    Violations
                                </NavLink>
                            </NavItem>
                        </>
                    )}
                />
                <Guard
                    serviceName="workOrders"
                    action={["list", "listSub", "listBin"]}
                    allow={() => (
                        <NavItem>
                            <NavLink tag={RouterNavLink} to="/preferences/work-order" exact>
                                Work Order
                            </NavLink>
                        </NavItem>
                    )}
                />
            </Nav>
            <TabContent activeTab={pathname}>
                <Switch>
                    <Route path="/preferences/chats">
                        <TabPane tabId="/preferences/chats">
                            <div className="table-responsive">
                                <Chats />
                            </div>
                        </TabPane>
                    </Route>
                    {locationEnv === 'NYC' &&
                        <Route path="/preferences/reminders">
                            <Guard
                                serviceName="violations"
                                action="all"
                                allow={() => (
                                    <TabPane tabId="/preferences/reminders">
                                        <div className="table-responsive">
                                            <Reminders />
                                        </div>
                                    </TabPane>
                                )}
                            />

                        </Route>
                    }
                    <Route path="/preferences/violations">
                        <Guard
                            serviceName="violations"
                            action="all"
                            allow={() => (
                                <TabPane tabId="/preferences/violations">
                                    <div className="table-responsive">
                                        {locationEnv === 'Philadelphia' ? <NotificationPhiladelphia /> : <NotificationNYC />}
                                    </div>
                                </TabPane>
                            )}
                        />
                    </Route>
                    <Route path="/preferences/work-order">
                        <Guard
                            serviceName="workOrders"
                            action={["list", "listSub", "listBin"]}
                            allow={() => (
                                <TabPane tabId="/preferences/work-order">
                                    <div className="table-responsive">
                                        <WorkOrder />
                                    </div>
                                </TabPane>
                            )}
                        />
                    </Route>
                    <Guard
                        serviceName="violations"
                        action="all"
                        allow={() => (
                            <Redirect from="/preferences" to="/preferences/chats" />
                        )}
                        deny={() => (
                            <Redirect from="/preferences" to="/preferences/chats" />
                        )}
                    />
                </Switch>
            </TabContent>
        </div >
    );
};

export default Preferences;
