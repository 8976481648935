import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';

import creService from '../../services/creService';
import Breadcrumb from '../../common/breadcrumb';

const RemoveMobile = () => {

    const [loading, setLoading] = useState(false)

    const requestRemoveData = async () => {
        setLoading(true)
        try {

            const { data } = await creService.requestDataRemoveMobileApp()
            if (!data.status) {
                toast.error(data.message)
            } else {
                toast.success(data.message)
            }
        } catch (err) {
            toast.error(err.message)
        }
        setLoading(false)
    }

    return (
        <>
            <Breadcrumb title="User Options" parent="Home" />
            <div className="container-fluid">

                <div>
                    <Button className="btn btn-primary mt-3" onClick={requestRemoveData} disabled={loading}>
                        {loading ? 'Sending...' : 'Request remove data'}
                    </Button>
                </div>

            </div>
        </>
    );
}

export default RemoveMobile;
