import { settingsKey } from './authService';

const getColorData = () => {
  const userSettings =
    JSON.parse(localStorage.getItem(settingsKey))?.colors || {};
  const allColors = [
    '#ff4545',
    '#eb6c23',
    '#ffc533',
    '#65c92a',
    '#175dad',
    '#872ade',
    '#cc6299',
    '#613c22',
    '#000000',
  ];
  let colorsWithLabel = [];
  for (const colorCode of allColors) {
    let color = {};
    color.colorCode = colorCode;
    color.colorLabel = userSettings[colorCode]?.label || '';
    colorsWithLabel.push(color);
  }
  return colorsWithLabel;
};

const getColorLabel = (colorCode) => {
  if (!colorCode) return '';

  const userSettings = JSON.parse(localStorage.getItem(settingsKey))?.colors;
  if (!userSettings) {
    return colorCode;
  }

  return (
    userSettings[`${colorCode[0] === '#' ? '' : '#' + colorCode}`]?.label ||
    `No Label (${colorCode})`
  );
};

export { getColorData, getColorLabel };
