import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import creService from '../../services/creService';
import ReactLoading from "react-loading";
import { useHistory } from "react-router-dom";
import Breadcrumb from '../../common/breadcrumb';
import { Button } from 'reactstrap';
import { confirmAlert } from 'react-confirm-alert'; // Import

const SubUsers = (props) => {

    const history = useHistory();
    const [subUsers, setSubUsers] = useState(props.jestData !== undefined ? props.jestData.subUsers : []);
    const [doneLoading, setDoneLoading] = useState(false);
    const [isLoadingInvite, setIsLoadingInvite] = useState(false);

    const getSubUsers = async () => {
        const res = await creService.getSubUsers();
        if (res.data.status) {
            setSubUsers(res.data.data);

            setDoneLoading(true);
        } else {
            toast.error(res.data.message);
        }
    }

    const handleDelete = async (userId) => {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        try {
                            const res = await creService.deleteStaff(userId);
                            if (res.data.status) {
                                toast.success(res.data.message);
                                getSubUsers();
                            } else {
                                toast.error(res.data.message);
                            }
                        } catch (_err) {
                            toast.error(_err.message);
                        }
                    }
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    }
    const resendInvitation = async (subUserId) => {
        setIsLoadingInvite(true);
        try {
            const res = await creService.sendPasswordReset(subUserId);
            if (res.data.status) {
                toast.success(res.data.message);
            } else {
                toast.error(res.data.message);
            }
        } catch (err) {
            toast.error(err.message);
        }
        setIsLoadingInvite(false);
    };
    const handleEdit = async (userId) => {
        history.push(`/staff/edit/${userId}`)
    }

    useEffect(() => {
        getSubUsers();
    }, [])

    return (
        <>
            <Breadcrumb title="Sub Users" parent="Home" />
            <div className="container-fluid">
                <table className="table table-borderless">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Full Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Status</th>
                            <th scope="col">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {subUsers.map((subUser, index) => {
                            return (
                                <tr key={index}>
                                    <th scope="row">{index + 1}</th>
                                    <td>{subUser.fullName}</td>
                                    <td>
                                        {subUser.email}
                                        {subUser.status !== 'Active' && <>
                                            <button
                                                onClick={() => resendInvitation(subUser._id)}
                                                className="btn btn-outline-primary btn-xs d-block"
                                                disabled={isLoadingInvite}
                                            >
                                                Resend Invite
                                            </button>
                                            <small>Pending Acceptance</small></>}
                                    </td>
                                    <td>{subUser.status}</td>
                                    <td>
                                        <button className="btn btn-warning btn-xs mr-1" disabled={props.disabled} onClick={() => handleEdit(subUser._id)}><i className="fa fa-edit"></i></button>
                                        <button className="btn btn-danger btn-xs" disabled={props.disabled} onClick={() => handleDelete(subUser._id)}><i className="fa fa-trash"></i></button>

                                    </td>
                                </tr>
                            )
                        })}
                        {!doneLoading && (
                            <tr>
                                <td colSpan={5}>
                                    <ReactLoading type={"cylon"} color={"grey"} />
                                </td>
                            </tr>
                        )}
                        {doneLoading && subUsers.length <= 0 &&
                            <tr>
                                <td colSpan={5} className="text-center">
                                    No sub users available.
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
                <Button onClick={() => history.push('/staff/create')} className="btn btn-primary mt-3">
                    <i className="fa fa-plus"></i> Add User
                </Button>
            </div>
        </>
    );
}

export default SubUsers;
