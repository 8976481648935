import React, { useState } from 'react';
import { Checkbox } from 'antd';
import { Button } from 'reactstrap';
import creService from '../../services/creService';
import { settingsKey } from '../../services/authService'
import { toast } from 'react-toastify';

import './userOptions.css'
import Breadcrumb from '../../common/breadcrumb';

const UserOptions = () => {

    const [options, setOptions] = useState({ ...JSON.parse(localStorage.getItem(settingsKey)) })

    const saveHandler = async () => {
        try {
            const { data } = await creService.updateAccountSetting(options)
            if (!data.status) {
                toast.error(data.message)
            } else {
                toast.success(data.message)
                localStorage.setItem(settingsKey, JSON.stringify(options));
                window.location.reload();
            }
        } catch (err) {
            toast.error(err.message)
        }

    }

    return (
        <>
            <Breadcrumb title="User Options" parent="Home" />
            <div className="container-fluid">
                <div >
                    <div className='mt-2 d-flex align-items-center'>
                        <span className='mr-2 min-width-120'> Google translater </span>
                        <Checkbox
                            checked={options.googleTranslater}
                            onChange={() => {
                                setOptions({ ...options, googleTranslater: !options.googleTranslater })
                            }} />
                    </div>
                    {/* <div className='mt-2 d-flex align-items-center'>
                        <span className='mr-2 min-width-120'> Page Zoom</span>
                        <Select
                            value={options.zoom}
                            onChange={(value) => {
                                setOptions({ ...options, zoom: value })
                            }}
                            options={[
                                { value: '80', label: '80%' },
                                { value: '90', label: '90%' },
                                { value: '100', label: '100%' },
                                { value: '110', label: '110%' },
                                { value: '120', label: '120%' },
                            ]}
                        />


                    </div> */}
                </div>
                <div>
                    <Button className="btn btn-primary mt-3" onClick={saveHandler}>
                        <i className="fa fa-save" ></i> Save Options
                    </Button>
                </div>

            </div>
        </>
    );
}

export default UserOptions;
