import React from "react";
import { Route, Redirect } from "react-router-dom";
import auth from "../services/authService";
import WordOrderLayout from '../pages/workOrders/layout';
import RedirectWrapper from "../pages/workOrders/redirectWrapper";
import { socket, SocketContext } from "../../context/socket";
import Guard from "../common/guard";


const ProtectedRoute = ({ component: Component, layout: Layout, serviceName, action, masterUserOnly, ...rest }) => {
  const user = auth.getCurrentUser();
  return (
    <Route
      {...rest}
      render={props => {
        if (!user) {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location }
              }}
            />
          );
        }
        if (masterUserOnly && user?.isSubUser) {
          return (
            <Redirect
              to={{
                pathname: "/",
                state: { from: props.location }
              }}
            />
          );
        }

        if (!Layout) {
          return (
            <Route {...rest} render={(props) =>
              <Component {...props} />
            } />
          )
        }

        return (
          <Route {...rest} render={(props) =>
            <SocketContext.Provider value={socket()}>
              <Layout {...props} {...rest}>
                {!serviceName && <Component {...props} />}
                {serviceName && <Guard
                  serviceName={serviceName}
                  action={action}
                  allow={() => {
                    switch (serviceName) {
                      case 'workOrders': {
                        return (
                          <WordOrderLayout serviceName={serviceName} action={action} {...rest}>
                            <Component {...props} />
                          </WordOrderLayout>
                        )
                      }
                      default: {
                        return <Component {...props} />;
                      }
                    }
                  }}
                  deny={() => {
                    return <RedirectWrapper path={"/"} />;
                  }}
                />}
              </Layout>
            </SocketContext.Provider>
          } />
        )
      }}
    />
  );
};

export default ProtectedRoute;
