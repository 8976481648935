import React, { Fragment, useState, useEffect, useMemo } from "react";
import { useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import { Modal, Input, Button } from "antd";
import { PlusCircleOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import ReactDragListView from 'react-drag-listview';
import { Collapse } from 'reactstrap';

import HideColumnDropDown from '../../common/table-violation/components/HideColumnDropDown'
import CustomCompliance from './custom';
import auth from '../../services/authService';
import creService from "../../services/creService";
import LocalState from '../../common/localState';
import { settingsKey } from '../../services/authService'
import DueThisYear from '../requirements/compilance'

const defaultNewCategoryValue = { name: '', additionalKeys: [] }
const diffname = {
    'FACADE': 'LL11',
    'BENCHMARKING': 'LL84',
    'ENERGY_AUDIT': 'LL87',
    'GAS_PIPING_INSPECTION': 'LL152',
    'LL97': 'LL97',
}
// ENERGY_AUDIT FACADE GAS_PIPING_INSPECTION INDOOR_ALLERGEN_HAZARD

const Compliance = (props) => {
    const params = useParams();
    const locationEnv = useMemo(() => auth.getCurrentUser()?.locationEnv, []);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [customCompliances, setCustomCompliances] = useState([]);
    const [customCompliancesRaw, setCustomCompliancesRaw] = useState([]);
    const [currentCustomCompliance, setCurrentCustomCompliance] = useState(null)
    const [currentGuide, setCurrentGuide] = useState(null)
    const [newCategory, setNewCategory] = useState(defaultNewCategoryValue);
    const [viewType, setViewType] = useState(
        LocalState.getViewTypeSetting('custom-compliance', 'list')
    );
    const [options, setOptions] = useState({ ...JSON.parse(localStorage.getItem(settingsKey)) })


    const [isModalNameVisible, setIsModalNameVisible] = useState(false);
    const [newName, setNewName] = useState('');
    const [addedDevices, setAddedDevices] = useState([])

    const [collapseDue, setCollapseDue] = useState(false);

    useEffect(() => {
        if (customCompliancesRaw.length) {
            const newItems = customCompliancesRaw.map(el => {
                el.name = el.name.trim()
                return { ...el, ...(options.customCompliance?.[el.name] || {}) }
            })
            setCustomCompliances(newItems)
            if (!currentCustomCompliance) {
                setCurrentCustomCompliance(newItems.find(el => el.name === params.category) || newItems.find(el => !el.hide))
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customCompliancesRaw, options])

    useEffect(() => {
        if (currentCustomCompliance) {
            getGuide(currentCustomCompliance.name)
        }
    }, [currentCustomCompliance])


    const getGuide = async (category) => {
        try {

            const response = await creService.getComplianceInfo(category);
            if (response.data.status) {
                setCurrentGuide(response.data.complianceInfo)
            } else {
                toast.error(response.data.message)
            }
        } catch (e) {
            toast.error(e.message)
        }
    }

    const switchToggle = (type) => {
        LocalState.setViewTypeSetting('custom-compliance', type)
        setViewType(type);
    };

    const additionalKeysAdd = () => {
        const newValue = {
            ...newCategory, additionalKeys: [...newCategory.additionalKeys, {
                key: '',
                label: ''
            }]
        }
        setNewCategory(newValue);
    }
    const additionalKeysRemove = (index) => {

        const additionalKeys = [...newCategory.additionalKeys.slice(0, index), ...newCategory.additionalKeys.slice(index + 1)]
        setNewCategory({
            ...newCategory, additionalKeys
        });
    }

    const handleMenuClick = async (compliance) => {
        setCurrentCustomCompliance(compliance)
        props.history.push(`/custom-compliance/${compliance.name}`)
    }


    const loadConfig = async () => {
        try {
            const allCustomCompliances = await creService.getCustomComplianceGetAll();
            setCustomCompliancesRaw(allCustomCompliances.data);

        } catch (err) {
            toast.error(err)
        }

    }
    const openNameModal = () => {
        setNewName(currentCustomCompliance.displayName || currentCustomCompliance.name.replaceAll('_', ' '))
        setIsModalNameVisible(true)
    }
    const onAddKeyLabelChange = (index, value) => {
        setNewCategory({
            ...newCategory, additionalKeys: newCategory.additionalKeys.map((el, i) => {
                if (index === i) {
                    el.label = value
                }
                return el
            })
        })
    }
    const handleToggleVisibility = async (name) => updateOptions({
        ...options,
        customCompliance: {
            ...(options.customCompliance || {}),
            [name]: { ...(options.customCompliance?.[name] || {}), hide: !options.customCompliance?.[name]?.hide }
        }
    })


    const dragEndHandler = async (fromIndex, toIndex) => {
        if (toIndex < 0) {
            return;
        }
        let startIndex;
        let endIndex;
        const sorted = customCompliances.sort((a, b) => a.order - b.order);
        const showed = customCompliances.filter(el => !el.hide)

        for (let i = 0; i < sorted.length; i++) {
            if (startIndex && endIndex) break;
            if (showed[fromIndex].name === sorted[i].name) {
                startIndex = i;
            } else if (showed[toIndex].name === sorted[i].name) {
                endIndex = i;
            }
        }

        const optionsOrder = sorted.map((column, i) => ({ order: column.order ? column.order : i, name: column.name }))

        if (startIndex > endIndex) {
            for (let i = endIndex; i < startIndex; i++) {
                optionsOrder[i].order = (optionsOrder[i].order || i) + 1;
            }
        } else {
            for (let i = endIndex; i > startIndex; i--) {
                optionsOrder[i].order = (optionsOrder[i].order || i) - 1;
            }
        }

        optionsOrder[startIndex].order = endIndex;

        updateOptions({
            ...options,
            customCompliance: {
                ...optionsOrder.reduce((ac, a) => {
                    if (!ac[a.name]) ac[a.name] = {}
                    ac[a.name].order = a.order
                    return ac
                }, options.customCompliance || {})

            }
        })
    }

    const updateOptions = async (updated) => {

        try {
            const response = await creService.updateAccountSetting(updated);
            if (response.data.status) {
                setOptions(updated)
                localStorage.setItem(settingsKey, JSON.stringify(updated));

            } else {
                toast.error(response.data.message)
            }
        } catch (e) {
            toast.error(e.message)
        }

    }

    const onNameUpdate = async () => {
        try {
            const payload = {
                name: newName
            }
            const { data } = await creService.updateCustomCompliance(currentCustomCompliance._id, payload)
            if (data.status) {
                setIsModalNameVisible(false)
                setCurrentCustomCompliance({ ...currentCustomCompliance, displayName: newName })
                loadConfig()
            } else {
                toast.error(data.error?.details?.[0]?.message || data.message)
            }

        } catch (err) {
            toast.error(err.message)
        }
    }

    const onCategorySave = async () => {
        try {
            let invalidKey
            const payload = {
                ...newCategory, additionalKeys: newCategory.additionalKeys.map((el, i) => {
                    el.key = el.label.replace(/[^a-zA-Z0-9]/g, "").toLowerCase().trim().split(' ').join('_')
                    if (!el.key) { invalidKey = true }
                    return el
                })
            }
            if (invalidKey) return toast.error('Invalid Field Name')

            const { data } = await creService.createCustomCategoryCompliance(payload)
            if (data.status) {
                setNewCategory(defaultNewCategoryValue)
                setIsModalVisible(false)
                loadConfig()
                toast.success('New category created')
            } else {
                toast.error(data.error?.details?.[0]?.message || data.message)
            }

        } catch (err) {
            toast.error(err.message)
        }


    }
    const removeCategoryHandler = async (id) => {
        try {
            const { data } = await creService.deleteCustomComplianceCategory(id)
            if (data.status) {
                toast.success(data.message);
                loadConfig()
                setCurrentCustomCompliance(null)
            } else {
                toast.error(data.message);
            }

        } catch (err) {
            toast.error(err.message);
        }
    }


    useEffect(() => {
        loadConfig();
        // eslint-disable-next-line
    }, [])

    let board = currentCustomCompliance?.name.toUpperCase()
    board = diffname[board] || board

    return (
        <Fragment>
            <div className="container-fluid pl-0 pr-0">
                <div className="row violation-sec w-100 ml-0">
                    <div className="compliance   w-100">
                        <div className="card">
                            <div className="card-header d-flex justify-content-between">
                                <div>
                                    <div className="d-flex align-items-end">
                                        <h5>Compliance -  {currentCustomCompliance?.name && <>{currentCustomCompliance.displayName ? currentCustomCompliance.displayName : currentCustomCompliance.name.replaceAll('_', ' ')}</>}</h5>
                                        {currentCustomCompliance?.name && <i onClick={openNameModal} className="fa fa-edit pointer ml-2 mb-1"></i>}
                                    </div>
                                    {currentCustomCompliance?.displayName && <small >Original Name - {currentCustomCompliance.name.replaceAll('_', ' ')} </small>}
                                </div>


                                <div className="d-flex align-items-center">
                                    <Button className="hide-btn-wrapper mr-2" size='small' onClick={() => setIsModalVisible(true)} >
                                        <PlusOutlined />
                                        Add Compliance
                                    </Button>
                                    <HideColumnDropDown columns={customCompliances} handleToggleVisibility={handleToggleVisibility} size='small' />


                                    <div id="btnContainer" className="mr-3 ml-3">
                                        <button
                                            className={`btn mr-2 ${viewType === 'list' ? 'active' : ''
                                                }`}
                                            onClick={() => switchToggle('list')}
                                        >
                                            <i className="fa fa-bars"></i>
                                        </button>
                                        <button
                                            className={`btn mr-2 ${viewType === 'column' ? 'active' : ''
                                                }`}
                                            onClick={() => switchToggle('column')}
                                        >
                                            <i className="fa fa-columns"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <ul className="custom_tab tab-sec w-100 flex-wrap">
                                    <ReactDragListView.DragColumn
                                        nodeSelector=".drag"
                                        handleSelector=".dragHandler"
                                        onDragEnd={dragEndHandler}
                                    >
                                        {customCompliances.length > 0 &&
                                            customCompliances
                                                .filter(el => !el.hide)
                                                .sort((a, b) => a.order - b.order)
                                                .map((customCompliance) => (
                                                    <li
                                                        key={`custom_menu_${customCompliance.name}`}
                                                        className={`dragHandler drag ${currentCustomCompliance?.name === customCompliance.name ? "active" : ""}`}
                                                        onClick={() => handleMenuClick(customCompliance)}>
                                                        {customCompliance.displayName ? customCompliance.displayName : customCompliance.name.replaceAll('_', ' ')}
                                                    </li>
                                                )
                                                )}
                                    </ReactDragListView.DragColumn>

                                </ul>
                                {currentGuide && locationEnv !== 'Philadelphia' && <div className="custom_tab tab-sec" style={{ padding: '10px 30px' }}>
                                    <div dangerouslySetInnerHTML={{ __html: currentGuide.text }} />
                                </div>
                                }

                                {currentCustomCompliance && <CustomCompliance
                                    setCollapseDue={setCollapseDue}
                                    viewType={viewType}
                                    customCompliance={currentCustomCompliance}
                                    removeCategory={removeCategoryHandler}
                                    currentGuideExist={!!currentGuide}
                                    setAddedDevices={setAddedDevices}
                                />

                                }
                            </div>

                        </div>

                        {currentCustomCompliance && locationEnv !== 'Philadelphia' &&
                            ['BOILER', 'DEP_BOILER', 'HPD_REGISTRATION', 'CAT1', 'CAT5', 'LL11', 'LL84', 'LL87', 'LL97', 'LL152', 'LEAD', 'MOLD']
                                .includes(board) && (<div className="card">
                                    <div className="card-header  p-3">
                                        <h6 onClick={() => setCollapseDue(!collapseDue)} className="pointer">
                                            <span className="mr-2">
                                                {collapseDue ? '-' : '+'}
                                            </span>
                                            Due this year
                                        </h6>
                                    </div>

                                    <Collapse isOpen={collapseDue}>
                                        <div className="card-body" id="guide-table">
                                            <DueThisYear board={board} noGuide noStatus addedDevices={addedDevices} />
                                        </div>
                                    </Collapse>

                                </div>
                            )}

                    </div>
                </div>
                <Modal
                    title="Add New Compliance Category"
                    visible={isModalVisible}
                    footer={null}
                    onCancel={() => setIsModalVisible(false)}
                    width={1000}
                >
                    <div className="p-2">

                        <Input
                            value={newCategory.name}
                            onChange={(event) => setNewCategory({ ...newCategory, name: event.target.value })}
                            addonBefore='Category Name'
                        />

                        <div className='mt-4 w-100'>
                            <div className='d-flex'>
                                <PlusCircleOutlined className='pointer mr-2 mb-3' style={{ fontSize: '20px' }} onClick={additionalKeysAdd} />
                                Optional Field
                            </div>

                            {
                                newCategory.additionalKeys.map((key, i) => (
                                    <div key={i} className='d-flex align-items-center mb-1'>
                                        <DeleteOutlined className="mr-2 pointer" onClick={() => additionalKeysRemove(i)} />
                                        <Input
                                            value={newCategory.additionalKeys[i].label}
                                            onChange={(event) => onAddKeyLabelChange(i, event.target.value)}
                                            addonBefore='Field Name'
                                            className="pr-2"
                                            placeHolder="(ex: Device#, Boiler ID, etc.)"
                                        />
                                    </div>
                                ))
                            }
                        </div>
                        <div className="d-flex justify-content-between mt-4">
                            <button type="button" className="btn btn-primary" onClick={() => setIsModalVisible(false)}>Cancel</button>
                            <button type="button" className="btn btn-primary" onClick={onCategorySave}>Save</button>

                        </div>

                    </div>
                </Modal>
                <Modal
                    title="Change Category Name"
                    visible={isModalNameVisible}
                    footer={null}
                    onCancel={() => setIsModalNameVisible(false)}
                    width={1000}
                >
                    <div className="p-2">
                        <Input
                            value={newName}
                            onChange={(event) => setNewName(event.target.value)}
                            addonBefore='Category Name'
                        />
                        <div className="d-flex justify-content-between mt-4">
                            <button type="button" className="btn btn-primary" onClick={() => setIsModalNameVisible(false)}>Cancel</button>
                            <button type="button" className="btn btn-primary" onClick={onNameUpdate}>Save</button>

                        </div>

                    </div>
                </Modal>
            </div>

        </Fragment>
    );
};

export default Compliance
