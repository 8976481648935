import React, { Fragment, useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import { Star } from 'react-feather';
import { toast } from 'react-toastify';

import RecentActivityListGroupCollapse from './recentActivityListGroupCollapse';
import creApi from './../../../services/creService';
import auth from '../../../services/authService';

const user = auth.getCurrentUser();

const RecentActivity = (props) => {
  const [doneLoading, setLoadingState] = useState(
    props.jestData === undefined ? false : true
  );
  const [data, setData] = useState(
    props.jestData === undefined ? [] : props.jestData
  );

  useEffect(() => {
    async function fetchData() {
      try {
        let recentActivity;
        if (props.type === 'buildingActivity') {
          recentActivity = await creApi.getRecentActivity(props.bin);
          setLoadingState(true);
          setData(recentActivity.data.violationHistoryDetails);
        } else if (props.type === 'buildingCategroySubActivity') {
          recentActivity = await creApi.getRecentActivity(
            props.bin,
            props.category,
            props.sub
          );
          setLoadingState(true);
          setData(recentActivity.data.violationHistoryDetails);
        } else if (props.type === 'violationActivity') {
          if (props.apiData) {
            recentActivity = await creApi.getRecentActivityPerViolation(
              props.apiData.violationDetails._id,
              props.saveOn
            );
            setLoadingState(true);
            setData(recentActivity.data);
          }
        } else {
          let bin = null;
          if (bin) {
            bin = props.apiData?.violationDetails?.bin;
          }
          recentActivity = await creApi.getRecentActivityAll(bin);
          setLoadingState(true);
          setData(recentActivity.data);
        }
      } catch (e) {
        toast.error(e.message);
      }
    }
    fetchData();
    //   eslint-disable-next-line
  }, [props.apiData, props.refreshHistory]);



  function handleClick(activityRow) {

    if (activityRow.category === 'custom-compliance') {
      props.history?.push(
        `/custom-compliance/${activityRow.sub}/${activityRow.violation_id}`
      );
    } else {
      props.history?.push(
        `/violation-details/${activityRow.violation_id}/notes`
      );
    }

  }

  return (
    <Fragment>
      <div className="card user-card">
        <div className="card-header p-3">
          <div className="online-user">
            <h5 className="f-18 mb-0">Recent Activity</h5>
          </div>
        </div>
        <div
          className={
            'card-body activity-scroll mt-0 p-3'
          }
        >
          <div className="activity">
            {!doneLoading ? (
              <div align="center">
                {' '}
                <ReactLoading type={'cylon'} color={'grey'} />{' '}
              </div>
            ) : data && data.length > 0 ? (
              data &&
              data.map((activityRow, index) => {
                let dateString = new Date(activityRow.added_at);
                let VPUHistory = activityRow.VPUHistory?.find((el) =>
                  el.clients.includes(user._id)
                );

                if (activityRow.isBrandNew) {
                  return (
                    <div
                      className="media badge-light mb-3 flex-column"
                      key={index}
                    >
                      <div className="media-body w-100">
                        <h6
                          className="mb-0"
                          onClick={() => handleClick(activityRow)}
                        >
                          {activityRow.fromScrapper && (
                            <Star color="#999" size={12} />
                          )}
                          <div className="mb-3">
                            <span className="font-weight-normal badge badge-light">
                              {activityRow.category !== 'custom-compliance' && `${activityRow.category}/`}
                              {activityRow.sub.replace(/_/g, ' ')}
                              <br />
                              <small>
                                <span>
                                  {activityRow?.uniqueData?.displayAs}:{' '}
                                </span>
                                {activityRow?.uniqueData?.value?.length > 25
                                  ? activityRow?.uniqueData?.value
                                    .substr(0, 25)
                                    .concat('...')
                                  : activityRow?.uniqueData?.value}
                              </small>
                            </span>
                            <span className="badge text-white filling-status-btn">
                              New Record
                            </span>
                            <div className="clearfix"></div>
                          </div>
                          <div className="d-flex mb-0 justify-content-between position-relative mb-3">
                            <span className="f-14 filling-status font-weight-bold"></span>
                            <span
                              className="pull-right f-14 position-absolute"
                              style={{ right: 0 }}
                            >
                              {dateString.toDateString()}
                            </span>
                            <div className="clearfix"></div>
                          </div>
                        </h6>
                      </div>
                      <ul className="list-group w-100">
                        <RecentActivityListGroupCollapse VPU={VPUHistory} />
                      </ul>
                    </div>
                  );
                } else {
                  const uniqValue =
                    activityRow?.uniqueData?.value || activityRow?.key?.value;

                  return (
                    <div
                      className="media badge-light mb-3 flex-column"
                      key={index}
                    >
                      <div className="w-100">
                        <div className="media-body">
                          <h6
                            className="m-b-0"
                            onClick={() => handleClick(activityRow)}
                          >
                            {activityRow.fromScrapper && (
                              <Star color="#999" size={12} />
                            )}
                            <div className="mb-3">
                              <span className="font-weight-normal badge badge-light">
                                {activityRow.category !== 'custom-compliance' && `${activityRow.category}/`}
                                {activityRow.sub.replace(/_/g, ' ')}
                                <br />
                                <small>
                                  <span>
                                    {activityRow?.uniqueData?.displayAs}:{' '}
                                  </span>
                                  {uniqValue?.length > 25
                                    ? activityRow?.uniqueData?.value
                                      .substr(0, 25)
                                      .concat('...')
                                    : uniqValue}
                                </small>
                              </span>
                              <span className="badge badge-secondary text-white">
                                Change Detected
                              </span>
                              <div className="clearfix"></div>
                              {activityRow.manuallyChangedBy && !activityRow.sub?.includes('Closed') && <div className='badge pt-1'>Changed by {activityRow.manuallyChangedBy.name}</div>}
                            </div>

                            <div className="d-flex mb-0 justify-content-between position-relative">
                              <span className="f-14 filling-status font-weight-bold">
                                View Changes
                              </span>
                              <span
                                className="pull-right f-14 position-absolute"
                                style={{ right: 0 }}
                              >
                                {dateString.toDateString()}
                              </span>
                              <div className="clearfix"></div>
                            </div>
                          </h6>
                          <ul className="list-group">
                            {Object.keys(activityRow.difference || {}).map(function (
                              item,
                              index
                            ) {
                              return (
                                <RecentActivityListGroupCollapse
                                  key={index}
                                  Data={{
                                    title: activityRow.convetedData?.[item] || item,
                                    titleKey: item,
                                    difference: activityRow.difference[item],
                                  }}
                                />
                              );
                            })}
                            <RecentActivityListGroupCollapse VPU={VPUHistory} />
                          </ul>
                        </div>
                      </div>
                    </div>
                  );
                }
              })
            ) : (
              <span className="font-weight-light text-muted">
                No Activity yet
              </span>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default RecentActivity;
