import React, { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";
import creApi from "../../services/creService";
import SmallCalendar from "../calendar/smallCalendar";
import moment from "moment";
import ReactLoading from "react-loading";
import { Link } from 'react-router-dom';
import auth from '../../services/authService';
import HistoryUpdates from '../buildings/partials/historyUpdates'
import { useHistory, useLocation } from "react-router-dom";
import { exportViolationEvents } from '../../services/exportExcel';
import Pagination from '../../common/forms/pagination';
import { servicesAccessKey } from "../../services/authService";
import DocsViewer from '../../common/document-viewer'

const UploadsAndNotes = (props) => {
    const user = props.jestData !== undefined ? props.jestData.user : auth.getCurrentUser();

    const history = useHistory()
    const location = useLocation();

    const servicesAccess = JSON.parse(localStorage.getItem(servicesAccessKey) || '{}');

    const documentsService = servicesAccess?.documents || {};

    const queryParams = new URLSearchParams(location.search);

    const urlParams = new URLSearchParams(window.location.search);
    const notesCurrentPage = queryParams.get('notePage') ? parseInt(queryParams.get('notePage')) : 1;
    const uploadsCurrentPage = queryParams.get('uploadsPage') ? parseInt(queryParams.get('uploadsPage')) : 1;

    const [isPrivate, setIsPrivate] = useState(!documentsService.disablePrivateUploadsForSubUser);
    const [loadingFiles, setLoadingFiles] = useState(props.jestData !== undefined ? false : true);
    const [loadingNotes, setLoadingNotes] = useState(props.jestData !== undefined ? false : true);
    const [uploadedFilesList, setUploadedFilesList] = useState(props.jestData !== undefined ? props.jestData.uploadedFilesList : []);
    const [notesList, setNotesList] = useState(props.jestData !== undefined ? props.jestData.notesList : []);
    const [uploadPercent] = useState(0);
    const [isUploading] = useState(false);
    const [totalNotes, setTotalNotes] = useState(0);
    const [totalUploads, setTotalUploads] = useState(0);

    const [currentfile, setCurrentfile] = useState(null);
    const [docsViewerVisibility, setDocsViewerVisibility] = useState(false);

    const loadFiles = async (sortBy, sortDirection, page) => {
        setLoadingFiles(true);
        try {
            const { data } = await creApi.getAllDocuments('FILE', sortBy, sortDirection, isPrivate, page);
            setUploadedFilesList(data.data)
            setTotalUploads(data.totalCount);
            setLoadingFiles(false);
        } catch (e) {
            toast.error(e.message)
            setLoadingFiles(false);
        }
    }

    const loadNotes = async (sortBy, sortDirection, page) => {
        setLoadingNotes(true);
        try { 
            const { data } = await creApi.getNotes(null, page, sortBy, sortDirection);
            setNotesList(data.data)
            setTotalNotes(data.totalCount);
            setLoadingNotes(false);
        } catch (e) {
            toast.error(e.message)
            setLoadingNotes(false);
        }
    }

    useEffect(() => {
        loadFiles(urlParams.get('sortBy'), urlParams.get('sortDirection') ? urlParams.get('sortDirection') : 'asc', uploadsCurrentPage);
        loadNotes(urlParams.get('notesSortBy'), urlParams.get('sortDirection') ? urlParams.get('notesSortDirection') : 'asc', notesCurrentPage);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleVisibilityChange = async (documentId, isPrivate) => {
        try {
            const { data } = await creApi.changeDocumentVisibility(documentId, isPrivate);
            if (data.status) {
                toast.success(data.message);
                setUploadedFilesList((oldList) => oldList.map((doc) => {
                    if (doc._id === documentId) {
                        doc.isPrivate = isPrivate
                    }
                    return doc;
                }));
            } else {
                toast.error(data.message);
                setUploadedFilesList((oldList) => oldList.map((doc) => {
                    if (doc._id === documentId) {
                        doc.isPrivate = !isPrivate
                    }
                    return doc;
                }))
            }
        } catch (_error) {
            toast.error('There was some problems in updating the document visibility.');
        }
    }

    const handleUrlClick = async (document) => {
        setCurrentfile(document)
        setDocsViewerVisibility(true)
    }

    const toggleSort = (sortBy, isNotes) => {
       
       
        if (isNotes) {
            const sortDirection = urlParams.get('notesSortDirection') ? urlParams.get('notesSortDirection') : 'asc';
            history.push(`/uploads-and-notes?notesSortBy=${sortBy}&notesSortDirection=${sortDirection === 'asc' ? 'desc' : 'asc'}`)
            loadNotes(sortBy, sortDirection)
        } else {
            const sortDirection = urlParams.get('sortDirection') ? urlParams.get('sortDirection') : 'asc';
            history.push(`/uploads-and-notes?sortBy=${sortBy}&sortDirection=${sortDirection === 'asc' ? 'desc' : 'asc'}`)
            loadFiles(sortBy, sortDirection);
        } 
    }

    const exportCsv = () => {
        const column = [
            { header: 'Violation', key: 'violation' },
            { header: 'Category', key: 'category' },
            { header: 'Sub', key: 'sub' },
            { header: 'File', key: 'originalname' },
            { header: 'Address', key: 'address' },
            { header: 'Uploaded Date', key: 'createdAt' },
            { header: 'Visibility', key: 'isPrivate' }
        ]

        let data = [];
        uploadedFilesList.map((value) => {
            let file = {}
            file.originalname = value.file.originalname;
            file.createdAt = moment.utc(value.createdAt).format('MM/DD/YYYY');
            file.address = value.building?.address;
            file.category = value.category;
            file.sub = value.sub ? value.sub.replace(/__/g, ' ').replace(/_/g, ' ') : value.sub;
            file.violation = value.identifier ? `${value.identifier.displayAs}: ${value.identifier.value}` : 'Custom'
            file.isPrivate = value.isPrivate ? 'Private' : 'Public';
            data.push(file)
            return true;
        })
        exportViolationEvents(column, data, 'Uploaded Files');
    }

    const onNotesPageChange = (page) => {
        queryParams.set('notePage', page);
        history.push(`uploads-and-notes?${queryParams.toString()}`);
        loadNotes(page);
    }

    const onUploadsPageChange = (page) => {
        queryParams.set('uploadsPage', page);
        history.push(`uploads-and-notes?${queryParams.toString()}`);
        loadFiles(urlParams.get('sortBy'), urlParams.get('sortDirection') ? urlParams.get('sortDirection') : 'asc', page);
    }

    useEffect(() => {
        onUploadsPageChange(1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPrivate])

    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-9 hupload">
                        <div className="card">
                            <div className="card-body">
                                <div className="card-header d-flex justify-content-between">
                                    <h5>
                                        Uploaded Files
                                    </h5>
                                </div>

                                {uploadedFilesList ?
                                    <div className="container">
                                        <div className="d-flex hupload-head justify-content-between">
                                            <div className="input-upload">
                                                {/* <input type="file" id="iupload" onChange={onUpload} hidden />
                                                <label htmlFor="iupload">Upload</label> */}
                                                <button className="btn text-danger font-weight-bold" onClick={() => exportCsv()}>Export To CSV</button>
                                            </div>
                                            <div className="iuser-wrapper d-flex justify-content-between">
                                                <div className="ipublic align-self-center">
                                                    <i className={`fa fa-${isPrivate ? 'lock' : 'globe'}`}></i>
                                                    {isPrivate ? 'Private' : 'Public'} Files
                                                </div>
                                                <div className="iuser">
                                                    {!documentsService.disablePrivateUploadsForSubUser && <button className={`btn ${isPrivate ? 'active' : 'p-1'}`} onClick={() => setIsPrivate(true)}><i className="fa fa-user"></i></button>}
                                                    <button className={`btn ${!isPrivate ? 'active' : 'p-1'}`} onClick={() => setIsPrivate(false)}><i className="fa fa-users"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                        {isUploading &&
                                            <div className="progress">
                                                <div className="progress-bar progress-bar-striped progress-bar-animated bg-success" role="progressbar" style={{ width: `${uploadPercent}%` }}>Uploading...</div>
                                            </div>
                                        }
                                        <div className="row hupload-content font-weight-bold">
                                            <div className="col-md-3">
                                                Violations
                                                <button className="btn btn-link p-0 ml-2" onClick={() => toggleSort('violation')}>
                                                    <i className={`fa fa-sort-numeric-${urlParams.get('sortBy') === 'violation' ? urlParams.get('sortDirection') : 'asc'}`}></i>
                                                </button>
                                            </div>
                                            <div className="col-md-4">
                                                Building
                                                <button className="btn btn-link p-0 ml-2" onClick={() => toggleSort('building')}>
                                                    <i className={`fa fa-sort-alpha-${urlParams.get('sortBy') === 'building' ? urlParams.get('sortDirection') : 'asc'}`}></i>
                                                </button>
                                            </div>
                                            <div className="col-md-3">
                                                File
                                                <button className="btn btn-link p-0 ml-2" onClick={() => toggleSort('fileName')}>
                                                    <i className={`fa fa-sort-alpha-${urlParams.get('sortBy') === 'fileName' ? urlParams.get('sortDirection') : 'asc'}`}></i>
                                                </button>
                                            </div>
                                            <div className="col-md-2 text-right fit-to-content">
                                                Date
                                                <button className="btn btn-link p-0 ml-2" onClick={() => toggleSort('date')}>
                                                    <i className={`fa fa-sort-numeric-${urlParams.get('sortBy') === 'date' ? urlParams.get('sortDirection') : 'asc'}`}></i>
                                                </button>
                                            </div>
                                        </div>
                                        {loadingFiles && <ReactLoading type={"cylon"} color={"grey"} className="m-auto" />}
                                        {
                                            !loadingFiles &&
                                            uploadedFilesList.map((value, key) => {
                                                return (
                                                    <div className="row hupload-content" key={'1' + key}>
                                                        <div className="col-md-3">
                                                            {value.violationId &&
                                                                <React.Fragment>
                                                                    <span className="d-block mb-1" style={{ cursor: 'default' }}>{value.identifier ? value.identifier.displayAs : 'Document#'}</span>
                                                                    {value.identifier &&
                                                                        <a className="mb-1" href={`/violation-details/${value.violationId}/documents`}>
                                                                            {value.identifier ? value.identifier.value : '-'}
                                                                        </a>
                                                                    }
                                                                </React.Fragment>
                                                            }
                                                            {!value.violationId && 'Custom Upload'}
                                                        </div>
                                                        <div className="col-md-4" style={{ cursor: 'default' }}>
                                                            <span className="d-block">{value.building ? value.building.display_address ? value.building.display_address : value.building.address : 'N/A'}</span>
                                                            <span className="text-uppercase mt-2">{value.category ? value.category : '-'} / {value.sub ? value.sub.replace(/_/g, ' ') : '-'}</span>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <button className="btn btn-xs btn-link p-0" style={{ wordBreak: 'break-all' }} onClick={() => handleUrlClick(value)} rel="noreferrer">
                                                                {value.file.originalname}
                                                            </button>
                                                        </div>
                                                        <div className="col-md-2 text-right fit-to-content">
                                                            <div style={{ cursor: 'default' }}>{moment(value.createdAt).format('MM/DD/YYYY')}</div>
                                                            {value.uploadedByClientId === user._id && !documentsService.disablePrivateUploadsForSubUser && (
                                                                <div className="custom-control custom-switch mt-2">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="custom-control-input"
                                                                        id={`file_private_switch_${value._id}`}
                                                                        checked={value.isPrivate}
                                                                        onChange={(e) => handleVisibilityChange(value._id, e.target.checked)}
                                                                    />
                                                                    <label className="custom-control-label" htmlFor={`file_private_switch_${value._id}`}> Private</label>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                        {!loadingFiles && uploadedFilesList.length <= 0 && (
                                            <p className="pt-2 pb-5">No {isPrivate ? 'private' : 'public'} documents available.</p>
                                        )}
                                        <Pagination
                                            className="my-4"
                                            itemsCount={totalUploads}
                                            pageSize={10}
                                            currentPage={uploadsCurrentPage}
                                            onPageChange={onUploadsPageChange}
                                        />
                                    </div>
                                    : ''}
                            </div>
                        </div>

                        <div className="card">
                            <div className="card-body">
                                <div className="card-header d-flex justify-content-between">
                                    <h5>Custom Notes</h5>
                                </div>
                                {notesList ?
                                    <div className="container">
                                        <div className="d-flex hupload-head justify-content-between">
                                            <div className="input-upload">
                                                {/* <input type="file" id="iupload" hidden />
                                                <label htmlFor="iupload">Upload</label> */}
                                            </div>
                                            <div className="iuser-wrapper d-flex justify-content-between">
                                                <div className="ipublic align-self-center">
                                                    <i className="fa fa-users"></i>
                                                    Notes
                                                </div>
                                                <div className="iuser">
                                                    <button className="btn active"><i className="fa fa-user"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row hupload-content font-weight-bold">
                                            <div className="col-md-3">
                                                Violations
                                                <button className="btn btn-link p-0 ml-2" onClick={() => toggleSort('violationId', true)}>
                                                    <i className={`fa fa-sort-numeric-${urlParams.get('notesSortBy') === 'violationId' ? urlParams.get('notesSortDirection') : 'asc'}`}></i>
                                                </button>
                                            </div>
                                            <div className="col-md-4">
                                                Building
                                                <button className="btn btn-link p-0 ml-2" onClick={() => toggleSort('bin', true)}>
                                                    <i className={`fa fa-sort-alpha-${urlParams.get('notesSortBy') === 'bin' ? urlParams.get('notesSortDirection') : 'asc'}`}></i>
                                                </button>
                                            </div>
                                            <div className="col-md-3">
                                                Note
                                                <button className="btn btn-link p-0 ml-2" onClick={() => toggleSort('notes', true)}>
                                                    <i className={`fa fa-sort-alpha-${urlParams.get('notesSortBy') === 'notes' ? urlParams.get('notesSortDirection') : 'asc'}`}></i>
                                                </button>
                                            </div>
                                            <div className="col-md-2 text-right fit-to-content">
                                                Date
                                                <button className="btn btn-link p-0 ml-2" onClick={() => toggleSort('createdAt', true)}>
                                                    <i className={`fa fa-sort-numeric-${urlParams.get('notesSortBy') === 'createdAt' ? urlParams.get('notesSortDirection') : 'asc'}`}></i>
                                                </button>
                                            </div>
                                        </div>
                                        {loadingNotes && <ReactLoading type={"cylon"} color={"grey"} className="m-auto" />}
                                        {!loadingNotes && notesList.map((value, key) => <div className="row hupload-content" key={key}>
                                            <div className="col-md-3">
                                                <span className="d-block mb-1" style={{ cursor: 'default' }}>{value.identifier ? value.identifier.displayAs : 'Doument #'}</span>
                                                <Link className="mb-1" to={`/violation-details/${value._id}/notes/#tab-content`}>
                                                    {value.identifier ? value.identifier.value : '-'}
                                                </Link>
                                            </div>
                                            <div className="col-md-4" style={{ cursor: 'default' }}>
                                                <span className="d-block">{value.building ? value.building.display_address ? value.building.display_address : value.building.address : 'N/A'}</span>
                                                <span className="text-uppercase mt-2">{value.category ? value.category : '-'} / {value.sub ? value.sub.replace(/_/g, ' ') : '-'}</span>
                                            </div>
                                            <div className="col-md-3" style={{ cursor: 'default' , whiteSpace: 'pre-wrap' }}>
                                                {value.notes.slice(0, 1).map((note) => {
                                                    return <div key={note._id}>{note.notes}</div>
                                                })}
                                            </div>
                                            <div className="col-md-2 text-right" style={{ cursor: 'default' }}>
                                                <div>
                                                    {value.notes.slice(0, 1).map((note) => {
                                                        return <div key={note._id}>{value.user.find(x => x._id === note.createdBy)?.fullName}</div>
                                                    })}
                                                </div>
                                                <div>{moment(value.createdAt).format('MM/DD/YYYY')}</div>
                                                {value.notes.length > 1 && <Link className="mt-3" to={`/violation-details/${value._id}/notes/#tab-content`}>See all notes...</Link>}
                                            </div>

                                        </div>
                                        )}
                                        
                                        {!loadingNotes && notesList.length <= 0 && (
                                            <p className="pt-2 pb-5">No notes available.</p>
                                        )}
                                        <Pagination
                                            className="my-4"
                                            itemsCount={totalNotes}
                                            pageSize={10}
                                            currentPage={notesCurrentPage}
                                            onPageChange={onNotesPageChange}
                                        />
                                    </div>
                                    : ''}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 recent-activity pr-0">
                        <div className="pt-3 pb-3">
                            {props.jestData !== undefined ? null : <SmallCalendar />}
                        </div>
                        <HistoryUpdates />
                    </div>
                </div>
            </div>
            <DocsViewer document={currentfile} isVisible={docsViewerVisibility} setIsVisible={setDocsViewerVisibility}/>
        </Fragment>
    )
}

export default UploadsAndNotes
