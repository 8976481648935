import React, { useState, useEffect } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';

import { DatePicker, Select } from 'antd';

import moment from 'moment';


const { RangePicker } = DatePicker;
const AdvancedSearch = ({
    columns,
    dataSource,
    advancedSearchHandler,
    advancedSearchDefault,
    agencyGroups,
    advancedSearchValues
}) => {

    const [selected, setSelected] = useState({})
    const [selectValues, setSelectValues] = useState({})
    const initilize = () => {
        const newSelected = {
            ...columns.reduce((ac, a) => ac[a.key] = [], {}),
            ...Object.keys(advancedSearchDefault || {}).reduce((ac, key) => {
                const column = columns.find(column => column.description === key)
                if (column) {
                    ac[column.key] = advancedSearchDefault[column.description].map(el => {
                        if (el === 'Not Specified') {
                            el = ''
                        }
                        return column.data?.data?.find?.(dataobj => dataobj.text === el)?.id || el
                    })
                }
                return ac
            }, {})
        }
        setSelected(newSelected)
        advancedSearchHandler(newSelected)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { initilize() }, [advancedSearchDefault])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { getUnique() }, [])
    
    useEffect(() => { 
        setSelected(advancedSearchValues)
     }, [advancedSearchValues])

    const getUnique = () => {

        const values = {}
        for (let item of dataSource) {
            for (let column of columns) {
                if (
                    column.key !== 'action' &&
                    !column.hide &&
                    column.data?.bcType !== 'files' &&
                    column.data?.bcType !== 'uploads' &&
                    column.data?.bcType !== 'chat' &&
                    column.data?.bcType !== 'notes' &&
                    column.data?.bcType !== 'links'
                ) {

                    const columnkey = column.data?.bcType === 'priority' ? 'isPriority' : column.key
                    const isBool = column.data?.bcType === 'priority' || column.data?.type === 'checkBox'
                    if (item[columnkey] || isBool) {

                        if (!values[columnkey]) {
                            values[columnkey] = new Set([''])
                        }

                        values[columnkey].add(isBool ? !!item[columnkey] : item[columnkey])
                    }

                }
            }
        }
        // console.log(values)
        setSelectValues(values)
    }

    const getOptionsAgency = (avaliableOptions) => {

        const result = []
        for (let avaliableOption of avaliableOptions) {
            if (avaliableOption) {
                const found = agencyGroups.find(el => el.agencies.includes(avaliableOption))
                if (found && !result.some(el => el.value === found._id)) {
                    result.unshift({ value: found._id, label: <span style={{ color: found.colorMark }}>{found.categoryName.replace(/\*/g, '')}</span> })
                } else {
                    result.push({ value: avaliableOption })
                }
            }

        }
        return result

    }

    const setDateRange = (key, start, end, options) => {
        let newValue = { ...selected, [key]: [] }
        if (start && end) {
            const newSelected = options.filter(el => {
                if (el[key]) {
                    if (el[key]) {
                        start = new Date(start);
                        start.setHours(0, 0, 0, 0);
                        end = new Date(end);
                        end.setHours(23, 59, 59, 999);
                        return moment(new Date(el[key])).isBetween(start, end);
                    }
                }
                return false
            })
            newValue = { ...selected, [key]: newSelected.length ? newSelected.map(el => el[key]) : ['unknown'] }

        }
        advancedSearchHandler(newValue)
        setSelected(newValue)
    }


    return (
        <div className='d-flex flex-wrap mt-2 mb-2 '>

            {
                columns.filter(
                    (column) =>
                        column.key !== 'action' &&
                        !column.hide &&
                        column.data?.bcType !== 'files' &&
                        column.data?.bcType !== 'uploads' &&
                        column.data?.bcType !== 'chat' &&
                        column.data?.bcType !== 'notes' &&
                        column.data?.bcType !== 'links'
                ).map(column => {


                    const columnkey = column.data?.bcType === 'priority' ? 'isPriority' : column.key

                    if (column.key === 'hearing_date') {

                        return <div className='pl-2 pt-2 w-50  ant-input-wrapper ant-input-group ' key={'ad-' + column.key}>

                            <span className="ant-input-group-addon w-25">{column.description}:</span>
                            <span>
                                <RangePicker
                               
                                    className='w-100'
                                    style={{ height: "38px" }}
                                    format='MM/DD/YYYY'
                                    onChange={(dates) => {
                                        setDateRange(column.key, dates?.[0], dates?.[1], dataSource)
                                    }} />
                            </span>
                        </div>
                    }
                    if (column.key === 'issuing_agency') {

                        const options = getOptionsAgency([...(selectValues[columnkey] || [])])

                        return <div className='pl-2 pt-2 w-50  ant-input-wrapper ant-input-group ' key={'ad-' + column.key}>

                            <span className="ant-input-group-addon w-25">{column.description}:</span>
                            <span>
                                <Select
                                    size="large"
                                    mode="multiple"
                                   
                                    onChange={(selectedObj) => {
                                        const newValue = {
                                            ...selected, [columnkey]: selectedObj.reduce((ac, a) => {
                                                const result = agencyGroups.find(el => el._id === a)?.agencies || [a]
                                                return [...ac, ...result]
                                            }, [])
                                        }
                                 
                                        setSelected(newValue)
                                        advancedSearchHandler(newValue)

                                    }}
                                    style={{ width: '100%' }}
                                    options={options}
                                />
                            </span>
                        </div>
                    }

                    return <Typeahead
                        id={'ad-' + column.key}
                        key={'ad-' + column.key}
                        multiple
                        className={`pl-2 pt-2 w-25 ${column.key.split(' ')[0]}`}
                        placeholder={column.description}
                        onChange={(selectedObj) => {
                            const newValue = { ...selected, [columnkey]: selectedObj }
                            setSelected(newValue)
                            advancedSearchHandler(newValue)
                        }}
                        selected={selected[columnkey]}
                        labelKey={(option) => {
                            if (column.data?.bcType === 'colors') {
                                return column.data?.data?.find?.(colorLabel => colorLabel.id === option)?.text || option || 'Not Specified'
                            } else if (column.data?.bcType === 'users') {
                                return column.subUsers?.find((el) => el._id === option)?.fullName || ''
                            } else if (column.data?.bcType === 'vendors') {
                                return column.vendors?.find((el) => el._id === option)?.fullName || ''
                            } else if (!column.isCustom && (column.key.toLowerCase().includes('date') || column.description.toLowerCase().includes('date'))) {
                                const date = moment.utc(new Date(option));
                                return date.isValid() ? date.format('MM/DD/YYYY') : option
                            } else if (column.data?.type === 'checkBox' || column.data?.bcType === 'priority') {
                                return option ? 'Yes' : 'No'
                            }
                            return option || ' ';
                        }}
                        options={[...(selectValues[columnkey] || [])]}
                    />
                })}

        </div>
    );
};

export default AdvancedSearch;
