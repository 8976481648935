import React, { Component, Fragment } from "react";
import { ToastContainer } from "react-toastify";
import RetainedListView from '../buildings/violationTable/retained/listView';
import RetainedGridView from '../buildings/violationTable/retained/gridView';

class RetainedAllViolations extends Component {

    state = this.props.jestData !== undefined ?
        this.props.jestData.state : {
            data: [],
            viewType: this.props.viewType,
        };

    onViolationClick = (violation) => {
        this.props.history.push(`/violation-details/${violation._id}/documents`)
    };

    render() {
        const { viewType } = this.state;

        return (
            <Fragment>
                <ToastContainer />
                <div className="row">
                    <div className="col-12 pt-0 violation-detail">
                        <div className="row">                      
                            <div className="col-xl-12 violation-detail">
                                {viewType === 'list' && <RetainedListView data={this.props.data} onViolationClick={this.onViolationClick} isRetained={true}/>}
                                {viewType === 'grid' && <RetainedGridView data={this.props.data} onViolationClick={this.onViolationClick} />}
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}
export default RetainedAllViolations;

