import React, { useEffect, useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from "react-router";
import { toast } from 'react-toastify';
import moment from 'moment'
import { Divider } from 'antd';
import { InfoCircleTwoTone } from '@ant-design/icons';

import creService from "../services/creService";


const ReactVersionTostify = ({ reactVersion }) => {
  const history = useHistory();
  const [updates, setUpdates] = useState([])
  const setnewVersionAndReload = (moveTo) => {
    if (moveTo) {
      history.push(moveTo);
    }
    localStorage.setItem('x-react-version', reactVersion);
    window.location.reload(true);
  }

  useEffect(() => {
    creService.getVersionUpdates(3).then(res => {
      if (res.status) {
        const current = localStorage.getItem('x-react-version')?.split(' - ')[0];
        let result = res.data.data;
        let i =0;
        for(let update of result){
          if (i === 0) {
            if ((update.version - 1).toString() === current) result = [update];
          }
          i++;
        }
        setUpdates(result)
      } else {
        toast.error(res.data.message)
      }

    }).catch(err => toast.error(err.message))
  }, [])


  return (
    <div className="text-center m-2">
      <p className="text-secondary">New version of the software is available!</p>

      {updates.length !== 0 && (<div style={{ width: '300px' }} className='pointer updates-menu text-left'>
        <Divider className='m-2' />
        {
          updates.map(el => (
              <Fragment key={'ver-' + el.version}>
                <Link to={"/updates#vu-" + el.version}>
                  <div className='mt-2 mb-2 m-10'>
                  <span className={`badge badge-pill badge-light text-left  ${+localStorage.getItem('last-checked-version') < el.version ? 'text-info font-weight-bold' : 'text-secondary'}`}>
                    <InfoCircleTwoTone className='mr-1' /> v. {el.version} - {el.reactVersionUpdatedAt ? moment(new Date(el.reactVersionUpdatedAt)).format('LL') : 'no date'}
                  </span> <br />
                    {el.updates.map(el => el.title).join(', ')}
                  </div>
                </Link>
              </Fragment>

          ))}
      </div>)}
      <Divider className='m-2' />
      <div className='d-flex justify-content-around'>
        <button
          type="button"
          className="btn btn-sm  btn-warning m-1 p-2"
          onClick={setnewVersionAndReload}
        >
          Update Now
        </button>

        <button
          type="button"
          className="btn btn-sm btn-info m-1 p-2"
          onClick={() => setnewVersionAndReload("/updates#vu-" + reactVersion?.split(' ')[0])}
        >
          Check what's new
        </button>

      </div>
    </div>
  );
};

export default ReactVersionTostify;
