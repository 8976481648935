import React, {Fragment} from "react";
import Joi from "joi-browser";
import Form from "../common/forms/form";
import {ToastContainer, toast} from "react-toastify";
import logo from "../../assets/images/endless-logo.png";
import creService from "../services/creService";


class ResetPassword extends Form {
    state = {
        data: { password: "", resetKey: ""},
        errors: {},
        loading: false,
        expired: false
    };

    schema = {
        password: Joi.string().min(5).max(50).required().label("Password"),
        confirmPassword: Joi.string()
            .required()
            .valid(Joi.ref('password'))
            .options({
                language: {
                    any: {
                        allowOnly: '!!Passwords do not match',
                    }
                } 
            }),
        resetKey: Joi.string().min(5).max(50).required(),
    };

    componentDidMount() {
        let resetKey = this.props.match.params.resetKey;
        let expire = parseInt(this.props.match.params.expire);

        if(new Date().getTime() > expire){
            this.setState({expired: true, errors: {formError: "Page has expired"}});
            toast.error("Page has expired");
        }

        if(resetKey){
            this.setState({data: {...this.state.data, resetKey}});
        }else{
            toast.error("Reset ID is missing");
        }
    }

    doSubmit = async () => {
        try {
            this.setState({loading: 1});
            await creService.resetPassword(this.state.data.password, this.state.data.resetKey);
            toast.success("Password set Successfully!")
            window.location = "/";

        } catch (ex) {
            if (ex.response && ex.response.status === 400) {
                const errors = { ...this.state.errors };
                errors.formError = ex.response.data;
                this.setState({ errors });
            }
        }
        this.setState({loading: 0});
    };


    render() {
        let submitButtonLabel = !this.state.loading ? "Set New Password" : "Logging in...";
        return (
            <Fragment>
                <ToastContainer />
                <div className="page-wrapper">
                    <div className="auth-bg">
                        <div className="authentication-box">
                            <div className="text-center"><img src={logo} alt="" /></div>
                            <div className="card mt-4">
                                <div className="card-body">

                                    <div className="text-center">
                                        <h4>Set new password</h4>
                                    </div>
                                    {this.renderFormError(this.state.errors.formError)}
                                    <form className={this.state.expired ? 'd-none' : 'theme-form'} onSubmit={this.handleSubmit}>

                                        <div className="form-group">
                                            {this.renderInput("password", "Password", "password")}
                                        </div>

                                        <div className="form-group">
                                            {this.renderInput("confirmPassword", "Confirm Password", "password")}
                                        </div>

                                        <div className="form-group form-row mt-3 mb-0">
                                            {this.renderButton(submitButtonLabel)}
                                        </div>

                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>

        );
    }
}

export default ResetPassword;
