
import styled from 'styled-components';

export const grid = 8;
export const borderRadius = 2;

export const ColumnList = styled.div`
    display: flex;
    justify-content: center;
    align-items: self-start;
`;

export const Container = styled.div`
    display: flex;
    background: #eee;
    padding: 10px;
    flex-direction: column;
    width: 20%;
    border-right: 5px solid #fff;
    &:last-child {
        border: none;
    }
`;

export const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
    text-transform: capitalize;
    margin-bottom: 10px;
`;


export const ItemsWrapper = styled.div`
`;

export const ColumnItem = styled.div`
    border-radius: ${borderRadius}px;
    border: 1px solid transparent;
    background-color: #fff;
    box-shadow: 0px 0px 3px #a3a3a3;
    box-sizing: border-box;
    padding: ${grid}px;
    margin-bottom: ${grid}px;

    .workOrderId {
        color: #4466f2;
        font-size: 15px;
    }

    .dueDate {
        color: #7e7e7e;
        font-size: 12px;
    }

    .title {
        font-size: 15px;
        color: #747474;
        padding: 5px 0;
    }

    .address {
        font-size: 12px;
        color: #585858;
    }
`;

export const LoadMoreButton = styled.button`
    font-size: 13px;
    color: #3d4347;
    border: 1px solid #b5b4b4;
    padding: 3px 2px 1px;
    background: #ddd;
    text-decoration: none;
    margin-top: 20px;

    &:hover, &:active, &:focus {
        text-decoration: none;
        color: #3d4347;
    }
`;