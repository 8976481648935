import React, { Fragment, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Link, useLocation } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Skeleton } from 'antd';
import creService from '../../services/creService';
import FilterReactDataTable from '../../common/filterReactDataTable';
import ExpandedRow from './expandRow';

const OrderStatuses = [
  'New Order',
  'Processing',
  'Printing',
  'Completed',
  'Cancelled',
];

const yearsList = (back = 10) => {
  const year = new Date().getFullYear() + 1;
  return Array.from({ length: back }, (v, i) => String(year - back + i + 1));
};

const SafetyMailings = ({ jestData = false }) => {
  const [orders, setOrders] = useState(jestData ? jestData.orders : []);
  const [loading, setLoading] = useState(true)
  const [buildings, setBuildings] = useState(
    jestData ? jestData.buildings : []
  );

  const [selectedFilterData, setSelectedFilterData] = useState(
    jestData
      ? jestData.selectedFilterData
      : {
        bins: [],
        status: [],
        year: '',
      }
  );

  const location = useLocation();

  const newOrder = new URLSearchParams(location.search).get('newOrder');

  const [filterText, setFilterText] = React.useState('');
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const filteredItems = orders.filter((item) => {
    if (filterText.length > 0) {
      if (
        (item.orderNumber &&
          String(item.orderNumber)
            .toLowerCase()
            .includes(filterText.toLowerCase())) ||
        (item.year &&
          String(item.year).toLowerCase().includes(filterText.toLowerCase())) ||
        (item.status &&
          item.status.toLowerCase().includes(filterText.toLowerCase()))
      ) {
        return true;
      }
      return false;
    }
    return true;
  });

  const columns = [
    {
      name: 'Order Number',
      sortable: true,
      cell: (row) => {
        if (String(row.orderNumber) === String(newOrder)) {
          return (
            <div>
              {row.orderNumber} <span className="badge badge-success">New</span>
            </div>
          );
        }
        return row.orderNumber;
      },
    },
    {
      name: 'Order Placed Date',
      sortable: true,
      selector: (row) => moment(row.addedAt).format('MM/DD/YYYY'),
    },
    {
      name: 'Responded',
      sortable: true,
      selector: () => 0,
    },
    {
      name: 'Status',
      sortable: true,
      cell: (row) => (
        <div className="d-flex flex-column">
          <span>{row.status}</span>
          <em>{row.notes}</em>
        </div>
      ),
    },
    {
      name: 'Year',
      sortable: true,
      selector: (row) => row.year,
    },
    {
      name: 'Action',
      cell: (row) => (
        <>
          <Link
            to={`/safety-mailings/view/${row._id}`}
            className="btn btn-info btn-xs"
          >
            <i className="fa fa-eye"></i> View
          </Link>
        </>
      ),
    },
  ];

  const handleChange = (name, value) => {
    setSelectedFilterData((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const fetchOrders = async () => {
    try {
      const { data } = await creService.getAllSafetyMailingsOrder(
        selectedFilterData
      );
      if (data.status) {
        setOrders(data.data);
      } else {
        toast.error(data.message);
      }
     
    } catch (_err) {
      toast.error(_err.message);
    }
    setLoading(false)
  };

  const fetchBuildings = async () => {
    try {
      const { data } = await creService.getSafetyMailingsBuildings();
      if (data.status) {
        setBuildings(data.data);
      } else {
        toast.error(data.message);
      }
    } catch (_err) {
      toast.error(_err.message);
    }
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <div className="row my-3">
        <div className="col-12 col-md-4">
          <Typeahead
            id="filter_buildings"
            multiple
            placeholder="Buildings"
            onChange={(selected) => {
              handleChange(
                'bins',
                selected.map((b) => b.bin)
              );
            }}
            selected={buildings.filter((b) =>
              selectedFilterData.bins.includes(b.bin)
            )}
            labelKey={(option) => {
              if (option.display_address) {
                return option.display_address;
              }
              return option.address;
            }}
            options={buildings}
          />
        </div>
        <div className="col-12 col-md-3">
          <Typeahead
            id="filter_status"
            multiple
            placeholder="Status"
            onChange={(selected) => {
              handleChange('status', selected);
            }}
            selected={OrderStatuses.filter((b) =>
              selectedFilterData.status.includes(b)
            )}
            options={OrderStatuses}
          />
        </div>
        <div className="col-12 col-md-2">
          <Typeahead
            id="filter_year"
            placeholder="Year"
            onChange={(selected) => {
              handleChange('year', String(selected));
            }}
            selected={yearsList(5).filter((b) =>
              selectedFilterData.year.includes(b)
            )}
            options={yearsList(5)}
          />
        </div>
        <div className="col-12 col-md-3">

          <FilterReactDataTable
            onFilter={(e) => setFilterText(e.target.value)}
            onClear={handleClear}
            filterText={filterText}
          />

        </div>
      </div>
    );
  }, [filterText, buildings, resetPaginationToggle, selectedFilterData]);

  useEffect(() => {
    fetchBuildings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilterData]);

  return (
    <Fragment>
      <div className="row violation-sec">
        <div className="col-xl-12 my-3">
          <div className="card">
            <div className="card-header row">
              <div className="col-6">
                <h5>List of Safety Mailings</h5>
              </div>
              <div className="col-6 text-right">
                <Link to="/safety-mailings/create">
                  <button className="btn btn-primary text-white" type="button">
                    Create New Order
                  </button>
                </Link>
              </div>
            </div>
            <div className="card-body data-table">
              {loading ?
                <Skeleton active /> :
                <DataTable
                  lo
                  columns={columns}
                  data={filteredItems}
                  paginationResetDefaultPage={resetPaginationToggle}
                  subHeaderComponent={
                    orders.length > 0 ? subHeaderComponentMemo : null
                  }
                  paginationRowsPerPageOptions={[
                    10, 20, 30, 40, 50, 60, 70, 80, 90, 100,
                  ]}
                  expandableRowsComponent={<ExpandedRow />}
                  expandableRows
                  subHeader
                  pagination
                  responsive
                  striped
                  noHeader
                />
              }
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SafetyMailings;
