import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Calendar } from 'react-date-range';
import { toast } from "react-toastify";
import creService from "../../../services/creService";
import CalendarEventPopover from './calendar-event-popover';

const DueDatePicker = ({ initialDate, currentDate, editView, onChange }) => {

    const [dueDatePickerCurrentDate, setDueDatePickerCurrentDate] = useState(null);
    const [calendarAllocationData, setCalendarAllocationData] = useState([]);

    const fetchAllocation = async (startDate, endDate) => {
        try {
            const res = await creService.getStaffAllocationForWorkOrders(startDate, endDate);
            if (res.data.status) {
                setCalendarAllocationData(res.data.data);
            } else {
                setCalendarAllocationData([]);
                if (res.data.errors) {
                    res.data.errors.map((err) => toast.error(err));
                } else {
                    toast.error(res.data.message);
                }
            }
        } catch (_err) {
            toast.error(_err.message || 'Something went wrong');
        }
    }

    useEffect(() => {
        if (!dueDatePickerCurrentDate) {
            setDueDatePickerCurrentDate(new Date());
        }
        if (dueDatePickerCurrentDate) {
            const startDate = moment(dueDatePickerCurrentDate).startOf('month');
            const endDate = moment(dueDatePickerCurrentDate).endOf('month');
            fetchAllocation(startDate, endDate);
        }
    }, [dueDatePickerCurrentDate])

    let date = null;
    if(dueDatePickerCurrentDate && moment(dueDatePickerCurrentDate).unix() <= moment(currentDate)) {
        date = moment(dueDatePickerCurrentDate).toDate();
    }

    return (
        <div className="input-date-picker">
            <Calendar
                date={date}
                minDate={editView && moment(initialDate).unix() <= moment().unix() ? moment(initialDate).toDate() : moment().toDate()}
                onShownDateChange={(startDateOfMonth) => {
                    setDueDatePickerCurrentDate(startDateOfMonth);
                    return startDateOfMonth;
                }}
                dayContentRenderer={(day) => {
                    const formattedDay = day.getDate();
                    const events = calendarAllocationData.filter((_v) => moment(_v.date).format('YYYYMMDD') === moment(day).format('YYYYMMDD'));
                    if (events && events.length > 0) {
                        return <>
                            <span>{formattedDay}</span>
                            <CalendarEventPopover events={events} day={day} />
                        </>;
                    }
                    return <span>{formattedDay}</span>;
                }}
                onChange={onChange}
            />
        </div>
    )
}

export default DueDatePicker;

