import React from 'react';
import Moment from "moment";
import { capSentence } from "../../../services/helperService";
import RetainedBadges from "./retainedBadges";
import CofCProgress from "./cofcProgress";
import CustomFields from "./customFields";
import './detailsDOBComplaint.css'
import { Col, Row } from "antd";

const DispositionBlockHeader = () => {
  return (
    <>
      <div className="d-flex justify-content-start ">
        <h5 className="font-weight-bold">Complaint Disposition History</h5>
      </div>
      <div className="row text-center align-items-center disposition-header mx-0 mt-3">
        <div className="col-1 p-0">
          #
        </div>
        <div className="col-2 p-0">
          Disposition Date
        </div>
        <div className="col-2 p-0">
          Disposition Code
        </div>
        <div className="col-4 p-0">
          Disposition
        </div>
        <div className="col-2 p-0">
          Inspection By
        </div>
        <div className="col-1 p-0">
          Date
        </div>
      </div>
    </>
  )
}

const DispositionBlock = ({ disposition, i }) => {
  return (
    <div className="row text-center border-bottom align-items-center disposition-rows" key={i}>
      <div className="col-1 p-0">
        <p>{disposition.Number ? disposition.Number : 'n/a'}</p>
      </div>
      <div className="col-2 p-0">
        <p>{disposition.disposition_date ? disposition.disposition_date : 'n/a'}</p>
      </div>
      <div className="col-2 p-0">
        <p>{disposition.code ? disposition.code : 'n/a'}</p>
      </div>
      <div className="col-4 p-0">
        <p>{disposition.Disposition ? disposition.Disposition : 'n/a'}</p>
      </div>
      <div className="col-2 p-0">
        <p>{disposition.Inspection ? disposition.Inspection : 'n/a'}</p>
      </div>
      <div className="col-1 p-0">
        <p>{disposition.inspection_date ? disposition.inspection_date : 'n/a'}</p>
      </div>
    </div>
  )
}


const DetailsDOBComplaint = ({
  category,
  sub,
  tableRawData,
  apiData,
  jestData = false,
  subUsers,
  vendors,
  customFields,
  setRefreshHistory,
  moveToCategory
}) => {

  const dispositionsBlock = [<DispositionBlockHeader key={100} />];
  if (apiData.violationDetails.dispositions && apiData.violationDetails.dispositions.length > 0) {
    for (let [i, disposition] of apiData.violationDetails.dispositions.entries()) {
      dispositionsBlock.push(<DispositionBlock key={i} disposition={disposition} i={i} />)
    }
  }

  const { retained, cofc } = apiData?.violationDetails?.clientAdditionalData || {};

  const retainedTypes = Object.keys(retained).filter(key => retained[key].isRetained);

  const customFieldsData = apiData?.violationDetails?.customFieldData ? apiData?.violationDetails?.customFieldData[0]?.fields || [] : [];

  return (
    <div className="card user-card ecb-head"
      style={retainedTypes.length > 0 ? { border: '1px solid #c36c00', borderRadius: 0 } : {}}>
      <RetainedBadges retained={retained} />
      <Row gutter={30}>
        <Col lg={14} span={24}>
          <div className="card-body d-flex">
            <div className="ecb-title ecb-head align-items-center">
              <div className="w-100">
                <h5 className="text-info mb-3 pointer" onClick={moveToCategory}>
                  {category}/{sub}
                </h5>
              </div>
              <div className="w-100 row ecb-row flex-wrap">
                <div className="col-4 col-md-6 mb-3">
                  <h5>Agency: </h5>
                  <p>{capSentence("DEPT. OF BUILDINGS")}</p>
                </div>
                <div className="col-4 col-md-6 mb-3">
                  <h5>Borough: </h5>
                  <p>{tableRawData.Borough}</p>
                </div>
                <div className="col-4 col-md-6 mb-3">
                  <h5>DOB Complaint#:</h5>
                  <p>{tableRawData.complaint_number}
                    <a
                      className="badge badge-light badgeH5"
                      rel="noopener noreferrer"
                      href={`http://a810-bisweb.nyc.gov/bisweb/OverviewForComplaintServlet?complaintno=${tableRawData.complaint_number}`}
                      target="_blank">
                      DOB <i className="fa fa-external-link" />
                    </a>
                  </p>
                </div>
                <div className="col-4 col-md-6 mb-3">
                  <h5 className="">Category Code: </h5>
                  <p>{capSentence(tableRawData.complaint_category)}</p>
                </div>
                <div className="col-4 col-md-6 mb-3">
                  <h5>Assigned To:</h5>
                  <p> {tableRawData.unit ? capSentence(tableRawData.unit) : 'n/a'}</p>
                </div>
                <div className="col-4 col-md-6 mb-3">
                  <h5>Priority:</h5>
                  <p className='pl-2'>{capSentence(tableRawData.Priority)}</p>
                </div>

                <div className="col-4 col-md-6 mb-3">
                  <h5>Received:</h5>
                  <p>{tableRawData.date_entered}</p>
                </div>
                <div className="col-4 col-md-6 mb-3">
                  <h5>Owner:</h5>
                  <p>{capSentence(tableRawData.owner)}</p>
                </div>
                <div className="col-4 col-md-6 mb-3">
                  <h5>Last Inspection:</h5>
                  <p>{capSentence(tableRawData.LastInspectionAll)}</p>
                </div>
                <div className="col-4 col-md-6 mb-3">
                  <h5>Disposition:</h5>
                  <p>{capSentence(tableRawData.DispositionAll)}</p>
                </div>
                <div className="col-12">
                  <h5>Comments: </h5>
                  <p>{capSentence(tableRawData.DispositionComment)}</p>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col className="d-flex" lg={10} span={24}>
          <div className="card-body d-flex flex-column">
            <div className="row ecb-sub-head">
              <div className="col-12">
                <h5>Complaint Details</h5>
              </div>
            </div>

            <div className="row ecb-row">
              <div className="col-6">
                <h6>Issue Date</h6>
                <p>{Moment(tableRawData.date_entered).format('LL')}</p>
              </div>
              <div className="col-6">
                <h6>Regarding</h6>
                <p>{tableRawData.disposition_code ? capSentence(tableRawData.disposition_code) : 'n/a'}</p>
              </div>

            </div>

            <div className="row ecb-row">
              <div className="col-6">
                <h6>Inspection Date</h6>
                <p>{tableRawData.inspection_date ? Moment(tableRawData.inspection_date).format('LL') : 'n/a'}</p>
              </div>
              <div className="col-6">
                <h6>Status</h6>
                <p>{tableRawData.status ?
                  (tableRawData.status === "ACTIVE") ?
                    <span className="badge badge-primary btn-sm">Active</span> : capSentence(tableRawData.status)
                  : 'n/a'}</p>
              </div>
            </div>
            <div className="row ecb-row">
              <div className="col-6">
                <h6>Disposition Date</h6>
                <p>{tableRawData.disposition_date ? Moment(tableRawData.disposition_date).format('LL') : 'n/a'}</p>
              </div>
              {tableRawData['311 Reference Number'] &&

                <div className="col-6">
                  <h6>311 Reference Number:</h6>
                  <p>{capSentence(tableRawData['311 Reference Number'])}</p>
                </div>
              }
            </div>
            {(tableRawData['ECB Violation #'] && <div className="row ecb-row">
              <div className="col-6">
                <h6>ECB Violation #</h6>

                <p>{capSentence(tableRawData['ECB Violation #'])}
                  <a
                    className="badge badge-light badgeH5"
                    rel="noopener noreferrer"
                    href={`https://account.bcompliant.com/redirect/ecb.html?violation_id=${tableRawData['ECB Violation #'].length === 9 ?
                      0 + tableRawData['ECB Violation #'] :
                      tableRawData['ECB Violation #']
                      }`}
                    target="_blank">
                    ECB <i className="fa fa-external-link" />
                  </a>
                </p>

              </div>
            </div>
            )}





            {(tableRawData['Complaint Comments'] && <div className="row ecb-row">
              <div className="col-12">
                <h6>Comments</h6>
                <p>{capSentence(tableRawData['Complaint Comments'])}</p>
              </div>
            </div>
            )}
            <div className="ecb-notes">
              <h5>Details</h5>
              <div className="desc">
                <p>
                  {capSentence(tableRawData.description)}
                </p>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      {(apiData?.violationDetails?.dispositions || apiData?.violationDetails?.additionalData?.bcompliantNotes) &&
        <div className="card-body mt-4">
          {dispositionsBlock.length > 1 && dispositionsBlock}

          {apiData?.violationDetails?.additionalData?.bcompliantNotes &&
            <div className="ecb-notes">
              <h5>
              BCompliant Violation Notes
              </h5>
              <div className="desc">
                <p>
                  {apiData.violationDetails.additionalData.bcompliantNotes}
                </p>
              </div>
              {apiData.violationDetails.bcompliantNotesUpdatedBy && <small className="text-muted">
                Last updated By {apiData.violationDetails.bcompliantNotesUpdatedBy.name}
              </small>}
            </div>
          }
        </div>
      }

      <CustomFields
        category={category}
        sub={sub}
        customFields={customFields}
        subUsers={subUsers}
        vendors={vendors}
        customFieldsData={customFieldsData}
        apiData={apiData}
        setRefreshHistory={setRefreshHistory}
      />
      <CofCProgress cofc={cofc} />
    </div>
  );
};

export default DetailsDOBComplaint;
