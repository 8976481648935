import React, { Fragment, useState, useEffect } from 'react';
import { Modal } from 'antd';
import ReactLoading from "react-loading";
import Datatable from "react-data-table-component";
import { faFileCsv } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'

import creService from '../../services/creService';

const ModalSnapshot = ({
    selectedDate,
    sub,
    category,
    isDashboard,
    bin
}) => {
    const [showModal, setShowModal] = useState(false)
    const [modalData, setModalData] = useState(null)

    useEffect(() => {
        if(sub && category) {
            setModalData(null)
            setShowModal(true)
            creService.getDashboardDataSnapshot(category, [sub], moment(new Date(selectedDate)).format('L'), true, isDashboard, bin).then((res) => {
                setModalData(res.data);
    
            })
        }
      
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sub, category])

    const getExcel = () => {
        const { displayNames, ...excelData } = modalData
        creService.createExcel('snapshot.xlsx', excelData, displayNames)
    }


    return (
        <Fragment>
            <Modal
                title={`Snapshot differences from ${selectedDate}`}
                visible={showModal}
                onOk={() => setShowModal(false)}
                onCancel={() => setShowModal(false)}
                footer={<></>}
                width={'80%'}
                centered 
            >
                {modalData ? <>
                    <span className='d-flex justify-content-between'>
                        <h3 className='snapshot-header'>{(modalData['Removed violations']?.length >= 1) && `Removed violations, ${modalData['Removed violations']?.length || 0} records`} </h3>
                        <div className='d-flex'>
                            <i>*All violation information below as of today</i>
                            <div className='pointer ml-2' onClick={getExcel}>
                                <FontAwesomeIcon color="#567AFF" icon={faFileCsv} size="lg" />
                            </div>
                        </div>
                    </span>
                    {modalData['Removed violations']?.length &&
                        <Datatable
                            columns={modalData.displayNames?.map(el => {
                                if (/date/i.test(el.selector + el.name)) {
                                    el.format = (row) => {
                                        const date = moment.utc(row[el.selector]);
                                        return date.isValid() ? date.format('MM/DD/YYYY') : row[el.selector]
                                    }  
                                }
                                return el
                            })}
                            data={modalData['Removed violations']}
                            highlightOnHover={true}
                            noHeader
                            onRowClicked={(violation) => window.open(`/violation-details/${violation._id}`, "_blank")}
                            className='pointer'
                        />}
                    <hr />
                    {(modalData['Created violations']?.length >= 1) && <>
                        <h3 className='mt-3 snapshot-header'>Issued Violations, {modalData['Created violations']?.length || 0} records</h3>
                        <Datatable
                            columns={modalData.displayNames}
                            data={modalData['Created violations']}
                            highlightOnHover={true}
                            noHeader
                            onRowClicked={(violation) => window.open(`/violation-details/${violation._id}`, "_blank")}
                            className='pointer'
                        /></>}
                    <hr />
                    <h3 className='mt-3 snapshot-header'>Original violations, {modalData['Original violations']?.length || 0} records</h3>
                    <Datatable
                        columns={modalData.displayNames}
                        data={modalData['Original violations']}
                        highlightOnHover={true}
                        noHeader
                        onRowClicked={(violation) => window.open(`/violation-details/${violation._id}`, "_blank")}
                        className='pointer'
                    />
                </> : <ReactLoading type={'cylon'} color={'grey'} />}
            </Modal>
        </Fragment>
    );
};

export default ModalSnapshot;
