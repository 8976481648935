import excel from 'exceljs';

const exportViolationEvents = async (column, data, fileName) => {

    const workbook = new excel.Workbook();

    const sheetName = 'Sheet1';
    const sheet = workbook.addWorksheet(sheetName);
    sheet.columns = column;
    for (const i in data) {
        sheet.addRow(data[i]);
    }
    const datas = await workbook.csv.writeBuffer();
    const blob = new Blob([datas], { type: "text/csv;charset=utf-8" });
    const pom = document.createElement('a');
    pom.href = URL.createObjectURL(blob);
    pom.setAttribute('download', `${fileName}.csv`);
    pom.click();
};

export {
    exportViolationEvents,
};
