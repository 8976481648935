import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';
import { Radio } from 'antd';

import creService from '../../../../services/creService';
import Select from '../../../../common/forms/select';

const ModalColor = ({
  showModalColor,
  createColorColumn,
  closeModal,
}) => {
  const [selectedData, setSelectedData] = useState(null);
  const [colorSchemes, setColorSchemes] = useState([]);
  const [selectedValue, setSelectedValue] = useState('new');

  useEffect(() => {
    if (showModalColor) {
      generateSelectOptions();
    }
  }, [showModalColor]);

  const generateSelectOptions = async () => {
    try {
      const result = await creService.getCustomColors();
      if (result.data.status) {
        const tmpColorSchemes = [];
        result.data.customColors.forEach((item) => {
          tmpColorSchemes.push(
            ...item.fields.map((field) => ({
              ...field,
              categoriesCustomFieldsKey: item.categoriesCustomFieldsKey,
            }))
          );
        });

        setColorSchemes(tmpColorSchemes);
      } else {
        toast.error('Failed to load color schemes');
      }
    } catch (err) {
      toast.error('Failed to load color schemes');
    }
  };

  const changeShowSelect = (event) => {
    setSelectedValue(event.target.value);
  };

  return (
    <div>
      <Modal isOpen={showModalColor} backdrop="static">
        <ModalBody>
          <Radio.Group
            value={selectedValue}
            buttonStyle="solid"
            onChange={changeShowSelect}
          >
            <Radio.Button value="new">New</Radio.Button>
            <Radio.Button value="scheme">Copy from existing</Radio.Button>
          </Radio.Group>
          {selectedValue === 'scheme' ? (
            <Select
              name="colorScheme"
              options={colorSchemes.map((field) => ({
                _id: field._id,
                name: `${field.name} - [${field.categoriesCustomFieldsKey}]`,
              }))}
              onChange={(e) => {
                setSelectedData(
                  colorSchemes.find((el) => el._id === e.target.value)?.data
                );
              }}
            />
          ) : (
            ''
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => {
              createColorColumn(selectedData);
            }}
          >
            Add
          </Button>
          <Button color="light" onClick={closeModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalColor;
