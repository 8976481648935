import React, { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Input, Select, Divider, Space, Modal, Button } from "antd";
import { DeleteOutlined, EditOutlined, RollbackOutlined, PlusSquareOutlined } from '@ant-design/icons'
import { useLocation } from 'react-router-dom';

import guard from '../../../common/guard';
import creService from "../../../services/creService";
import TableViolation from '../../../common/table-violation/tableViolation.jsx';
// import configDB from '../../../../data/customizer/config';
// import auth from '../../../services/authService';
import AdressTable from '../../../common/adressesTable';
import ViewByColumn from './columnView'

const category = 'customCompliance'



const Compliance = ({ customCompliance, removeCategory, viewType, setCollapseDue, setAddedDevices }) => {

    // const [user] = useState(auth.getCurrentUser())
    const [addNew, setAddNew] = useState(null);
    const [buildings, setBuildings] = useState([]);
    const [unusedBuildings, setUnusedBuildings] = useState([]);
    const [unusedBuildingsFiltred, setUnusedBuildingsFiltred] = useState([]);
    const [dataForTable, setDataForTable] = useState({});
    const [filterValue, setFilterValue] = useState('')
    const [currentArchive, setCurrentArchive] = useState()
    const [exportArchive, setExportArchive] = useState()
    const location = useLocation();



    const [isModalArchivesVisible, setIsModalArchivesVisible] = useState(false);
    const [archives, setArchives] = useState([]);
    const [modifyiedArchives, setModifyiedArchives] = useState([]);
    const [itemsToArchive, setItemsToArchive] = useState([]);


    useEffect(() => {

        setArchives(customCompliance.archives ? customCompliance.archives : []);
        setCurrentArchive()
    }, [customCompliance])
    useEffect(() => {

        if (buildings.length && dataForTable.dataSource) {
            resetBuilding()
            if (setAddedDevices) {

                setAddedDevices(dataForTable.dataSource.reduce((ac, a) => {

                    if (customCompliance.additionalKeys.length) {
                        ac = [...ac, ...Object.values(a.additionalKeys || {})]
                    } else {
                        ac = [...ac, a.bin]
                    }

                    return ac
                }, []))
            }

        }

        // eslint-disable-next-line
    }, [buildings, dataForTable])

    useEffect(() => {
        if (addNew === false) {
            resetBuilding()
        }
        // eslint-disable-next-line
    }, [addNew])

    useEffect(() => {
        setUnusedBuildingsFiltred(unusedBuildings.filter(el => String(el.bin).includes(filterValue) || (el.address + el.display_address).toLowerCase().includes(filterValue.toLowerCase())))
    }, [filterValue, unusedBuildings])

    const moveToArchive = async () => {


        try {
            const response = await creService.moveToArchive(itemsToArchive, exportArchive);
            if (response.data.status) {
                setCurrentArchive(exportArchive)
                setExportArchive()
                setItemsToArchive([])
                toast.success(response.data.message)
            } else {
                toast.error(response.data.message)
            }
        } catch (e) {
            toast.error(e.message)
        }


    }
    const deleteArchiveHandler = (index) => {
        if (modifyiedArchives[index]._id) {
            setModifyiedArchives(modifyiedArchives.map((el, ind) => {
                if (index === ind) {
                    return { ...el, removed: !el.removed }
                }
                return el
            })
            )
        } else {
            setModifyiedArchives(modifyiedArchives.filter((el, ind) => index !== ind))

        }

    }
    const setArchiveName = (name, index) => {
        setModifyiedArchives(modifyiedArchives.map((el, ind) => {
            if (index === ind) {
                return { ...el, name }
            }
            return el
        })
        )
    }
    const openArchiveModal = () => {
        setModifyiedArchives(archives)
        setIsModalArchivesVisible(true)
    }

    const closeArchiveModal = () => {
        setIsModalArchivesVisible(false)
    }
    const saveArchives = async () => {
        try {
            const response = await creService.modifyArchives(modifyiedArchives, customCompliance._id);
            if (response.data.status) {
                setArchives(response.data.archives)

                setIsModalArchivesVisible(false)
            } else {
                toast.error(response.data.message)
            }
        } catch (e) {
            toast.error(e.message)
        }

    }
    const addNewArchive = () => {
        setModifyiedArchives([...modifyiedArchives, { name: 'New Archive' }])
    };

    const archiveSelectHandler = (value) => {
        if (value === 'current') { return setCurrentArchive() }
        setCurrentArchive(value)
    }

    const archiveHandler = (items, shouldUnarchive) => {
        if(shouldUnarchive) {
            unArchive(items)
        } else {
            setItemsToArchive(items)
        }
      
    }
    const unArchive  = async (itemsToUnArchive) => {

        try {
            const response = await creService.moveToArchive(itemsToUnArchive, 'current');
            if (response.data.status) {
                setCurrentArchive()
                toast.success(response.data.message)
            } else {
                toast.error(response.data.message)
            }
        } catch (e) {
            toast.error(e.message)
        }
    }

    const archiveExportSelectHandler = (id) => {
        setExportArchive(id)
    }

    const resetBuilding = () => {
        const unusedBuildings = buildings.reduce((ac, a) => {
            const data = dataForTable.dataSource.filter(item => item.bin === a.bin)
            if (!data.length || (customCompliance.additionalKeys.length && data.length)) {
                const element = { ...a }
                for (let { key, label } of customCompliance.additionalKeys) {
                    if (!element.additionalKeys) element.additionalKeys = {}
                    element.additionalKeys[key] = {
                        values: [{ value: '', error: '' }],
                        label,
                        existingValues: data.map(el => el.additionalKeys[key]),
                        posibleValues: dataForTable.posibleValues[a.bin] || []
                    }
                }
                ac.push(element)
            }
            return ac
        }, [])

        setUnusedBuildings(unusedBuildings)
    }

    const additionalKeysAdd = (key, bin, value = '') => {
        const updateBuildings = unusedBuildings.map(build => {
            if (build.bin === bin) {
                const newValue = {
                    value,
                    error: ''
                }
                const values = build.additionalKeys?.[key]?.values
                if (values) {
                    if (value && !values[values.length - 1].value) {
                        values[values.length - 1] = newValue
                    } else {
                        values.push(newValue)
                    }
                }


            }
            return build
        });
        handleBuildingCheck(
            true,
            bin
        )
        setUnusedBuildings(updateBuildings);
    }
    const additionalKeysRemove = (key, bin, index) => {
        const updateBuildings = unusedBuildings.map(build => {
            if (build.bin === bin) {
                const values = build.additionalKeys?.[key]?.values || []
                if (values.length > 1) {
                    build.additionalKeys[key].values = [...values.slice(0, index), ...values.slice(index + 1)]
                }
                validateBuilding(build)
            }
            return build
        });
        setUnusedBuildings(updateBuildings);
    }


    const additionalKeysChange = (key, value, bin, index, shouldValidate) => {
        handleBuildingCheck(true, bin, true)
        const updateBuildings = unusedBuildings.map(build => {
            if (build.bin === bin && build.additionalKeys?.[key]) {
                build.additionalKeys[key].values[index] = {
                    value,
                    error: ''
                }
                if (shouldValidate) {
                    validateBuilding(build)
                }
            }
            return build
        });
        setUnusedBuildings(updateBuildings);
    }
    const additionalKeysValidate = (bin) => {

        const updateBuildings = unusedBuildings.map(build => {
            if (build.bin === bin) {
                validateBuilding(build)
            }
            return build
        });

        setUnusedBuildings(updateBuildings);

    }
    const validateBuilding = (build) => {
        let errorExist = false
        for (let key in build.additionalKeys) {
            for (let index = 0; index < build.additionalKeys[key].values.length; index++) {
                const value = build.additionalKeys[key].values[index].value
                let err = ''
                if (build.checked) {
                    if (!value) {
                        err = `${build.additionalKeys[key].label} can not be empty`
                    } else if (build.additionalKeys[key].values.filter(el => el.value === value).length > 1) {
                        err = `${build.additionalKeys[key].label} should be unique`
                    } else if (build.additionalKeys[key].existingValues.includes(value)) {
                        err = `${build.additionalKeys[key].label} with ${value} already created`
                    }
                }

                if (err) errorExist = true
                build.additionalKeys[key].values[index].error = err
            }
        }
        return errorExist
    }

    const handleBuildingCheck = (state, bin, dontValidate) => {
        const updateBuildings = unusedBuildings.map(build => {
            if (!bin || build.bin === bin) {
                build.checked = state
                if (!dontValidate) {
                    validateBuilding(build)
                }
            }
            return build
        });
        setUnusedBuildings(updateBuildings);
    };

    const handleSaveClick = async () => {
        const [items, error] = prepareItemAndcheckValidation()
        if (error) {
            toast.warn('please fix validation issues')
            return
        } else if (items.length === 0) {
            toast.warn('nothing to save')
            return
        }
        try {
            const { data } = await creService.createCustomComplianceNew({ items, customComplianceId: customCompliance._id });
            toast.success(data.message)
        } catch (err) {
            toast.error(err.message)
        }

        await loadContent(customCompliance._id);
        setAddNew(false)
    }
    const prepareItemAndcheckValidation = () => {

        const { updatedBuildings, items, errorExist } = unusedBuildingsFiltred.reduce((ac, a) => {
            if (a.checked) {
                if (validateBuilding(a)) {
                    ac.errorExist = true
                } else {
                    let additionalKeys = []
                    if (a.additionalKeys) {
                        let intial = null
                        const allKeyes = Object.keys(a.additionalKeys)
                        for (let i = 0; i < allKeyes.length; i++) {
                            let key = allKeyes[i]
                            if (!intial) {
                                intial = a.additionalKeys[key].values.map(el => ({ [key]: el.value }))
                            } else {
                                for (let initItem of intial) {
                                    for (let { value } of a.additionalKeys[key].values) {
                                        additionalKeys.push({ ...initItem, [key]: value })
                                    }
                                }
                            }
                            if (i !== allKeyes.length - 1) {
                                intial = additionalKeys
                                additionalKeys = []
                            } else if (i === 0) {
                                additionalKeys = intial
                            }
                        }
                    }
                    ac.items.push({
                        bin: a.bin,
                        additionalKeys
                    })
                }
            }
            ac.updatedBuildings.push(a)
            return ac
        }, { updatedBuildings: [], items: [], errorExist: false })

        if (errorExist) {
            setUnusedBuildings(updatedBuildings);
        }

        return [items, errorExist]
    }



    const loadContent = async (id) => {
        try {
            const compliancesRes = await creService.getCustomCompliancesNew(id, currentArchive);
            if (compliancesRes.data.items.length) {
                setCollapseDue(false)

            } else {
                setCollapseDue(true)

            }

            const columns = [
                { title: 'Address', key: 'address' },
                ...customCompliance.additionalKeys.map(el => ({ title: el.label, key: el.key })),
                ...compliancesRes.data.additionalViolationsKeys
            ]
            const linkedKey = columns[1] ? columns[1].key : columns[0].key
            setDataForTable({
                posibleValues: compliancesRes.data.posibleValues,
                columns,
                dataSource: compliancesRes.data.items.map(el => ({ ...el, ...el.additionalKeys })),
                customFieldData: {},
                category,
                sub: customCompliance.name,
                options: {
                    links: {
                        [linkedKey]: `/custom-compliance/${customCompliance.name}/:record._id`
                    }
                },
            })
        } catch (e) {
            toast.error(e.message)
        }
    };

    const loadBuildings = async () => {
        const buildingRes = await creService.getBuildingsListOnly('portfolio');
        setBuildings(buildingRes.data);
    }

    useEffect(() => {
        if (customCompliance) {
            setDataForTable({})
            setAddNew(null)
            loadContent(customCompliance._id);
        }
        // eslint-disable-next-line
    }, [customCompliance, viewType, currentArchive])

    useEffect(() => {
        loadBuildings();
        // eslint-disable-next-line
    }, [])

    const removeFunction = async (id) => {
        const result = await creService.deleteCustomComplianceNew(id)
        const deletedEl = dataForTable.dataSource.find(el => el._id === id)

        const building = buildings.find(el => el.bin === deletedEl.bin)

        setUnusedBuildings([...unusedBuildings, building])
        return result
    }
 
    let advancedSearchDefault =  null 
    const search = new URLSearchParams(location.search)
    if(search.get("status")){
        advancedSearchDefault = {
            Status: [search.get("status") || '']
        }
    }
    if(search.get("bin")){
        const building = buildings.find(el => String(el.bin) === search.get("bin"))
         if(building) {
            advancedSearchDefault = {
                Address: [building.address]
            }
         }
    }

    return (
        <Fragment>
            <div className="table-responsive-sm mb-4 p-3">

                <div className={`d-flex ${addNew ? '' : 'justify-content-between'}`}>


                    <button className={`btn ${unusedBuildings.length > 0 ? 'btn-primary' : 'btn-light'}  btn-sm `} onClick={() => setAddNew(!addNew)} disabled={!(unusedBuildings.length > 0) || currentArchive} title={!(unusedBuildings.length > 0) ? 'All addresses has been added' : null} >
                        <i className={`fa fa-${addNew ? 'times' : 'plus'}`} /> {addNew ? 'Close' : 'Add Record'}
                    </button>

                    {!addNew && itemsToArchive.length !== 0 && !currentArchive && archives.length !== 0 && <div>
                            <Select
                                style={{ width: 200 }}
                                placeholder="Select Archive"
                                className="ml-2 mb-1"
                                value={exportArchive}
                                onChange={archiveExportSelectHandler}
                                options={archives.map((item) => ({ label: item.name, value: item._id }))}
                            />
                            <button disabled={!exportArchive} className='ml-2 mb-1 btn btn-sm btn-warning ' onClick={moveToArchive} >Move to Archive</button>
                        </div>

                    }


                    {addNew ?
                        (<div className="d-flex justify-content-between align-items-center flex-grow-1 ">
                            <div>
                                <button className="btn btn-success btn-sm ml-2" onClick={() => handleSaveClick()} >
                                    <i className={`fa fa-save`} /> Save
                                </button>
                            </div>
                            <div><Input placeholder="Building Filter" value={filterValue} onChange={(e) => setFilterValue(e.target.value)} /></div>

                        </div>) :
                        <div>
                            {guard({ serviceName: 'custom-compliance', action: 'compliance-remove-add-category' }) &&
                                customCompliance.createdByClient && (
                                    <button className="btn btn-outline-danger btn-sm my-2 mr-2" onClick={() => removeCategory(customCompliance._id)}>
                                        <DeleteOutlined /> Remove Category
                                    </button>
                                )
                            }

                                <Select
                                    style={{ width: 200 }}
                                    className="mr-2 mb-1"
                                    allowClear
                                    value={currentArchive}
                                    placeholder="Current"
                                    onChange={archiveSelectHandler}
                                    dropdownRender={(menu) => (
                                        <>
                                            {menu}
                                            <Divider style={{ margin: '8px 0' }} />
                                            <Space className="w-100">

                                                <Button type="text" icon={<EditOutlined />} onClick={openArchiveModal} className="d-block w-100">
                                                    Add Archive
                                                </Button>
                                            </Space>
                                        </>
                                    )}
                                    options={[{ name: 'Current', _id: 'current' }, ...archives].map((item) => ({ label: item.name, value: item._id }))}
                                />


                        </div>

                    }

                </div>

                {unusedBuildings.length > 0 && addNew &&
                    <div className="table-responsive">
                        <AdressTable
                            unChecked={true}
                            additionalKeysChange={additionalKeysChange}
                            handleUnAndCheckAll={handleBuildingCheck}
                            buildings={unusedBuildingsFiltred}
                            handleBuildingCheck={handleBuildingCheck}
                            additionalKeysValidate={additionalKeysValidate}
                            additionalKeysAdd={additionalKeysAdd}
                            additionalKeysRemove={additionalKeysRemove}
                        />
                    </div>}


            </div>
            {dataForTable.dataSource?.length > 0 && (viewType === 'list' ?
                <TableViolation
                    saveOn={category}
                    removeFunction={guard({ serviceName: 'custom-compliance', action: 'compliance-remove-add-individual' }) ? removeFunction : null}
                    data={dataForTable}
                    customFieldsFromViolation={true}
                    multiChangeAction={true}
                    currentArchive={currentArchive}
                    archiveFunction={archiveHandler}
                    advancedSearchDefault={advancedSearchDefault}

                /> : <ViewByColumn data={dataForTable} />)
            }
            <Modal
                title="Archives"
                visible={isModalArchivesVisible}
                footer={null}
                onCancel={closeArchiveModal}
                width={1000}
            >
                <div className="p-2">
                    {modifyiedArchives.map((el, index) =>
                        <Input

                            disabled={el.removed}
                            key={index}
                            value={el.name}
                            className="mt-1"
                            onChange={(event) => setArchiveName(event.target.value, index)}
                            addonBefore={el.removed ? 'Removed' : 'Archive Name'}
                            addonAfter={el.removed ? <RollbackOutlined onClick={() => deleteArchiveHandler(index)} /> : <DeleteOutlined onClick={() => deleteArchiveHandler(index)} />}
                        />
                    )}
                    <div className=" mt-2">
                        <PlusSquareOutlined style={{ color: '#4466f2', fontSize: '40px' }} onClick={addNewArchive} />


                    </div>

                    <div className="d-flex justify-content-between mt-4">
                        <button type="button" className="btn btn-primary" onClick={closeArchiveModal}>Cancel</button>
                        <button type="button" className="btn btn-primary" onClick={saveArchives}>Save</button>

                    </div>

                </div>
            </Modal>
        </Fragment>
    )
}

export default Compliance
