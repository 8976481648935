import React, { useState, Fragment, useEffect, useCallback, useRef } from 'react';
import MENUITEMS from '../../../constant/menu';
import { Link } from 'react-router-dom';
import { Clipboard, Home, Search, Tool } from 'react-feather';
import creService from "../../services/creService";
import ReactLoading from "react-loading";
import { toast } from 'react-toastify';

const SearchHeader = () => {
    const mainmenu = MENUITEMS;
    const [searchValue, setsearchValue] = useState('');
    const [searchOpen, setsearchOpen] = useState(false);

    const escFunction = useCallback((event) => {
        if (event.keyCode === 27) {
            //Do whatever when esc is pressed
            setsearchOpen(false)
            setsearchValue('')
        }
    }, []);

    useEffect(() => {
        document?.addEventListener("keydown", escFunction, false);
        return () => {
            document?.removeEventListener("keydown", escFunction, false);
        };
        //eslint-disable-next-line
    }, []);

    const OnOutsideClick = (ref) => {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    document.querySelector(".Typeahead-menu").classList.remove('is-open');
                    document.querySelector(".empty-menu").classList.remove('is-open');
                    document.body.classList.remove("offcanvas");
                }
            }

            document?.addEventListener("mousedown", handleClickOutside);
            return () => {
                document?.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const handleSearchKeyword = async (keyword) => {
        keyword ? addFix() : removeFix()
        const items = [];
        // eslint-disable-next-line
        if (keyword.length >= 1) {
            // Setting Loading...
            checkSearchResultEmpty([1, 2, 3]);
            setsearchValue([{ title: 'Loading...', icon: 'loading', path: '' }])

            let searchResult = await creService.searchV3(keyword);
            if (searchResult.data.status) {
                if (searchResult.data.data.length > 0) {
                    searchResult.data.data.forEach(v => {
                        if (v && v.address) {
                            items.push({
                                title: v.display_address ? v.display_address : v.address,
                                type: 'building',
                                icon: Home,
                                path: `/violations/all/${v.bin}`
                            });
                        } else {
                            items.push({
                                directMatch: v.directMatch,
                                title: `${v.category}/${v.sub.replace(/_/g, ' ')} - #${v.uniqueKey ? Object.entries(v.uniqueKey)[0]?.[1] : ''}`,
                                icon: Clipboard,
                                type: 'link',
                                path: `/violation-details/${v._id}/notes`
                            });
                        }
                    })
                }
                if (searchResult.data.workOrderData.length > 0) {
                    searchResult.data.workOrderData.forEach(v => {
                        items.push({
                            directMatch: v.directMatch,
                            title: `Work Order #${v.workOrderId}`,
                            icon: Tool,
                            type: 'link',
                            path: `/work-orders/view/${v._id}`
                        })
                    })
                }
            } else {
                toast.error(searchResult.data.message);
            }
        }

        // eslint-disable-next-line
        mainmenu.filter(menuItems => {
            if (menuItems.title.toLowerCase().includes(keyword) && menuItems.type === 'link') {
                items.push(menuItems);
            }

            if (!menuItems.children) {
                checkSearchResultEmpty(items)
                setsearchValue(items);
                return false
            }
            // eslint-disable-next-line
            menuItems.children.filter(subItems => {
                if (subItems.title.toLowerCase().includes(keyword) && subItems.type === 'link') {
                    subItems.icon = menuItems.icon
                    items.push(subItems);
                }
                if (!subItems.children) return false
                // eslint-disable-next-line
                subItems.children.filter(suSubItems => {
                    if (suSubItems.title.toLowerCase().includes(keyword)) {
                        suSubItems.icon = menuItems.icon
                        items.push(suSubItems);
                    }
                })
            })
            checkSearchResultEmpty(items)
            setsearchValue(items);
        });
    }

    const checkSearchResultEmpty = (items) => {
        if (!items.length) {
            document.querySelector(".empty-menu").classList.add('is-open');
        } else {
            document.querySelector(".empty-menu").classList.remove('is-open');
        }
    }

    const addFix = () => {
        document.querySelector(".Typeahead-menu").classList.add('is-open');
        document.body.classList.add("offcanvas");
    }

    const removeFix = () => {
        setsearchValue('')
        document.querySelector(".Typeahead-menu").classList.remove('is-open');
        document.body.classList.remove("offcanvas");
    }

    const toggleBtn = () => {
        if (searchOpen) {
            setsearchOpen(!searchOpen);
            document.querySelector('.searchIcon').classList.add('open');
        } else {
            setsearchOpen(!searchOpen);
            document.querySelector('.searchIcon').classList.remove('open');
        }
    }

    const searchRef = useRef(null);
    OnOutsideClick(searchRef);

    return (
        <Fragment>
            <div ref={searchRef} className="head-search">
                <form className="form-inline search-form">
                    <div className="form-group">
                        <input
                            className="form-control-plaintext searchIcon"
                            type="text"
                            placeholder="search"
                            defaultValue={searchValue}
                            onChange={(e) => handleSearchKeyword(e.target.value)}
                        />
                        <span className="d-sm-none mobile-search" onClick={toggleBtn}>
                            <Search />
                        </span>

                        <div className="Typeahead-menu custom-scrollbar" id="search-outer">
                            {searchValue &&
                                searchValue
                                    .sort((a, b) => {
                                        const aValue = a.type === "building" ? 2 : (a.directMatch ? 1 : 0)
                                        const bValue = b.type === "building" ? 2 : (b.directMatch ? 1 : 0)
                                        return bValue - aValue
                                    }).map((data, index) => {
                                        return (
                                            <div className="ProfileCard u-cf" key={index} style={{
                                                background: data.type === "building" ? 'rgb(96, 196, 247,0.2)' : (data.directMatch ? 'rgb(96, 196, 247,0.1)' : undefined)
                                            }}>
                                                <div className="ProfileCard-avatar">
                                                    {(data.icon !== 'loading') ? <data.icon /> :
                                                        <ReactLoading type={"cylon"} color={"grey"} height={24} width={24} />
                                                    }
                                                </div>
                                                <div className="ProfileCard-details">
                                                    <div className="ProfileCard-realName text-left"  >
                                                        <Link
                                                            to={data.path}
                                                            className="realname"
                                                            onClick={removeFix}
                                                        >
                                                            {data.title}
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                            }
                        </div>
                        <div className="Typeahead-menu empty-menu">
                            <div className="tt-dataset tt-dataset-0">
                                <div className="EmptyMessage">
                                    Opps!! There are no result found.
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </Fragment>
    );
};

export default SearchHeader;
