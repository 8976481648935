import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { toast } from "react-toastify";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    FormGroup,
    Label,
    Input,
    FormText,
} from 'reactstrap';
import creApi from '../../../services/creService';

const ChatModal = ({ violationId, modalState, toggle }) => {
    const location = useLocation();

    const [requestType, setRequestType] = useState('NEED-HELP');

    const [message, setMessage] = useState("");
    const [isPrivate, setIsPrivate] = useState(false);

    const [sending, setSending] = useState(false);

    useEffect(() => {
        const needHelp = new URLSearchParams(location.search).get("need-help")
        const hireUs = new URLSearchParams(location.search).get("hire-us")
        const reply = new URLSearchParams(location.search).get("reply")
        if (needHelp !== null) {
            setRequestType('NEED-HELP');
        }
        if (hireUs !== null) {
            setRequestType('HIRE-US');
        }
        if (reply !== null) {
            setRequestType('REPLY');
        }
    }, [location]);

    const goToChat = () => {
        window.scrollTo(0, document.getElementById('all-tabs-area').offsetTop);
        toggle();
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        let finalMessage = message;
        if (requestType === 'NEED-HELP') {
            finalMessage = `(I need help) ${message}`
        }
        if (requestType === 'HIRE-US') {
            finalMessage = `(I want to hire) ${message}`
        }
        let data = {
            violationId,
            message: finalMessage,
            isPrivate,
        }
        setSending(true);
        try {
            await creApi.sendChatMessage(data);
            setSending(false);
        } catch (e) {
            toast.error(e);
            setSending(false);
        }
        toggle();
        window.scrollTo(0, document.getElementById('all-tabs-area').offsetTop);
    }

    return (
        <Modal isOpen={modalState} toggle={toggle} >
            <ModalHeader>
                {requestType === "NEED-HELP" ? 'I need help' : requestType === "HIRE-US" ? 'I want to hire' : 'Reply to Message'}
            </ModalHeader>
            <ModalBody>
                <Form onSubmit={(e) => handleSubmit(e)}>
                    <FormGroup>
                        <Label htmlFor="chatMessage">Enter your message <sup className="text-danger">*</sup></Label>
                        <Input type="textarea" name="text" id="chatMessage" required onKeyUp={(e) => setMessage(e.target.value)} />
                    </FormGroup>
                    <FormGroup check>
                        <Label check>
                            <Input type="checkbox" checked={isPrivate} onChange={(e) => setIsPrivate(e.target.checked)} />{' '} Private Message
                        </Label>
                        <FormText color="muted">
                            By Making Private the message will be visible to you and support team only.
                        </FormText>
                    </FormGroup>
                    <FormGroup className="mt-3">
                        <Button type="submit" color="primary" disabled={sending}>{sending ? "Sending..." : "Send"}</Button>
                    </FormGroup>
                </Form>
                <Button color="link" onClick={() => goToChat()} className="p-0">View Chat History</Button>
            </ModalBody>
        </Modal>
    )
}

export default ChatModal;
