import React from "react";

import ReactLoading from "react-loading";





const buildingsComponentNYC = ({ viewType, onAddressClick, sortField, handleSort, combined, doneLoading, buildingsData, fullBildingPage }) => {

    return <>
        {
            viewType === 'grid' && (
                <div className="grid-building">
                    <div className="card-body">
                        <div className="row">
                            {
                                !doneLoading
                                    ?
                                    (<div className="loadercenter"> <ReactLoading type={"cylon"} color={"grey"} /> </div>)
                                    :
                                    (buildingsData.map((value, key) => {
                                        return <div key={key} className="col-6 col-xl-3 col-md-4 col-sm-4 mb-5 d-flex">
                                            <div className="pointer flex-fill"
                                                onClick={() => onAddressClick(value.BIN)}>
                                                <div className="icon-bg"><i className="fa fa-building"></i></div>
                                                <div className="thead d-flex">
                                                    <div className="flex-fill">
                                                        {value.Address}
                                                    </div>
                                                </div>
                                                {fullBildingPage === 'full' && <table>

                                                    <tbody>

                                                        <tr><td>DOB</td><td>{value.DOB}</td></tr>
                                                        <tr><td>ECB</td><td>{value.ECB}</td></tr>
                                                        <tr><td>HPD</td><td>{value.HPD}</td></tr>

                                                    </tbody>
                                                </table>}
                                            </div>
                                        </div>
                                    }
                                    ))
                            }
                        </div>
                    </div>
                </div>
            )
        }
        {
            viewType === 'list' && (
                <div className="my-building">
                    <div className="card-body">
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            BUILDING LIST
                                            <i className={`fa ml-1 fa-sort-numeric-${sortField.Address === -1 ? 'desc' : 'asc'}`} onClick={() => handleSort('Address')} style={{ cursor: "pointer" }} />
                                        </th>
                                        {fullBildingPage === 'full' && <>
                                            <th scope="col">
                                                DOB
                                                <i className={`fa ml-1 fa-sort-numeric-${sortField.DOB === -1 ? 'desc' : 'asc'}`} onClick={() => handleSort('DOB')} style={{ cursor: "pointer" }} />
                                            </th>
                                            <th scope="col">
                                                ECB
                                                <i className={`fa ml-1 fa-sort-numeric-${sortField.ECB === -1 ? 'desc' : 'asc'}`} onClick={() => handleSort('ECB')} style={{ cursor: "pointer" }} />
                                            </th>
                                            <th scope="col">
                                                HPD
                                                <i className={`fa ml-1 fa-sort-numeric-${sortField.HPD === -1 ? 'desc' : 'asc'}`} onClick={() => handleSort('HPD')} style={{ cursor: "pointer" }} />
                                            </th>
                                        </>
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        !doneLoading
                                            ?
                                            (<tr><td colSpan="5"><div align="center"> <ReactLoading type={"cylon"} color={"grey"} /> </div></td></tr>)
                                            :
                                            (
                                                fullBildingPage === 'full' ? buildingsData.map((value, key) => {

                                                    return <tr key={key} className="pointer"
                                                        onClick={() => onAddressClick(value.BIN)}>
                                                        <td><i className="fa fa-building"></i> {value.Address}</td>
                                                        <>
                                                            <td>{
                                                                typeof value.DOB === 'number' ?
                                                                    value.DOB :
                                                                    <div className="d-flex justify-content-center">
                                                                        <ReactLoading type={'bars'} color={'#DCDCDC'} width={20} height={20} />
                                                                    </div>}
                                                            </td>
                                                            <td>{typeof value.ECB === 'number' ?
                                                                value.ECB :
                                                                <div className="d-flex justify-content-center"> <ReactLoading type={'bars'} color={'#DCDCDC'} width={20} height={20} />
                                                                </div>}
                                                            </td>
                                                            <td>{typeof value.HPD === 'number' ? value.HPD :
                                                                <div className="d-flex justify-content-center"> <ReactLoading type={'bars'} color={'#DCDCDC'} width={20} height={20} />
                                                                </div>}
                                                            </td>
                                                        </>
                                                    </tr>
                                                }) : combined.map((value, key) => {

                                                    return <tr
                                                        key={key}

                                                    >
                                                        <td
                                                            className="pointer  w-50"
                                                            onClick={() => onAddressClick(value[0].BIN)}
                                                        ><i className="fa fa-building"></i> {value[0].Address}</td>

                                                        {value[1] && <td
                                                            className="pointer w-50 text-left"
                                                            onClick={() => onAddressClick(value[1].BIN)}
                                                        ><i className="fa fa-building"></i> {value[1].Address}</td>}
                                                    </tr>
                                                })
                                            )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )
        }
    </>
}

export default buildingsComponentNYC;
