
import { Modal } from "antd";
import moment from 'moment';
import { useEffect, useState } from "react";
import { Table } from 'antd';
import {
    LeftOutlined,
    RightOutlined
} from '@ant-design/icons';

import './PivotTable.css'
import PivotPropertyDropDown from './PivotPropertyDropDown';
import { dateSorter } from "./Charts";

const getdisplayValue = (column, a) => {
    let keyValue = a[column.key]

    if (column.data?.bcType === 'colors') {
        const found = column.data?.data?.find?.(
            (colorLabel) => colorLabel.id === a[column.key]
        );
        keyValue = found?.text
    } else if (column.data?.bcType === 'users') {
        const found = column.subUsers?.find(
            (el) => el._id === a[column.key]
        );
        keyValue = found?.fullName
    } else if (column.data?.bcType === 'vendors') {
        const found = column.vendors?.find(
            (el) => el._id === a[column.key]
        );
        keyValue = found?.fullName
    }
    return keyValue || 'N/A'
}

const PivotTable = ({ pivotSecondKey, isVisible, setIsVisible, columns, dataSource, handleSetColumnPivotMasterProperty }) => {

    const [pivotData, setPivotData] = useState([])
    const [pivotColumns, setPivotColumns] = useState([])
    const [pageSize, setPageSize] = useState(10)
    const [page, setPage] = useState(0)


    useEffect(() => {
        pageSizeCount()
        window?.addEventListener('resize', pageSizeCount);
        return () => {
            window?.removeEventListener('resize', pageSizeCount);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const pageSizeCount = () => {
        const sizeOut = window.innerWidth * 0.95 - 120 - 100 * pageSize
        if (sizeOut < 0) {

            setPageSize(pageSize - Math.floor(sizeOut / -100) - 1)
        }

    }

    useEffect(() => {
        if (columns && columns.length && pivotSecondKey) {
            let masterColumn = columns.find(el => el.isPivotMasterProperty)
            let pivotSecondColumn = columns.find(el => el.key === pivotSecondKey)
            const uniqueValue = new Set();
            const result = dataSource.reduce((ac, a) => {
                const keyValueSecond = getdisplayValue(pivotSecondColumn, a)
                const keyValueMaster = getdisplayValue(masterColumn, a)

                uniqueValue.add(keyValueSecond)
                if (!ac[keyValueMaster]) {
                    ac[keyValueMaster] = {}
                }
                if (ac[keyValueMaster][keyValueSecond]) {
                    ac[keyValueMaster][keyValueSecond] += 1
                } else {
                    ac[keyValueMaster][keyValueSecond] = 1
                }
                return ac
            }, {})

            const pivotColumns = [{
                title: <div >
                    <div className="d-flex border-bottom border-dark">
                        <div className="w-50 p-2 ">
                        </div>
                        <div className="border-left w-50 p-2 border-dark">
                            {pivotSecondColumn.description}
                        </div>
                    </div>
                    <div className="d-flex">
                        <div className=" w-50 p-2">
                            {masterColumn.description}
                        </div>
                        <div className=" border-left w-50 p-2 border-dark">
                        </div>
                    </div>
                </div>,
                dataIndex: 'masterProperty',
                key: 'masterProperty',
                width: 400
            }]

            let temp = []
   
            const isDateSecond = /date/i.test(pivotSecondColumn.description + '_' + pivotSecondColumn.key)
            const isDateMaster = /date/i.test(masterColumn.description + '_' + masterColumn.key)
            for (let item of uniqueValue) {
                let display = item

                if (isDateSecond) {
                    const date = moment.utc(item);
                    display = date.isValid() ? date.format('MM/DD/YYYY') : item
                    date.isValid()
                }

                temp.push({
                    title: <div className="d-flex justify-content-center"><div className="text-center max-width-80">{display}</div></div>,
                    dataIndex: item,
                    key: item,
                    displayValue: display
                })
            }

            if (isDateSecond) {
                temp = dateSorter(temp, null, 'displayValue')
            }

            setPivotColumns([...pivotColumns, ...temp])
            setPivotData(Object.keys(result).map(key => {
                const date = moment.utc(key);
                return {
                    key,
                    masterProperty: isDateMaster ? (date.isValid() ? date.format('MM/DD/YYYY') : key) : key,
                    ...result[key]
                }
            }))
        }

    }, [columns, pivotSecondKey, dataSource])

    const filteredPivotColumns = pivotColumns.length ? [pivotColumns[0], ...pivotColumns.slice(page * pageSize + 1, (page + 1) * pageSize + 1)] : []
    const filteredPivotData = pivotData.filter(dataElement => filteredPivotColumns.slice(1).some(columnEl => dataElement[columnEl.key]))

    return pivotSecondKey && (

        <Modal
            style={{ top: 20 }}
            title='Pivot Table'
            visible={isVisible}
            footer={null}
            onCancel={() => setIsVisible(false)}
            width={'95%'}
        >
            <div className="pivot-table-wrapper over">
                <div className="mb-3 d-flex justify-content-between">
                    <PivotPropertyDropDown
                        pivotSecondKey={pivotSecondKey}
                        handleSetColumnPivotMasterProperty={handleSetColumnPivotMasterProperty}
                        columns={columns}
                    />
                    <div>
                        <button
                            type="button"
                            className="btn btn-sm btn-primary mr-1"
                            disabled={page === 0} onClick={() => setPage(page - 1)}
                        >
                            <LeftOutlined />
                        </button>
                        <button
                            type="button"
                            className="btn btn-sm btn-primary"
                            disabled={pivotColumns.length / pageSize < page + 1}
                            onClick={() => setPage(page + 1)}>
                            <RightOutlined />
                        </button>
                    </div>

                </div>
                <Table
                    tableLayout="auto"
                    size={'small'}
                    columns={filteredPivotColumns}
                    dataSource={filteredPivotData}
                    pagination={false}
                    bordered
                />
            </div>
        </Modal>

    );

}

export default PivotTable;