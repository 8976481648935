import React, { Fragment, useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { useHistory, useLocation } from 'react-router-dom';
import { useParams } from 'react-router';
import moment from 'moment';


import creService from '../../services/creService';

const WorkOrderCreate = (props) => {

    const history = useHistory();
    const params = useParams();
    const location = useLocation();

    const cloneId = new URLSearchParams(location.search).get('clone');
    const workOrderId = params.id ? params.id : cloneId;
    const violationId = params.violationId;
    const editView = workOrderId && !cloneId ? true : false;

    const [bin, setBin] = useState(params.bin);


    const formRef = useRef();


    const [isCreating, setIsCreating] = useState(false);

    const [isBuildingLoading, setBuildingLoading] = useState(true);
    // const [isUploading, setIsUploading] = useState(false);
    // const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({ vendorNotification: 'NoEmail', title: 'Visitt Work Order' });
    // Used for Edit
    // const [originalData, setOriginalData] = useState({ });
    // const [workOrderBuilding, setWorkOrderBuilding] = useState(null);
    const [buildings, setBuildings] = useState([]);
    // const [priority, setPriority] = useState('Low');
    const [visittCategories, setVisittCategories] = useState([]);
    const [visittSpaces, setVisittSpaces] = useState([]);
    // const [uploads, setUploads] = useState([]);
    // const [partialEdit, setPartialEdit] = useState(false);
    const [violationData, setViolationData] = useState({});
    // const [dueDatePicker, setDueDatePicker] = useState(false);

    // useEffect(() => {
    //     if (workOrderBuilding) {
    //         const buildingExists = buildings.find(
    //             (b) => b.bin === workOrderBuilding.bin
    //         );
    //         if (!buildingExists) {
    //             setBuildings((prev) => [...prev, workOrderBuilding]);
    //         }
    //     }
    // }, [buildings, workOrderBuilding]);

    const loadBuildings = async () => {
        setBuildingLoading(true);
        const buildingRes = await creService.getBuildingsListOnly();
        setBuildings(buildingRes.data);
        setBuildingLoading(false);
    };

    const loadViolation = async () => {
        const res = await creService.getWorkOrderViolation(violationId, 'visitt');
        if (res.data.status) {
            setViolationData(res.data.data);
            setFormData((state) => ({
                ...state,
                description: res.data.data.description,
                aptNumber: res.data.data.apartment
            }));
        } else {
            toast.error(res.data.message);
        }
    };


    // const loadAllStatus = async () => {
    //     const res = await creService.getWorkOrderStatus(workOrderId);
    //     if (res.data.status) {
    //         setAllStatus(res.data.data);
    //     } else {
    //         toast.error(res.data.message);
    //     }
    // };

    const handleInput = (e) => {
        let { name, value, type, checked } = e.target;
        if (type === 'date') {
            value = new Date(value)
        } else if (type === 'checkbox') {
            value = checked
        } else {
            value = value.length ? value : null
        }
  

        setFormData((state) => ({
            ...state,
            [name]: value
        }));
    };

    const categoryChange = (value) => {

        setFormData((state) => ({
            ...state,
            category: value,
            categoryName: visittCategories.find(el => el._id === value).name,
            subCategory: '',
            subCategoryName: ''
        }));
    };
    const subCategoryChange = (value) => {
        let name = ''
        for (let cat of visittCategories) {
            const find = cat.subCategories.find(el => value === el._id)
            if (find) {
                name = find.name
            }
        }

        setFormData((state) => ({
            ...state,
            subCategory: value,
            subCategoryName: name
        }));
    };

    const handleOnSubmit = async (e) => {
        e.preventDefault();

        // setIsCreating(true);
        let payload = {
            ...formData,
            priority: 'Low',
            violationId,
            bin,
            dueDate: moment().add(3, 'days').format('MM/DD/YYYY')
        };

        try {
            const res = await creService.createWorkOrder(payload, 'visitt');

            if (res.data.status) {
                toast.success(res.data.message);
                if (res.data.workOrderId) {
                    history.push(`/work-orders/view/${res.data.workOrderId}/documents`);
                } else {
                    history.push('/work-orders/list');
                }
            } else {
                if (res.data.errors) {
                    res.data.errors.map((err) => toast.error(err));
                } else {
                    toast.error(res.data.message);
                }
            }
        } catch (_err) {
            toast.error(_err.message || 'Something went wrong');

        }
        setIsCreating(false);
    };



    const getCategories = async () => {
        try {
            const res = await creService.getVisittCategories(bin);

            if (res.data.status) {
                setVisittCategories(res.data.categories)

            } else {
                toast.error(res.data.message);
            }
        } catch (_err) {
            toast.error(_err.message);

        }
    }

    
    const getSpaces = async () => {
        try {
            const res = await creService.getVisittSpaces(bin);

            if (res.data.status) {
                setVisittSpaces(res.data.sites)

            } else {
                toast.error(res.data.message);
            }
        } catch (_err) {
            toast.error(_err.message);

        }
    }

    const spaceChange = (value) => {

        setFormData((state) => ({
            ...state,
            site: value,
            aptNumber: visittSpaces.find(el => el._id === value).name,
        }));
    };


    useEffect(() => {
        // loadAllStatus();
        loadBuildings();
        if (violationId) {
            loadViolation();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (bin) {
            getCategories()
            getSpaces()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bin])




    return (
        <Fragment>
            <div className="row violation-sec">
                <div className="col-xl-12 my-3">
                    <div className="card">
                        <div className="card-header">

                            <h5>
                                {editView ? 'Edit' : 'Create'} Work Order{' '}
                                {/* {editView ? `#${originalData.workOrderId}` : ''} */}
                            </h5>
                        </div>

                        <div className="card-body">
                            {Object.keys(violationData).length > 0 && (
                                <h6>
                                    <b>{violationData.label}:</b> {violationData.data}
                                </h6>
                            )}

                            <form ref={formRef} onSubmit={(e) => handleOnSubmit(e)}>
                                {!editView && (
                                    <>
                                        <div className="row">

                                            <div className="form-group col-12 col-md-6">
                                                <label>
                                                    Building Address{' '}
                                                    <sup className="text-danger">*</sup>
                                                </label>
                                                <select
                                                    className="form-control"
                                                    defaultValue={formData.bin}
                                                    value={bin}
                                                    disabled={
                                                        isBuildingLoading ||
                                                        Object.keys(violationData).length > 0
                                                    }
                                                    name="bin"
                                                    required
                                                    onChange={(e) => setBin(e.target.value)}
                                                >
                                                    <option value="">
                                                        {isBuildingLoading
                                                            ? 'Loading...'
                                                            : '-- Select --'}
                                                    </option>
                                                    {buildings &&
                                                        buildings.filter(building => building.visittAddress).map((building) => (
                                                            <option
                                                                key={`building_${building.bin}`}
                                                                value={building.bin}
                                                            >
                                                                {building.visittAddress}
                                                            </option>
                                                        ))}
                                                </select>
                                            </div>
                                            {/* <div className="form-group col-12 col-md-6">
                                                <label>
                                                    Due Date <sup className="text-danger">*</sup>
                                                </label>
                                                <div className="input-group ">
                                                    <input
                                                        required
                                                        readOnly
                                                        type="text"
                                                        name="dueDate"
                                                        className="form-control bg-white"
                                                        onClick={() => setDueDatePicker((prev) => !prev)}
                                                        value={
                                                            formData.dueDate &&
                                                            moment
                                                                .utc(formData.dueDate)
                                                                .format('MM/DD/YYYY')
                                                        }
                                                    />
                                                    <div className="input-group-append">
                                                        <button
                                                            className="btn btn-primary"
                                                            onClick={() =>
                                                                setDueDatePicker((prev) => !prev)
                                                            }
                                                            type="button"
                                                        >
                                                            <i className="fa fa-calendar"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                {dueDatePicker && (
                                                    <DueDatePicker
                                                        // initialDate={originalData.dueDate}
                                                        currentDate={formData.dueDate}
                                                        editView={editView}
                                                        onChange={onDueDateChange}
                                                    />
                                                )}
                                            </div>  */}
                                            <div className="form-group col-12 col-md-6">
                                                <label>
                                                    Category <sup className="text-danger">*</sup>
                                                </label>
                                                <select
                                                    defaultValue={formData.category}
                                                    className="form-control"
                                                    name="category"
                                                    required
                                                    onChange={(e) => categoryChange(e.target.value)}
                                                >
                                                    <option value="">-- Select --</option>
                                                    {visittCategories.map(category => (
                                                        <option key={category._id} value={category._id}>
                                                            {category.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                           
                                            {visittCategories.find(el => el._id === formData.category)?.subCategories.length ?
                                                <div className="form-group col-12 col-md-6">
                                                    <label>
                                                        Sub Category <sup className="text-danger"></sup>
                                                    </label>
                                                    <select
                                                        defaultValue={formData.subCategory}
                                                        className="form-control"
                                                        name="subCategory"
                                                        required
                                                        onChange={(e) => subCategoryChange(e.target.value)}
                                                    >
                                                        <option value="">-- Select --</option>
                                                        {visittCategories.find(el => el._id === formData.category).subCategories.map(category => (
                                                            <option key={category._id} value={category._id}>
                                                                {category.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div> : null
                                            }
                                             <div className="form-group col-12 col-md-6">
                                                <label>
                                                    Apt Number 
                                                </label>
                                                <select
                                                    defaultValue={formData.aptNumber}
                                                    className="form-control"
                                                    name="space"
                                                  
                                                    onChange={(e) => spaceChange(e.target.value)}
                                                >
                                                    <option value="">-- Select --</option>
                                                    {visittSpaces.map(space => (
                                                        <option key={space._id} value={space._id}>
                                                            {space.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>

                                        {/* <div className="row">
                                            <div className="form-group col-12 col-md-4">
                                                <label className='mr-2'>
                                                    Priority
                                                </label>
                                                <ButtonGroup>
                                                    <Button
                                                        className="priority"
                                                        color="info"
                                                        onClick={() => setPriority('Low')}
                                                        active={priority === 'Low'}
                                                    >
                                                        Low
                                                    </Button>
                                                    <Button
                                                        className="priority"
                                                        color="warning"
                                                        onClick={() => setPriority('Medium')}
                                                        active={priority === 'Medium'}
                                                    >
                                                        Medium
                                                    </Button>
                                                    <Button
                                                        className="priority"
                                                        color="danger"
                                                        onClick={() => setPriority('High')}
                                                        active={priority === 'High'}
                                                    >
                                                        High
                                                    </Button>
                                                </ButtonGroup>
                                            </div>
                                        </div> */}

                                        {/* <div className="row">
                                                <div className="form-group col-12 col-md-6">
                                                    <label>Space/Equipment (optional)</label>
                                                    <select
                                                        defaultValue={'test'}
                                                        className="form-control"
                                                        name="vendorId"
                                                        disabled={true}
                                                        onChange={(e) => handleInput(e)}
                                                    >
                                                        <option value="">
                                                            {true ? 'Loading...' : '-- Select --'}
                                                        </option>
                                
                                                    </select>
                                                </div>
                                                <div className="form-group col-12 col-md-6">
                                                    <label>Checklist (optional)</label>
                                                    <select
                                                        defaultValue={'test'}
                                                        className="form-control"
                                                        name="vendorId"
                                                        disabled={true}
                                                        onChange={(e) => handleInput(e)}
                                                    >
                                                        <option value="">
                                                            {true ? 'Loading...' : '-- Select --'}
                                                        </option>

                                                    </select>
                                                </div>
                                            </div> */}
                                    </>
                                )}

                                <div className="row">
                                    <div className="form-group col-12">
                                        <label>
                                            Description <sup className="text-danger">*</sup>
                                        </label>
                                        <textarea
                                            type="text"
                                            name="description"
                                            className="form-control"
                                            onChange={(e) => handleInput(e)}
                                            required
                                            value={formData.description}
                                        ></textarea>
                                    </div>
                                    {/* <div className="form-group col-12">
                                            <div className="row">
                                                <div className="col">
                                                    <div className="form-group row">
                                                        <label className="col-sm-3 col-form-label">
                                                            Defect images or videos (optional)
                                                        </label>
                                                        <div className="col-sm-9">
                                                            <WorkOrderUploadDocument
                                                                isUploading={(state) => setIsUploading(state)}
                                                                uploads={uploads}
                                                                setUploads={setUploads}
                                                                allowed={true}
                                                                id={formData._id}
                                                                editView={editView || partialEdit}
                                                                createView={!(editView || partialEdit)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                    <div className="form-group col-12 mb-0">
                                        <button
                                            type="submit"
                                            className="btn btn-primary"
                                            disabled={isCreating}
                                        >
                                            {editView ? 'Update' : 'Add'}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default WorkOrderCreate;
