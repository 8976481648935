import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import GoogleMapReact from 'google-map-react';
import config from "../../../../config.json";

const MapWrapper = styled.main`
  width: 100%;
  display: flex;
`;
  // height: 360px;
const GoogleMapWrapper = styled.div`
  width: 50%;
  height: 260px;
`;

const StreetViewWrapper = styled.div`
  width: 50%;
  height: 260px;
`;

const GoogleMap = ({ children, ...props }) => {

  const streetViewRef = useRef(null);

  const onMapLoaded = (google) => {
    google.map.setCenter(new google.maps.LatLng(props.lat, props.lng));

    var svService = new google.maps.StreetViewService();
    var panoRequest = {
      location: new google.maps.LatLng(props.lat, props.lng),
      preference: google.maps.StreetViewPreference.NEAREST,
      radius: 50,
      source: google.maps.StreetViewSource.OUTDOOR
    };
    svService.getPanorama(panoRequest, (panoData, status) => {
      if (status === google.maps.StreetViewStatus.OK) {
        const panorama = new google.maps.StreetViewPanorama(
          streetViewRef.current,
          {
            pano: panoData.location.pano,
            pov: {
              heading: 0,
              pitch: 0
            }
          });
        google.map.setStreetView(panorama);
      }
    });
  }


  return props.loaded && (
    <MapWrapper>
      <StreetViewWrapper ref={streetViewRef} />
      <GoogleMapWrapper>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: config.googleMapKey,
          }}
          onGoogleApiLoaded={onMapLoaded}
          options={{ streetViewControl: true }}
          {...props}
        >
          {children}
        </GoogleMapReact>
      </GoogleMapWrapper>

    </MapWrapper>
  );
}

GoogleMap.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

GoogleMap.defaultProps = {
  children: null,
};

export default GoogleMap;