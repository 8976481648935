import React, { useState } from 'react';
import { Badge, Button, Modal } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import UploadsComponent from '../../../pages/buildings/violationDetails/uploads';

//Button Component to trigger Uploads Modal
const Uploads = ({ record, category, source, setDataSource }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [uploadedDocuments, setUploadedDocuments] = useState(record.documents);
 
  return (
    <div className="text-center">
      <Button onClick={() => setIsModalVisible(true)} type="text">
        <Badge count={uploadedDocuments.length}>
          {uploadedDocuments.length > 0 && (
            <UploadOutlined style={{ fontSize: 22 }} />
          )}
          {uploadedDocuments.length <= 0 && (
            <UploadOutlined style={{ fontSize: 22 }} />
          )}
        </Badge>
      </Button>
      <Modal
        title="Uploads"
        visible={isModalVisible}
        footer={null}
        onCancel={() => setIsModalVisible(false)}
        width={1000}
      >
        <UploadsComponent
          violationId={record._id}
          category={category}
          setUploadedDocuments={setUploadedDocuments}
          source={source}
          setDataSource={setDataSource}
        />
      </Modal>
    </div>
  );
};

export default Uploads;
