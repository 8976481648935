import jwtDecode from "jwt-decode";
import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router";
import moment from 'moment';
import authService, { logout } from "./services/authService";
import creServices from './services/creService';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useContext } from 'react';
import { SocketContext } from "../context/socket";
const tokenKey = "cre-token";

const AppWrapper = ({ children }) => {

    const socket = useContext(SocketContext);

    const history = useHistory();

    let trackerInterval = null;

    let secondsInterval = null;

    const [showLogoutWarning, setShowLogoutWarning] = useState(false);

    const [leftSeconds, setLeftSeconds] = useState(null);

    useEffect(() => {
        const jwt = localStorage.getItem(tokenKey);
        if (jwt) {
            const jwtDecoded = jwtDecode(jwt);
            if (moment(jwtDecoded.exp * 1000).unix() <= moment().unix() + 1) {
                console.log('Session expired. Logging out');
                logout(socket, jwtDecoded);
                history.push('/login');
                clearInterval(secondsInterval);
            }
        } else {
            logout(null, null);
            history.push('/login');
            clearInterval(secondsInterval);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [leftSeconds])

    useEffect(() => {
        return () => {
            clearInterval(secondsInterval);
            clearInterval(trackerInterval);
        }
    })

    const runTracker = () => {
        trackerInterval = setInterval(() => {
            const jwt = localStorage.getItem(tokenKey);
            try {
                const jwtDecoded = jwtDecode(jwt);
                if (moment().unix() >= moment(jwtDecoded.exp * 1000).subtract('1', 'minute').unix()) {
                    if (!showLogoutWarning) {
                        setShowLogoutWarning(true);
                        clearInterval(trackerInterval);
                        secondsInterval = setInterval(() => {
                            const leftSessionSecs = parseInt(moment.duration(moment(jwtDecoded.exp * 1000).diff(moment())).asSeconds().toFixed());
                            if (leftSessionSecs <= 0) {
                                clearInterval(secondsInterval);
                            }
                            setLeftSeconds(leftSessionSecs);
                        }, 1000)
                    }
                }

            } catch (_err) {
                console.log('_err', _err);
                console.log('Exception. Logging out');
                logout(socket);
                history.push('/login');
                clearInterval(secondsInterval);
                clearInterval(trackerInterval);
            }
        }, 1000);
    }

    if (!showLogoutWarning) {
        runTracker();
    }

    const continueSession = async () => {
        try {
            const { data } = await creServices.renewSession();
            if (data.status) {
                localStorage.setItem(tokenKey, data.token);
                setShowLogoutWarning(false);
                runTracker();
            }
        } catch (_err) {
            console.log('error in renewing session', _err);
        }
    }

    return (
        <div>
            {children}
            <Modal isOpen={showLogoutWarning} backdrop="static">
                <ModalHeader>Your session is about to expire.</ModalHeader>
                <ModalBody>
                    You will be logged-out automatically in {leftSeconds !== null && leftSeconds >= 0 ? leftSeconds : '--'} seconds.
                </ModalBody>
                <ModalFooter>
                    <Button color="light" onClick={() => {
                        const user = authService.getCurrentUser();
                        logout(socket, user, true);
                        history.push('/login');
                    }}>Logout</Button>{' '}
                    <Button color="secondary" onClick={() => continueSession()}>Continue Session</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default AppWrapper;
