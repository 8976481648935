import ReactLoading from 'react-loading';
import { Input, Alert } from 'antd';
import { PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons'
import './adressesTable.css'
import { Fragment } from 'react';

const AdressTable = ({
    buildings,
    doneLoading,
    handleUnAndCheckAll,
    handleBuildingCheck,
    additionalKeysChange,
    additionalKeysValidate,
    additionalKeysAdd,
    additionalKeysRemove,
    unChecked
}) => {

    return (
        <table className="table">
            <thead>
                <tr>
                    <th scope="col">
                        <input
                            type="checkbox"
                            defaultChecked={!unChecked}
                            onChange={(e) => handleUnAndCheckAll(e.target.checked)}
                        />
                    </th>
                    <th scope="col">Address</th>
                    <th scope="col">BIN</th>
                </tr>
            </thead>
            <tbody>
                {doneLoading ? (
                    <tr>
                        <td colSpan="5">
                            <div align="center">
                                <ReactLoading type={'cylon'} color={'grey'} />
                            </div>
                        </td>
                    </tr>
                ) : (
                    buildings.map((building, key) => {
                        return (
                            <tr key={key}>
                                <td>
                                    <input
                                        type="checkbox"
                                        id={`bin${building.bin}`}
                                        className="building"
                                        checked={building.checked || false}
                                        onChange={(e) =>
                                            handleBuildingCheck(
                                                e.target.checked,
                                                building.bin
                                            )
                                        }
                                    />

                                </td>
                                <td>
                                    <div >
                                        {building.display_address
                                            ? building.display_address
                                            : building.address}
                                        {building.additionalKeys &&
                                            <div className='mt-3'>
                                                {
                                                    Object.keys(building.additionalKeys).map(key => {
                                                        let posibleValues = building
                                                            .additionalKeys[key]
                                                            .posibleValues
                                                            .filter(
                                                                posValue => !(
                                                                    building.additionalKeys[key].values.some(el => el.value === posValue) ||
                                                                    building.additionalKeys[key].existingValues.some(el => el === posValue)
                                                                    )
                                                            )
                                                        return (
                                                            <Fragment key={key}>
                                                                <div className='mr-3' >

                                                                    <div className='d-flex flex-wrap ml-4'>
                                                                        {posibleValues.map(el =>
                                                                            <div
                                                                                className='pointer posible-var mr-2 '
                                                                                onClick={() => additionalKeysAdd(key, building.bin, el)}
                                                                                key={el}
                                                                            > <PlusCircleOutlined className='posible-var' /> {el}
                                                                            </div>)}
                                                                        {posibleValues.length > 1 &&
                                                                            <button
                                                                                className='btn btn-sm btn-success  pl-1 pt-0 pr-1 pb-0'
                                                                                onClick={()=>{
                                                                                    for (let el of posibleValues) {
                                                                                        additionalKeysAdd(key, building.bin, el)
                                                                                    }
                                                                                }}
                                                                            >
                                                                                Add all
                                                                            </button>}
                                                                    </div>

                                                                    {building.additionalKeys[key].values.map((value, index) => {

                                                                        return (
                                                                            <div className='d-flex align-items-center mb-1 height-40' key={index}>
                                                                                <DeleteOutlined
                                                                                    className={`pointer ${index === 0 ? 'invisible' : ''}`}
                                                                                    onClick={() => additionalKeysRemove(key, building.bin, index)}
                                                                                />
                                                                                <Input
                                                                                    className='w-50 ml-2 mr-2'
                                                                                    addonBefore={building.additionalKeys[key].label}
                                                                                    onChange={(event) => additionalKeysChange(key, event.target.value, building.bin, index)}
                                                                                    onBlur={() => building.checked && additionalKeysValidate(building.bin)}
                                                                                    value={value.value}
                                                                                    status={value.error ? 'error' : ''}
                                                                                />

                                                                                <div className='w-250px'>
                                                                                    {value.error &&
                                                                                        <Alert message={value.error} type="error" className='ptb-4px' />
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }
                                                                    )}

                                                                </div>
                                                                <div className='d-flex mt-2 w-50 mb-2'>
                                                                    <PlusCircleOutlined
                                                                        onClick={() => additionalKeysAdd(key, building.bin)}
                                                                        className='pointer mr-2 ml-4'
                                                                        style={{ color: '#143fef', fontSize: '20px' }}
                                                                    />
                                                                    <strong>
                                                                        Add {building.additionalKeys[key].label}
                                                                    </strong>
                                                                </div>

                                                            </Fragment>
                                                        )
                                                    })
                                                }

                                            </div>
                                        }

                                    </div>
                                </td>
                                <td>{building.bin}</td>
                            </tr>
                        );
                    })
                )}
            </tbody>
        </table>
    );
};

export default AdressTable;