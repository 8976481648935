import moment from 'moment';

const permitsAdditionalTable = ({ items }) => {

    return (
        <div className="w-100">
            <div className="d-flex justify-content-center">
                <h5>DOB Approved Permits</h5>
            </div>
            <div className="d-flex text-center align-items-center disposition-header " style={{ background: '#CECECE' }}>
                <div className="col-4 p-0">
                    Filing Reason
                </div>
                <div className="col-4 p-0">
                    Issued Date
                </div>
                <div className="col-4 p-0">
                    Expired Date
                </div>

            </div>
            {items.map((el, i) => {
                return <div className="d-flex text-center border-bottom align-items-center disposition-rows" key={i}>
                    <div className="col-4 p-0">
                        <p>{el["filing_reason"] ? el["filing_reason"] : 'n/a'}</p>
                    </div>
                    <div className="col-4 p-0">
                        <p>{el["issued_date"] ? moment(new Date(el["issued_date"])).format('MM/DD/YYYY') : 'n/a'}</p>
                    </div>
                    <div className="col-4 p-0">
                        <p>{el["expired_date"] ? moment(new Date(el["expired_date"])).format('MM/DD/YYYY') : 'n/a'}</p>
                    </div>

                </div>
            })}

        </div>
    )
}

export default permitsAdditionalTable;