import React from "react";
import { DatePicker } from 'antd';
import moment from 'moment'


import guard from '../../common/guard'
export const lSvaliableSnapshotsKey = 'avaliableSnapshots'
export const lScomparisonDateKey = 'comparisonDate'

const SnapshotDataMenu = ({ selectedDate, setSelectedDate, noPadding, noLabel }) => {


    if (!guard({ serviceName: 'snapshot' })) {
        return null
    }

    const setDateHandler = (item) => {

        // if (!noStorage) {
        //     if (item.value) {
        //         localStorage.setItem(lScomparisonDateKey, item.value);
        //     } else {
        //         localStorage.removeItem(lScomparisonDateKey);
        //     }
        // }

        setSelectedDate(item.value)
    }

    return (
        <div className="d-flex">
            <div className={`d-flex align-items-center bg-white ${noPadding ? '' : 'p-2'} pr-3 mb-2`} style={{ borderRadius: '15px' }}>
                {!noLabel && <span className="mr-2">Compared to</span>}
                <DatePicker
                    disabledDate={(date) => {
                        const dates = JSON.parse(localStorage.getItem(lSvaliableSnapshotsKey) || '[]')
                        const result = moment(date).format('MM-DD-YYYY')
                        return !dates.includes(result)
                    }}
                    value={selectedDate ? moment(selectedDate) : selectedDate}
                    onChange={(date) => { setDateHandler({ value: date?.format('LL') }); }
                    }
                />
                {selectedDate && <span
                    onClick={() => setSelectedDate()}
                    className="btn btn-link text-secondary px-0 ml-1"
                >
                    [Clear]
                </span>}


            </div>
        </div>
    );
};


export default SnapshotDataMenu;