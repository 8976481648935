import React, { useState, useEffect } from 'react';
import { Modal, AutoComplete } from 'antd';
import { toast } from 'react-toastify';


import creService from '../../services/creService';

const VisttModal = ({ cancleVisittHandler, vissitCurrentKey, setVisittBuilding, buildings }) => {

    const [options, setOptions] = useState([])

    useEffect(() => {

        getOptions()
    }, [])

    const getOptions = async () => {
        try {
            const result = await creService.getBuildingsListOnly()

            setOptions(result.data.map(el => ({
                building: el,
                label: el.display_address ? el.display_address : el.address,
                value: el.display_address ? el.display_address : el.address,
            })))

        } catch (err) {
            toast.error(err.message)
        }

    }

    const setConnectAddress = async (visittAddress, bcompliantBuilding) => {

        let bin = bcompliantBuilding?.bin
        setVisittBuilding(buildings.map(el => {

            if (el.name === visittAddress.name) {
                if (!bcompliantBuilding) {
                    visittAddress = ''
                    bin = el.bcompliantBuilding.bin
                }
                return { ...el, bcompliantBuilding }
            }
            return el
        }),  vissitCurrentKey._id)



        try {
            const result = await creService.setVisittAddress(bin, visittAddress.name, vissitCurrentKey._id, visittAddress._id)
            if (result.data.status) {
                toast.success('Address Set')
            } else {
                toast.error(result.data.message)
            }
        } catch (err) {
            toast.error(err.message)
        }

    }


    return (
        <>
            <Modal
                title='Validate Buildings'
                visible={vissitCurrentKey?.valid}
                footer={null}
                onCancel={cancleVisittHandler}
                // style={{ top: 20 }}

                width={'90%'}
            // centered
            >
                {buildings.map((el, i) => {
                    let defaultAddress;
                    if(el.bcompliantBuilding) {
                        defaultAddress = el.bcompliantBuilding.display_address ? el.bcompliantBuilding.display_address : el.bcompliantBuilding.address
                    } else {
                        defaultAddress = ''
                    }
                    return <div key={i + vissitCurrentKey._id} className={`d-flex justify-content-between align-items-center  p-2 ${i !== 0 ? 'border-top' : ''}`}>
                        <div><strong>Visitt address:</strong> {el.name}</div>
                        <div><strong>BCompliant address:</strong>
                            <AutoComplete
                                style={{ width: 400 }}
                                className='ml-2'
                                defaultValue={defaultAddress}
                                options={options}
                                onSelect={(address, fullOption) => setConnectAddress(el, fullOption.building)}
                                onClear={() => setConnectAddress(el)}
                                allowClear
                                placeholder="type to building search"
                                filterOption={(inputValue, option) =>
                                    (option.building.bin + option.building.address + option.building.display_address).toLocaleLowerCase().includes(inputValue.toLocaleLowerCase())
                                }
                            />
                        </div>
                    </div>
                })}

            </Modal>
        </>
    );
}

export default VisttModal;
