import React, { Fragment } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import ReactLoading from 'react-loading';
import creService from "../../../services/creService";
import { faFileCsv } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ActivityWeekCard = ({ title, isLoading, data }) => {

  const dowlandExcel = () => {
    creService.activityExcel('activity.xlsx')
  }

  return (
    <Fragment>
      <div className="card">
        <div className="card-header p-3 d-flex justify-content-between">
          <h5>{title}</h5>
          <div  onClick={dowlandExcel}  class='pointer'> <FontAwesomeIcon color="#567AFF" icon={faFileCsv} size="lg"/></div>
        </div>
        <div className="card-body p-0">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Address</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {isLoading && (
                <tr>
                  <td colSpan="2">
                    <ReactLoading type={'cylon'} color={'grey'} />
                  </td>
                </tr>
              )}
              {!isLoading && data.length <= 0 && (
                <tr>
                  <td colSpan="2">No data available.</td>
                </tr>
              )}
              {data &&
                data.map((row, i) => {
                  const address = (
                    row.building.display_address
                      ? row.building.display_address
                      : row.building.address
                  )?.split(',')[0];
                  return (
                    <tr key={address + i}>
                      <td>
                        <p className="mb-0 font-weight-bold">
                          <Link
                            to={`/violations/all/${row.bin}`}
                            className="text-dark-blue"
                          >
                            <i className="fa fa-building mr-1" />
                            {address}
                          </Link>
                        </p>
                        {row.eventDate && (
                          <p className="mb-0">
                            <i className="fa fa-calendar mr-1" />
                            {moment(row.eventDate).format('MM/DD/YYYY')}
                          </p>
                        )}
                        {!row.eventDate && (
                          <div className="row">
                            <div className="col-6">
                              Open:{' '}
                              {moment(row.reportUseDate).format('MM/DD/YYYY')}
                            </div>
                            {row.reportClosedDate && (
                              <div className="col-6 text-right">
                                Closed:{' '}
                                {moment(row.reportClosedDate).format(
                                  'MM/DD/YYYY'
                                )}
                              </div>
                            )}
                          </div>
                        )}
                      </td>
                      <td>
                        {row.category && (
                          <>
                            <p className="mb-0 font-weight-bold">
                              <Link
                                to={`/violations/table/${row.bin}/${row.category}/${row.sub}`}
                                className="text-dark-blue"
                              >
                                {row.category}/
                                {row.sub.replace(/__/, ' ').replace(/_/, ' ')}
                              </Link>
                            </p>
                            <Link
                                to={`/violation-details/${row.violation_id ? row.violation_id : row._id }`}
                              >
                            {row.uniqueData?.displayAs} {row.uniqueData.value}
                            </Link>
                          </>
                        )}
                        {!row.category && (
                          <>
                            <p className="mb-0 font-weight-bold">{row.type}</p>
                          </>
                        )}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </Fragment>
  );
};

export default ActivityWeekCard;
