import React, {Fragment} from "react";
import Joi from "joi-browser";
import Form from "../common/forms/form";
import {ToastContainer} from "react-toastify";
import logo from "../../assets/images/endless-logo.png";
import creApi from "../services/creService";
import enrollimg from '../../assets/images/dashboard/enroll.png';

class EnrollForm extends Form {
    state = {
        data: { fullName: "", email: "", phone: "", numberOfBuildings: "", comments: "" },
        errors: {},
        loading: false
    };

    schema = {
        fullName: Joi.string().regex(/(\w.+\s).+/, { name: 'Full Name'}).required().label("Full Name"),
        email: Joi.string().max(50).required().email().label("Email"),
        phone: Joi.string().max(15).required().label("Phone Number"),
        numberOfBuildings: Joi.number().allow('').label("Number of Buildings"),
        comments: Joi.string().allow('').max(450).label("Comments"),
    };

    doSubmit = async () => {
        try {
            this.setState({loading: 1});
            const response = await creApi.register(this.state.data);
            console.log(response)
           // auth.loginWithJwt(response.headers["x-auth-token"]);
           // window.location = "/";
            this.props.history.push("/thank-you");
        } catch (ex) {
            if (ex.response && ex.response.status === 400) {
                const errors = { ...this.state.errors };
                errors.formError = ex.response.data;
                this.setState({ errors });
            }
        }
        this.setState({loading: 0});
    };

    render() {
        let submitButtonLabel = !this.state.loading ? "Submit" : "Processing...";
        return (
            <Fragment>
                <ToastContainer />
                 <div className="page-wrapper">
                    <div className="container-fluid">
                      <div className="row login-header">
                        <div className="col-md-6 mobile-center">
                          <div className="pt-2">
                            <img src={logo}  className="image-logo" alt="" />
                          </div>
                        </div>
                        <div className="col-md-6 text-right mobile-center">
                          <ul>
                            <li><a href="/login">Log In</a></li>
                            <li><a href="/enroll">Sign Up</a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="auth-bg">
                          <div className="container">
                              <div className="row">
                                  <div className="col-md-7 mobile-hide">
                                    <img className="w-100" src={enrollimg} alt="" />
                                  </div>
                                  <div className="col-md-5 mt-3 pb-3 enroll">
                                    <div className="card mt-4">
                                      <div className="card-body">
                                        <h1>Enroll</h1>
                                        <p>This screen allows user to request an account.</p>
                                        <form className="needs-validation" onSubmit={this.handleSubmit}>
                                            {this.renderFormError(this.state.errors.formError)}
                                            <div>
                                                {this.renderInput("fullName", "Full Name")}
                                                {this.renderInput("email", "Email")}
                                                {this.renderInput("phone", "Phone")}
                                                {this.renderInput("numberOfBuildings", "Number of Buildings")}
                                                {this.renderInput("comments", "Comments")}
                                                {this.renderButton(submitButtonLabel)}
                                            </div>
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                              </div>
                        </div>
                    </div>
                </div>
            </Fragment>

        );
    }
}

export default EnrollForm;
