import React, { Fragment, useEffect, useState } from 'react';
import { Skeleton, Collapse } from 'antd';
import { toast } from 'react-toastify';
import { FilePdfOutlined } from '@ant-design/icons'
import DataTable from "react-data-table-component";
import moment from "moment";

import DocsViewer from '../../common/document-viewer'
import creService from "../../services/creService";
import './guides.css'

const { Panel } = Collapse;

const Guides = () => {

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([])
    const [currentfile, setCurrentfile] = useState(null);
    const [docsViewerVisibility, setDocsViewerVisibility] = useState(false);

    const handleUrlClick = async (document) => {
        setCurrentfile(document)
        setDocsViewerVisibility(true)
    };

    const loadConfig = async () => {
        try {
            setLoading(true)
            const response = await creService.getAllGuides();
            if (response.data.status) {
                setData(response.data.complianceInfo);
            } else {
                toast.error(response.data.message)
            }

        } catch (err) {
            toast.error(err.message)
        }
        setLoading(false)
    }

    useEffect(() => {
        loadConfig()
    }, [])
    const columnsGuides = [
        {
            name: 'Item',
            selector: 'name',
            sortable: true,
            wrap: true
        },
        {

            name: 'Description',
            selector: 'description',
            sortable: true,
            wrap: true
        },
        {
            name: 'Date',
            selector: 'date',
            sortable: true,
            format: (row) => row.date ? moment.utc(row.date).format('MM/DD/YYYY') : row.date,
            wrap: true
        },
        {
            name: 'Files',
            selector: 'files',
            sortable: true,
            format: (row) => row.files?.map((fileObj) =>
                <div className="pointer text-primary" key={fileObj._id} onClick={() => handleUrlClick(fileObj)}>{fileObj.file.originalname}</div>
            ),
            wrap: true
        },
        {
            name: 'Links',
            selector: 'links',
            sortable: true,
            format: (row) => row.links?.map((link) =>
                <Fragment key={link.value + link.displayValue}>
                    <a className='text-primary' href={link.value.includes('http') ? link.value : 'http://' + link.value} target="_blank" rel="noreferrer">{link.displayValue}</a>
                    <br />
                </Fragment>
            ),
            wrap: true

        },
        {
            name: 'Notes',
            selector: 'notes',
            sortable: true,
            wrap: true
        },

    ];

    return !loading ? (
        <div id='guides'><div className="card" >
            <div className="card-header p-3" >
                <h5 id="guide-table" className="d-flex justify-content-between w-100">
                    <div>
                        Guides
                    </div>
                    <a
                        href="https://bcompliant-public.s3.amazonaws.com/Compliance+Calendar+Handout+2024.pdf"
                        target="_blank"
                        rel="noreferrer"
                    ><FilePdfOutlined /></a></h5>

            </div>
        </div>
            <Collapse accordion >
                {data.map((currentGuide, i) => {
                    return <Panel header={currentGuide.name.replaceAll('_', ' ')} key={i}>
                        <div>
                            <div className="custom_tab tab-sec" style={{ padding: '10px 30px' }}>
                                <div dangerouslySetInnerHTML={{ __html: currentGuide.text }} />
                            </div>
                            <DataTable
                                columns={columnsGuides}
                                data={currentGuide.guides}
                                pagination
                                responsive
                                striped
                                noHeader
                                noDataComponent={<div className="my-3">No guides created yet. </div>}
                            />
                        </div>
                    </Panel>
                })

                }
            </Collapse>
            <DocsViewer document={currentfile} isVisible={docsViewerVisibility} setIsVisible={setDocsViewerVisibility} />
        </div>
    ) : (<Skeleton active />);

}

export default Guides;
