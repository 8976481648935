import ReactLoading from "react-loading";

const RespondentFilter = (props) => {

    const handleCheckUncheckRespondents = (checked) => {
        const allRespondents = checked ? props.respondents : [];
        props.setFilterData({
            ...props.filterData,
            Respondents: [...allRespondents],
        });
    }

    const handleRespondentChange = (checked, respondent) => {
        let subRespondents = props.filterData.Respondents ? [...props.filterData.Respondents] : [];
        if (checked) {
            if (!subRespondents.includes(respondent)) {
                subRespondents.push(respondent)
            }
        } else {
            const subRespondentIndex = subRespondents.indexOf(respondent);
            if (subRespondentIndex > -1) {
                subRespondents.splice(subRespondentIndex, 1);
            }
        }
        props.setFilterData({
            ...props.filterData,
            Respondents: subRespondents
        })
    }

    return (
        <>
        { props.filterRespondents &&
            <div className="card-body p-2">
                <div className="form-check">
                    <label className="mb-0">
                        <input type="checkbox" className="form-check-input" id="filter_by_respondent" onChange={(e) => props.getRespondent(e.target.checked)} />
                        <h5 className="text-uppercase mb-3" htmlFor="filter_by_respondent">Filter Respondents</h5>
                    </label>
                </div>
            </div>
        }
        { props.respondentChecked && props.respondents.length < 1 && !props.respondentsLoading &&
            <div className="alert alert-danger" role="alert">
                No respondents available.
            </div>
        }
        { (props.respondentsLoading ? (<div align="center"> <ReactLoading type={"cylon"} color={"grey"} /> </div>) :
            <div className="card">
                { props.respondents.length > 0 &&
                    <div className="card-header p-2 mx-4">
                        <input type="checkbox" className="form-check-input"
                            onChange={(e) => handleCheckUncheckRespondents(e.target.checked)}
                            checked={props.filterData['Respondents']?.length === props.respondents.length ? 'checked' : ''}
                        />
                        <label>Select/Deselect All</label>
                    </div>
                }
                <div className="card-body p-2">
                    <div className="row px-4">
                        {props.respondents.map((respondent, index) => {
                            return (
                                <div className="col-3" key={index}>
                                    <input type="checkbox" className="form-check-input" onChange={(e) => handleRespondentChange(e.target.checked, respondent)}
                                        checked={props.filterData['Respondents']?.includes(respondent) ? 'checked'
                                            : ''} />
                                    <label>{respondent}</label>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        )}
        </>
    )
}

export default RespondentFilter;