import moment from 'moment';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './caseInvestigations.css'
import codes from '../../../../constant/philly_codes.json'
import { Collapse } from 'reactstrap';

const CaseViolations = ({ items }) => {
    const [collapse, setcollapse] = useState({});



    const callapseHandler = (_id) => {
        setcollapse({ ...collapse, [_id]: !collapse[_id] })

    }


    return (
        <div className="w-100 mt-5 col-12">

            <h5 className='text-info'>Violations</h5>

            <div className="d-flex text-center align-items-center" >
                <div className="col-3 p-0 font-weight-bold">
                    Code
                </div>
                <div className="col-3 p-0 font-weight-bold">
                    Type
                </div>
                <div className="col-3 p-0 font-weight-bold">
                    Date
                </div>
                <div className="col-3 p-0 font-weight-bold">
                    Status
                </div>
            </div>
            {items.map((el, i) => {
                return <div>
                    <div className=" pointer d-flex text-center border-bottom align-items-center disposition-rows caseInvestigation" key={i} onClick={() => callapseHandler(el._id)}>
                        <div className="col-3 p-0">
                            <p>{el.violationData.violationcode}</p>
                        </div>
                        <div className="col-3 p-0">
                            <p>{el.violationData.violationcodetitle}</p>
                        </div>

                        <div className="col-3 p-0">
                            <p>{el.violationData.violationdate ? moment(new Date(el.violationData.violationdate)).format('MM/DD/YYYY') : 'n/a'}</p>
                        </div>
                        <div className="col-3 p-0">
                            <p>{el.violationData.violationstatus}</p>
                        </div>
                        <div className="p-0">

                        </div>
                    </div>
                    <Collapse isOpen={!!collapse[el._id]}>
                        <p>
                            <strong>Code Description: </strong>
                            {codes.find(code => code['Violation Type'] === el.violationData.violationcode)?.['Violation Explanation'] || 'No Violation Explanation'}
                            <div>
                                <Link className="text-nowrap" to={`/violation-details/${el._id}`} target="_blank">
                                    View the Violation <i aria-hidden="true" class="fa fa-external-link"></i>
                                </Link>

                            </div>
                            <div>
                                <a href='https://codelibrary.amlegal.com/codes/philadelphia/latest/overview' target='_blank' rel="noreferrer" >
                                    View the full code {el.violationData.violationcode} <i aria-hidden="true" class="fa fa-external-link"></i>
                                </a>
                            </div>
                        </p>
                    </Collapse>

                </div>
            })}

        </div >
    )
}

export default CaseViolations;