import React from 'react';

const FilterReactDataTable = ({ onFilter, onClear, filterText }) => {

    return (
        <div className="form-group">
            <div className="input-group">
                <div className="input-group-prepend">
                    <span className="input-group-text"><i className="fa fa-search"/></span>
                </div>
                <input
                    type="search"
                    name="query"
                    onKeyUp={onFilter}
                    className="form-control"
                />
            </div>
        </div>
    )
}

export default FilterReactDataTable;
